import {
  CourseItem,
  Page,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import React from "react";
import { PageParagraphRender } from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { PageImageRender } from "app/components/Exercises/CourseEdit/items/content/PageImageItem";
import { PageMultipleChoiceRender } from "app/components/Exercises/CourseEdit/items/quiz/PageMultipleChoiceItem";
import { ConsumptionContextProvider } from "app/components/Exercises/CourseEdit/render/ConsumptionContext";
import { PageImageLabelingRender } from "app/components/Exercises/CourseEdit/items/quiz/PageImageLabelingItem";
import { PageListRender } from "app/components/Exercises/CourseEdit/items/content/PageListItem";
import { PageVideoRender } from "app/components/Exercises/CourseEdit/items/content/PageVideoItem";
import { PageEmbedRender } from "app/components/Exercises/CourseEdit/items/content/PageEmbedItem";
import { PageAudioRender } from "app/components/Exercises/CourseEdit/items/content/PageAudioItem";
import { PageSortingRender } from "app/components/Exercises/CourseEdit/items/quiz/PageSortingItem";
import { PagePairingRender } from "app/components/Exercises/CourseEdit/items/quiz/PagePairingItem";
import { SlideRender } from "app/components/Exercises/CourseEdit/slide/SlideRender";
import { PageFillTheGapRender } from "app/components/Exercises/CourseEdit/items/quiz/PageFillTheGapItem";
import { FlashcardsItemRender } from "app/components/Exercises/CourseEdit/items/content/PageFlashcardsItem";
import { PageColumnsRender } from "app/components/Exercises/CourseEdit/items/content/PageColumnsItem";
import { PollMultipleChoiceRender } from "../items/poll/PollMultipleChoiceItem";
import { PollCommentRender } from "../items/poll/PollCommentItem";
import { PageCategorisationRender } from "../items/quiz/PageCategorisationItem";
import { PageCrosswordRender } from "../items/quiz/PageCrosswordItem";
import { FlashcardsQuizItemRender } from "../items/quiz/PageFlashcardsQuizItem";

export const PageRender = ({ page }: { page: Page }) => {
  if (page.category === "slide") return <SlideRender page={page} />;

  return (
    <ConsumptionContextProvider items={page.items}>
      <CoursePageRender items={page.items} />
    </ConsumptionContextProvider>
  );
};

export const CoursePageRender = ({ items }: { items: CourseItem[] }) => (
  <div className="max-w-2xl w-full mx-auto relative flex flex-col gap-6 my-12 text-gray-700 px-4 sm:px-1 md:px-0">
    {items.map((item) => (
      <PageItemRender key={item.id} item={item} />
    ))}
  </div>
);

export const PageItemRender = ({ item }: { item: CourseItem }) => {
  const { type } = item;
  if (type === PageItemType.Paragraph)
    return <PageParagraphRender item={item} />;
  if (type === PageItemType.List) return <PageListRender item={item} />;
  if (type === PageItemType.Image) return <PageImageRender item={item} />;
  if (type === PageItemType.Video) return <PageVideoRender item={item} />;
  if (type === PageItemType.Audio) return <PageAudioRender item={item} />;
  if (type === PageItemType.Embed) return <PageEmbedRender item={item} />;
  if (type === PageItemType.Columns) return <PageColumnsRender item={item} />;

  if (type === PageItemType.Explanation) return null;

  if (type === PageItemType.Flashcards)
    return <FlashcardsItemRender item={item} />;

  if (type === PageItemType.MultipleChoice)
    return <PageMultipleChoiceRender id={item.id} />;
  // if (type === PageItemType.ShortAnswer)
  //   return <PageShortAnswerRender id={item.id} />;
  if (type === PageItemType.ImageLabeling)
    return <PageImageLabelingRender id={item.id} />;
  if (type === PageItemType.Sorting) return <PageSortingRender id={item.id} />;
  if (type === PageItemType.Pairing) return <PagePairingRender id={item.id} />;
  if (type === PageItemType.FillTheGap)
    return <PageFillTheGapRender id={item.id} />;
  if (type === PageItemType.Categorisation)
    return <PageCategorisationRender id={item.id} />;
  if (type === PageItemType.FlashcardsQuiz)
    return <FlashcardsQuizItemRender id={item.id} />;
  if (type === PageItemType.Crossword)
    return <PageCrosswordRender id={item.id} />;

  if (type === PageItemType.MultipleChoicePoll)
    return <PollMultipleChoiceRender id={item.id} />;
  if (type === PageItemType.CommentPoll)
    return <PollCommentRender id={item.id} />;

  return null;
};
