import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getGuestCourse } from "api/course/courseAPI";
import { InnerCourseTestDetails } from "app/pages/courses/CourseTestDetails";

export const GuestCourseTestDetails = () => {
  const { guestPin, courseId } =
    useParams<{ guestPin: string; courseId: string }>();

  const { isSuccess, data } = useQuery({
    queryFn: () => getGuestCourse(guestPin, courseId, { results: true }),
    queryKey: ["guestCourse", guestPin, "test", courseId],
    refetchOnWindowFocus: false,
    enabled: !!courseId,
  });

  return <InnerCourseTestDetails {...{ data, isSuccess }} />;
};
