import React, { useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router";
import {
  adminRoutes,
  authRoutes,
  authRoutesList,
  AuthUserRoutes,
  courseRoutes,
  groupRoutes,
  guestCourseRoutes,
  GuestRoutes,
  libraryRoutes,
  miscRoutes,
  publicRoutes,
  sessionRoutes,
} from "enums/routes";
import Activation from "../pages/auth/Activation";
import {
  ExerciseLayout,
  FullWidthLayout,
  OnboardingLayout,
} from "../pages/Layout";
import { logoutUser } from "../store/authentication";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import { AccessToken } from "enums/constant";
import { store } from "app/store";
import setAuthTokenAxios from "../../config/axios/setAuthTokenAxios";
import Upload from "../pages/sources/Upload";
import { useHistory } from "react-router-dom";
import { B2BLandingPage, LandingPage } from "app/pages/landingPage/LandingPage";
import SessionRender from "../components/Exercises/LiveSession/SessionRender";
import AdminPage from "app/pages/admin/AdminPage";
import AdminUsers from "app/pages/admin/AdminUsers";
import AdminOrganizations from "app/pages/admin/AdminOrganizations";
import AdminUserDetails from "app/pages/admin/AdminUserDetails";
import AdminUserNew from "app/pages/admin/AdminUserNew";
import { AdminOrganizationDetails } from "../pages/admin/organizationDetails/AdminOrganizationDetails";
import {
  AppRoute,
  AppSwitch,
  ProtectedRoute,
} from "app/routes/helpers/AppSwitch";
import { NotFound } from "app/pages/misc/NotFound";
import { useRole } from "../hooks/useRole";
import { CourseEditView } from "app/pages/courses/Edit/CourseEditView";
import { CoursesListView } from "app/pages/courses/CoursesListView";
import { Subscriptions } from "app/pages/payment/Subscriptions";
import { useSelector } from "react-redux";
import { selectUserDetails } from "app/store/user";
import { AccountSetup } from "app/pages/account/AccountSetup";
import { LibraryView } from "app/pages/library/LibraryView";
import { CourseDetails } from "app/pages/courses/CourseDetails";
import { GroupsRoutes } from "app/pages/groups/GroupsRoutes";
import { CourseTestDetails } from "app/pages/courses/CourseTestDetails";
import { CoursePageTest } from "app/pages/courses/CoursePageTest";
import { CourseActivity } from "app/pages/courses/CourseActivity";
import { TermsPrivacy } from "app/pages/landingPage/TermsPrivacy";
import { RegisterView } from "app/pages/auth/Registration";
import { LoginView } from "app/pages/auth/Login";
import { HelpRoutes } from "app/pages/help/HelpRoutes";
import { GuestCoursesListView } from "app/pages/courses/guest/GuestCoursesListView";
import { GuestCourseTestDetails } from "app/pages/courses/guest/GuestCourseTestDetails";
import { PublicLibraryView } from "app/pages/library/PublicLibraryView";

const LibraryRoutes = () => {
  return (
    <AppSwitch>
      <ProtectedRoute
        exact
        path={libraryRoutes.public}
        component={PublicLibraryView}
      />

      <ProtectedRoute
        exact
        path={[libraryRoutes.library, libraryRoutes.share()]}
        component={LibraryView}
      />
    </AppSwitch>
  );
};

const CourseRoutes = () => {
  const role = useRole();
  return (
    <AppSwitch>
      <ProtectedRoute
        exact
        path={[courseRoutes.list, courseRoutes.share()]}
        component={CoursesListView}
      />

      <ProtectedRoute
        exact
        path={courseRoutes.test()}
        component={CourseTestDetails}
      />
      <ProtectedRoute
        exact
        path={courseRoutes.pageTest()}
        component={CoursePageTest}
        noLayout
      />

      <ProtectedRoute
        exact
        path={courseRoutes.details()}
        component={CourseDetails}
      />
      <ProtectedRoute
        exact
        path={courseRoutes.activity()}
        component={CourseActivity}
      />

      {role.teacher && (
        <ProtectedRoute
          exact
          noLayout
          path={courseRoutes.new}
          render={(props) => <CourseEditView {...props} new />}
        />
      )}
      {role.teacher && (
        <ProtectedRoute
          exact
          noLayout
          path={[courseRoutes.edit(), courseRoutes.editPage()]}
          component={CourseEditView}
        />
      )}
    </AppSwitch>
  );
};

const GuestCourseRoutes = () => {
  return (
    <AppSwitch>
      <AppRoute
        exact
        path={guestCourseRoutes.list()}
        component={GuestCoursesListView}
      />

      <AppRoute
        exact
        path={guestCourseRoutes.test()}
        component={GuestCourseTestDetails}
      />
      <AppRoute
        exact
        path={guestCourseRoutes.pageTest()}
        component={CoursePageTest}
        noLayout
      />
    </AppSwitch>
  );
};

const MainRoutes = () => {
  const role = useRole();
  const history = useHistory();
  const location = useLocation();
  const userDetails = useSelector(selectUserDetails);

  useEffect(() => {
    if (location.pathname.includes(AuthUserRoutes.logout)) {
      if (localStorage.getItem(AccessToken)) {
        store.dispatch(logoutUser(history));
        localStorage.removeItem(AccessToken);
      }
      setAuthTokenAxios("");
    }
  }, []);

  // is loaded, but has null org
  if (
    !role.guest &&
    !!userDetails.id &&
    userDetails.organization == null &&
    role.isB2B
  )
    return (
      <OnboardingLayout>
        <AccountSetup />
      </OnboardingLayout>
    );

  // if (!role.prodMode) {
  //   if (
  //     !role.guest &&
  //     !role.isB2B &&
  //     !role.superuser &&
  //     userDetails.is_org_pro &&
  //     (!userDetails.subscription ||
  //       (userDetails.subscription.status !== "active" &&
  //         userDetails.subscription.status !== "trialing"))
  //   )
  //     return (
  //       <OnboardingLayout>
  //         <Subscriptions />
  //       </OnboardingLayout>
  //     );
  // }

  // routes for logged in users
  return (
    <>
      <AppSwitch>
        {role.guest && [
          <AppRoute
            key={authRoutes.login}
            path={authRoutes.login}
            component={LoginView}
          />,
          <AppRoute
            key={authRoutes.register}
            path={authRoutes.register}
            component={RegisterView}
          />,
          <AppRoute
            key={authRoutes.activate()}
            path={authRoutes.activate()}
            component={Activation}
          />,
          <AppRoute
            key={authRoutes.forgotPassword}
            path={authRoutes.forgotPassword}
            component={ForgotPassword}
          />,
          <AppRoute
            key={authRoutes.resetPassword()}
            path={authRoutes.resetPassword()}
            component={ResetPassword}
          />,
        ]}
        {role.guest && (
          <AppRoute
            layout={FullWidthLayout}
            path={publicRoutes.home}
            exact
            component={role.isB2B ? B2BLandingPage : LandingPage}
          />
        )}
        <AppRoute path={publicRoutes.help} component={HelpRoutes} />

        <Route path={guestCourseRoutes.list()} component={GuestCourseRoutes} />
        <ProtectedRoute
          noLayout
          path={courseRoutes.list}
          component={CourseRoutes}
        />
        {role.teacher && (
          <ProtectedRoute
            noLayout
            path={libraryRoutes.library}
            component={LibraryRoutes}
          />
        )}
        <ProtectedRoute
          path={groupRoutes.list}
          component={GroupsRoutes}
          noLayout
        />

        <ProtectedRoute
          exact
          path={adminRoutes.admin_organization()}
          component={AdminOrganizationDetails}
          isAdmin
        />
        <ProtectedRoute
          exact
          path={adminRoutes.admin}
          component={AdminPage}
          isAdmin
        />
        <ProtectedRoute
          exact
          path={adminRoutes.admin_users}
          component={AdminUsers}
          isAdmin
        />
        <ProtectedRoute
          exact
          path={adminRoutes.admin_users_new}
          component={AdminUserNew}
          isAdmin
        />
        <ProtectedRoute
          exact
          path={adminRoutes.admin_user_details()}
          component={AdminUserDetails}
          isAdmin
        />
        <ProtectedRoute
          exact
          path={adminRoutes.admin_organization()}
          component={AdminOrganizations}
          isAdmin
        />
        <ProtectedRoute
          exact
          path={adminRoutes.admin_organizations}
          component={AdminOrganizations}
          isAdmin
        />
        <ProtectedRoute exact path={AuthUserRoutes.logout} />

        <AppRoute path={authRoutesList} component={RedirectAfterLogin} />

        <AppRoute
          exact
          path={sessionRoutes.startSession()}
          component={SessionRender}
          layout={ExerciseLayout}
        />
        <AppRoute
          exact
          path={sessionRoutes.session()}
          component={SessionRender}
          layout={ExerciseLayout}
        />

        <AppRoute
          path={GuestRoutes.mobile_upload}
          component={Upload}
          layout={FullWidthLayout}
        />
        <AppRoute path={publicRoutes.terms} component={TermsPrivacy} />

        {!role.isB2B && (
          <ProtectedRoute
            exact
            path={miscRoutes.subscriptions}
            component={Subscriptions}
          />
        )}
        <Redirect from="/" to={courseRoutes.list} />
        <AppRoute component={NotFound} />
      </AppSwitch>
    </>
  );
};

const RedirectAfterLogin = () => {
  const location =
    useLocation<{ requestedPath?: string; requestedState?: any }>();

  return (
    <Redirect
      to={{
        pathname: location.state?.requestedPath || courseRoutes.list,
        state: location.state?.requestedState,
      }}
    />
  );
};

export default MainRoutes;
