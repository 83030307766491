import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MaxLengthInput90 } from "../../../enums/constant";
import { AppDispatch } from "../../store";
import styled from "styled-components";
import SpinnerButton from "../Buttons/SpinnerButton";
import useInterval from "../../../helpers/hooks/useInterval";
import { resetPassword } from "../../store/authentication/authActions";
import { useParams } from "react-router";

const ScForm = styled.form`
  display: flex;
  margin: auto;
  max-width: 300px;
  flex-direction: column;
  justify-content: center;
`;

const ScInput = styled.input`
  box-sizing: border-box;
  height: 38px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  padding: 2px 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
`;

const ScErrorMessage = styled.span`
  display: flex;
  margin: 10px auto;
  justify-content: center;
`;

const ScSubmitButton = styled(SpinnerButton)`
  min-height: 40px;
  padding: 0 20px;
  border-radius: 4px;
  background-color: #f07f0e;
  font-weight: 600;
  color: white;
  &:disabled {
    cursor: not-allowed;
  }
`;

const ResetPasswordForm = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { uid } = useParams<{ uid: string }>();
  const { token } = useParams<{ token: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<boolean>(false);
  const [generalMessage, setGeneralMessage] = useState<string>("");
  const mountedRef = useRef(true);
  const [disable, setDisable] = useState(false);

  const { register, handleSubmit, getValues } = useForm<{
    new_password1: string;
    new_password2: string;
  }>();

  const handleSubmitCallback = useCallback(async () => {
    setLoading(true);
    const data = {
      uid: uid,
      token: token,
      new_password1: getValues().new_password1,
      new_password2: getValues().new_password2,
    };
    if (getValues().new_password1 !== getValues().new_password2) {
      setGeneralMessage(t("authPages.register.errors.passwordsDoNotMatch"));
      setLoading(false);
      return;
    }
    const res: any = (await dispatch(resetPassword(data))) as any;
    if (res.type === "auth/post/reset-password/rejected") {
      if (res.payload.error === "INVALID_RESET_PASSWORD_TOKEN") {
        setGeneralMessage(t("authPages.register.errors.invalidToken"));
      } else {
        setGeneralMessage(t("authPages.register.errors.passwordsRequirements"));
      }
    } else {
      setGeneralMessage(t("authPages.register.resetPasswordSuccess.label"));
      setDisable(true);
    }

    setLoading(false);
  }, [uid, token, getValues, dispatch, t]);

  useEffect(
    () => () => {
      mountedRef.current = false;
    },
    []
  );

  useInterval(
    () => {
      setRequestError(false);
    },
    requestError ? 5000 : null
  );
  useInterval(
    () => {
      setGeneralMessage("");
    },
    generalMessage ? 5000 : null
  );

  return (
    <>
      <ScForm onSubmit={handleSubmit(handleSubmitCallback)}>
        <ScInput
          className="input input-icon"
          type="password"
          autoComplete={t("authPages.register.password1.name")}
          placeholder={t("authPages.register.password1.placeholder")}
          maxLength={MaxLengthInput90}
          {...register("new_password1")}
        />
        <ScInput
          className="input input-icon"
          type="password"
          autoComplete={t("authPages.register.password2.name")}
          placeholder={t("authPages.register.password2.placeholder")}
          maxLength={MaxLengthInput90}
          {...register("new_password2")}
        />
        <ScSubmitButton isLoading={loading} type="submit" disabled={disable}>
          {t("authPages.register.sendResetPasswordBtn.label")}
        </ScSubmitButton>
        {requestError && (
          <ScErrorMessage id="general-error">
            {t("authPages.register.errors.general")}
          </ScErrorMessage>
        )}
        <ScErrorMessage>{generalMessage}</ScErrorMessage>
      </ScForm>
    </>
  );
};

export default ResetPasswordForm;
