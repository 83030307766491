import {
  TbAlignLeft,
  TbArrowsShuffle,
  TbBook2,
  TbBrandYoutube,
  TbCards,
  TbCircleCheck,
  TbColumns,
  TbColumns1,
  TbColumns2,
  TbDots,
  TbIcons,
  TbLanguage,
  TbLayoutGrid,
  TbLayoutRows,
  TbLetterCase,
  TbLicense,
  TbList,
  TbListCheck,
  TbMessageCircle2,
  TbPhoto,
  TbSlideshow,
  TbSpace,
  TbSubtask,
  TbTriangleSquareCircle,
  TbVideo,
  TbVolume,
} from "react-icons/tb";
import { DropMediaColumns } from "../../Sources/MediaPicker/context/dropMediaContext";

export type UUID = string;
export type URL = string;

export enum ConsumptionType {
  typeTheAnswer = "tta",
  strictTypeTheAnswer = "stta",
  multipleChoice = "mcq",
  multipleChoiceSemantic = "mcqSemantic",
  multipleChoiceLanguage = "mcqLanguage",
}

export interface Position {
  x: number;
  y: number;
}

export interface Dimensions extends Position {
  w: number;
  h: number;
}

export type LeafType = {
  text: string;
  // for properties like *bold: true* etc
  [key: string]: any;
};

export type LinkType = {
  id: string;
  type: "link";
  url?: string;
  sourceId?: string;
  children: LeafType[];
};

export type RichParagraph = (LeafType | LinkType)[];

// noinspection JSUnusedGlobalSymbols
export type Course = {
  id: UUID;
  author_id: UUID;
  name: string;
  items: Page[];
};

export type PageBase = {
  id: UUID;
  version: number;
  quizType?: string;
};

export type CoursePage = PageBase & {
  category: "content" | "quiz"; // | "poll";
  items: CourseItem[];
  name: string;
};

export type SlidePage = PageBase & {
  category: "slide";
  items: SlideItem[];
};

export type PollPage = PageBase & {
  category: "poll";
  items: PollItem[];
  name: string;
};

export type Page = CoursePage | SlidePage | PollPage;

export enum PageItemType {
  Heading = "heading",
  Paragraph = "paragraph",
  List = "list",
  Image = "image",
  Video = "video",
  Audio = "audio",
  Embed = "embed",

  MultipleChoice = "multipleChoice",
  ShortAnswer = "shortAnswer",
  Sorting = "sorting",
  Pairing = "pairing",
  ImageLabeling = "imageLabeling",
  FillTheGap = "fillTheGap",
  Categorisation = "categorisation",
  Crossword = "crossword",
  FlashcardsQuiz = "flashcardsQuiz",

  Explanation = "explanation",
  // Slider = "slider",

  Flashcards = "flashcards",
  Columns = "columns",

  MultipleChoicePoll = "multipleChoicePoll",
  CommentPoll = "commentPoll",

  ParagraphAutocomplete = "paragraphAutocomplete",
  ListGenerate = "listGenerate",
  ParagraphSummarize = "paragraphSummarize",
  ParagraphSimplify = "paragraphSimplify",
  ParagraphParaphrase = "paragraphParaphrase",
  ParagraphNext = "paragraphNext",
  ParagraphTranslate = "paragraphTranslate",
  ImageGenerate = "imageGenerate",
  MultipleChoiceGenerate = "multipleChoiceGenerate",
  MultipleResponseGenerate = "multipleResponseGenerate",
  PageGenerate = "pageGenerate",
  MediaUpload = "mediaUpload",
}

export const COURSE_ITEM_DATA = (t: any) =>
  ({
    [PageItemType.Paragraph]: {
      name: t("v4.item.text.text"),
      icon: TbLetterCase,
    },
    [PageItemType.Image]: {
      name: t("v4.item.image.text"),
      icon: TbPhoto,
    },
    [PageItemType.List]: {
      name: t("v4.item.list.text"),
      icon: TbList,
    },
    [PageItemType.Video]: {
      name: t("v4.item.video.text"),
      icon: TbVideo,
    },
    [PageItemType.Audio]: {
      name: t("v4.item.audio.text"),
      icon: TbVolume,
    },
    [PageItemType.Embed]: {
      name: t("v4.item.youTube.text"),
      icon: TbBrandYoutube,
    },
    [PageItemType.Columns]: {
      name: t("v4.item.columns.text"),
      icon: TbColumns2,
    },
    [PageItemType.MultipleChoice]: {
      name: t("v4.item.selectAnswers.text"),
      icon: TbListCheck,
    },
    [PageItemType.ShortAnswer]: {
      name: t("v4.item.shortAnswer.text"),
      icon: TbMessageCircle2,
    },
    [PageItemType.Sorting]: {
      name: t("v4.item.sorting.text"),
      icon: TbArrowsShuffle,
    },
    [PageItemType.Pairing]: {
      name: t("v4.item.pairing.text"),
      icon: TbSubtask,
    },
    [PageItemType.ImageLabeling]: {
      name: t("v4.item.imageLabeling.text"),
      icon: TbSlideshow,
    },
    [PageItemType.FillTheGap]: {
      name: t("v4.item.fillTheGap.text"),
      icon: TbSpace,
    },
    [PageItemType.Categorisation]: {
      name: t("v4.item.categorisation.text"),
      icon: TbIcons,
    },
    [PageItemType.Crossword]: {
      name: t("v4.item.crossword.text"),
      icon: TbLayoutGrid,
    },
    [PageItemType.Flashcards]: {
      name: t("v4.item.flashcards.text"),
      icon: TbCards,
    },
    [PageItemType.FlashcardsQuiz]: {
      name: t("v4.item.flashcards.text"),
      icon: TbCards,
    },
    [PageItemType.ParagraphAutocomplete]: {
      name: t("v4.item.autocomplete.text"),
      icon: TbAlignLeft,
    },
    [PageItemType.ParagraphSummarize]: {
      name: t("v4.item.summarize.text"),
      icon: TbDots,
    },
    [PageItemType.ParagraphSimplify]: {
      name: t("v4.item.simplify.text"),
      icon: TbTriangleSquareCircle,
    },
    [PageItemType.ParagraphParaphrase]: {
      name: t("v4.item.paraphrase.text"),
      icon: TbLicense,
    },
    [PageItemType.ParagraphNext]: {
      name: t("v4.item.nextParagraph.text"),
      icon: TbLayoutRows,
    },
    [PageItemType.ParagraphTranslate]: {
      name: t("v4.item.translate.text"),
      icon: TbLanguage,
    },
    [PageItemType.MultipleChoiceGenerate]: {
      name: t("v4.item.multipleChoice.text"),
      icon: TbCircleCheck,
    },
    [PageItemType.MultipleResponseGenerate]: {
      name: t("v4.item.multipleResponse.text"),
      icon: TbListCheck,
    },
    [PageItemType.Explanation]: {
      name: t("v4.item.explanation.text"),
      icon: TbBook2,
    },

    [PageItemType.MultipleChoicePoll]: {
      name: t("v4.item.selectAnswersPoll.text"),
      icon: TbListCheck,
    },
    [PageItemType.CommentPoll]: {
      name: t("v4.item.comment.text"),
      icon: TbMessageCircle2,
    },

    [DropMediaColumns.OneColumn]: {
      name: t("v4.library.tableSelection.oneColumn"),
      icon: TbColumns1,
    },
    [DropMediaColumns.TwoColumns]: {
      name: t("v4.library.tableSelection.tablePairs"),
      icon: TbColumns,
    },
  } as const);

export type ContentItem =
  | Paragraph
  | List
  | Image
  | Video
  | Audio
  | Embed
  | Flashcards
  | Columns;

export type SlideItem = SlideContentItem | SlideGenerateItem;

export type SlideContentItem =
  | SlideParagraph
  | SlideList
  | SlideImage
  | SlideVideo
  | SlideAudio
  | SlideEmbed;

export type SlideGenerateItem =
  | SlideParagraphAutocomplete
  | SlideListGenerate
  | SlideParagraphSummarize
  | SlideParagraphSimplify
  | SlideParagraphParaphrase
  | SlideParagraphNext
  | SlideParagraphTranslate
  | SlideMediaUpload;

export type QuizItem =
  | MultipleChoice
  | ShortAnswer
  | Sorting
  | Pairing
  | ImageLabeling
  | FillTheGap
  | Explanation
  | Categorisation
  | Crossword
  | FlashcardsQuiz;

export type PollItem = MultipleChoicePoll | CommentPoll;

// export type PollItem = MultipleChoicePoll | ShortAnswerPoll | SliderPoll;

export type GenerateItem =
  | ParagraphAutocomplete
  | ParagraphSummarize
  | ParagraphSimplify
  | ParagraphParaphrase
  | ParagraphNext
  | ParagraphTranslate
  | MultipleChoiceGenerate
  | MultipleResponseGenerate
  | PageGenerate
  | MediaUpload;

export type Item = CourseItem | SlideItem;
export type CourseItem = ContentItem | QuizItem | GenerateItem | PollItem;

export type CropSize = {
  w: number;
  h: number;
  x: number;
  y: number;
  sizeX: number;
  sizeY: number;
};

type AllOrNone<T> = T | { [K in keyof T]?: never };

export type ImageData = {
  type: PageItemType.Image;
  data: {
    src: URL;
    sourceId?: string;
    page?: number;
  } & AllOrNone<CropSize>;
};

export type VideoData = {
  type: PageItemType.Video;
  data: {
    sourceId: string;
    src: URL;
    // range: [number, number];
  };
};

export type AudioData = {
  type: PageItemType.Audio;
  data: {
    sourceId: string;
    src: URL;
    // range: [number, number];
  };
};

export type ContentField = {
  id: UUID;
  text: string;
  media?: ImageData | VideoData | AudioData;
};

/* --- CONTENT --- */

export type Paragraph = {
  id: UUID;
  category: "content";
  type: PageItemType.Paragraph;
  data: RichParagraph[];
  align: "left" | "center" | "right";
};

export type List = {
  id: UUID;
  category: "content";
  type: PageItemType.List;
  data: RichParagraph[];
  variant: "bullet" | "number";
};

export type Image = {
  id: UUID;
  category: "content";
  attribution?: string;
  width?: number;
} & ImageData;

export type Video = {
  id: UUID;
  category: "content";
} & VideoData;

export type Audio = {
  id: UUID;
  category: "content";
} & AudioData;

export type Embed = {
  id: UUID;
  category: "content";
  type: PageItemType.Embed;
  data: URL;
};

export type Flashcards = {
  id: UUID;
  category: "content";
  type: PageItemType.Flashcards;
  items: [ContentField, ContentField][];
};

export type Columns = {
  id: UUID;
  category: "content";
  type: PageItemType.Columns;
  items: { id: UUID; data: ContentItem[] }[];
};

/* --- SLIDE --- */

export type VerticalAlign = "top" | "center" | "bottom";
export type SlideParagraph = {
  id: UUID;
  position: Dimensions;
  category: "slide";
  type: PageItemType.Paragraph;
  data: RichParagraph[];
  align: "left" | "center" | "right";
  verticalAlign: VerticalAlign;
  // used for auto editing
  focus?: true;
};

export type SlideList = {
  id: UUID;
  position: Dimensions;
  category: "slide";
  type: PageItemType.List;
  data: RichParagraph[];
  variant: "bullet" | "number";
  verticalAlign: VerticalAlign;
};

export type ObjectFit = "cover" | "contain" | "fill";
export type SlideImage = {
  id: UUID;
  position: Dimensions;
  category: "slide";
  fit: ObjectFit;
  attribution?: string;
} & ImageData;

export type SlideVideo = {
  id: UUID;
  position: Dimensions;
  category: "slide";
} & VideoData;

export type SlideAudio = {
  id: UUID;
  position: Dimensions;
  category: "slide";
} & AudioData;

export type SlideEmbed = {
  id: UUID;
  position: Dimensions;
  category: "slide";
  type: PageItemType.Embed;
  data: URL;
};

/* --- SLIDE GENERATE --- */

export type SlideMediaUpload = {
  id: UUID;
  position: Dimensions;
  category: "slideGenerate";
  type: PageItemType.MediaUpload;
  mediaType: PageItemType.Image | PageItemType.Video | PageItemType.Audio;
  uploadId: string;
};

export type SlideParagraphAutocomplete = {
  id: UUID;
  position: Dimensions;
  category: "slideGenerate";
  type: PageItemType.ParagraphAutocomplete;
  data: SlideParagraph;
};

export type SlideListGenerate = {
  id: UUID;
  position: Dimensions;
  category: "slideGenerate";
  type: PageItemType.ListGenerate;
  text?: string;
};

export type SlideParagraphSummarize = {
  id: UUID;
  position: Dimensions;
  category: "slideGenerate";
  type: PageItemType.ParagraphSummarize;
  data: string;
};

export type SlideParagraphSimplify = {
  id: UUID;
  position: Dimensions;
  category: "slideGenerate";
  type: PageItemType.ParagraphSimplify;
  data: string;
};

export type SlideParagraphParaphrase = {
  id: UUID;
  position: Dimensions;
  category: "slideGenerate";
  type: PageItemType.ParagraphParaphrase;
  data: string;
};

export type SlideParagraphNext = {
  id: UUID;
  position: Dimensions;
  category: "slideGenerate";
  type: PageItemType.ParagraphNext;
  data: string;
};

export type SlideParagraphTranslate = {
  id: UUID;
  position: Dimensions;
  category: "slideGenerate";
  type: PageItemType.ParagraphTranslate;
  language: string;
  data: string;
  list?: List["variant"];
};

/* --- QUIZ --- */

export type MultipleChoice = {
  id: UUID;
  category: "quiz";
  type: PageItemType.MultipleChoice;
  multi: boolean;
  items: (ContentField & { correct: boolean })[];
  instructions: ContentItem[];
};

export type ShortAnswer = {
  id: UUID;
  category: "quiz";
  type: PageItemType.ShortAnswer;
  items: string[];
  instructions: ContentItem[];
  consumptionType:
    | ConsumptionType.typeTheAnswer
    | ConsumptionType.strictTypeTheAnswer;
};

export type Sorting = {
  id: UUID;
  category: "quiz";
  type: PageItemType.Sorting;
  items: ContentField[];
  instructions: ContentItem[];
};

export type Pairing = {
  id: UUID;
  category: "quiz";
  type: PageItemType.Pairing;
  items: [ContentField, ContentField][];
  instructions: ContentItem[];
};

export type ImageLabeling = {
  id: UUID;
  category: "quiz";
  type: PageItemType.ImageLabeling;
  data?: ImageData["data"];
  size: number;
  instructions: ContentItem[];
  items: {
    id: UUID;
    text: string;
    w: number;
    h: number;
    x: number;
    y: number;
    angle: number;
  }[];
  showOptions: boolean;
  consumptionType:
    | ConsumptionType.strictTypeTheAnswer
    | ConsumptionType.multipleChoice;
};

export type FillTheGap = {
  id: UUID;
  category: "quiz";
  type: PageItemType.FillTheGap;
  data: string;
  instructions: ContentItem[];
  showOptions: boolean;
} & (
  | {
      items: Record<
        UUID,
        { text: string; hint: string; options?: never; generating?: never }
      >;
      consumptionType:
        | ConsumptionType.typeTheAnswer
        | ConsumptionType.strictTypeTheAnswer;
    }
  | {
      items: Record<
        UUID,
        { text: string; hint: string; options?: string[]; generating?: boolean }
      >;
      consumptionType:
        | ConsumptionType.multipleChoiceSemantic
        | ConsumptionType.multipleChoiceLanguage;
    }
);

export type Categorisation = {
  id: UUID;
  category: "quiz";
  type: PageItemType.Categorisation;
  items: (ContentField & { items: ContentField[] })[];
  instructions: ContentItem[];
};

export type Crossword = {
  id: UUID;
  category: "quiz";
  type: PageItemType.Crossword;
  items: {
    [answer: string]: {
      text: string;
      media?: ImageData | VideoData | AudioData;
      position?: {
        index: number;
        x: number;
        y: number;
        orientation: "down" | "across";
      };
    };
  };
  instructions: ContentItem[];
};

export type FlashcardsQuiz = {
  id: UUID;
  category: "quiz";
  type: PageItemType.FlashcardsQuiz;
  items: [ContentField, ContentField][];
  instructions: ContentItem[];
  consumptionType:
    | ConsumptionType.strictTypeTheAnswer
    | ConsumptionType.multipleChoice;
};

export type Explanation = {
  id: UUID;
  category: "quiz";
  type: PageItemType.Explanation;
  instructions: ContentItem[];
};

/* --- POLL --- */

export type MultipleChoicePoll = {
  id: UUID;
  category: "poll";
  type: PageItemType.MultipleChoicePoll;
  multi: boolean;
  items: ContentField[];
  instructions: ContentItem[];
};

export type CommentPoll = {
  id: UUID;
  category: "poll";
  type: PageItemType.CommentPoll;
  instructions: ContentItem[];
};

/* -- PLACEHOLDERS -- */
export type ParagraphAutocomplete = {
  id: UUID;
  category: "generate";
  type: PageItemType.ParagraphAutocomplete;
  data: Paragraph;
};

export type ParagraphSummarize = {
  id: UUID;
  category: "generate";
  type: PageItemType.ParagraphSummarize;
  data: string;
};

export type ParagraphSimplify = {
  id: UUID;
  category: "generate";
  type: PageItemType.ParagraphSimplify;
  data: string;
};

export type ParagraphParaphrase = {
  id: UUID;
  category: "generate";
  type: PageItemType.ParagraphParaphrase;
  data: string;
};

export type ParagraphNext = {
  id: UUID;
  category: "generate";
  type: PageItemType.ParagraphNext;
  data: string;
};

export type ParagraphTranslate = {
  id: UUID;
  category: "generate";
  type: PageItemType.ParagraphTranslate;
  language: string;
  data: RichParagraph[];
  list?: List["variant"];
};

export type MultipleChoiceGenerate = {
  id: UUID;
  category: "generate";
  type: PageItemType.MultipleChoiceGenerate;
  data: string;
};

export type MultipleResponseGenerate = {
  id: UUID;
  category: "generate";
  type: PageItemType.MultipleResponseGenerate;
  data: string;
};

export type PageGenerate = {
  id: UUID;
  category: "generate";
  type: PageItemType.PageGenerate;
  data: {
    text?: string;
    sourceId: string;
    questions?: number;
    summarize?: boolean;
  };
};

export type MediaUpload = {
  id: UUID;
  category: "generate";
  type: PageItemType.MediaUpload;
  mediaType: PageItemType.Image | PageItemType.Video | PageItemType.Audio;
  uploadId: string;
};

export default null;

export type GridItem = {
  i: string;
  static?: boolean;
  minW?: number;
} & Dimensions;
