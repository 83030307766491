import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { NewButton } from "app/components/Buttons/NewButton";
import { courseRoutes, guestCourseRoutes } from "enums/routes";
import {
  TbArrowLeft,
  TbArrowRight,
  TbCheck,
  TbChevronLeft,
  TbChevronRight,
  TbCornerLeftUp,
  TbRefresh,
} from "react-icons/tb";
import { getCourse, getGuestCourse } from "api/course/courseAPI";
import { Trans, useTranslation } from "react-i18next";
import { CgSpinner } from "react-icons/cg";
import {
  ConsumptionContextProvider,
  ConsumptionStoreContext,
} from "app/components/Exercises/CourseEdit/render/ConsumptionContext";
import { SlideRender } from "app/components/Exercises/CourseEdit/slide/SlideRender";
import { CoursePageRender } from "app/components/Exercises/CourseEdit/render/PageRender";
import { GlobalFlexStyles } from "app/components/Helpers/GlobalFlexStyles";
import CountUp from "react-countup";
import {
  getCourseGuestPageResult,
  getCoursePageResult,
  postValidateCourse,
  postValidateGuestCourse,
} from "api/course/courseConsumptionAPI";
import { updatePageSchema } from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  OrganizationLogo,
  ScrollResponsiveHeader,
} from "app/components/Header";
import UserMenu, { GuestMenu } from "app/components/UserMenu";
import { toast } from "react-hot-toast";
import {
  CourseIcon,
  CourseSlideThumbnail,
} from "app/pages/courses/Edit/CoursePanel";
import classNames from "classnames";
import { COURSE_ITEMS } from "app/components/Exercises/CourseEdit/items/InsertPageItem";
import { roundTo } from "helpers/roundTo";
import { CoursePageResults } from "app/components/Exercises/CourseEdit/results/PageResults";
import { FaFlagCheckered } from "react-icons/fa";
import { useHistory } from "react-router";
import { ifExistsThen } from "helpers/ifExistsThen";
import { BigModal, BigModalBody } from "../../components/BigModal";

export const ConsumptionHeader = () => {
  const { t } = useTranslation();
  const { guestPin, courseId } =
    useParams<{ guestPin?: string; courseId: string }>();

  return (
    <ScrollResponsiveHeader className="bg-white shrink-0 h-12 border-b border-gray-100 flex items-center text-gray-600 sticky top-0 left-0 w-full z-10">
      <Link
        to={!guestPin ? courseRoutes.list : guestCourseRoutes.list(guestPin)}
        className="px-3 h-full hover:bg-gray-100 transition flex items-center justify-center"
      >
        <OrganizationLogo />
      </Link>
      <Link
        to={
          !guestPin
            ? courseRoutes.test(courseId)
            : guestCourseRoutes.test(guestPin, courseId)
        }
        className="h-12 border-x border-gray-200 flex items-center px-3 pr-4 gap-2 cursor-pointer hover:bg-gray-100 text-gray-500 font-bold transition"
      >
        <TbCornerLeftUp className="text-2xl" /> {t("v4.training.backTo")}
      </Link>
      <div className="flex-grow" />

      {!guestPin ? <UserMenu /> : <GuestMenu />}
    </ScrollResponsiveHeader>
  );
};

export const CoursePageTest = () => {
  const { t } = useTranslation();
  const location = useLocation<{ redo?: boolean }>();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(true);
  const [redo, setRedo] = useState(!!location.state?.redo);
  const { guestPin, courseId, pageId } =
    useParams<{ guestPin?: string; courseId: string; pageId: string }>();

  useEffect(() => {
    if (location.state?.redo)
      history.replace(
        !guestPin
          ? courseRoutes.pageTest(courseId, pageId)
          : guestCourseRoutes.pageTest(guestPin, courseId, pageId)
      );
  }, [!!location.state?.redo]);

  const submitContentMutation = useMutation(
    (items: any = {}) =>
      !guestPin
        ? postValidateCourse(pageId, items)
        : postValidateGuestCourse(guestPin, pageId, items),
    {
      onSuccess: () => {
        course.refetch();
        if (redo) {
          setRedo(false);
          query.remove();
        } else {
          query.refetch();
        }
      },
      onError: (err: any) => {
        const error = err?.response?.data?.error;
        if (error === "MULTIPLE_ATTEMPTS_FORBIDDEN") {
          toast.error(t("v4.assignment.attempts.error"));
        }
      },
    }
  );

  const course = useQuery({
    queryFn: () =>
      !guestPin
        ? getCourse(courseId, { results: true })
        : getGuestCourse(guestPin, courseId, { results: true }),
    queryKey: !guestPin
      ? ["course", "test", courseId]
      : ["guestCourse", guestPin, "test", courseId],
    refetchOnWindowFocus: false,
    enabled: !!courseId,
  });

  const query = useQuery({
    queryFn: () =>
      !guestPin
        ? getCoursePageResult(pageId)
        : getCourseGuestPageResult(guestPin, pageId),
    queryKey: !guestPin
      ? ["course", "test", "page", pageId]
      : ["guestCourse", guestPin, "test", "page", pageId],
    refetchOnWindowFocus: false,
    enabled: !!courseId && !!pageId,
    onSuccess: (data) => {
      if (
        !data.results &&
        data?.page?.category !== "quiz" &&
        data?.page?.category !== "poll"
      )
        submitContentMutation.mutate(undefined);
    },
    initialData: ifExistsThen(
      course?.data?.pages?.find(({ id }) => id === pageId),
      (page) => ({ page })
    ),
  });

  const isClosed =
    course.data?.status === "closed" ||
    (course.data?.is_single_attempt && query.data?.results);

  const pageData = useMemo(
    () =>
      ifExistsThen(
        query.data?.page ?? course.data?.pages?.find(({ id }) => id === pageId),
        updatePageSchema
      ),
    [query.data, course.data]
  );

  useEffect(() => {
    setCollapsed(true);
  }, [pageId]);

  const percentage = pageData?.score ?? query.data?.percentage;

  return (
    <div className="absolute w-full h-full left-0 top-0 flex flex-col bg-gray-100">
      <GlobalFlexStyles />

      <div className="grow flex flex-col">
        <ConsumptionHeader />
        <div className="grow relative flex">
          <div className="fixed z-10 p-1 lg:hidden">
            <NewButton
              size="lg"
              variant="primary"
              color="bg-gray-400 text-white"
              iconOnly
              className="lg:hidden"
              onClick={() => setCollapsed(false)}
            >
              <TbChevronRight />
            </NewButton>
          </div>
          <div
            className={classNames(
              "w-[300px] border-r border-gray-200 shadow-2xl fixed lg:relative lg:shadow-none h-full z-10 lg:z-0 bg-white transform transition",
              collapsed && "-translate-x-full lg:translate-x-0"
            )}
          >
            <div className="absolute-cover flex flex-col">
              <NewButton
                size="lg"
                variant="primary"
                color="bg-gray-400 text-white"
                iconOnly
                className="lg:hidden ml-auto my-1 mr-1"
                onClick={() => setCollapsed(true)}
              >
                <TbChevronLeft />
              </NewButton>
              <div className="flex flex-col grow p-2 overflow-y-auto gap-0.5">
                {!course.isSuccess ? (
                  <CgSpinner className="animate-spin m-auto text-2xl text-gray-400" />
                ) : (
                  course.data.pages.map(
                    ({
                      category,
                      quiz_type,
                      name,
                      items,
                      id,
                      result_id,
                      score,
                    }) => {
                      const active = id === pageId;
                      return (
                        <Link
                          key={id}
                          to={
                            !guestPin
                              ? courseRoutes.pageTest(courseId, id)
                              : guestCourseRoutes.pageTest(
                                  guestPin,
                                  courseId,
                                  id
                                )
                          }
                          onClick={() => setRedo(false)}
                          className={classNames(
                            "rounded-xl items-center w-full transition p-1.5 flex text-gray-600",
                            category !== "slide" && "pr-3",
                            active
                              ? "bg-gray-500 bg-opacity-10"
                              : "cursor-pointer bg-gray-500 bg-opacity-0 hover:bg-opacity-10"
                          )}
                        >
                          {!result_id ? (
                            <div className="bg-gray-200 text-gray-500 text-xl leading-none rounded-full min-h-[1.75rem] min-w-[2.5rem] flex items-center justify-center text-center px-2">
                              -
                            </div>
                          ) : category !== "quiz" ? (
                            <div className="bg-primary text-white px-2 py-0.5 rounded-full min-h-[1.75rem] min-w-[2.5rem] flex items-center justify-center">
                              <TbCheck className="text-base" strokeWidth={3} />
                            </div>
                          ) : (
                            <div className="bg-primary text-white text-sm font-bold px-2 py-0.5 rounded-full min-h-[1.75rem] min-w-[2.5rem] flex items-center justify-center">
                              {roundTo(score * 100, 0)}
                              <span className="text-xs">%</span>
                            </div>
                          )}

                          <CourseIcon category={category} quizType={quiz_type}>
                            {(Icon, color) => (
                              <div
                                className={classNames(
                                  "shrink-0 w-6 h-6 mx-2 rounded-lg flex items-center justify-center",
                                  color
                                )}
                              >
                                <Icon />
                              </div>
                            )}
                          </CourseIcon>
                          {category === "slide" ? (
                            <div className="aspect-video relative max-w-[6rem] mr-auto bg-white w-full border border-gray-100 rounded-xl overflow-hidden pointer-events-none">
                              <CourseSlideThumbnail items={items} />
                            </div>
                          ) : !quiz_type || !COURSE_ITEMS(t)?.[quiz_type] ? (
                            <div className="grow whitespace-nowrap overflow-x-clip text-ellipsis select-none">
                              {name}
                            </div>
                          ) : name ? (
                            <div className="flex flex-col leading-none grow whitespace-nowrap select-none  min-w-0">
                              <div className="font-bold overflow-x-clip text-ellipsis">
                                {name}
                              </div>
                              <div className="text-xs text-gray-500 overflow-x-clip text-ellipsis">
                                {COURSE_ITEMS(t)[quiz_type].name}
                              </div>
                            </div>
                          ) : (
                            <div className="flex flex-col leading-none grow whitespace-nowrap overflow-x-clip text-ellipsis select-none min-w-0 font-bold">
                              {COURSE_ITEMS(t)[quiz_type].name}
                            </div>
                          )}
                        </Link>
                      );
                    }
                  )
                )}
              </div>
            </div>
          </div>

          <div className="grow flex relative">
            <div className="absolute-cover flex flex-col overflow-y-auto">
              {!pageData?.items ? (
                <div className="m-auto">
                  <CgSpinner className="m-8 text-2xl text-gray-400 animate-spin" />
                </div>
              ) : pageData.category === "slide" ? (
                <div className="grow relative overflow-hidden px-4">
                  <SlideRender page={pageData} />
                </div>
              ) : pageData.category === "quiz" &&
                !!query.data?.results &&
                !redo ? (
                <div className="my-4 max-w-4xl w-full mx-auto flex flex-col gap-4 px-4">
                  {percentage != null && (
                    <div className="bg-green-500 w-full px-6 py-10 gap-2 rounded-xl text-white flex flex-col text-center items-center justify-center relative">
                      <FaFlagCheckered
                        className="absolute-cover px-[10%] opacity-10"
                        preserveAspectRatio="xMidYMid slice"
                      />
                      <div className="font-bold text-2xl">
                        {t("liveSession.score")}
                      </div>
                      <div className="bg-white rounded-lg px-6 py-3 text-3xl font-bold text-green-500">
                        <CountUp
                          end={Math.round(percentage * 100)}
                          duration={2}
                        />
                        %
                      </div>
                      {!isClosed && (
                        <NewButton
                          color="bg-white text-white"
                          className="relative font-bold text-lg"
                          variant="bg-opacity-30 hover:bg-opacity-50"
                          onClick={() => setRedo(true)}
                        >
                          <TbRefresh className="!text-xl" />
                          {t("v4.assignment.redo")}
                        </NewButton>
                      )}
                    </div>
                  )}
                  <div className="bg-white w-full mx-auto flex flex-col rounded-2xl">
                    <CoursePageResults
                      results={query.data.results}
                      items={pageData.items}
                    />
                  </div>
                </div>
              ) : pageData.category === "poll" && !!query.data?.results ? (
                <div className="my-4 max-w-4xl w-full mx-auto flex flex-col gap-4 px-4">
                  <div className="bg-white w-full mx-auto flex flex-col rounded-2xl">
                    <CoursePageResults
                      results={query.data.results}
                      items={pageData.items}
                    />
                  </div>
                </div>
              ) : !pageData?.items ? (
                <div className="m-auto">
                  <CgSpinner className="m-8 text-2xl text-gray-400 animate-spin" />
                </div>
              ) : (
                <ConsumptionContextProvider items={pageData.items}>
                  <div className="my-4 max-w-4xl w-full mx-auto flex flex-col gap-4 px-4">
                    <div className="bg-white w-full mx-auto flex flex-col rounded-2xl">
                      <CoursePageRender items={pageData.items} />
                    </div>
                  </div>
                  {(pageData.category === "quiz" ||
                    pageData.category === "poll") && (
                    <ConsumptionBar
                      pages={course.data?.pages}
                      submit={!isClosed && submitContentMutation}
                      singleAttempt={!!course.data?.is_single_attempt}
                    />
                  )}
                </ConsumptionContextProvider>
              )}

              {((pageData?.category !== "quiz" &&
                pageData?.category !== "poll") ||
                !!query.data?.results) &&
                !redo && <ConsumptionBar pages={course.data?.pages} />}
            </div>
          </div>
          <div
            onClick={() => setCollapsed(true)}
            className={classNames(
              "absolute-cover !fixed bg-black bg-opacity-50 transition lg:pointer-events-none",
              collapsed
                ? "opacity-0 pointer-events-none"
                : "opacity-100 lg:opacity-0"
            )}
          />
        </div>
      </div>
    </div>
  );
};

const ConsumptionBar = ({
  submit,
  pages,
  singleAttempt,
}: {
  submit?: any;
  pages?: any[];
  singleAttempt?: boolean;
}) => {
  const { t } = useTranslation();
  const consumptionStore = useContext(ConsumptionStoreContext);
  const { guestPin, courseId, pageId } =
    useParams<{ guestPin?: string; courseId: string; pageId: string }>();
  const [disabledButton, setDisabledButton] = useState(false);
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    if (!submit) return;
    setDisabledButton(true);
    const t = setTimeout(() => {
      setDisabledButton(false);
    }, 500);

    return () => {
      if (t) clearTimeout(t);
    };
  }, [pageId, !submit]);

  const nextPageId = useMemo(() => {
    if (!pages) return null;
    const index = pages.findIndex((item) => item.id === pageId) + 1;

    if (index >= pages.length) return null;
    return pages[index].id;
  }, [pageId, pages]);

  const previousPageId = useMemo(() => {
    if (!pages) return null;
    const index = pages.findIndex((item) => item.id === pageId) - 1;

    if (index < 0) return null;
    return pages[index].id;
  }, [pageId, pages]);

  if (!previousPageId && !nextPageId && !submit) return null;

  return (
    <div className="bg-white border-t border-gray-100 p-1 flex items-center mt-auto sticky bottom-0 gap-1">
      {!!previousPageId && (
        <NewButton
          component={Link}
          to={
            !guestPin
              ? courseRoutes.pageTest(courseId, previousPageId)
              : guestCourseRoutes.pageTest(guestPin, courseId, previousPageId)
          }
        >
          <TbArrowLeft /> {t("v4.generic.back")}
        </NewButton>
      )}
      <div className="flex-grow" />
      {!!nextPageId && (
        <NewButton
          component={Link}
          to={
            !guestPin
              ? courseRoutes.pageTest(courseId, nextPageId)
              : guestCourseRoutes.pageTest(guestPin, courseId, nextPageId)
          }
        >
          {t("v4.generic.next")} <TbArrowRight />
        </NewButton>
      )}
      {submit && (
        <NewButton
          variant="primary"
          disabled={submit.isLoading || disabledButton}
          onClick={() => {
            if (submit.isLoading) return;
            if (singleAttempt) {
              setConfirm(true);
              return;
            }
            submit.mutate(consumptionStore?.getState()?.items ?? {});
          }}
        >
          {submit.isLoading ? (
            <CgSpinner className="animate-spin" />
          ) : (
            <TbCheck />
          )}
          {t("v4.generic.submit")}
        </NewButton>
      )}
      {singleAttempt && (
        <BigModal fit open={confirm || submit.isLoading}>
          <BigModalBody className="text-gray-600 p-8 gap-4 max-w-lg" maxWidth>
            <div className="text-2xl font-bold">
              {"⚠️ "}
              {t("v4.assignment.attempts.singleAttemptWarning1")}
            </div>
            <p className="text-gray-500">
              <Trans
                defaults={`You can attempt this quiz <b>only once</b>.`}
                // i18nKey="v4.welcomeMessage.freeBody"
                components={{ b: <b /> }}
              />
            </p>
            <p className="text-gray-500">
              <Trans
                defaults={`Make sure to <b>double-check your answers</b> before submitting.`}
                // i18nKey="v4.welcomeMessage.freeBody"
                components={{ b: <b /> }}
              />
            </p>
            <div className="flex flex-col-reverse sm:flex-row justify-end gap-1 font-bold mt-2">
              <NewButton
                size="lg"
                center
                variant="transparent"
                disabled={submit.isLoading}
                onClick={() => setConfirm(false)}
              >
                {t("v4.generic.cancel")}
              </NewButton>
              <NewButton
                size="lg"
                center
                variant="primary"
                disabled={submit.isLoading}
                onClick={() => {
                  if (submit.isLoading) return;
                  submit.mutate(consumptionStore?.getState()?.items ?? {});
                  setConfirm(false);
                }}
              >
                {submit.isLoading ? (
                  <CgSpinner className="animate-spin" />
                ) : (
                  <TbCheck />
                )}{" "}
                {t("v4.generic.submit")}
              </NewButton>
            </div>
          </BigModalBody>
        </BigModal>
      )}
    </div>
  );
};
