import { useTranslation } from "react-i18next";
import {
  useSourceUploadContext,
  useSourceUploadStore,
  WSStatus,
} from "api/ws/SourceUploadContext";
import React, { useEffect, useMemo } from "react";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbArrowBackUp, TbX } from "react-icons/tb";
import { CgSpinner } from "react-icons/cg";
import { QRCode } from "react-qrcode-logo";
import { SelectOnlyType } from "app/components/Sources/MediaPicker/MediaPicker";
import { useSelectOnly } from "app/components/Sources/MediaPicker/context/selectOnlyContext";
import { useRole } from "app/hooks/useRole";

export const MobileUpload = ({
  close,
  back,
  setFile,
}: {
  close: () => void;
  back: () => void;
  setFile: (file: string | null) => void;
}) => {
  const { t } = useTranslation();
  const connected = useSourceUploadStore(
    (store) => store.status === WSStatus.Connected
  );
  const qr = useSourceUploadStore((state) => state.qr);
  const { getQr, listenQr } = useSourceUploadContext();
  const selectOnly = useSelectOnly();
  const role = useRole();

  useEffect(() => {
    listenQr((uploadId) => setFile(uploadId));
    return () => {
      listenQr(null);
    };
  }, []);

  const link = useMemo(() => {
    if (!qr) return null;
    return `${window.location.origin.toString()}/upload/${qr}`;
  }, [qr]);

  useEffect(() => {
    if (!role.prodMode) console.log(link);
  }, [link]);

  useEffect(() => {
    if (connected) {
      getQr();
    }
  }, [connected]);

  return (
    <>
      <div className="p-1 font-bold border-b-2 border-gray-100 flex items-center gap-2 text-gray-500">
        {selectOnly !== SelectOnlyType.none && (
          <NewButton onClick={back} size="sm">
            <TbArrowBackUp />
            {t("v4.generic.back")}
          </NewButton>
        )}

        <div className="font-bold text-gray-500">
          {t("v4.library.upload.text")}
        </div>
        <NewButton
          iconOnly
          onClick={close}
          className="ml-auto"
          variant="transparent"
        >
          <TbX />
        </NewButton>
      </div>
      <div className="grow relative">
        <div className="absolute-cover overflow-y-auto">
          <div className="p-8 gap-6 flex flex-col justify-center items-center min-h-full">
            <div className="font-bold text-2xl text-gray-600">
              {t("v4.library.upload.qrPrompt")}
            </div>
            <div className="bg-gray-200 rounded-2xl w-44 h-44 flex items-center justify-center [&>canvas]:rounded-xl">
              {link ? (
                <QRCode value={link} size={128} />
              ) : (
                <CgSpinner className="animate-spin text-3xl text-gray-400" />
              )}
            </div>
            <div className="text-gray-400">
              {t("v4.library.upload.qrOneUse")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
