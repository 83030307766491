import React, { useEffect, useMemo, useState } from "react";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { groupRoutes } from "enums/routes";
import {
  TbClipboardText,
  TbCopy,
  TbLock,
  TbLockOpen,
  TbPlus,
  TbSend,
  TbTrash,
  TbUsers,
} from "react-icons/tb";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { ContextMenu } from "app/components/ContextMenu";
import Loader from "app/components/Loader";
import { useHistory, useParams } from "react-router";
import styled, { css } from "styled-components";
import { useRole } from "app/hooks/useRole";
import { SharedGroup } from "./components/SharedGroup";
import { NewGroup } from "app/pages/groups/components/NewGroup";
import {
  deleteGroup,
  getGroupsList,
  postDuplicateGroup,
  postJoinGroup,
} from "api/groups/groupsAPI";
import { InView } from "react-intersection-observer";
import classNames from "classnames";
import { NewButton } from "app/components/Buttons/NewButton";
import { FaStar } from "react-icons/fa";
import WarningModal from "app/components/Modal/WarningModal";
import { translateError } from "app/components/Exercises/Edit/missingTranslation";
import { Tooltip } from "app/components/Tooltip";

const Skeleton = () => (
  <div className="flex flex-col">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {[...new Array(20)].map((_, i) => (
        <div
          key={i}
          className="rounded-lg h-32 bg-gray-200 animate-pulse"
          style={{ animationDelay: i * 0.2 + "s" }}
        />
      ))}
    </div>
  </div>
);

export const useGroupJoin = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(postJoinGroup, {
    onSuccess: (data) => {
      history.push(groupRoutes.details(data.group_id));
      toast.success(t("v4.group.joinSuccess"));
      queryClient.invalidateQueries(["groups"]);
    },
    onError: () => {
      toast.error(translateError(t("v4.group.joinError"), t));
    },
  });
};

export const GroupsList = () => {
  const { t } = useTranslation();

  const role = useRole();
  const { data, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ["groups"],
      ({ pageParam: page = 0 }) => getGroupsList({ page }),
      {
        refetchOnWindowFocus: false,
        getNextPageParam: ({ groups_total }, pages) =>
          groups_total > pages.length * 20 ? pages.length : undefined,
      }
    );

  const list = useMemo(
    () =>
      (data?.pages || []).reduce(
        (list, group) => [...list, ...group.groups],
        []
      ),
    [data?.pages]
  );

  const [newOpen, setNewOpen] = useState(false);
  const params = useParams<{ pin?: string }>();

  const joinByPin = useGroupJoin();

  useEffect(() => {
    if (!params?.pin || joinByPin.isLoading) return;
    joinByPin.mutate(params.pin);
  }, []);

  return (
    <div className="py-4 px-3 sm:px-8">
      {role.teacher && (
        <div className="flex justify-end items-center gap-2 mb-4">
          <NewButton
            size="lg"
            variant="primary"
            onClick={() => setNewOpen(true)}
          >
            <TbPlus /> {t("v4.group.new")}
          </NewButton>
        </div>
      )}
      {!isSuccess ? (
        <Skeleton />
      ) : (
        <>
          <div className="flex flex-col">
            {!list.length ? (
              <div className="text-xl text-center text-gray-500 py-5">
                {t("v4.group.noItems")}
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {list.map((item) => (
                  <GroupItem key={item.id} item={item} />
                ))}
              </div>
            )}
            {hasNextPage && (
              <InView
                as="div"
                className="flex"
                onChange={(inView) =>
                  inView && !isFetchingNextPage && fetchNextPage()
                }
                key={data.pages.length}
              >
                {({ inView, ref }) => (
                  <div
                    ref={ref}
                    className={classNames(
                      "flex flex-col divide-gray-100 transition",
                      !inView && "opacity-0"
                    )}
                  >
                    <Skeleton />
                  </div>
                )}
              </InView>
            )}
          </div>
        </>
      )}
      <NewGroup open={newOpen} close={() => setNewOpen(false)} />
    </div>
  );
};

export const ItemContainer = styled.div<{ $color?: string }>`
  border-style: solid;
  border-width: 3px;
  border-left-width: 5px;
  ${(p) =>
    p.$color &&
    css`
      border-color: ${p.$color}33;
      border-left-color: ${p.$color};
      transition: border-color 0.2s ease;

      &:hover {
        border-color: ${p.$color};
      }

      &:hover > a:first-of-type {
        background-color: ${p.$color}11;
      }

      > a:first-of-type {
        background-color: ${p.$color}00;
      }
    `}
`;

const GroupItem = ({ item }: { item: any }) => {
  const { t } = useTranslation();
  const role = useRole();

  return (
    <ItemContainer
      className="outline-gray-300 px-6 py-5 rounded-lg shadow-2xl transition relative group user-select-none bg-white text-gray-600 w-full"
      $color={item.color}
    >
      <Link
        to={groupRoutes.assignments(item.id)}
        className="absolute rounded-lg left-0 top-0 w-full h-full bg-primary bg-opacity-0 md:hover:bg-opacity-5 transition cursor-pointer"
      />

      <div className="flex flex-col">
        {item.is_admin && (
          <div className="bg-gray-100 text-gray-500 text-xs px-1.5 py-0.5 gap-1 flex items-center rounded-bl rounded-tr-lg mr-auto top-0 right-0 absolute">
            <FaStar /> {t("v4.group.admin.text")}
          </div>
        )}
        <div className="flex items-start pointer-events-none">
          <h4 className="flex items-center min-w-0 flex-grow text-base sm:text-lg font-bold flex-grow whitespace-nowrap text-ellipsis overflow-hidden text-inherit">
            {/*{item.color && (*/}
            {/*  <div*/}
            {/*    className="h-4 w-4 rounded-full mr-2"*/}
            {/*    style={{ backgroundColor: item.color }}*/}
            {/*  />*/}
            {/*)}*/}
            {item.name}
          </h4>
          {role.teacher && <GroupContextMenu item={item} />}
        </div>
        {/*<div className="flex items-center">*/}
        {/*  <TbClipboardCheck className="text-xl mr-1 text-primary" />*/}
        {/*  <Trans*/}
        {/*    i18nKey="assignments.open"*/}
        {/*    values={{ number: item.exercises_open }}*/}
        {/*    components={{*/}
        {/*      strong: <b className="text-primary mr-1" />,*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="flex items-center">
          <TbClipboardText className="text-xl mr-1" />
          {item.courses_total} {t("v4.assignment.pluralNumber")}
        </div>

        <span className="ml-auto flex items-center">
          {item?.guest_access ? (
            <Tooltip
              value={t("v4.group.guest.open")}
              className="pointer-events-auto relative"
            >
              <TbLockOpen className="mr-1 text-lg text-primary" />
            </Tooltip>
          ) : (
            <Tooltip
              value={t("v4.group.guest.close")}
              className="pointer-events-auto relative"
            >
              <TbLock className="mr-1 text-lg text-gray-400" />
            </Tooltip>
          )}
          <TbUsers className="mr-1 text-xl" /> {item.students_count}
        </span>
      </div>
    </ItemContainer>
  );
};

const GroupContextMenu = ({ item }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [openShare, setOpenShare] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const duplicateGroup = useMutation(() => postDuplicateGroup(item.id), {
    onSuccess: () => {
      queryClient.invalidateQueries("groups");
      toast.success(t("v4.group.duplicateSuccess"));
    },
    onError: () => {
      toast.error(t("v4.group.duplicateError."));
    },
  });

  const deleteMutation = useMutation(() => deleteGroup(item.id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["groups"]);
      toast.success(t("v4.group.deleteSuccess"));
    },
    onError: () => {
      toast.error(translateError(t("v4.group.deleteError"), t));
    },
    onSettled: () => {
      setDeleteOpen(false);
    },
  });

  return (
    <ContextMenu
      buttonClassName="pointer-events-auto ml-2"
      open={openShare || undefined}
      options={[
        [
          {
            icon: <TbSend />,
            text: t("common.invite"),
            onClick: () => setOpenShare(true),
          },
          {
            icon: <TbCopy />,
            text: t("common.rowSubMenu.buttons.duplicate.label"),
            onClick: duplicateGroup.mutate,
            loading: duplicateGroup.isLoading,
          },
        ],
        [
          {
            icon: <TbTrash />,
            text: t("v4.generic.delete"),
            onClick: () => setDeleteOpen(true),
            color: "bg-red-500 text-red-500",
            loading: deleteMutation.isLoading,
          },
        ],
      ]}
    >
      {({ content }) =>
        duplicateGroup.isLoading ? (
          <div className="flex items-center justify-center p-4">
            <Loader />
          </div>
        ) : (
          <>
            {content}
            {openShare && (
              <SharedGroup id={item.id} close={() => setOpenShare(false)} />
            )}
            <WarningModal
              onConfirm={deleteMutation.mutate}
              open={deleteOpen}
              closeModal={() => setDeleteOpen(false)}
              text={t("v4.group.deletePrompt")}
              confirmButton={t("v4.generic.delete")}
              closeButton={t("v4.generic.cancel")}
            />
          </>
        )
      }
    </ContextMenu>
  );
};
