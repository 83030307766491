import { Button } from "app/components/_styled/Button";
import { adminRoutes } from "enums/routes";
import Navigation from "./Navigation";
import { useQuery } from "react-query";
import { getAllUsers } from "../../../api/organizationsAPI";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import icArrowLeft from "../../../assets/images/arrowLeft.svg";
import icArrowRight from "../../../assets/images/arrowRigth.svg";
import styled from "styled-components";
import { ScTableRow } from "../../components/Tables/ExercisesLibraryHome/TableRowNew";
import SearchBar from "../../components/SearchBar";
import { useSelector } from "react-redux";
import {
  selectExerciseUserLibrary,
  selectSearchPhase,
} from "../../store/exercise-library-user";
import TablesHeader from "./TablesHeader";
import convertSortBy from "../../../helpers/convertSortBy";
import { InputCheckboxControlled } from "../../components/Buttons/InputCheckbox";
import CreateUser from "./AdminCreateUser";
import { useDebounce } from "../../hooks/useDebounce";

const AdminUsers = () => {
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [page, setPage] = useState(1);
  const search = useSelector(selectSearchPhase);
  const { sorting } = useSelector(selectExerciseUserLibrary);
  const [newUserModal, setNewUserModal] = useState(false);
  const [searchPhase, setSearchPhase] = useState("");

  const debounce = useDebounce((e) => setSearchPhase(e.substring(0, 50)), 500);

  useEffect(() => {
    debounce(search);
  }, [search]);

  const { data, isSuccess, isLoading } = useQuery(
    ["users", page, searchPhase, sorting[0]],
    () =>
      getAllUsers({
        page: page,
        sortParam: `${convertSortBy(sorting[0])}`,
        phrase: searchPhase || "",
      }),
    { refetchOnWindowFocus: false }
  );

  const handleSelectUser = (org) => {
    const userIdExist = selectedUsers.some((o) => o.id === org.id);
    if (userIdExist) {
      const index = selectedUsers.findIndex((o) => o.id === org.id);
      if (index > -1) {
        setSelectedUsers(selectedUsers.filter((o) => o.id !== org.id));
      }
    } else {
      setSelectedUsers([...selectedUsers, org]);
    }
  };

  return (
    <section>
      <div>
        <SearchBar />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Navigation />
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>
            Selected <strong>{selectedUsers.length}</strong>{" "}
            {selectedUsers.length > 0 && (
              <button onClick={() => setSelectedUsers([])}>Deselect</button>
            )}
          </span>
          <Button clickAction={() => setNewUserModal(true)}>New User(s)</Button>
          {/*<Button path={AuthSuperAdminRoutes.admin_users_new}>New User</Button>*/}
        </div>
      </div>
      <ScTableWrapper>
        <TablesHeader />
        {isLoading ? (
          <Loader />
        ) : isSuccess ? (
          <>
            {data.results.map((item) => {
              return (
                <TableRow key={item.id}>
                  <div style={{ display: "flex", width: "100%" }}>
                    <InputCheckboxControlled
                      value={selectedUsers.some((org) => org.id === item.id)}
                      toogleValue={() => handleSelectUser(item)}
                      id={item.id}
                    />
                    <ScTitleWrapper>{item.name}</ScTitleWrapper>
                    <ScTitleWrapper>{item.email}</ScTitleWrapper>
                  </div>
                  <Link to={adminRoutes.admin_user_details(item.id)}>
                    <button>Details</button>
                  </Link>
                </TableRow>
              );
            })}
            <ScTableNav>
              <img
                alt="arrowLeft"
                src={icArrowLeft}
                onClick={() => page > 1 && setPage(page - 1)}
              />
              Page {data.page} of {data.num_pages}
              <img
                alt="arrowRight"
                src={icArrowRight}
                onClick={() => page < data.num_pages && setPage(page + 1)}
              />
            </ScTableNav>
          </>
        ) : null}
      </ScTableWrapper>
      {newUserModal && (
        <CreateUser open={newUserModal} close={() => setNewUserModal(false)} />
      )}
    </section>
  );
};
export default AdminUsers;

const ScTableNav = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;
  border-radius: 8px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  img {
    padding: 10px;
    cursor: pointer;
  }
`;

const ScTableWrapper = styled.div`
  box-sizing: border-box;
  background-color: white;
  border-radius: 5px;
  margin: 40px auto;
  width: 100%;
  max-width: 1264px;
  padding-bottom: 80px;
  @media screen and (max-width: 1000px) {
    padding: 0 12px;
  }
  @media screen and (max-width: 500px) {
    padding: 0 8px;
  }
`;

const TableRow = styled(ScTableRow)`
  justify-content: space-between;
  &:hover {
    background: #f1f1f1;
  }
`;

const ScTitleWrapper = styled.div`
  flex: 1;
  min-width: 40%;
`;
