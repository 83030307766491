import React, { useEffect, useMemo, useState } from "react";
import { CoursePageRender } from "app/components/Exercises/CourseEdit/render/PageRender";
import { Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import { publicRoutes } from "enums/routes";
import { FloatingMenu, HeaderIconButton } from "app/components/Header";
import {
  TbAlertTriangle,
  TbChevronRight,
  TbHelpCircle,
  TbMail,
  TbSearch,
} from "react-icons/tb";
import { NewButton } from "app/components/Buttons/NewButton";
import { useTranslation } from "react-i18next";
import { HorizontalLine } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { InputText } from "app/components/Buttons/InputText";
import objectHash from "object-hash";
import { checkSafari } from "helpers/detectMobileDevice";

import enUploadSource from "app/pages/help/pages/en/upload-source.json";
import enGenerateSource from "app/pages/help/pages/en/generate-source.json";
import enGeneratePrompt from "app/pages/help/pages/en/generate-prompt.json";
import enEditTraining from "app/pages/help/pages/en/edit-training.json";
import enGenerateEditMode from "app/pages/help/pages/en/generate-edit-mode.json";
import enCreateQuizzes from "app/pages/help/pages/en/create-quizzes.json";
import enGenerateTranscript from "app/pages/help/pages/en/generate-transcript.json";
import enOrganizeContent from "app/pages/help/pages/en/organize-content.json";
import enCreateGroup from "app/pages/help/pages/en/create-group.json";
import enAssignTraining from "app/pages/help/pages/en/assign-training.json";
import enLiveSession from "app/pages/help/pages/en/live-session.json";

import frUploadSource from "app/pages/help/pages/fr/upload-source.json";
import frGenerateSource from "app/pages/help/pages/fr/generate-source.json";
import frGeneratePrompt from "app/pages/help/pages/fr/generate-prompt.json";
import frEditTraining from "app/pages/help/pages/fr/edit-training.json";
import frGenerateEditMode from "app/pages/help/pages/fr/generate-edit-mode.json";
import frCreateQuizzes from "app/pages/help/pages/fr/create-quizzes.json";
import frGenerateTranscript from "app/pages/help/pages/fr/generate-transcript.json";
import frOrganizeContent from "app/pages/help/pages/fr/organize-content.json";
import frCreateGroup from "app/pages/help/pages/fr/create-group.json";
import frAssignTraining from "app/pages/help/pages/fr/assign-training.json";
import frLiveSession from "app/pages/help/pages/fr/live-session.json";

import deUploadSource from "app/pages/help/pages/de/upload-source.json";
import deGenerateSource from "app/pages/help/pages/de/generate-source.json";
import deGeneratePrompt from "app/pages/help/pages/de/generate-prompt.json";
import deEditTraining from "app/pages/help/pages/de/edit-training.json";
import deGenerateEditMode from "app/pages/help/pages/de/generate-edit-mode.json";
import deCreateQuizzes from "app/pages/help/pages/de/create-quizzes.json";
import deGenerateTranscript from "app/pages/help/pages/de/generate-transcript.json";
import deOrganizeContent from "app/pages/help/pages/de/organize-content.json";
import deCreateGroup from "app/pages/help/pages/de/create-group.json";
import deAssignTraining from "app/pages/help/pages/de/assign-training.json";
import deLiveSession from "app/pages/help/pages/de/live-session.json";
import useWindowDimensions from "app/hooks/useWindowDimensions";

const pageToUrl = (page: any, language = "en") =>
  publicRoutes.help +
  `/${language}/` +
  page.name.replaceAll(" ", "-").toLocaleLowerCase();
// encodeURIComponent(page.name.replaceAll(" ", "-").toLocaleLowerCase());

const pages = {
  en: [
    enUploadSource,
    enGenerateSource,
    enGeneratePrompt,
    enEditTraining,
    enGenerateEditMode,
    enCreateQuizzes,
    enGenerateTranscript,
    enOrganizeContent,
    enCreateGroup,
    enAssignTraining,
    enLiveSession,
  ],
  de: [
    deUploadSource,
    deGenerateSource,
    deGeneratePrompt,
    deEditTraining,
    deGenerateEditMode,
    deCreateQuizzes,
    deGenerateTranscript,
    deOrganizeContent,
    deCreateGroup,
    deAssignTraining,
    deLiveSession,
  ],
  fr: [
    frUploadSource,
    frGenerateSource,
    frGeneratePrompt,
    frEditTraining,
    frGenerateEditMode,
    frCreateQuizzes,
    frGenerateTranscript,
    frOrganizeContent,
    frCreateGroup,
    frAssignTraining,
    frLiveSession,
  ],
};

const getHelpRoute = (data: any, language = "en") => (
  <Route
    key={data.id}
    path={pageToUrl(data, language)}
    render={() => (
      <div className="bg-white rounded-2xl my-4 max-w-4xl w-full mx-auto flex flex-col">
        <CoursePageRender
          items={[
            // {
            //   id: "heading",
            //   category: "content",
            //   type: PageItemType.Heading,
            //   data: data.name,
            //   align: "left",
            // },
            ...data.items,
          ]}
        />
      </div>
    )}
  />
);

export const HelpRoutes = () => (
  <Switch>
    {["en", "de", "fr"].map((language) =>
      pages[language].map((page) => getHelpRoute(page, language))
    )}
  </Switch>
);

export const HelpButton = ({ editMode = false }) => {
  const { t, i18n } = useTranslation();
  const { width: windowWidth } = useWindowDimensions();
  const isDesktop = windowWidth >= 650;

  const language = i18n.language in pages ? i18n.language : "en";
  const list = pages[language];

  const [helpOpen, setHelpOpen] = useState(false);

  const [search, setSearch] = useState("");
  const regex = useMemo(() => new RegExp(`(${search})`, "gi"), [search]);
  const [focus, setFocus] = useState(0);
  const filtered = useMemo(() => {
    if (!search) return list;
    return list.filter(({ name }) => name.match(regex));
  }, [list, search]);

  useEffect(() => {
    setFocus(0);
  }, [objectHash(filtered)]);

  const isSafari = useMemo(checkSafari, []);
  const warning = editMode && isSafari;

  return (
    <FloatingMenu
      size="xs"
      trigger={(toggle) => (
        <HeaderIconButton
          onClick={() => {
            toggle();
            setHelpOpen(false);
            setSearch("");
          }}
        >
          <TbHelpCircle />
          {warning && (
            <div className="bg-amber-400 border-2 border-white absolute top-2 right-2 w-3.5 h-3.5 rounded-full" />
          )}
        </HeaderIconButton>
      )}
    >
      {({ setIsOpen }) => (
        <>
          {warning && (
            <>
              <div className="bg-amber-100 w-full max-w-[300px] mx-auto rounded-lg p-3 text-xs leading-tight text-gray-600">
                <TbAlertTriangle className="text-base inline mb-0.5 mr-1" />
                {t("v4.editView.safariWarning")}
              </div>
              <HorizontalLine className="my-2" />
            </>
          )}

          <InputText
            value={search}
            onChange={setSearch}
            icon={TbSearch}
            placeholder={t("v4.help.search")}
            className="mb-1 outline-transparent"
            onKeyDown={(e) => {
              if (!filtered?.length) return;
              if (e.key === "Enter") {
                e.preventDefault();
                const page = filtered?.[focus];
                if (!page) return;

                window.open(pageToUrl(page, language), "_blank");
                setIsOpen(false);
                return;
              }
              if (e.key === "ArrowUp") {
                setFocus((focus) => Math.max(focus - 1, 0));
                return;
              }
              if (e.key === "ArrowDown") {
                setFocus((focus) => Math.min(focus + 1, filtered.length - 1));
                return;
              }
            }}
          />
          <FloatingMenu
            size="xs"
            open={helpOpen || !!search}
            placement={isDesktop ? "auto-start" : "bottom"}
            options={[
              {
                name: "offset",
                options: { offset: [0, 0] },
              },
            ]}
            className="w-60 max-h-[50vh] overflow-y-auto"
            trigger={() => (
              <NewButton
                size="lg"
                variant="transparent"
                className="font-bold"
                onPointerOver={() => setHelpOpen(true)}
              >
                {t("v4.help.menu")}
                <TbChevronRight className="ml-auto -mr-2" />
              </NewButton>
            )}
          >
            {!filtered?.length ? (
              <span className="p-2 text-center text-gray-400 font-bold text-sm">
                {t("v4.help.empty")}
              </span>
            ) : (
              filtered.map((page, i) => (
                <NewButton
                  component={Link}
                  size="lg"
                  variant={search && i === focus ? "light" : "transparent"}
                  color={
                    search && i === focus
                      ? "bg-primary text-gray-600"
                      : undefined
                  }
                  className="font-bold text-sm"
                  to={pageToUrl(page, language)}
                  key={page.id}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    {!search
                      ? page.name
                      : page.name.split(regex).map((text, i) => {
                          if (text.match(regex))
                            return (
                              <span
                                key={i}
                                className="bg-primary bg-opacity-30 rounded"
                              >
                                {text}
                              </span>
                            );
                          return text;
                        })}
                  </span>
                </NewButton>
              ))
            )}
          </FloatingMenu>
          <NewButton
            onPointerOver={() => setHelpOpen(false)}
            component="a"
            size="lg"
            variant="transparent"
            className="font-bold"
            href="mailto:support@smartest.io"
          >
            <TbMail />
            {t("v4.subscriptions.contactUs")}
          </NewButton>
        </>
      )}
    </FloatingMenu>
  );
};
