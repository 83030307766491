import { useTranslation } from "react-i18next";
import React, { useMemo, useState } from "react";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbArrowBackUp, TbCheck, TbX } from "react-icons/tb";
import { CgSpinner } from "react-icons/cg";
import { SelectOnlyType } from "app/components/Sources/MediaPicker/MediaPicker";
import { useSelectOnly } from "app/components/Sources/MediaPicker/context/selectOnlyContext";
import { useMutation, useQueryClient } from "react-query";
import { urlToPdf } from "api/sourcesAPI";
import { InputText } from "app/components/Buttons/InputText";
import { toast } from "react-hot-toast";
import classNames from "classnames";

const urlRegex =
  /^https?:\/\/(?:www\.)?[-a-zA-Z\d@:%._+~#=]{1,256}\.[a-zA-Z\d()]{1,6}\b[-a-zA-Z\d()@:%_+.~#?&/=]*$/;

export const WebsiteUpload = ({
  close,
  back,
  setSourceId,
}: {
  close: () => void;
  back: () => void;
  setSourceId: (id: string) => void;
}) => {
  const { t } = useTranslation();
  const selectOnly = useSelectOnly();
  const queryClient = useQueryClient();
  const [url, setUrl] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!isValidUrl)
      return toast.error(t("v4.library.websiteUpload.incorrectLink"));
    websiteUploadMutation.mutate();
  };

  const websiteUploadMutation = useMutation(() => urlToPdf(url), {
    onSuccess: ({ id }) => {
      setSourceId(id);
      queryClient.invalidateQueries(["library"]);
    },
  });

  const isValidUrl = useMemo(() => {
    return urlRegex.test(url);
  }, [url]);

  return (
    <>
      <div className="p-1 font-bold border-b-2 border-gray-100 flex items-center gap-2 text-gray-500">
        {selectOnly !== SelectOnlyType.none && (
          <NewButton onClick={back} size="sm">
            <TbArrowBackUp />
            {t("v4.generic.back")}
          </NewButton>
        )}

        <div className="font-bold text-gray-500">
          {t("v4.library.websiteUpload.text")}
        </div>
        <NewButton
          iconOnly
          onClick={close}
          className="ml-auto"
          variant="transparent"
        >
          <TbX />
        </NewButton>
      </div>
      <div className="grow relative">
        <div className="absolute-cover overflow-y-auto">
          <form
            className="p-8 gap-2 flex flex-col justify-center items-center min-h-full"
            onSubmit={handleSubmit}
          >
            <div className="text-lg font-bold mb-2 text-gray-600">
              {t("v4.library.websiteUpload.instruction")}
            </div>
            <div className="w-full max-w-md">
              <InputText
                value={url}
                onChange={setUrl}
                placeholder="http://..."
                readOnly={websiteUploadMutation.isLoading}
              />
            </div>
            <NewButton
              variant="primary"
              component="button"
              size="lg"
              type="submit"
              disabled={websiteUploadMutation.isLoading}
              onClick={handleSubmit}
              className={classNames(
                "transition",
                !isValidUrl && "opacity-50 hover:opacity-100"
              )}
            >
              {websiteUploadMutation.isLoading ? (
                <CgSpinner className="animate-spin" />
              ) : (
                <TbCheck />
              )}
              {t("v4.generic.confirm")}
            </NewButton>
          </form>
        </div>
      </div>
    </>
  );
};
