import {
  PageItemType,
  SlideItem,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { SlidePageVideo } from "app/components/Exercises/CourseEdit/slide/items/SlidePageVideo";
import { SlidePageAudio } from "app/components/Exercises/CourseEdit/slide/items/SlidePageAudio";
import { SlidePageImage } from "app/components/Exercises/CourseEdit/slide/items/SlidePageImage";
import React, { FC, ReactNode } from "react";
import { SlidePageParagraph } from "app/components/Exercises/CourseEdit/slide/items/SlidePageParagraph";
import { SlidePageList } from "app/components/Exercises/CourseEdit/slide/items/SlidePageList";
import { SlidePageEmbed } from "app/components/Exercises/CourseEdit/slide/items/SlidePageEmbed";
import { SlidePageMediaUpload } from "app/components/Exercises/CourseEdit/slide/generate/SlidePageMediaUpload";
import { SlidePageParagraphSimplify } from "app/components/Exercises/CourseEdit/slide/generate/SlidePageParagraphSimplify";
import { SlidePageParagraphSummarize } from "app/components/Exercises/CourseEdit/slide/generate/SlidePageParagraphSummarize";
import { SlidePageParagraphNext } from "app/components/Exercises/CourseEdit/slide/generate/SlidePageParagraphNext";
import { SlidePageParagraphAutocomplete } from "app/components/Exercises/CourseEdit/slide/generate/SlidePageParagraphAutocomplete";
import { SlidePageParagraphParaphrase } from "app/components/Exercises/CourseEdit/slide/generate/SlidePageParagraphParaphrase";
import { SlidePageParagraphTranslate } from "app/components/Exercises/CourseEdit/slide/generate/SlidePageParagraphTranslate";
import { SlidePageListGenerate } from "app/components/Exercises/CourseEdit/slide/generate/SlidePageListGenerate";
import { SlideItemWrapper } from "app/components/Exercises/CourseEdit/slide/SlideItemWrapper";
import { TbAlertTriangle } from "react-icons/tb";
import { Tooltip } from "app/components/Tooltip";
import { sendError } from "app/components/Helpers/ErrorBoundary";
import { useTranslation } from "react-i18next";

const withWrapper =
  (Component: FC<{ type: SlideItem["type"] }>) =>
  ({ type }: { type: SlideItem["type"] }): JSX.Element | null => {
    return (
      <SlidePageErrorWrapper>
        <Component type={type} />
      </SlidePageErrorWrapper>
    );
  };

class SlidePageErrorWrapper extends React.Component<
  { children: ReactNode },
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    sendError(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <SlideItemWrapper>
          <div className="absolute-cover flex items-center justify-center text-red-800 bg-red-200 rounded-xl">
            <Message />
          </div>
        </SlideItemWrapper>
      );
    }

    return this.props.children;
  }
}

const Message = () => {
  const { t } = useTranslation();
  return (
    <Tooltip value={t("v4.error.componentError")}>
      <TbAlertTriangle className="text-5xl" />
    </Tooltip>
  );
};

export const SlidePageItem = withWrapper(
  ({ type }: { type: SlideItem["type"] }) => {
    if (type === PageItemType.Video) return <SlidePageVideo />;
    if (type === PageItemType.Audio) return <SlidePageAudio />;
    if (type === PageItemType.Image) return <SlidePageImage />;
    if (type === PageItemType.Paragraph) return <SlidePageParagraph />;
    if (type === PageItemType.List) return <SlidePageList />;
    if (type === PageItemType.Embed) return <SlidePageEmbed />;
    if (type === PageItemType.MediaUpload) return <SlidePageMediaUpload />;

    if (type === PageItemType.ParagraphAutocomplete)
      return <SlidePageParagraphAutocomplete />;
    if (type === PageItemType.ListGenerate) return <SlidePageListGenerate />;
    if (type === PageItemType.ParagraphSummarize)
      return <SlidePageParagraphSummarize />;
    if (type === PageItemType.ParagraphSimplify)
      return <SlidePageParagraphSimplify />;
    if (type === PageItemType.ParagraphParaphrase)
      return <SlidePageParagraphParaphrase />;
    if (type === PageItemType.ParagraphNext) return <SlidePageParagraphNext />;
    if (type === PageItemType.ParagraphTranslate)
      return <SlidePageParagraphTranslate />;

    return null;
  }
);
