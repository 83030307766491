import React, { ComponentProps, ReactNode } from "react";
import Tippy from "@tippyjs/react/headless";
import { motion, useSpring } from "framer-motion";
import { Placement } from "@popperjs/core";
import classNames from "classnames";
import { TippyProps } from "@tippyjs/react";

type TooltipProps = TippyProps &
  Omit<ComponentProps<"div">, "children"> & {
    value?: string | false | null | ReactNode;
    placement?: Placement;
    variant?: "light" | "dark";
  };

const INITIAL_SCALE = 0.9;

export const TooltipRaw = ({
  content,
  onClick,
  touch = "hold",
  ...props
}: Omit<Omit<TooltipProps, "value">, "variant"> & {
  content: ReactNode;
}) => {
  const springConfig = { damping: 50, stiffness: 1000, mass: 1 };
  const opacity = useSpring(0, springConfig);
  const scale = useSpring(INITIAL_SCALE, springConfig);

  function onMount() {
    scale.set(1);
    opacity.set(1);
  }

  function onHide({ unmount }) {
    const cleanup = scale.onChange((value) => {
      if (value <= INITIAL_SCALE) {
        cleanup();
        unmount();
      }
    });

    scale.set(INITIAL_SCALE);
    opacity.set(0);
  }

  return (
    <Tippy
      render={(attrs) => (
        <motion.div {...attrs} style={{ scale, opacity }}>
          {content}
        </motion.div>
      )}
      animation
      {...{ onMount, onHide }}
      {...props}
    />
  );
};

export const Tooltip = ({
  value,
  variant = "dark",
  children,
  isHidden,
  className,
  ...props
}: TooltipProps & { isHidden?: boolean }) => {
  const inner = (
    <div {...{ className }} {...props}>
      {children}
    </div>
  );

  if (isHidden) return inner;

  return (
    <TooltipRaw
      content={
        value ? (
          <div
            className={classNames(
              "rounded-lg px-4 py-2 text-sm max-w-xs shadow-lg text-center",
              {
                "bg-gray-700 text-white": variant === "dark",
                "bg-white text-gray-500 border-gray-100 border":
                  variant === "light",
              }
            )}
          >
            {value}
          </div>
        ) : null
      }
      {...props}
    >
      {inner}
    </TooltipRaw>
  );
};
