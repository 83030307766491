import React, { useEffect, useMemo } from "react";
import { useSessionContext } from "app/hooks/useSessionContext";
import { LiveSessionToggles } from "app/components/Exercises/LiveSession/LiveSessionToggles";
import { CourseExercisesList } from "./ExercisesList";
import Participants from "./Participants";
import { useParams } from "react-router-dom";
import ShareInfo from "./ShareInfo";
import SessionConsumption from "./SessionConsumption";
import TeacherResults from "./TeacherResults";
import SessionTeacherSummary from "./SessionTeacherSummary";
import { screenAwake } from "../utils/screenAwake";
import Transition from "app/components/Exercises/LiveSession/transition";
import { getSessionStatus } from "app/components/Exercises/utils/sessionStatus";
import classNames from "classnames";
import { TeacherControlBar } from "app/components/Exercises/LiveSession/TeacherControlBar";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbArrowRight } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { Tooltip } from "app/components/Tooltip";

const TeacherView = ({ isFullscreen }) => {
  const {
    creationStep,
    session,
    selectedExercises,
    isDuration,
    isOpen,
    isTeacher,
    name,
  } = useSessionContext();
  const { t } = useTranslation();
  const {
    current_exercise,
    status,
    exercise_content,
    exercise_submitted,
    unit_id,
  } = session.get.status;
  // const step = creationStep.get;
  const params = useParams<{ courseId: string }>();
  const exercise_id = params?.courseId || "";
  const initSession = session.get?.initSession;
  const { consumption, results, leaderboard, initiation } =
    getSessionStatus(status);

  useEffect(() => {
    screenAwake().getScreenLock();
  }, [status, current_exercise, exercise_content, exercise_submitted]);

  useEffect(() => {
    if (session.get.initSession && !session.get.exercises.length) {
      initSession(exercise_id || unit_id);
    }
  }, []);

  const isLeaderboard = useMemo(() => {
    return session.get.exercises.some(
      (p) => p?.type === "quiz" && p?.category !== "poll"
    );
  }, [session.get.exercises]);

  return (
    <>
      <div
        className="relative z-[2] flex justify-center flex-1"
        id="exercise-mixed-consumption"
      >
        <div className="flex flex-col flex-1 relative">
          <Transition
            id="session-teacher-scroll"
            key={status?.slice(0, 5)}
            className={classNames(
              "absolute-cover flex flex-col !overflow-y-auto px-1 xs:px-2 gap-1 md:gap-2",
              isFullscreen && "py-1 xs:py-2"
            )}
          >
            {consumption ? (
              <SessionConsumption />
            ) : results ? (
              <TeacherResults />
            ) : leaderboard ? (
              <SessionTeacherSummary />
            ) : (
              <div className="flex flex-col gap-2 mx-auto w-full max-w-2xl">
                <div className="p-6 bg-white shadow-xl rounded-xl flex flex-col gap-4">
                  <div className="text-xl font-bold">{name}</div>
                  <CourseExercisesList />
                </div>
                {isTeacher.get && (
                  <>
                    {creationStep.get === 0 && (
                      <>
                        <div className="p-2 bg-white shadow-xl rounded-xl flex flex-col gap-4">
                          <LiveSessionToggles />
                        </div>
                        <NewButton
                          size="lg"
                          className="ml-auto px-8 py-4 text-2xl rounded-xl"
                          variant="primary"
                          onClick={() =>
                            session.get.createSession({
                              id: params.courseId,
                              exercises_in_scope: selectedExercises.get,
                              is_duration: isDuration.get,
                              is_open: isOpen.get,
                              // is_leaderboard: isLeaderboard.get,
                              is_leaderboard: isLeaderboard,
                            })
                          }
                        >
                          {t("liveSession.next")}
                          <TbArrowRight
                            className="!text-2xl"
                            strokeWidth={2.75}
                          />
                        </NewButton>
                      </>
                    )}

                    {creationStep.get === 1 && <LobbyView />}
                  </>
                )}
              </div>
            )}
          </Transition>
        </div>

        {/*{isTeacher.get &&*/}
        {/*  (consumption || results || (!leaderboard && step === 1)) && (*/}
        {/*    <Participants {...{ consumption, results }} />*/}
        {/*  )}*/}
        {initiation && <Participants />}
      </div>
      {(consumption || results) && <TeacherControlBar {...{ isFullscreen }} />}
    </>
  );
};

const LobbyView = () => {
  const { t } = useTranslation();
  const { session } = useSessionContext();
  const participants =
    session.get?.status?.participants?.filter(
      (participant) => participant.status !== "banned"
    )?.length || 0;

  return (
    <>
      <div className="bg-white shadow-xl rounded-xl overflow-hidden flex items-center">
        <ShareInfo />
      </div>
      <Tooltip
        value={!participants && t("liveSession.notEnoughPlayers")}
        className="ml-auto"
      >
        <NewButton
          size="lg"
          className="px-8 py-4 text-2xl rounded-xl"
          variant="primary"
          onClick={() => session.get.startSession()}
          disabled={!participants}
        >
          {t("liveSession.start")}
          <TbArrowRight className="!text-2xl" strokeWidth={2.75} />
        </NewButton>
      </Tooltip>
    </>
  );
};

export default TeacherView;
