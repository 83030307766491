import React, { useMemo } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useInfiniteQuery } from "react-query";
import { InView } from "react-intersection-observer";
import { ConsumptionAssignmentItem } from "app/pages/courses/CoursesListView";
import { postGetGuestGroupCourses } from "api/groups/groupsAPI";

const Skeleton = () => (
  <>
    {[...new Array(15)].map((_, i) => (
      <div
        key={i}
        className="rounded-lg h-32 bg-gray-200 animate-pulse"
        style={{ animationDelay: i * 0.2 + "s" }}
      />
    ))}
  </>
);

export const GuestCoursesListView = () => {
  const { guestPin } = useParams<{ guestPin: string }>();
  const { t } = useTranslation();

  const { data, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ["guestCourses", guestPin],
      ({ pageParam = 0 }) =>
        postGetGuestGroupCourses({ page: pageParam }, guestPin),
      {
        refetchOnWindowFocus: false,
        // staleTime: 5 * 60 * 1000,
        getNextPageParam: (lastPage, pages) =>
          lastPage.courses_total > pages.length * 15 ? pages.length : undefined,
      }
    );

  const list = useMemo(
    () =>
      (data?.pages || []).reduce(
        (list, group) => [...list, ...group.courses],
        []
      ),
    [data?.pages]
  );

  return (
    <div className="py-4 px-3 sm:px-8">
      {/*<HomeToolbar />*/}
      {!isSuccess ? (
        <div className="flex flex-col space-y-3">
          <Skeleton />
        </div>
      ) : (
        <>
          <div className="flex flex-col space-y-3">
            {!list.length ? (
              <div className="text-xl text-center text-gray-500 py-5">
                {t("v4.training.noItems")}
              </div>
            ) : (
              list.map(({ received, ...item }) => (
                <ConsumptionAssignmentItem
                  key={item.course_id}
                  item={item}
                  tagged
                />
              ))
            )}
          </div>
          {hasNextPage && (
            <InView
              as="div"
              className="flex"
              onChange={(inView) =>
                inView && !isFetchingNextPage && fetchNextPage()
              }
              key={data.pages.length}
            >
              {({ inView, ref }) => (
                <div
                  ref={ref}
                  className={classNames(
                    "flex flex-col gap-3 transition",
                    !inView && "opacity-0"
                  )}
                >
                  <Skeleton />
                </div>
              )}
            </InView>
          )}
        </>
      )}
    </div>
  );
};
