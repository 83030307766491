import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getUsersOrganization,
  putAddUserToOrganization,
  putOrganizationUserDetails,
  putRemoveUserFromOrganization,
} from "../../../../api/organizationsAPI";
import Loader from "../../../components/Loader";
import UsersClass from "./UsersClass";
import { toast } from "react-hot-toast";
import { useDetectKeyPress } from "../../../../helpers/hooks/detectKeyPress";
import { InputCheckboxControlled } from "../../../components/Buttons/InputCheckbox";
import { Link } from "react-router-dom";
import { adminRoutes } from "../../../../enums/routes";
import useOnClickOutside from "use-onclickoutside";
import icArrowLeft from "../../../../assets/images/arrowLeft.svg";
import icArrowRight from "../../../../assets/images/arrowRigth.svg";
import { useDebounce } from "../../../hooks/useDebounce";
import { useSelector } from "react-redux";
import { selectSearchPhase } from "../../../store/exercise-library-user";
import SearchBar from "../../../components/SearchBar";
import { useParams } from "react-router";

export const OrganizationUsers = () => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const ref = useRef(null);
  const [addUserInput, setAddUserInput] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [newUserMail, setNewUserMail] = useState("");
  const search = useSelector(selectSearchPhase);
  const [searchPhase, setSearchPhase] = useState("");
  const debounce = useDebounce((e) => setSearchPhase(e.substring(0, 50)), 500);

  useEffect(() => {
    debounce(search);
  }, [search]);

  const { data, isLoading, isSuccess } = useQuery(
    ["users", id, page, searchPhase],
    () => getUsersOrganization(id, page, searchPhase),
    { refetchOnWindowFocus: false }
  );

  const changeUserRole = useMutation(
    ({ role, member_id }: { role: string; member_id: string }) => {
      const defaultData = { is_pro: false };
      const dataToSave = { ...defaultData, [`is_${role.toLowerCase()}`]: true };
      return putOrganizationUserDetails(id, member_id, dataToSave);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["users", id]),
    }
  );

  const deleteUsers = useMutation(
    () => {
      return putRemoveUserFromOrganization({
        id,
        dataToSave: { members: selectedUsers.map((item) => item.id) },
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["users", id]);
        setSelectedUsers([]);
        if (data.removed_count < 1) {
          toast.error("User not found");
        } else {
          toast.success("Success");
        }
      },
      onError: () => {
        toast.error("Something went wrong");
      },
    }
  );

  const addUser = useMutation(
    () => {
      return putAddUserToOrganization({
        id,
        dataToSave: { members: [newUserMail] },
      });
    },
    {
      onSuccess: (data) => {
        setNewUserMail("");
        setAddUserInput(false);
        queryClient.invalidateQueries(["users", id]);
        if (data.added_count < 1) {
          toast.error("User not found or already exist");
        } else {
          toast.success("Success");
        }
      },
    }
  );

  useOnClickOutside(ref, () => {
    setNewUserMail("");
    setAddUserInput(false);
  });
  useDetectKeyPress("Enter", () => newUserMail.length && addUser.mutate(), [
    newUserMail,
  ]);
  useDetectKeyPress("Escape", () => {
    setNewUserMail("");
    setAddUserInput(false);
  });

  const handleSelectUser = (org) => {
    const userIdExist = selectedUsers.some((o) => o.id === org.id);
    if (userIdExist) {
      const index = selectedUsers.findIndex((o) => o.id === org.id);
      if (index > -1) {
        setSelectedUsers(selectedUsers.filter((o) => o.id !== org.id));
      }
    } else {
      setSelectedUsers([...selectedUsers, org]);
    }
  };

  return (
    <div className="bg-white rounded-2xl shadow-xl p-6 flex flex-col gap-2">
      <div>
        <SearchBar />
      </div>
      {isLoading ? (
        <Loader />
      ) : isSuccess ? (
        <div style={{ paddingBottom: "100px" }}>
          <ScRow style={{ margin: "15px 0", justifyContent: "space-between" }}>
            <div ref={ref}>
              <button onClick={() => setAddUserInput(true)}>Add user</button>
              {addUserInput && (
                <input
                  autoFocus
                  placeholder="email"
                  value={newUserMail}
                  onChange={(e) => setNewUserMail(e.target.value)}
                />
              )}
              {newUserMail.length > 0 && (
                <button onClick={() => addUser.mutate()}>Add</button>
              )}
            </div>
            <button
              onClick={() => deleteUsers.mutate()}
              disabled={selectedUsers.length < 1 || deleteUsers.isLoading}
            >
              Delete selected users
            </button>
          </ScRow>
          <ScRow>
            <ScCol style={{ minWidth: "250px", maxWidth: "250px" }}>
              <strong style={{ marginLeft: "40px" }}>Name</strong>
            </ScCol>
            <ScCol style={{ minWidth: "120px", maxWidth: "120px" }}>
              <strong>Role</strong>
            </ScCol>
            <ScCol
              style={{
                minWidth: "calc(100% - 420px)",
                maxWidth: "calc(100% - 420px)",
                textAlign: "center",
              }}
            >
              <strong>Classes</strong>
            </ScCol>
          </ScRow>
          {data.results.map((item) => {
            const roles = {
              Pro: item.is_pro,
              Basic: !item.is_pro,
            };
            return (
              <ScRow key={item.id}>
                <ScCol style={{ minWidth: "250px", maxWidth: "250px" }}>
                  <ScColElement key={item.id}>
                    <InputCheckboxControlled
                      style={{ alignItems: "flex-start" }}
                      value={selectedUsers.some((org) => org.id === item.id)}
                      toogleValue={() => handleSelectUser(item)}
                      id={item.id}
                    />
                    <ScColBasic>
                      <span>{item.name}</span>
                      <span style={{ fontSize: "12px" }}>{item.email}</span>
                      <Link to={adminRoutes.admin_user_details(item.id)}>
                        <button>Details</button>
                      </Link>
                    </ScColBasic>
                  </ScColElement>
                </ScCol>
                <ScCol style={{ minWidth: "120px", maxWidth: "120px" }}>
                  <ScColElement key={item.id}>
                    <ScColBasic>
                      <select
                        onChange={(e) =>
                          changeUserRole.mutate({
                            role: e.target.value,
                            member_id: item.id,
                          })
                        }
                        defaultValue={item.is_pro ? "Pro" : "Basic"}
                      >
                        {Object.keys(roles).map((item, index) => {
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </ScColBasic>
                  </ScColElement>
                </ScCol>
                <ScCol
                  style={{
                    minWidth: "calc(100% - 420px)",
                    maxWidth: "calc(100% - 420px)",
                  }}
                >
                  <UsersClass id={id} member_id={item.id} />
                </ScCol>
              </ScRow>
            );
          })}
          <ScTableNav>
            <img
              alt="arrowLeft"
              src={icArrowLeft}
              onClick={() => page > 1 && setPage(page - 1)}
            />
            Page {data.page} of {data.num_pages}
            <img
              alt="arrowRight"
              src={icArrowRight}
              onClick={() => page < data.num_pages && setPage(page + 1)}
            />
          </ScTableNav>
        </div>
      ) : (
        <div>Something went wrong</div>
      )}
    </div>
  );
};

const ScCol = styled.div`
  display: flex;
  flex-direction: column;

  input {
    &:first-child {
      margin-left: 0;
    }
    margin: 10px 0;
    max-width: 300px;
    height: 30px;
    font-size: 16px;
  }
`;

const ScRow = styled.div`
  display: flex;
`;

const ScColElement = styled.div`
  flex: 1;
  margin: 10px 0;
  padding-top: 10px;
  display: flex;
  border-top: 1px solid #b7b7b7;

  input {
    &:first-child {
      margin-left: 0;
    }

    margin: 10px;
    height: 30px;
    font-size: 16px;
  }
`;

const ScColBasic = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScTableNav = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;
  border-radius: 8px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  img {
    padding: 10px;
    cursor: pointer;
  }
`;
