import { useTranslation } from "react-i18next";
import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  ObjectFit,
  SlideImage,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { SlideItemWrapper } from "app/components/Exercises/CourseEdit/slide/SlideItemWrapper";
import { Tooltip } from "app/components/Tooltip";
import { ActiveButton } from "app/components/Exercises/Edit/questionType/Slide/item/ItemText";
import {
  TbArrowsMaximize,
  TbAspectRatio,
  TbBoxModel2,
  TbPhotoStar,
} from "react-icons/tb";
import { ProgressiveCanvas } from "app/components/Helpers/ProgressiveImage";
import React, { useState } from "react";
import { ImageGenerate } from "app/components/Exercises/CourseEdit/components/generate/ImageGenerate";
import {
  ImageAttribution,
  ImageAttributionModal,
} from "app/components/Exercises/CourseEdit/items/content/PageImageItem";
import { NewButton } from "app/components/Buttons/NewButton";
import { FaQuestionCircle } from "react-icons/fa";

export const SlidePageImage = () => {
  const { t } = useTranslation();
  const [{ data, fit, attribution, position }, set] =
    usePageItemContext<SlideImage>();
  const [attributionOpen, setAttributionOpen] = useState(false);

  const setFit = (fit: ObjectFit) => () =>
    set((item) => {
      item.fit = fit;
    });

  return (
    <SlideItemWrapper
      magic={!!data.sourceId && ((close) => <ImageGenerate close={close} />)}
      toolbar={
        <div className="h-full flex items-center gap-1">
          <Tooltip value={t("media.image.fit.cover")} delay={[500, 0]}>
            <ActiveButton
              icon={TbAspectRatio}
              onClick={setFit("cover")}
              isActive={fit === "cover"}
            />
          </Tooltip>
          <Tooltip value={t("media.image.fit.contain")} delay={[500, 0]}>
            <ActiveButton
              icon={TbBoxModel2}
              onClick={setFit("contain")}
              isActive={fit === "contain"}
            />
          </Tooltip>
          <Tooltip value={t("media.image.fit.stretch")} delay={[500, 0]}>
            <ActiveButton
              icon={TbArrowsMaximize}
              onClick={setFit("fill")}
              isActive={fit === "fill"}
            />
          </Tooltip>
          <div className="h-4 rounded-full w-0.5 bg-gray-100" />
          <Tooltip value={t("v4.item.image.attribution.text")}>
            <NewButton
              iconOnly
              size="lg"
              variant="transparent"
              onClick={() => {
                setAttributionOpen(true);
              }}
            >
              <TbPhotoStar />
            </NewButton>
          </Tooltip>
        </div>
      }
    >
      <ProgressiveCanvas
        crop={data}
        src={data.src}
        className="absolute-cover rounded-2xl"
        style={{ objectFit: fit || "cover" }}
      />
      <SlideImageAttribution data={attribution} width={position.w} />
      <ImageAttributionModal
        open={attributionOpen}
        setOpen={setAttributionOpen}
      />
    </SlideItemWrapper>
  );
};

const SlideImageAttribution = ({ data = "", width = 1 }) => {
  if (!data) return null;
  return (
    <div className="absolute bottom-0 left-0 text-sm bg-gray-50 px-2 py-0.5 rounded-tr-lg rounded-bl-lg text-gray-600">
      {width < 3 ? (
        <Tooltip
          interactive
          content={
            <div className="bg-white py-1 px-2 text-sm rounded-lg border border-gray-100 shadow-xl text-gray-600 max-w-sm">
              <ImageAttribution data={data} />
            </div>
          }
          appendTo={document.body}
        >
          <FaQuestionCircle className="text-lg text-gray-500" />
        </Tooltip>
      ) : (
        <ImageAttribution data={data} />
      )}
    </div>
  );
};

export const SlideImageRender = ({ item }: { item: SlideImage }) => (
  <>
    <ProgressiveCanvas
      crop={item.data}
      src={item.data.src}
      className="absolute-cover rounded-2xl border border-2 border-gray-100"
      style={{ objectFit: item.fit || "cover" }}
    />

    <SlideImageAttribution data={item?.attribution} width={item.position.w} />
  </>
);
