import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { history, store } from "./app/store";
import "./i18n/config";
import { QueryClient, QueryClientProvider } from "react-query";
import { MathJaxContext } from "better-react-mathjax";
import ErrorBoundary from "./app/components/Helpers/ErrorBoundary";
import "./helpers/polyfill";
import { SourceUploadContextProvider } from "api/ws/SourceUploadContext";
import { Helmet } from "react-helmet";
import React from "react";

const GoogleScripts = () => (
  <Helmet>
    <script
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG}`}
    />
    <script>
      {`console.log("[Analytics loaded]");
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag("config", "${process.env.REACT_APP_GTAG}");`}
    </script>
  </Helmet>
);

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <GoogleScripts />
    <QueryClientProvider client={queryClient}>
      <MathJaxContext
        config={{
          loader: {
            load: ["input/asciimath"],
          },
        }}
      >
        <ErrorBoundary>
          <SourceUploadContextProvider>
            <App history={history} />
          </SourceUploadContextProvider>
        </ErrorBoundary>
      </MathJaxContext>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
