export const screenAwake = () => {
  function isScreenLockSupported() {
    return "wakeLock" in navigator;
  }

  let screenLock;

  async function getScreenLock() {
    if (isScreenLockSupported()) {
      let screenLock;
      try {
        // @ts-ignore
        screenLock = await navigator.wakeLock
          .request("screen")
          .then(() => console.log("screen locked"));
      } catch (err) {
        console.log(err);
      }
      return screenLock;
    }
  }

  function release() {
    if (typeof screenLock !== "undefined" && screenLock != null) {
      screenLock.release().then(() => {
        console.log("Lock released 🎈");
        screenLock = null;
      });
    }
  }

  return { release, getScreenLock };
};
