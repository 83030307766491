import {
  CourseItem,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import React, { useMemo } from "react";
import { PageItemRender } from "app/components/Exercises/CourseEdit/render/PageRender";
import { PageSortingCorrect } from "app/components/Exercises/CourseEdit/items/quiz/PageSortingItem";
import { PagePairingCorrect } from "app/components/Exercises/CourseEdit/items/quiz/PagePairingItem";
import classNames from "classnames";
import { PageMultipleChoiceCorrect } from "app/components/Exercises/CourseEdit/items/quiz/PageMultipleChoiceItem";
import { InnerLabelingCorrect } from "app/components/Exercises/CourseEdit/items/quiz/PageImageLabelingItem";
import {
  CreateProtectedContext,
  useProtectedContext,
} from "app/hooks/useProtectedContext";
import { PageFillTheGapCorrect } from "app/components/Exercises/CourseEdit/items/quiz/PageFillTheGapItem";
import { useSessionContext } from "app/hooks/useSessionContext";
import { PageExplanationRender } from "app/components/Exercises/CourseEdit/items/quiz/PageExplanationItem";
import { PageCategorisationCorrect } from "../items/quiz/PageCategorisationItem";
import { PollCommentTeacher } from "../items/poll/PollCommentItem";
import { PollMultipleChoiceTeacher } from "../items/poll/PollMultipleChoiceItem";
import { PageCrosswordCorrect } from "../items/quiz/PageCrosswordItem";
import { PageFlashcardsQuizCorrect } from "../items/quiz/PageFlashcardsQuizItem";

export const CoursePageCorrect = ({ items }: { items: CourseItem[] }) => (
  <div className="max-w-2xl w-full mx-auto relative flex flex-col gap-6 my-12 text-gray-700">
    {items.map((item) => (
      <PageItemCorrect key={item.id} item={item} />
    ))}
  </div>
);

export const PageItemCorrect = ({ item }: { item: CourseItem }) => {
  const { type } = item;

  if (item.category === "content") return <PageItemRender item={item} />;
  // if (item.category === "poll") return null;

  if (type === PageItemType.MultipleChoice)
    return <PageMultipleChoiceCorrect item={item} />;
  // if (type === PageItemType.ShortAnswer)
  //   return <PageShortAnswerCorrect item={item} />;
  if (type === PageItemType.ImageLabeling)
    return <InnerLabelingCorrect item={item} />;
  if (type === PageItemType.Sorting) return <PageSortingCorrect item={item} />;
  if (type === PageItemType.Pairing) return <PagePairingCorrect item={item} />;
  if (type === PageItemType.FillTheGap)
    return <PageFillTheGapCorrect item={item} />;
  if (type === PageItemType.Categorisation)
    return <PageCategorisationCorrect item={item} />;
  if (type === PageItemType.Crossword)
    return <PageCrosswordCorrect item={item} />;
  if (type === PageItemType.FlashcardsQuiz)
    return <PageFlashcardsQuizCorrect item={item} />;
  if (type === PageItemType.Explanation)
    return <PageExplanationRender item={item} />;

  if (type === PageItemType.CommentPoll)
    return <PollCommentTeacher item={item} />;
  if (type === PageItemType.MultipleChoicePoll)
    return <PollMultipleChoiceTeacher item={item} />;

  return null;
};

export const useSuccessRate = <T = number,>(ids: string[]) => {
  const correct = useProtectedContext(CorrectContext);

  const { session } = useSessionContext();
  const correctness = session.get.status?.correctness;

  const value = useMemo((): T | null => {
    if (!correctness) return null;
    return ids.reduce((acc, id) => acc?.[id], correctness);
  }, [correctness, ids]);

  if (!correct || value == null) return null;

  return value;
};

export const CorrectnessMarker = ({
  className = "",
  ids,
}: {
  className?: string;
  ids: string[];
}) => {
  const value = useSuccessRate(ids);
  return <CorrectnessMarkerRaw {...{ className, value }} />;
};
export const CorrectnessMarkerRaw = ({
  className = "",
  value,
}: {
  className?: string;
  value?: number | null;
}) => {
  if (value == null) return null;

  return (
    <div
      className={classNames(
        "p-2 pr-3 -mr-1 rounded-l-lg flex items-center justify-center text-center shrink-0 border-l-2 border-current bg-white text-primary relative overflow-hidden",
        className
      )}
    >
      <div className="bg-primary absolute-cover opacity-10" />
      <div className="text-sm font-bold w-9 inline-block">
        {Math.round(value * 100)}
        <span className="text-xs">%</span>
      </div>
    </div>
  );
};

export const CorrectContext = CreateProtectedContext<boolean>(false);
