import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SessionLeaderboard from "./SessionLeaderboard";
import SessionSection from "./SessionSection";
import boardIcon from "../../../../assets/images/board.svg";
import progressGraphIcon from "../../../../assets/images/progressGraph.svg";
import progressIcon from "../../../../assets/images/progress.svg";
import { useTranslation } from "react-i18next";
import { useSessionContext } from "app/hooks/useSessionContext";
import {
  CorrectContext,
  CoursePageCorrect,
} from "app/components/Exercises/CourseEdit/results/PageCorrect";
import classNames from "classnames";
import { TbPercentage } from "react-icons/tb";
import { InputToggle } from "app/components/Buttons/InputToggle";

export const CourseTeacherCorrect = () => {
  const { t } = useTranslation();
  const { session } = useSessionContext();
  const { exercise_content } = session.get.status;
  const [correct, setCorrect] = useState(false);

  return (
    <CorrectContext.Provider value={correct}>
      <div className="bg-white pt-1 pb-3 rounded-lg">
        {exercise_content?.category !== "poll" && (
          <label
            className={classNames(
              "flex items-center gap-2 rounded-lg py-1.5 px-2 cursor-pointer transition text-gray-600 mb-2",
              correct ? "bg-primary bg-opacity-40" : "bg-gray-500 bg-opacity-10"
            )}
          >
            <TbPercentage className="text-xl" />
            <span className="mr-auto">{t("liveSession.showSuccessRate")}</span>
            <InputToggle
              value={correct}
              onChange={() => setCorrect(!correct)}
            />
          </label>
        )}

        <CoursePageCorrect items={exercise_content.items} />
      </div>
    </CorrectContext.Provider>
  );
};

const TeacherResults = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const { session } = useSessionContext();
  const status = session.get?.status;
  const isLeaderboard = status.is_leaderboard;
  const isPoll = status?.exercise_content?.category === "poll";

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const tabs = [
    {
      label: t("common.tabSwitch.exercise"),
      icon: <img src={boardIcon} />,
      id: 0,
    },
    {
      label: t("common.tabSwitch.results"),
      icon: <img src={progressGraphIcon} />,
      id: 1,
    },
    {
      label: t("common.tabSwitch.leaderboard"),
      icon: <img src={progressIcon} />,
      id: isLeaderboard ? 2 : null,
    },
  ];

  return (
    <>
      {!isPoll && (
        <TabBar>
          {tabs.map(
            (t, index) =>
              t.id !== null && (
                <Tab active={tab === index} onClick={() => setTab(index)}>
                  {t?.label}
                </Tab>
              )
          )}
        </TabBar>
      )}
      {tab === 0 || isPoll ? (
        <SessionSection>
          <CourseTeacherCorrect />
        </SessionSection>
      ) : tab === 1 ? (
        <SessionSection>
          <SessionLeaderboard results />
        </SessionSection>
      ) : (
        tab === 2 && (
          <SessionSection>
            <SessionLeaderboard summary />
          </SessionSection>
        )
      )}
    </>
  );
};

export default TeacherResults;

const TabBar = styled.div`
  display: flex;
  font-weight: bold;
`;

const Tab = styled.div<{ active: boolean }>`
  cursor: pointer;
  opacity: ${({ active }) => !active && "0.6"};
  transition: all 0.1s ease-out;
  margin: 0 1px -6px;
  background: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  max-height: 40px;
  &:hover {
    transform: translateY(-2px);
  }
`;
