import React, { KeyboardEventHandler, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  ConsumptionType,
  ShortAnswer,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { usePageItemFocus } from "app/components/Exercises/CourseEdit/items/PageItemFocus";
import { withConsumption } from "app/components/Exercises/CourseEdit/render/ConsumptionContext";
import { ShortAnswerConsumption } from "app/components/Exercises/CourseEdit/render/courseConsumptionTypes";
import { TbAlertTriangle, TbX } from "react-icons/tb";
import classNames from "classnames";
import {
  InstructionsRender,
  QuizDropzone,
  QuizInstructionsRender,
  QuizItemLabel,
} from "app/components/Exercises/CourseEdit/components/generate/QuizDropzone";
import { ShortAnswerResults } from "app/components/Exercises/CourseEdit/results/courseResultsTypes";
import { CorrectMarker } from "app/components/Exercises/CourseEdit/results/PageResults";
import { CorrectnessMarker } from "app/components/Exercises/CourseEdit/results/PageCorrect";
import { useTranslation } from "react-i18next";

export const PageShortAnswerItem = () => {
  const { t } = useTranslation();
  const [item, set] = usePageItemContext<ShortAnswer>();
  const [text, setText] = useState("");

  const inputRef = useRef<HTMLTextAreaElement>(null);
  usePageItemFocus((distance, reverse) => {
    const pos = reverse ? text.length - distance : distance;
    inputRef.current?.focus();
    inputRef.current?.setSelectionRange(pos, pos);
  });

  const addCurrentValue = () => {
    if (!text) return;
    const value = text.trim();
    if (!value) return;
    setText("");
    if (item.items.includes(value)) return;

    set((item) => {
      item.items.push(value);
    });
  };

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addCurrentValue();
    }
  };

  const handleDeleteAnswer = (index: number) => (e: any) => {
    e.preventDefault();
    set((item) => {
      item.items.splice(index, 1);
    });
  };

  return (
    <PageItemWrapper>
      <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
        <QuizItemLabel
          type={item.type}
          consumptionType={[
            ConsumptionType.typeTheAnswer,
            ConsumptionType.strictTypeTheAnswer,
          ]}
        />
        <QuizDropzone />
        {item.consumptionType === ConsumptionType.strictTypeTheAnswer && (
          <div className="font-bold mx-1 text-sm flex gap-1 items-center mb-0.5 text-gray-500">
            <TbAlertTriangle className="text-lg" />
            {t("v4.item.shortAnswer.strictHint")}
          </div>
        )}
        <div className="bg-white rounded-lg p-1.5 flex items-center group">
          {/*<TbMessageCircle2 className="text-2xl text-gray-500 ml-0.5" />*/}
          <label className="flex flex-col-reverse grow cursor-text group">
            <TextareaAutosize
              // ref={ref}
              value={text || ""}
              onChange={(e) => setText(e.target.value.replace(/\r?\n/, " "))}
              placeholder={t("v4.item.shortAnswer.creationPlaceholder")}
              maxLength={50}
              onKeyDown={handleKeyDown}
              onBlur={addCurrentValue}
              className={classNames(
                "outline-none px-2 py-1 w-full flex-grow resize-none overflow-hidden m-0 min-h-full",
                !!item.items.length &&
                  "transition opacity-0 bg-transparent group-focus-within:opacity-100"
              )}
            />
            {!!item.items.length && (
              <div className="flex flex-wrap items-center gap-1 pl-2 transform transition translate-y-4 group-focus-within:translate-y-0">
                {item.items.map((answer, i) => (
                  <div
                    key={i}
                    className="bg-gray-300 flex items-center leading-none p-0.5 text-sm rounded-full font-bold"
                  >
                    <span className="px-1">{answer}</span>
                    <div
                      className="w-4 h-4 bg-gray-400 flex items-center justify-center rounded-full transition cursor-pointer hover:bg-gray-500 text-gray-200"
                      onClick={handleDeleteAnswer(i)}
                    >
                      <TbX className="text-xs" strokeWidth={4} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </label>
        </div>
      </div>
    </PageItemWrapper>
  );
};

export const PageShortAnswerRender = withConsumption<ShortAnswerConsumption>(
  ({ answer, consumptionType, set, id, type }) => {
    const { t } = useTranslation();

    return (
      <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
        <QuizItemLabel type={type} consumptionType={consumptionType} />
        <QuizInstructionsRender id={id} />
        {consumptionType === ConsumptionType.strictTypeTheAnswer && (
          <div className="font-bold mx-1 text-sm flex gap-1 items-center mb-0.5 text-gray-500">
            <TbAlertTriangle className="text-lg" />
            {t("v4.item.shortAnswer.strictHint")}
          </div>
        )}
        <div className="bg-white rounded-lg p-1.5 flex items-center">
          {/*<TbMessageCircle2 className="text-2xl text-gray-500 ml-0.5" />*/}
          <div className="flex flex-col grow">
            <input
              value={answer || ""}
              onChange={(e) => {
                set((item) => {
                  item.answer = e.target.value;
                });
              }}
              placeholder={t("v4.item.shortAnswer.consumptionPlaceholder")}
              className="outline-none px-2 py-1 w-full flex-grow resize-none overflow-hidden m-0 min-h-full"
            />
          </div>
        </div>
      </div>
    );
  }
);

export const PageShortAnswerResults = ({
  item,
}: {
  item: ShortAnswerResults;
}) => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <InstructionsRender instructions={item.instructions} />
      {item.consumptionType === ConsumptionType.strictTypeTheAnswer && (
        <div className="font-bold mx-1 text-sm flex gap-1 items-center mb-0.5 text-gray-500">
          <TbAlertTriangle className="text-lg" />
          {t("v4.item.shortAnswer.strictHint")}
        </div>
      )}
      <div className="flex items-stretch">
        <CorrectMarker
          correct={item.result}
          content={
            <div className="flex flex-col grow py-1">
              {!!item.items.length && (
                <div className="flex flex-wrap items-center gap-1 pl-2">
                  {item.items.map((answer, i) => (
                    <div
                      key={i}
                      className="bg-green-300 flex items-center leading-none p-0.5 text-sm rounded-full font-bold"
                    >
                      <span className="px-1">{answer}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          }
        />
        <div className="bg-white rounded-lg p-1.5 flex items-center grow">
          {/*<TbMessageCircle2 className="text-2xl text-gray-500 ml-0.5" />*/}
          <div className="flex flex-col grow">
            <input
              value={item.answer || ""}
              readOnly
              className="outline-none px-2 py-1 w-full flex-grow resize-none overflow-hidden m-0 min-h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const PageShortAnswerCorrect = ({ item }: { item: ShortAnswer }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <InstructionsRender instructions={item.instructions} />
      {item.consumptionType === ConsumptionType.strictTypeTheAnswer && (
        <div className="font-bold mx-1 text-sm flex gap-1 items-center mb-0.5 text-gray-500">
          <TbAlertTriangle className="text-lg" />
          {t("v4.item.shortAnswer.strictHint")}
        </div>
      )}
      <div className="flex items-stretch">
        <CorrectnessMarker ids={[item.id]} />
        <div className="bg-white rounded-lg p-1.5 flex items-center grow relative">
          {/*<TbMessageCircle2 className="text-2xl text-gray-500 ml-0.5" />*/}
          <div className="flex flex-col grow py-2">
            {!!item.items.length && (
              <div className="flex flex-wrap items-center gap-1 pl-2">
                {item.items.map((answer, i) => (
                  <div
                    key={i}
                    className="bg-gray-300 flex items-center leading-none p-0.5 text-sm rounded-full font-bold"
                  >
                    <span className="px-1">{answer}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
