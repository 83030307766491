import React from "react";
import ReactDOM from "react-dom";
import { useMutation, useQuery } from "react-query";
import { GlobalModal } from "app/components/Exercises/Edit/ExerciseModal";
import { SendView } from "app/components/Tables/SharedExercise";
import { useTranslation } from "react-i18next";
import { isAfter } from "date-fns";
import { getGroupPin, postNewGroupPin } from "api/groups/groupsAPI";
import { CgSpinner } from "react-icons/cg";
import { groupRoutes } from "enums/routes";

type Props = {
  id: string;
  close?: () => void;
};

const isExpired = (data) =>
  data?.pin_expiration_date &&
  isAfter(new Date(), new Date(data?.pin_expiration_date));

export const postSendGroup = async (id, regen = false) => {
  const send = (create = false) => {
    if (create) return postNewGroupPin(id);
    return getGroupPin(id);
  };

  if (regen) return send(true);

  const result = await send(false);
  if (!result.pin || isExpired(result)) return send(true);

  return result;
};

export const SharedGroup = ({ id, close }: Props) => {
  const { t } = useTranslation();
  const query = useQuery({
    queryKey: ["group_send", id],
    queryFn: () => postSendGroup(id),
    refetchOnWindowFocus: false,
  });

  const regen = useMutation(() => postSendGroup(id, true), {
    onSuccess: query.refetch,
  });

  return ReactDOM.createPortal(
    <GlobalModal close={close}>
      {!query.isSuccess ||
      regen.isLoading ||
      (isExpired(query.data) && !query.isSuccess) ? (
        <CgSpinner className="animate-spin text-3xl m-auto" />
      ) : (
        <>
          <h2 className="text-2xl text-center mb-4 mx-auto">
            {t("v4.group.inviteTo")}
          </h2>
          <SendView
            due={query.data?.pin_expiration_date}
            pin={query.data.pin}
            link={window.location.origin + groupRoutes.join(query.data.pin)}
            generate={regen.mutate}
          />
        </>
      )}
    </GlobalModal>,
    document.body
  );
};
