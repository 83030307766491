import { ComponentProps } from "react";

export const PINIcon = (props: ComponentProps<"svg">) => (
  <svg viewBox="0 0 24 24" fill="none" height="1em" width="1em" {...props}>
    <rect
      x="1"
      y="4"
      width="22"
      height="16"
      rx="3"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M4.764 15.546V9h2.704c.49 0 .912.096 1.268.288.358.19.634.455.828.796.194.338.29.732.29 1.182 0 .452-.098.847-.296 1.186a1.993 1.993 0 0 1-.84.783c-.365.185-.798.278-1.298.278H5.75v-1.247h1.375c.238 0 .437-.041.597-.124a.855.855 0 0 0 .368-.349c.083-.151.124-.327.124-.527 0-.202-.041-.377-.124-.524a.836.836 0 0 0-.368-.345 1.327 1.327 0 0 0-.597-.122h-.78v5.27H4.764zM12.577 9v6.546h-1.582V9h1.582zM19.5 9v6.546h-1.342l-2.605-3.778h-.041v3.777H13.93V9h1.361l2.576 3.771h.055V9H19.5z"
      fill="currentColor"
    />
  </svg>
);
