import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
// import LoginForm from "app/components/Forms/LoginForm";
import {
  authRoutes,
  courseRoutes,
  groupRoutes,
  guestCourseRoutes,
  libraryRoutes,
} from "enums/routes";
import { useRole } from "app/hooks/useRole";
import { toast } from "react-hot-toast";
import {
  TbAlertTriangleFilled,
  TbArrowRight,
  TbAt,
  TbEye,
  TbEyeOff,
  TbKey,
  TbUser,
} from "react-icons/tb";
import { matchPath, useLocation } from "react-router";
import { useGlobalQuery } from "app/App";
import useOnClickOutside from "use-onclickoutside";
import { LoginGoogle } from "app/pages/auth/LoginGoogle";
import { LoginApple } from "app/pages/auth/LoginApple";
import { LoginMicrosoft } from "app/pages/auth/LoginMicrosoft";
import { InputText } from "app/components/Buttons/InputText";
import { NewButton } from "app/components/Buttons/NewButton";
import { CgSpinner } from "react-icons/cg";
import { Link, useHistory } from "react-router-dom";
import { OrSeparator } from "app/pages/auth/Registration";
import { AppDispatch } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading, userSignIn } from "app/store/authentication";
import LoadingStatus from "enums/loading";
import { translateError } from "app/components/Exercises/Edit/missingTranslation";
import { useMutation, useQuery } from "react-query";
import { getCanGuestJoinGroup, postJoinGuestGroup } from "api/groups/groupsAPI";

export const AuthSection = styled.section`
  margin: 48px auto;
`;

export const LoginView = () => {
  const { t } = useTranslation();
  const location = useLocation<{
    requestedPath?: string;
    requestedState?: { guest: string };
  }>();
  const role = useRole();
  const isLoggedInRedirect =
    role.guest &&
    (!!matchPath(location?.state?.requestedPath ?? "", groupRoutes.join()) ||
      !!matchPath(
        location?.state?.requestedPath ?? "",
        libraryRoutes.share()
      ) ||
      !!matchPath(location?.state?.requestedPath ?? "", courseRoutes.share()));

  const groupPin = matchPath<{ pin: string }>(
    location?.state?.requestedPath ?? "",
    groupRoutes.join()
  )?.params?.pin;

  const joinGroupGuest = useQuery({
    enabled: !!groupPin,
    queryKey: ["canJoinGroup", groupPin],
    queryFn: () => getCanGuestJoinGroup(groupPin ?? ""),
    // onSuccess: (data, pin) => {
    //   if (!data?.guest_access) return history.push(groupRoutes.join(pin));
    //   history.push({ pathname: groupRoutes.join(pin), state: { guest: pin } });
    // },
    // onError: () => {
    //   toast.error(translateError(t("v4.group.joinError"), t));
    // },
  });

  useEffect(() => {
    if (!isLoggedInRedirect || joinGroupGuest.isLoading) return;
    toast(() => (
      <span className="flex items-center gap-2 text-gray-500">
        <TbAlertTriangleFilled className="text-xl -ml-2 mt-0.5" />
        {t("assignments.joinLoginPrompt")}
      </span>
    ));
  }, [isLoggedInRedirect, joinGroupGuest.isLoading]);

  if (joinGroupGuest.isLoading)
    return (
      <div className="p-8 m-auto">
        <CgSpinner className="text-4xl animate-spin mx-auto" />
      </div>
    );

  return (
    <>
      <AuthSection>
        <h1 className="text-4xl mx-auto text-center font-bold mb-8">
          {t("authPages.signIn.submitBtn.label")}
        </h1>

        <LoginForm />

        <div className="text-center font-bold text-gray-600 mt-12">
          {t("v4.account.registerPrompt")}{" "}
          <Link
            to={authRoutes.register}
            className="text-primary hover:opacity-50 transition inline"
          >
            {t("authPages.register.submitBtn.label")}
          </Link>
        </div>

        {!!joinGroupGuest.data?.guest_access && !!groupPin && (
          <GuestGroupForm pin={groupPin} />
        )}
      </AuthSection>
    </>
  );
};

// GZYYP
// http://localhost:8008/groups/join/GZYYP
// UEFWL
const GuestGroupForm = ({ pin }: { pin: string }) => {
  const { t } = useTranslation();
  const history = useHistory();

  console.log(pin);

  const [guestName, setGuestName] = useState("");

  const joinGroupGuest = useMutation(
    ({ pin, name }: { pin: string; name: string }) =>
      postJoinGuestGroup(pin, name),
    {
      onSuccess: ({ guest_pin }, { pin }) => {
        history.replace(guestCourseRoutes.list(guest_pin));
      },
      onError: () => {
        toast.error(translateError(t("v4.group.joinError"), t));
      },
    }
  );

  return (
    <>
      <OrSeparator className="w-full max-w-md mx-auto my-12 text-gray-500" />
      <form
        className="flex flex-col items-center gap-2"
        onSubmit={(e) => {
          e.preventDefault();
          if (guestName.length < 3) return;
          joinGroupGuest.mutate({ pin, name: guestName });
        }}
      >
        <InputText
          placeholder={t("v4.group.guest.namePrompt")}
          value={guestName}
          onChange={setGuestName}
          icon={TbUser}
          readOnly={joinGroupGuest.isLoading}
        />
        <NewButton
          center
          variant="primary"
          className="font-bold mx-auto"
          size="lg"
          disabled={joinGroupGuest.isLoading || guestName.length < 3}
          component="button"
          type="submit"
          // onClick={() => setGuest(true)}
        >
          {t("packagesPage.doPackage.joinAsGuest")}
          {joinGroupGuest.isLoading ? (
            <CgSpinner className="animate-spin" />
          ) : (
            <TbArrowRight />
          )}
        </NewButton>
      </form>
    </>
  );
};

export const LoginForm = () => {
  const isLoading = useSelector(selectLoading);
  const dispatch: AppDispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const globalQuery = useGlobalQuery();

  const ref = useRef<HTMLLabelElement | null>(null);
  const [passwordHidden, setPasswordHidden] = useState(true);
  useOnClickOutside(ref, () => setPasswordHidden(true));

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await dispatch<any>(userSignIn({ email, password }));

    if (!userSignIn.fulfilled.match(res)) {
      toast.error(t("authPages.signIn.errors.general"));
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col-reverse sm:flex-row items-center w-full gap-10 max-w-2xl mx-auto">
        {globalQuery.data?.is_org_social_login && (
          <>
            <div className="flex flex-col gap-2 my-auto grow">
              <LoginGoogle />
              <LoginApple />
              <LoginMicrosoft />
            </div>
            <OrSeparator
              vertical
              className="self-stretch hidden sm:flex text-gray-500"
            />
            <OrSeparator className="sm:hidden w-full text-gray-500" />
          </>
        )}
        <form
          className="flex flex-col gap-2 my-auto sm:pt-10 grow max-w-xs mx-auto"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <InputText
            icon={TbAt}
            value={email}
            onChange={setEmail}
            placeholder={t("v4.account.email")}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
          />
          <InputText
            icon={TbKey}
            value={password}
            onChange={setPassword}
            placeholder={t("v4.account.password")}
            type={passwordHidden ? "password" : "text"}
            ref={ref}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
          >
            <NewButton
              variant="transparent"
              className="px-2"
              iconOnly
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setPasswordHidden(!passwordHidden);
              }}
            >
              {passwordHidden ? <TbEye /> : <TbEyeOff />}
            </NewButton>
          </InputText>

          <div className="text-center text-sm text-gray-600 my-2">
            <Link
              to={authRoutes.forgotPassword}
              className="text-primary hover:opacity-50 transition inline"
            >
              {t("authPages.signIn.forgotPassword.label")}
            </Link>
          </div>

          <NewButton
            variant="primary"
            className="ml-auto"
            size="lg"
            component="button"
            type="submit"
            disabled={
              !email || !password || isLoading === LoadingStatus.pending
            }
          >
            {isLoading === LoadingStatus.pending && (
              <CgSpinner className="animate-spin" />
            )}
            {t("authPages.signIn.submitBtn.label")}
          </NewButton>
        </form>
      </div>
    </div>
  );
};
