import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalFlexStyles } from "app/components/Helpers/GlobalFlexStyles";
import { courseRoutes } from "enums/routes";
import {
  PageContextProvider,
  PageItemIndexContext,
  TopLevelIndexContext,
  usePageContext,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import { CgSpinner } from "react-icons/cg";
import {
  COURSE_ITEM_DATA,
  CourseItem,
  Page,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { PageItem } from "app/components/Exercises/CourseEdit/items/PageItem";
import { useMutation, useQuery } from "react-query";
import {
  getCourse,
  getCourseName,
  postNewCourse,
  postNewCoursePage,
} from "api/course/courseAPI";
import {
  CourseHeader,
  TrainingPreview,
} from "app/pages/courses/Edit/CourseHeader";
import { LeftPanel } from "app/pages/courses/Edit/LeftPanel";
import { GapInsertButton } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import {
  TbArrowDown,
  TbArrowLeft,
  TbFileText,
  TbPencil,
  TbX,
} from "react-icons/tb";
import { useWindowSize } from "react-use";
import { SlideCoursePage } from "app/components/Exercises/CourseEdit/slide/SlideCoursePage";
import {
  DropMediaColumns,
  DropMediaType,
  useDropMedia,
} from "app/components/Sources/MediaPicker/context/dropMediaContext";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  paragraphsToText,
  paragraphToText,
} from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { truncate } from "helpers/textUtils";
import { ProgressiveCanvas } from "app/components/Helpers/ProgressiveImage";
import { COURSE_ITEMS } from "app/components/Exercises/CourseEdit/items/InsertPageItem";
import { useRole } from "app/hooks/useRole";

export const CourseEditView = (props: { new?: boolean }) => {
  const role = useRole();
  const history = useHistory();
  const [, setDropMedia] = useDropMedia();
  const { courseId, pageId } =
    useParams<{ courseId: string; pageId: string }>();

  const { width } = useWindowSize();

  useEffect(() => {
    return () => {
      setDropMedia(null);
    };
  }, []);

  const [throwError, setThrowError] = useState(false);

  // @ts-ignore
  if (!role.prodMode) window.throwError = () => setThrowError(true);
  // @ts-ignore
  if (throwError) return { testError: "yes" } as JSX.Element;

  if (width < 768)
    return (
      <TrainingPreview
        courseId={courseId}
        defaultPageId={pageId}
        close={() => history.push(courseRoutes.details(courseId))}
      />
    );

  return (
    <PageContextProvider courseId={courseId} pageId={pageId}>
      <InnerCourseView new={props.new} />
    </PageContextProvider>
  );
};

const InnerCourseView = (props: { new?: boolean }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const category = usePageContext((store) => store.data?.category);
  const loading = usePageContext((store) => store.loading);
  const { courseId, pageId } =
    useParams<{ courseId: string; pageId: string }>();

  const course = useQuery({
    queryFn: () => getCourse(courseId),
    queryKey: ["course", courseId],
    refetchOnWindowFocus: false,
    enabled: !!courseId,
  });

  // redirect to first page available
  useEffect(() => {
    if (!pageId && course.isSuccess && course.data.pages.length) {
      history.replace(courseRoutes.editPage(courseId, course.data.pages[0].id));
    }
  }, [course.isSuccess, pageId]);

  const newCourse = useMutation(
    () => postNewCourse(getCourseName(t, i18n.language)),
    {
      onSuccess: ({ id }) => {
        history.replace(courseRoutes.edit(id));
      },
    }
  );

  const newPage = useMutation(
    () =>
      postNewCoursePage(courseId, {
        category: "slide",
      }),
    {
      onSuccess: ({ id }) => {
        history.replace(courseRoutes.editPage(courseId, id));
        course.refetch();
      },
    }
  );

  useEffect(() => {
    if (props?.new && !newCourse.isLoading) {
      newCourse.mutate();
    }
  }, [!!props?.new]);

  useEffect(() => {
    if (course.isSuccess && !course.data.pages?.length) {
      newPage.mutate();
    }
  }, [course.isSuccess]);

  return (
    <div className="absolute w-full h-full left-0 top-0 flex flex-col">
      <GlobalFlexStyles />
      <div className="flex-grow relative flex">
        <DropMediaOverlay />
        {!!courseId && !!pageId && <LeftPanel />}
        <div className="flex-grow flex flex-col">
          <CourseHeader />
          <DropMediaInfo />
          <div
            id="exercise_container"
            className="flex flex-col flex-grow relative ml-4"
          >
            {!pageId ||
            loading ||
            newCourse.isLoading ||
            newPage.isLoading ||
            !course.isSuccess ||
            category == null ? (
              <div className="m-auto">
                <CgSpinner className="m-auto my-8 text-4xl animate-spin text-gray-500" />
              </div>
            ) : (
              <div
                id="exercise_scroll"
                className="absolute pr-4 w-full h-full left-0 top-0 overflow-y-auto"
              >
                {category === "slide" ? <SlideCoursePage /> : <CoursePage />}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const DropMediaOverlay = () => {
  const { t } = useTranslation();
  const [dropMedia] = useDropMedia();
  const [pos, setPos] = useState<null | [number, number]>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  const item = (dropMedia && COURSE_ITEM_DATA?.(t)?.[dropMedia.type]) || null;

  useEffect(() => {
    if (!item) return;

    const move = (e: PointerEvent) => {
      const { clientX, clientY } = e;
      const box = ref.current?.getBoundingClientRect();
      if (!box) return;
      setPos([clientX - box.left, clientY - box.top]);
    };

    document.addEventListener("pointermove", move);
    return () => {
      document.removeEventListener("pointermove", move);
    };
  }, [!!item]);

  if (!item) return null;
  if (!dropMedia) return null;

  return (
    <div
      className="left-0 top-0 w-full h-full fixed pointer-events-none opacity-75 text-gray-600 overflow-hidden z-10"
      ref={ref}
    >
      {pos && (
        <div className="absolute left-0 top-0 w-[150%] h-[150%]">
          <div
            className="absolute p-1 rounded-lg shadow-xl border border-gray-100 rounded-tl-none bg-white max-w-xs"
            style={{ left: pos[0], top: pos[1] }}
          >
            <div className="flex items-center gap-2 font-bold bg-gray-100 rounded-lg py-1 px-2 whitespace-nowrap">
              <item.icon className="text-xl shrink-0" />
              {item.name}
            </div>
            {dropMedia.type === DropMediaColumns.OneColumn && (
              <div className="p-2 text-sm w-full text-center flex flex-col gap-1">
                {dropMedia.data.slice(0, 4).map((text, i) => (
                  <div key={i}>{truncate(text, 10)}</div>
                ))}
                {dropMedia.data.length > 4 && <div>...</div>}
              </div>
            )}
            {dropMedia.type === DropMediaColumns.TwoColumns && (
              <div className="p-2 text-sm w-full grid grid-cols-2 gap-1">
                {dropMedia.data.slice(0, 4).map(([left, right], i) => (
                  <Fragment key={i}>
                    <div className="text-left">{truncate(left, 10)}</div>
                    <div className="text-right">{truncate(right, 10)}</div>
                  </Fragment>
                ))}
                {dropMedia.data.length > 4 && (
                  <div className="col-span-2 text-center">...</div>
                )}
              </div>
            )}
            {dropMedia.type === PageItemType.Paragraph && (
              <div
                className="p-2 text-sm w-full"
                style={{ textAlign: dropMedia.align }}
              >
                {truncate(paragraphsToText(dropMedia.data))}
              </div>
            )}
            {dropMedia.type === PageItemType.List && (
              <ul className="p-2 text-sm w-full list-disc list-inside">
                {dropMedia.data.slice(0, 3).map((data, i) => (
                  <li
                    key={i}
                    className="w-full overflow-x-clip overflow-ellipsis"
                  >
                    {truncate(paragraphToText(data), 30)}
                  </li>
                ))}
                {dropMedia.data.length > 3 && "..."}
              </ul>
            )}
            {dropMedia.type === PageItemType.Image && (
              <ProgressiveCanvas
                className="max-w-full max-h-20 rounded-lg mt-1"
                crop={dropMedia.data}
                src={dropMedia.data.src}
                thumbOnly
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const QUIZ_TYPES = [
  PageItemType.MultipleChoice,
  PageItemType.ShortAnswer,
  PageItemType.Sorting,
  PageItemType.Pairing,
  PageItemType.ImageLabeling,
  PageItemType.FillTheGap,
  PageItemType.Explanation,
  PageItemType.MultipleChoiceGenerate,
  PageItemType.MultipleResponseGenerate,
  PageItemType.Categorisation,
  PageItemType.Crossword,
] as DropMediaType["type"][];

export const canInsertToPage = (
  type: false | DropMediaType["type"] | null,
  page: Partial<Pick<Page, "category" | "quizType">>
) => {
  if (!type) return false;
  const { category, quizType } = page;

  if (category === "poll") {
    switch (type) {
      case PageItemType.CommentPoll:
      case PageItemType.MultipleChoicePoll:
        return true;
    }
    return false;
  }

  // inserting in quiz
  if (category === "quiz") {
    if (type === DropMediaColumns.OneColumn) return true;
    if (type === DropMediaColumns.TwoColumns) return true;

    switch (type) {
      case PageItemType.MultipleChoiceGenerate:
      case PageItemType.MultipleResponseGenerate:
      case PageItemType.MultipleChoice:
      case PageItemType.ShortAnswer:
      case PageItemType.Sorting:
      case PageItemType.Pairing:
      case PageItemType.ImageLabeling:
      case PageItemType.FillTheGap:
      case PageItemType.Explanation:
      case PageItemType.Categorisation:
      case PageItemType.Crossword:
      case PageItemType.FlashcardsQuiz:
      case PageItemType.Flashcards:
        return true;
    }
  }

  if (
    (quizType === PageItemType.Flashcards ||
      quizType === PageItemType.FlashcardsQuiz) &&
    type === DropMediaColumns.TwoColumns
  )
    return true;
  // inserting not in quiz
  switch (type) {
    case PageItemType.ParagraphSummarize:
    case PageItemType.ParagraphSimplify:
    case PageItemType.ParagraphNext:
    case PageItemType.ParagraphParaphrase:
    case PageItemType.ParagraphTranslate:
    case PageItemType.Paragraph:
    case PageItemType.List:
    case PageItemType.Image:
    case PageItemType.Video:
    case PageItemType.Audio:
    case PageItemType.Embed:
      return true;
    case PageItemType.Flashcards:
    case PageItemType.FlashcardsQuiz:
      return category === "content" && quizType === PageItemType.Flashcards;
    case PageItemType.Columns:
      return category === "content";
  }

  return false;
};

const DropMediaInfo = () => {
  const { t } = useTranslation();
  const [dropMedia, setDropMedia] = useDropMedia();
  const category = usePageContext((state) => state.data?.category);
  const quizType = usePageContext((state) => state.data?.quizType);

  if (!dropMedia) return null;

  return (
    <div className="bg-primary text-white h-12 flex items-center p-2 gap-4">
      <div className="font-bold pl-2 flex items-center">
        {canInsertToPage(dropMedia?.type, { category, quizType }) ? (
          <>
            <TbArrowDown className="text-xl mr-1.5 shrink-0" />
            {t("v4.editView.dropPrompt")}
          </>
        ) : dropMedia.type && QUIZ_TYPES.includes(dropMedia.type) ? (
          <>
            <TbArrowLeft className="text-xl mr-1.5 shrink-0" />
            <TbPencil className="text-xl mr-1.5 shrink-0" />
            {t("v4.editView.dropQuizPrompt")}
          </>
        ) : (
          <>
            <TbArrowLeft className="text-xl mr-1.5 shrink-0" />
            <TbFileText className="text-xl mr-1.5 shrink-0" />
            {t("v4.editView.dropPagePrompt")}
          </>
        )}
      </div>
      <NewButton
        color="bg-white text-white"
        variant="strongLight"
        className="font-bold ml-auto"
        onClick={() => setDropMedia(null)}
      >
        <TbX /> {t("v4.generic.cancel")}
      </NewButton>
    </div>
  );
};

const CoursePage = () => {
  const items = usePageContext(({ data }) => data!.items as CourseItem[]);
  const setList = usePageContext((state) => state.setList);
  // const setFocus = usePageFocusContext((state) => state.select);

  const handleDrop = ({ destination, source }: any) => {
    if (!source || !destination) return;
    if (source.index === destination.index) return;

    setList((list) => {
      const [item] = list.splice(source.index, 1);
      list.splice(destination.index, 0, item);
    });
  };

  // useEffect(() => {
  //   if (items?.length) {
  //     setFocus(items[items.length - 1].id, 0, true);
  //   }
  // }, [!!items]);

  return (
    <div className="max-w-4xl w-full mx-auto">
      <PageSettingsPanel />
      <div className="bg-white text-gray-700 py-4 pl-[4.5rem] pr-11 xl:px-4 rounded-2xl mx-auto mb-6">
        <div className="flex flex-col">
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="page-items">
              {({ innerRef, droppableProps, placeholder }) => (
                <div ref={innerRef} {...droppableProps}>
                  {items.map((item, i) => (
                    <TopLevelIndexContext.Provider
                      key={item.id}
                      value={[i, item.id]}
                    >
                      <PageItemIndexContext.Provider
                        key={item.id}
                        value={[i, item.id]}
                      >
                        <PageItem type={item.type} index={i} />
                      </PageItemIndexContext.Provider>
                    </TopLevelIndexContext.Provider>
                  ))}
                  {placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        <GapInsertButton index={items.length} big />
      </div>
    </div>
  );
};

export const pagePlaceholder =
  (data?: Pick<Page, "category" | "quizType"> | null) => (t: any) => {
    const quizTypeName =
      data?.quizType &&
      data.category === "content" &&
      COURSE_ITEMS(t)?.[data.quizType]?.name;
    if (quizTypeName) return quizTypeName;
    if (data?.category === "poll") return t("v4.poll.text");
    if (data?.category !== "quiz") return t("v4.page.text");
    return t("v4.quiz.text");
  };

export const PageName = ({ className = "" }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const placeholder = usePageContext(({ data }) => pagePlaceholder(data))(t);

  const [pageName_, setPageName_] = usePageContext((state) => [
    state.data?.category !== "slide" && state.data?.name,
    state.setName,
  ]);

  const [pageName, setPageName] = useState(
    pageName_ === placeholder ? "" : pageName_ || ""
  );
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) return;
    if (document.activeElement === ref.current) return;

    setPageName(pageName_ === placeholder ? "" : pageName_ || "");
  }, [pageName_]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <input
      className={className}
      placeholder={placeholder}
      value={pageName}
      onChange={(e) => {
        setPageName(e.target.value);
        setPageName_(e.target.value || placeholder);
      }}
      ref={ref}
    />
  );
};

const PageSettingsPanel = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white text-gray-700 rounded-2xl mt-4 flex flex-col mb-2">
      <div className="relative">
        <div className="text-gray-400 font-bold top-4 left-6 absolute flex items-center gap-1.5">
          {t("v4.page.name")}
        </div>
        <PageName className="text-3xl font-bold outline-none w-full px-6 pb-3 pt-9 text-gray-600 bg-white rounded-2xl leading-none" />
      </div>
    </div>
  );
};
