import { useFocused, useSlate } from "slate-react";
import { useTranslation } from "react-i18next";
import React, { useMemo, useState } from "react";
import { Editor, Range, Transforms } from "slate";
import { createPortal } from "react-dom";
import classNames from "classnames";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbPlus, TbWand } from "react-icons/tb";
import { uuid } from "app/components/Exercises/utils/uuid";
import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  ConsumptionType,
  FillTheGap,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { useFTGContext } from "app/components/Exercises/CourseEdit/items/quiz/PageFillTheGapItem";
import { useRole } from "../../../../../hooks/useRole";

export const FillTheGapButton = () => {
  const role = useRole();
  const [item] = usePageItemContext<FillTheGap>();
  const editor = useSlate();
  const inFocus = useFocused();
  const select = useFTGContext((store) => store.select);
  const { t } = useTranslation();
  const [position, setPosition] = useState<{ x: number; y: number } | null>(
    null
  );

  const isMcq = item.consumptionType === ConsumptionType.multipleChoiceSemantic;
  const element = document.body;

  const handleCreate = (
    e: React.MouseEvent<HTMLButtonElement>,
    generate = false
  ) => {
    e.preventDefault();
    if (!editor.selection) return;
    const id = uuid();

    const answer =
      (editor.selection &&
        !Range.isCollapsed(editor.selection) &&
        Editor.string(editor, editor.selection)) ||
      "";

    const trimmed = answer.trim().substring(0, 50);
    const trimStart = answer.indexOf(trimmed);
    const trimEnd = answer.length - trimStart - trimmed.length;

    Transforms.move(editor, { distance: trimStart, edge: "start" });
    const start = Range.start(editor.selection);
    Transforms.move(editor, {
      distance: trimEnd,
      edge: "end",
      reverse: true,
    });
    const end = Range.end(editor.selection);
    Transforms.select(editor, {
      anchor: start,
      focus: end,
    });

    Transforms.insertNodes(
      editor,
      {
        type: "gap",
        data: trimmed,
        id,
        hint: "",
        ...(isMcq && { options: [] }),
        ...(isMcq && generate && { generating: true }),
        children: [{ text: "" }],
      },
      {
        at: inFocus ? editor.selection : Editor.end(editor, []),
      }
    );
    select(id);
  };

  const visible = useMemo(() => {
    const { selection } = editor;
    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ""
    )
      return false;

    const domSelection = window.getSelection();
    if (!domSelection || !domSelection.rangeCount) return null;
    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    setPosition({
      y: rect.top + (element?.scrollTop || 0) - elementRect.top,
      x: rect.left + rect.width / 2 - elementRect.left,
    });
    return true;
  }, [editor.selection, element?.scrollTop, inFocus]);

  return createPortal(
    <div
      style={{ left: position?.x, top: position?.y }}
      className={classNames(
        "flex absolute z-[1] bg-white rounded-xl p-1 gap-1 border border-gray-100 shadow-xl transform -translate-y-[110%] -translate-x-1/2 transition",
        !visible && "opacity-0 pointer-events-none"
      )}
      onPointerDown={(e) => e.preventDefault()}
    >
      {!isMcq || !role.aiEnabled ? (
        <NewButton
          onClick={handleCreate}
          onPointerDown={(e) => e.preventDefault()}
          className="font-bold text-sm"
          color="bg-primary text-primary"
        >
          <TbPlus />
          {t("common.new")}
        </NewButton>
      ) : (
        <>
          <NewButton
            onClick={handleCreate}
            onPointerDown={(e) => e.preventDefault()}
            className="font-bold text-sm"
          >
            <TbPlus />
            {t("common.new")}
          </NewButton>
          <NewButton
            onClick={(e) => handleCreate(e, true)}
            onPointerDown={(e) => e.preventDefault()}
            className="font-bold text-sm"
            color="bg-primary text-primary"
          >
            <TbWand />
            {t("v4.generate.text")}
          </NewButton>
        </>
      )}
    </div>,
    element
  );
};
