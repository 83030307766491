export const lessonRoutes = {
  list: "/lessons",
  folders: "/lessons/folders",
  share: (pin = ":pin") => `/lessons/share/${pin}`,
  test: (id = ":id") => `/test/${id}`,
  create: "/lessons/edit",
  edit: (id = ":id") => `/lessons/edit/${id}`,
};

export const courseRoutes = {
  list: "/trainings",
  share: (pin = ":pin") => `/trainings/share/${pin}`,

  details: (courseId = ":courseId") => `/trainings/${courseId}`,
  activity: (courseId = ":courseId", activityId = ":activityId") =>
    `/trainings/${courseId}/activity/${activityId}`,

  test: (courseId = ":courseId") => `/trainings/${courseId}/test`,
  pageTest: (courseId = ":courseId", pageId = ":pageId") =>
    `/trainings/${courseId}/test/${pageId}`,

  new: `/trainings/edit/new`,
  edit: (courseId = ":courseId") => `/trainings/edit/${courseId}`,
  editPage: (courseId = ":courseId", pageId = ":pageId") =>
    `/trainings/edit/${courseId}/${pageId}`,
};

export const guestCourseRoutes = {
  list: (guestPin = ":guestPin") => `/guest/${guestPin}/trainings`,
  test: (guestPin = ":guestPin", courseId = ":courseId") =>
    `/guest/${guestPin}/trainings/${courseId}/test`,
  pageTest: (
    guestPin = ":guestPin",
    courseId = ":courseId",
    pageId = ":pageId"
  ) => `/guest/${guestPin}/trainings/${courseId}/test/${pageId}`,
};

export const libraryRoutes = {
  library: "/library",
  public: "/library/public",
  share: (pin = ":pin") => `/library/share/${pin}`,
};

export const groupRoutes = {
  list: "/groups",
  details: (id = ":id") => `/groups/${id}`,
  assignments: (id = ":id") => `/groups/${id}/assignments`,
  settings: (id = ":id") => `/groups/${id}/settings`,
  members: (id = ":id") => `/groups/${id}/members`,
  join: (pin = ":pin") => `/groups/join/${pin}`,
};

export const sessionRoutes = {
  startSession: (courseId = ":courseId", lesson = false) =>
    `/session/new/${courseId}` + (lesson ? `?lesson=true` : ""),
  session: (id = ":id") => `/session/${id}`,
};

export const adminRoutes = {
  admin: "/admin",
  admin_users: "/admin/users",
  admin_users_new: "/admin/users/new",
  admin_user_details: (id = ":id") => `/admin/users/${id}`,
  admin_organizations: "/admin/organizations",
  admin_organization: (id = ":id") => `/admin/organizations/${id}`,
};

export const authRoutes = {
  login: "/login",
  register: "/register",
  forgotPassword: "/forgot",
  resetPassword: (uid = ":uid", token = ":token") =>
    `/reset-password/${uid}/${token}`,
  activate: (uid = ":uid", token = ":token") => `/activate/${uid}/${token}`,
};

export const authRoutesList = Object.values(authRoutes).map((path) =>
  typeof path === "function" ? path() : path
);

export const publicRoutes = {
  terms: "/terms-and-privacy",
  unauthorized: "/404",
  not_found: "/404",
  home: "/",
  legacy_home: "/home",
  help: "/help",
};

export const miscRoutes = {
  subscriptions: `/subscriptions`,
  checkoutSuccess: `/checkout_success`,
  trialSuccess: `/trial_success`,
};

// TODO: everything under this has to be moved

export enum AuthUserRoutes {
  session = "/session/:id",
  logout = "/logout",
}

export enum GuestRoutes {
  signIn = "/login",
  registration = "/register",
  mobile_upload = "/upload/:id",
}
