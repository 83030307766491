import React, { useEffect, useState } from "react";
import { LiveSessionHeader } from "./Header";
import styled from "styled-components";
import watermark from "assets/images/logo-watermark.svg";
import StudentView from "./StudentView";
import TeacherView from "./TeacherView";
import { useSessionContext } from "app/hooks/useSessionContext";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { AuthUserRoutes, publicRoutes, sessionRoutes } from "enums/routes";
import { selectUserDetails } from "app/store/user";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Transition from "./transition";
import { screenAwake } from "../utils/screenAwake";
import { GlobalFlexStyles } from "app/components/Helpers/GlobalFlexStyles";
import { TbAlertTriangle, TbArrowBackUp, TbHome } from "react-icons/tb";
import { NewButton } from "app/components/Buttons/NewButton";
import { ConsumptionContextProvider } from "app/components/Exercises/CourseEdit/render/ConsumptionContext";

const LiveSession = (props) => {
  const { t } = useTranslation();
  const userDetails = useSelector(selectUserDetails);
  const { isTeacher, creationStep, session } = useSessionContext();
  const history = useHistory();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const isStart = useRouteMatch(sessionRoutes.startSession())?.isExact;

  useEffect(() => {
    if (isStart) {
      isTeacher.set(true);
      creationStep.set(0);
    }
    if (localStorage.getItem("joining") !== "true") {
      props.session.joinSession();
    }
    screenAwake().getScreenLock();
    return () => {
      screenAwake().release();
    };
  }, []);

  useEffect(() => {
    if (props.session) {
      session.set(props.session);
    }
    if (props.session.status?.pin) {
      creationStep.set(1);
    }
    if (props.session.status?.teacher?.id === userDetails.id) {
      isTeacher.set(true);
    }
  }, [props.session, props.session?.pin, props.session?.current_exercise]);

  useEffect(() => {
    if (creationStep.get === 1) {
      return history.push(
        AuthUserRoutes.session.replace(":id", props.session.status.pin)
      );
    }
  }, [creationStep.get]);

  const { exercise_content } = session.get?.status ?? {};

  return (
    <ConsumptionContextProvider items={exercise_content?.items ?? []}>
      <div className="flex-1 flex flex-col w-full overflow-hidden bg-gray-200 text-gray-700">
        <ScWatermark src={watermark} />
        <LiveSessionHeader {...{ isFullscreen, setIsFullscreen }} />
        <GlobalFlexStyles />
        <>
          {session.get?.state &&
            (!session.get.pinValid ||
            session.get.sessionLocked ||
            session.get.userBanned ||
            session.get.nameTaken ||
            session.get.userRejected ? (
              <Transition className="flex-1 flex">
                {!session.get.pinValid && (
                  <Warning>{t("liveSession.invalidPin")}</Warning>
                )}
                {session.get.sessionLocked && (
                  <Warning>{t("liveSession.locked")}</Warning>
                )}
                {session.get.userRejected && (
                  <Warning>{t("liveSession.rejected")}</Warning>
                )}
                {session.get.nameTaken && (
                  <Warning>
                    {t("liveSession.nameTakenInfo")}
                    <NewButton
                      size="lg"
                      variant="primary"
                      to={publicRoutes.home}
                      className="mt-8 text-base"
                      onClick={() => {
                        localStorage.removeItem("gid");
                        window.location.reload();
                      }}
                    >
                      <TbArrowBackUp />
                      {t("liveSession.changeName")}
                    </NewButton>
                  </Warning>
                )}
                {session.get.userBanned && (
                  <Warning>
                    {t("liveSession.kickInfo")}
                    <NewButton
                      size="lg"
                      variant="primary"
                      to={publicRoutes.home}
                      className="mt-8 text-base"
                      onClick={() => {
                        localStorage.removeItem("gid");
                        window.location.reload();
                      }}
                    >
                      <TbArrowBackUp />
                      {t("liveSession.changeName")}
                    </NewButton>
                  </Warning>
                )}
              </Transition>
            ) : !isTeacher.get ? (
              <StudentView />
            ) : (
              <TeacherView {...{ isFullscreen }} />
            ))}
        </>
      </div>
    </ConsumptionContextProvider>
  );
};

export default LiveSession;

export const ScWatermark = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: calc(100% - 12px);
  width: 90%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  filter: invert();
`;

const Warning = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-200 rounded-xl p-4 sm:p-8 md:p-16 m-4 text-center flex flex-col items-center justify-center text-3xl text-gray-500 font-bold flex-1">
      <TbAlertTriangle className="text-5xl mb-2" />
      {children}
      <NewButton
        size="lg"
        variant="primary"
        color="bg-gray-300 text-gray-500"
        component={Link}
        to={publicRoutes.home}
        className="mt-2 text-base"
      >
        <TbHome />
        {t("pages.notFound.return")}
      </NewButton>
    </div>
  );
};
