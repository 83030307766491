import { useTranslation } from "react-i18next";
import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BigModal } from "app/components/BigModal";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  TbCheck,
  TbChevronDown,
  TbCircleCheck,
  TbDots,
  TbLanguage,
  TbLayoutRows,
  TbLicense,
  TbListCheck,
  TbPhoto,
  TbTriangleSquareCircle,
  TbVideo,
  TbX,
} from "react-icons/tb";
import classNames from "classnames";
import {
  useGetList,
  usePageItemContext,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  PageItemType,
  Paragraph,
  SlideParagraph,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import {
  DropMediaType,
  useDropMedia,
} from "app/components/Sources/MediaPicker/context/dropMediaContext";
import { paragraphsToText } from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { HorizontalLine } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { useQuery } from "react-query";
import { getGenerateMedia } from "api/course/courseContentAPI";
import { CgSpinner } from "react-icons/cg";
import { AnimatePresence, motion } from "framer-motion";
import YouTube from "react-youtube";
import { youtubeId } from "helpers/extractYoutubeVideoId";
import { toast } from "react-hot-toast";
import objectHash from "object-hash";

export const TRANSLATE_LANGS = (t) => [
  {
    name: t("v4.language.english"),
    value: "en",
  },
  {
    name: t("v4.language.german"),
    value: "de",
  },
  {
    name: t("v4.language.french"),
    value: "fr",
  },
  {
    name: t("v4.language.spanish"),
    value: "es",
  },
  {
    name: t("v4.language.portuguese"),
    value: "pt",
  },
  {
    name: t("v4.language.italian"),
    value: "it",
  },
  // {
  //   name: t("v4.language.polish"),
  //   value: "pl",
  // },
  // {
  //   name: t("v4.language.arabic"),
  //   value: "ar",
  // },
  // {
  //   name: t("v4.language.hindi"),
  //   value: "hi",
  // },
];

export const TranslateModal = ({ onConfirm, close }) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(TRANSLATE_LANGS(t)[0].value);

  return (
    <BigModal fit>
      <div className="p-1 pl-3 font-bold border-b-2 border-gray-100 flex items-center gap-2 text-gray-500">
        <div className="font-bold text-gray-500">{t("v4.language.select")}</div>
        <NewButton
          iconOnly
          onClick={close}
          className="ml-auto"
          variant="transparent"
        >
          <TbX />
        </NewButton>
      </div>

      <div className="flex p-8">
        <div className="flex flex-wrap justify-center items-center gap-2 max-w-sm">
          {TRANSLATE_LANGS(t).map(({ name, value }) => (
            <div
              key={value}
              className={classNames(
                "px-2 py-1 rounded-lg border border-gray-100 bg-primary font-bold transition",
                value === language
                  ? "bg-opacity-100 text-white cursor-default"
                  : "bg-opacity-0 hover:bg-opacity-50 text-gray-600 cursor-pointer"
              )}
              onClick={() => setLanguage(value)}
            >
              {name}
            </div>
          ))}
        </div>
      </div>

      <div className="p-1 font-bold border-t-2 border-gray-100 flex items-center gap-1 text-gray-500">
        <div className="grow" />
        <NewButton
          disabled={!language}
          onClick={() => onConfirm(language)}
          variant="primary"
        >
          <TbCheck /> {t("v4.generic.confirm")}
        </NewButton>
      </div>
    </BigModal>
  );
};

export const getPageText = (): string => {
  const getList = useGetList();
  const list = getList() || [];

  return useMemo(() => {
    return list
      .map((item) => {
        // if (item.type === PageItemType.Heading) return item.data;
        if (
          item.type === PageItemType.Paragraph ||
          item.type === PageItemType.List
        )
          return paragraphsToText(item.data);
        return "";
      })
      .filter((text) => !!text)
      .join("\n");
  }, [objectHash(list)]);
};

export const MediaModal = ({
  onConfirm,
  close,
  text,
}: {
  onConfirm: (src: string, isVideo: boolean, attribution?: string) => void;
  close: () => void;
  text: string;
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<{
    url: string;
    isVideo: boolean;
    attribution?: string;
  } | null>(null);

  useEffect(() => {
    if (!text) {
      toast.error(t("v4.generate.media.noTextAvailable"));
      close();
    }
  }, [text]);

  const query = useQuery(
    ["generate_media", text],
    () => getGenerateMedia(text),
    {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: !!text,
      onError: () => {
        close();
      },
    }
  );

  return (
    <BigModal>
      <div className="p-1 pl-3 font-bold border-b-2 border-gray-100 flex items-center gap-2 text-gray-500">
        <div className="font-bold text-gray-500">
          {t("v4.generate.media.selectMedia")}
        </div>
        <NewButton
          iconOnly
          onClick={close}
          className="ml-auto"
          variant="transparent"
        >
          <TbX />
        </NewButton>
      </div>

      {!query.isSuccess ? (
        <div className="grow flex">
          <CgSpinner className="text-5xl text-gray-400 animate-spin m-auto" />
        </div>
      ) : (
        <div className="grow flex flex-col gap-2 p-2 overflow-y-auto">
          <MediaSection
            header={
              <>
                <TbPhoto className="text-xl" /> {t("v4.item.image.plural")}
              </>
            }
          >
            {!query.data.images.length ? (
              <div className="grow p-4 flex items-center justify-center text-center text-gray-500">
                {t("v4.generate.media.noImagesFound")}
              </div>
            ) : (
              <div
                className="p-4 content-start flex-1 grid gap-3"
                style={{
                  gridTemplateColumns: "repeat(auto-fill, minmax(12rem, 1fr))",
                }}
              >
                {query.data.images.map(({ title, url, attribution }) => (
                  <div
                    key={url}
                    className={classNames(
                      "transition rounded-xl flex flex-col p-2",
                      selected?.url === url
                        ? "bg-primary bg-opacity-20"
                        : "hover:bg-gray-100 cursor-pointer"
                    )}
                    onClick={() =>
                      setSelected({ url, isVideo: false, attribution })
                    }
                  >
                    <div className="mx-auto p-2 text-gray-500">
                      <img
                        src={url}
                        className="w-full h-24 object-contain object-center drop-shadow-lg filter rounded-lg"
                      />
                    </div>
                    <div className="break-all text-gray-600 bg-gray-100 py-0.5 px-1 leading-tight w-full rounded-lg font-bold text-center text-sm line-clamp-2 text-ellipsis overflow-hidden my-auto">
                      {title || t("v4.generate.media.noName")}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </MediaSection>
          <MediaSection
            header={
              <>
                <TbVideo className="text-xl" /> {t("v4.item.video.plural")}
              </>
            }
          >
            {!query.data.videos.length ? (
              <div className="grow p-4 flex items-center justify-center text-center text-gray-500">
                {t("v4.generate.media.noVideosFound")}
              </div>
            ) : (
              <div
                className="grow p-4 content-start flex-1 grid gap-3 overflow-y-auto"
                style={{
                  gridTemplateColumns: "repeat(auto-fill, minmax(16rem, 1fr))",
                }}
              >
                {query.data.videos.map(({ title, url }) => (
                  <div
                    key={url}
                    className={classNames(
                      "transition rounded-xl flex flex-col p-2",
                      selected?.url === url
                        ? "bg-primary bg-opacity-20"
                        : "hover:bg-gray-100 cursor-pointer"
                    )}
                    onClick={() => setSelected({ url, isVideo: true })}
                  >
                    <div className="mx-auto p-2 text-gray-500">
                      <YouTube
                        opts={{ width: `${320}`, height: `${180}` }}
                        videoId={youtubeId(url)}
                        className="w-full mx-auto rounded-xl"
                        containerClassName="aspect-video w-full"
                      />
                    </div>
                    <div className="break-all text-gray-600 bg-gray-100 py-0.5 px-1 leading-tight w-full rounded-lg font-bold text-center text-sm line-clamp-2 text-ellipsis overflow-hidden my-auto">
                      {title || t("v4.generate.media.noName")}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </MediaSection>
        </div>
      )}

      <div className="p-1 font-bold border-t-2 border-gray-100 flex items-center gap-1 text-gray-500">
        <div className="grow" />
        <NewButton
          disabled={!selected}
          onClick={() =>
            selected &&
            onConfirm(selected.url, selected.isVideo, selected?.attribution)
          }
          variant="primary"
        >
          <TbCheck /> {t("v4.generic.confirm")}
        </NewButton>
      </div>
    </BigModal>
  );
};

const MediaSection = ({
  children,
  header,
}: PropsWithChildren<{
  header: ReactNode;
}>) => {
  const [show, setShow] = useState(true);

  return (
    <div className="rounded-xl border-gray-100 border flex-col overflow-hidden shrink-0">
      <div
        className="px-4 py-2 flex items-center text-gray-600 gap-2 font-bold hover:bg-gray-100 transition cursor-pointer text-lg"
        onClick={() => setShow(!show)}
      >
        {header}
        <TbChevronDown
          className={classNames(
            "text-xl text-gray-400 ml-auto transform transition",
            !show && "rotate-180"
          )}
        />
      </div>
      <AnimatePresence initial={false}>
        {show && (
          <motion.div
            className="flex flex-col"
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
          >
            <div className="w-full border-b border-gray-100" />
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export enum TextGenerateModal {
  None,
  Translate,
  Media,
}

export const TextGenerate = ({ close }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(TextGenerateModal.None);
  const [item] = usePageItemContext<SlideParagraph | Paragraph>();
  const [, setDropMedia] = useDropMedia();

  const text = useMemo(
    () => (!item?.data ? "" : paragraphsToText(item.data)),
    [item?.data]
  );

  const handleOption =
    <T extends Extract<DropMediaType, { data: string }>>(type: T["type"]) =>
    () => {
      const text = paragraphsToText(item.data);
      close();
      setDropMedia({ type, data: text } as T);
    };

  const handleTranslate = (language: string) => {
    setModal(TextGenerateModal.None);
    setDropMedia({
      type: PageItemType.ParagraphTranslate,
      language,
      data: item.data,
    });
  };

  const handleMedia = (
    link: string,
    isVideo: boolean,
    attribution?: string
  ) => {
    setModal(TextGenerateModal.None);
    if (isVideo)
      setDropMedia({
        type: PageItemType.Embed,
        data: link,
      });
    else
      setDropMedia({
        type: PageItemType.Image,
        data: { src: link },
        fit: "contain",
        ...(attribution && { attribution }),
      });
  };

  return (
    <>
      <NewButton
        variant="transparent"
        onClick={handleOption(PageItemType.MultipleChoiceGenerate)}
      >
        <TbCircleCheck /> {t("v4.item.multipleChoice.text")}
      </NewButton>
      <NewButton
        variant="transparent"
        onClick={handleOption(PageItemType.MultipleResponseGenerate)}
      >
        <TbListCheck /> {t("v4.item.multipleResponse.text")}
      </NewButton>

      <HorizontalLine />

      <NewButton
        variant="transparent"
        onClick={handleOption(PageItemType.ParagraphSummarize)}
      >
        <TbDots /> {t("v4.item.summarize.text")}
      </NewButton>
      <NewButton
        variant="transparent"
        onClick={handleOption(PageItemType.ParagraphSimplify)}
      >
        <TbTriangleSquareCircle /> {t("v4.item.simplify.text")}
      </NewButton>
      <NewButton
        variant="transparent"
        onClick={handleOption(PageItemType.ParagraphParaphrase)}
      >
        <TbLicense /> {t("v4.item.paraphrase.text")}
      </NewButton>

      <HorizontalLine />

      <NewButton
        variant="transparent"
        onClick={() => {
          close();
          setModal(TextGenerateModal.Media);
        }}
      >
        <TbPhoto /> {t("v4.item.media.text")}
      </NewButton>
      <NewButton
        variant="transparent"
        onClick={handleOption(PageItemType.ParagraphNext)}
      >
        <TbLayoutRows /> {t("v4.item.nextParagraph.text")}
      </NewButton>
      <NewButton
        variant="transparent"
        onClick={() => {
          close();
          setModal(TextGenerateModal.Translate);
        }}
      >
        <TbLanguage /> {t("v4.item.translate.text")}
      </NewButton>

      {modal === TextGenerateModal.Translate && (
        <TranslateModal
          onConfirm={handleTranslate}
          close={() => setModal(TextGenerateModal.None)}
        />
      )}

      {modal === TextGenerateModal.Media && (
        <MediaModal
          text={text}
          onConfirm={handleMedia}
          close={() => setModal(TextGenerateModal.None)}
        />
      )}
    </>
  );
};
