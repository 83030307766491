import React, {
  Fragment,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  TbBook,
  TbCalendar,
  TbCheck,
  TbCircleNumber1,
  TbClipboardCheck,
  TbDownload,
  TbExternalLink,
  TbLock,
  TbLockOpen,
  TbPresentationAnalytics,
  TbRotate,
  TbSearch,
  TbTrash,
  TbUsers,
  TbX,
} from "react-icons/tb";
import { useDateFormat } from "app/hooks/useDateFormat";
import { useTranslation } from "react-i18next";
import {
  getActivityResults,
  getCoursePollingResult,
  getCourseResult,
  getExportResults,
  postCloseAssignment,
  postOpenAssignment,
  postRemoveAssignment,
} from "api/course/courseConsumptionAPI";
import { CourseIcon } from "app/pages/courses/Edit/CoursePanel";
import classNames from "classnames";
import { Tooltip } from "app/components/Tooltip";
import { roundTo } from "helpers/roundTo";
import { courseRoutes, groupRoutes, sessionRoutes } from "enums/routes";
import { NewButton } from "app/components/Buttons/NewButton";
import { CgSpinner } from "react-icons/cg";
import WarningModal from "app/components/Modal/WarningModal";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router";
import { getCoursePage } from "api/course/courseAPI";
import { PageRender } from "app/components/Exercises/CourseEdit/render/PageRender";
import { PageItemResults } from "app/components/Exercises/CourseEdit/results/PageResults";
import { updatePageSchema } from "app/components/Exercises/CourseEdit/PageStoreContext";
import { translateError } from "app/components/Exercises/Edit/missingTranslation";
import { AttemptsOption } from "app/pages/courses/components/AssignCourseModal";
import { putEditAssignment } from "api/assignments/assignmentsAPI";
import { PageItemCorrect } from "../../components/Exercises/CourseEdit/results/PageCorrect";
import { pagePlaceholder } from "./Edit/CourseEditView";
import download from "downloadjs";
import { MIME } from "../../../helpers/mimeType";

const percentage = (number: number) => roundTo(number * 100, 0);

export const LiveSessionBadges = ({ item }) => {
  const { t } = useTranslation();
  if (item.type === "assignment") return null;
  return (
    <>
      {item.status === "active" && (
        <div className="flex text-sm px-1.5 leading-none py-1.5 gap-1 rounded-lg font-bold bg-primary bg-opacity-10 text-primary">
          {t("v4.activity.active")}
        </div>
      )}
      {(item.status === "finished" || item.status === "expired") && (
        <div className="flex text-sm py-1.5 px-1.5 leading-none gap-1 rounded-lg bg-gray-100 text-gray-500 font-bold">
          {t("v4.activity.ended")}
        </div>
      )}
    </>
  );
};

export const AssignmentBadges = ({ item }) => {
  const { t } = useTranslation();
  if (item.type === "live_session") return null;
  return (
    <>
      {item.status === "closed" && (
        <div className="flex text-sm pl-1.5 pr-2 leading-none py-1 gap-1 rounded-lg bg-gray-200 text-gray-600 font-bold">
          <TbLock className="text-base" /> {t("v4.activity.closed")}
        </div>
      )}
      {item.is_single_attempt && (
        <div className="flex text-sm pl-1.5 pr-2 leading-none py-1 gap-1 rounded-lg bg-orange-100 text-orange-500 font-bold">
          <TbCircleNumber1 className="text-base" />{" "}
          {t("v4.assignment.attempts.singleLabel")}
        </div>
      )}
    </>
  );
};

export const AssignmentButtons = ({
  courseId,
  assignmentId,
  onDelete = () => {},
  closed: closed_,
}) => {
  const { t } = useTranslation();
  const [closed, setClosed] = useState(closed_);
  const [openDelete, setOpenDelete] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    setClosed(closed_);
  }, [closed_]);

  const closeMutation = useMutation(() => postCloseAssignment([assignmentId]), {
    onSuccess: () => {
      queryClient.refetchQueries(["activities", courseId]);
      queryClient.refetchQueries(["activity", courseId, assignmentId]);
      setClosed(true);
      toast.success(t("v4.assignment.close.success"));
    },
    onError: () => {
      toast.error(translateError(t("v4.assignment.close.error"), t));
    },
  });

  const openMutation = useMutation(() => postOpenAssignment([assignmentId]), {
    onSuccess: () => {
      queryClient.refetchQueries(["activities", courseId]);
      queryClient.refetchQueries(["activity", courseId, assignmentId]);
      setClosed(false);
      toast.success(t("v4.assignment.open.success"));
    },
    onError: () => {
      toast.error(translateError(t("v4.assignment.open.error"), t));
    },
  });

  const removeMutation = useMutation(
    () => postRemoveAssignment([assignmentId]),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["activities", courseId]);
        onDelete?.();
        toast.success(t("v4.assignment.delete.success"));
      },
      onError: () => {
        toast.error(translateError(t("v4.assignment.delete.error"), t));
      },
      onSettled: () => {
        setOpenDelete(false);
      },
    }
  );

  if (!closed)
    return (
      <NewButton
        className="pointer-events-auto z-[1]"
        size="sm"
        disabled={closeMutation.isLoading}
        onClick={closeMutation.mutate}
      >
        {closeMutation.isLoading ? (
          <CgSpinner className="animate-spin" />
        ) : (
          <TbLock />
        )}
        {t("v4.generic.close")}
      </NewButton>
    );

  return (
    <>
      <NewButton
        className="pointer-events-auto z-[1]"
        size="sm"
        disabled={openMutation.isLoading}
        onClick={openMutation.mutate}
      >
        {openMutation.isLoading ? (
          <CgSpinner className="animate-spin" />
        ) : (
          <TbLockOpen />
        )}
        {t("v4.generic.open")}
      </NewButton>
      <NewButton
        className="pointer-events-auto z-[1]"
        size="sm"
        disabled={removeMutation.isLoading}
        onClick={() => setOpenDelete(true)}
        color="bg-red-500 text-red-500"
      >
        {removeMutation.isLoading ? (
          <CgSpinner className="animate-spin" />
        ) : (
          <TbTrash />
        )}
        {t("v4.generic.delete")}
      </NewButton>
      <WarningModal
        onConfirm={removeMutation.mutate}
        open={openDelete}
        closeModal={() => setOpenDelete(false)}
        confirmButton={t("v4.generic.delete")}
        closeButton={t("v4.generic.cancel")}
        text={t("v4.assignment.deletePrompt")}
      />
    </>
  );
};

export const CourseActivity = () => {
  const { t } = useTranslation();
  const { courseId, activityId } =
    useParams<{ courseId: string; activityId: string }>();
  const { isSuccess, data, refetch } = useQuery({
    queryFn: () => getActivityResults(courseId, activityId),
    queryKey: ["activity", courseId, activityId],
    refetchOnWindowFocus: false,
    enabled: !!courseId && !!activityId,
  });
  const history = useHistory();
  const [page, setPage] = useState<null | string>(null);
  const [result, setResult] = useState<null | string>(null);
  const [pollResult, setPollResult] = useState<null | string>(null);
  const format = useDateFormat();

  const { pages, users, course, activity } = data || {};

  const updateAttemptsMutation = useMutation(
    (isSingleAttempt: boolean) =>
      putEditAssignment(activity.id, {
        is_single_attempt: isSingleAttempt,
      }),
    {
      onSuccess: () => {
        refetch();
        // location.push(groupRoutes.list);
        toast.success(t("v4.assignment.update.success"));
      },
      onError: () => {
        toast.error(translateError(t("v4.assignment.update.error"), t));
      },
    }
  );

  const exportMutation = useMutation(
    () => getExportResults(courseId, activityId),
    {
      onSuccess: (data) => {
        console.log(data);
        download(data, `Results_${courseId}_${activityId}.csv`, MIME.csv);
      },
    }
  );

  return (
    <div className="py-8 px-3 sm:px-8">
      {/*<div className="flex items-center gap-1 mb-2">*/}
      {/*{isSuccess && (*/}
      {/*  <SourceContextMenu*/}
      {/*    item={{ id: courseId, name: data.name, type: FileType.Course }}*/}
      {/*    options={[["download", "delete"]]}*/}
      {/*    trigger={(toggle) => (*/}
      {/*      <NewButton iconOnly onClick={toggle}>*/}
      {/*        <TbDotsVertical />*/}
      {/*      </NewButton>*/}
      {/*    )}*/}
      {/*  />*/}
      {/*)}*/}
      {/*</div>*/}
      <div className="flex flex-col gap-2 text-gray-600">
        {!isSuccess ? (
          <div className="gap-4 bg-white rounded-2xl p-6">
            <div className="flex flex-col gap-2 animate-pulse">
              <div className="bg-gray-100 rounded-xl h-10" />
              <div className="bg-gray-100 rounded-xl h-6" />
            </div>
          </div>
        ) : (
          <>
            <div className="flex items-start gap-4 bg-white rounded-2xl p-6">
              <div className="flex flex-col relative min-w-0 grow">
                <div className="flex gap-1 flex-wrap items-start mb-1">
                  {activity.type === "assignment" && (
                    <div className="bg-lime-600 font-bold text-sm gap-1 flex items-center text-white py-0.5 px-1.5 rounded-lg">
                      <TbClipboardCheck className="text-base" />
                      {t("v4.assignment.text")}
                    </div>
                  )}
                  {activity.type === "live_session" && (
                    <div className="bg-purple-700 font-bold text-sm gap-1 flex items-center text-white py-0.5 px-1.5 rounded-lg">
                      <TbPresentationAnalytics className="text-base" />
                      {t("v4.presentation.text")}
                    </div>
                  )}
                  <LiveSessionBadges item={activity} />
                  <AssignmentBadges item={activity} />
                </div>

                {/* TODO: Disable if not owner */}
                <Link
                  to={courseRoutes.details(courseId)}
                  className="font-bold text-2xl overflow-ellipsis overflow-hidden mb-4 hover:text-primary transition"
                >
                  <TbBook
                    className="inline mr-2 text-3xl mb-0.5"
                    strokeWidth={2.25}
                  />
                  {course.name}
                </Link>

                {activity.group_id && (
                  <>
                    {activity.group_status === "archived" ? (
                      <div className="inline-flex items-center font-bold text-gray-400 mr-auto">
                        <TbUsers className="mr-1 text-xl" />{" "}
                        {activity.group_name} ({t("classes.archived")})
                      </div>
                    ) : activity.group_status === "removed" ? (
                      <div className="inline-flex items-center font-bold text-gray-400 mr-auto">
                        <TbUsers className="mr-1.5 text-xl" />{" "}
                        {activity.group_name} ({t("classes.deleted")})
                      </div>
                    ) : (
                      <Link
                        to={groupRoutes.details(activity.group_id)}
                        className="inline-flex items-center font-bold pointer-events-auto hover:text-primary mr-auto transition"
                      >
                        <TbUsers className="mr-1.5 text-xl" />{" "}
                        {activity.group_name}
                      </Link>
                    )}
                  </>
                )}

                <div className="inline-flex items-center mr-auto">
                  <b>{activity.participants}</b>
                  &nbsp;
                  {activity.participants === 1
                    ? t("v4.activity.participant")
                    : t("v4.activity.participants")}
                </div>

                <span className="flex items-center text-gray-500 mt-4">
                  <TbCalendar className="mr-1 text-xl" />
                  {format(activity.date_assigned, "MMM d, yyyy")}
                </span>
                <div className="flex flex-col sm:flex-row items-start sm:items-center sm:gap-4 mt-2">
                  <div className="flex items-center gap-1.5 font-bold pointer-events-auto hover:text-primary transition">
                    <TbRotate className="text-xl shrink-0" />
                    {t("v4.assignment.attempts.number")}
                  </div>
                  <AttemptsOption
                    value={activity.is_single_attempt}
                    disabled={updateAttemptsMutation.isLoading}
                    setValue={updateAttemptsMutation.mutate}
                  />
                </div>
              </div>

              <div className="flex gap-1 ml-auto mt-4 sm:mt-0">
                {activity.type === "assignment" && (
                  <AssignmentButtons
                    assignmentId={activity.id}
                    courseId={courseId}
                    closed={activity.status === "closed"}
                    onDelete={() =>
                      history.replace(courseRoutes.details(courseId))
                    }
                  />
                )}
                {activity.type === "live_session" &&
                  activity.status === "active" && (
                    <NewButton
                      className="pointer-events-auto z-[1] hidden md:flex"
                      size="sm"
                      color="bg-primary text-primary"
                      component={Link}
                      to={sessionRoutes.session(activity.pin)}
                    >
                      <TbExternalLink /> {t("v4.session.open")}
                    </NewButton>
                  )}

                <NewButton
                  disabled={exportMutation.isLoading}
                  onClick={() => exportMutation.mutate()}
                  iconOnly
                >
                  {exportMutation.isLoading ? (
                    <CgSpinner className="animate-spin" />
                  ) : (
                    <TbDownload />
                  )}
                  {/*{t("v4.export.download")}*/}
                </NewButton>
              </div>
            </div>

            <div className="flex items-start gap-4 bg-white rounded-2xl overflow-x-auto">
              {!users?.length ? (
                <div className="text-center p-6 text-lg text-gray-600 mx-auto">
                  {t("v4.results.empty")}
                </div>
              ) : (
                <div
                  className="m-6 grid grow items-center gap-x-1 gap-y-3"
                  style={{
                    gridTemplateColumns: `minmax(10em, 4fr) minmax(5em, 2fr) repeat(${pages.length}, minmax(3em, 1fr))`,
                  }}
                >
                  <div className="font-bold pr-3 text-center">
                    {t("v4.generic.name")}
                  </div>
                  <div className="font-bold pr-3 text-center">
                    {t("v4.results.text")}
                  </div>
                  {pages.map(({ page_id, category, name, quiz_type }) => {
                    const content = (
                      <CourseIcon
                        category={category}
                        key={page_id}
                        quizType={quiz_type}
                      >
                        {(Icon, color) => (
                          <div
                            className={classNames(
                              "shrink-0 w-full h-full min-w-[2em] min-h-[1.75em] rounded-lg flex items-center text-lg justify-center cursor-pointer transition hover:opacity-80",
                              color
                            )}
                            onClick={() => setPage(page_id)}
                          >
                            <Icon />
                          </div>
                        )}
                      </CourseIcon>
                    );
                    if (category === "slide") return content;

                    return (
                      <Tooltip
                        key={page_id}
                        value={
                          name ||
                          pagePlaceholder({ category, quizType: quiz_type })(t)
                        }
                        className="w-full h-full flex items-center justify-center"
                      >
                        {content}
                      </Tooltip>
                    );
                  })}

                  {/* averages */}
                  <div className="font-bold text-primary">
                    {t("v4.results.average")}
                  </div>
                  <div />
                  {pages.map(({ avg_score, page_id, category }, i) => {
                    if (category === "poll")
                      return (
                        <div
                          className="w-full h-full relative flex flex-col"
                          key={`${page_id}_avg`}
                          onClick={() => setPollResult(page_id)}
                          style={{ gridColumn: `${i + 1 + 2} / span 1` }}
                        >
                          <div className="text-primary font-bold text-center grow -my-1.5 flex items-center justify-center rounded-xl bg-primary bg-opacity-10 hover:bg-opacity-30 cursor-pointer transition">
                            <TbSearch className="text-xl" strokeWidth={3} />
                          </div>
                        </div>
                      );

                    const hasPollResults = users.some((u) => u.results?.[i]);
                    if (!hasPollResults) return null;

                    return (
                      <div
                        className="text-primary font-bold text-center flex items-center justify-center"
                        style={{ gridColumn: `${i + 1 + 2} / span 1` }}
                        key={`${page_id}_avg`}
                      >
                        {category === "quiz" && (
                          <>
                            {percentage(avg_score)}
                            <span className="text-xs">%</span>
                          </>
                        )}
                      </div>
                    );
                  })}

                  {users.map(
                    ({ user_id, user_name, results, last_date, avg_score }) => (
                      <Fragment key={user_id}>
                        <div
                          className="flex flex-col sticky left-0 bg-white pl-4 -ml-4 pr-2 -mr-2"
                          style={{ gridColumn: "1 / span 1" }}
                        >
                          <div className="font-bold">{user_name}</div>
                          {last_date && (
                            <div className="text-xs">
                              {format(last_date, "MMM d yyyy, HH:mm")}
                            </div>
                          )}
                        </div>
                        <div
                          className="text-center flex items-center justify-center font-bold"
                          style={{ gridColumn: "2 / span 1" }}
                        >
                          {percentage(avg_score)}
                          <span className="text-xs">%</span>
                        </div>
                        {results.map((item, i) => {
                          if (!item) return null;
                          if (pages?.[i]?.category === "quiz")
                            return (
                              <div
                                key={item.id || `${user_id}_${i}`}
                                onClick={() => setResult(item.id)}
                                className="w-full h-full flex items-center justify-center text-center font-bold rounded-xl hover:bg-gray-100 cursor-pointer transition"
                                style={{ gridColumn: `${i + 3} / span 1` }}
                              >
                                {percentage(item.score)}
                                <span className="text-xs">%</span>
                              </div>
                            );

                          if (pages?.[i]?.category === "poll")
                            return (
                              <div
                                key={item.id || `${user_id}_${i}`}
                                onClick={() => setResult(item.id)}
                                className="w-full h-full flex items-center justify-center text-center rounded-xl hover:bg-gray-100 cursor-pointer transition"
                                style={{ gridColumn: `${i + 3} / span 1` }}
                              >
                                <TbCheck strokeWidth={3} />
                              </div>
                            );

                          return (
                            <div
                              key={item.id}
                              className="w-full h-full flex items-center justify-center text-center"
                              style={{ gridColumn: `${i + 3} / span 1` }}
                            >
                              <TbCheck strokeWidth={3} />
                            </div>
                          );
                        })}
                      </Fragment>
                    )
                  )}
                  <div
                    style={{
                      gridColumn: `1 / span ${pages.length + 2}`,
                      gridRow: "3 / span 1",
                    }}
                    className="border-b-2 border-gray-100"
                  />
                </div>
              )}
            </div>
          </>
        )}
        {page && (
          <CoursePreviewApi
            courseId={courseId}
            id={page}
            close={() => setPage(null)}
          />
        )}
        {result && (
          <ResultPreviewApi
            courseId={courseId}
            id={result}
            close={() => setResult(null)}
          />
        )}
        {pollResult && (
          <PollResultPreviewApi
            activityId={activityId}
            pageId={pollResult}
            close={() => setPollResult(null)}
          />
        )}
      </div>
    </div>
  );
};

export const CoursePreviewApi = ({
  courseId,
  id,
  close,
}: {
  close: () => void;
  courseId: string;
  id: string;
}) => {
  const page = useQuery(["page", courseId, id], () =>
    getCoursePage(courseId, id)
  );

  const data = useMemo(
    () => page?.data && updatePageSchema(page.data),
    [page?.data]
  );

  return (
    <div className="fixed w-full h-full top-0 left-0 z-20 bg-white animate-fadeIn overflow-y-auto">
      {page.isLoading ? (
        <div className="absolute-cover flex">
          <CgSpinner className="text-gray-400 m-auto animate-spin text-4xl" />
        </div>
      ) : (
        <PageRender page={data} />
      )}
      <NewButton iconOnly className="fixed top-2 left-2" onClick={close}>
        <TbX />
      </NewButton>
    </div>
  );
};

export const ResultPreviewApi = ({
  courseId,
  id,
  close,
}: PropsWithChildren<{
  close: () => void;
  courseId: string;
  id: string;
}>) => {
  const { isSuccess, data } = useQuery({
    queryFn: () => getCourseResult(id),
    queryKey: ["results", id],
    refetchOnWindowFocus: false,
    enabled: !!courseId && !!id,
  });

  return (
    <div className="fixed w-full h-full top-0 left-0 z-20 bg-white animate-fadeIn overflow-y-scroll">
      {close && (
        <NewButton iconOnly onClick={close} className="fixed top-1 left-1">
          <TbX />
        </NewButton>
      )}
      {!isSuccess ? (
        <div className="absolute-cover flex">
          <CgSpinner className="text-gray-400 m-auto animate-spin text-4xl" />
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="max-w-2xl w-full mx-auto relative flex flex-col gap-6 my-12 text-gray-700 px-4 sm:px-1 md:px-0">
            {data.page.items.map((item) => (
              <PageItemResults
                key={item.id}
                item={data.results?.[item.id] ?? item}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export const PollResultPreviewApi = ({
  activityId,
  pageId,
  close,
}: PropsWithChildren<{
  close: () => void;
  activityId: string;
  pageId: string;
}>) => {
  const { isSuccess, data } = useQuery({
    queryFn: () => getCoursePollingResult(activityId, pageId),
    queryKey: ["results", pageId],
    refetchOnWindowFocus: false,
    enabled: !!activityId && !!pageId,
  });

  return (
    <div className="fixed w-full h-full top-0 left-0 z-20 bg-white animate-fadeIn overflow-y-scroll">
      {close && (
        <NewButton iconOnly onClick={close} className="fixed top-1 left-1">
          <TbX />
        </NewButton>
      )}
      {!isSuccess ? (
        <div className="absolute-cover flex">
          <CgSpinner className="text-gray-400 m-auto animate-spin text-4xl" />
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="max-w-2xl w-full mx-auto relative flex flex-col gap-6 my-12 text-gray-700 px-4 sm:px-1 md:px-0">
            {data.items.map((item) => (
              <PageItemCorrect key={item.id} item={item} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
