import React from "react";
import classNames from "classnames";
import { FaCheck } from "react-icons/fa";
import { useColorsContrastRatio } from "app/hooks/useColorsContrastRatio";

const groupColors = [
  "#333333",
  "#774396",
  "#8052E3",
  "#3648C9",
  "#1B88C6",
  "#4DBEE7",
  "#40CFAA",
  "#68B11F",
  "#C7E243",
  "#F4DD32",
  "#FAA52E",
  "#F26E3A",
  "#DB5554",
  "#D7352C",
];

const ColorItem = ({ color, selected, onClick }) => {
  const ratio = useColorsContrastRatio(color, "#ffffff");

  return (
    <div
      className="w-10 h-10 rounded-full cursor-pointer flex items-center justify-center"
      onClick={() => onClick(color)}
      style={{ backgroundColor: color }}
    >
      <FaCheck
        className={classNames(
          "text-xl transition text-white transform",
          !selected && "opacity-0 scale-0",
          ratio.pass ? "text-white" : "text-gray-700"
        )}
      />
    </div>
  );
};

export const ColorPicker = ({ setSelectedColor, selectedColor }) => (
  <>
    <div
      className="w-10 h-10 rounded-full cursor-pointer border-[3px] border-dashed border-gray-300 flex items-center justify-center"
      onClick={() => setSelectedColor(null)}
    >
      <FaCheck
        className={classNames(
          "text-xl transition text-white transform text-gray-700",
          selectedColor && "opacity-0 scale-0"
        )}
      />
    </div>
    {groupColors.map((color) => (
      <ColorItem
        key={color}
        onClick={setSelectedColor}
        selected={selectedColor === color}
        color={color}
      />
    ))}
  </>
);
