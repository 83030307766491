import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import { SlideVideo } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { SlideItemWrapper } from "app/components/Exercises/CourseEdit/slide/SlideItemWrapper";
import { VideoPlayer } from "app/components/MediaPlayer";
import React from "react";

export const SlidePageVideo = () => {
  const [{ data }] = usePageItemContext<SlideVideo>();
  return (
    <SlideItemWrapper>
      <VideoPlayer src={data.src} cover />
    </SlideItemWrapper>
  );
};

export const SlideVideoRender = ({ item }: { item: SlideVideo }) => (
  <VideoPlayer src={item.data.src} cover />
);
