import React from "react";

export const rotatePoint = (x, y, x0, y0, angle) => {
  const cos = Math.cos((angle * Math.PI) / 180);
  const sin = Math.sin((angle * Math.PI) / 180);
  return [
    (x - x0) * cos - (y - y0) * sin + x0,
    (x - x0) * sin + (y - y0) * cos + y0,
  ];
};
