import { GuestRoutes, publicRoutes } from "./../../enums/routes";
import axios from "axios";
import { store } from "../../app/store";
import { logoutUser } from "../../app/store/authentication";
import setAuthTokenAxios from "./setAuthTokenAxios";
import { AccessToken } from "../../enums/constant";
import { toast } from "react-hot-toast";

const initInterceptors = (history: any) =>
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!error?.response || !error?.response.status) {
        return Promise.reject(error);
      }
      switch (error.response.status) {
        case 401:
          if (localStorage.getItem(AccessToken)) {
            store.dispatch(logoutUser(history));
            localStorage.removeItem(AccessToken);
          }
          setAuthTokenAxios("");
          // toast.error("Session expired");
          history.push(GuestRoutes.signIn);
          break;
        case 403:
          // toast.error("Session expired");
          history.push(publicRoutes.unauthorized);
          break;
        case 500:
          toast.error("Internal Server Error");
          //history.push("/404");
          break;
        default:
          break;
      }
      return Promise.reject(error);
    }
  );

export default initInterceptors;
