import React, { useEffect, useState } from "react";
import {
  AudioData,
  ImageData,
  VideoData,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { BigModal } from "app/components/BigModal";
import "react-advanced-cropper/dist/style.css";
import { SingleUpload } from "app/components/Sources/MediaPicker/Upload/SingleUpload";
import { LibraryFilter } from "app/components/Sources/MediaPicker/SourceList/useLibrary";
import { SingleSource } from "app/components/Sources/MediaPicker/SingleSource/SingleSource";
import { SourceList } from "app/components/Sources/MediaPicker/SourceList/SourceList";
import {
  MediaOneColumn,
  MediaTwoColumns,
  useDropMedia,
} from "app/components/Sources/MediaPicker/context/dropMediaContext";
import { MobileUpload } from "app/components/Sources/MediaPicker/Upload/MobileUpload";
import { MediaRecord } from "app/components/Forms/MediaForm/MediaRecord";
import { SelectOnlyContext } from "app/components/Sources/MediaPicker/context/selectOnlyContext";
import { WebsiteUpload } from "app/components/Sources/MediaPicker/Upload/WebsiteUpload";
import { useTableSelectionContext } from "./context/useTableSelectionContext";
import { TableSelection } from "./TableSelection/TableSelection";

export type MediaData = VideoData | ImageData | AudioData;

export enum SelectOnlyType {
  none = "none",
  image = "image",
  source = "source",
  documentRange = "documentRange",
  media = "media",
  oneColumn = "oneColumn",
  twoColumns = "twoColumns",
  columns = "columns",
}

export type SelectType =
  // when select = none, have no onInsert
  | {
      onInsert: null;
      selectOnly: SelectOnlyType.none;
    }
  // when select = null -> select everything
  | {
      onInsert: (
        media: MediaData | string | MediaOneColumn | MediaTwoColumns
      ) => void;
      selectOnly?: null;
    }
  // when select = media -> select images, video, audio, text
  | {
      onInsert: (media: MediaData | string) => void;
      selectOnly: SelectOnlyType.media;
    }
  // when select = oneColumn -> select only one column
  | {
      onInsert: (media: MediaOneColumn) => void;
      selectOnly: SelectOnlyType.oneColumn;
    }
  // when select = twoColumns -> select only two columns
  | {
      onInsert: (media: MediaTwoColumns) => void;
      selectOnly: SelectOnlyType.twoColumns;
    }
  // when select = columns -> select one or two columns
  | {
      onInsert: (media: MediaOneColumn | MediaTwoColumns) => void;
      selectOnly: SelectOnlyType.columns;
    }
  // when select = text -> select text
  | {
      onInsert: (item: any) => void;
      selectOnly: SelectOnlyType.source;
    }
  // when select = image -> select imageData
  | {
      onInsert: (image: ImageData) => void;
      selectOnly: SelectOnlyType.image;
    };

export enum MediaPickerView {
  List,
  Record,
  Mobile,
  Website,
}

export const MediaPicker = ({
  close,
  onInsert,
  folderId = "",
  sourceId: initialSourceId = null,
  initialView = MediaPickerView.List,
  search = "",
  filters,
  selectOnly,
  mainPicker,
  initialFile,
}: {
  close: () => void;
  folderId?: string;
  sourceId?: string | null;
  search?: string;
  filters?: LibraryFilter;
  initialView?: MediaPickerView;
  initialFile?: File | string | null;
  mainPicker?: boolean;
} & SelectType) => {
  const [dropMedia, setDropMedia] = useDropMedia();
  const [tableSelection, setTableSelection] = useTableSelectionContext(
    (data) => [!!data.value, data.set]
  );
  const [sourceId, setSourceId] = useState<string | null>(initialSourceId);
  const [file, setFile] = useState<File | string | null>(initialFile || null);
  const [view, setView] = useState(initialView);

  // close the main media picker after inserting data
  useEffect(() => {
    if (dropMedia === false && !!mainPicker) {
      close();
      setDropMedia(null);
    }
  }, [dropMedia === false, !!mainPicker]);

  useEffect(() => {
    return () => {
      setTableSelection(null);
    };
  }, []);

  const back = () => {
    setSourceId("");
    setFile(null);
    setView(MediaPickerView.List);
    setTableSelection(null);
  };

  return (
    <SelectOnlyContext.Provider value={selectOnly || null}>
      <BigModal className={!!dropMedia && "!hidden"}>
        {tableSelection ? (
          <TableSelection
            onInsert={onInsert as any}
            {...{ close, selectOnly }}
          />
        ) : sourceId ? (
          <SingleSource
            onInsert={onInsert as any}
            {...{ sourceId, close, back }}
          />
        ) : file ? (
          <SingleUpload
            {...{ file, setFile, close, back }}
            openSource={(source) => {
              if (selectOnly === SelectOnlyType.source) return onInsert(source);
              setSourceId(source.id);
            }}
          />
        ) : view === MediaPickerView.Website ? (
          <WebsiteUpload {...{ close, setSourceId, back }} />
        ) : view === MediaPickerView.Mobile ? (
          <MobileUpload {...{ close, setFile, back }} />
        ) : view === MediaPickerView.Record ? (
          <MediaRecord {...{ close, setFile, back }} />
        ) : (
          <SourceList
            close={close}
            openSource={(item) => {
              if (selectOnly === SelectOnlyType.source) return onInsert(item);
              setSourceId(item.id);
            }}
            initialFolderId={folderId}
            onFile={setFile}
            initialSearch={search}
            initialFilters={filters}
            setView={setView}
          />
        )}
      </BigModal>
    </SelectOnlyContext.Provider>
  );
};
