import React from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { isAfter } from "date-fns";
import { CgSpinner } from "react-icons/cg";
import { courseRoutes, libraryRoutes } from "enums/routes";
import {
  deleteUnpublishItem,
  getItemPin,
  postNewItemPin,
  postPublishItem,
} from "api/sourcesAPI";
import { BigModal } from "app/components/BigModal";
import QRCode from "react-qr-code";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  TbCheck,
  TbClipboard,
  TbLock,
  TbShare,
  TbWorld,
  TbX,
} from "react-icons/tb";
import { translateError } from "app/components/Exercises/Edit/missingTranslation";
import copy from "copy-to-clipboard";
import { toast } from "react-hot-toast";
import { useDateFormat } from "app/hooks/useDateFormat";
import classNames from "classnames";
import { useRole } from "app/hooks/useRole";

const isExpired = (data) =>
  data?.date_due && isAfter(new Date(), new Date(data?.date_due));

export const postSendCourse = async (
  id: string,
  type: number,
  regen = false
) => {
  const send = (create = false) => {
    if (create) return postNewItemPin(id, type);
    return getItemPin(id, type);
  };
  try {
    if (regen) throw new Error();
    const result = await send(false);

    if (!result.pin || isExpired(result)) throw new Error();

    return result;
  } catch (e) {
    return send(true);
  }
};

// type 2 - source
// type 3 - course

export const SharedSource = ({
  id,
  close,
  type,
}: {
  id: string;
  type: number;
  close?: () => void;
}) => {
  const role = useRole();
  const { t } = useTranslation();
  const query = useQuery({
    queryKey: ["source", "send", id],
    queryFn: () => postSendCourse(id, type),
    refetchOnWindowFocus: false,
  });
  const publicationId = query.data?.item?.publication_id;

  const regen = useMutation(() => postSendCourse(id, type, true), {
    onSuccess: query.refetch,
  });

  const publishMutation = useMutation(
    (publish: boolean) =>
      (publish ? postPublishItem : deleteUnpublishItem)(id, type),
    {
      onSuccess: () => {
        query.refetch();
        toast.success(t("v4.library.visibilityChange.success"));
      },
      onError: () => {
        translateError(t("v4.library.visibilityChange.error"), t);
      },
    }
  );

  const isTraining = type === 3;
  const pin = query.data?.pin;

  const format = useDateFormat();

  const link =
    window.location.origin +
    (isTraining ? courseRoutes.share(pin) : libraryRoutes.share(pin));

  const handlePublish = (visibility: boolean) => {
    if (!!publicationId === visibility) return;
    publishMutation.mutate(visibility);
  };

  return (
    <BigModal fit className="!rounded-3xl text-gray-600">
      <div className="flex gap-2 p-1 border-b-gray-100 border-b-2">
        <div className="my-auto font-bold ml-2 flex items-center">
          <TbShare className="mr-2" />
          {isTraining ? t("v4.training.send") : t("v4.library.send")}
        </div>

        <div className="grow" />

        <NewButton iconOnly onClick={close} variant="transparent">
          <TbX />
        </NewButton>
      </div>
      <div className="p-6 gap-6 flex flex-col">
        {!query.isSuccess ||
        regen.isLoading ||
        (isExpired(query.data) && !query.isSuccess) ? (
          <CgSpinner className="animate-spin text-3xl m-auto" />
        ) : (
          <div className="flex flex-col sm:flex-row gap-6">
            <div className="flex flex-col justify-center">
              <QRCode
                level="L"
                size={140}
                value={link}
                viewBox="0 0 140 140"
                className="mx-auto"
              />
              <div
                onClick={() => {
                  copy(pin);
                  toast.success(t("groupsPage.labels.copiedPIN"));
                }}
                className="mb-4 text-xl text-gray-600 text-center font-bold bg-primary py-1 px-2 mt-1 rounded-xl cursor-pointer bg-opacity-0 hover:bg-opacity-30 transition"
              >
                {pin}
              </div>

              <div className="grow" />

              {query.data?.date_due && (
                <div className="text-gray-500 text-xs text-center">
                  {t("warningPopup.generateCode.valid_until")}{" "}
                  {format(query.data.date_due, "MMM d, yyyy")}
                </div>
              )}
              <NewButton
                className="text-xs font-bold mx-auto"
                variant="transparent"
                disabled={regen.isLoading}
                onClick={() => {
                  if (regen.isLoading) return;
                  regen.mutate();
                }}
              >
                {regen.isLoading && (
                  <CgSpinner className="animate-spin !text-xs" />
                )}
                {t("v4.generic.newCode")}
              </NewButton>
            </div>

            <div className="flex flex-col mb-auto gap-2">
              <div className="flex w-full items-center p-0.5 gap-1 border rounded-lg border-gray-200">
                <div className="grow self-stretch relative">
                  <div className="absolute-cover flex flex-row-reverse items-center text-sm px-2 text-gray-600 overflow-ellipsis overflow-x-auto scrollbar-none">
                    <div className="mr-auto">
                      {location.host +
                        (isTraining
                          ? courseRoutes.share(pin)
                          : libraryRoutes.share(pin))}
                    </div>
                  </div>
                </div>
                <NewButton
                  className="text-sm gap-1 my-auto font-bold"
                  onClick={() => {
                    copy(link);
                    toast.success(t("common.linkCopy.copied"));
                  }}
                >
                  <TbClipboard className="!text-base" /> {t("v4.generic.copy")}
                </NewButton>
              </div>

              <div className="text-sm font-bold mt-4">
                {t("v4.library.visibility.text")}
              </div>
              <VisibilityOption
                icon={TbLock}
                title={t("v4.library.visibility.private.text")}
                subtitle={t("v4.library.visibility.private.description")}
                onClick={() => handlePublish(false)}
                active={!publicationId}
                loading={publishMutation.isLoading}
              />
              <VisibilityOption
                icon={TbWorld}
                title={t("v4.library.visibility.public.text")}
                subtitle={t("v4.library.visibility.public.description")}
                onClick={() => handlePublish(true)}
                active={!!publicationId}
                loading={publishMutation.isLoading}
              />
            </div>
          </div>
        )}
      </div>
      {/*<div className="flex gap-2 p-1 border-t-gray-100 border-t-2">*/}
      {/*  <NewButton*/}
      {/*    className="text-sm font-bold text-gray-500"*/}
      {/*    variant="transparent"*/}
      {/*    onClick={close}*/}
      {/*  >*/}
      {/*    Generate new code*/}
      {/*  </NewButton>*/}

      {/*  <div className="grow" />*/}

      {/*  <NewButton className="font-bold" size="lg" onClick={close}>*/}
      {/*    Close*/}
      {/*  </NewButton>*/}
      {/*</div>*/}
    </BigModal>
  );
};

const VisibilityOption = ({
  icon: Icon,
  title,
  subtitle,
  active = false,
  loading = false,
  onClick,
}) => (
  <label
    onClick={onClick}
    className={classNames(
      "flex gap-3 p-2 rounded-xl border-2 text-gray-600 transition",
      loading && "animate-pulse",
      active
        ? "border-primary/40"
        : "border-gray-100 group cursor-pointer hover:border-primary/40"
    )}
  >
    <div className="h-12 w-12 bg-gray-100 rounded-xl flex items-center justify-center shrink-0">
      <Icon className="text-2xl" />
    </div>
    <div className="grow flex flex-col my-auto pr-3">
      <div className="font-bold text-lg leading-tight">{title}</div>
      <div className="text-gray-500 text-sm leading-tight">{subtitle}</div>
    </div>
    {loading && !active ? (
      <CgSpinner className="w-6 h-6 animate-spin text-gray-400" />
    ) : (
      <div
        className={classNames(
          "w-6 h-6 rounded-full border-2 flex items-center justify-center transition shrink-0",
          active
            ? "border-primary bg-primary text-white"
            : "border-gray-200 text-transparent"
        )}
      >
        <TbCheck strokeWidth={4} />
      </div>
    )}
  </label>
);
