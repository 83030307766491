import { createSlice } from "@reduxjs/toolkit";
import { userSignIn, userSignInGoogle } from ".";
import { AppThunk, RootState } from "..";
import LoadingStatus from "../../../enums/loading";
import { userLogout, userSignInApple, userSignInMS } from "./authActions";
import { GuestRoutes } from "../../../enums/routes";
import { clearUser } from "app/store/user/userSlice";

interface AuthState {
  loading: LoadingStatus;
}

const initialState: AuthState = {
  loading: LoadingStatus.idle,
};

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userSignIn.fulfilled, (state) => {
      state.loading = LoadingStatus.succeeded;
    });
    builder.addCase(userSignIn.pending, (state) => {
      state.loading = LoadingStatus.pending;
    });
    builder.addCase(userSignIn.rejected, (state) => {
      state.loading = LoadingStatus.failed;
    });
    builder.addCase(userSignInGoogle.fulfilled, (state) => {
      state.loading = LoadingStatus.succeeded;
    });
    builder.addCase(userSignInGoogle.pending, (state) => {
      state.loading = LoadingStatus.pending;
    });
    builder.addCase(userSignInGoogle.rejected, (state) => {
      state.loading = LoadingStatus.failed;
    });
    builder.addCase(userSignInApple.fulfilled, (state) => {
      state.loading = LoadingStatus.succeeded;
    });
    builder.addCase(userSignInApple.pending, (state) => {
      state.loading = LoadingStatus.pending;
    });
    builder.addCase(userSignInApple.rejected, (state) => {
      state.loading = LoadingStatus.failed;
    });
    builder.addCase(userSignInMS.fulfilled, (state) => {
      state.loading = LoadingStatus.succeeded;
    });
    builder.addCase(userSignInMS.pending, (state) => {
      state.loading = LoadingStatus.pending;
    });
    builder.addCase(userSignInMS.rejected, (state) => {
      state.loading = LoadingStatus.failed;
    });
  },
});

export const logoutUser =
  (history: any): AppThunk =>
  (dispatch) => {
    dispatch(userLogout());
    history.push({ pathname: GuestRoutes.signIn });
    dispatch(clearUser());
  };

export const selectLoading = (state: RootState) => state.auth.loading;
export default slice.reducer;
