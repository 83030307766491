import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { getPage, openSource } from "api/sourcesAPI";

export interface Word {
  angle: number;
  break: number;
  h: number;
  w: number;
  x: number;
  y: number;
  id: string;
  text: string;
  start_point: [number, number];
}

export interface Line extends Word {
  words: Word[];
}

export const useImageQuery = (src: string, { enabled = true } = {}) =>
  useQuery(["loaded_image", src], () => imagePromise(src), {
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled,
  });

export const imagePromise = (url) =>
  new Promise<HTMLImageElement>((res, rej) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      res(img);
    };
    img.onerror = () => {
      rej();
    };
  });

export const useSourcePageQuery = (
  sourceId,
  page,
  willGetBlocks?: boolean,
  options: { [key: string]: any } = {},
  logOpen?: boolean
) => {
  useEffect(() => {
    if (!logOpen || !sourceId) return;
    openSource(sourceId);
  }, [sourceId, logOpen]);

  return useQuery({
    queryKey: ["sourcePage", sourceId, page, willGetBlocks],
    queryFn: async () => {
      const data = await getPage(sourceId, page, willGetBlocks);
      if (willGetBlocks && !data?.blocks) throw new Error();
      return data;
    },
    enabled: !!sourceId,
    onError: () => {
      options?.onBlocksError?.();
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnMount: false,
    retry: 2,
    ...options,
  });
};
