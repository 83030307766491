import { useCallback, useEffect, useState } from "react";
import { CropSize } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { useImageQuery } from "app/components/Exercises/Edit/context/SourceContext";

export const useImageMediaCanvas = (src: string, media?: CropSize) => {
  const [ref, setRef] = useState<HTMLCanvasElement | null>(null);
  const query = useImageQuery(src);

  useEffect(() => {
    if (!ref) return;
    if (!query.isSuccess) return;
    const img = query.data;
    const mediaData = media ?? {
      w: img.naturalWidth,
      h: img.naturalHeight,
      x: 0,
      y: 0,
      sizeX: img.naturalWidth,
      sizeY: img.naturalHeight,
    };
    const scale = img.naturalWidth / mediaData.sizeX;
    ref.width = mediaData.w;
    ref.height = mediaData.h;
    const ctx = ref.getContext("2d");
    if (!ctx) return;
    ctx.drawImage(
      img,
      mediaData.x * scale,
      mediaData.y * scale,
      mediaData.w * scale,
      mediaData.h * scale,
      0,
      0,
      mediaData.w,
      mediaData.h
    );
  }, [ref, query.isSuccess, media, src]);

  return useCallback(setRef, [
    media?.w,
    media?.h,
    media?.x,
    media?.y,
    query.isSuccess,
  ]);
};
