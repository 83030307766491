import { useHistory, useParams } from "react-router-dom";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import {
  deleteCoursePage,
  getCourse,
  NewCourseData,
  postNewCoursePage,
  putEditCourse,
} from "api/course/courseAPI";
import React, { ChangeEvent, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  updatePageSchema,
  usePageContext,
  usePageStore,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  Categorisation,
  ConsumptionType,
  ContentItem,
  Crossword,
  FillTheGap,
  Flashcards,
  FlashcardsQuiz,
  ImageLabeling,
  Item,
  MultipleChoice,
  Page,
  PageItemType,
  Pairing,
  Paragraph,
  QuizItem,
  SlideItem,
  SlidePage,
  Sorting,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { courseRoutes } from "enums/routes";
import {
  TbBook,
  TbCards,
  TbChartBar,
  TbCopy,
  TbDotsVertical,
  TbDownload,
  TbFileText,
  TbGripVertical,
  TbPencil,
  TbPlus,
  TbRectangle,
  TbTool,
  TbTrash,
  TbWand,
  TbX,
} from "react-icons/tb";
import { CgSpinner } from "react-icons/cg";
import classNames from "classnames";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FloatingMenu } from "app/components/Header";
import { NewButton } from "app/components/Buttons/NewButton";
import WarningModal from "app/components/Modal/WarningModal";
import download from "downloadjs";
import UTILS from "app/components/Exercises/utils";
import { uuid } from "app/components/Exercises/utils/uuid";
import { SlideRenderGrid } from "app/components/Exercises/CourseEdit/slide/SlideRender";
import useResizeObserver from "use-resize-observer";
import {
  SLIDE_HEIGHT,
  SLIDE_WIDTH,
} from "app/components/Exercises/Edit/questionType/Slide/slideUtils";
import { useRole } from "app/hooks/useRole";
import { toast } from "react-hot-toast";
import { IconType } from "react-icons";
import { CourseNameEdit } from "app/pages/courses/Edit/CourseHeader";
import {
  COURSE_ITEMS,
  FloatingList,
  LargeListItem,
} from "app/components/Exercises/CourseEdit/items/InsertPageItem";
import { DropHighlight } from "app/components/Sources/MediaPicker/context/dropMediaContext";
import {
  canInsertToPage,
  PageName,
  pagePlaceholder,
} from "app/pages/courses/Edit/CourseEditView";
import {
  useSourceUploadContext,
  useSourceUploadStore,
} from "api/ws/SourceUploadContext";
import { GenerateTraining } from "app/pages/courses/components/GenerateTraining";
import produce from "immer";

export const useCourseData = (
  options?: UseQueryOptions<any> & { courseId?: string }
) => {
  const { courseId } = useParams<{ courseId: string }>();
  const id = options?.courseId ?? courseId;
  return useQuery({
    queryFn: () => getCourse(id),
    queryKey: ["course", id],
    refetchOnWindowFocus: false,
    enabled: !!id,
    ...options,
  });
};

export const useCourseMutation = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const { refetch } = useCourseData();

  return useMutation({
    mutationFn: (data: { name?: string; pages?: string[] }) =>
      putEditCourse(courseId, data),
    onSuccess: () => refetch(),
  });
};

export const CoursePanel = () => {
  const { isSuccess } = useCourseData();

  if (!isSuccess) return null;
  return <InnerCoursePanel />;
};

const InnerCoursePanel = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { courseId } = useParams<{ courseId: string }>();
  const role = useRole();
  const pageStore = usePageStore();

  const { data: courseData, refetch } = useCourseData({
    onSuccess: ({ pages }) => {
      setCoursePages(pages.map((_, i) => i));
    },
  });
  const courseEditMutation = useCourseMutation();

  const newPage = useMutation(
    (data: NewCourseData) => postNewCoursePage(courseId, data),
    {
      onSuccess: ({ id }) => {
        history.replace(courseRoutes.editPage(courseId, id));
        refetch();
        // queryClient.invalidateQueries(["course", courseId]);
      },
    }
  );

  const courseIds = useMemo(() => {
    return courseData?.pages?.map(({ id }) => id);
  }, [courseData?.pages]);

  // update pages order
  const [coursePages, setCoursePages] = useState(
    courseData.pages.map((_, i) => i)
  );

  const handleDrop = ({ destination, source }: any) => {
    if (!source || !destination) return;
    if (source.index === destination.index) return;

    const newCoursePages = [...coursePages];
    const [item] = newCoursePages.splice(source.index, 1);
    newCoursePages.splice(destination.index, 0, item);
    setCoursePages(newCoursePages);
    courseEditMutation.mutate({
      pages: newCoursePages.map((i) => courseData.pages[i].id),
    });
  };

  const { cancelTask } = useSourceUploadContext();
  const tasks = useSourceUploadStore((state) =>
    Object.entries(state.task).filter(
      ([, task]) =>
        !task.data && !task.error && task?.input?.training_id === courseId
    )
  );

  return (
    <div className="flex flex-col">
      <CourseNameEdit />
      <div className="flex flex-col px-4 gap-1">
        {!courseData.pages.length ? (
          <div className="text-center text-gray-400">
            {t("v4.page.noItems")}
          </div>
        ) : (
          <>
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable
                droppableId="course-pages"
                isDropDisabled={courseEditMutation.isLoading}
              >
                {({ innerRef, droppableProps, placeholder }) => (
                  <div
                    ref={innerRef}
                    {...droppableProps}
                    className="gap-0.5 flex flex-col"
                  >
                    {(!newPage.isLoading
                      ? coursePages
                      : newPage.variables?.index == null
                      ? [...coursePages, null]
                      : [
                          ...coursePages.slice(0, newPage.variables.index),
                          null,
                          ...coursePages.slice(newPage.variables.index),
                        ]
                    ).map((index, i, list) => {
                      if (index == null)
                        return (
                          <div
                            key="placeholder"
                            className="rounded-lg items-center w-full transition p-1.5 pr-3 flex text-gray-600 animate-pulse"
                          >
                            <div className="shrink-0 w-6 h-6 mr-2 bg-gray-300 rounded-lg" />
                            <div className="rounded-full h-3 bg-gray-300 w-32" />
                          </div>
                        );

                      const item = courseData.pages?.[index];
                      if (!item) return null;
                      return (
                        <CoursePanelItem
                          key={item.id}
                          item={item}
                          index={i}
                          isLoading={courseEditMutation.isLoading}
                          setCoursePages={setCoursePages}
                          isLastItem={list.length === 1}
                          courseIds={courseIds}
                          duplicate={() => {
                            const active = pageStore.getState()?.data;
                            const items = produce<Page["items"]>(
                              active?.items ?? item?.items ?? [],
                              (items: Item[]) => {
                                for (const item of items) {
                                  item.id = uuid();
                                  if (item.category === "quiz") {
                                    if (!item.instructions) continue;
                                    for (const instructionsItem of item.instructions) {
                                      instructionsItem.id = uuid();
                                    }
                                  }
                                }
                              }
                            );
                            newPage.mutate({
                              items,
                              quizType: active?.quizType ?? item?.quiz_type,
                              version: active?.version ?? item?.version,
                              name:
                                (active && "name" in active && active?.name) ??
                                item?.name,
                              category: active?.category ?? item.category,
                              index: i + 1,
                            });
                          }}
                          duplicateLoading={newPage.isLoading}
                        />
                      );
                    })}
                    {placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {role.teacher &&
              tasks.map(([id, item]) => (
                <div
                  className="rounded-xl items-center w-full transition p-1.5 flex text-gray-600 gap-2 bg-gray-100"
                  key={id}
                >
                  <div className="shrink-0 w-6 h-6 rounded-lg flex items-center justify-center bg-primary text-white">
                    <CgSpinner className="animate-spin" />
                  </div>
                  <div className="h-1 grow rounded-full relative overflow-hidden transition bg-gray-200">
                    <div
                      className="absolute-cover bg-primary bg-opacity-50 origin-left transition"
                      style={{
                        transform: `scaleX(${item?.progress || 0}%)`,
                      }}
                    />
                  </div>
                  <NewButton
                    size="sm"
                    iconOnly
                    variant="transparent"
                    onClick={() => cancelTask(id)}
                  >
                    <TbX className="!text-lg" />
                  </NewButton>
                </div>
              ))}

            <div className="flex">
              <AddItemMenu generateNewPage={newPage.mutate} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const CourseIcon = ({
  category,
  children,
  quizType,
}: {
  category: Page["category"];
  quizType?: string;
  children: (icon: IconType, color: string) => ReactNode;
}) => {
  const { t } = useTranslation();

  if (quizType && category === "content") {
    return <>{children(TbCards, "bg-blue-700 text-white")}</>;
  }
  // if (quizType) {
  //   if (category === "content") {
  //     if (quizType === PageItemType.Flashcards)
  //       return <>{children(TbCards, "bg-blue-700 text-white")}</>;
  //   } else if (category === "quiz") {
  //     const icon = COURSE_ITEMS(t)?.[quizType]?.icon;
  //     if (icon) return <>{children(icon, "bg-red-600 text-white")}</>;
  //   }
  // }
  return (
    <>
      {category === "slide" && children(TbRectangle, "bg-blue-700 text-white")}
      {category === "content" && children(TbFileText, "bg-blue-700 text-white")}
      {category === "quiz" && children(TbPencil, "bg-red-600 text-white")}
      {category === "poll" && children(TbChartBar, "bg-blue-700 text-white")}
      {/*{category === "flashcards" &&*/}
      {/*  children(TbLayersSubtract, "bg-lime-600 text-white")}*/}
    </>
  );
};

const CoursePanelItem = ({
  item,
  index,
  isLoading: isLoading_,
  setCoursePages,
  isLastItem,
  courseIds,
  duplicate,
  duplicateLoading,
}: {
  item: {
    id: string;
    name: string;
    quiz_type?: string;
  } & (
    | {
        category: Exclude<Page, SlidePage>["category"];
      }
    | {
        category: "slide";
        items: SlideItem[];
      }
  );
  courseIds?: string[];
  index: number;
  isLoading: boolean;
  setCoursePages: (generator: (pages: number[]) => number[]) => void;
  duplicate: () => void;
  duplicateLoading: boolean;
  isLastItem?: boolean;
}) => {
  const [deleteOpen, setDeleteOpen] = useState(false);

  const { courseId, pageId } =
    useParams<{ courseId: string; pageId: string }>();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const history = useHistory();
  const role = useRole();

  const category_ = usePageContext((state) => state.data?.category);
  const pageStore = usePageStore();

  const deletePageMutation = useMutation(
    () => deleteCoursePage(courseId, item.id),
    {
      onSuccess: () => {
        setCoursePages((pages) => [
          ...pages.slice(0, index),
          ...pages.slice(index + 1),
        ]);
        if (pageId === item.id) {
          const item =
            !courseIds || courseIds?.length < 2
              ? null
              : !index
              ? courseIds?.[1]
              : courseIds?.[index - 1];

          if (!item) history.replace(courseRoutes.edit(courseId));
          else history.replace(courseRoutes.editPage(courseId, item));
        }
        queryClient.invalidateQueries(["course", courseId]);
      },
    }
  );

  if (deletePageMutation.isSuccess) return null;

  const handleDownloadJSON = () => {
    const page = pageStore.getState().data!;
    download(
      new Blob([JSON.stringify(page)], { type: "text/json" }),
      `smartest-course-${page.id}-${UTILS.makeId()}.json`,
      "application/json"
    );
  };

  const isLoading = isLoading_ || deletePageMutation.isLoading;

  const active = item.id === pageId;
  const category = (active && category_) || item.category;

  return (
    <>
      <Draggable
        key={item.id}
        draggableId={item.id}
        index={index}
        isDragDisabled={isLoading}
      >
        {({ innerRef, draggableProps, dragHandleProps }) => (
          <div
            className={classNames(
              "flex relative page-item transition group",
              deletePageMutation.isLoading && "opacity-50"
            )}
            ref={innerRef}
            {...draggableProps}
          >
            <div
              {...dragHandleProps}
              className={classNames(
                "absolute top-0 left-0 transform -translate-x-3/4 h-full w-4 flex items-center justify-center rounded-lg bg-gray-600 bg-opacity-0 transition opacity-0",
                !isLoading &&
                  "hover:bg-opacity-10 [.page-item:hover_&]:opacity-50 [.page-item:hover_&:hover]:opacity-100"
              )}
            >
              <TbGripVertical className="shrink-0" />
            </div>
            <div
              onClick={() => {
                if (active || deletePageMutation.isLoading) return;
                history.replace(courseRoutes.editPage(courseId, item.id));
              }}
              className={classNames(
                "rounded-xl items-center w-full transition p-1.5 flex text-gray-600",
                category !== "slide" && "pr-3",
                active
                  ? "bg-gray-500 bg-opacity-10"
                  : "cursor-pointer bg-gray-500 bg-opacity-0 hover:bg-opacity-10"
              )}
            >
              <CourseIcon category={category} quizType={item?.quiz_type}>
                {(Icon, color) => (
                  <div
                    className={classNames(
                      "shrink-0 w-6 h-6 mr-2 rounded-lg flex items-center justify-center",
                      color
                    )}
                  >
                    <Icon />
                  </div>
                )}
              </CourseIcon>
              {item.category === "slide" ? (
                <SlidePageThumbnail items={item.items} id={item.id} />
              ) : (
                <div className="grow whitespace-nowrap overflow-hidden text-ellipsis">
                  {!active ? (
                    item.name ||
                    pagePlaceholder({
                      category: item.category,
                      quizType: item.quiz_type,
                    })(t)
                  ) : !category_ ? (
                    <div className="rounded-full h-3 bg-gray-300 w-32 animate-pulse" />
                  ) : (
                    <PageName className="min-w-0 grow bg-transparent outline-none font-bold w-full" />
                  )}
                </div>
              )}
              {/*{item.category === "slide" ? (*/}
              {/*  <SlidePageThumbnail items={item.items} id={item.id} />*/}
              {/*) : !item?.quiz_type || !COURSE_ITEMS(t)?.[item.quiz_type] ? (*/}
              {/*  <div className="grow whitespace-nowrap overflow-x-clip text-ellipsis select-none font-bold">*/}
              {/*    {activeName}*/}
              {/*  </div>*/}
              {/*) : activeName ? (*/}
              {/*  <div className="flex flex-col leading-none grow whitespace-nowrap select-none  min-w-0">*/}
              {/*    <div className="font-bold overflow-x-clip text-ellipsis">*/}
              {/*      {activeName}*/}
              {/*    </div>*/}
              {/*    <div className="text-xs text-gray-500 overflow-x-clip text-ellipsis">*/}
              {/*      {COURSE_ITEMS(t)[item.quiz_type].name}*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*) : (*/}
              {/*  <div className="flex flex-col leading-none grow whitespace-nowrap overflow-x-clip text-ellipsis select-none min-w-0 font-bold">*/}
              {/*    {COURSE_ITEMS(t)[item.quiz_type].name}*/}
              {/*  </div>*/}
              {/*)}*/}
              <div
                className={classNames(
                  category === "slide" && "absolute top-3 right-3"
                )}
              >
                <FloatingMenu
                  portal
                  placement="bottom-end"
                  size="xs"
                  trigger={(toggle) => (
                    <NewButton
                      color="bg-white text-gray-400 backdrop-blur"
                      className={classNames(
                        "!px-0.5 !py-1 -my-1 -mr-1.5 transition",
                        !active && "opacity-0 group-hover:opacity-100"
                      )}
                      iconOnly
                      variant="primary"
                      component="button"
                      onClick={(e) => {
                        toggle();
                        e.stopPropagation();
                      }}
                    >
                      <TbDotsVertical />
                    </NewButton>
                  )}
                >
                  {({ setIsOpen }) => (
                    <>
                      {!role.prodMode && active && (
                        <NewButton
                          variant="transparent"
                          onClick={(e) => {
                            handleDownloadJSON();
                            setIsOpen(false);
                            e.stopPropagation();
                          }}
                        >
                          <TbDownload /> {t("v4.editView.downloadJSON")}
                        </NewButton>
                      )}
                      {active && (
                        <>
                          <NewButton
                            variant="transparent"
                            onClick={() => {
                              setIsOpen(false);
                              duplicate();
                            }}
                            disabled={duplicateLoading}
                          >
                            {duplicateLoading ? (
                              <CgSpinner className="animate-spin" />
                            ) : (
                              <TbCopy />
                            )}
                            {t("v4.editView.duplicate")}
                          </NewButton>

                          <div className="w-full mx-auto my-1 border-t border-b border-gray-100" />
                        </>
                      )}

                      <NewButton
                        color="bg-red-500 text-red-500"
                        variant="transparent"
                        disabled={isLastItem}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (isLastItem) return;
                          setDeleteOpen(true);
                          setIsOpen(false);
                        }}
                      >
                        <TbTrash /> {t("common.delete")}
                      </NewButton>
                    </>
                  )}
                </FloatingMenu>
              </div>
              <DropHighlight
                types={(type) =>
                  active
                    ? false
                    : canInsertToPage(type, {
                        quizType: item.quiz_type,
                        category: item.category,
                      })
                }
              />
            </div>
          </div>
        )}
      </Draggable>

      <WarningModal
        onConfirm={deletePageMutation.mutate}
        open={deleteOpen}
        closeModal={() => setDeleteOpen(false)}
        text={t("warningPopup.deleteExercise.text")}
        confirmButton={t("common.delete")}
        closeButton={t("common.cancel")}
      />
    </>
  );
};

const SlidePageThumbnail = ({
  items,
  id,
}: {
  items: SlideItem[];
  id: string;
}) => {
  const currentItems = usePageContext(
    (store) =>
      store.data?.id === id &&
      store.data.category === "slide" &&
      store.data.items
  );

  return (
    <div className="aspect-video relative max-w-[15rem] mr-auto bg-white w-full border border-gray-100 rounded-xl overflow-hidden pointer-events-none">
      <CourseSlideThumbnail items={currentItems || items} />
    </div>
  );
};

export const CourseSlideThumbnail = ({ items }: { items: SlideItem[] }) => {
  const { ref, width, height } = useResizeObserver<HTMLDivElement>();

  const scale = Math.min(
    (width || 0) / SLIDE_WIDTH,
    (height || 0) / SLIDE_HEIGHT
  );

  const newItems = useMemo(() => {
    return updatePageSchema({
      items,
      version: 4,
      category: "slide",
      id: "any",
    });
  }, [items]);

  return (
    <div className="absolute-cover" ref={ref}>
      <div
        style={{
          width: SLIDE_WIDTH,
          height: SLIDE_HEIGHT,
          transform: `scale(${scale})`,
        }}
        className="absolute left-0 top-0 rounded-2xl bg-white origin-top-left"
      >
        <SlideRenderGrid items={newItems.items as SlideItem[]} />
      </div>
    </div>
  );
};

export const AddItemMenu = ({
  generateNewPage,
}: {
  generateNewPage: (data: NewCourseData) => void;
}) => {
  const { t } = useTranslation();
  const { courseId } = useParams<{ courseId: string }>();
  const [generate, setGenerate] = useState(false);
  const role = useRole();

  const handleImportFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    e.target.value = "";
    e.target.files = null;
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e?.target?.result) return toast.error("No JSON data");
      const { id, ...data } = JSON.parse(e.target.result as string);
      generateNewPage(data);
    };
    reader.readAsText(file);
  };

  const insertQuiz = <T extends QuizItem>(
    quizType: string,
    item: Omit<T, "id" | "category" | "instructions"> & {
      instructions?: T["instructions"];
    }
  ) => {
    generateNewPage({
      category: "quiz",
      quizType,
      items: [
        {
          id: uuid(),
          category: "quiz",
          instructions: [],
          ...item,
        } as QuizItem,
      ],
      name: COURSE_ITEMS(t)?.[quizType]?.name || "",
    });
  };

  const insertPage = <T extends ContentItem>(
    quizType: string,
    item: Omit<T, "id" | "category">
  ) => {
    generateNewPage({
      category: "content",
      quizType,
      items: [
        {
          id: uuid(),
          category: "content",
          ...item,
        } as T,
      ],
      name: "",
    });
  };

  return (
    <>
      {generate && (
        <GenerateTraining
          close={() => setGenerate(false)}
          trainingId={courseId}
        />
      )}
      <FloatingMenu
        size="xs"
        portal
        placement="bottom-start"
        containerClassName="flex-1"
        trigger={(toggle) => (
          <NewButton onClick={toggle} className="text-center justify-center">
            <TbPlus /> {t("v4.generic.add")}
          </NewButton>
        )}
      >
        {({ isOpen, setIsOpen }) => (
          <>
            <FloatingList
              items={[
                {
                  key: "generate",
                  name: t("v4.generate.text"),
                  icon: TbWand,
                  color: "bg-primary",
                  hidden: !role.aiEnabled,
                  onClick: () => {
                    setGenerate(true);
                    setIsOpen(false);
                  },
                },
                {
                  key: "content",
                  name: t("v4.slide.content"),
                  icon: TbBook,
                  color: "bg-blue-700",
                  items: [
                    {
                      key: "slide",
                      name: t("v4.slide.text"),
                      description: t("v4.slide.description"),
                      icon: TbRectangle,
                      color: "bg-blue-700",
                      onClick: () => {
                        generateNewPage({ category: "slide" });
                        setIsOpen(false);
                      },
                    },
                    {
                      key: "content",
                      name: t("v4.page.text"),
                      description: t("v4.page.description"),
                      icon: TbFileText,
                      color: "bg-blue-700",
                      onClick: () => {
                        generateNewPage({
                          category: "content",
                          items: [
                            {
                              id: uuid(),
                              type: PageItemType.Paragraph,
                              align: "left",
                              data: [[{ text: "" }]],
                            } as Paragraph,
                          ],
                          name: "Page",
                        });
                        setIsOpen(false);
                      },
                    },
                    {
                      key: "poll",
                      name: t("v4.poll.text"),
                      description: t("v4.poll.description"),
                      icon: TbChartBar,
                      color: "bg-blue-700",
                      onClick: () => {
                        generateNewPage({
                          category: "poll",
                          items: [],
                          name: "Polling",
                        });
                        setIsOpen(false);
                      },
                    },
                    {
                      key: "flashcards",
                      name: t("v4.item.flashcards.text"),
                      description: t("v4.item.flashcards.description"),
                      icon: TbCards,
                      color: "bg-blue-700",
                      onClick: () => {
                        insertPage<Flashcards>(PageItemType.Flashcards, {
                          type: PageItemType.Flashcards,
                          items: [
                            [
                              { id: uuid(), text: "" },
                              { id: uuid(), text: "" },
                            ],
                          ],
                        });
                        setIsOpen(false);
                      },
                    },
                  ],
                },
                {
                  key: "quiz",
                  name: t("v4.quiz.text"),
                  icon: TbPencil,
                  color: "bg-red-600",
                  items: [
                    {
                      key: "quiz",
                      name: t("v4.quiz.empty"),
                      icon: TbPencil,
                      color: "bg-red-600",
                      onClick: () => {
                        generateNewPage({
                          category: "quiz",
                          items: [],
                          name: "Quiz",
                        });
                        setIsOpen(false);
                      },
                    },
                    {
                      key: PageItemType.ImageLabeling,
                      ...COURSE_ITEMS(t)[PageItemType.ImageLabeling],
                      color: "bg-red-600",
                      onClick: () => {
                        insertQuiz<ImageLabeling>(PageItemType.ImageLabeling, {
                          type: PageItemType.ImageLabeling,
                          items: [],
                          size: 1,
                          showOptions: true,
                          consumptionType: ConsumptionType.multipleChoice,
                        });
                        setIsOpen(false);
                      },
                    },
                    {
                      key: PageItemType.FillTheGap,
                      ...COURSE_ITEMS(t)[PageItemType.FillTheGap],
                      color: "bg-red-600",
                      onClick: () => {
                        insertQuiz<FillTheGap>(PageItemType.FillTheGap, {
                          type: PageItemType.FillTheGap,
                          items: {},
                          data: "",
                          showOptions: true,
                          consumptionType:
                            ConsumptionType.multipleChoiceSemantic,
                        });
                        setIsOpen(false);
                      },
                    },
                    {
                      key: PageItemType.MultipleChoice,
                      ...COURSE_ITEMS(t)[PageItemType.MultipleChoice],
                      color: "bg-red-600",
                      onClick: () => {
                        insertQuiz<MultipleChoice>(
                          PageItemType.MultipleChoice,
                          {
                            type: PageItemType.MultipleChoice,
                            multi: false,
                            instructions: [
                              {
                                id: uuid(),
                                type: PageItemType.Paragraph,
                                category: "content",
                                data: [[{ text: "", bold: true }]],
                                align: "left",
                              },
                            ],
                            items: [
                              { text: "", id: uuid(), correct: true },
                              { text: "", id: uuid(), correct: false },
                              { text: "", id: uuid(), correct: false },
                            ],
                          }
                        );
                        setIsOpen(false);
                      },
                    },
                    {
                      key: PageItemType.Sorting,
                      ...COURSE_ITEMS(t)[PageItemType.Sorting],
                      color: "bg-red-600",
                      onClick: () => {
                        insertQuiz<Sorting>(PageItemType.Sorting, {
                          type: PageItemType.Sorting,
                          items: [{ id: uuid(), text: "" }],
                        });
                        setIsOpen(false);
                      },
                    },
                    {
                      key: PageItemType.Pairing,
                      ...COURSE_ITEMS(t)[PageItemType.Pairing],
                      color: "bg-red-600",
                      onClick: () => {
                        insertQuiz<Pairing>(PageItemType.Pairing, {
                          type: PageItemType.Pairing,
                          items: [
                            [
                              { id: uuid(), text: "" },
                              { id: uuid(), text: "" },
                            ],
                          ],
                        });
                        setIsOpen(false);
                      },
                    },
                    {
                      key: PageItemType.Categorisation,
                      ...COURSE_ITEMS(t)[PageItemType.Categorisation],
                      color: "bg-red-600",
                      onClick: () => {
                        insertQuiz<Categorisation>(
                          PageItemType.Categorisation,
                          {
                            type: PageItemType.Categorisation,
                            items: [
                              {
                                id: uuid(),
                                text: "",
                                items: [{ id: uuid(), text: "" }],
                              },
                            ],
                          }
                        );
                        setIsOpen(false);
                      },
                    },
                    {
                      key: PageItemType.Crossword,
                      ...COURSE_ITEMS(t)[PageItemType.Crossword],
                      color: "bg-red-600",
                      onClick: () => {
                        insertQuiz<Crossword>(PageItemType.Crossword, {
                          type: PageItemType.Crossword,
                          items: {},
                        });
                        setIsOpen(false);
                      },
                    },
                    {
                      key: PageItemType.FlashcardsQuiz,
                      ...COURSE_ITEMS(t)[PageItemType.FlashcardsQuiz],
                      color: "bg-red-600",
                      onClick: () => {
                        insertQuiz<FlashcardsQuiz>(
                          PageItemType.FlashcardsQuiz,
                          {
                            type: PageItemType.FlashcardsQuiz,
                            items: [
                              [
                                { id: uuid(), text: "" },
                                { id: uuid(), text: "" },
                              ],
                            ],
                            consumptionType: ConsumptionType.multipleChoice,
                          }
                        );
                        setIsOpen(false);
                      },
                    },
                    // {
                    //   key: PageItemType.ShortAnswer,
                    //   ...COURSE_ITEMS(t)[PageItemType.ShortAnswer],
                    //   color: "bg-red-600",
                    //   onClick: () => {
                    //     insertQuiz<ShortAnswer>(PageItemType.ShortAnswer, {
                    //       type: PageItemType.ShortAnswer,
                    //       items: [],
                    //       consumptionType: ConsumptionType.typeTheAnswer,
                    //       instructions: [
                    //         {
                    //           id: uuid(),
                    //           type: PageItemType.Paragraph,
                    //           category: "content",
                    //           data: [[{ text: "" }]],
                    //           align: "left",
                    //         },
                    //       ],
                    //     });
                    //     setIsOpen(false);
                    //   },
                    // },
                  ],
                },
              ]}
              open={isOpen}
            />
            {role.superuser && (
              <label>
                <input
                  type="file"
                  accept=".json"
                  className="hidden"
                  onChange={handleImportFile}
                />
                <LargeListItem
                  icon={TbTool}
                  name={t("v4.generic.import")}
                  color="bg-gray-700"
                  description={t("v4.editView.importJSON")}
                />
              </label>
            )}
          </>
        )}
      </FloatingMenu>
    </>
  );
};
