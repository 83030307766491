import { useRef } from "react";

type DummyType = any;

export const useDebounce = <T extends DummyType = any>(
  fun: (data: T) => void,
  delay = 500
) => {
  const debounceRef = useRef<ReturnType<typeof setTimeout>>();

  return (data: T) => {
    if (debounceRef.current) clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => fun(data), delay);
  };
};
