import styled from "styled-components";

export const PrimaryButton = styled.button`
  font-size: 1rem;
  background-color: rgb(var(--brand-color));
  border-radius: 0.75rem;
  padding: 0.75rem;
  font-weight: bold;
  color: white;
  transition: opacity 0.1s ease;
  outline: none;

  &:hover,
  &:focus-visible {
    opacity: ${({ disabled }) => !disabled && 0.8};
  }

  opacity: ${({ disabled }) => disabled && 0.5};
  cursor: ${({ disabled }) => disabled && "default"};
`;

export const SecondaryButton = styled(PrimaryButton)`
  background-color: var(--light-grey);
  color: var(--dark-grey);
`;
