import { useTranslation } from "react-i18next";
import { useSessionContext } from "app/hooks/useSessionContext";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbArrowLeft, TbArrowRight, TbCheck } from "react-icons/tb";
import { Tooltip } from "app/components/Tooltip";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { getSessionStatus } from "app/components/Exercises/utils/sessionStatus";
import WarningModal from "app/components/Modal/WarningModal";
import { useDetectKeyPress } from "helpers/hooks/detectKeyPress";
import {
  CourseIcon,
  CourseSlideThumbnail,
} from "app/pages/courses/Edit/CoursePanel";
import { pagePlaceholder } from "../../../pages/courses/Edit/CourseEditView";

export const useWheelForHorizontalScroll = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    const onWheel = (e) => {
      if (e.deltaY == 0) return;
      e.preventDefault();
      el.scrollBy(e.deltaY, 0);
    };
    el.addEventListener("wheel", onWheel);
    return () => el.removeEventListener("wheel", onWheel);
  }, []);
  return ref;
};

export const TeacherControlBar = ({ isFullscreen }) => {
  const { t } = useTranslation();
  const { session } = useSessionContext();
  const [showFinish, setShowFinish] = useState(false);
  const scrollRef = useWheelForHorizontalScroll();

  useEffect(() => {
    const el = scrollRef.current;
    if (!el) return;

    const onWheel = (e) => {
      if (e.deltaY == 0) return;
      e.preventDefault();
      el.scrollBy(e.deltaY, 0);
    };
    el.addEventListener("wheel", onWheel);
    return () => el.removeEventListener("wheel", onWheel);
  }, []);

  const {
    current_exercise,
    status,
    exercises: all_exercises,
    exercises_in_scope,
  } = session.get?.status ?? {};

  useEffect(() => {
    const el = scrollRef.current;
    if (!el) return;
    el.scroll({
      left:
        (document.getElementById(`slide_${current_exercise}`)?.offsetLeft ??
          0) -
        el.clientWidth / 4,
      behavior: "smooth",
    });
  }, [current_exercise]);

  const { exercise, exerciseBefore, exerciseClosing } =
    getSessionStatus(status);

  const exercises = all_exercises.filter((_, i) => !!exercises_in_scope?.[i]);
  const lastItem = current_exercise === exercises.length - 1;
  const { startExercise, closeExercise, closeSession, jumpExercise } =
    session.get;

  const handleJumpAction = (index: number) => {
    jumpExercise(index);
  };

  const handleContinue = () => {
    if (exercise) return;
    if (!lastItem) return handleJumpAction(current_exercise + 1);
    if (!showFinish) return setShowFinish(true);
    closeSession();
  };

  useDetectKeyPress("ArrowRight", handleContinue, [
    showFinish,
    exercise,
    current_exercise,
  ]);

  useDetectKeyPress(
    " ",
    () => {
      if (exerciseClosing) return;
      if (exerciseBefore) return startExercise();
      if (exercise) return closeExercise();
      handleContinue();
    },
    [showFinish, exercise, current_exercise, exerciseBefore]
  );

  useDetectKeyPress(
    "ArrowLeft",
    () => {
      if (exercise) return;
      if (showFinish) return setShowFinish(false);
      if (current_exercise > 0) return handleJumpAction(current_exercise - 1);
    },
    [showFinish, exercise, current_exercise]
  );

  return (
    <div className="flex flex-col group">
      {/*{isFullscreen && <div className="absolute bottom-0 h-0.5 w-full z-10" />}*/}
      <div
        className={classNames(
          "flex flex-col transition",
          isFullscreen && "opacity-50 hover:opacity-100"
        )}
      >
        <div
          className={classNames(
            "flex items-center p-1 xs:p-2 gap-1",
            exercise && "pointer-events-none opacity-20"
          )}
        >
          <div
            ref={scrollRef}
            className="flex items-center grow !overflow-x-auto mr-2 rounded-lg scrollbar-none"
            // onWheel={(e) => {
            //   e.preventDefault();
            //   console.log({...e.target})
            //   e.target.scrollLeft += e.deltaY;
            // }}
          >
            <div className="flex items-center gap-1 transition m-auto">
              {exercises.map(
                (
                  { type, question, status, content, category, quiz_type },
                  i
                ) => (
                  <Tooltip
                    value={
                      current_exercise !== i &&
                      (question ||
                        pagePlaceholder({
                          category: category,
                          quizType: quiz_type,
                        })(t))
                    }
                    delay={[500, 0]}
                    key={i}
                  >
                    <div
                      id={`slide_${i}`}
                      className={classNames(
                        "bg-white h-10 aspect-video rounded-lg relative overflow-hidden transition-all",
                        current_exercise !== i &&
                          "opacity-75 hover:opacity-100 cursor-pointer"
                      )}
                      onClick={() =>
                        !exercise &&
                        current_exercise !== i &&
                        handleJumpAction(i)
                      }
                    >
                      <div
                        className={classNames(
                          type &&
                            type !== "slide" &&
                            !type.startsWith("polling_") &&
                            status === "open"
                            ? "bg-gray-300 text-gray-600"
                            : "bg-primary text-white",
                          "absolute text-sm h-4 flex items-center justify-center text-center rounded top-0.5 left-0.5 px-1 py-0.5 z-[1] font-bold"
                        )}
                      >
                        {i + 1}
                        {status === "closed" && (
                          <TbCheck
                            strokeWidth={4.5}
                            className="ml-0.5 text-base"
                          />
                        )}
                      </div>

                      <div
                        className={classNames(
                          "absolute-cover z-[1] rounded-lg transition",
                          current_exercise !== i && "opacity-0"
                        )}
                        style={{
                          boxShadow: `inset 0 0 0 0.25rem var(--main-color)`,
                        }}
                      />
                      {category === "slide" && content?.items ? (
                        <CourseSlideThumbnail items={content?.items} />
                      ) : (
                        <CourseIcon category={category} quizType={quiz_type}>
                          {(Icon, color) => (
                            <div
                              className={classNames(
                                "absolute-cover flex items-center justify-center",
                                color
                              )}
                            >
                              <Icon className="text-2xl" />
                            </div>
                          )}
                        </CourseIcon>
                      )}
                    </div>
                  </Tooltip>
                )
              )}
            </div>
          </div>

          <NewButton
            color="bg-black"
            size="lg"
            iconOnly
            disabled={!current_exercise || exercise}
            onClick={() =>
              !!current_exercise &&
              !exercise &&
              handleJumpAction(current_exercise - 1)
            }
          >
            <TbArrowLeft />
          </NewButton>
          <NewButton
            color={lastItem ? "bg-primary text-primary" : "bg-black"}
            size="lg"
            iconOnly={!lastItem}
            // onClick={() => handleNextAction()}
            disabled={exercise}
            onClick={() =>
              !exercise && lastItem
                ? setShowFinish(true)
                : handleJumpAction(current_exercise + 1)
            }
          >
            {lastItem && (
              <span className="text-sm font-bold">
                {t("exercisePage.consumption.formatsCommands.finish")}
              </span>
            )}
            <TbArrowRight />
          </NewButton>
        </div>
      </div>

      <WarningModal
        onConfirm={() => closeSession()}
        open={showFinish}
        closeModal={() => setShowFinish(false)}
        text={t("liveSession.endSessionPrompt")}
        confirmButton={t("warningPopup.deleteGroup.confirmButton")}
        closeButton={t("warningPopup.deleteGroup.closeButton")}
      />
    </div>
  );
};
