import { useEffect, useState } from "react";
import Loader from "../Loader";

const SpinnerButton = ({ isLoading, children, ...props }: any) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  useEffect(() => {
    if (isLoading) {
      setShowLoader(true);
    }

    if (!isLoading && showLoader) {
      const timeout = setTimeout(() => {
        setShowLoader(false);
      }, 200);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isLoading, showLoader]);

  return (
    <button type="submit" {...props}>
      {showLoader ? (
        <div>
          <Loader light />
        </div>
      ) : (
        <div>{children}</div>
      )}
    </button>
  );
};

export default SpinnerButton;
