import styled from "styled-components";
import React from "react";

export const InteractiveIcon = styled.i`
  color: var(--dark-grey);
  padding: 0.4rem;
  text-align: center;
  width: 1.75rem;
  height: fit-content;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: rgb(var(--brand-color));
  }
`;
