import styled, { keyframes } from "styled-components";
import React, { CSSProperties, ReactNode } from "react";
import icClose from "assets/images/menu-close.png";
import { createPortal } from "react-dom";
import classNames from "classnames";

export const ModalContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
`;

const fadeInBg = keyframes`
  from {
    opacity: 0;
  }
`;

export const ModalBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #c3c3c3c4;
  animation: ${fadeInBg} 0.2s ease backwards;
`;

const fadeInModal = keyframes`
  from {
    opacity: 0;
    transform: scale(0.98);
  }
`;

export const StyledModal = styled.div`
  padding: 2rem;
  width: 100%;
  background: var(--white-background);
  border-radius: 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  max-height: 100%;
  overflow-y: auto;
  animation: ${fadeInModal} 0.2s ease backwards;
  white-space: pre-line;

  h4 {
    margin: 0;
  }

  h3 {
    margin-top: 0;
  }
`;

export const ModalClose = ({ onClick }: { onClick: () => void }) => (
  <ScCloseButton onClick={onClick}>
    <img src={icClose} alt="" />
  </ScCloseButton>
);

export const ScCloseButton = styled.button`
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;

  img {
    width: 100%;
    height: 100%;
  }
`;

interface Props {
  children: ReactNode;
  close?: any;
  style?: CSSProperties;
  className?: React.Component<{ className: string }>;
}

export const GlobalModal = ({
  children,
  close = undefined,
  style,
  className,
}: Props) =>
  createPortal(
    <ModalContainer
      style={{ position: "fixed", zIndex: 9999 }}
      // onClick={(e) => e.preventDefault()}
    >
      <ModalBackground onClick={close} />
      <StyledModal
        style={{ width: "auto", minWidth: "min(20rem, 100%)", ...style }}
        className={classNames("text-center items-center !max-w-lg", className)}
      >
        {close && <ModalClose onClick={close} />}
        {children}
      </StyledModal>
    </ModalContainer>,
    document.body
  );
