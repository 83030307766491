import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TbCheck, TbTrash, TbTypography } from "react-icons/tb";
import { toast } from "react-hot-toast";
import { Redirect, useParams } from "react-router";
import { deleteGroup, getGroup, updateGroup } from "api/groups/groupsAPI";
import { Header } from "app/components/Header";
import { NewButton } from "app/components/Buttons/NewButton";
import { GroupInfo, GroupSidebar } from "app/pages/groups/GroupMembers";
import { CgSpinner } from "react-icons/cg";
import { InputText } from "app/components/Buttons/InputText";
import { ColorPicker } from "app/pages/groups/components/ColorPicker";
import { useHistory } from "react-router-dom";
import { groupRoutes } from "enums/routes";
import WarningModal from "app/components/Modal/WarningModal";
import { useIsGroupAdmin } from "app/hooks/useRole";
import { useTranslation } from "react-i18next";
import { translateError } from "app/components/Exercises/Edit/missingTranslation";

export const GroupSettings = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { isSuccess, data } = useQuery(["group", id], () => getGroup(id));
  const queryClient = useQueryClient();
  const location = useHistory();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const isGroupAdmin = useIsGroupAdmin(id);

  const deleteMutation = useMutation(() => deleteGroup(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["groups"]);
      location.push(groupRoutes.list);
      toast.success(t("v4.group.deleteSuccess"));
    },
    onError: () => {
      toast.error(translateError(t("v4.group.deleteError"), t));
    },
    onSettled: () => {
      setDeleteOpen(false);
    },
  });

  const isLoading = !isSuccess || !isGroupAdmin.isSuccess;

  if (isGroupAdmin.isSuccess && !isGroupAdmin.data)
    return <Redirect to={groupRoutes.assignments(id)} />;

  return (
    <>
      <Header />
      <main className="flex flex-col w-full justify-center py-6 px-4 text-gray-600">
        <GroupInfo />
        <div className="flex flex-col md:flex-row justify-center">
          <GroupSidebar />
          <div className="flex flex-col gap-4 max-w-3xl grow mb-auto">
            <div className="bg-white rounded-2xl shadow-xl p-6 flex flex-col">
              {isLoading ? (
                <CgSpinner className="text-3xl text-gray-400 m-auto animate-spin" />
              ) : (
                <div className="flex flex-col gap-2">
                  <GroupForm name={data.group_name} color={data.group_color} />
                </div>
              )}
            </div>
            {!isLoading && (
              <div className="bg-white shadow-red-500/10 rounded-2xl shadow-xl p-6 flex flex-col">
                <div className="flex flex-col gap-2">
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                      <div className="text-lg font-bold text-gray-600">
                        {t("v4.group.delete")}
                      </div>
                      <div className="text-gray-500">
                        {t("v4.generic.deletePrompt")}
                      </div>
                    </div>
                    <NewButton
                      size="lg"
                      variant="primary"
                      color="bg-red-500 text-white"
                      onClick={() => setDeleteOpen(true)}
                      disabled={deleteMutation.isLoading}
                    >
                      {deleteMutation.isLoading ? (
                        <CgSpinner className="animate-spin" />
                      ) : (
                        <TbTrash />
                      )}
                      {t("v4.generic.delete")}
                    </NewButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>

      <WarningModal
        onConfirm={deleteMutation.mutate}
        open={deleteOpen}
        closeModal={() => setDeleteOpen(false)}
        text={t("v4.group.deletePrompt")}
        confirmButton={t("v4.generic.delete")}
        closeButton={t("v4.generic.cancel")}
      />
    </>
  );
};

const GroupForm = (props: { name: string; color: string | null }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [name, setName] = useState(props.name);
  const [color, setColor] = useState(props.color);
  const queryClient = useQueryClient();

  const updateMutation = useMutation(() => updateGroup(id, { name, color }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["group", id]);
      toast.success(t("v4.group.updateSuccess"));
    },
    onError: () => {
      toast.error(translateError(t("v4.group.updateError"), t));
    },
  });

  return (
    <>
      <div className="text-lg font-bold text-gray-500">
        {t("v4.group.name")}
      </div>
      <InputText
        value={name}
        onChange={setName}
        icon={TbTypography}
        maxLength={50}
        readOnly={updateMutation.isLoading}
      />
      <div className="text-lg font-bold text-gray-500 mt-4">
        {t("v4.group.color")}
      </div>
      <div
        className="grid gap-2 flex-wrap justify-center"
        style={{
          gridTemplateColumns: "repeat(auto-fill, minmax(2.5rem, 1fr))",
        }}
      >
        <ColorPicker
          setSelectedColor={updateMutation.isLoading ? () => null : setColor}
          selectedColor={color}
        />
      </div>
      <div className="flex justify-end mt-6">
        <NewButton
          size="lg"
          variant="primary"
          onClick={updateMutation.mutate}
          disabled={
            (name === props.name && color === props.color) ||
            updateMutation.isLoading
          }
        >
          {updateMutation.isLoading ? (
            <CgSpinner className="animate-spin" />
          ) : (
            <TbCheck />
          )}
          {t("v4.generic.submit")}
        </NewButton>
      </div>
    </>
  );
};
