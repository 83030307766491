import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DetectRTC from "detectrtc";
import { FILE_SIZE_LIMIT } from "helpers/file";
import { toast } from "react-hot-toast";
import { AudioPlayer, VideoPlayer } from "app/components/MediaPlayer";
import { NewButton } from "app/components/Buttons/NewButton";
import { CgSpinner } from "react-icons/cg";
import { VideoPicker } from "app/components/Forms/MediaForm/VideoPicker";
import { useSourceUploadContext } from "api/ws/SourceUploadContext";
import { TbArrowBackUp, TbCircleCheck, TbX } from "react-icons/tb";
import { InputText } from "app/components/Buttons/InputText";
import { SelectOnlyType } from "app/components/Sources/MediaPicker/MediaPicker";
import { useSelectOnly } from "app/components/Sources/MediaPicker/context/selectOnlyContext";

export const MediaRecord = ({
  close,
  back,
  setFile,
}: {
  close: () => void;
  back: () => void;
  setFile: (file: string | null) => void;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVideoMode, setIsVideoMode] = useState(true);
  const [recordedBlob, setRecordedBlob] = useState<Blob | null>(null);
  const [hasMic, setHasMic] = useState(false);
  const [hasCamera, setHasCamera] = useState(false);
  const { t } = useTranslation();
  const selectOnly = useSelectOnly();

  useEffect(() => {
    DetectRTC.load(() => {
      setIsLoading(false);
      if (!DetectRTC.hasMicrophone) return;

      setHasMic(DetectRTC.isWebsiteHasMicrophonePermissions);
      const webcam =
        DetectRTC.hasWebcam && DetectRTC.isWebsiteHasWebcamPermissions;
      setHasCamera(webcam);

      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: true,
        })
        .then((track) => {
          setIsLoading(true);
          DetectRTC.load(() => {
            setIsLoading(false);
            setHasMic(DetectRTC.isWebsiteHasMicrophonePermissions);
            const webcam =
              DetectRTC.hasWebcam && DetectRTC.isWebsiteHasWebcamPermissions;
            setHasCamera(webcam);
            setIsVideoMode(webcam);
            track.getTracks().forEach((track) => {
              track.stop();
            });
          });
        })
        .catch(async () => {
          setHasMic(false);
          setHasCamera(false);
          close();
        });
    });
  }, []);

  const isVideo = isVideoMode && hasCamera;

  return (
    <>
      <div className="p-1 font-bold border-b-2 border-gray-100 flex items-center gap-2 text-gray-500">
        {selectOnly !== SelectOnlyType.none && (
          <NewButton onClick={back} size="sm">
            <TbArrowBackUp />
            {t("v4.generic.back")}
          </NewButton>
        )}

        <div className="font-bold text-gray-500">
          {t("v4.library.recordSource")}
        </div>
        <NewButton
          iconOnly
          onClick={close}
          className="ml-auto"
          variant="transparent"
        >
          <TbX />
        </NewButton>
      </div>
      <div className="grow relative">
        <div className="text-center absolute-cover flex flex-col items-center justify-center p-8">
          {isLoading ? (
            <CgSpinner className="animate-spin text-3xl" />
          ) : !DetectRTC.hasMicrophone ? (
            <div className="text-lg text-gray-500 gap-4 font-bold flex flex-col justify-center items-center text-center">
              <TbCircleCheck className="text-6xl" strokeWidth={1.25} />
              {t("media.noMicPrompt")}
            </div>
          ) : !hasMic ? (
            <div className="text-lg text-gray-500 gap-4 font-bold flex flex-col justify-center items-center text-center">
              <TbCircleCheck className="text-6xl" strokeWidth={1.25} />
              {t("media.allowPrompt")}
            </div>
          ) : !recordedBlob ? (
            <VideoPicker
              {...{ setRecordedBlob, isVideo }}
              isCamera={hasCamera}
              setIsVideo={setIsVideoMode}
            />
          ) : (
            <MediaPreview
              {...{
                recordedBlob,
                isVideo,
                setFile,
              }}
              cancelVideo={() => setRecordedBlob(null)}
            />
          )}
        </div>
      </div>
    </>
  );
};

const MediaPreview = ({ recordedBlob, isVideo, setFile, cancelVideo }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [recordedUrl, setRecordedUrl] = useState<string>("");
  const [name, setName] = useState("");
  const { upload } = useSourceUploadContext();

  useEffect(() => {
    if (!recordedBlob) return;
    const file = blobToFile(recordedBlob);
    const url = URL.createObjectURL(file);
    setRecordedUrl(url);

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [recordedBlob]);

  const saveSource = useCallback(async () => {
    if (!recordedBlob) return;
    setIsLoading(true);
    const file = blobToFile(recordedBlob, name);
    if (file.size > FILE_SIZE_LIMIT) {
      toast.error(t("sourcesPage.status.uploading.errorSize"));
      setIsLoading(false);
      return;
    }
    upload({
      file,
      name:
        name ||
        (isVideo ? t("media.video.videoName") : t("media.audio.audioName")),
      resolve: setFile,
    });
  }, [recordedBlob, t, name, isVideo]);

  return (
    <>
      {!recordedUrl ? (
        <CgSpinner className="animate-spin text-3xl absolute" />
      ) : isVideo ? (
        <VideoPlayer src={recordedUrl} />
      ) : (
        <AudioPlayer src={recordedUrl} />
      )}
      <div>
        <InputText
          value={name}
          onChange={setName}
          readOnly={isLoading}
          maxLength={200}
          className="mt-4"
          placeholder={
            isVideo ? t("media.video.videoName") : t("media.audio.audioName")
          }
        />
      </div>
      <div className="grid grid-cols-2 gap-2 mt-4">
        <NewButton center size="lg" onClick={cancelVideo} disabled={isLoading}>
          {t("media.recordAgain")}
        </NewButton>
        <NewButton
          variant="primary"
          center
          size="lg"
          onClick={saveSource}
          disabled={isLoading}
        >
          {isLoading && <CgSpinner className="animate-spin" />}
          {t("common.save")}
        </NewButton>
      </div>
    </>
  );
};

const blobToFile = (blob: Blob, name = "") =>
  new File(
    [blob],
    (name || `smartest_${new Date().getTime()}`) +
      (blob.type.includes("webm")
        ? blob.type.includes("audio")
          ? ".weba"
          : ".webm"
        : ".mp4"),
    {
      type: blob.type,
      lastModified: new Date().getTime(),
    }
  );
