import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { userDetails } from "../../store/user";
import { lessonRoutes } from "../../../enums/routes";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbSchool, TbUser } from "react-icons/tb";
import { CgSpinner } from "react-icons/cg";
import { InputText } from "app/components/Buttons/InputText";
import { useMutation } from "react-query";
import { putUpdateUserDetails } from "api/userAPI";
import { OrSeparator } from "app/pages/auth/Registration";

export const AccountSetup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const history = useHistory();

  const updateAccountPro = useMutation(
    () =>
      putUpdateUserDetails({
        is_org_pro: true,
        pro_code: code,
      }),
    {
      onSuccess: () => {
        toast.success(t("authPages.register.profileUpdated.label"));
        history.push(lessonRoutes.list);
        dispatch(userDetails(""));
      },
      onError: (data: any) => {
        const error = data?.response?.data?.error;
        if (error === "PROMOTION_CODE_EXPIRED") {
          toast.error(t("v4.account.codeExpired"));
          setCode("");
        }
        if (error === "PROMOTION_CODE_INVALID") {
          toast.error(t("v4.account.codeInvalid"));
          setCode("");
        }
        console.log({ error });
      },
    }
  );

  const updateAccount = useMutation(
    () =>
      putUpdateUserDetails({
        is_org_pro: false,
      }),
    {
      onSuccess: () => {
        toast.success(t("authPages.register.profileUpdated.label"));
        history.push(lessonRoutes.list);
        dispatch(userDetails(""));
      },
      onError: (data: any) => {
        const error = data?.response?.data?.error;
        console.log({ error });
      },
    }
  );

  return (
    <div className="text-center flex flex-col justify-center items-center my-12 gap-2 text-gray-600 px-4">
      <h1>{t("v4.account.welcome")}</h1>
      <p>{t("v4.account.b2bIntro")}</p>
      <div className="flex flex-col w-full max-w-xs gap-2 mt-8">
        <form
          className="flex flex-col gap-2"
          onSubmit={(e) => {
            e.preventDefault();
            if (updateAccountPro.isLoading || code.length < 5) return;
            updateAccountPro.mutate();
          }}
        >
          <InputText
            value={code}
            onChange={(code) => setCode((code || "").toUpperCase())}
            maxLength={5}
            placeholder={t("v4.account.trainerCode")}
            className="[&_input]:text-center"
          />
          <NewButton
            size="lg"
            center
            color="bg-primary text-primary"
            className="font-bold"
            variant="strongLight"
            component="button"
            type="submit"
            disabled={updateAccountPro.isLoading || code.length < 5}
          >
            {updateAccountPro.isLoading ? (
              <CgSpinner className="animate-spin" />
            ) : (
              <TbSchool />
            )}
            {t("v4.account.trainerContinue")}
          </NewButton>
        </form>

        <OrSeparator className="my-6" />

        <NewButton
          size="lg"
          center
          variant="primary"
          onClick={updateAccount.mutate}
          disabled={updateAccount.isLoading}
        >
          {updateAccount.isLoading ? (
            <CgSpinner className="animate-spin" />
          ) : (
            <TbUser />
          )}
          {t("v4.account.learnerContinue")}
        </NewButton>
      </div>
    </div>
  );
};
