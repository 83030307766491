import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postActivation,
  postForgotPasswordMail,
  postLogout,
  postRegistration,
  postResendActivationEmail_,
  postResetPassword,
  postSignIn,
  postSignInByApple,
  postSignInByGoogle,
  postSignInByMS,
} from "api/authAPI";
import {
  AccessToken,
  SessionMessagesToken,
  SessionToken,
} from "enums/constant";
import setAuthTokenAxios from "../../../config/axios/setAuthTokenAxios";
import {
  ILoginAppleForm,
  ILoginForm,
  ILoginGoogleForm,
  IRegistrationForm,
} from "models/api/auth.interface";
import cookies from "js-cookie";
import { use2FAContext } from "app/App";
import { useRoleContext } from "app/hooks/useRole";
import { userDetails } from "app/store/user";
import { useSourceUploadStore } from "api/ws/SourceUploadContext";

export const userRegistration = createAsyncThunk(
  "auth/post/register",
  async (userData: IRegistrationForm, thunkApi) => {
    try {
      return await postRegistration(userData);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const activateRegistration = createAsyncThunk(
  "auth/post/activate",
  async (data: any, thunkApi) => {
    try {
      return await postActivation(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/post/reset-password",
  async (data: any, thunkApi) => {
    try {
      return await postResetPassword(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const resendActivationMail = createAsyncThunk(
  "auth/post/resend-activation-email",
  async (data: any, thunkApi) => {
    try {
      return await postResendActivationEmail_(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

export const forgotPasswordMail = createAsyncThunk(
  "auth/post/resend-password",
  async (data: any, thunkApi) => {
    try {
      return await postForgotPasswordMail(data);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkApi.rejectWithValue(err.response.data);
    }
  }
);

const handleSignIn = (response: Record<string, string>, dispatch: any) => {
  const accessToken = response.key;
  localStorage.setItem(AccessToken, accessToken);
  setAuthTokenAxios(accessToken);

  update2FA(response);
  useRoleContext.getState().readPayload(response);
  dispatch(userDetails(""));
};

export const userSignIn = createAsyncThunk(
  "auth/post/signin",
  async (userData: ILoginForm, thunkApi) => {
    const response = await postSignIn(userData);
    if (response.status > 400) {
      return thunkApi.rejectWithValue(await response.json());
    }

    handleSignIn(response, thunkApi.dispatch);
    return response;
  }
);

export const userSignInGoogle = createAsyncThunk(
  "auth/post/signin/google",
  async (userData: ILoginGoogleForm, thunkApi) => {
    const response = await postSignInByGoogle(userData);
    if (response.status > 400) {
      return thunkApi.rejectWithValue(await response.json());
    }

    handleSignIn(response, thunkApi.dispatch);
    return response;
  }
);

export const userSignInApple = createAsyncThunk(
  "auth/post/signin/apple",
  async (userData: ILoginAppleForm, thunkApi) => {
    const response = await postSignInByApple(userData);
    if (response.status > 400) {
      return thunkApi.rejectWithValue(await response.json());
    }

    handleSignIn(response, thunkApi.dispatch);
    return response;
  }
);

export const userSignInMS = createAsyncThunk(
  "auth/post/signin/MS",
  async (userData: ILoginGoogleForm, thunkApi) => {
    const response = await postSignInByMS(userData);
    if (response.status > 400) {
      return thunkApi.rejectWithValue(await response.json());
    }

    handleSignIn(response, thunkApi.dispatch);
    return response;
  }
);

export const userLogout = createAsyncThunk("auth/post/logout", async () => {
  useRoleContext.getState().reset();
  useSourceUploadStore.getState().reset();

  if (localStorage.getItem(AccessToken)) {
    localStorage.removeItem(AccessToken);
  }
  use2FAContext.getState().set(false);
  if (cookies.get(SessionToken)) {
    cookies.remove(SessionToken);
  }
  if (cookies.get(SessionMessagesToken)) {
    cookies.remove(SessionMessagesToken);
  }
  const response = await postLogout();
  setAuthTokenAxios("");

  return response;
});

export const update2FA = (response: any) => {
  if (response) {
    if (!response.is_2fa_confirmed) {
      use2FAContext.getState().set(true);
    } else {
      use2FAContext.getState().set(false);
    }
  }
};
