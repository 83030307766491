import {
  PageItemType,
  SlideParagraphSummarize,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { postSimplify } from "api/course/courseContentAPI";
import { textToParagraphs } from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { SlidePageGenerate } from "app/components/Exercises/CourseEdit/components/PageGenerate";

export const SlidePageParagraphSummarize = () => (
  <SlidePageGenerate
    promise={(item: SlideParagraphSummarize) => postSimplify(item.data)}
    gen={({ text_predicted }, { id, position }) => ({
      id,
      position,
      verticalAlign: "center",
      type: PageItemType.Paragraph,
      category: "slide",
      align: "left",
      data: textToParagraphs(text_predicted),
    })}
  />
);
