import React, { ComponentProps } from "react";
import classNames from "classnames";
import { CgSpinner } from "react-icons/cg";

type Props = {
  onChange?: (value: boolean) => void;
  value?: boolean;
  defaultValue?: boolean;
  containerClassName?: string;
  loading?: boolean;
} & Omit<ComponentProps<"input">, "value" | "onChange">;

export const InputToggle = ({
  onChange,
  value,
  disabled,
  containerClassName,
  loading,
  ...props
}: Props) => (
  <div
    className={classNames(
      "w-9 h-5 relative rounded-full cursor-pointer block overflow-hidden shrink-0",
      containerClassName
    )}
  >
    <input
      type="checkbox"
      className="hidden [&:checked+span]:translate-x-full"
      checked={value}
      onChange={() => onChange?.(!value)}
      {...props}
    />
    <div
      className={classNames(
        "absolute-cover bg-gray-300 [input:checked~&]:bg-primary transition",
        disabled && "animate-pulse"
      )}
    />
    <div className="w-4 h-4 left-0.5 [input:checked~&]:translate-x-full rounded-full flex absolute top-0.5 transition bg-white transform">
      <CgSpinner
        className={classNames(
          "animate-spin text-base m-auto transition text-gray-500",
          !loading && "opacity-0"
        )}
      />
    </div>
  </div>
);
