import { apiUrlV1 } from "enums/constant";
import axios from "axios";

export const getGroupsList = async ({ page = 0 }, courseId?: string[]) => {
  const url =
    `${apiUrlV1}/v4/group/list/` +
    `?limit=${20}` +
    `&offset=${page * 20}` +
    (courseId == null ? "" : `&course_id=${courseId.join(",")}`);
  const res = await axios.get(url);
  return res.data;
};

export const getGroupPin = async (id: string) => {
  const url = `${apiUrlV1}/group/pin/${id}/`;
  const res = await axios.get(url);
  return res.data;
};

export const postNewGroupPin = async (id: string, data?: any) => {
  const url = `${apiUrlV1}/group/pin/${id}/`;
  const res = await axios.post(url, data);
  return res.data;
};

export const postCreateGroup = async (data: any) => {
  const url = `${apiUrlV1}/groups/`;
  const res = await axios.post(url, data);
  return res.data;
};

export const postDuplicateGroup = async (id: string, data = {}) => {
  const url = `${apiUrlV1}/group/${id}/copy/`;
  const res = await axios.post(url, data);
  return res.data;
};

export const postJoinGroup = async (id: string) => {
  const url = `${apiUrlV1}/group/join/${id}/`;
  const { data } = await axios.post(url);
  return data;
};

export const getGroup = async (id: string) => {
  const url = `${apiUrlV1}/groups/${id}/`;
  const res = await axios.get(url);
  return res.data;
};

export const updateGroup = async (
  id: string,
  data: { name?: string; color?: string | null; guest_access?: boolean }
) => {
  const url = `${apiUrlV1}/groups/${id}/`;
  const res = await axios.put(url, data);
  return res.data;
};

export const deleteGroup = async (id: string) => {
  const url = `${apiUrlV1}/groups/${id}/`;
  const res = await axios.delete(url);
  return res.data;
};

export const removeGroupMember = async (
  groupId: string,
  users: string | string[]
) => {
  const url = `${apiUrlV1}/group/members/remove/${groupId}/`;
  const res = await axios.put(url, {
    members: typeof users === "string" ? [users] : users,
  });
  return res.data;
};

export const updateGroupMember = async (
  groupId: string,
  memberId: string,
  data: { is_admin?: boolean }
) => {
  const url = `${apiUrlV1}/group/${groupId}/member/${memberId}/`;
  const res = await axios.put(url, data);
  return res.data;
};

// GUEST GROUP ROUTES
export const getCanGuestJoinGroup = async (pin: string) => {
  const url = `${apiUrlV1}/guest/group/check/${pin}/`;
  const res = await axios.get<{ guest_access: boolean }>(url);
  return res.data;
};

export const postJoinGuestGroup = async (pin: string, name: string) => {
  const url = `${apiUrlV1}/guest/group/join/${pin}/`;
  const res = await axios.post<any>(url, { guest_name: name });
  return res.data;
};

export const postGetGuestGroupCourses = async ({ page = 0 }, pin: string) => {
  const url =
    `${apiUrlV1}/guest/${pin}/courses/received/` +
    `?limit=${20}` +
    `&offset=${page * 20}`;
  const res = await axios.get<any>(url);
  return res.data;
};
