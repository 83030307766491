import React, { ComponentProps, ReactNode, useEffect, useState } from "react";
import {
  TbArrowsShuffle,
  TbBrandYoutube,
  TbCards,
  TbChevronRight,
  TbColumns2,
  TbIcons,
  TbLayoutGrid,
  TbLetterCase,
  TbListCheck,
  TbListDetails,
  TbMessageCircle2,
  TbPhoto,
  TbSlideshow,
  TbSpace,
  TbSubtask,
} from "react-icons/tb";
import { PageItemType } from "app/components/Exercises/CourseEdit/courseEditTypes";
import classNames from "classnames";
import { IconType } from "react-icons";
import { FloatingMenu } from "app/components/Header";

export const LargeListItem = ({
  icon: Icon,
  name,
  description,
  color,
  children,
  disabled,
  ...rest
}: {
  icon: IconType;
  name: string;
  description?: ReactNode;
  color: string;
  disabled?: boolean;
} & ComponentProps<"div">) => (
  <div
    className={classNames(
      color,
      disabled ? "opacity-50" : "hover:bg-opacity-10 cursor-pointer",
      "rounded-lg items-center w-full transition p-1.5 pr-3 flex gap-2 text-gray-600 bg-opacity-0"
    )}
    {...rest}
  >
    <div
      className={classNames(
        color,
        "w-7 h-7 rounded-lg flex items-center justify-center text-white shrink-0"
      )}
    >
      <Icon className="text-lg" />
    </div>
    <div className="flex flex-col leading-none">
      <div className="font-bold">{name}</div>
      {!!description && (
        <div className="text-gray-400 text-xs whitespace-nowrap">
          {description}
        </div>
      )}
    </div>
    {children}
  </div>
);

export const COURSE_ITEMS = (t) => ({
  [PageItemType.Paragraph]: {
    name: t("v4.item.text.text"),
    icon: TbLetterCase,
    description: t("v4.item.text.description"),
  },
  [PageItemType.Image]: {
    name: t("v4.item.media.text"),
    icon: TbPhoto,
    description: t("v4.item.media.description"),
  },
  [PageItemType.ImageGenerate]: {
    name: t("v4.item.media.text"),
    icon: TbPhoto,
    description: t("v4.item.media.generate"),
  },
  [PageItemType.ListGenerate]: {
    name: t("v4.item.list.text"),
    icon: TbListDetails,
    description: t("v4.item.list.generate"),
  },
  [PageItemType.Embed]: {
    name: t("v4.item.youTube.text"),
    icon: TbBrandYoutube,
    description: t("v4.item.youTube.description"),
  },
  [PageItemType.Columns]: {
    name: t("v4.item.columns.text"),
    icon: TbColumns2,
    description: t("v4.item.columns.description"),
  },
  [PageItemType.MultipleChoice]: {
    name: t("v4.item.selectAnswers.text"),
    icon: TbListCheck,
    description: t("v4.item.selectAnswers.description"),
  },
  [PageItemType.ShortAnswer]: {
    name: t("v4.item.shortAnswer.text"),
    icon: TbMessageCircle2,
    description: t("v4.item.shortAnswer.description"),
  },
  [PageItemType.ImageLabeling]: {
    name: t("v4.item.imageLabeling.text"),
    icon: TbSlideshow,
    description: t("v4.item.imageLabeling.description"),
  },
  [PageItemType.Sorting]: {
    name: t("v4.item.sorting.text"),
    icon: TbArrowsShuffle,
    description: t("v4.item.sorting.description"),
  },
  [PageItemType.Pairing]: {
    name: t("v4.item.pairing.text"),
    icon: TbSubtask,
    description: t("v4.item.pairing.description"),
  },
  [PageItemType.FillTheGap]: {
    name: t("v4.item.fillTheGap.text"),
    icon: TbSpace,
    description: t("v4.item.fillTheGap.description"),
  },
  [PageItemType.Categorisation]: {
    name: t("v4.item.categorisation.text"),
    icon: TbIcons,
    description: t("v4.item.categorisation.description"),
  },
  [PageItemType.Crossword]: {
    name: t("v4.item.crossword.text"),
    icon: TbLayoutGrid,
    description: t("v4.item.crossword.description"),
  },
  [PageItemType.FlashcardsQuiz]: {
    name: t("v4.item.flashcards.text"),
    icon: TbCards,
    description: t("v4.item.flashcards.quizInstructions"),
  },

  [PageItemType.Flashcards]: {
    name: t("v4.item.flashcards.text"),
    icon: TbCards,
  },

  [PageItemType.MultipleChoicePoll]: {
    name: t("v4.item.selectAnswersPoll.text"),
    icon: TbListCheck,
    description: t("v4.item.selectAnswersPoll.description"),
  },
  [PageItemType.CommentPoll]: {
    name: t("v4.item.comment.text"),
    icon: TbMessageCircle2,
    description: t("v4.item.shortAnswer.description"),
  },
});

type FloatingListItem = {
  key: string;
  name: string;
  description?: string;
  disabled?: boolean;
  hidden?: boolean;
  icon: IconType;
  color: string;
} & (
  | { onClick: () => void; items?: never }
  | { onClick?: never; items: FloatingListItem[] }
);

export const FloatingList = ({
  items,
  open,
}: {
  items: FloatingListItem[];
  open: boolean;
}) => {
  const [panel, setPanel] = useState<string | null>(null);

  useEffect(() => {
    if (open) setPanel(null);
  }, [open]);

  return (
    <div className="flex flex-col gap-1 max-h-64 overflow-y-auto pr-1">
      {items.map(
        ({
          name,
          key,
          disabled,
          icon,
          description,
          onClick,
          color,
          items,
          hidden,
        }) => {
          if (hidden) return null;

          if (onClick)
            return (
              <LargeListItem
                {...{ name, key, disabled, icon, description, color }}
                onClick={() => !disabled && onClick()}
                onPointerEnter={() => setPanel(null)}
              />
            );

          return (
            <FloatingMenu
              size="xs"
              key={key}
              trigger={() => (
                <LargeListItem
                  {...{ name, key, disabled, icon, description, color }}
                  onPointerEnter={() => setPanel(disabled ? null : key)}
                >
                  <div className="w-2" />
                  <TbChevronRight className="text-gray-400 ml-auto text-xl -mr-1.5" />
                </LargeListItem>
              )}
              open={panel === key}
              placement="right-start"
            >
              {({ isOpen }) => <FloatingList items={items} open={isOpen} />}
            </FloatingMenu>
          );
        }
      )}
    </div>
  );
};
