import React, { useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useInfiniteQuery, useQuery } from "react-query";
import { getCourseAssignments } from "api/assignments/assignmentsAPI";
import { NewButton } from "app/components/Buttons/NewButton";
import { courseRoutes, groupRoutes, sessionRoutes } from "enums/routes";
import {
  TbBulb,
  TbCalendar,
  TbClipboardCheck,
  TbDotsVertical,
  TbExternalLink,
  TbEye,
  TbPencil,
  TbPresentationAnalytics,
  TbUsers,
} from "react-icons/tb";
import { InView } from "react-intersection-observer";
import classNames from "classnames";
import { getCourse } from "api/course/courseAPI";
import { useDateFormat } from "app/hooks/useDateFormat";
import { CourseIcon } from "app/pages/courses/Edit/CoursePanel";
import { Page } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { useTranslation } from "react-i18next";
import { SourceContextMenu } from "app/pages/courses/SourceContextMenu";
import { FileType } from "helpers/mimeType";
import { AssignCourseModal } from "app/pages/courses/components/AssignCourseModal";
import { InputCheckbox } from "app/components/Buttons/InputCheckbox";
import { TrainingPreview } from "app/pages/courses/Edit/CourseHeader";
import {
  AssignmentBadges,
  AssignmentButtons,
  LiveSessionBadges,
} from "app/pages/courses/CourseActivity";
import { useRole } from "app/hooks/useRole";

export const CourseDetails = () => {
  const { t } = useTranslation();
  const role = useRole();
  const { courseId } = useParams<{ courseId: string }>();
  const { isSuccess, data } = useQuery({
    queryFn: () => getCourse(courseId),
    queryKey: ["course", courseId],
    refetchOnWindowFocus: false,
    enabled: !!courseId,
  });
  const format = useDateFormat();
  const [assign, setAssign] = useState(false);
  const [preview, setPreview] = useState(false);

  return (
    <div className="py-8 px-3 sm:px-8">
      <div className="flex items-center gap-1 mb-3">
        <div className="flex-grow" />
        {role.teacher && (
          <NewButton
            component={Link}
            to={courseRoutes.edit(courseId)}
            className="hidden md:flex"
          >
            <TbPencil /> {t("v4.generic.edit")}
          </NewButton>
        )}
        <NewButton onClick={() => setPreview(true)}>
          <TbEye /> {t("v4.generic.preview")}
        </NewButton>

        {isSuccess && (
          <SourceContextMenu
            item={{ id: courseId, name: data.name, type: FileType.Course }}
            options={[["send", "download", "json"], ["delete"]]}
            trigger={(toggle) => (
              <NewButton iconOnly size="lg" onClick={toggle}>
                <TbDotsVertical />
              </NewButton>
            )}
          />
        )}
      </div>
      <div className="flex flex-col md:items-start md:flex-row gap-6 text-gray-600">
        {!isSuccess ? (
          <div className="gap-4 bg-white rounded-2xl p-6 grow">
            <div className="flex flex-col gap-2 animate-pulse">
              <div className="bg-gray-100 rounded-xl h-10" />
              <div className="bg-gray-100 rounded-xl h-6" />
            </div>
          </div>
        ) : (
          <div className="flex items-start gap-4 bg-white rounded-2xl p-6 grow-[3]">
            <div className="flex flex-col gap-1 grow overflow-hidden">
              <div className="font-bold text-2xl overflow-ellipsis overflow-hidden">
                {data.name}
              </div>
              <div className="text-gray-500">
                <CoursePageListSimple pages={data.pages} />
              </div>
              <div className="text-gray-500 text-sm flex items-center gap-1.5 mt-2">
                <TbCalendar className="text-lg" />
                {t("v4.generic.lastModified")}
                <b>
                  {format(
                    data?.date_last_edited || data?.date_created,
                    "distance"
                  )}
                </b>
              </div>
            </div>

            {/*<NewButton iconOnly>*/}
            {/*  <TbDotsVertical />*/}
            {/*</NewButton>*/}
          </div>
        )}
      </div>

      {role.teacher && (
        <>
          <div className="flex flex-col xs:flex-row xs:items-center mt-8 mb-2">
            <div className="text-2xl font-bold flex items-center gap-2 text-gray-500 grow">
              <TbBulb className="text-3xl" />
              {t("v4.activity.text")}
            </div>
            <div className="flex gap-1 ml-auto -order-1 xs:order-none">
              <NewButton
                component={Link}
                to={sessionRoutes.startSession(courseId)}
                color="text-primary bg-primary"
                className="hidden md:flex"
              >
                <TbPresentationAnalytics />
                {t("lessons.live")}
              </NewButton>
              {isSuccess && (
                <>
                  <NewButton
                    color="bg-primary text-primary"
                    className="ml-1"
                    onClick={() => setAssign(true)}
                  >
                    <TbClipboardCheck /> {t("v4.assignment.assign")}
                  </NewButton>
                </>
              )}
            </div>
          </div>
          {assign && (
            <AssignCourseModal
              open={assign}
              close={() => setAssign(false)}
              courses={[{ id: courseId, name: data.name }]}
              hideSelected
            />
          )}
          <ActivityList />
        </>
      )}
      {preview && (
        <TrainingPreview courseId={courseId} close={() => setPreview(false)} />
      )}
    </div>
  );
};

export const CoursePageListSimple = ({ pages }: { pages: Page[] }) => {
  const items = useMemo(() => categorizePagesSimple(pages ?? []), [pages]);
  const { t } = useTranslation();

  const labels = {
    slide: {
      single: t("v4.slide.singular"),
      plural: t("v4.slide.plural"),
    },
    quiz: {
      single: t("v4.quiz.singular"),
      plural: t("v4.quiz.plural"),
    },
    content: {
      single: t("v4.page.singular"),
      plural: t("v4.page.plural"),
    },
  };

  if (!pages.length) return null;
  return (
    <div className="flex flex-col divide-y-2 xs:divide-y-0 xs:flex-row xs:divide-x-2 items-center divide-opacity-50">
      {(["slide", "content", "quiz"] as const).map((category) => {
        const item = labels?.[category];
        const count = items?.[category];
        if (!item || !count) return null;

        return (
          <div
            key={category}
            className="flex gap-2 items-center font-bold py-2 xs:py-1 px-3 xs:first:pl-0 xs:last:pr-0 whitespace-nowrap"
          >
            <CourseIcon category={category}>
              {(Icon, color) => (
                <div
                  className={classNames(
                    "shrink-0 w-6 h-6 rounded-lg flex items-center text-base justify-center",
                    color
                  )}
                >
                  <Icon />
                </div>
              )}
            </CourseIcon>
            {count} {count === 1 ? item.single : item.plural}
          </div>
        );
      })}
    </div>
  );
};

export const categorizePagesSimple = (pages: Page[]) => {
  const items = {
    slide: 0,
    quiz: 0,
    content: 0,
    poll: 0,
  };

  for (const { category } of pages) {
    if (category in items) items[category]++;
  }

  items.content += items.poll;
  return items;
};

const Skeleton = () => (
  <>
    {[...new Array(15)].map((_, i) => (
      <div
        key={i}
        className="rounded-lg h-32 bg-gray-200 animate-pulse"
        style={{ animationDelay: i * 0.2 + "s" }}
      />
    ))}
  </>
);

const ActivityList = () => {
  const { t } = useTranslation();
  const { courseId } = useParams<{ courseId: string }>();
  const [only, setOnly] = useState<"assignment" | "live_session" | null>(null);
  const { data, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ["activities", courseId, only],
      ({ pageParam: page = 0 }) =>
        getCourseAssignments(courseId, { page, only }),
      {
        refetchOnWindowFocus: false,
        getNextPageParam: ({ activities_total }, pages) =>
          activities_total > pages.length * 20 ? pages.length : undefined,
      }
    );

  const list = useMemo(
    () =>
      (data?.pages || []).reduce(
        (list, group) => [...list, ...group.activities],
        []
      ),
    [data?.pages]
  );

  const toggleOnly = (value: typeof only) => () => {
    if (value === "assignment") {
      if (only === "live_session") return setOnly(null);
      return setOnly("live_session");
    }

    if (only === "assignment") return setOnly(null);
    return setOnly("assignment");
  };

  return (
    <>
      <div className="flex flex-wrap gap-2 mb-1 select-none">
        <label className="flex items-center gap-1 font-bold cursor-pointer text-gray-500 bg-lime-50 border border-gray-200 py-0.5 pl-1 pr-2 rounded-lg">
          <InputCheckbox
            value={!only || only === "assignment"}
            onChange={toggleOnly("assignment")}
            color="bg-lime-600"
          />
          <TbClipboardCheck className="text-lg ml-1" />
          {t("v4.assignment.plural")}
        </label>
        <label className="flex items-center gap-1 font-bold cursor-pointer text-gray-500 bg-purple-50 border border-gray-200 py-0.5 pl-1 pr-2 rounded-lg">
          <InputCheckbox
            value={!only || only === "live_session"}
            onChange={toggleOnly("live_session")}
            color="bg-purple-700"
          />
          <TbPresentationAnalytics className="text-lg ml-1" />
          {t("v4.presentation.plural")}
        </label>
      </div>
      {!isSuccess ? (
        <div className="flex flex-col space-y-3">
          <Skeleton />
        </div>
      ) : (
        <>
          <div className="flex flex-col space-y-3">
            {!list.length ? (
              <div className="text-xl text-center text-gray-500 py-5">
                {t("v4.activity.noItems")}
              </div>
            ) : (
              list.map((item) => <Activity key={item.id} item={item} />)
            )}
          </div>
          {hasNextPage && (
            <InView
              as="div"
              className="flex"
              onChange={(inView) =>
                inView && !isFetchingNextPage && fetchNextPage()
              }
              key={data.pages.length}
            >
              {({ inView, ref }) => (
                <div
                  ref={ref}
                  className={classNames(
                    "flex flex-col gap-3 transition",
                    !inView && "opacity-0"
                  )}
                >
                  <Skeleton />
                </div>
              )}
            </InView>
          )}
        </>
      )}
    </>
  );
};

export const Activity = ({ item }: { item: any }) => {
  const { courseId } = useParams<{ courseId: string }>();
  const { t } = useTranslation();
  const format = useDateFormat();

  return (
    <div className="px-6 py-5 rounded-lg shadow-2xl transition relative group user-select-none bg-white text-gray-600 w-full">
      <Link
        to={courseRoutes.activity(courseId, item.id)}
        className="absolute rounded-lg left-0 top-0 w-full h-full bg-primary bg-opacity-0 md:hover:bg-opacity-5 transition cursor-pointer"
      />

      <div className="flex pointer-events-none flex-col sm:flex-row sm:items-start">
        <div className="flex flex-col relative min-w-0 grow">
          <div className="flex gap-1 flex-wrap items-start mb-1">
            {item.type === "assignment" && (
              <div className="bg-lime-600 font-bold text-sm gap-1 flex items-center text-white py-0.5 px-1.5 rounded-lg">
                <TbClipboardCheck className="text-base" />
                {t("v4.assignment.text")}
              </div>
            )}
            {item.type === "live_session" && (
              <div className="bg-purple-700 font-bold text-sm gap-1 flex items-center text-white py-0.5 px-1.5 rounded-lg">
                <TbPresentationAnalytics className="text-base" />
                {t("v4.presentation.text")}
              </div>
            )}
            <LiveSessionBadges item={item} />
            <AssignmentBadges item={item} />
          </div>

          {item.group_id && (
            <>
              {item.group_status === "archived" ? (
                <div className="inline-flex items-center font-bold text-gray-400 mr-auto">
                  <TbUsers className="mr-1 text-xl" /> {item.group_name} (
                  {t("classes.archived")})
                </div>
              ) : item.group_status === "removed" ? (
                <div className="inline-flex items-center font-bold text-gray-400 mr-auto">
                  <TbUsers className="mr-1.5 text-xl" /> {item.group_name} (
                  {t("classes.deleted")})
                </div>
              ) : (
                <Link
                  to={groupRoutes.details(item.group_id)}
                  className="inline-flex items-center font-bold pointer-events-auto hover:text-primary mr-auto transition"
                >
                  <TbUsers className="mr-1.5 text-xl" /> {item.group_name}
                </Link>
              )}
            </>
          )}

          <div className="inline-flex items-center mr-auto">
            <b>{item.participants}</b>
            &nbsp;
            {item.participants === 1
              ? t("v4.activity.participant")
              : t("v4.activity.participants")}
          </div>

          <div className="inline-flex items-center text-sm text-gray-400 mr-auto">
            {t("assignments.assignedBy")}
            &nbsp;
            <b>{item.assigner_name}</b>
          </div>

          <span className="flex items-center text-gray-500 mt-4">
            <TbCalendar className="mr-1 text-xl" />
            {format(item.date_assigned, "MMM d, yyyy")}
          </span>
        </div>
        <div className="flex gap-1 ml-auto mt-4 sm:mt-0">
          {item.type === "assignment" && (
            <AssignmentButtons
              assignmentId={item.id}
              courseId={courseId}
              closed={item.status === "closed"}
            />
          )}
          {item.type === "live_session" && item.status === "active" && (
            <NewButton
              className="pointer-events-auto z-[1] hidden md:flex"
              size="sm"
              color="bg-primary text-primary"
              component={Link}
              to={sessionRoutes.session(item.pin)}
            >
              <TbExternalLink /> {t("v4.session.open")}
            </NewButton>
          )}
        </div>
      </div>
    </div>
  );
};
