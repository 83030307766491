import { useEffect, useState } from "react";

export const useScrollDirection = ({
  threshold = 50,
  scrollRef = window as any,
} = {}) => {
  const [isScrollDown, setScrollDown] = useState(false);

  useEffect(() => {
    let lastScrollY = scrollRef?.scrollTop || scrollRef?.scrollY;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = scrollRef?.scrollTop || scrollRef?.scrollY;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDown(scrollY > lastScrollY);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    scrollRef?.addEventListener("scroll", onScroll);

    return () => scrollRef?.removeEventListener("scroll", onScroll);
  }, [isScrollDown, threshold]);

  return isScrollDown;
};
