import React, {
  Fragment,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { postCheckout, postCustomerPortal, postTrial } from "api/paymentsAPI";
import {
  TbCheck,
  TbCircleCheck,
  TbMail,
  TbRefresh,
  TbSettings,
  TbX,
} from "react-icons/tb";
import { CgSpinner } from "react-icons/cg";
import { NewButton } from "app/components/Buttons/NewButton";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserDetails,
  userDetails as setUserDetails,
} from "app/store/user";
import { useDateFormat } from "app/hooks/useDateFormat";
import classNames from "classnames";
import { useSubscription } from "api/userAPI";
import { useHistory, useLocation } from "react-router";
import { courseRoutes, miscRoutes, publicRoutes } from "enums/routes";
import { InputToggle } from "app/components/Buttons/InputToggle";
import { toast } from "react-hot-toast";

export const BASIC_ID = "O9Bn88JFQP6R5kZtZsWdLw";
export const PRO_ID = "FuRnYBLYQEW5sYnszBstsg";

const SelectedIndicator = () => {
  const { t } = useTranslation();
  return (
    <NewButton
      variant="transparent"
      color="bg-transparent text-inherit"
      className="font-bold pointer-events-none"
      size="lg"
      center
    >
      <TbCircleCheck />
      {t("v4.subscriptions.selected")}
    </NewButton>
  );
};

const SubscriptionButtons = ({ pro = false, annual = false }) => {
  const { t } = useTranslation();
  const { data: sub } = useSubscription();
  const id = pro ? PRO_ID : BASIC_ID;

  const checkoutMutation = useMutation(
    (annual: boolean) => postCheckout(id, annual),
    {
      onSuccess: (data) => {
        // @ts-ignore
        window.gtag("event", "begin_checkout", {
          event_callback: () => {
            window.open(data.redirect_url, "_self");
          },
        });
      },
    }
  );

  const isSubActive = useMemo(() => {
    if (!sub) return false;
    if (sub.id !== id) return false;
    return sub.status === "active" || sub.status === "trialing";
  }, [!!sub, sub?.id, sub?.status]);

  if (isSubActive)
    return (
      <>
        {sub?.trial_end || sub?.cancel_at ? (
          <NewButton
            className="font-bold"
            color={pro ? "bg-white text-primary" : "bg-primary text-white"}
            variant="primary"
            center
            size="lg"
            disabled={checkoutMutation.isLoading}
            onClick={() => checkoutMutation.mutate(annual)}
          >
            {checkoutMutation.isLoading ? (
              <CgSpinner className="animate-spin !text-2xl" />
            ) : (
              <>
                <TbCircleCheck /> {t("v4.subscriptions.extend")}
              </>
            )}
          </NewButton>
        ) : sub?.is_annual !== annual ? (
          <NewButton
            className="font-bold"
            color={pro ? "bg-white text-primary" : "bg-primary text-white"}
            variant="primary"
            center
            size="lg"
            disabled={checkoutMutation.isLoading}
            onClick={() => checkoutMutation.mutate(annual)}
          >
            {checkoutMutation.isLoading ? (
              <CgSpinner className="animate-spin !text-2xl" />
            ) : (
              <>
                <TbCircleCheck />
                {annual
                  ? t("v4.subscriptions.switchAnnually")
                  : t("v4.subscriptions.switchMonthly")}
              </>
            )}
          </NewButton>
        ) : (
          <SelectedIndicator />
        )}
      </>
    );

  return (
    <NewButton
      className="font-bold"
      variant={pro ? "primary" : undefined}
      color={pro ? "bg-white text-primary" : "bg-primary text-primary"}
      center
      size="lg"
      disabled={checkoutMutation.isLoading}
      onClick={() => checkoutMutation.mutate(annual)}
    >
      {checkoutMutation.isLoading ? (
        <CgSpinner className="animate-spin !text-2xl" />
      ) : (
        t("v4.subscriptions.choose")
      )}
    </NewButton>
  );
};

export const useTrialMutation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return useMutation(() => postTrial(), {
    onSuccess: (data) => {
      dispatch(setUserDetails(""));
      // @ts-ignore
      window.gtag("event", "begin_checkout", {
        event_callback: () => {
          window.open(data.redirect_url, "_self");
        },
      });
    },
    onError: () => {
      toast.error(t("common.exercises.error.generic"));
    },
  });
};

export const Subscriptions = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const userDetails = useSelector(selectUserDetails);
  const history = useHistory();
  const [annual, setAnnual] = useState(true);
  const subscriptionQuery = useSubscription({
    onSuccess: (data) => {
      if (
        (data?.status === "active" || data?.status === "trialing") &&
        data?.id == null
      )
        history.replace(courseRoutes.list);
    },
  });
  const format = useDateFormat();

  const successParam = useMemo(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("success") !== "trial" && params.get("success") !== "plan")
      return false;
    if (!params.get("session_id")) return false;
    return true;
  }, [location.search]);

  useEffect(() => {
    if (!successParam) return;
    if (!subscriptionQuery.isSuccess) return;

    const params = new URLSearchParams(location.search);
    const success = params.get("success")!;
    const sessionId = params.get("session_id")!;

    if (success === "trial") {
      toast.success(t("v4.subscriptions.trialSuccess"));
      if (sessionId)
        // @ts-ignore
        window?.gtag?.("event", "purchase", {
          transaction_id: sessionId,
          items: [{ item_name: "trial" }],
        });

      history.replace(publicRoutes.home);
    }
    if (success === "plan") {
      toast.success(t("v4.subscriptions.orderSuccess"));
      if (sessionId)
        // @ts-ignore
        window?.gtag?.("event", "purchase", {
          transaction_id: sessionId,
          items: [
            {
              item_name:
                subscriptionQuery.data?.name +
                "-" +
                subscriptionQuery.data?.is_annual
                  ? "annual"
                  : "monthly",
            },
          ],
        });

      history.replace(miscRoutes.subscriptions);
    }
  }, [i18n.language, successParam, subscriptionQuery.isSuccess]);

  const retryMutation = useMutation(
    ({ id, annual }: { id: string; annual: boolean }) =>
      postCheckout(id, annual),
    {
      onSuccess: (data) => {
        window.open(data.redirect_url, "_self");
      },
    }
  );

  const portalMutation = useMutation(() => postCustomerPortal(), {
    onSuccess: (data) => {
      window.open(data.redirect_url, "_self");
    },
  });
  const trialMutation = useTrialMutation();

  const sub = subscriptionQuery.data;

  const isSub = (id: string) => {
    if (!sub) return false;
    if (sub.id !== id) return false;
    return sub.status === "active" || sub.status === "trialing";
  };

  return (
    <>
      {subscriptionQuery.isLoading ? (
        <div className="flex flex-col text-center text-gray-600 py-6 gap-8">
          <div className="text-3xl font-bold">
            {t("v4.subscriptions.subscriptions")}
          </div>
          <CgSpinner className="mx-auto animate-spin text-4xl text-gray-500 mt-12" />
        </div>
      ) : (
        <div className="flex flex-col text-center text-gray-600 py-6 gap-8">
          <div className="text-3xl font-bold">
            {t("v4.subscriptions.subscriptions")}
          </div>

          <label className="flex items-center gap-4 mx-auto text-center cursor-pointer">
            {t("v4.subscriptions.billMonthly")}
            <InputToggle value={annual} onChange={() => setAnnual(!annual)} />
            {t("v4.subscriptions.billAnnually")}
          </label>

          <div className="grid sm:grid-cols-2 lg:grid-cols-4 place-content-center justify-center justify-items-center gap-2 mx-auto px-2 lg:px-0">
            <SubscriptionTile
              name={t("v4.subscriptions.plans.free")}
              enabled={[t("v4.subscriptions.features.consumption")]}
              disabled={[
                t("v4.subscriptions.features.credits"),
                t("v4.subscriptions.features.liveSession"),
                t("v4.subscriptions.features.creation"),
                t("v4.subscriptions.features.assign"),
              ]}
              active={!userDetails?.is_org_pro}
            >
              <>
                <div className="flex flex-col gap-2 mt-auto">
                  <div className="flex gap-0.5 mt-4">
                    <span className="text-4xl font-bold leading-none">
                      {t("v4.subscriptions.plans.free")}
                    </span>
                  </div>
                  <span className="opacity-80 ml-0.5 leading-none text-sm">
                    {t("v4.subscriptions.freeSubtitle")}
                  </span>
                </div>

                {!userDetails?.is_org_pro ? (
                  <SelectedIndicator />
                ) : (
                  <NewButton
                    className="font-bold opacity-0 pointer-events-none"
                    color="bg-primary text-primary"
                    center
                    size="lg"
                  >
                    {t("v4.subscriptions.choose")}
                  </NewButton>
                )}
              </>
            </SubscriptionTile>

            <SubscriptionTile
              name={t("v4.subscriptions.plans.basic")}
              price={15}
              annual={annual}
              enabled={[
                t("v4.subscriptions.features.consumption"),
                <Trans
                  i18nKey="v4.subscriptions.features.creditsLimit"
                  values={{ credits: 2500, trainings: 15 }}
                  components={{ b: <b /> }}
                />,
                <Trans
                  i18nKey="v4.subscriptions.features.liveSessionLimit"
                  values={{ number: 100 }}
                  components={{ b: <b /> }}
                />,
                t("v4.subscriptions.features.creation"),
                t("v4.subscriptions.features.assign"),
              ]}
              active={isSub(BASIC_ID)}
            >
              <SubscriptionButtons annual={annual} />
            </SubscriptionTile>

            <SubscriptionTile
              color="bg-primary text-white"
              name={t("v4.subscriptions.plans.pro")}
              price={30}
              annual={annual}
              enabled={[
                t("v4.subscriptions.features.consumption"),
                <Trans
                  i18nKey="v4.subscriptions.features.creditsLimit"
                  values={{ credits: 7500, trainings: 45 }}
                  components={{ b: <b /> }}
                />,
                <Trans
                  i18nKey="v4.subscriptions.features.liveSessionLimit"
                  values={{ number: 500 }}
                  components={{ b: <b /> }}
                />,
                t("v4.subscriptions.features.creation"),
                t("v4.subscriptions.features.assign"),
              ]}
              active={isSub(PRO_ID)}
            >
              {!sub ? (
                <NewButton
                  className="font-bold"
                  variant="primary"
                  color="bg-white text-primary"
                  center
                  size="lg"
                  disabled={trialMutation.isLoading}
                  onClick={trialMutation.mutate}
                >
                  {trialMutation.isLoading ? (
                    <CgSpinner className="animate-spin !text-2xl" />
                  ) : (
                    t("v4.subscriptions.trialPrompt")
                  )}
                </NewButton>
              ) : (
                <SubscriptionButtons pro annual={annual} />
              )}
            </SubscriptionTile>

            <div className="flex flex-col gap-6 text-left max-w-sm p-4 rounded-2xl bg-white w-full shadow-2xl shadow-black/20">
              <div className="text-2xl font-bold">
                {t("v4.subscriptions.plans.enterprise")}
              </div>
              <div className="flex flex-col grow justify-center items-center text-center">
                {t("v4.subscriptions.features.custom")}
              </div>
              <NewButton
                component="a"
                href="mailto:support@smartest.io?subject=Smartest Enterprise Licence"
                className="font-bold"
                color="bg-primary text-primary"
                center
                size="lg"
              >
                <TbMail />
                {t("v4.subscriptions.contactUs")}
              </NewButton>
            </div>
          </div>

          {userDetails?.is_stripe_customer && (
            <div className="bg-white rounded-xl p-6 flex flex-col justify-center items-center text-center max-w-xs mx-auto w-full">
              {!userDetails.is_stripe_payment_method && (
                <>
                  <span className="font-bold text-sm text-red-700">
                    {t("v4.subscriptions.noPaymentMethodAdded")}
                  </span>
                  <div className="w-10 h-0.5 bg-gray-200 rounded-full my-4" />
                </>
              )}

              {sub && (
                <>
                  <div className="font-bold text-lg">{sub.name}</div>
                  {sub.status === "active" ? (
                    <span className="text-xs">
                      {sub.is_annual
                        ? t("v4.subscriptions.annual")
                        : t("v4.subscriptions.monthly")}
                    </span>
                  ) : sub.status === "canceled" ? (
                    <span className="font-bold text-gray-400">
                      {t("v4.subscriptions.cancelled")}
                    </span>
                  ) : sub.status === "past_due" ||
                    sub.status === "incomplete_expired" ? (
                    <span className="font-bold text-red-700">
                      {t("v4.subscriptions.expired")}
                    </span>
                  ) : sub.status === "unpaid" ? (
                    <span className="font-bold text-red-700">
                      {t("v4.subscriptions.paymentFailed")}
                    </span>
                  ) : sub.status === "incomplete" ? (
                    <>
                      <span className="font-bold text-red-700">
                        {t("v4.subscriptions.paymentIncomplete")}
                      </span>
                      <NewButton
                        variant="primary"
                        onClick={() =>
                          !retryMutation.isLoading &&
                          retryMutation.mutate({
                            id: sub.id,
                            annual: sub?.is_annual,
                          })
                        }
                        className="mt-1"
                        disabled={retryMutation.isLoading}
                      >
                        {portalMutation.isLoading ? (
                          <CgSpinner className="animate-spin" />
                        ) : (
                          <TbRefresh />
                        )}
                        {t("v4.generic.retry")}
                      </NewButton>
                    </>
                  ) : sub.status === "trialing" ? (
                    <>
                      <span className="font-bold text-primary">
                        {t("v4.subscriptions.trial")}
                      </span>
                    </>
                  ) : null}

                  {(sub.cancel_at || sub.trial_end) && (
                    <span className="text-xs">
                      {t("v4.subscriptions.expires")}
                      {format(sub.cancel_at || sub.trial_end || "", "distance")}
                    </span>
                  )}
                </>
              )}

              <NewButton
                color="text-primary bg-primary"
                size="lg"
                center
                onClick={portalMutation.mutate}
                disabled={portalMutation.isLoading}
                className="mt-6"
              >
                {portalMutation.isLoading ? (
                  <CgSpinner className="animate-spin" />
                ) : (
                  <TbSettings />
                )}
                {t("v4.subscriptions.manageSubscriptions")}
              </NewButton>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const SubscriptionTile = ({
  name,
  enabled,
  disabled,
  price,
  children,
  color,
  annual,
  active,
}: PropsWithChildren<{
  name: string;
  enabled?: ReactNode[];
  disabled?: ReactNode[];
  price?: number;
  color?: string;
  annual?: boolean;
  active?: boolean;
}>) => {
  const { t } = useTranslation();
  const annualPrice = price && (price / 12) * 10;

  return (
    <div
      className={classNames(
        "flex flex-col gap-6 text-left max-w-sm p-4 rounded-2xl w-full shadow-2xl shadow-black/20",
        color || "bg-white"
      )}
    >
      <div className="text-2xl font-bold flex items-center">
        {name}{" "}
        {active && <TbCircleCheck className="text-3xl ml-auto opacity-75" />}
      </div>
      <div className="grid grid-cols-[1.5rem_1fr] gap-3 text-sm">
        {enabled?.map((text, i) => (
          <Fragment key={i}>
            <TbCheck className="text-base" strokeWidth={3} />
            <div>{text}</div>
          </Fragment>
        ))}
        {disabled?.map((text, i) => (
          <Fragment key={i}>
            <TbX className="text-base opacity-50" strokeWidth={3} />
            <div className="opacity-50">{text}</div>
          </Fragment>
        ))}
      </div>
      {price != null && (
        <div className="flex flex-col gap-2 mt-auto">
          <div className="flex gap-0.5 mt-6">
            <span className="mt-auto mb-1 font-bold text-base leading-none">
              {"CHF"}
            </span>
            <span className="text-4xl font-bold leading-none">
              {annual == null || annual ? Math.trunc(annualPrice || 0) : price}
            </span>
            {(annual == null || annual) && (
              <span className="mb-auto mt-1 font-bold text-xl leading-none">
                {annualPrice &&
                  ((annualPrice - Math.trunc(annualPrice || 0)) * 100 || "00")}
              </span>
            )}
          </div>
          {annual == null || annual ? (
            <span className="opacity-80 ml-0.5 leading-none text-sm">
              {t("v4.subscriptions.perMonthAnnual")}
            </span>
          ) : (
            <span className="opacity-80 ml-0.5 leading-none text-sm">
              {t("v4.subscriptions.perMonth")}
            </span>
          )}
          {annual == null && (
            <span className="opacity-80 ml-0.5 leading-none text-xs">
              <Trans
                i18nKey="v4.subscriptions.orPerMonth"
                components={{ b: <b /> }}
                values={{ price }}
              />
            </span>
          )}
        </div>
      )}
      {children}
    </div>
  );
};
