import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { userDetails } from ".";
import { IUserDetails } from "../../../models/user.interface";
import { updateUserDetails } from "./userActions";

interface UserState {
  isLoading: boolean;
  details: IUserDetails;

  theme: Record<string, unknown> | null;
}

const initialState: UserState = {
  isLoading: false,
  theme: null,
  details: {
    id: null,
    picture: null,
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    organization: null,
    organizations: null,
    is_superuser: false,
    is_org_pro: false,
    is_org_external: false,
    is_stripe_customer: false,
    is_stripe_payment_method: false,
    language: "",
    logo: null,
    impersonator: null,
    profile: {},
  },
};

export const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUser: (state) => {
      state.isLoading = false;
      state.details = initialState.details;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userDetails.fulfilled, (state, { payload }) => {
      state.details = payload;
      state.details.logo = payload.organization?.logo_download_url;
      state.theme = payload.organization?.theme;
      if (payload.organization?.theme) {
        Object.entries(payload.organization.theme).forEach((item: any) =>
          document.documentElement.style.setProperty(
            `--${item[0].replaceAll("_", "-")}`,
            item[1]
          )
        );
      }
      state.isLoading = false;
      // if (payload?.organization?.is_b2c === true) {
      //   state.applicationType = ApplicationType.IS_B2C;
      // } else if (payload?.organization?.is_b2c === false) {
      //   state.applicationType = ApplicationType.IS_B2B;
      // } else if (payload?.organization === null) {
      //   state.applicationType = ApplicationType.IS_B2C;
      // } else {
      //   state.applicationType = null;
      // }
    });
    builder.addCase(userDetails.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateUserDetails.pending, (state) => {
      // state.isLoading = true;
    });
    builder.addCase(updateUserDetails.fulfilled, (state, { payload }) => {
      state.details = payload;
      state.details.logo = payload.organization?.logo_download_url;
      state.theme = payload.organization?.theme;
      payload.organization?.theme &&
        Object.entries(payload.organization.theme).forEach((item: any) =>
          document.documentElement.style.setProperty(
            `--${item[0].replaceAll("_", "-")}`,
            item[1]
          )
        );
      // state.isLoading = false;
      // if (payload?.organization?.is_b2c === true) {
      //   state.applicationType = ApplicationType.IS_B2C;
      // } else if (payload?.organization?.is_b2c === false) {
      //   state.applicationType = ApplicationType.IS_B2B;
      // } else if (payload?.organization === null) {
      //   state.applicationType = ApplicationType.IS_B2C;
      // } else {
      //   state.applicationType = null;
      // }
    });
    builder.addCase(updateUserDetails.rejected, (state) => {
      // state.isLoading = false;
    });
  },
});

export const selectUserLoading = (state: RootState) =>
  state.user.isLoading && !state.user.details.id;
export const selectUserDetails = (state: RootState) => state.user.details;

export const clearUser = slice.actions.clearUser;

export default slice.reducer;
