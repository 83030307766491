import React, { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FloatingMenu, HeaderIconButton } from "./Header";
import { PINIcon } from "./Exercises/Edit/ExerciseIcons";
import { NewButton } from "app/components/Buttons/NewButton";
import { useHistory } from "react-router-dom";
import { pinTypes } from "enums/pinTypes";
import {
  courseRoutes,
  groupRoutes,
  guestCourseRoutes,
  libraryRoutes,
  sessionRoutes,
} from "enums/routes";
import { toast } from "react-hot-toast";
import { CgSpinner } from "react-icons/cg";
import { useRole } from "app/hooks/useRole";
import { matchPath } from "react-router";
import { useGroupJoin } from "app/pages/groups/GroupsList";

const PINInput = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const role = useRole();
  const [pin, setPin] = useState("");

  const isValid = useMemo(() => {
    if (pin.length !== 5) return false;
    return (
      pin.charAt(0) === pinTypes.Courses ||
      pin.charAt(0) === pinTypes.Session ||
      pin.charAt(0) === pinTypes.Source ||
      pin.charAt(0) === pinTypes.Groups ||
      pin.charAt(0) === pinTypes.Guests
    );
  }, [pin]);

  const joinGroupUser = useGroupJoin();

  const firstKey = pin?.[0] || "";
  const isLoading = joinGroupUser.isLoading;

  const handleSendPIN =
    (close: () => void) => async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (isLoading) return;
      if (!isValid) {
        toast.error(t("v4.account.codeInvalid"));
        return;
      }
      switch (firstKey) {
        case pinTypes.Guests: {
          history.push(guestCourseRoutes.list(pin));
          return close();
        }
        case pinTypes.Groups: {
          if (role.guest) return history.push(groupRoutes.join(pin));

          return joinGroupUser.mutate(pin, { onSuccess: close });
        }
        case pinTypes.Source: {
          if (!role.guest && !role.teacher) {
            toast.error(t("v4.library.save.noPermission"));
            return close();
          }
          history.push(libraryRoutes.share(pin));
          return close();
        }
        case pinTypes.Courses: {
          if (!role.guest && !role.teacher) {
            toast.error(t("v4.training.importError"));
            return close();
          }
          history.push(courseRoutes.share(pin));
          return close();
        }
        case pinTypes.Session:
          history.push(sessionRoutes.session(pin));
          return close();
      }
    };

  const handleInputPin = (text: string) => {
    try {
      for (const route of [
        groupRoutes.join(),
        libraryRoutes.share(),
        courseRoutes.share(),
        sessionRoutes.session(),
      ]) {
        const pathname = new URL(text).pathname;
        const match = matchPath<{ pin?: string; id?: string }>(pathname, {
          path: route,
          exact: true,
        });
        if (!match) continue;
        const pin = match?.params?.pin || match?.params?.id;
        if (!pin) continue;
        if (pin.length !== 5) continue;
        return setPin(pin.toUpperCase());
      }
    } catch (e) {
      null;
    }
    setPin(text.toUpperCase().substring(0, 5));
  };

  return (
    <FloatingMenu
      trigger={(toggle) => (
        <HeaderIconButton onClick={toggle}>
          <PINIcon />
        </HeaderIconButton>
      )}
      className="w-44"
      size="sm"
    >
      {({ isOpen, setIsOpen }) => (
        <form
          className="flex flex-col"
          onSubmit={handleSendPIN(() => {
            setIsOpen(false);
            setPin("");
          })}
        >
          <div>
            <PINInputField
              value={pin}
              setValue={handleInputPin}
              isOpen={isOpen}
            />
          </div>
          <NewButton
            center
            variant="primary"
            size="sm"
            component="button"
            type="submit"
            disabled={!isValid || isLoading}
          >
            {isLoading ? (
              <CgSpinner className="animate-spin" />
            ) : (
              t("groupsPage.buttons.send")
            )}
          </NewButton>
        </form>
      )}
    </FloatingMenu>
  );
};

export default PINInput;

const PINInputField = ({ isOpen, value, setValue }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!isOpen) return;
    if (!ref.current) return;
    ref.current.focus();
  }, [isOpen]);

  return (
    <input
      ref={ref}
      placeholder={t("groupsPage.labels.enterPIN")}
      value={value}
      className="rounded-md text-center w-full py-1.5 px-3 text-sm border border-gray-200 mb-2 focus:border-primary outline-0"
      onChange={(e) => setValue(e.target.value)}
    />
  );
};
