/* import { AVAILABLE_LANGUAGES, LANG_EN } from "./../enums/languages"; */
import i18n from "i18next";
import en_EN from "./en/translation.json";
import de_DE from "./de/translation.json";
import fr_FR from "./fr/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const resources = {
  de: {
    translation: de_DE,
  },
  fr: {
    translation: fr_FR,
  },
  en: {
    translation: en_EN,
  },
} as const;

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: options,
    debug: false,
    fallbackLng: "en",
    supportedLngs: ["de", "fr", "en"],
    keySeparator: ".",
    returnObjects: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
