import { Dimensions } from "app/components/Exercises/Edit/questionType/Diagram/DiagramExercise";
import { GridItem } from "app/components/Exercises/CourseEdit/courseEditTypes";

export const SLIDE_PADDING = 20;
export const SLIDE_GAP = 10;
export const SLIDE_WIDTH = 1280;
export const SLIDE_HEIGHT = 720;
export const SLIDE_COLS = 12;
export const SLIDE_ROWS = 6;

const COL_WIDTH =
  (SLIDE_WIDTH - SLIDE_PADDING * 2 - SLIDE_GAP * (SLIDE_COLS - 1)) / SLIDE_COLS;

export const ROW_HEIGHT =
  (SLIDE_HEIGHT - SLIDE_PADDING * 2 - SLIDE_GAP * (SLIDE_ROWS - 1)) /
  SLIDE_ROWS;

export const gridToItem = (
  grid: GridItem
): {
  id: string;
} & Dimensions => ({
  id: grid.i,
  x: grid.x * (SLIDE_GAP + COL_WIDTH) + SLIDE_PADDING,
  y: grid.y * (SLIDE_GAP + ROW_HEIGHT) + SLIDE_PADDING,
  w: (grid.w - 1) * SLIDE_GAP + grid.w * COL_WIDTH,
  h: (grid.h - 1) * SLIDE_GAP + grid.h * ROW_HEIGHT,
});
