import { createGlobalStyle } from "styled-components";

export const GridStyles = createGlobalStyle`
  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
    user-select: none;
  }
  .react-grid-item img {
    pointer-events: none;
  }
  .react-grid-item.cssTransforms {
    transition-property: transform;
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }
  
  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }
  
  .react-grid-item.dropping {
    visibility: hidden;
  }
  
  .react-grid-item.react-grid-placeholder {
    opacity: 0.2;
    transition-duration: 100ms;
    border-radius: 1rem;
    border: 4px dashed #777;
    z-index: 2;
    user-select: none;
  }
  
  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    opacity: 0.75;
    transition: opacity 0.1s ease;
  }
  
  .react-resizable-hide > .react-resizable-handle {
    opacity: 0;
    pointer-events: none;
  }

  .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    right: -5px;
    bottom: -5px;
    border-radius: 1rem 0;
    border-right: 10px solid currentColor;
    border-bottom: 10px solid currentColor;
    cursor: nwse-resize;
  }
`;
