import React, { ReactNode, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { FileType, mapMime } from "helpers/mimeType";
import { getFileErrorData } from "helpers/file";
import { sendAppError } from "helpers/appError";
import { NewButton } from "../Buttons/NewButton";
import { CgSpinner } from "react-icons/cg";
import { InputText } from "../Buttons/InputText";
import { TbFile, TbVideo, TbVolume } from "react-icons/tb";

const ScThumbContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ScImage = styled.img`
  max-height: 15rem;
  max-width: 15rem;
  margin: 0 auto;
  border: solid 1px var(--light-grey);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem #0001;
`;

export interface FileWithPreview extends File {
  preview?: string;
}

export const SourcePreview = ({
  file,
  setFile,
  onUpload,
  onCancel,
  children,
}: {
  file: FileWithPreview;
  setFile: (file: FileWithPreview | null) => void;
  onUpload: (file: FileWithPreview, name: string) => Promise<void>;
  onCancel?: () => void;
  children?: ReactNode;
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [inputName, setInputName] = useState<string>("");
  const { t } = useTranslation();

  useEffect(() => {
    setInputName(file?.name.split(".")[0] || "");
    const preview = file.type.startsWith("image/")
      ? URL.createObjectURL(file)
      : undefined;

    const type =
      !file?.type &&
      (file.name.toLowerCase().endsWith("heic") ||
        file.name.toLowerCase().endsWith("heif"))
        ? "image/heif"
        : file?.type;

    const newFile = new File([file], file.name, {
      type,
      lastModified: file.lastModified,
    });
    setFile(Object.assign(newFile, { preview }));

    return () => {
      if (!preview) return;
      URL.revokeObjectURL(preview);
    };
  }, []);

  const saveSource = async () => {
    try {
      setLoading(true);
      await onUpload(file, inputName || t("media.source"));
      // toast.success(t("sourcesPage.status.uploading.success"));
      setFile(null);
      setInputName("");
    } catch (e) {
      toast.error(t("sourcesPage.status.uploading.error"));
      sendAppError({ error: getFileErrorData(e, file), type: "FILE_UPLOAD" });
    } finally {
      setLoading(false);
    }
  };

  const type = useMemo(() => {
    return mapMime(file.type);
  }, [file.type]);

  return (
    <ScThumbContainer>
      {file.preview ? (
        <ScImage src={file.preview} alt={file.name} />
      ) : type === FileType.Audio ? (
        <TbVolume className="text-[4rem] [&>path]:stroke-gray-600" />
      ) : type === FileType.Video ? (
        <TbVideo className="text-[4rem] [&>path]:stroke-gray-600" />
      ) : (
        <TbFile className="text-[4rem] [&>path]:stroke-gray-600" />
      )}

      <label className="flex text-center flex-col items-center mt-4">
        <div className="font-bold mb-1 text-gray-600">{t("source.name")}</div>
        <InputText
          id="input_name"
          value={inputName}
          onChange={setInputName}
          placeholder={t("media.source.name")}
          maxLength={250}
          icon={TbFile}
        />
      </label>
      {children}
      <div className="flex space-x-1 mt-8 w-full">
        <NewButton
          disabled={isLoading}
          size="lg"
          center
          className="flex-1"
          onClick={() => (onCancel ? onCancel() : setFile(null))}
        >
          {t("common.cancel")}
        </NewButton>
        <NewButton
          variant="primary"
          size="lg"
          center
          className="flex-1"
          onClick={saveSource}
          disabled={isLoading}
        >
          {isLoading && <CgSpinner className="animate-spin" />}
          {t("common.save")}
        </NewButton>
      </div>
    </ScThumbContainer>
  );
};
