import {
  deleteOrganizationPatterns,
  getOrganizationPatterns,
  postOrganizationPatterns,
  updateOrganization,
} from "api/organizationsAPI";
import { postFile } from "api/sourcesAPI";
import React, { ChangeEvent, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useColorsContrastRatio } from "app/hooks/useColorsContrastRatio";
import { NewButton } from "app/components/Buttons/NewButton";
import { useParams } from "react-router";
import { InputText } from "app/components/Buttons/InputText";
import {
  TbAlertTriangle,
  TbAt,
  TbBook,
  TbBuilding,
  TbCheck,
  TbColorSwatch,
  TbIcons,
  TbInfoCircle,
  TbLock,
  TbPlus,
  TbSocial,
  TbTrash,
  TbTypography,
  TbUsers,
  TbWorld,
  TbX,
} from "react-icons/tb";
import { CgSpinner } from "react-icons/cg";
import { toast } from "react-hot-toast";
import { useGetOrganization } from "app/pages/admin/organizationDetails/AdminOrganizationDetails";
import useOnClickOutside from "use-onclickoutside";
import { HexColorPicker } from "react-colorful";
import { InputToggle } from "app/components/Buttons/InputToggle";
import hexRgb from "hex-rgb";
import { useFileUrl } from "app/hooks/useFileUrl";
import smartestLogo from "assets/images/logo.png";
import smartestLogoIcon from "assets/images/logo.svg";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const ColorPicker = ({ color, setColor }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div className="flex items-center gap-2">
      <div
        ref={ref}
        className="w-10 h-10 rounded-xl cursor-pointer flex items-center justify-center relative border border-gray-200"
        style={{ backgroundColor: color }}
        onClick={() => setIsOpen(true)}
      >
        {isOpen && (
          <div className="absolute left-0 top-0 z-10 rounded-lg overflow-hidden translate -translate-y-full">
            <HexColorPicker color={color} onChange={setColor} />
          </div>
        )}
      </div>
      <InputText
        value={color}
        onChange={setColor}
        icon={TbColorSwatch}
        maxLength={7}
      />
    </div>
  );
};

const parseColor = (hex: string) => {
  try {
    return hexRgb(hex);
  } catch (e) {
    return null;
  }
};

export const getBrandColor = (hex: string) => {
  let rgb;
  try {
    rgb = hexRgb(hex);
  } catch (e) {
    rgb = { red: 240, green: 172, blue: 14 };
  }
  return `${rgb.red} ${rgb.green} ${rgb.blue}`;
};

const ColorPickerWithPreview = ({ color, setColor }) => {
  const rgb = parseColor(color);

  return (
    <div className="grid grid-cols-2 gap-2">
      <div className="flex flex-col gap-1">
        <div className="text-lg font-bold text-gray-500">
          Color{" "}
          <span
            className={classNames(
              (color === "f08020" || color === "#f08020") &&
                "opacity-0 pointer-events-none",
              "text-sm text-gray-400 font-normal hover:underline cursor-pointer"
            )}
            onClick={() => setColor("#f08020")}
          >
            restore default
          </span>
        </div>
        <ColorPicker {...{ color, setColor }} />
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-lg font-bold text-gray-500">Preview</div>
        {!rgb ? (
          <div className="italic text-red-500 py-1 pl-1 text-lg">
            Invalid color
          </div>
        ) : (
          <div
            className="flex items-center gap-1"
            style={
              { "--brand-color": `${rgb.red} ${rgb.green} ${rgb.blue}` } as any
            }
          >
            <NewButton variant="primary">
              <TbCheck /> Primary
            </NewButton>
            <NewButton color="bg-primary text-primary">
              <TbCheck /> Secondary
            </NewButton>
            <NewButton variant="primary" iconOnly size="lg">
              <TbIcons />
            </NewButton>
            <NewButton color="bg-primary text-primary" iconOnly size="lg">
              <TbIcons />
            </NewButton>
          </div>
        )}
      </div>
    </div>
  );
};

export const OrganizationSettings = () => {
  const { isSuccess } = useGetOrganization();

  if (!isSuccess)
    return <CgSpinner className="text-3xl text-gray-400 m-auto animate-spin" />;

  return (
    <>
      <SettingsForm />
      <BrandingForm />
      <SecuritySettings />
    </>
  );
};

const SettingsForm = () => {
  const { data, refetch } = useGetOrganization();
  const { id } = useParams<{ id: string }>();
  const [name, setName] = useState(data?.name ?? "");
  const [contactEmail, setContactEmail] = useState(data?.contact_email ?? "");

  const updateMutation = useMutation(
    () =>
      updateOrganization(id, {
        name,
        contact_email: contactEmail,
      }),
    {
      onSuccess: () => {
        refetch();
        toast.success("Organization updated successfully");
      },
      onError: () => {
        toast.error(
          "There was an error when updating the organization. Please try again later."
        );
      },
    }
  );

  const updateB2B = useMutation(
    () =>
      updateOrganization(id, {
        is_b2c: !data?.is_b2c,
      }),
    {
      onSuccess: () => {
        refetch();
        toast.success("B2B settings updated successfully");
      },
      onError: () => {
        toast.error(
          "There was an error when updating the B2B settings. Please try again later."
        );
      },
    }
  );

  return (
    <div className="bg-white rounded-2xl shadow-xl p-6 flex flex-col gap-2">
      <div className="text-lg font-bold text-gray-500">Name</div>
      <InputText
        value={name}
        onChange={setName}
        icon={TbTypography}
        maxLength={50}
        readOnly={updateMutation.isLoading}
      />
      <div className="text-lg font-bold text-gray-500 mt-2">Contact email</div>
      <InputText
        value={contactEmail}
        onChange={setContactEmail}
        icon={TbAt}
        maxLength={50}
        readOnly={updateMutation.isLoading}
      />
      <div className="text-lg font-bold text-gray-500 mt-2">Subdomain</div>
      <InputText value={data?.subdomain} readOnly icon={TbWorld} />

      <label className="flex items-center gap-2 bg-gray-100 py-1 px-2 rounded-lg mt-2">
        {updateB2B.isLoading ? (
          <CgSpinner className="animate-spin text-xl" />
        ) : (
          <TbBuilding className="text-xl" />
        )}
        <div className="font-bold text-gray-500">Is B2B</div>
        <div className="flex-grow" />
        <InputToggle
          value={!data?.is_b2c}
          disabled={updateB2B.isLoading}
          onChange={() => updateB2B.mutate()}
        />
      </label>

      <div className="flex justify-end mt-6">
        <NewButton
          size="lg"
          variant="primary"
          onClick={updateMutation.mutate}
          disabled={name === data.name || updateMutation.isLoading}
        >
          {updateMutation.isLoading ? (
            <CgSpinner className="animate-spin" />
          ) : (
            <TbCheck />
          )}
          Submit
        </NewButton>
      </div>
    </div>
  );
};

const useMultiLangInput = (initialData) => {
  const [en, setEn] = useState(initialData?.en || "");
  const [fr, setFr] = useState(initialData?.fr || "");
  const [de, setDe] = useState(initialData?.de || "");

  return {
    data: { en, fr, de },
    set: { en: setEn, fr: setFr, de: setDe },
  };
};

const MultiLangInput = ({ value, langKey }) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-3 gap-2">
      <div className="flex flex-col gap-0.5">
        <div className="font-bold text-gray-400">English</div>
        <InputText
          value={value.data.en}
          onChange={value.set.en}
          placeholder={t(langKey, { lng: "en" }) || "English"}
        />
      </div>
      <div className="flex flex-col gap-0.5">
        <div className="font-bold text-gray-400">German</div>
        <InputText
          value={value.data.de}
          onChange={value.set.de}
          placeholder={t(langKey, { lng: "de" }) || "German"}
        />
      </div>
      <div className="flex flex-col gap-0.5">
        <div className="font-bold text-gray-400">French</div>
        <InputText
          value={value.data.fr}
          onChange={value.set.fr}
          placeholder={t(langKey, { lng: "fr" }) || "French"}
        />
      </div>
    </div>
  );
};

const BrandingForm = () => {
  const { data, refetch } = useGetOrganization();
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const [color, setColor] = useState(data?.theme?.primary_color || "#f07f0e");
  const [topbarLeft, setTopbarLeft] = useState<string>(
    data?.theme?.top_bar_left || ""
  );
  const [topbarRight, setTopbarRight] = useState<string>(
    data?.theme?.top_bar_right || ""
  );
  const contrast = useColorsContrastRatio(color, "#ffffff");

  const trainingsLabel = useMultiLangInput(data?.theme?.trainingsLabel);
  const groupsLabel = useMultiLangInput(data?.theme?.groupsLabel);

  // "" -> resetting to default, null -> unset
  const [bigLogo, setBigLogo] = useState<File | null | "">(null);
  const bigLogoUrl = useFileUrl(bigLogo || null);

  const handleBigLogo = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    e.target.value = "";
    e.target.files = null;

    if (!file) {
      setBigLogo(null);
      return;
    }

    setBigLogo(file);
  };

  const [smallLogo, setSmallLogo] = useState<File | null | "">(null);
  const smallLogoUrl = useFileUrl(smallLogo || null);
  const handleSmallLogo = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    e.target.value = "";
    e.target.files = null;

    if (!file) {
      setSmallLogo(null);
      return;
    }

    setSmallLogo(file);
  };

  const [topbar, setTopbar] = useState<File | null | "">(null);
  const topbarUrl = useFileUrl(topbar || null);
  const handleTopbar = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    e.target.value = "";
    e.target.files = null;

    if (!file) {
      setTopbar(null);
      return;
    }

    setTopbar(file);
  };

  const updateMutation = useMutation(
    async () => {
      let bigLogoId = "";
      if (bigLogo) {
        const data = await postFile({ file: bigLogo });
        bigLogoId = data?.id;
      }

      let smallLogoId = "";
      if (smallLogo) {
        const data = await postFile({ file: smallLogo });
        smallLogoId = data?.id;
      }

      let topbarId = "";
      if (topbar) {
        const data = await postFile({ file: topbar });
        topbarId = data?.id;
      }

      return updateOrganization(id, {
        ...(bigLogo != null && {
          logo_id: bigLogoId,
        }),
        ...(smallLogo != null && {
          small_logo_id: smallLogoId,
        }),
        ...(topbar != null && {
          top_bar_id: topbarId,
        }),
        theme: {
          primary_color: color,
          top_bar_left: topbarLeft,
          top_bar_right: topbarRight,
          trainingsLabel: trainingsLabel.data,
          groupsLabel: groupsLabel.data,
        },
      });
    },
    {
      onSuccess: (data) => {
        refetch();
        toast.success("Organization branding updated successfully");

        if (data?.theme?.primary_color) {
          const brandColor = getBrandColor(data.theme.primary_color);
          document.documentElement.style.setProperty(
            "--brand-color",
            brandColor
          );
        }
        queryClient.invalidateQueries(["guest"]);
      },
      onError: () => {
        toast.error(
          "There was an error when updating the organization branding. Please try again later."
        );
      },
    }
  );

  return (
    <div className="bg-white rounded-2xl shadow-xl p-6 flex flex-col gap-2">
      <div className="text-xl font-bold text-gray-500 mb-2">Branding</div>
      <ColorPickerWithPreview {...{ color, setColor }} />
      {!contrast.pass && (
        <div className="text-red-500 flex gap-2 items-center">
          <TbAlertTriangle className="text-xl" /> Contrast too low!
        </div>
      )}

      <div className="text-lg font-bold text-gray-500 mt-2">Topbar</div>
      <label className="flex items-center gap-2 bg-gray-100 py-1 px-2 rounded-lg">
        <TbColorSwatch className="text-xl" />
        <div className="font-bold text-gray-500">Use colors</div>
        <div className="flex-grow" />
        <InputToggle
          value={!!topbarLeft || !!topbarRight}
          onChange={() => {
            const enabled = !!topbarLeft || !!topbarRight;
            if (enabled) {
              setTopbarLeft("");
              setTopbarRight("");
              return;
            }
            setTopbar(null);
            setTopbarLeft(data?.theme?.top_bar_left || "#ff5e5e");
            setTopbarRight(data?.theme?.top_bar_right || "#5ec8ff");
          }}
        />
      </label>

      {!!topbarLeft || !!topbarRight ? (
        <div className="grid grid-cols-2 gap-4">
          <ColorPicker color={topbarLeft} setColor={setTopbarLeft} />
          <ColorPicker color={topbarRight} setColor={setTopbarRight} />
        </div>
      ) : (
        <div className="flex flex-col">
          <label className="block h-10 w-64 border-2 bg-gray-100 cursor-pointer rounded-xl relative mx-auto">
            <input
              className="hidden"
              accept="image/*"
              type="file"
              onChange={handleTopbar}
            />
            {topbar !== "" && (topbarUrl ?? data?.logo_desktop_url) && (
              <img
                src={topbarUrl ?? data?.top_bar_desktop_url}
                className="h-6 w-full top-1.5 object-fill relative"
              />
            )}
          </label>
          {/* if has no logo, reset on unchanged or empty. if has logo, reset on non-empty */}
          {(!data?.top_bar_desktop_url ? !!topbar : topbar !== "") && (
            <NewButton
              size="sm"
              center
              className="mx-auto"
              onClick={() => setTopbar("")}
            >
              <TbX />
              Restore default
            </NewButton>
          )}
        </div>
      )}

      <div className="text-lg font-bold text-gray-500 mt-2">Logo</div>
      <label className="block h-16 w-60 border-2 bg-gray-100 cursor-pointer rounded-xl relative mx-auto">
        <input
          className="hidden"
          accept="image/*"
          type="file"
          onChange={handleBigLogo}
        />
        <img
          src={
            bigLogo === ""
              ? smartestLogo
              : bigLogoUrl ?? data?.logo_desktop_url ?? smartestLogo
          }
          className="absolute-cover object-contain"
        />
      </label>
      {/* if has no logo, reset on unchanged or empty. if has logo, reset on non-empty */}
      {(!data?.logo_desktop_url ? !!bigLogo : bigLogo !== "") && (
        <NewButton
          size="sm"
          center
          className="mx-auto"
          onClick={() => setBigLogo("")}
        >
          <TbX />
          Restore default
        </NewButton>
      )}

      <div className="text-lg font-bold text-gray-500 mt-2">Small Logo</div>
      <label className="block h-16 w-16 border-2 bg-gray-100 cursor-pointer rounded-xl relative mx-auto">
        <input
          className="hidden"
          accept="image/*"
          type="file"
          onChange={handleSmallLogo}
        />
        <img
          src={
            smallLogo === ""
              ? smartestLogoIcon
              : smallLogoUrl ?? data?.small_logo_desktop_url ?? smartestLogoIcon
          }
          className="absolute-cover object-contain"
        />
      </label>
      {/* if has no logo, reset on unchanged or empty. if has logo, reset on non-empty */}
      {(!data?.small_logo_desktop_url ? !!smallLogo : smallLogo !== "") && (
        <NewButton
          size="sm"
          center
          className="mx-auto"
          onClick={() => setSmallLogo("")}
        >
          <TbX />
          Restore default
        </NewButton>
      )}

      <div className="text-lg font-bold text-gray-500 mt-2">
        <TbBook className="text-xl inline" strokeWidth={2.5} /> Trainings label
      </div>
      <MultiLangInput value={trainingsLabel} langKey="v4.training.plural" />

      <div className="text-lg font-bold text-gray-500 mt-2">
        <TbUsers className="text-xl inline" strokeWidth={2.5} /> Groups label
      </div>
      <MultiLangInput value={groupsLabel} langKey="v4.group.plural" />

      <div className="flex justify-end mt-6">
        <NewButton
          size="lg"
          variant="primary"
          onClick={updateMutation.mutate}
          disabled={
            updateMutation.isLoading ||
            !parseColor(color) ||
            ((topbarLeft || topbarRight) &&
              (!parseColor(topbarLeft) || !parseColor(topbarRight)))
          }
        >
          {updateMutation.isLoading ? (
            <CgSpinner className="animate-spin" />
          ) : (
            <TbCheck />
          )}
          Submit
        </NewButton>
      </div>
    </div>
  );
};

const SecuritySettings = () => {
  const { id } = useParams<{ id: string }>();
  const { data, refetch } = useGetOrganization();
  const patternsQuery = useQuery(
    ["organization", id, "patterns"],
    () => getOrganizationPatterns(id),
    { refetchOnWindowFocus: false }
  );

  const [newPattern, setNewPattern] = useState("");

  const update2FAMutation = useMutation(
    () =>
      updateOrganization(id, {
        is_2fa: !data?.is_2fa,
      }),
    {
      onSuccess: () => {
        refetch();
        toast.success("2FA settings updated successfully");
      },
      onError: () => {
        toast.error(
          "There was an error when updating the 2FA settings. Please try again later."
        );
      },
    }
  );

  const updateSocialLogin = useMutation(
    () =>
      updateOrganization(id, {
        is_social_login: !data?.is_social_login,
      }),
    {
      onSuccess: () => {
        refetch();
        toast.success("Social login settings updated successfully");
      },
      onError: () => {
        toast.error(
          "There was an error when updating the social login settings. Please try again later."
        );
      },
    }
  );

  const addPatternMutation = useMutation(
    () => postOrganizationPatterns(id, { email_pattern: newPattern }),
    {
      onSuccess: () => {
        patternsQuery.refetch();
        setNewPattern("");
      },
    }
  );

  return (
    <div className="bg-white rounded-2xl shadow-xl p-6 flex flex-col gap-2">
      <div className="text-xl font-bold text-gray-500 mb-2">Security</div>
      <label className="flex items-center gap-2 bg-gray-100 py-1 px-2 rounded-lg">
        {update2FAMutation.isLoading ? (
          <CgSpinner className="animate-spin text-xl" />
        ) : (
          <TbLock className="text-xl" />
        )}
        <div className="font-bold text-gray-500">Use 2FA</div>
        <div className="flex-grow" />
        <InputToggle
          value={!!data?.is_2fa}
          disabled={update2FAMutation.isLoading}
          onChange={() => update2FAMutation.mutate()}
        />
      </label>
      <label className="flex items-center gap-2 bg-gray-100 py-1 px-2 rounded-lg">
        {updateSocialLogin.isLoading ? (
          <CgSpinner className="animate-spin text-xl" />
        ) : (
          <TbSocial className="text-xl" />
        )}
        <div className="font-bold text-gray-500">Use social login</div>
        <div className="flex-grow" />
        <InputToggle
          value={!!data?.is_social_login}
          disabled={updateSocialLogin.isLoading}
          onChange={() => updateSocialLogin.mutate()}
        />
      </label>

      <div className="font-bold text-gray-500 mt-2">Email patterns</div>

      {patternsQuery.isLoading ? (
        <CgSpinner className="animate-spin text-lg" />
      ) : (
        <div className="grid grid-cols-[1fr_auto] gap-2">
          {patternsQuery.data.map(({ id, email_pattern }) => (
            <SingleEmailPattern key={id} id={id} pattern={email_pattern} />
          ))}
          <div className="col-span-2 flex flex-col gap-1 mt-1">
            <form
              className="flex gap-1 items-center"
              onSubmit={(e) => {
                e.preventDefault();
                addPatternMutation.mutate();
              }}
            >
              <InputText
                value={newPattern}
                onChange={setNewPattern}
                placeholder="New pattern"
                icon={TbAt}
                readOnly={addPatternMutation.isLoading}
              />
              <NewButton
                variant="primary"
                size="lg"
                component="button"
                type="submit"
                disabled={addPatternMutation.isLoading || !newPattern.length}
              >
                {addPatternMutation.isLoading ? (
                  <CgSpinner className="animate-spin" />
                ) : (
                  <TbPlus />
                )}
                Add
              </NewButton>
            </form>
            <div className="text-sm text-gray-400 flex gap-1 items-center">
              <TbInfoCircle /> E.g. "smartest.io" or "gmail.com"
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const SingleEmailPattern = ({ id: patternId, pattern }) => {
  const { id } = useParams<{ id: string }>();

  const queryClient = useQueryClient();
  const removePatternMutation = useMutation(
    (patternId: number) => deleteOrganizationPatterns(id, patternId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["organization", id, "patterns"]);
      },
    }
  );

  return (
    <>
      <div>{pattern}</div>
      <div>
        <NewButton
          onClick={() => removePatternMutation.mutate(patternId)}
          disabled={removePatternMutation.isLoading}
          color="bg-red-500 text-red-500"
          iconOnly
        >
          {removePatternMutation.isLoading ? (
            <CgSpinner className="animate-spin" />
          ) : (
            <TbTrash />
          )}
        </NewButton>
      </div>
    </>
  );
};

// const OrganizationSettings_ = ({ id }) => {
//   const { t } = useTranslation();
//   const fileRef = useRef<HTMLInputElement>(null);
//   const [file, setFile] = useState<File | null>(null);
//   const [logoUrl, setLogoUrl] = useState<string | null>(null);
//   const [logoId, setLogoId] = useState<string | null>(null);
//
//   const { data, isLoading, isSuccess } = useQuery(
//     ["organization", id],
//     () => getAdminOrganization(id || ""),
//     {
//       onSuccess: (data) => {
//         if (data.logo_download_url) {
//           setLogoUrl(data.logo_download_url);
//           setLogoId(
//             data.logo_download_url
//               .split("/")
//               [data.logo_download_url.split("/").length - 1].split(".")[0]
//           );
//         }
//       },
//       refetchOnWindowFocus: false,
//       enabled: !!id,
//     }
//   );
//
//   const [primaryColor, setPrimaryColor] = useState(
//     data?.theme?.primary_color ||
//       getComputedStyle(document.documentElement).getPropertyValue(
//         "--primary-color"
//       )
//   );
//
//   const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0] || null;
//     e.target.value = "";
//     e.target.files = null;
//     setFile(file);
//
//     if (!file) return;
//   };
//
//   const uploadFile = useMutation(() => postFile({ file }), {
//     onSuccess: (data) => {
//       setLogoUrl(data.download_url);
//       setLogoId(data.id);
//       setFile(null);
//     },
//   });
//
//   const updateOrganization = useMutation(() =>
//     putOrganization({
//       id,
//       dataToSave: {
//         logo_id: logoId,
//         theme: {
//           primary_color: primaryColor,
//         },
//       },
//     })
//   );
//
//   const contrast = useColorsContrastRatio(primaryColor, "#fffff");
//
//   if (isLoading)
//     return (
//       <div>
//         <Loader />
//       </div>
//     );
//
//   return (
//     <section style={{ background: "#fff", padding: "20px", display: "flex" }}>
//       <div style={{ width: "50%" }}>
//         <div style={{ display: "flex" }}>
//           <div style={{ display: "flex", flexDirection: "column" }}>
//             <span style={{ margin: "10px 10px 10px 0" }}>
//               Organization logo:
//             </span>
//             <label>
//               <input
//                 type="file"
//                 accept={["image/*"].join(",")}
//                 onChange={handleFile}
//                 hidden={true}
//                 ref={fileRef}
//               />
//               <NewButton
//                 component="div"
//                 style={{
//                   marginBottom: "5px",
//                 }}
//               >
//                 Upload
//               </NewButton>
//               {file && (
//                 <NewButton
//                   component="button"
//                   variant="primary"
//                   small
//                   onClick={() => uploadFile.mutate()}
//                 >
//                   Use this logo
//                 </NewButton>
//               )}
//             </label>
//           </div>
//           {logoUrl && <ScImagePreview src={logoUrl} />}
//           {!logoUrl && file && (
//             <ScImagePreview src={URL.createObjectURL(file)} />
//           )}
//           {logoId && (
//             <button
//               onClick={() => {
//                 setLogoId("");
//                 setLogoUrl(null);
//               }}
//             >
//               Remove logo
//             </button>
//           )}
//         </div>
//         <ScColor>
//           Primary color:
//           {/*<Color color={primaryColor} setColor={setPrimaryColor} />*/}
//           {!contrast.pass && <p>Contrast too low!</p>}
//         </ScColor>
//         <NewButton
//           variant="primary"
//           center
//           onClick={() => updateOrganization.mutate()}
//           disabled={!contrast.pass}
//         >
//           Update
//         </NewButton>
//       </div>
//       <div style={{ width: "50%" }}>
//         <Button
//           small
//           clickAction={() => {
//             setPrimaryColor("#f07f0e");
//           }}
//         >
//           Set default
//         </Button>
//         <ScExample>
//           Primary Button
//           <NewButton variant="primary">Example</NewButton>
//         </ScExample>
//         <ScExample>
//           Secondary Button
//           <NewButton color="bg-primary text-primary">Example</NewButton>
//         </ScExample>
//       </div>
//     </section>
//   );
// };

// const ScColor = styled.div`
//   display: flex;
//   align-items: center;
//   margin: 10px 0;
// `;
//
// const ScExample = styled.div`
//   margin: 10px 0;
// `;
//
// const ScImagePreview = styled.img`
//   max-width: 200px;
//   max-height: 200px;
// `;
