import styled, { keyframes } from "styled-components";
import logo from "../../assets/images/logo.svg";
import whiteLogo from "../../assets/images/logo-white.svg";
import { ComponentProps } from "react";
import classNames from "classnames";
import { useGlobalQuery } from "app/App";

const ScLoaderContainer = styled.div<{ small: boolean }>`
  margin: ${(props) => (props.small ? "initial" : "auto")};
  width: 100%;
  height: ${(props) => (props.small ? "16px" : "40px")};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  color: rgb(var(--brand-color));
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const ScLoader = styled.div<{ small: boolean }>`
  box-sizing: content-box;
  border: 4px solid #ddd;
  border-left: 4px solid;
  animation: ${spin} 700ms infinite linear;
  border-radius: 50%;
  width: ${(props) => (props.small ? 14 : 20)}px;
  height: ${(props) => (props.small ? 14 : 20)}px;
  outline: none;
`;

const LogoLoader = styled.img<{ small: boolean }>`
  animation: ${spin} 700ms infinite linear;
  width: ${(props) => (props.small ? 18 : 25)}px;
  height: ${(props) => (props.small ? 18 : 25)}px;
  margin-top: 0 !important;
`;

type LoaderProps = {
  small?: boolean;
  light?: boolean;
  spinner?: boolean;
};

const Loader = ({ small = false, light, spinner }: LoaderProps) => {
  const globalQuery = useGlobalQuery();
  return (
    <ScLoaderContainer small={small}>
      {!spinner && !!globalQuery.data?.small_logo_download_url ? (
        <LogoLoader
          small={small}
          src={
            globalQuery.data?.small_logo_download_url ||
            (light ? whiteLogo : logo)
          }
        />
      ) : (
        <ScLoader small={small} />
      )}
    </ScLoaderContainer>
  );
};

export const NewLoader = ({
  light = false,
  className,
  ...props
}: ComponentProps<"img"> & { light?: boolean }) => (
  <img
    className={classNames(className, "animate-spin")}
    src={light ? whiteLogo : logo}
    style={{ width: "1em", height: "1em" }}
    {...props}
  />
);

export default Loader;
