import React, { HTMLProps } from "react";
import styled from "styled-components";
import { IconType } from "react-icons";
import { Tooltip } from "app/components/Tooltip";
import { NewButton } from "app/components/Buttons/NewButton";

interface DiagramButtonProps {
  text?: string;
  icon: IconType;
  isActive?: boolean;
}

export const ExerciseActionButton = ({
  text,
  icon: Icon,
  isActive,
  disabled,
  onClick,
}: DiagramButtonProps & HTMLProps<HTMLButtonElement>) => (
  <Tooltip value={text} delay={[500, 0]}>
    <NewButton
      iconOnly
      size="lg"
      {...{ disabled, onClick }}
      color={isActive ? "bg-primary text-primary" : "bg-gray-600 text-gray-600"}
      variant="transparent"
      className="!p-2.5"
    >
      <Icon className="!text-2xl" />
    </NewButton>
  </Tooltip>
);

export const Separator = styled.div`
  height: 2em;
  width: 1px;
  background: #000;
  opacity: 0.1;
  flex-shrink: 0;
  align-self: center;
`;
