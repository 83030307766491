import { useEffect, useRef, useState } from "react";
import { AccessToken, wsUrl } from "enums/constant";

import { Toast, toast } from "react-hot-toast";
import { NotificationPopup } from "app/components/Notifications/NotificationPopup";
import { useTranslation } from "react-i18next";
import { userDetails } from "app/store/user";
import { useDispatch } from "react-redux";
import { NotificationCode } from "app/components/Notifications/Notification";
import { useSubscriptionRefetch } from "api/userAPI";

export const useNotifications = () => {
  const { t } = useTranslation();
  const webSocket = useRef<any>(null);
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();
  const subscriptionRefetch = useSubscriptionRefetch();

  const handleNewNotification = (data) => {
    const code = data?.payload?.notification_code;
    if (
      code &&
      [
        NotificationCode.TrialExpire,
        NotificationCode.SubscriptionExpired,
        NotificationCode.SubscriptionCanceled,
        NotificationCode.SubscriptionActive,
      ].includes(code)
    ) {
      subscriptionRefetch();
      dispatch(userDetails(""));
    }

    toast(
      (toast: Toast) => (
        <NotificationPopup data={data} t={t} toastId={toast.id} />
      ),
      {
        duration: 5000,
      }
    );
  };

  const handleReadNotifications = () => {
    if (!unread.length) return;
    webSocket.current.send(
      JSON.stringify({ notifications: unread.map((item: any) => item.id) })
    );
  };

  const unread = notifications.filter(
    ({ date_read }: any) => date_read === null
  );

  useEffect(() => {
    const token = localStorage.getItem(AccessToken);
    webSocket.current = new WebSocket(`${wsUrl}/notifications?token=${token}`);

    webSocket.current.onopen = (e) => {
      console.log("connected");
    };

    webSocket.current.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data.error) return;
      // if (data.method === "edit") {
      //   if (!data?.edit_id) return;
      //   document.dispatchEvent(lessonEditEvent(data));
      //   return;
      // }

      !data.results &&
        !data.notifications &&
        data.id &&
        handleNewNotification(JSON.parse(e.data));

      setNotifications((notifications) => {
        return (data.results || [data, ...notifications]).sort(
          (a, b) =>
            new Date(b.date_created).getDate() -
            new Date(a.date_created).getDate()
        );
      });
    };

    webSocket.current.onerror = (e) => {
      console.log(e);
    };

    return () => webSocket.current.close();
  }, []);

  return {
    notifications: notifications,
    unread,
    handleReadNotifications,
  };
};
