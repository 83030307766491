import styled from "styled-components";

const ScActionButton = styled.button<{ searchBar?: boolean }>`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  background-color: #f07f0e;
  color: white;
  text-decoration: none;
  transition: all 0.1s ease;
  &:hover {
    background-color: #d9730d;
  }
  &:disabled {
    background-color: #847362;
    cursor: not-allowed;
  }
  @media screen and (max-width: 480px) {
    margin-top: ${(props) => props.searchBar && "40px"};
  }
  a {
    color: white;
    text-decoration: none;
  }
`;

export { ScActionButton };
