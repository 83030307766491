import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthSection } from "./Login";
import { LoginApple } from "app/pages/auth/LoginApple";
import { LoginMicrosoft } from "app/pages/auth/LoginMicrosoft";
import { LoginGoogle } from "app/pages/auth/LoginGoogle";
import { InputText } from "app/components/Buttons/InputText";
import {
  TbAt,
  TbCircleCheck,
  TbEye,
  TbEyeOff,
  TbKey,
  TbRefresh,
  TbUser,
} from "react-icons/tb";
import { NewButton } from "app/components/Buttons/NewButton";
import { Link } from "react-router-dom";
import { authRoutes, publicRoutes } from "enums/routes";
import useOnClickOutside from "use-onclickoutside";
import isEmail from "validator/lib/isEmail";
import isNumeric from "validator/lib/isNumeric";
import { AnimatePresence, motion } from "framer-motion";
import { toast } from "react-hot-toast";
import { CgSpinner } from "react-icons/cg";
import { postRegistration, postResendActivationEmail } from "api/authAPI";
import { useMutation } from "react-query";
import classNames from "classnames";
import { useGlobalQuery } from "app/App";

export const RegisterView = () => {
  const { t } = useTranslation();

  return (
    <AuthSection>
      <h1 className="text-4xl mx-auto text-center font-bold mb-8">
        {t("v4.landingPage.register.forFree")}
      </h1>
      <RegistrationForm />

      <div className="text-center font-bold text-gray-600 mt-12">
        {t("v4.account.signInPrompt")}{" "}
        <Link
          to={authRoutes.login}
          className="text-primary hover:opacity-50 transition inline"
        >
          {t("authPages.signIn.submitBtn.label")}
        </Link>
      </div>
    </AuthSection>
  );
};

const ErrorMessage = ({ children }) => (
  <AnimatePresence initial={false}>
    {!!children && (
      <motion.div
        className="text-xs text-red-500 font-bold -mt-1 overflow-hidden"
        initial={{ height: 0 }}
        animate={{ height: "auto" }}
        exit={{ height: 0 }}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);

export const RegistrationForm = () => {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const globalQuery = useGlobalQuery();

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const ref = useRef<HTMLLabelElement | null>(null);
  const [passwordHidden, setPasswordHidden] = useState(true);
  useOnClickOutside(ref, () => setPasswordHidden(true));

  const validatedName = useMemo(() => {
    if (!name) return t("v4.account.nameEmptyError");
    return null;
  }, [name, i18n.language]);

  const validatedEmail = useMemo(() => {
    if (!email || !isEmail(email)) return t("v4.account.emailInvalidError");
    return null;
  }, [email, i18n.language]);

  const validatedPassword = useMemo(() => {
    if (password.length < 8) return t("v4.account.passwordLengthError");
    if (isNumeric(password)) return t("v4.account.passwordLettersError");
    return null;
  }, [password, i18n.language]);

  const registerMutation = useMutation(
    () =>
      postRegistration({
        email,
        name,
        password1: password,
        password2: password,
      }),
    {
      onError: (e: any) => {
        const data = e?.response?.data;
        if (data?.error === "EMAIL_PATTERN_FORBIDDEN") {
          toast.error(t("v4.account.emailFilterError"));
          return;
        }

        if (data?.email?.[0].includes("A user is already registered")) {
          toast.error(t("authPages.register.errors.emailExist"));
          return;
        }

        if (
          data?.email?.[0].includes("ACCOUNT_INACTIVE") ||
          data?.error === "ACCOUNT_INACTIVE"
        ) {
          toast.error(t("v4.account.inactive"));
          return;
        }

        toast.error(t("common.exercises.error.generic"));
      },
    }
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (registerMutation.isLoading) return;
    if (registerMutation.isSuccess) return;
    setHasSubmitted(true);
    if (validatedName || validatedEmail || validatedPassword) return;

    registerMutation.mutate();
  };

  if (registerMutation.isSuccess) return <ActivationEmailView email={email} />;

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col-reverse sm:flex-row items-center w-full gap-10 max-w-2xl mx-auto">
        {globalQuery.data?.is_org_social_login && (
          <>
            <div className="flex flex-col gap-2 my-auto grow">
              <LoginGoogle />
              <LoginApple />
              <LoginMicrosoft />
            </div>
            <OrSeparator vertical className="self-stretch hidden sm:flex" />
            <OrSeparator className="sm:hidden w-full" />
          </>
        )}
        <form
          className="flex flex-col gap-2 my-auto sm:pt-10 grow max-w-xs mx-auto"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <InputText
            icon={TbUser}
            value={name}
            onChange={setName}
            placeholder={t("v4.generic.name")}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
          />
          <ErrorMessage>{hasSubmitted && validatedName}</ErrorMessage>
          <InputText
            icon={TbAt}
            value={email}
            onChange={setEmail}
            placeholder={t("v4.account.email")}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
          />
          <ErrorMessage>{hasSubmitted && validatedEmail}</ErrorMessage>
          <InputText
            icon={TbKey}
            value={password}
            onChange={setPassword}
            placeholder={t("v4.account.password")}
            type={passwordHidden ? "password" : "text"}
            ref={ref}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
          >
            <NewButton
              variant="transparent"
              className="px-2"
              iconOnly
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setPasswordHidden(!passwordHidden);
              }}
            >
              {passwordHidden ? <TbEye /> : <TbEyeOff />}
            </NewButton>
          </InputText>
          <ErrorMessage>{hasSubmitted && validatedPassword}</ErrorMessage>

          <div className="text-center text-sm text-gray-600 my-2">
            {t("authPages.register.terms")}{" "}
            <Link
              to={publicRoutes.terms}
              className="text-primary hover:opacity-50 transition inline"
              target="_blank"
            >
              {t("authPages.register.termsLink")}
            </Link>
            .
          </div>

          <span className="text-gray-500 text-sm mb-2">
            {t("v4.landingPage.register.freeTrial")}
          </span>

          <NewButton
            variant="primary"
            className="ml-auto"
            size="lg"
            component="button"
            type="submit"
            disabled={
              registerMutation.isLoading ||
              (hasSubmitted &&
                (!!validatedName || !!validatedEmail || !!validatedPassword))
            }
          >
            {registerMutation.isLoading && (
              <CgSpinner className="animate-spin" />
            )}
            {t("authPages.register.submitBtn.label")}
          </NewButton>
        </form>
      </div>
    </div>
  );
};

const ActivationEmailView = ({ email }) => {
  const { t } = useTranslation();
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    if (!timer) return;
    const t = setTimeout(() => setTimer((t) => t - 1), 1000);
    return () => {
      clearTimeout(t);
    };
  }, [timer]);

  const resendMutation = useMutation(() => postResendActivationEmail(email), {
    onSuccess: () => {
      setTimer(30);
    },
    onError: () => {
      toast.error(t("common.exercises.error.generic"));
    },
  });

  return (
    <div className="flex flex-col gap-4 max-w-prose text-center items-center mx-auto w-full whitespace-pre-line text-gray-600">
      <TbCircleCheck className="text-5xl text-primary" strokeWidth={1.25} />
      <div className="text-lg md:text-xl">
        {t("authPages.register.confirmEmailSent.label")}
      </div>

      <OrSeparator className="w-full my-2" />

      <NewButton
        color="bg-primary text-primary"
        disabled={timer || resendMutation.isLoading}
        onClick={() => !timer && resendMutation.mutate()}
        size="lg"
      >
        {resendMutation.isLoading ? (
          <CgSpinner className="animate-spin" />
        ) : (
          <TbRefresh />
        )}
        {timer ? `${t("v4.2fa.resendIn")} ${timer}` : t("v4.2fa.resend")}
      </NewButton>
    </div>
  );
};

export const OrSeparator = ({
  vertical = false,
  className = "",
  children = undefined as ReactNode,
}) => {
  const { t } = useTranslation();

  if (vertical)
    return (
      <div
        className={classNames("flex flex-col items-center gap-4", className)}
      >
        <div className="grow border-l-2 border-gray-200" />
        <div className="text-sm font-bold text-gray-500">
          {t("authPages.register.or")}
        </div>
        <div className="grow border-l-2 border-gray-200" />
      </div>
    );
  return (
    <div
      className={classNames(
        "flex text-sm font-bold items-center gap-4",
        className
      )}
    >
      <div className="flex-grow border-b-2 border-gray-200" />
      {children ?? t("authPages.register.or")}
      <div className="flex-grow border-b-2 border-gray-200" />
    </div>
  );
};
