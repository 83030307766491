import React from "react";
import { toast } from "react-hot-toast";
import {
  getNotificationDetails,
  Notification,
} from "app/components/Notifications/Notification";

export const NotificationPopup = ({ data, t, toastId }) => {
  const item = getNotificationDetails(data, t);

  if (!item) return null;
  return (
    <div className="-my-2 -mx-4">
      <Notification item={data} close={() => toast.dismiss(toastId)} />
    </div>
  );
};
