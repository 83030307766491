import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TbArrowsDiagonal,
  TbBook,
  TbChevronRight,
  TbDeviceMobile,
  TbDots,
  TbFile,
  TbFilter,
  TbFolder,
  TbFolders,
  TbPhoto,
  TbPlus,
  TbSearch,
  TbUpload,
  TbVideo,
  TbVolume,
  TbWorldUpload,
} from "react-icons/tb";
import classNames from "classnames";
import { NewButton } from "app/components/Buttons/NewButton";
import { usePersistentStorage } from "app/hooks/useBrowserStorageState";
import { Resizable } from "react-resizable";
import styled from "styled-components";
import { InView } from "react-intersection-observer";
import {
  ACCEPTED_FILE_TYPES,
  FileType,
  mapFileTypeToName,
  mapMime,
  mapSourceToType,
} from "helpers/mimeType";
import { useDateFormat } from "app/hooks/useDateFormat";
import {
  MediaData,
  MediaPicker,
  MediaPickerView,
} from "app/components/Sources/MediaPicker/MediaPicker";
import { useDropzone } from "react-dropzone";
import { FILE_SIZE_LIMIT } from "helpers/file";
import { toast } from "react-hot-toast";
import { useSourceUploadStore } from "api/ws/SourceUploadContext";
import { FloatingMenu } from "app/components/Header";
import { useLibrary } from "app/components/Sources/MediaPicker/SourceList/useLibrary";
import { FilterSelectMenu } from "app/components/Sources/MediaPicker/SourceList/SourceList";
import {
  MediaOneColumn,
  MediaTwoColumns,
  useDropMedia,
} from "app/components/Sources/MediaPicker/context/dropMediaContext";
import { useRole } from "app/hooks/useRole";

export const CourseSourcesPanel = () => {
  const [dropMedia] = useDropMedia();
  const [height, setHeight] = usePersistentStorage(
    "left_panel_library_size",
    300
  );
  const [resizing, setResizing] = useState(false);

  const handleResize = (_, { size }) => {
    if (size?.height == null) return;
    setHeight(size.height);
  };

  return (
    <Resizable
      onResize={handleResize}
      height={height}
      maxConstraints={[0, 800]}
      minConstraints={[0, 100]}
      width={300}
      axis="y"
      resizeHandles={["n"]}
      handle={(_, ref) => (
        <ResizeHandle
          className={classNames(
            "before:transition hover:before:bg-primary -top-4 [.slides-group:hover>&]:opacity-100 transition",
            resizing ? "before:bg-primary" : "before:bg-gray-300 opacity-25"
          )}
          ref={ref}
        />
      )}
      onResizeStart={() => setResizing(true)}
      onResizeStop={() => setResizing(false)}
      className="transition transform"
    >
      <div
        style={{ height }}
        className={classNames(
          "bg-white border-t border-gray-200 relative flex flex-col mt-auto max-h-[80vh] transition",
          dropMedia && "opacity-20 pointer-events-none"
        )}
      >
        <InnerSourcesPanel />
        {/*<DropMediaInfo />*/}
      </div>
    </Resizable>
  );
};

const ResizeHandle = styled.div`
  -ms-touch-action: none;
  touch-action: none;

  cursor: ns-resize;
  width: 100%;
  height: 1rem;
  top: 0;
  position: absolute;
  z-index: 10;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    height: 0.25rem;
    width: 4rem;
    border-radius: 100000px;
  }
`;

export const Skeleton = ({ size = 15 }) =>
  useMemo(
    () => (
      <>
        {[...new Array(size)].map((_, i) => (
          <div
            key={i}
            className="flex items-center mx-3 gap-2 px-1 py-1 animate-pulse rounded-lg bg-gray-800 bg-opacity-0"
            style={{ animationDelay: i * 0.2 + "s" }}
          >
            <div className="w-9 h-9 bg-gray-200 rounded-lg shrink-0 relative flex items-center justify-center" />
            <div className="flex flex-col grow gap-1">
              <div className="bg-gray-300 h-3 w-full rounded-full" />
              <div className="text-xs flex text-gray-300 items-center gap-1">
                <div className="h-3 bg-gray-200 w-10 rounded-full" />
                <div>•</div>
                <div className="h-3 bg-gray-200 w-20 rounded-full" />
              </div>
            </div>
          </div>
        ))}
      </>
    ),
    [size]
  );

export const NavigationItem = ({ children, id, onClick }) => (
  <div
    onClick={onClick(FileType.Folder, id)}
    className="font-bold text-xs text-gray-400 transition hover:text-primary cursor-pointer"
  >
    {children}
  </div>
);

export const InnerSourcesPanel = () => {
  const [, setDropMedia] = useDropMedia();
  const { t } = useTranslation();
  const format = useDateFormat();
  const {
    list,
    query,
    folderId,
    setFolderId,
    input,
    setInput,
    bindFilter,
    filters,
  } = useLibrary();
  const [file, setFile] = useState<File | string | null>(null);
  const [mediaPicker, setMediaPicker] = useState<MediaPickerView | null>(null);
  const [sourceId, setSourceId] = useState<string | null>(null);
  const role = useRole();

  const onItemClick = (type: FileType, id: string) => () => {
    if (type === FileType.Course) return;
    if (type === FileType.Folder) return setFolderId(id);
    setSourceId(id);
    setMediaPicker(MediaPickerView.List);
    setFile(null);
  };

  useEffect(() => {
    if (!mediaPicker) {
      setFile(null);
      setSourceId(null);
    }
    // if (mediaPicker !== MediaPickerView.List) {
    //   setFile(null);
    // }
  }, [mediaPicker]);

  const navigationStack = query.data?.pages?.[0]?.header?.navigation_stack;

  const handleMedia = (
    media: MediaData | string | MediaOneColumn | MediaTwoColumns
  ) => {
    setDropMedia(media);
  };

  const uploaded = useSourceUploadStore((state) => Object.entries(state.data));

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: ACCEPTED_FILE_TYPES,
    noKeyboard: true,
    noClick: true,
    multiple: false,
    onDrop: async (acceptedFiles: any) => {
      const file = acceptedFiles?.[0] as File;
      if (!file) return;

      if (file.size > FILE_SIZE_LIMIT) {
        toast.error(t("sourcesPage.status.uploading.errorSize"));
        return;
      }

      const mimeType = !!file?.type && mapMime(file.type);
      if (
        mimeType === false ||
        ![
          FileType.Document,
          FileType.Image,
          FileType.Audio,
          FileType.Video,
        ].includes(mimeType)
      ) {
        toast.error(t("sourcesPage.uploadNew.uploadTypeError"));
        return;
      }
      setFile(file);
      setMediaPicker(MediaPickerView.List);
    },
  });

  return (
    <div
      className="absolute-cover overflow-y-auto pb-2"
      {...(query.isSuccess && getRootProps())}
      role={undefined}
    >
      <div className="flex flex-col text-gray-600">
        <div className="flex flex-col sticky z-[1] top-0 w-full bg-white pb-2 pt-3">
          <div className="flex gap-0.5 items-center mx-3 px-1 mt-1">
            {folderId && !query.isSuccess ? (
              <div className="h-2 mt-1 bg-gray-200 w-1/2 rounded-full animate-pulse" />
            ) : (
              <>
                {!!navigationStack?.length && (
                  <>
                    <NavigationItem onClick={onItemClick} id="">
                      {t("v4.library.text")}
                    </NavigationItem>
                    <TbChevronRight className="text-gray-400 text-sm" />
                  </>
                )}
                {navigationStack?.length > 3 && (
                  <>
                    <TbDots className="text-gray-500 text-sm" />
                    <TbChevronRight className="text-gray-400 text-sm" />
                  </>
                )}
                {navigationStack?.slice(-3, -1).map(({ id, name }) => (
                  <Fragment key={id}>
                    <NavigationItem id={id} onClick={onItemClick}>
                      {name}
                    </NavigationItem>
                    <TbChevronRight className="text-gray-400 text-sm" />
                  </Fragment>
                ))}
              </>
            )}
          </div>
          <div className="px-4 pb-3 font-bold flex items-center gap-1">
            <TbFolders className="text-2xl shrink-0 mr-1" />
            {navigationStack?.slice(-1)?.[0]?.name ?? t("v4.library.text")}
            <div className="grow" />
            {/*<NewButton*/}
            {/*  size="sm"*/}
            {/*  variant="transparent"*/}
            {/*  color="bg-gray-400 text-gray-400"*/}
            {/*  iconOnly*/}
            {/*  className="shrink-0"*/}
            {/*  onClick={() => {*/}
            {/*    query.remove();*/}
            {/*    query.refetch();*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <TbRefresh />*/}
            {/*</NewButton>*/}
            <FloatingMenu
              size="xs"
              portal
              trigger={(toggle) => (
                <NewButton
                  size="sm"
                  variant="transparent"
                  iconOnly
                  className="shrink-0"
                  onClick={toggle}
                >
                  <TbPlus />
                </NewButton>
              )}
            >
              {({ setIsOpen }) => (
                <>
                  <NewButton
                    variant="transparent"
                    onClick={() => {
                      open();
                      setIsOpen(false);
                    }}
                  >
                    <TbUpload /> {t("v4.library.uploadSource")}
                  </NewButton>
                  <NewButton
                    variant="transparent"
                    onClick={() => {
                      setMediaPicker(MediaPickerView.Mobile);
                      setIsOpen(false);
                    }}
                  >
                    <TbDeviceMobile /> {t("v4.library.mobileUpload")}
                  </NewButton>
                  {!role.prodMode && (
                    <NewButton
                      variant="transparent"
                      onClick={() => {
                        setMediaPicker(MediaPickerView.Website);
                        setIsOpen(false);
                      }}
                    >
                      <TbWorldUpload />
                      {t("v4.library.websiteUpload.text")}
                    </NewButton>
                  )}
                  <NewButton
                    variant="transparent"
                    onClick={() => {
                      setMediaPicker(MediaPickerView.Record);
                      setIsOpen(false);
                    }}
                  >
                    <TbVideo /> {t("v4.library.record")}
                  </NewButton>
                </>
              )}
            </FloatingMenu>
            <NewButton
              size="sm"
              variant="transparent"
              iconOnly
              className="shrink-0"
              onClick={() => {
                setSourceId(null);
                setMediaPicker(MediaPickerView.List);
              }}
            >
              <TbArrowsDiagonal />
            </NewButton>
          </div>
          <div className="flex px-4 gap-1">
            <label className="flex grow bg-gray-100 focus-within:bg-gray-200 transition rounded-lg items-center">
              <TbSearch className="text-xl ml-2" />
              <input
                className="py-1 px-2 min-w-0 w-full bg-transparent outline-none"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={t("v4.generic.search")}
              />
            </label>

            <FloatingMenu
              portal
              trigger={(toggle) => (
                <NewButton onClick={toggle} iconOnly>
                  <TbFilter />
                </NewButton>
              )}
              placement="bottom-end"
              size="sm"
              className="space-y-1"
            >
              <FilterSelectMenu bindFilter={bindFilter} sourcesOnly />
            </FloatingMenu>
          </div>
        </div>
        {mediaPicker != null && (
          <MediaPicker
            close={() => {
              setMediaPicker(null);
              setFile(null);
            }}
            folderId={folderId}
            sourceId={sourceId}
            onInsert={handleMedia}
            initialView={mediaPicker}
            mainPicker
            search={input}
            filters={filters}
            initialFile={file}
          />
        )}
        {!query.isSuccess ? (
          <Skeleton />
        ) : (
          <>
            {/* upload by dragging over */}
            <input {...getInputProps()} />
            <div
              className={classNames(
                "fixed w-full h-full p-8 z-20 pointer-events-none bg-white bg-opacity-50 filter transition backdrop-blur opacity-0",
                isDragActive && "opacity-100"
              )}
            >
              <div className="w-full h-full border-4 rounded-lg border-dashed border-gray-300 flex flex-col gap-4 text-center items-center justify-center text-gray-500">
                <TbUpload className="text-5xl" strokeWidth={3} />
                <div className="text-xl font-bold">
                  {t("v4.library.uploadDrop")}
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              {!!uploaded.length && (
                <div className="mx-3 mb-2 flex flex-col">
                  {uploaded.map(
                    ([
                      id,
                      { type, name, progress, data, uploadId, error, ...item },
                    ]) =>
                      !data &&
                      !error &&
                      !uploadId &&
                      ((!folderId && !item.folderId) ||
                        folderId === item.folderId) && (
                        <div
                          key={id}
                          className="flex items-center gap-2 p-1 py-1 rounded-lg animate-pulse"
                        >
                          <div className="w-9 h-9 shrink-0 relative flex items-center justify-center">
                            {type === FileType.Audio ? (
                              <TbVolume
                                className="text-3xl text-gray-500"
                                strokeWidth={1.5}
                              />
                            ) : type === FileType.Video ? (
                              <TbVideo
                                className="text-3xl text-gray-500"
                                strokeWidth={1.5}
                              />
                            ) : type === FileType.Image ? (
                              <TbPhoto
                                className="text-3xl text-gray-500"
                                strokeWidth={1.5}
                              />
                            ) : (
                              <TbFile
                                className="text-3xl text-gray-500"
                                strokeWidth={1.5}
                              />
                            )}
                          </div>
                          <div className="flex flex-col min-w-0 leading-none grow pr-2">
                            <div className="font-bold overflow-hidden overflow-ellipsis whitespace-nowrap">
                              {name}
                            </div>
                            <div className="bg-gray-200 mt-1.5 h-1.5 rounded-full w-full relative overflow-hidden">
                              {progress != null && (
                                <div
                                  className="transition-[width] bg-primary rounded-full h-full"
                                  style={{ width: progress + "%" }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}

              {!list.length ? (
                <div className="text-center text-gray-400 py-5">
                  {t("v4.library.noItems")}
                </div>
              ) : (
                list.map((item) => {
                  const { id, name, thumbnail_url } = item;
                  const type = mapSourceToType(item);
                  return (
                    <div
                      key={item.id + "_" + item.entry_id}
                      onClick={onItemClick(type, id)}
                      className={classNames(
                        "flex items-center mx-3 gap-2 px-1 py-1 rounded-lg bg-gray-800 bg-opacity-0 hover:bg-opacity-5 cursor-pointer transition",
                        type === FileType.Folder && "-order-1"
                      )}
                    >
                      <div className="w-9 h-9 shrink-0 relative flex items-center justify-center">
                        {type === FileType.Folder ? (
                          <TbFolder
                            className="text-3xl text-gray-500"
                            strokeWidth={1.5}
                          />
                        ) : type === FileType.Course ? (
                          <TbBook
                            className="text-3xl text-gray-500"
                            strokeWidth={1.5}
                          />
                        ) : type === FileType.Audio ? (
                          <TbVolume
                            className="text-3xl text-gray-500"
                            strokeWidth={1.5}
                          />
                        ) : (
                          thumbnail_url && (
                            <img
                              src={thumbnail_url}
                              className="absolute-cover object-cover bg-gray-200 rounded-lg overflow-hidden"
                            />
                          )
                        )}
                      </div>
                      <div className="flex flex-col min-w-0 leading-none grow">
                        <div className="font-bold overflow-hidden overflow-ellipsis whitespace-nowrap">
                          {name}
                        </div>
                        <div className="text-xs text-gray-400 overflow-hidden overflow-ellipsis whitespace-nowrap">
                          <span>{mapFileTypeToName(t)(type)}</span>
                          <span> • </span>
                          {type === FileType.Folder
                            ? item.child_count != null && (
                                <span>
                                  {item.child_count}{" "}
                                  {item.child_count === 1
                                    ? t("common.exercises.item.text")
                                    : t("common.exercises.item.plural")}
                                </span>
                              )
                            : item.recent_date && (
                                <span>
                                  {format(item.recent_date, "distance")}
                                </span>
                              )}
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
            {query.hasNextPage && (
              <InView
                as="div"
                className="flex"
                onChange={(inView) =>
                  inView && !query.isFetchingNextPage && query.fetchNextPage()
                }
                key={query.data.pages.length}
              >
                {({ inView, ref }) => (
                  <div
                    ref={ref}
                    className={classNames(
                      "flex flex-col gap-3 transition",
                      !inView && "opacity-0"
                    )}
                  >
                    <Skeleton />
                  </div>
                )}
              </InView>
            )}
          </>
        )}
      </div>
    </div>
  );
};
