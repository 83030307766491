import React, { useMemo } from "react";
import { getNotificationDetails, Notification } from "./Notification";
import { useTranslation } from "react-i18next";

const Notifications = ({ list, close }) => {
  const { t } = useTranslation();

  const isEmpty = useMemo(
    () =>
      list.every((item) => {
        const data = getNotificationDetails(item, t);
        return !data;
      }),
    [list]
  );

  if (isEmpty)
    return (
      <div className="font-bold m-2 text-center text-xs">
        {t("notifications.empty")}
      </div>
    );

  return (
    <>
      {list.map((item) => (
        <Notification key={item?.id} item={item} handleClose={close} />
      ))}
    </>
  );
};

export default Notifications;
