import { useMemo } from "react";

export const SkeletonParagraph = ({ min = 20, max = 40 }) => {
  const list = useMemo(
    () =>
      [...new Array(Math.floor(Math.random() * (max - min) + min))].map(() => [
        Math.floor(Math.random() * 100 + 32),
        Math.floor(Math.random() * -2000),
      ]),
    [min, max]
  );
  return (
    <>
      {list.map(([width, delay], i) => (
        <div
          className="bg-primary bg-opacity-30 inline-flex mx-[0.2em] rounded-full h-[1em] animate-pulse"
          style={{
            width: width + "px",
            animationDuration: "2s",
            animationDelay: delay + "ms",
          }}
          key={"paragraph-" + i}
        />
      ))}
    </>
  );
};
