import { Button } from "app/components/_styled/Button";
import { adminRoutes } from "enums/routes";

const AdminPage = () => {
  return (
    <section id="organizations-managment">
      <div style={{ display: "flex", margin: "80px auto" }}>
        <Button path={adminRoutes.admin_users}>Users</Button>
        <Button path={adminRoutes.admin_organizations}>Organizations</Button>
      </div>
    </section>
  );
};
export default AdminPage;
