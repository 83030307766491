import axios from "axios";
import { apiUrlV1 } from "../enums/constant";
import {
  ILoginAppleForm,
  ILoginForm,
  ILoginGoogleForm,
  ILoginMSForm,
  IRegistrationForm,
} from "../models/api/auth.interface";

export async function postRegistration(formData: IRegistrationForm) {
  const url = `${apiUrlV1}/register/`;
  const { data } = await axios.post<any>(url, {
    email: formData.email,
    password1: formData.password1,
    password2: formData.password2,
    name: formData.name,
  });
  return data;
}

export async function postActivation(d: any) {
  const url = `${apiUrlV1}/activate/`;
  const { data } = await axios.post<any>(url, {
    uid: d.uid,
    token: d.token,
  });
  return data;
}

export async function postResetPassword(d: any) {
  const url = `${apiUrlV1}/reset_password/`;
  const { data } = await axios.post<any>(url, {
    uid: d.uid,
    token: d.token,
    new_password1: d.new_password1,
    new_password2: d.new_password2,
  });
  return data;
}

export async function postResendActivationEmail(email: string) {
  const url = `${apiUrlV1}/email/activation/`;
  const { data } = await axios.post<any>(url, { email });
  return data;
}
export async function postResendActivationEmail_(d: any) {
  const url = `${apiUrlV1}/email/activation/`;
  const { data } = await axios.post<any>(url, {
    email: d.email,
  });
  return data;
}

export async function postForgotPasswordMail(d: any) {
  const url = `${apiUrlV1}/email/reset_password/`;
  const { data } = await axios.post<any>(url, {
    email: d.email,
  });
  return data;
}

export async function postSignIn(formData: ILoginForm) {
  const url = `${apiUrlV1}/login/`;
  const { data } = await axios.post<any>(url, {
    email: formData.email,
    password: formData.password,
  });
  return data;
}

export async function postSignInByGoogle(formData: ILoginGoogleForm) {
  let dataToSend = {};
  if (Object.keys(formData.context).length) {
    dataToSend = {
      access_token: formData.access_token,
      context: formData.context,
    };
  } else {
    dataToSend = {
      access_token: formData.access_token,
    };
  }
  const url = `${apiUrlV1}/google/login/`;
  const { data } = await axios.post<any>(url, dataToSend);
  return data;
}

export async function postSignInByApple(formData: ILoginAppleForm) {
  let dataToSend = {};
  if (Object.keys(formData.context).length) {
    dataToSend = {
      access_token: formData.access_token,
      user: formData.user,
      context: formData.context,
    };
  } else {
    dataToSend = {
      access_token: formData.access_token,
      user: formData.user,
    };
  }
  const url = `${apiUrlV1}/apple/login/`;
  const { data } = await axios.post<any>(url, dataToSend);
  return data;
}

export async function postSignInByMS(formData: ILoginMSForm) {
  let dataToSend = {};
  if (Object.keys(formData.context).length) {
    dataToSend = {
      access_token: formData.access_token,
      context: formData.context,
    };
  } else {
    dataToSend = {
      access_token: formData.access_token,
    };
  }
  const url = `${apiUrlV1}/microsoft/login/`;
  const { data } = await axios.post<any>(url, dataToSend);
  return data;
}

export async function postLogout() {
  const url = `${apiUrlV1}/logout/`;
  const { data } = await axios.post<any>(url);
  return data;
}

export async function postVerify2FACode(code: string) {
  const url = `${apiUrlV1}/2fa/${code}/`;
  const { data } = await axios.post<any>(url);
  return data;
}

export async function postGenerate2FACode() {
  const url = `${apiUrlV1}/2fa/`;
  const { data } = await axios.post<any>(url);
  return data;
}
