import {
  PageItemType,
  SlideItem,
  SlidePage,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import React, { useRef } from "react";
import useResizeObserver from "use-resize-observer";
import {
  SLIDE_COLS,
  SLIDE_HEIGHT,
  SLIDE_ROWS,
  SLIDE_WIDTH,
} from "app/components/Exercises/Edit/questionType/Slide/slideUtils";
import { SlideImageRender } from "app/components/Exercises/CourseEdit/slide/items/SlidePageImage";
import { SlideVideoRender } from "app/components/Exercises/CourseEdit/slide/items/SlidePageVideo";
import { SlideAudioRender } from "app/components/Exercises/CourseEdit/slide/items/SlidePageAudio";
import { SlideListRender } from "app/components/Exercises/CourseEdit/slide/items/SlidePageList";
import { SlideParagraphRender } from "app/components/Exercises/CourseEdit/slide/items/SlidePageParagraph";
import { SlideEmbedRender } from "app/components/Exercises/CourseEdit/slide/items/SlidePageEmbed";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch";
import { compareMargin } from "helpers/compareMargin";
import { NewButton } from "app/components/Buttons/NewButton";
import classNames from "classnames";
import { TbFocusCentered } from "react-icons/tb";
import { useTranslation } from "react-i18next";

export const SlideRender = ({ page }: { page: SlidePage }) => {
  const { t } = useTranslation();
  const { ref, width, height } = useResizeObserver<HTMLDivElement>();
  const transformRef = useRef<ReactZoomPanPinchRef | null>(null);

  const initialScale = Math.min(
    (width || 0) / SLIDE_WIDTH,
    (height || 0) / SLIDE_HEIGHT
  );

  return (
    <div className="absolute-cover flex flex-col text-gray-700" ref={ref}>
      {initialScale < 0.9 && height != null && width != null ? (
        <TransformWrapper
          initialScale={initialScale * 0.9}
          minScale={initialScale * 0.5}
          maxScale={initialScale * 5}
          centerZoomedOut
          centerOnInit
          doubleClick={{ disabled: true }}
          panning={{ excluded: ["panningDisabled"] }}
          ref={transformRef}
        >
          {({ state, centerView }) => {
            const scale = initialScale * 0.9;
            const hideCenterButton =
              compareMargin(state.scale, scale) &&
              compareMargin(
                state.positionX,
                (width - SLIDE_WIDTH * scale) / 2
              ) &&
              compareMargin(
                state.positionY,
                (height - SLIDE_HEIGHT * scale) / 2
              );
            return (
              <>
                <TransformComponent
                  wrapperStyle={{
                    height: "100%",
                    width: "100%",
                    overflow: "visible",
                  }}
                >
                  <div
                    style={{
                      width: 1280,
                      height: 720,
                    }}
                    className="shadow-2xl rounded-2xl bg-white"
                  >
                    <SlideRenderGrid items={page.items} />
                  </div>
                </TransformComponent>
                <NewButton
                  onClick={(e) => {
                    e.preventDefault();
                    centerView(initialScale * 0.9);
                  }}
                  className={classNames(
                    "absolute bottom-2 right-2 transition",
                    hideCenterButton
                      ? "opacity-0 pointer-events-none"
                      : "delay-100 animate-fadeIn"
                  )}
                  variant="primary"
                  color="bg-gray-100 text-gray-600 hover:bg-gray-200 border border-gray-300 shadow-lg"
                >
                  <TbFocusCentered />
                  {t("common.exercises.diagram.center")}
                </NewButton>
              </>
            );
          }}
        </TransformWrapper>
      ) : (
        <div
          style={{
            width: 1280,
            height: 720,
            transform: `translate(-50%, -50%) scale(${initialScale * 0.95})`,
          }}
          className="absolute left-1/2 top-1/2 shadow-2xl rounded-2xl bg-white"
        >
          <SlideRenderGrid items={page.items} />
        </div>
      )}
    </div>
  );
};

export const SlideRenderGrid = ({ items }: { items: SlideItem[] }) => (
  <div
    className="grid absolute-cover text-gray-600"
    style={{
      gridTemplateColumns: `repeat(${SLIDE_COLS}, 1fr)`,
      gridTemplateRows: `repeat(${SLIDE_ROWS}, 1fr)`,
      padding: 20,
      gap: 10,
    }}
  >
    {items.length === 1 &&
    items[0].position.w === SLIDE_COLS &&
    items[0].position.h === SLIDE_ROWS &&
    [PageItemType.Image, PageItemType.Video, PageItemType.Embed].includes(
      items[0].type
    ) ? (
      <div className="position-absolute rounded-2xl">
        <SlideItemRender item={items[0]} />
      </div>
    ) : (
      items.map((item) => (
        <div
          className="rounded-2xl relative overflow-hidden border-2 border-transparent"
          key={item.id}
          style={{
            gridColumnStart: item.position.x + 1,
            gridColumnEnd: `span ${item.position.w}`,
            gridRowStart: item.position.y + 1,
            gridRowEnd: `span ${item.position.h}`,
          }}
        >
          <SlideItemRender item={item} />
        </div>
      ))
    )}
  </div>
);

export const SlideItemRender = ({ item }: { item: SlideItem }) => {
  const { type } = item;
  if (type === PageItemType.Paragraph)
    return <SlideParagraphRender item={item} />;
  if (type === PageItemType.List) return <SlideListRender item={item} />;
  if (type === PageItemType.Image) return <SlideImageRender item={item} />;
  if (type === PageItemType.Video) return <SlideVideoRender item={item} />;
  if (type === PageItemType.Audio) return <SlideAudioRender item={item} />;
  if (type === PageItemType.Embed) return <SlideEmbedRender item={item} />;

  return null;
};
