import { createPortal } from "react-dom";
import React, { ReactNode } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbX } from "react-icons/tb";

export const BigModal = ({
  children,
  className,
  fit = false,
  open,
  stopPropagation: stop = false,
}: {
  children: ReactNode;
  className?: string | any;
  fit?: boolean;
  open?: boolean;
  stopPropagation?: boolean;
}) => {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  const modal = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ opacity: { duration: 0.1 } }}
      className={classNames(
        "fixed w-full h-full z-40 left-0 top-0 flex flex-col p-2 md:p-8",
        className
      )}
      {...(stop && {
        onClick: stopPropagation,
        onPointerDown: stopPropagation,
        onPointerDownCapture: stopPropagation,
        onMouseDown: stopPropagation,
      })}
    >
      <div className="bg-black bg-opacity-40 absolute-cover" />
      <div
        className={classNames(
          "max-w-5xl m-auto bg-white h-min max-h-full rounded-xl shadow-xl relative flex flex-col",
          !fit && "w-full grow"
        )}
      >
        {children}
      </div>
    </motion.div>
  );
  if (open == null) return createPortal(modal(), document.body);

  return createPortal(
    <AnimatePresence initial={false}>{open && modal()}</AnimatePresence>,
    document.body
  );
};

export const BigModalClose = ({ close }) => (
  <NewButton variant="transparent" className="ml-auto" iconOnly onClick={close}>
    <TbX />
  </NewButton>
);

export const BigModalHeader = ({ className = "", children }) => (
  <div
    className={classNames(
      "p-1 font-bold border-b-2 border-gray-100 flex items-center gap-2 text-gray-500",
      className
    )}
  >
    {children}
  </div>
);

export const BigModalBody = ({
  className = "",
  children,
  maxWidth = false,
  maxHeight = false,
}) => (
  <div
    className={classNames(
      "flex flex-col grow p-4",
      maxWidth && "w-[calc(100vw_-_2rem)] md:w-[calc(100vw_-_4rem)]",
      maxHeight && "h-[calc(100vh_-_3rem)] md:h-[calc(100vh_-_6rem)]",
      className
    )}
  >
    {children}
  </div>
);

export const BigModalFooter = ({ className = "", children }) => (
  <div
    className={classNames(
      "p-1 font-bold border-t-2 border-gray-100 flex items-center gap-1 text-gray-500",
      className
    )}
  >
    {children}
  </div>
);
