import React, {
  Fragment,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Collapse } from "app/components/Collapse";
import {
  TbAlertTriangle,
  TbArrowLeft,
  TbArrowRight,
  TbArrowsHorizontal,
  TbChevronDown,
  TbFileText,
  TbFolders,
  TbInfoCircle,
  TbRectangle,
  TbRefresh,
  TbSend,
  TbWand,
  TbWriting,
  TbX,
} from "react-icons/tb";
import { NewButton } from "app/components/Buttons/NewButton";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import {
  useSourceUploadContext,
  useSourceUploadStore,
} from "api/ws/SourceUploadContext";
import {
  BigModal,
  BigModalBody,
  BigModalHeader,
} from "app/components/BigModal";
import { IconType } from "react-icons";
import { FloatingMenu } from "app/components/Header";
import { useMutation, useQuery } from "react-query";
import {
  getSource,
  getVideoTranscription,
  postPageValid,
} from "api/sourcesAPI";
import { toast } from "react-hot-toast";
import {
  MediaPicker,
  SelectOnlyType,
} from "app/components/Sources/MediaPicker/MediaPicker";
import { CgSpinner } from "react-icons/cg";
import { TRANSLATE_LANGS } from "app/components/Exercises/CourseEdit/components/generate/TextGenerate";
import { Range } from "react-range";
import useOnClickOutside from "use-onclickoutside";
import { TooltipRaw } from "app/components/Tooltip";
import { DocumentPage } from "app/components/Sources/MediaPicker/SingleSource/SingleSource";
import {
  StyledHandle,
  StyledTrack,
} from "app/components/Exercises/Edit/questionType/Info/InfoDocumentModal";
import { useSelector } from "react-redux";
import { selectUserDetails } from "app/store/user";
import { useGlobalQuery } from "app/App";
import { motion } from "framer-motion";
import AutosizeInput from "react-input-autosize";
import smartestLogoIcon from "assets/images/logo.svg";
import avatarImage from "assets/images/avatar-40-x-40.png";
import { formatDuration } from "date-fns";
import { getDateLocale } from "app/hooks/useDateFormat";
import { FileType, mapMime } from "helpers/mimeType";
import { AudioPlayer, VideoPlayer } from "app/components/MediaPlayer";
import {
  postGenerateOutline,
  postUpdateOutline,
  SlideOutline,
} from "../../../../api/course/courseAPI";

export enum ContentType {
  SlideOutline = "slides_outline",
  Page = "page",
}

export enum Size {
  Off = "off",
  // Small = "small",
  Medium = "medium",
  // Large = "large",
}

export const Dropdown = <T extends string>({
  value,
  onChange,
  items,
  active,
  fit = false,
}: {
  value: T;
  onChange: (value: T) => void;
  items: Record<T, { name: string; icon?: IconType }>;
  active?: boolean;
  fit?: boolean;
}) => {
  const list = useMemo(
    () => Object.entries(items) as [T, typeof items[T]][],
    [items]
  );
  const { icon: Icon, name } = items[value];

  return (
    <FloatingMenu
      size="xs"
      placement="bottom-end"
      trigger={(toggle) => (
        <NewButton
          size="sm"
          onClick={toggle}
          className="font-bold"
          color={active ? "bg-primary text-primary" : undefined}
        >
          {fit ? (
            <>
              {Icon && <Icon className="text-lg" />}
              {name}
            </>
          ) : (
            <div className="grid grid-cols-1 grid-rows-1">
              {list.map(([key, { name, icon: Icon }]) => (
                <span
                  className={classNames(
                    "mx-auto flex items-center gap-1.5 text-sm",
                    key !== value && "opacity-0"
                  )}
                  style={{
                    gridColumn: "1 / span 1",
                    gridRow: "1 / span 1",
                  }}
                  key={key}
                >
                  {Icon && <Icon className="text-base" />}
                  {name}
                </span>
              ))}
            </div>
          )}
          <TbChevronDown className="ml-auto" />
        </NewButton>
      )}
    >
      {({ setIsOpen }) => (
        <>
          {list.map(([key, { name, icon: Icon }]) => (
            <NewButton
              variant={key === value ? "light" : "transparent"}
              color={key === value ? "bg-primary text-primary" : undefined}
              key={key}
              onClick={() => {
                onChange(key);
                setIsOpen(false);
              }}
            >
              {Icon && <Icon className="-ml-1" />}
              {name}
            </NewButton>
          ))}
        </>
      )}
    </FloatingMenu>
  );
};
const DropdownBox = ({
  children,
  onClick,
}: PropsWithChildren<{ onClick?: () => void }>) => (
  <div
    onClick={onClick}
    className="font-bold text-primary bg-primary bg-opacity-20 px-1.5 rounded-lg transition hover:opacity-50 cursor-pointer inline-flex items-center gap-1"
  >
    {children}
  </div>
);
const InlineDropdown = <T extends string>({
  value,
  onChange,
  items,
  suffix = "",
}: {
  value: T;
  onChange: (value: T) => void;
  items: Record<T, { name: string; icon?: IconType }>;
  suffix?: string;
}) => {
  const list = useMemo(
    () => Object.entries(items) as [T, typeof items[T]][],
    [items]
  );
  const { icon: Icon, name } = items[value];
  return (
    <FloatingMenu
      portal
      size="xs"
      containerClassName="inline-flex"
      placement="bottom-end"
      trigger={(toggle) => (
        <DropdownBox onClick={toggle}>
          {Icon && <Icon className="text-lg" />}
          {name + suffix}
        </DropdownBox>
      )}
    >
      {({ setIsOpen }) => (
        <>
          {list.map(([key, { name, icon: Icon }]) => (
            <NewButton
              variant={key === value ? "light" : "transparent"}
              color={key === value ? "bg-primary text-primary" : undefined}
              key={key}
              onClick={() => {
                onChange(key);
                setIsOpen(false);
              }}
            >
              {Icon && <Icon className="-ml-1" />}
              {name + suffix}
            </NewButton>
          ))}
        </>
      )}
    </FloatingMenu>
  );
};

export enum GenerateType {
  None,
  Library,
  Prompt,
}

const MessageContainer = ({
  children,
  received = false,
  full = false,
  options,
  delay = 0,
  skipAnimation = false,
}: PropsWithChildren<{
  received?: boolean;
  full?: boolean;
  options?: ReactNode;
  delay?: number;
  skipAnimation?: boolean;
}>) => {
  const userDetails = useSelector(selectUserDetails);
  const globalQuery = useGlobalQuery();

  return (
    <motion.div
      {...(!skipAnimation
        ? {
            initial: { opacity: 0, height: 0 },
            animate: { opacity: 1, height: "auto" },
            transition: {
              delay: delay * 1,
              delayChildren: delay * 1,
              duration: 0.5,
              opacity: {
                delay: delay * 1 + 0.25,
                duration: 0.3,
              },
            },
          }
        : { initial: { opacity: 1, height: "auto" } })}
      className={classNames(
        "flex items-end gap-2",
        received ? "pr-[5%] md:pr-[10%]" : "pl-[5%] md:pl-[10%]"
      )}
    >
      <motion.div
        {...(!skipAnimation
          ? {
              initial: { translateY: "100%" },
              animate: { translateY: 0 },
              transition: {
                delay: delay * 1,
                delayChildren: delay * 1,
                duration: 0.5,
              },
            }
          : { initial: { translateY: 0 } })}
        className="flex items-end grow pt-6 gap-2"
      >
        {received ? (
          <>
            <div className="w-10 h-10 rounded-full bg-primary bg-opacity-20 relative p-2 shrink-0">
              <div className="w-full h-full relative">
                <img
                  src={
                    globalQuery.data?.small_logo_download_url ||
                    smartestLogoIcon
                  }
                  alt="Logo"
                  className="absolute-cover object-contain"
                />
              </div>
            </div>
            <div
              className={classNames(
                "grow flex flex-col gap-1",
                !full && "items-start"
              )}
            >
              {children && (
                <div className="rounded-2xl py-2 px-4 bg-primary bg-opacity-20 rounded-bl-md relative break-words">
                  {children}
                </div>
              )}
              {options && (
                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-1">
                  {options}
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div
              className={classNames(
                "grow flex flex-col gap-1",
                !full && "items-end"
              )}
            >
              {children && (
                <div className="rounded-2xl py-2 px-4 bg-gray-200 rounded-br-md relative text-right break-words">
                  {children}
                </div>
              )}
              {options && (
                <div className="flex flex-col sm:flex-row items-end sm:items-center gap-1 justify-end">
                  {options}
                </div>
              )}
            </div>
            <div className="w-10 h-10 rounded-full bg-gray-200 relative p-2 shrink-0">
              <img
                src={userDetails.picture || avatarImage}
                alt="User"
                className="absolute-cover object-cover rounded-full"
              />
            </div>
          </>
        )}
      </motion.div>
    </motion.div>
  );
};
export const GenerateTraining = ({ close, trainingId = "" }) => {
  const { t } = useTranslation();
  const [generateType, setGenerateType] = useState(GenerateType.None);
  const { startTask } = useSourceUploadContext();

  const [content, setContent] = useState<Size>(Size.Medium);
  const [contentType, setContentType] = useState(ContentType.SlideOutline);
  const [quiz, setQuiz] = useState<Size>(Size.Medium);

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (data) => {
    startTask("generate_slides", {
      ...(trainingId && { training_id: trainingId }),
      content_volume: content,
      exercise_volume: quiz,
      training_type:
        contentType === ContentType.Page
          ? "pages"
          : // : contentType === ContentType.Slide
            // ? "slides"
            "slides_from_outline",
      ...data,
    });
    close();
  };

  return (
    <BigModal fit>
      <BigModalHeader className="leading-none">
        <TbWand className="ml-2 shrink-0" />
        {t("v4.training.generate.text")}
        <NewButton
          variant="transparent"
          className="ml-auto"
          iconOnly
          onClick={close}
        >
          <TbX />
        </NewButton>
      </BigModalHeader>
      <BigModalBody
        maxHeight
        maxWidth
        className="text-gray-600 max-w-lg max-h-[75vh] sm:max-h-[min(66vh,35em)] xl:max-h-[min(66vh,45em)] overflow-y-auto !flex-col-reverse"
      >
        <div className="flex flex-col mt-auto">
          <MessageContainer
            options={
              !submitted && (
                <>
                  {/*<GenerateTimeEstimate*/}
                  {/*  time={typeof time === "function" ? time?.(content, quiz) : time}*/}
                  {/*/>*/}
                  {/*<span className="text-center mt-2 text-sm text-gray-500">*/}
                  {/*    {t("v4.generate.timeToGenerate")}*/}
                  {/*  <b>*/}
                  {/*      {formatDuration(duration, { locale: getDateLocale(language) })}*/}
                  {/*    </b>*/}
                  {/*  </span>*/}
                  <NewButton
                    variant="primary"
                    disabled={content === Size.Off && quiz === Size.Off}
                    onClick={() => setSubmitted(true)}
                  >
                    <TbSend /> {t("v4.generic.send")}
                  </NewButton>
                </>
              )
            }
          >
            {t("v4.chatUI.optionsPlaceholder_1")}
            <InlineDropdown
              value={contentType}
              onChange={setContentType}
              items={{
                [ContentType.SlideOutline]: {
                  name: t("v4.slide.plural"),
                  icon: TbRectangle,
                },
                // [ContentType.Slide]: {
                //   name: `${t("v4.slide.plural")} (no outline)`,
                //   icon: TbRectangle,
                // },
                [ContentType.Page]: {
                  name: t("v4.page.plural"),
                  icon: TbFileText,
                },
              }}
            />
            {t("v4.chatUI.optionsPlaceholder_2")}
            <InlineDropdown
              value={quiz}
              onChange={setQuiz}
              items={{
                [Size.Off]: {
                  name: t("v4.quiz.noQuizzes"),
                },
                [Size.Medium]: {
                  name: t("v4.quiz.plural"),
                },
              }}
            />
          </MessageContainer>

          {submitted && (
            <>
              <MessageContainer received>
                {t("v4.chatUI.typePrompt")}
              </MessageContainer>
              <MessageContainer
                delay={1}
                options={
                  <>
                    <NewButton
                      center
                      variant={
                        generateType === GenerateType.Library
                          ? "primary"
                          : undefined
                      }
                      onClick={() => setGenerateType(GenerateType.Library)}
                    >
                      <TbFolders /> {t("v4.library.source")}
                    </NewButton>
                    <NewButton
                      center
                      variant={
                        generateType === GenerateType.Prompt
                          ? "primary"
                          : undefined
                      }
                      onClick={() => setGenerateType(GenerateType.Prompt)}
                    >
                      <TbWriting /> {t("v4.generate.prompt.text")}
                    </NewButton>
                  </>
                }
              >
                {t("v4.chatUI.generateFromPrompt")}
              </MessageContainer>
              {generateType === GenerateType.Prompt &&
                (contentType === ContentType.SlideOutline ? (
                  <GenerateOutlinePrompt
                    onSubmit={handleSubmit}
                    contentVolume={content}
                    quizVolume={quiz}
                  />
                ) : (
                  <GeneratePrompt onSubmit={handleSubmit} />
                ))}

              {generateType === GenerateType.Library && (
                <GenerateLibrary
                  close={close}
                  onSubmit={handleSubmit}
                  back={() => setGenerateType(GenerateType.None)}
                />
              )}
            </>
          )}
        </div>
      </BigModalBody>
    </BigModal>
  );
};

enum SourceFilter {
  None,
  Keywords,
  Range,
}

const useSourceValidity = (
  sourceId: string | false | null,
  start = 1,
  end = 1
) => {
  return useQuery(
    ["sourceValid", sourceId, start, end],
    () => postPageValid({ start, end, sourceId: sourceId || "" }),
    {
      enabled: !!sourceId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      cacheTime: Infinity,
      retryOnMount: false,
    }
  );
};
const GenerateLibrary = ({ close, back, onSubmit }) => {
  const { t } = useTranslation();
  const [sourceId, setSourceId] = useState<string | null>(null);

  const sourceQuery = useQuery({
    queryKey: ["source", sourceId],
    queryFn: () => getSource(sourceId),
    enabled: !!sourceId,
    onError: () => {
      toast.error(t("v4.chatUI.noSource"));
      setSourceId(null);
    },
    refetchOnWindowFocus: false,
  });

  const pages: number = sourceQuery.data?.page_count;

  if (!sourceId)
    return (
      <MediaPicker
        close={back}
        onInsert={({ id }) => setSourceId(id)}
        selectOnly={SelectOnlyType.source}
      />
    );

  const loading = (
    <MessageContainer>
      <CgSpinner className="animate-spin text-xl" />
    </MessageContainer>
  );

  if (!sourceQuery.isSuccess) return loading;

  const generate = (data: Record<string, any>) => {
    close();
    onSubmit({
      source_id: sourceId,
      ...data,
    });
  };

  const mime = mapMime(sourceQuery.data.mime_type);
  const isMedia = mime === FileType.Audio || mime === FileType.Video;

  return (
    <>
      <MessageContainer
        full={isMedia}
        options={
          <>
            <NewButton onClick={() => setSourceId(null)}>
              <TbRefresh /> {t("v4.generic.change")}
            </NewButton>
          </>
        }
      >
        <div className="flex flex-col -mx-2">
          {mime === FileType.Audio ? (
            <AudioPlayer src={sourceQuery.data.download_url} />
          ) : mime === FileType.Video ? (
            <VideoPlayer src={sourceQuery.data.download_url} />
          ) : (
            <div className="min-h-[4rem] min-w-[4rem] relative flex">
              <div className="absolute-cover flex items-center justify-center">
                <CgSpinner className="animate-spin text-lg m-1 text-gray-400" />
              </div>
              <img
                className="max-w-[12rem] max-h-[15rem] rounded-lg relative"
                src={sourceQuery.data.image_url}
              />
            </div>
          )}
        </div>
      </MessageContainer>
      {isMedia ? (
        <GenerateMediaFile
          sourceId={sourceId}
          onSubmit={generate}
          transcriptId={sourceQuery.data.transcription_id}
        />
      ) : pages <= 10 ? (
        <GenerateLowPageDocument
          sourceId={sourceId}
          pages={pages}
          onSubmit={generate}
        />
      ) : (
        <GenerateMultiPageDocument
          sourceId={sourceId}
          pages={pages}
          onSubmit={generate}
        />
      )}
    </>
  );
};
//
const GenerateMediaFile = ({ sourceId, onSubmit, transcriptId }) => {
  const { t } = useTranslation();
  const { startTranscript, resetTranscript } = useSourceUploadContext();

  const transcript = useSourceUploadStore(
    (store) => store.transcript?.[sourceId]
  );

  const transcription = useQuery({
    queryKey: ["sourceTranscription", sourceId],
    queryFn: () => getVideoTranscription(transcriptId),
    enabled: !!transcriptId,
    refetchOnWindowFocus: false,
  });

  const text = transcript?.data?.text || transcription?.data?.text;

  const validity = useQuery(["valid", text], () => postPageValid({ text }), {
    enabled: text != null,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: Infinity,
    retryOnMount: false,
  });

  const time = useMemo(() => {
    if (!validity.data?.result) return undefined;
    const words = (text || "").split(/\s+/).length / 500;
    return Math.round(words) * 30;
  }, [text, !!validity.data?.result]);

  if (text == null) {
    if (transcript?.error)
      return (
        <MessageContainer
          options={
            <NewButton
              onClick={() => {
                resetTranscript(sourceId);
                startTranscript(sourceId);
              }}
            >
              <TbRefresh /> {t("v4.generic.tryAgain")}
            </NewButton>
          }
          received
        >
          {t("v4.library.transcript.generateError")}
        </MessageContainer>
      );

    if (transcript?.progress != null)
      return (
        <MessageContainer received>
          <div className="flex flex-col">
            <span>{t("v4.library.transcript.generating")}</span>
            <div className="mt-1 h-1 grow rounded-full relative overflow-hidden bg-white">
              <div
                className="absolute-cover bg-primary origin-left transition"
                style={{
                  transform: `scaleX(${(transcript?.progress ?? 0) / 100})`,
                }}
              />
            </div>
          </div>
        </MessageContainer>
      );

    return (
      <>
        <MessageContainer
          options={
            <NewButton onClick={() => startTranscript(sourceId)}>
              <TbWand /> {t("v4.generate.text")}
            </NewButton>
          }
          received
        >
          {t("v4.library.transcript.noData")}
        </MessageContainer>
      </>
    );
  }

  if (!validity.isSuccess)
    return (
      <MessageContainer received>
        <CgSpinner className="animate-spin text-xl" />
      </MessageContainer>
    );

  if (!validity.data?.result)
    return (
      <MessageContainer received>
        {t("v4.chatUI.notEnoughContent")}
      </MessageContainer>
    );

  return (
    <>
      <MessageContainer received>{t("v4.chatUI.validSource")}</MessageContainer>
      <MessageContainer
        delay={1}
        options={
          <NewButton
            variant="primary"
            onClick={() => {
              onSubmit();
              // setPromptSubmitted(true);
            }}
          >
            <TbWand /> {t("v4.generate.text")}
          </NewButton>
        }
      />
    </>
  );
};

const GenerateMultiPageDocument = ({ sourceId, pages, onSubmit }) => {
  const { t } = useTranslation();
  const [sourceFilter, setSourceFilter] = useState(SourceFilter.None);

  return (
    <>
      <MessageContainer received>
        {t("v4.chatUI.longSourcePrompt")}
      </MessageContainer>
      <MessageContainer
        delay={1}
        options={
          <>
            <NewButton
              variant={
                sourceFilter === SourceFilter.Keywords ? "primary" : "light"
              }
              onClick={() => setSourceFilter(SourceFilter.Keywords)}
            >
              <TbWriting /> {t("v4.chatUI.keywords")}
            </NewButton>
            <NewButton
              variant={
                sourceFilter === SourceFilter.Range ? "primary" : "light"
              }
              onClick={() => setSourceFilter(SourceFilter.Range)}
            >
              <TbArrowsHorizontal /> {t("v4.chatUI.pageRange")}
            </NewButton>
          </>
        }
      >
        {t("v4.chatUI.pageFilterPrompt")}
      </MessageContainer>
      {sourceFilter === SourceFilter.Range && (
        <GenerateSourceRange
          onSubmit={onSubmit}
          sourceId={sourceId}
          pages={pages}
        />
      )}
      {sourceFilter === SourceFilter.Keywords && (
        <GenerateSourcePrompt onSubmit={onSubmit} />
      )}
    </>
  );
};
const GenerateLowPageDocument = ({ pages, sourceId, onSubmit }) => {
  const { t } = useTranslation();
  const validity = useSourceValidity(sourceId, 1, pages);

  if (!validity.isSuccess)
    return (
      <MessageContainer received>
        <CgSpinner className="animate-spin text-xl" />
      </MessageContainer>
    );

  if (!validity.data?.result)
    return (
      <MessageContainer received>
        {t("v4.chatUI.notEnoughContent")}
      </MessageContainer>
    );

  return (
    <>
      <MessageContainer received>{t("v4.chatUI.validSource")}</MessageContainer>
      <MessageContainer
        delay={1}
        options={
          <NewButton
            variant="primary"
            onClick={() => {
              onSubmit();
              // setPromptSubmitted(true);
            }}
          >
            <TbWand /> {t("v4.generate.text")}
          </NewButton>
        }
      />
    </>
  );
};
const PageSelector = ({
  max,
  sourceId,
  value,
  setValue,
}: {
  max: number;
  sourceId: string;
  value: number;
  setValue: (value: number) => void;
}) => {
  const rangeRef: any = React.useRef<Range>();
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const [innerValue, setInnerValue] = useState(value);
  const [text, setText] = useState(`${innerValue}`);

  const handleValue = (value: number) => {
    setText(`${value}`);
    setInnerValue(value);
  };

  const handleTextValue = (text: string) => {
    setText(text);

    const value = Number(text);
    if (!value) return;
    if (value < 1) return setInnerValue(1);
    if (value > max) return setInnerValue(max);
    setInnerValue(value);
  };

  useOnClickOutside(ref, () => setOpen(false));

  useEffect(() => {
    if (!open) setValue(innerValue);
  }, [open]);

  useEffect(() => {
    handleValue(value);
  }, [value]);

  return (
    <div className="inline-flex relative" ref={ref}>
      <TooltipRaw
        visible={open}
        offset={[0, 8]}
        placement="top"
        interactive
        trigger={undefined}
        content={
          <div className="flex flex-col bg-white border rounded-lg shadow-lg text-center p-1 gap-2">
            <div className="w-56 h-56 bg-gray-100 rounded-lg relative flex items-center justify-center">
              <DocumentPage sourceId={sourceId} page={innerValue} />
            </div>
            <div className="flex gap-2 items-center">
              <NewButton
                iconOnly
                onClick={() => handleValue(Math.max(innerValue - 1, 1))}
              >
                <TbArrowLeft />
              </NewButton>
              <div className="grow -mb-6 -my-10">
                <Range
                  draggableTrack
                  allowOverlap
                  ref={rangeRef}
                  values={[innerValue]}
                  step={1}
                  min={1}
                  max={max}
                  onChange={([value]) => handleValue(value)}
                  renderTrack={({ props, children }) => (
                    <StyledTrack
                      className="cursor-ew-resize"
                      {...props}
                      min={1}
                      max={max}
                      values={innerValue}
                    >
                      {children}
                    </StyledTrack>
                  )}
                  renderThumb={({ props, isDragged }) => (
                    <StyledHandle {...props} {...{ isDragged }} />
                  )}
                />
              </div>
              <NewButton
                iconOnly
                onClick={() => handleValue(Math.min(innerValue + 1, max))}
              >
                <TbArrowRight />
              </NewButton>
            </div>
          </div>
        }
      >
        <div className="inline-flex relative">
          <AutosizeInput
            value={text || ""}
            inputClassName="text-center outline-0 relative font-bold text-primary bg-primary bg-opacity-20 px-1.5 rounded-lg transition inline-flex items-center gap-1"
            onChange={(e) => handleTextValue(e.target.value.substring(0, 4))}
            type="number"
            maxLength={4}
            minWidth={36}
            onFocus={() => setOpen(true)}
            onBlur={() => setText(`${innerValue}`)}
          />
        </div>
      </TooltipRaw>
    </div>
  );
};
const GenerateSourceRange = ({
  pages,
  sourceId,
  onSubmit,
}: {
  pages: number;
  sourceId: string;
  onSubmit: (data: { start_page: number; end_page: number }) => void;
}) => {
  const { t } = useTranslation();
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(Math.min(pages, 11));

  const validity = useSourceValidity(sourceId, start, end);

  return (
    <>
      <MessageContainer
        options={
          <div className="flex flex-col gap-1 text-right items-end">
            <Collapse>
              {validity.isSuccess &&
                !validity.isFetching &&
                !validity.data.result && (
                  <div className="text-red-500 text-sm mb-1">
                    <TbAlertTriangle className="text-base inline mr-1" />
                    {t("v4.chatUI.notEnoughContentRange")}
                  </div>
                )}
            </Collapse>
            <div className="flex items-center gap-2">
              <GenerateTimeEstimate time={(end - start + 1) * 30} />
              <NewButton
                variant="primary"
                disabled={!validity.isSuccess || !validity?.data.result}
                onClick={() => {
                  onSubmit({
                    start_page: start,
                    end_page: end,
                  });
                  // setRangeSubmitted(true);
                }}
              >
                <TbWand /> {t("v4.generate.text")}
              </NewButton>
            </div>
          </div>
        }
      >
        {t("v4.chatUI.pagesFrom")}
        <PageSelector
          max={pages}
          sourceId={sourceId}
          value={start}
          setValue={(start) => {
            setStart(start);
            if (start >= end) return setEnd(start);
            if (end - start > 19) return setEnd(start + 19);
          }}
        />
        {t("v4.chatUI.to")}
        <PageSelector
          max={pages}
          sourceId={sourceId}
          value={end}
          setValue={(end) => {
            setEnd(end);
            if (end <= start) return setStart(end);
            if (end - start > 19) return setStart(end - 19);
          }}
        />
      </MessageContainer>
    </>
  );
};
const GenerateSourcePrompt = ({
  onSubmit,
}: {
  onSubmit: (data: { focus: string }) => void;
}) => {
  const { t } = useTranslation();
  const [prompt, setPrompt] = useState("");

  return (
    <>
      <MessageContainer
        full
        options={
          <>
            <div className="flex grow self-stretch gap-2 text-sm text-gray-400 relative mb-auto">
              <div
                className={classNames(
                  "transition sm:absolute-cover",
                  prompt.length >= 5 && "opacity-0"
                )}
              >
                {t("v4.generate.prompt.minLength", { number: 5 })}
              </div>
              <div
                className={classNames(
                  "whitespace-nowrap absolute-cover transition",
                  prompt.length < 5 && "opacity-0"
                )}
              >
                {prompt.length} / 100
              </div>
            </div>
            <NewButton
              variant="primary"
              disabled={prompt.length < 5}
              onClick={() => {
                onSubmit({ focus: prompt });
                // setPromptSubmitted(true);
              }}
            >
              <TbWand /> {t("v4.generate.text")}
            </NewButton>
          </>
        }
      >
        <div className="w-full h-full flex">
          <TextareaAutosize
            autoFocus
            className="min-h-full -mx-4 -my-2 px-4 py-2 bg-transparent grow resize-none outline-0"
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                // setPromptSubmitted(true);
              }
            }}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value.substring(0, 100))}
            placeholder={t("v4.chatUI.keywordsPlaceholder")}
          />
        </div>
      </MessageContainer>
    </>
  );
};

interface GenerateOptions {
  content_volume: Size;
  exercise_volume: Size;
  training_type: string;
}

const GenerateTimeEstimate = ({ time }: { time?: number }) => {
  const { t, i18n } = useTranslation();

  const duration = useMemo(() => {
    if (time == null) return null;
    if (time <= 45) return { seconds: 30 };
    return { minutes: Math.floor(time / 30) / 2 };
  }, [time]);

  if (!duration) return null;

  return (
    <span className="text-xs opacity-50 mr-2 text-right">
      {t("v4.chatUI.approximately")}
      {formatDuration(duration, {
        locale: getDateLocale(i18n.language),
      })}
    </span>
  );
};

const GeneratePrompt = ({ onSubmit }) => {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language || "en");
  const [prompt, setPrompt] = useState("");

  return (
    <>
      <MessageContainer received>
        {t("v4.chatUI.promptPrompt")}
      </MessageContainer>
      <MessageContainer
        delay={prompt ? 0 : 1}
        full
        options={
          <div className="flex flex-col gap-1 w-full">
            <div className="text-xs text-gray-400 bg-primary bg-opacity-10 rounded-lg px-1.5 py-0.5">
              <TbInfoCircle className="text-sm mr-1 inline -mt-0.5" />
              {t("v4.generate.prompt.lengthTip")}
            </div>
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-1">
              <div className="flex grow gap-2 text-sm text-gray-400 relative items-start self-stretch">
                <div
                  className={classNames(
                    "transition sm:absolute-cover mb-2 sm:mb-0",
                    prompt.length >= 25 && "opacity-0"
                  )}
                >
                  {t("v4.generate.prompt.minLength", { number: 25 })}
                </div>
                <div
                  className={classNames(
                    "absolute-cover transition flex justify-between",
                    prompt.length < 25 && "opacity-0"
                  )}
                >
                  <div className="whitespace-nowrap">{prompt.length} / 400</div>
                  <Dropdown
                    fit
                    value={language}
                    onChange={setLanguage}
                    items={Object.fromEntries(
                      TRANSLATE_LANGS(t).map(({ name, value }) => [
                        value,
                        { name },
                      ])
                    )}
                  />
                </div>
              </div>
              <NewButton
                variant="primary"
                disabled={prompt.length < 25}
                onClick={() => {
                  onSubmit({
                    focus: prompt,
                    language,
                  });
                  // setPromptSubmitted(true);
                }}
              >
                <TbWand /> {t("v4.generate.text")}
              </NewButton>
            </div>
          </div>
        }
      >
        <div className="w-full h-full flex">
          <TextareaAutosize
            autoFocus
            className="min-h-full -mx-4 -my-2 px-4 py-2 bg-transparent grow resize-none outline-0"
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                // setPromptSubmitted(true);
              }
            }}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value.substring(0, 400))}
            placeholder={t("v4.generate.prompt.placeholder")}
          />
        </div>
      </MessageContainer>

      {/*{promptSubmitted && (*/}
      {/*  <GenerateTrainingOptions*/}
      {/*    onSubmit={(data) => {*/}
      {/*      onSubmit({*/}
      {/*        focus: prompt,*/}
      {/*        language,*/}
      {/*        ...data,*/}
      {/*      });*/}
      {/*      close();*/}
      {/*    }}*/}
      {/*    time={(content, quiz) =>*/}
      {/*      (content === Size.Off*/}
      {/*        ? 2*/}
      {/*        : content === Size.Small*/}
      {/*        ? 3*/}
      {/*        : content === Size.Medium*/}
      {/*        ? 7*/}
      {/*        : 10) **/}
      {/*        30 +*/}
      {/*      (quiz === Size.Off*/}
      {/*        ? 0*/}
      {/*        : quiz === Size.Small*/}
      {/*        ? 2*/}
      {/*        : quiz === Size.Medium*/}
      {/*        ? 3*/}
      {/*        : 4) **/}
      {/*        5*/}
      {/*    }*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

const GenerateOutlinePrompt = ({ onSubmit, contentVolume, quizVolume }) => {
  const { t, i18n } = useTranslation();
  const [history, setHistory] = useState<
    {
      prompt: string;
      outline: SlideOutline;
    }[]
  >([]);
  const [prompt, setPrompt] = useState("");
  const [isPrompt, setIsPrompt] = useState(false);

  const contentMutation = useMutation({
    mutationFn: () =>
      !history.length
        ? postGenerateOutline(prompt, {
            content_volume: contentVolume,
            exercise_volume: quizVolume,
          })
        : postUpdateOutline(prompt, history.slice(-1)[0].outline),
    onSuccess: ({ slides, title, topic }) => {
      setHistory((h) => [...h, { prompt, outline: { slides, title, topic } }]);
      setPrompt("");
      setIsPrompt(false);
    },
  });

  const limit = !history.length ? 25 : 15;

  return (
    <>
      <MessageContainer received>
        {t("v4.chatUI.promptPrompt")}
      </MessageContainer>
      {history.map((item, i) => (
        <Fragment key={i}>
          <MessageContainer delay={0}>{item.prompt}</MessageContainer>
          <MessageContainer received delay={1}>
            {t("v4.chatUI.outline")}
            <div className="font-bold leading-tight mb-1 mt-2">
              {item.outline.title}
            </div>
            <ol className="text-sm list-decimal list-inside">
              {item.outline.slides.map(({ title }, i) => (
                <li key={i}>{title}</li>
              ))}
            </ol>
            <div className="mt-2">{t("v4.chatUI.outlinePrompt")}</div>
          </MessageContainer>
        </Fragment>
      ))}
      {!history.length ? (
        <MessageContainer
          delay={prompt ? 0 : 1}
          full
          options={
            <div className="flex flex-col gap-1 w-full">
              <div className="text-xs text-gray-400 bg-primary bg-opacity-10 rounded-lg px-1.5 py-0.5">
                <TbInfoCircle className="text-sm mr-1 inline -mt-0.5" />
                {t("v4.generate.prompt.lengthTip")}
              </div>
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-1">
                <div className="flex grow gap-2 text-sm text-gray-400 relative items-start self-stretch">
                  <div
                    className={classNames(
                      "transition sm:absolute-cover mb-2 sm:mb-0",
                      prompt.length >= limit && "opacity-0"
                    )}
                  >
                    {t("v4.generate.prompt.minLength", { number: limit })}
                  </div>
                  <div
                    className={classNames(
                      "absolute-cover transition flex justify-between",
                      prompt.length < limit && "opacity-0"
                    )}
                  >
                    <div className="whitespace-nowrap">
                      {prompt.length} / 400
                    </div>
                  </div>
                </div>
                <NewButton
                  variant="primary"
                  disabled={prompt.length < limit || contentMutation.isLoading}
                  onClick={() =>
                    !contentMutation.isLoading && contentMutation.mutate()
                  }
                >
                  {contentMutation.isLoading ? (
                    <CgSpinner className="animate-spin" />
                  ) : (
                    <TbSend />
                  )}
                  {t("v4.generic.send")}
                </NewButton>
              </div>
            </div>
          }
        >
          <div className="w-full h-full flex">
            <TextareaAutosize
              autoFocus
              className="min-h-full -mx-4 -my-2 px-4 py-2 bg-transparent grow resize-none outline-0"
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  // setPromptSubmitted(true);
                  if (!contentMutation.isLoading) contentMutation.mutate();
                }
              }}
              value={prompt}
              onChange={(e) =>
                !contentMutation.isLoading &&
                setPrompt(e.target.value.substring(0, 400))
              }
              placeholder={t("v4.generate.prompt.placeholder")}
              readOnly={contentMutation.isLoading}
            />
          </div>
        </MessageContainer>
      ) : (
        <>
          <MessageContainer
            delay={3}
            options={
              <>
                <NewButton
                  center
                  variant={isPrompt ? "primary" : undefined}
                  onClick={() => setIsPrompt(!isPrompt)}
                >
                  <TbWriting /> {t("v4.chatUI.modifyOutline")}
                </NewButton>
                <NewButton
                  center
                  color="bg-primary text-primary"
                  onClick={() =>
                    onSubmit({ slides_outline: history.slice(-1)[0].outline })
                  }
                >
                  <TbWand /> {t("v4.generate.text")}
                </NewButton>
              </>
            }
          >
            {t("v4.chatUI.outlineChoice")}
          </MessageContainer>
          {isPrompt && (
            <MessageContainer
              full
              options={
                <div className="flex flex-col gap-1 w-full">
                  <div className="text-xs text-gray-400 bg-primary bg-opacity-10 rounded-lg px-1.5 py-0.5">
                    <TbInfoCircle className="text-sm mr-1 inline -mt-0.5" />
                    {t("v4.generate.prompt.lengthTip")}
                  </div>
                  <div className="flex flex-col sm:flex-row items-start sm:items-center gap-1">
                    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-1 grow">
                      <div className="flex grow gap-2 text-sm text-gray-400 relative items-start self-stretch">
                        <div
                          className={classNames(
                            "transition sm:absolute-cover mb-2 sm:mb-0",
                            prompt.length >= limit && "opacity-0"
                          )}
                        >
                          {t("v4.generate.prompt.minLength", { number: limit })}
                        </div>
                        <div
                          className={classNames(
                            "absolute-cover transition flex justify-between",
                            prompt.length < limit && "opacity-0"
                          )}
                        >
                          <div className="whitespace-nowrap">
                            {prompt.length} / 400
                          </div>
                        </div>
                      </div>
                      <NewButton
                        variant="primary"
                        disabled={
                          prompt.length < limit || contentMutation.isLoading
                        }
                        onClick={() =>
                          !contentMutation.isLoading && contentMutation.mutate()
                        }
                      >
                        {contentMutation.isLoading ? (
                          <CgSpinner className="animate-spin" />
                        ) : (
                          <TbSend />
                        )}
                        {t("v4.generic.send")}
                      </NewButton>
                    </div>
                  </div>
                </div>
              }
            >
              <div className="w-full h-full flex">
                <TextareaAutosize
                  autoFocus
                  className="min-h-full -mx-4 -my-2 px-4 py-2 bg-transparent grow resize-none outline-0"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      // setPromptSubmitted(true);
                      if (!contentMutation.isLoading) contentMutation.mutate();
                    }
                  }}
                  value={prompt}
                  onChange={(e) =>
                    !contentMutation.isLoading &&
                    setPrompt(e.target.value.substring(0, 400))
                  }
                  placeholder={t("v4.chatUI.outlinePlaceholder")}
                  readOnly={contentMutation.isLoading}
                />
              </div>
            </MessageContainer>
          )}
        </>
      )}
    </>
  );
};
