import { SelectOnlyType } from "app/components/Sources/MediaPicker/MediaPicker";
import {
  Selecting,
  SelectionCrop,
  useSourceSelection,
  useSourceSelectionRange,
  WordWithPos,
} from "app/components/Sources/MediaPicker/SingleSource/useSourceSelection";
import {
  Line,
  useSourcePageQuery,
} from "app/components/Exercises/Edit/context/SourceContext";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  distanceBetween,
  Position,
} from "app/components/Exercises/Edit/sourcePanel/selectionType/TextSelection";
import classNames from "classnames";
import { ProgressiveCanvas } from "app/components/Helpers/ProgressiveImage";
import { InView } from "react-intersection-observer";
import { useSelectOnly } from "app/components/Sources/MediaPicker/context/selectOnlyContext";
import linesImage from "assets/images/lines.png";
import { TbLetterCase, TbPhoto } from "react-icons/tb";
import { TooltipRaw } from "app/components/Tooltip";
import { useRefCopy } from "app/hooks/useRefCopy";
import { useTranslation } from "react-i18next";

interface NewLine extends Line {
  words: WordWithPos[];
}

const getScrollParent = () => {
  const parent = document.getElementById("media-scroll");
  if (!parent) throw new Error();
  return parent;
};

enum SeenType {
  No,
  Brief,
  Seen,
}

export const DocumentPage = ({ sourceId = "", page = 1, scale = 1 }) => {
  // is visible currently
  const [isVisible, setIsVisible] = useState(!page);
  // has seen for longer than 0.5s
  const [hasSeen, setHasSeen] = useState(
    !page ? SeenType.Seen : page < 5 ? SeenType.Brief : SeenType.No
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (!isVisible) return;
    if (hasSeen === SeenType.Seen) return;

    if (hasSeen === SeenType.No) setHasSeen(SeenType.Brief);

    const timeout = setTimeout(() => {
      setHasSeen(SeenType.Seen);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [isVisible]);

  const selectOnly = useSelectOnly();
  const firstPage = useSourceSelection((state) => {
    const item = state.pages.find((page) => page && page.width && page.height)!;
    return {
      height: item.height,
      width: item.width,
    };
  });
  const [tempCrop, setTempCrop] = useState<SelectionCrop | null>(null);
  const tempCropRef = useRefCopy(tempCrop);
  const selectionRef = useRef<HTMLDivElement | null>(null);
  const selecting = useSourceSelection((state) => state.selecting);
  const cropPage = useSourceSelection((state) => state.crop?.page);

  useEffect(() => {
    if (cropPage == null || page == null || cropPage !== page)
      setTempCrop(null);
  }, [cropPage, page]);

  const { setSelecting, setStart, setEnd, setCrop, setPage } =
    useSourceSelection(
      ({ setSelecting, setStart, setEnd, setCrop, setPage }) => ({
        setSelecting,
        setStart,
        setEnd,
        setCrop,
        setPage,
      })
    );
  const [from, to] = useSourceSelectionRange();

  const sourcePage = useSourcePageQuery(
    sourceId,
    page + 1,
    false,
    { enabled: !!hasSeen },
    false
  );
  const sourcePageWords = useSourcePageQuery(
    sourceId,
    page + 1,
    true,
    { enabled: hasSeen === SeenType.Seen && sourcePage.isSuccess },
    false
  );

  const blocks = sourcePageWords.data?.blocks;
  const lines = useMemo(() => {
    if (!blocks) return [];
    const lines: (Line & { isLast: boolean })[] = blocks.reduce(
      (acc, { lines }) => [
        ...acc,
        ...lines.map((line, i) => ({
          ...line,
          isLast: i + 1 === lines.length,
        })),
      ],
      []
    );
    return lines.map<NewLine>(({ words, isLast, ...line }, lI) => ({
      ...line,
      words: words.map((item, i) => ({
        ...item,
        isLast: isLast && i + 1 === words.length,
        position: [lI, i] as Position,
      })),
    }));
  }, [blocks]);

  const words = useMemo(
    () =>
      lines.reduce<WordWithPos[]>((acc, { words }) => [...acc, ...words], []),
    [lines]
  );

  useEffect(() => {
    if (!words) return;
    setPage(page, { words });
  }, [words]);

  useEffect(() => {
    if (sourcePage.isSuccess) {
      setPage(page, {
        src: sourcePage.data.image_url,
        width: sourcePage.data.image_width,
        height: sourcePage.data.image_height,
      });
    }
  }, [sourcePage.isSuccess]);

  const handleSelectionPointerDown = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      if (hasSeen !== SeenType.Seen) return;
      if (
        !!selectOnly &&
        selectOnly !== SelectOnlyType.image &&
        selectOnly !== SelectOnlyType.columns &&
        selectOnly !== SelectOnlyType.oneColumn &&
        selectOnly !== SelectOnlyType.twoColumns &&
        selectOnly !== SelectOnlyType.media
      )
        return;

      const scrollParent = getScrollParent();
      if (!scrollParent) return;

      e.stopPropagation();
      e.preventDefault();
      // @ts-ignore
      e?.target?.releasePointerCapture?.(e.pointerId);

      const startScrollX = scrollParent.scrollLeft;
      const startScrollY = scrollParent.scrollTop;
      const startX = e.clientX;
      const startY = e.clientY;

      const parentSize = (e.target as HTMLDivElement).getBoundingClientRect();
      const x = (startX - parentSize.x) / scale;
      const y = (startY - parentSize.y) / scale;
      let crop = {
        x,
        y,
        w: 0,
        h: 0,
        page,
      };
      setStart(null);
      setEnd(null);
      setSelecting(Selecting.Image);
      setCrop(null);
      setTempCrop(crop);

      let tempClientY;
      let tempClientX;
      const handleMove = (e: Event | PointerEvent) => {
        e.stopPropagation();
        e.preventDefault();
        tempClientY = "clientY" in e ? e.clientY : tempClientY;
        tempClientX = "clientX" in e ? e.clientX : tempClientX;
        const clientY =
          tempClientY + getScrollParent().scrollTop - startScrollY;
        const clientX =
          tempClientX + getScrollParent().scrollLeft - startScrollX;
        const w = Math.min(
          Math.max(-x, (clientX - startX) / scale),
          parentSize.width / scale - x
        );
        const h = Math.min(
          Math.max(-y, (clientY - startY) / scale),
          parentSize.height / scale - y
        );
        crop = {
          x: w > 0 ? x : x + w,
          y: h > 0 ? y : y + h,
          w: Math.abs(w),
          h: Math.abs(h),
          page,
        };
        setTempCrop(crop);
      };

      getScrollParent().addEventListener("scroll", handleMove);
      document.addEventListener("pointermove", handleMove);
      document.addEventListener(
        "pointerup",
        (e) => {
          e.stopPropagation();
          e.preventDefault();
          getScrollParent().removeEventListener("scroll", handleMove);
          document.removeEventListener("pointermove", handleMove);
          setSelecting(Selecting.None);
          setCrop(tempCropRef.current);
        },
        { once: true }
      );
    },
    [page, scale, selectOnly, hasSeen]
  );

  const handleTextPointerDown = useCallback(
    (pos: number) => (e: React.PointerEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      // @ts-ignore
      e?.target?.releasePointerCapture?.(e.pointerId);
      // if (from != null && to != null) return;
      setStart([page, pos]);
      setEnd([page, pos]);
      setCrop(null);
      setTempCrop(null);
      setSelecting(Selecting.Text);

      document.addEventListener(
        "pointerup",
        (e) => {
          e.stopPropagation();
          e.preventDefault();
          setSelecting(Selecting.None);
        },
        { once: true }
      );
    },
    [from, to, page, setStart, setEnd, setSelecting, hasSeen, !!words?.length]
  );

  const handleTextPointerOver = useCallback(
    (pos: number) => (e: React.PointerEvent<HTMLDivElement>) => {
      if (selecting === Selecting.Text) {
        e.stopPropagation();
        e.preventDefault();
        setEnd([page, pos]);
      }
    },
    [
      selecting === Selecting.Text,
      words,
      page,
      setEnd,
      hasSeen,
      !!words?.length,
    ]
  );

  const linesSelection = useMemo(() => {
    if (!lines) return [];
    if (!from || !to) return [];

    if (page < from[0] || page > to[0]) return [];

    return lines.reduce((acc, line, lI) => {
      const linePos = ((): [number, number] | null => {
        // has entire page
        if (from[0] < page && to[0] > page) return [0, 1];

        const startLine = from[0] < page ? 0 : words[from[1]].position[0];
        const endLine =
          to[0] > page ? words.length - 1 : words[to[1]].position[0];
        // outside lines
        if (lI < startLine || lI > endLine) return null;
        const start = words[from[0] < page ? 0 : from[1]];
        const end = words[to[0] > page ? words.length - 1 : to[1]];
        if (!start || !end) return null;
        const selectionStart = distanceBetween(start, line) / line.w;
        const selectionEnd = (distanceBetween(end, line) + end.w) / line.w;

        // if selection is within a single line
        if (startLine === endLine) {
          return [selectionStart, selectionEnd];
        }
        // first line
        if (startLine === lI) return [selectionStart, 1];
        // last line
        if (lI === endLine) return [0, selectionEnd];
        return [0, 1];
      })();

      if (!linePos) return acc;
      return [
        ...acc,
        {
          ...line,
          selectX: linePos[0],
          selectW: linePos[1] - linePos[0],
        },
      ];
    }, [] as (NewLine & { selectX: number; selectW: number })[]);
  }, [lines, words, from, to]);

  const width = sourcePage.data?.image_width ?? firstPage.width;
  const height = sourcePage.data?.image_height ?? firstPage.height;

  return (
    <InView as="div" className="flex" onChange={setIsVisible}>
      {({ ref, inView }) => (
        <div
          ref={ref}
          className={classNames(
            "flex relative justify-center items-center bg-white text-gray-300",
            (!selectOnly ||
              selectOnly === SelectOnlyType.image ||
              selectOnly === SelectOnlyType.columns ||
              selectOnly === SelectOnlyType.oneColumn ||
              selectOnly === SelectOnlyType.twoColumns ||
              selectOnly === SelectOnlyType.media) &&
              "cursor-crosshair"
          )}
          style={{ width, height }}
          onPointerDown={handleSelectionPointerDown}
        >
          {!sourcePage.isSuccess ? (
            <div
              className="absolute-cover grid place-content-center p-2 animate-pulse"
              style={{
                gridTemplateColumns: `[first-x] repeat(auto-fill, 360px) [last-x]`,
                gridTemplateRows: `[first-y] repeat(auto-fill, 204px) [last-y]`,
                gap: "12px",
              }}
            >
              <div
                className="bg-repeat-space opacity-10"
                style={{
                  gridColumn: "first-x / last-x",
                  gridRow: "first-y / last-y",
                  backgroundImage: `url(${linesImage})`,
                }}
              />
            </div>
          ) : !inView ? null : (
            <>
              <ProgressiveCanvas
                thumbOnly={hasSeen === SeenType.Brief}
                src={sourcePage?.data?.image_url}
                crop={{
                  x: 0,
                  y: 0,
                  w: sourcePage.data.image_width,
                  h: sourcePage.data.image_height,
                  sizeX: sourcePage.data.image_width,
                  sizeY: sourcePage.data.image_height,
                  sourceId,
                }}
              />
              {(!selectOnly ||
                selectOnly === SelectOnlyType.image ||
                selectOnly === SelectOnlyType.columns ||
                selectOnly === SelectOnlyType.oneColumn ||
                selectOnly === SelectOnlyType.twoColumns ||
                selectOnly === SelectOnlyType.media) &&
                tempCrop &&
                tempCrop.page === page && (
                  <>
                    <div
                      ref={selectionRef}
                      className="absolute bg-primary bg-opacity-50"
                      style={{
                        left: tempCrop.x,
                        top: tempCrop.y,
                        width: tempCrop.w,
                        height: tempCrop.h,
                      }}
                    />
                    <TooltipRaw
                      key="b"
                      touch
                      visible
                      offset={[0, 2]}
                      placement="top-start"
                      appendTo={
                        document.getElementById("media-scroll") || document.body
                      }
                      trigger={undefined}
                      reference={selectionRef}
                      content={
                        <div className="flex items-center gap-2 py-1 px-2 text-gray-600 font-bold text-sm bg-white border rounded-lg max-w-xs shadow-lg text-center">
                          <TbPhoto className="shrink-0 text-xl" />
                          {t("v4.library.imageSelection")}
                        </div>
                      }
                    />
                  </>
                )}
              {(!selectOnly || selectOnly === SelectOnlyType.media) && (
                <>
                  {words.map(({ id, angle, h, w, x, y }, i) => (
                    <div
                      className={classNames(
                        "absolute cursor-text bg-primary opacity-0 transition",
                        !selecting && "hover:opacity-25"
                      )}
                      key={id}
                      style={{
                        left: x,
                        top: y,
                        width: w,
                        height: h,
                        transform: `rotate(-${(angle + 360) % 360}deg)`,
                      }}
                      onPointerDown={handleTextPointerDown(i)}
                      onPointerEnter={handleTextPointerOver(i)}
                    />
                  ))}
                  {linesSelection.map(
                    ({ id, angle, h, w, x, y, selectX, selectW }, i) => (
                      <>
                        <div
                          key={id}
                          className="absolute pointer-events-none"
                          style={{
                            left: x,
                            top: y,
                            width: w,
                            height: h,
                            transform: `rotate(-${(angle + 360) % 360}deg)`,
                          }}
                        >
                          <div
                            {...(i === 0 && { ref: selectionRef })}
                            className="absolute top-0 h-full bg-primary opacity-50"
                            style={{
                              left: `${selectX * 100}%`,
                              width: `${selectW * 100}%`,
                            }}
                          />
                          {i === 0 && (
                            <TooltipRaw
                              key="b"
                              touch
                              visible
                              offset={[0, 2]}
                              placement="top-start"
                              appendTo={
                                document.getElementById("media-scroll") ||
                                document.body
                              }
                              trigger={undefined}
                              reference={selectionRef}
                              content={
                                <div className="flex items-center gap-2 py-1 px-2 text-gray-600 font-bold text-sm bg-white border rounded-lg max-w-xs shadow-lg text-center">
                                  <TbLetterCase className="shrink-0 text-xl" />
                                  {t("v4.library.textSelection")}
                                </div>
                              }
                            />
                          )}
                        </div>
                      </>
                    )
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </InView>
  );
};
