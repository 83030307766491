import {
  usePageItemContext,
  usePageItemIndex,
  useSetList,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import { ParagraphAutocomplete } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { postAutocomplete } from "api/course/courseContentAPI";
import {
  PageLeafRender,
  paragraphsToText,
  textToParagraphs,
} from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { SkeletonParagraph } from "app/components/Exercises/CourseEdit/components/SkeletonParagraph";
import { SlideItemWrapper } from "app/components/Exercises/CourseEdit/slide/SlideItemWrapper";
import { StyledEditable } from "app/components/Exercises/Edit/questionType/Slide/item/ItemText";
import { useTranslation } from "react-i18next";
import { useMountedRef } from "app/hooks/useMounted";
import React, { useEffect, useRef } from "react";
import {
  handleOpenAIError,
  joinParagraphs,
} from "app/components/Exercises/CourseEdit/items/generate/PageParagraphAutocomplete";
import { useSubscriptionRefetch } from "api/userAPI";
import { useRole } from "app/hooks/useRole";

export const SlidePageParagraphAutocomplete = () => {
  const [item] = usePageItemContext<ParagraphAutocomplete>();
  const { t } = useTranslation();
  const mountedRef = useMountedRef();
  const index = usePageItemIndex();
  const indexRef = useRef(index);
  indexRef.current = index;
  const setList = useSetList();
  const refetchSubscription = useSubscriptionRefetch();
  const role = useRole();

  useEffect(() => {
    postAutocomplete(paragraphsToText(item.data.data))
      .then(({ text_predicted }) => {
        if (!mountedRef.current) return;
        const text = textToParagraphs(text_predicted);
        const generated = {
          ...item.data,
          data: joinParagraphs(item.data.data, text),
        };
        const items = Array.isArray(generated) ? generated : [generated];

        setList((list) => {
          list.splice(indexRef.current, 1, ...items);
        });
        refetchSubscription();
      })
      .catch((e) => {
        handleOpenAIError(e, t, role);
        if (!mountedRef.current) return;
        setList((list) => {
          list.splice(indexRef.current, 1, item.data);
        });
      });
  }, []);

  return (
    <SlideItemWrapper>
      <StyledEditable
        vertical="center"
        className="absolute-cover overflow-y-auto flex flex-col rounded-2xl"
      >
        <div className="text-gray-500 leading-relaxed flex flex-col gap-2 p-4">
          {item.data.data.map((paragraph, i, list) => (
            <div>
              {paragraph.map((item, i) => (
                <PageLeafRender slide item={item} key={i} />
              ))}
              {i === list.length - 1 && <SkeletonParagraph min={8} max={20} />}
            </div>
          ))}
        </div>
      </StyledEditable>
    </SlideItemWrapper>
  );
};
