import {
  CourseItem,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { PageParagraphItem } from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { PageListItem } from "app/components/Exercises/CourseEdit/items/content/PageListItem";
import { PageImageItem } from "app/components/Exercises/CourseEdit/items/content/PageImageItem";
import { PageVideoItem } from "app/components/Exercises/CourseEdit/items/content/PageVideoItem";
import { PageAudioItem } from "app/components/Exercises/CourseEdit/items/content/PageAudioItem";
import { PageEmbedItem } from "app/components/Exercises/CourseEdit/items/content/PageEmbedItem";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import React from "react";
import { PageMultipleChoiceItem } from "app/components/Exercises/CourseEdit/items/quiz/PageMultipleChoiceItem";
import { PageParagraphAutocomplete } from "app/components/Exercises/CourseEdit/items/generate/PageParagraphAutocomplete";
import { PageMultipleChoiceGenerate } from "app/components/Exercises/CourseEdit/items/generate/PageMultipleChoiceGenerate";
import { PageMultipleResponseGenerate } from "app/components/Exercises/CourseEdit/items/generate/PageMultipleResponseGenerate";
// import { PageShortAnswerItem } from "app/components/Exercises/CourseEdit/items/quiz/PageShortAnswerItem";
import { PageImageLabelingItem } from "app/components/Exercises/CourseEdit/items/quiz/PageImageLabelingItem";
import { PageParagraphSummarize } from "app/components/Exercises/CourseEdit/items/generate/PageParagraphSummarize";
import { PageParagraphSimplify } from "app/components/Exercises/CourseEdit/items/generate/PageParagraphSimplify";
import { PageParagraphParaphrase } from "app/components/Exercises/CourseEdit/items/generate/PageParagraphParaphrase";
import { PageParagraphNext } from "app/components/Exercises/CourseEdit/items/generate/PageParagraphNext";

import { PageParagraphTranslate } from "app/components/Exercises/CourseEdit/items/generate/PageParagraphTranslate";
import { PageSortingItem } from "app/components/Exercises/CourseEdit/items/quiz/PageSortingItem";
import { PagePairingItem } from "app/components/Exercises/CourseEdit/items/quiz/PagePairingItem";
import { PageMediaUpload } from "app/components/Exercises/CourseEdit/items/generate/PageMediaUpload";
import { PagePageGenerate } from "app/components/Exercises/CourseEdit/items/generate/PagePageGenerate";
import { PageFillTheGapItem } from "app/components/Exercises/CourseEdit/items/quiz/PageFillTheGapItem";
import { FlashcardsItem } from "app/components/Exercises/CourseEdit/items/content/PageFlashcardsItem";
import { PageExplanationItem } from "app/components/Exercises/CourseEdit/items/quiz/PageExplanationItem";
import { useRole } from "app/hooks/useRole";
import { PageColumnsItem } from "app/components/Exercises/CourseEdit/items/content/PageColumnsItem";
import { PollMultipleChoiceItem } from "./poll/PollMultipleChoiceItem";
import { PollCommentItem } from "./poll/PollCommentItem";
import { PageCategorisationItem } from "./quiz/PageCategorisationItem";
import { PageCrosswordItem } from "./quiz/PageCrosswordItem";
import { FlashcardsQuizItem } from "./quiz/PageFlashcardsQuizItem";

export const PageItem = ({
  type,
  index,
}: {
  type: CourseItem["type"];
  index: number;
}) => {
  const role = useRole();
  if (type === PageItemType.Paragraph) return <PageParagraphItem />;
  if (type === PageItemType.List) return <PageListItem />;
  if (type === PageItemType.Image) return <PageImageItem />;
  if (type === PageItemType.Video) return <PageVideoItem />;
  if (type === PageItemType.Audio) return <PageAudioItem />;
  if (type === PageItemType.Embed) return <PageEmbedItem />;
  if (type === PageItemType.Columns) return <PageColumnsItem />;

  if (type === PageItemType.MultipleChoice) return <PageMultipleChoiceItem />;
  // if (type === PageItemType.ShortAnswer) return <PageShortAnswerItem />;
  if (type === PageItemType.ImageLabeling) return <PageImageLabelingItem />;
  if (type === PageItemType.Sorting) return <PageSortingItem />;
  if (type === PageItemType.Pairing) return <PagePairingItem />;
  if (type === PageItemType.FillTheGap) return <PageFillTheGapItem />;
  if (type === PageItemType.Categorisation) return <PageCategorisationItem />;
  if (type === PageItemType.Crossword) return <PageCrosswordItem />;
  if (type === PageItemType.FlashcardsQuiz) return <FlashcardsQuizItem />;

  if (type === PageItemType.Explanation) return <PageExplanationItem />;

  if (type === PageItemType.Flashcards) return <FlashcardsItem />;

  if (type === PageItemType.MultipleChoicePoll)
    return <PollMultipleChoiceItem />;
  if (type === PageItemType.CommentPoll) return <PollCommentItem />;

  if (type === PageItemType.ParagraphAutocomplete)
    return <PageParagraphAutocomplete />;
  if (type === PageItemType.ParagraphSummarize)
    return <PageParagraphSummarize />;
  if (type === PageItemType.ParagraphSimplify) return <PageParagraphSimplify />;
  if (type === PageItemType.ParagraphParaphrase)
    return <PageParagraphParaphrase />;
  if (type === PageItemType.ParagraphNext) return <PageParagraphNext />;
  if (type === PageItemType.ParagraphTranslate)
    return <PageParagraphTranslate />;
  if (type === PageItemType.MultipleChoiceGenerate)
    return <PageMultipleChoiceGenerate />;
  if (type === PageItemType.MultipleResponseGenerate)
    return <PageMultipleResponseGenerate />;
  if (type === PageItemType.PageGenerate) return <PagePageGenerate />;
  if (type === PageItemType.MediaUpload) return <PageMediaUpload />;

  if (!role.prodMode)
    return (
      <PageItemWrapper>
        {type} - {index}
      </PageItemWrapper>
    );

  return null;
};
