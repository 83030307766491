import { useTranslation } from "react-i18next";
import { useSlate } from "slate-react";
import { Node, Transforms } from "slate";
import { InputText } from "app/components/Buttons/InputText";
import { TbCheck, TbHelpCircle, TbPlus, TbTrash, TbWand } from "react-icons/tb";
import { NewButton } from "app/components/Buttons/NewButton";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import useOnClickOutside from "use-onclickoutside";
import { motion, useSpring } from "framer-motion";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { useFTGContext } from "app/components/Exercises/CourseEdit/items/quiz/PageFillTheGapItem";
import {
  GapElementType,
  placeholderRegex,
} from "app/components/Exercises/CourseEdit/components/ftg/FillTheGapUtils";
import { Collapse } from "app/components/Collapse";
import { ActiveButton } from "app/components/Exercises/Edit/questionType/Slide/item/ItemText";
import { Tooltip } from "app/components/Tooltip";
import { HorizontalLine } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { useGetPageItem } from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  ConsumptionType,
  FillTheGap,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import produce from "immer";
import { useRole } from "app/hooks/useRole";
import { useMutation } from "react-query";
import { postFtgGaps } from "api/course/courseContentAPI";
import { handleOpenAIError } from "app/components/Exercises/CourseEdit/items/generate/PageParagraphAutocomplete";
import { CgSpinner } from "react-icons/cg";

const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: 0 }}>
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

const config = { damping: 50, stiffness: 1000, mass: 1 };
const INITIAL_SCALE = 0.9;

export const FillTheGapElement = ({
  children,
  attributes,
  element,
  isMcq = false,
}: {
  children: ReactNode;
  attributes: any;
  element: GapElementType;
  isMcq?: boolean;
}) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const isSelected = useFTGContext((store) => store.selected === element.id);
  const select = useFTGContext((store) => store.select);
  useOnClickOutside(ref, () => isSelected && select(null));

  const handleClick = () => {
    select(isSelected ? null : element.id);
  };

  const opacity = useSpring(0, config);
  const scale = useSpring(INITIAL_SCALE, config);

  const onMount = () => {
    scale.set(1);
    opacity.set(1);
  };

  const onHide = ({ unmount }) => {
    const cleanup = scale.onChange((value) => {
      if (value <= INITIAL_SCALE) {
        cleanup();
        unmount();
      }
    });

    scale.set(INITIAL_SCALE);
    opacity.set(0);
  };

  return (
    <span
      contentEditable={false}
      className="relative inline-block"
      {...attributes}
    >
      <InlineChromiumBugfix />
      <div ref={ref} contentEditable={false} className="inline-block relative">
        <Tippy
          render={(attrs) => (
            <motion.div
              style={{ scale, opacity }}
              {...attrs}
              contentEditable={false}
              className="bg-white shadow-xl p-2 border-gray-100 border rounded-xl max-h-72 overflow-y-auto"
            >
              <SingleGapToolbar {...{ element, isMcq }} />
            </motion.div>
          )}
          visible={isSelected}
          animation
          placement="top"
          {...{ onMount, onHide }}
          interactive
          popperOptions={{
            // strategy: "fixed",
            modifiers: [
              {
                name: "flip",
                options: {
                  allowedAutoPlacements: ["top", "bottom"],
                },
              },
              {
                name: "offset",
                options: { offset: [0, 4] },
              },
            ],
          }}
        >
          <div
            contentEditable={false}
            onClick={handleClick}
            className={classNames(
              "select-none rounded px-1 font-bold cursor-pointer transition-colors",
              element?.generating && "animate-pulse",
              isSelected
                ? "bg-opacity-100 text-white bg-primary"
                : !element.data.trim() ||
                  (isMcq && !element?.generating && !element?.options?.length)
                ? "bg-red-600 bg-opacity-80 text-white"
                : "bg-primary bg-opacity-40"
            )}
          >
            {element?.generating && (
              <CgSpinner className="animate-spin text-base inline my-1 mr-1" />
            )}
            {element.data || (
              <span style={{ fontSize: "small" }}>
                {`[${t("common.exercises.gap.empty")}]`}
              </span>
            )}
            {children}
          </div>
        </Tippy>
      </div>
      <InlineChromiumBugfix />
    </span>
  );
};

const SingleGapToolbar = ({
  element,
  isMcq = false,
}: {
  element: GapElementType;
  isMcq?: boolean;
}) => {
  const { t } = useTranslation();
  const editor = useSlate();
  const getPosition = useCallback(() => {
    for (const [item, location] of Node.nodes(editor)) {
      if ("type" in item && item.type === "gap" && item.id === element.id)
        return location;
    }
  }, [editor, element.id]);
  const [showHint, setShowHint] = useState(!!element.hint);
  const getPageItem = useGetPageItem<FillTheGap>();
  const role = useRole();

  const handleChange = (value: string) => {
    Transforms.setNodes(
      editor,
      { data: value, children: [{ text: "" }] },
      { at: getPosition() }
    );
  };

  const handleChangeHint = (hint: string) => {
    Transforms.setNodes(editor, { hint }, { at: getPosition() });
  };

  const handleAddOption = () => {
    Transforms.setNodes(
      editor,
      { options: [...(element?.options ?? []), ""] },
      { at: getPosition() }
    );
  };

  const generateOptionsMutation = useMutation(
    () => {
      const item = getPageItem();
      const split = item.data
        .split(placeholderRegex)
        .map((value) => {
          if (!placeholderRegex.test(value)) return value;

          const itemId = value.slice(1, -1);
          const gap = item.items?.[itemId];
          if (!gap || itemId === element.id) return value;

          return gap.text;
        })
        .join("");

      return postFtgGaps(
        split,
        item.consumptionType === ConsumptionType.multipleChoiceLanguage
      );
    },
    {
      onSuccess: ({ items }) => {
        const options = items?.[element.id]?.options ?? [];

        Transforms.setNodes(
          editor,
          {
            options: [...new Set([...(element?.options ?? []), ...options])],
            generating: false,
          },
          { at: getPosition() }
        );
      },
      onError: (e) => {
        handleOpenAIError(e, t, role);
        Transforms.setNodes(
          editor,
          {
            generating: undefined,
          },
          { at: getPosition() }
        );
      },
    }
  );

  useEffect(() => {
    if (element?.generating) generateOptionsMutation.mutate();
  }, [!!element?.generating]);

  const handleChangeOption = (text: string, index: number) => {
    Transforms.setNodes(
      editor,
      {
        options: produce(element?.options ?? [], (items) => {
          if (items?.[index] == null) return;
          items[index] = text;
        }),
      },
      { at: getPosition() }
    );
  };

  const handleDeleteOption = (index: number) => {
    Transforms.setNodes(
      editor,
      {
        options: produce(element?.options ?? [], (items) => {
          if (!items) return;
          items.splice(index, 1);
        }),
      },
      { at: getPosition() }
    );
  };

  const handleDelete = () => {
    Transforms.insertNodes(
      editor,
      { text: element.data },
      { at: getPosition() }
    );
    Transforms.removeNodes(editor, { at: getPosition() });
  };

  return (
    <div className="flex flex-col w-48 xs:w-80 lg:w-96">
      <div className="flex items-center gap-2">
        <InputText
          placeholder={t("common.exercises.answer.correct") + "..."}
          value={element.data}
          onChange={handleChange}
          icon={TbCheck}
          variant="primary"
          invalid={!element.data.trim()}
          maxLength={25}
        />
        <Tooltip value={t("v4.item.fillTheGap.hint")} delay={[500, 0]}>
          <ActiveButton
            icon={TbHelpCircle}
            isActive={showHint}
            onClick={() => {
              if (!showHint) setShowHint(true);
              else {
                setShowHint(false);
                handleChangeHint("");
              }
            }}
          />
        </Tooltip>

        <div className="h-6 border-l border-r border-gray-200" />

        <NewButton
          iconOnly
          variant="transparent"
          color="bg-red-500 text-red-500"
          size="lg"
          className="flex-shrink-0"
          onClick={handleDelete}
        >
          <TbTrash />
        </NewButton>
      </div>
      <Collapse className="-mx-1 -mb-1">
        {showHint && (
          <div className="px-1 mt-2 pb-1">
            <InputText
              placeholder={t("v4.item.fillTheGap.hint") + "..."}
              value={element.hint}
              onChange={handleChangeHint}
              icon={TbHelpCircle}
              maxLength={200}
            />
          </div>
        )}
      </Collapse>

      {isMcq && (
        <>
          <HorizontalLine className="w-full my-3" />
          {element?.options && (
            <div className="flex flex-col gap-1.5 mb-2">
              {element.options.map((text, i) => (
                <InputText
                  className="[&>input]:!py-1"
                  key={i}
                  placeholder={t("v4.item.fillTheGap.option") + "..."}
                  value={element.options![i]}
                  readOnly={element?.generating}
                  onChange={(text) => handleChangeOption(text, i)}
                  onKeyDown={(e) => {
                    if (!text && e.key === "Backspace") {
                      e.preventDefault();
                      handleDeleteOption(i);
                    }
                  }}
                  maxLength={25}
                />
              ))}
            </div>
          )}
          <div className="flex gap-1">
            <NewButton
              center
              className={classNames(
                "text-sm font-bold",
                !role.aiEnabled && "w-full grow"
              )}
              onClick={handleAddOption}
              disabled={element?.generating}
            >
              <TbPlus /> {t("v4.generic.add")}
            </NewButton>
            {role.aiEnabled && (
              <NewButton
                center
                className="text-sm font-bold grow"
                color="bg-primary text-primary"
                disabled={element?.generating}
                onClick={() => {
                  Transforms.setNodes(
                    editor,
                    { generating: true },
                    { at: getPosition() }
                  );
                }}
              >
                {element?.generating ? (
                  <CgSpinner className="animate-spin" />
                ) : (
                  <TbWand />
                )}
                {t("v4.generate.text")}
              </NewButton>
            )}
          </div>
        </>
      )}
    </div>
  );
};
