import React from "react";
import styled from "styled-components";
import Transition from "./transition";

const SessionSection = ({ children, color = "#fff" }) => {
  return (
    <Transition style={{ overflow: "initial" }}>
      <ScWrapper color={color}>{children}</ScWrapper>
    </Transition>
  );
};

export default SessionSection;

const ScWrapper = styled.div<{ color: string }>`
  overflow: hidden;
  padding: 24px 32px;
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  background: ${(p) => p.color};
`;
