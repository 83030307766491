import { FileWithPreview } from "app/components/Forms/SourceForm";
import { useTranslation } from "react-i18next";
import {
  useSourceUploadContext,
  useSourceUploadStore,
  WSStatus,
} from "api/ws/SourceUploadContext";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { FileType, mapMime } from "helpers/mimeType";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  TbArrowBackUp,
  TbCheck,
  TbExternalLink,
  TbFile,
  TbVideo,
  TbVolume,
  TbX,
} from "react-icons/tb";
import { InputText } from "app/components/Buttons/InputText";
import { CgSpinner } from "react-icons/cg";
import { SelectOnlyType } from "app/components/Sources/MediaPicker/MediaPicker";
import { useSelectOnly } from "app/components/Sources/MediaPicker/context/selectOnlyContext";

export const SingleUpload = ({
  close,
  file,
  setFile,
  openSource,
  back,
}: {
  close: () => void;
  back: () => void;
  file: FileWithPreview | string;
  setFile: (file: FileWithPreview | string | null) => void;
  openSource: (item: any) => void;
}) => {
  const { t } = useTranslation();
  const { upload } = useSourceUploadContext();
  const isUploadId = typeof file === "string";
  const connected = useSourceUploadStore(
    (store) => store.status === WSStatus.Connected
  );
  const { get } = useSourceUploadContext();
  const [inputName, setInputName] = useState<string>("");
  const uploadData = useSourceUploadStore(
    (state) => (isUploadId && state?.data?.[file]) || null
  );
  const selectOnly = useSelectOnly();

  useEffect(() => {
    if (isUploadId && connected) {
      get(file);
    }
  }, [isUploadId, connected]);

  useEffect(() => {
    if (!isUploadId) {
      setInputName(file?.name.split(".")[0] || "");
      const preview = file.type.startsWith("image/")
        ? URL.createObjectURL(file)
        : undefined;

      const type =
        !file?.type &&
        (file.name.toLowerCase().endsWith("heic") ||
          file.name.toLowerCase().endsWith("heif"))
          ? "image/heif"
          : file?.type;

      const newFile = new File([file], file.name, {
        type,
        lastModified: file.lastModified,
      });
      setFile(Object.assign(newFile, { preview }));

      return () => {
        if (!preview) return;
        URL.revokeObjectURL(preview);
      };
    }
  }, [isUploadId]);

  const uploadMutation = useMutation(
    ({ file, name }: { file: FileWithPreview; name: string }) =>
      new Promise<string>((resolve, reject) =>
        upload({ file, name, resolve, reject })
      ),
    {
      onSuccess: (uploadId) => setFile(uploadId),
    }
  );

  const type = useMemo(() => {
    if (isUploadId) return null;
    return mapMime(file.type);
  }, [!isUploadId && file.type]);

  return (
    <>
      <div className="p-1 font-bold border-b-2 border-gray-100 flex items-center gap-2 text-gray-500">
        {selectOnly !== SelectOnlyType.none && (
          <NewButton onClick={back} size="sm">
            <TbArrowBackUp />
            {t("v4.generic.back")}
          </NewButton>
        )}

        <div className="font-bold text-gray-500">
          {t("v4.library.upload.text")}
        </div>
        <NewButton
          iconOnly
          onClick={close}
          className="ml-auto"
          variant="transparent"
        >
          <TbX />
        </NewButton>
      </div>
      <div className="grow relative">
        <div className="absolute-cover overflow-y-auto">
          <div className="p-8 flex flex-col justify-center min-h-full">
            {typeof file === "string" ? (
              <div className="flex flex-col items-center justify-center text-gray-600">
                <div className="text-2xl font-bold mb-2">
                  {uploadData?.error
                    ? t("v4.library.upload.error")
                    : uploadData?.data
                    ? t("v4.library.upload.done")
                    : t("v4.library.upload.uploading")}
                </div>
                {!uploadData?.error && (
                  <div className="w-full max-w-md overflow-hidden rounded-lg relative bg-gray-100 text-gray-600 h-8">
                    <div
                      className="absolute-cover bg-primary transition origin-left"
                      style={{
                        transform: `scaleX(${
                          (uploadData?.progress ?? 0) / 100
                        })`,
                      }}
                    />
                    <div className="absolute-cover flex items-center justify-center text-center text-sm font-bold">
                      {Math.round(uploadData?.progress ?? 0)}%
                    </div>
                  </div>
                )}
                <div className="flex items-center mt-8 gap-2">
                  {selectOnly !== SelectOnlyType.none && (
                    <NewButton size="lg" center onClick={() => setFile(null)}>
                      <TbArrowBackUp />
                      {t("v4.generic.back")}
                    </NewButton>
                  )}
                  {!!uploadData?.data?.id && (
                    <NewButton
                      size="lg"
                      center
                      color="bg-primary text-primary"
                      onClick={() => openSource(uploadData.data)}
                    >
                      {selectOnly === SelectOnlyType.source ? (
                        <>
                          <TbCheck /> {t("v4.generic.confirm")}
                        </>
                      ) : (
                        <>
                          <TbExternalLink />
                          {t("v4.library.upload.open")}
                        </>
                      )}
                    </NewButton>
                  )}
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center text-gray-500">
                {file.preview ? (
                  <img
                    className="max-h-xs max-w-xs border bg-gray-200 rounded-lg shadow mx-auto"
                    src={file.preview}
                    alt={file.name}
                  />
                ) : type === FileType.Audio ? (
                  <TbVolume className="text-7xl" />
                ) : type === FileType.Video ? (
                  <TbVideo className="text-7xl" />
                ) : (
                  <TbFile className="text-7xl" />
                )}

                <label className="flex text-center flex-col items-center mt-4">
                  <div className="font-bold mb-1 text-gray-600">
                    {t("source.name")}
                  </div>
                  <InputText
                    id="input_name"
                    value={inputName}
                    onChange={setInputName}
                    placeholder={t("media.source.name")}
                    maxLength={250}
                    icon={TbFile}
                  />
                </label>
                <div className="flex space-x-1 mt-8 w-full max-w-md">
                  <NewButton
                    disabled={uploadMutation.isLoading}
                    size="lg"
                    center
                    className="flex-1"
                    onClick={() => setFile(null)}
                  >
                    {t("common.cancel")}
                  </NewButton>
                  <NewButton
                    variant="primary"
                    size="lg"
                    center
                    className="flex-1"
                    onClick={() =>
                      uploadMutation.mutate({ file, name: inputName })
                    }
                    disabled={uploadMutation.isLoading}
                  >
                    {uploadMutation.isLoading && (
                      <CgSpinner className="animate-spin" />
                    )}
                    {t("common.save")}
                  </NewButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
