import React, { forwardRef, KeyboardEventHandler, useState } from "react";
import {
  MediaData,
  MediaPicker,
  SelectOnlyType,
} from "app/components/Sources/MediaPicker/MediaPicker";
import classNames from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbArrowsDiagonal, TbPhoto, TbTrash, TbX } from "react-icons/tb";
import {
  Image,
  ImageData,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { ProgressiveCanvas } from "app/components/Helpers/ProgressiveImage";
import { AudioPlayer, VideoPlayer } from "app/components/MediaPlayer";
import {
  DropMedia,
  useDropMedia,
} from "app/components/Sources/MediaPicker/context/dropMediaContext";
import {
  TransformComponent,
  TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch";
import useResizeObserver from "use-resize-observer";
import { createPortal } from "react-dom";
import { paragraphsToText } from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";

export const InputWithMedia = forwardRef<
  HTMLTextAreaElement,
  {
    onText: (text: string) => void;
    onMedia?: (data?: MediaData) => void;
    text: string;
    media?: MediaData;
    onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
    placeholder?: string;
    className?: string;
    maxLength?: number;
  }
>(
  (
    {
      onText,
      onMedia,
      text,
      media,
      onKeyDown,
      placeholder,
      className,
      maxLength,
    },
    ref
  ) => {
    const [dropMedia] = useDropMedia();
    const [mediaPicker, setMediaPicker] = useState(false);

    return (
      <div
        className={classNames(
          "overflow-hidden relative flex flex-col grow",
          className
        )}
      >
        <div className="flex group grow">
          <div className="flex flex-col grow relative">
            <TextareaAutosize
              ref={ref}
              value={text || ""}
              onChange={(e) => onText(e.target.value.slice(0, maxLength))}
              placeholder={placeholder}
              onKeyDown={onKeyDown}
              readOnly={!!dropMedia}
              maxLength={maxLength}
              className="outline-none px-2 py-1 w-full flex-grow resize-none overflow-hidden m-0 min-h-full bg-transparent"
            />

            {!!onMedia && dropMedia && (
              <div className="absolute-cover pl-1">
                <div className="flex min-w-full min-h-full relative">
                  <DropMedia
                    onDrop={(data) => {
                      switch (data.type) {
                        case PageItemType.Paragraph: {
                          const newData = paragraphsToText(data.data);
                          return () => onText(text + newData);
                        }
                        case PageItemType.Image:
                          return !!onMedia && !media && (() => onMedia(data));
                        case PageItemType.Video:
                          return !!onMedia && !media && (() => onMedia(data));
                        case PageItemType.Audio:
                          return !!onMedia && !media && (() => onMedia(data));
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          {!!onMedia && !dropMedia && (
            <div className="flex flex-col">
              <NewButton
                iconOnly
                size="sm"
                variant="transparent"
                className="mb-auto opacity-0 group-hover:opacity-50 hover:!opacity-100"
                onClick={() => setMediaPicker(true)}
              >
                <TbPhoto className="!text-2xl" />
              </NewButton>
            </div>
          )}

          {maxLength != null && (
            <div className="text-[0.6rem] text-gray-500 backdrop-blur-sm font-bold pt-0.5 pl-0.5 bg-white bg-opacity-50 rounded leading-none absolute right-0 bottom-0 transition-opacity pointer-events-none [:focus-within>&]:opacity-100 opacity-0">
              {text.length}/{maxLength}
            </div>
          )}
        </div>
        {!!onMedia && media && (
          <div className="flex relative group ml-2 bg-gray-50 rounded-xl">
            {media.type === PageItemType.Image ? (
              <ProgressiveCanvas
                crop={media.data}
                src={media.data.src}
                className="max-w-full rounded-xl overflow-hidden mx-auto max-h-64"
              />
            ) : media.type === PageItemType.Video ? (
              <VideoPlayer src={media.data.src} />
            ) : (
              <AudioPlayer src={media.data.src} />
            )}
            <NewButton
              iconOnly
              size="sm"
              variant="strongLight"
              className="absolute top-1 right-1 opacity-0 group-hover:opacity-100 backdrop-blur"
              color="text-red-500 bg-red-100"
              onClick={() => onMedia()}
            >
              <TbTrash className="!text-2xl" />
            </NewButton>
          </div>
        )}
        {mediaPicker && (
          <MediaPicker
            selectOnly={SelectOnlyType.media}
            onInsert={(media) => {
              if (typeof media === "string") onText(text + media);
              else onMedia?.(media);
              setMediaPicker(false);
            }}
            close={() => setMediaPicker(false)}
          />
        )}
      </div>
    );
  }
);

export const InputWithMediaRender = ({
  text = "",
  media,
  className,
}: {
  text: string;
  media?: MediaData;
  className?: string;
}) => (
  <div
    className={classNames(
      "overflow-hidden relative flex-grow flex flex-col",
      className
    )}
  >
    {!!text && (
      <div
        className="flex px-2 py-1 flex-grow w-full whitespace-pre-line"
        style={{ wordBreak: "break-word" }}
      >
        {text}
      </div>
    )}
    {media && (
      <div className="flex relative bg-gray-50 rounded-xl">
        {media.type === PageItemType.Image ? (
          <CourseImage media={media} className="max-h-64" preview />
        ) : media.type === PageItemType.Video ? (
          <VideoPlayer src={media.data.src} />
        ) : (
          <AudioPlayer src={media.data.src} />
        )}
      </div>
    )}
  </div>
);

export const CourseImage = ({
  media,
  className,
  preview = false,
}: {
  media: Image | ImageData;
  className?: string;
  preview?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const { ref, width, height } = useResizeObserver<HTMLDivElement>();

  if (!("w" in media.data) || media.data?.w == null) return null;

  const initialScale =
    !width || !height
      ? null
      : Math.min(width / media.data.w, height / media.data.h);

  return (
    <div
      className="relative max-w-full flex mx-auto"
      style={{
        width:
          "width" in media && media?.width
            ? `min(100%, ${media.width}px)`
            : "w" in media.data
            ? `min(100%, ${(media.data.w! / media.data.h!) * 800}px, ${
                media.data.w
              }px)`
            : undefined,
      }}
    >
      <ProgressiveCanvas
        crop={media.data}
        src={media.data.src}
        className={classNames(
          "max-w-full rounded-xl overflow-hidden mx-auto border-2 border-gray-100",
          className
        )}
      />
      {preview &&
        open &&
        createPortal(
          <div
            className="fixed w-full h-full left-0 top-0 bg-black bg-opacity-90 z-40"
            ref={ref}
            onClick={(e) => e.stopPropagation()}
          >
            {initialScale != null && (
              <TransformWrapper
                initialScale={initialScale * 0.9}
                minScale={initialScale * 0.5}
                maxScale={initialScale * 5}
                centerZoomedOut
                centerOnInit
                doubleClick={{ disabled: true }}
                panning={{ excluded: ["panningDisabled"] }}
              >
                <TransformComponent
                  wrapperStyle={{
                    height: "100%",
                    width: "100%",
                    overflow: "visible",
                  }}
                >
                  <ProgressiveCanvas
                    crop={media.data}
                    src={media.data.src}
                    className="rounded-xl overflow-hidden mx-auto shadow-xl"
                  />
                </TransformComponent>
              </TransformWrapper>
            )}

            <NewButton
              iconOnly
              variant="strongLight"
              color="bg-white text-white"
              className="absolute top-2 left-2 opacity-100 backdrop-blur"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(false);
              }}
            >
              <TbX />
            </NewButton>
          </div>,
          document.body
        )}
      {preview && (
        <NewButton
          iconOnly
          size="sm"
          variant="primary"
          color="bg-white text-gray-600"
          className="absolute top-1 right-1 border border-gray-100 shadow-lg backdrop-blur transition opacity-50 [div:hover>div>&]:opacity-100"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(true);
          }}
        >
          <TbArrowsDiagonal />
        </NewButton>
      )}
    </div>
  );
};
