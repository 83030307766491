import { useRef, useEffect } from "react";

export default function useInterval(
  callback: () => void,
  delay?: number | null
) {
  const savedCallback = useRef<typeof callback>(() => {});

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay]);
}
