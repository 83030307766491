import { NewButton } from "app/components/Buttons/NewButton";
import { TbArrowsShuffle, TbLanguage } from "react-icons/tb";
import React, { useState } from "react";
import {
  List,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { uuid } from "app/components/Exercises/utils/uuid";
import { paragraphToText } from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import { useDropMedia } from "app/components/Sources/MediaPicker/context/dropMediaContext";
import { useTranslation } from "react-i18next";
import { TextGenerateModal, TranslateModal } from "./TextGenerate";
import { HorizontalLine } from "../../items/PageItemWrapper";

export const ListGenerate = ({ close }) => {
  const [item] = usePageItemContext<List>();
  const [modal, setModal] = useState(TextGenerateModal.None);
  const [, setDropMedia] = useDropMedia();
  const { t } = useTranslation();

  const handleTranslate = (language: string) => {
    setModal(TextGenerateModal.None);
    setDropMedia({
      type: PageItemType.ParagraphTranslate,
      language,
      data: item.data,
      list: item.variant,
    });
  };
  const generateSorting = () => {
    setDropMedia({
      type: PageItemType.Sorting,
      instructions: [],
      items: item.data.map((paragraph) => ({
        id: uuid(),
        text: paragraphToText(paragraph),
      })),
    });
  };

  return (
    <>
      <NewButton
        variant="transparent"
        onClick={() => {
          generateSorting();
          close();
        }}
      >
        <TbArrowsShuffle /> {t("v4.item.sorting.text")}
      </NewButton>

      <HorizontalLine />

      <NewButton
        variant="transparent"
        onClick={() => {
          close();
          setModal(TextGenerateModal.Translate);
        }}
      >
        <TbLanguage /> {t("v4.item.translate.text")}
      </NewButton>

      {modal === TextGenerateModal.Translate && (
        <TranslateModal
          onConfirm={handleTranslate}
          close={() => setModal(TextGenerateModal.None)}
        />
      )}
    </>
  );
};
