import { BaseEditor, createEditor, Editor } from "slate";
import { uuid } from "app/components/Exercises/utils/uuid";
import { FillTheGap } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { FillTheGapElement } from "app/components/Exercises/CourseEdit/components/ftg/FillTheGapElement";
import React, { useState } from "react";
import { ReactEditor, withReact } from "slate-react";
import { HistoryEditor, withHistory } from "slate-history";

export type DefaultElementType = {
  type: "paragraph";
  children: (SlateText | GapElementType | LinkElementType)[];
};

export type GapElementType = {
  type: "gap";
  id: string;
  hint: string;
  data: string;
  options?: string[];
  generating?: boolean;
  children: [SlateText];
};

export type LinkElementType = {
  type: "link";
  url?: string;
  source?: string;
  children: SlateText[];
  id: string;
};

export type SlateText = { text: string };

type Element = DefaultElementType | GapElementType | LinkElementType;

declare module "slate" {
  // noinspection JSUnusedGlobalSymbols
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: Element;
    Text: SlateText;
  }
}

export const placeholderRegex =
  /(\[[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}])/g;

export const gapParagraphsToSlate = (
  data: string,
  items: FillTheGap["items"]
) => {
  const descendants: Descendant[] = data.split(/\r?\n/).map((paragraph) => {
    const split = paragraph.split(placeholderRegex);
    const children = split.map((value) => {
      if (!placeholderRegex.test(value)) return { text: value };

      const id = value.slice(1, -1);
      const item = items?.[id];
      if (!item) return { text: value };

      const gap: GapElementType = {
        type: "gap",
        id,
        hint: item.hint,
        data: item.text,
        options: item?.options,
        generating: item?.generating,
        children: [{ text: "" }],
      };
      return gap;
    });
    return {
      type: "paragraph",
      children,
    };
  });

  return descendants;
};

const normalizeGaps = (nodes: (Editor | Descendant)[]) => {
  for (const node of nodes) {
    if (!("type" in node)) continue;
    if (node.type === "link") continue;
    if (node.type === "gap") {
      node.id = uuid();
      continue;
    }
    normalizeGaps(node.children);
  }
};

const withGaps = (editor: Editor) => {
  const { isVoid, isInline, insertFragment } = editor;

  editor.isVoid = (element) => element.type === "gap" || isVoid(element);
  editor.isInline = (element) => element.type === "gap" || isInline(element);
  editor.insertFragment = (data) => {
    normalizeGaps(data);
    return insertFragment(data);
  };

  return editor;
};

export const useGapEditor = () =>
  useState(() => withReact(withHistory(withGaps(createEditor()))))[0];

export type Descendant =
  | DefaultElementType
  | GapElementType
  | LinkElementType
  | SlateText;

export const PageBlock = ({ attributes, children, element, isMcq = false }) => {
  switch (element.type) {
    case "gap":
      return (
        <FillTheGapElement {...{ attributes, element, isMcq }}>
          {children}
        </FillTheGapElement>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};
