import axios from "axios";
import { apiUrlV1 } from "../enums/constant";

export const postSource =
  (url: string) =>
  async ({
    file,
    name,
    folder_id,
  }: {
    file: File;
    name: string;
    folder_id?: string;
  }) => {
    const fd = new FormData();
    fd.append("file", file);
    fd.append("name", name);
    if (folder_id) fd.append("folder_id", folder_id);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const { data } = await axios.post<any>(url, fd, config);
    return data;
  };

export const postSourceAsync = postSource(`${apiUrlV1}/source/async/`);

export async function getPage(sourceId: any, pageNumber: any, getBlocks: any) {
  const url = `${apiUrlV1}/page/${sourceId}/${pageNumber}/${getBlocks ? 1 : 0}`;
  const { data } = await axios.get<any>(url);
  return data;
}

export const postPageValid = async ({
  start,
  end,
  sourceId,
  text,
}: {
  start?: number;
  end?: number;
  sourceId?: string;
  text?: string;
}) => {
  const url = `${apiUrlV1}/valid_source/`;
  const { data } = await axios.post<{ result: boolean }>(url, {
    source_id: sourceId,
    start_page: start,
    end_page: end,
    user_text: text,
  });
  return data;
};

export async function getSource(sourceId: any) {
  const url = `${apiUrlV1}/source/${sourceId}`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function putSource(
  sourceId: string,
  putData: Record<string, any>
) {
  const url = `${apiUrlV1}/source/${sourceId}`;
  const { data } = await axios.put<any>(url, putData);
  return data;
}

export async function openSource(sourceId: any) {
  const url = `${apiUrlV1}/source/open/${sourceId}/`;
  const { data } = await axios.post<any>(url, {});
  return data;
}

export async function postFileWithCode(dataToSend: any) {
  const url = `${apiUrlV1}/upload/`;
  const { data } = await axios.post<any>(url, dataToSend);
  return data;
}

export async function urlToPdf(address: string) {
  const url = `${apiUrlV1}/url_to_pdf/`;
  const { data } = await axios.post<any>(url, { url: address });
  return data;
}

export async function postFile({ file }: any) {
  const url = `${apiUrlV1}/files/`;
  const fd = new FormData();
  fd.append("file", file);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const { data } = await axios.post<any>(url, fd, config);
  return data;
}

export async function getVideoTranscription(transcriptionId: string) {
  const url = `${apiUrlV1}/source/transcription/${transcriptionId}/`;
  const { data } = await axios.get<any>(url);
  return data;
}

export const postNewItemPin = async (
  id: string,
  type: number,
  data?: { date_due: string }
) => {
  const url = `${apiUrlV1}/items/pin/create/`;
  const res = await axios.post(url, {
    item_id: id,
    item_type: type,
    ...data,
  });
  return res.data;
};

export const postPublishItem = async (id: string, type: number) => {
  const url = `${apiUrlV1}/items/publish/`;
  const res = await axios.post(url, {
    item_id: id,
    item_type: type,
  });
  return res.data;
};

export const deleteUnpublishItem = async (id: string, type: number) => {
  const url = `${apiUrlV1}/items/publish/`;
  const res = await axios.delete(url, {
    data: {
      item_id: id,
      item_type: type,
    },
  });
  return res.data;
};

export const getItemPin = async (id: string, type: number) => {
  const url = `${apiUrlV1}/items/pin/${id}/${type}/`;
  const res = await axios.get<{ item: { id: string; name: string } }>(url);
  return res.data;
};

export const getItemFromPin = async (pin: string) => {
  const url = `${apiUrlV1}/items/pin/${pin}/`;
  const res = await axios.get<{ item: { id: string; name: string } }>(url);
  return res.data;
};

export const postImportItem = async (pin: string, folder?: string) => {
  const url = `${apiUrlV1}/items/${folder || "root"}/import/${pin}/`;
  const res = await axios.post(url);
  return res.data;
};

export const postImportPublicItem = async (id: string, folder?: string) => {
  const url = `${apiUrlV1}/publications/${folder || "root"}/import/${id}/`;
  const res = await axios.post(url);
  return res.data;
};
