import React, { ComponentProps } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { FaCheck } from "react-icons/fa";
import { TbCheck } from "react-icons/tb";

const ScCheckboxWrapper = styled.div<{ middleValue?: boolean }>`
  display: flex;
  align-items: center;
  min-width: 40px;
  &:hover {
    cursor: pointer;
  }

  input[type="checkbox"] {
    display: none;
  }

  label {
    display: flex;
    padding-left: 40px;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    min-height: 24px;
    color: black;
    position: relative;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  label:before {
    content: "";
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    border: 2px solid #d0d0d0;
    background: white;
    border-radius: 4px;
  }

  label:after {
    content: "";
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    transform: scale(${(p) => (p.middleValue ? 0.3 : 0)});
    transition: transform 0.25s ease;
    &.middle-status {
      transform: scale(0.2) !important;
    }
  }

  input[type="checkbox"]:checked + label:after {
    transform: scale(0.6);
  }

  input[type="checkbox"]:disabled + label:before {
    border-color: #d0d0d0;
  }
`;

const InputCheckboxControlled = ({
  id,
  value,
  middleValue,
  label = "",
  toogleValue,
  style,
  ...checkboxProps
}: any) => {
  const changeHandler = () => {
    toogleValue && toogleValue(value);
  };

  return (
    <>
      <ScCheckboxWrapper style={{ ...style }} middleValue={middleValue}>
        <input
          id={id && `checkbox-${id}`}
          type="checkbox"
          checked={value}
          onChange={changeHandler}
          {...checkboxProps}
        />
        <label
          style={{
            paddingLeft: `${label === "" ? 0 : "40px"}`,
            minWidth: "40px",
          }}
          htmlFor={`checkbox-${id}`}
          className="after:bg-primary"
        >
          {label}
        </label>
      </ScCheckboxWrapper>
    </>
  );
};

export { InputCheckboxControlled };

type InputCheckboxProps = {
  onChange: (value: boolean) => void;
  value: boolean;
  defaultValue?: boolean;
  color?: string;
} & Omit<ComponentProps<"input">, "value" | "onChange">;

export const InputCheckbox = ({
  onChange,
  value,
  className,
  color = "bg-primary",
  ...props
}: InputCheckboxProps) => (
  <div
    className={classNames(
      "w-[1.25em] h-[1.25em] relative rounded-[25%] cursor-pointer shrink-0 transition flex items-center justify-center text-white",
      className,
      value ? color : "bg-gray-800 bg-opacity-20"
    )}
  >
    <input
      type="checkbox"
      className="hidden"
      checked={value}
      onChange={() => onChange?.(!value)}
      {...props}
    />
    <TbCheck
      className={classNames(
        "text-[0.8em] transform transition",
        value ? "scale-100 opacity-1" : "scale-50 opacity-0"
      )}
      strokeWidth="0.3em"
    />
  </div>
);

export const Checkbox = ({
  isSelected,
  className,
  unselectedColor = "bg-primary text-primary border-gray-300",
  selectedColor = "bg-white text-primary border-primary/50",
  ...props
}: {
  isSelected: boolean;
  unselectedColor?: string;
  selectedColor?: string;
} & ComponentProps<"div">) => (
  <div
    className={classNames(
      "w-7 h-7 rounded-lg border-2 cursor-pointer transition flex items-center justify-center shrink-0",
      isSelected
        ? "bg-opacity-100 hover:bg-opacity-80"
        : "bg-opacity-0 hover:border-primary hover:bg-opacity-40",
      isSelected ? selectedColor : unselectedColor,
      className
    )}
    {...props}
  >
    <FaCheck
      className={classNames(
        "transition",
        isSelected ? "delay-100 duration-300" : "scale-0 opacity-50"
      )}
    />
  </div>
);
