import { useGetList } from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  PageItemType,
  SlideListGenerate,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import {
  paragraphsToText,
  paragraphToText,
  textToParagraph,
} from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { postGenerateList } from "api/course/courseContentAPI";
import { SlidePageGenerate } from "app/components/Exercises/CourseEdit/components/PageGenerate";

export const SlidePageListGenerate = () => {
  const getList = useGetList();

  return (
    <SlidePageGenerate
      promise={(item: SlideListGenerate) => {
        const text =
          item?.text ??
          (getList() || [])
            .map((item) => {
              if (item.type === PageItemType.Paragraph)
                return paragraphsToText(item.data);
              return "";
            })
            .filter((text) => !!text)
            .join("\n");

        const list = (getList() || []).reduce<string[]>((acc, item) => {
          if (item.type !== PageItemType.List) return acc;
          return [...acc, ...item.data.map((p) => paragraphToText(p))];
        }, []);

        return postGenerateList(text, list);
      }}
      gen={({ bullet_points }, { id, position }) => {
        const data = bullet_points.map((text) => textToParagraph(text), []);
        return {
          id,
          position,
          verticalAlign: "center",
          type: PageItemType.List,
          category: "slide",
          data: data,
          variant: "bullet",
        };
      }}
    />
  );
};
