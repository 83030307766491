// @ts-nocheck
import { useRef, useState } from "react";

export const useMediaPlayer = <
  T extends HTMLAudioElement | HTMLVideoElement
>() => {
  const ref = useRef<T>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState<number | null>();
  const [currentTime, setCurrentTime] = useState(0);
  const [size, setSize] = useState<{ width: number; height: number } | null>(
    null
  );
  const animationRef = useRef<number | null>(null); // reference the animation

  const onLoadedMetadata = () => {
    if (ref.current == null) return;
    if ("videoWidth" in ref.current) {
      setSize({
        width: ref.current.videoWidth,
        height: ref.current.videoHeight,
      });
    }
    if (Number.isFinite(ref.current.duration)) {
      const seconds = Math.floor(ref.current.duration * 10);
      setDuration(seconds);
    } else {
      setDuration(null);
    }
    setIsLoading(false);
  };

  const pause = () => {
    if (ref.current == null) return;
    setIsPlaying(false);
    ref.current.pause();
    if (animationRef.current != null)
      cancelAnimationFrame(animationRef.current);
  };

  const restart = () => {
    goToTime(0);
    play();
  };

  const whilePlaying = () => {
    if (ref.current == null) return;
    setCurrentTime(Math.floor(ref.current.currentTime * 10));

    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const play = () => {
    if (ref.current == null) return;
    setIsPlaying(true);
    ref.current.play();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const toggle = () => {
    if (!isPlaying) play();
    else pause();
  };

  const goToTime = (newTime) => {
    if (ref.current == null) return;
    setCurrentTime(newTime);
    ref.current.currentTime = newTime / 10;
  };

  return {
    isLoading,
    currentTime,
    duration,
    isPlaying,
    play,
    pause,
    restart,
    toggle,
    goToTime,
    size,
    bind: {
      onLoadedMetadata,
      ref,
    },
  };
};
