import React, {
  PropsWithChildren,
  RefObject,
  useCallback,
  useRef,
  useState,
} from "react";
import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import { Categorisation } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { MediaData } from "app/components/Sources/MediaPicker/MediaPicker";
import { uuid } from "app/components/Exercises/utils/uuid";
import { withConsumption } from "app/components/Exercises/CourseEdit/render/ConsumptionContext";
import { CategorisationConsumption } from "app/components/Exercises/CourseEdit/render/courseConsumptionTypes";
import {
  InputWithMedia,
  InputWithMediaRender,
} from "app/components/Exercises/CourseEdit/components/InputWithMedia";
import {
  InstructionsRender,
  QuizDropzone,
  QuizInstructionsRender,
  QuizItemLabel,
} from "app/components/Exercises/CourseEdit/components/generate/QuizDropzone";
import { CategorisationResults } from "app/components/Exercises/CourseEdit/results/courseResultsTypes";
import { CorrectMarker } from "app/components/Exercises/CourseEdit/results/PageResults";
import { CorrectnessMarker } from "app/components/Exercises/CourseEdit/results/PageCorrect";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  TbExclamationCircle,
  TbPlus,
  TbSquarePlus,
  TbTrash,
} from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";

export const PageCategorisationItem = () => {
  const [item, set] = usePageItemContext<Categorisation>();
  const { t } = useTranslation();

  return (
    <PageItemWrapper>
      <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
        <QuizItemLabel type={item.type} />
        <QuizDropzone />
        <div className="flex flex-col gap-2">
          {item.items?.map((item, i) => (
            <SingleCategory key={item.id} {...item} index={i} />
          ))}
        </div>
        <NewButton
          center
          variant="bg-opacity-0 hover:bg-opacity-80"
          color="bg-white text-gray-500"
          size="lg"
          className="font-bold"
          onClick={() => {
            set((item) => {
              item.items.push({
                id: uuid(),
                text: "",
                items: [{ id: uuid(), text: "" }],
              });
            });
          }}
        >
          <TbSquarePlus /> {t("v4.item.categorisation.add")}
        </NewButton>
      </div>
    </PageItemWrapper>
  );
};

const SingleCategory = ({
  text,
  items,
  media,
  index: itemIndex,
}: { index: number } & Categorisation["items"][number]) => {
  const { t } = useTranslation();
  const [, set] = usePageItemContext<Categorisation>();

  const handleText = useCallback(
    (text: string) => {
      set((item) => {
        item.items[itemIndex].text = text;
      });
    },
    [set, itemIndex]
  );

  const handleMedia = useCallback(
    (media?: MediaData) => {
      set((item) => {
        if (!media) {
          delete item.items[itemIndex]["media"];
        } else {
          item.items[itemIndex].media = media;
        }
      });
    },
    [set, itemIndex]
  );

  return (
    <div className="bg-gray-300 rounded-xl p-2 flex flex-col gap-1.5">
      <div className="bg-white rounded-lg p-1.5 flex items-center relative category-group">
        <InputWithMedia
          {...{ text, media }}
          onText={handleText}
          onMedia={handleMedia}
          className="grow"
          placeholder={t("v4.item.categorisation.placeholder")}
        />

        <NewButton
          iconOnly
          color="bg-red-500 text-red-500"
          size="lg"
          className="mb-auto absolute top-0 -right-2.5 transform translate-x-full opacity-0 [.category-group:hover>&]:opacity-100"
          center
          onClick={() => {
            set((item) => {
              item.items.splice(itemIndex, 1);
            });
          }}
        >
          <TbTrash />
        </NewButton>
      </div>
      <div className="flex flex-col pl-3 ml-0.5 border-l-4 border-l-gray-200 gap-1.5">
        {items?.map((item, i) => (
          <SingleCategoryItem
            key={item.id}
            {...item}
            parentIndex={itemIndex}
            index={i}
          />
        ))}
        <NewButton
          center
          variant="bg-opacity-0 hover:bg-opacity-80"
          color="bg-gray-200 text-gray-500"
          size="lg"
          className="font-bold"
          onClick={() => {
            set((item) => {
              item.items[itemIndex].items.push({ id: uuid(), text: "" });
            });
          }}
        >
          <TbPlus /> {t("v4.generic.addItem")}
        </NewButton>
      </div>
    </div>
  );
};

const SingleCategoryItem = ({
  text,
  media,
  parentIndex,
  index: itemIndex,
}: {
  index: number;
  parentIndex: number;
} & Categorisation["items"][number]["items"][number]) => {
  const { t } = useTranslation();
  const [, set] = usePageItemContext<Categorisation>();

  const handleText = useCallback(
    (text: string) => {
      set((item) => {
        item.items[parentIndex].items[itemIndex].text = text;
      });
    },
    [set, itemIndex]
  );

  const handleMedia = useCallback(
    (media?: MediaData) => {
      set((item) => {
        if (!media) {
          delete item.items[parentIndex].items[itemIndex]["media"];
        } else {
          item.items[parentIndex].items[itemIndex].media = media;
        }
      });
    },
    [set, itemIndex]
  );

  return (
    <div className="bg-white rounded-lg p-1.5 flex items-center relative cat-item-group">
      <InputWithMedia
        {...{ text, media }}
        onText={handleText}
        onMedia={handleMedia}
        className="grow"
        placeholder={t("v4.generic.textPlaceholder")}
        maxLength={100}
      />

      <NewButton
        iconOnly
        color="bg-red-500 text-red-500"
        size="lg"
        className="mb-auto absolute top-0 -right-2.5 transform translate-x-full opacity-0 [.cat-item-group:hover>&]:opacity-100"
        center
        onClick={() => {
          set((item) => {
            item.items[parentIndex].items.splice(itemIndex, 1);
          });
        }}
      >
        <TbTrash />
      </NewButton>
    </div>
  );
};

export const PageCategorisationRender =
  withConsumption<CategorisationConsumption>(
    ({ answer, options, items, id, set, type }) => {
      const { t } = useTranslation();
      const categoryParentRef = useRef<HTMLDivElement | null>(null);
      const [visibleCategory, setVisibleCategory] = useState(0);

      const hasOptions = options.some(({ id }) => !answer?.[id]);

      const [isDragging, setIsDragging] = useState(false);
      const handlePointer = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        const startX = e.clientX;
        const startY = e.clientY;

        const parentScrollStart = categoryParentRef.current?.scrollTop;

        let isScrolling;

        const handleMove = (e: MouseEvent) => {
          e.stopPropagation();
          e.preventDefault();
          const { clientX, clientY } = e;
          // const w = clientX - startX;
          // const h = Math.abs(clientY - startY);
          // const angle = Math.atan(h / w);
          // console.log(angle);

          if (isScrolling == null) {
            if ((startX - clientX) ** 2 + (startY - clientY) ** 2 >= 2 ** 2) {
              const w = clientX - startX;
              const h = Math.abs(clientY - startY);
              const angle = Math.atan(h / w);
              isScrolling = angle < -0.8 || angle > 0.8;
              setIsDragging(isScrolling);
            }
            return;
          }

          if (
            isScrolling &&
            categoryParentRef.current?.scrollTop != null &&
            parentScrollStart != null
          ) {
            categoryParentRef.current.scrollTop =
              parentScrollStart + startY - clientY;
          }
        };

        document.addEventListener("mousemove", handleMove);
        document.addEventListener(
          "mouseup",
          (e) => {
            e.stopPropagation();
            e.preventDefault();
            document.removeEventListener("mousemove", handleMove);
            setIsDragging(false);
          },
          { once: true }
        );
      };

      return (
        <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
          <QuizItemLabel type={type} />
          <QuizInstructionsRender id={id} />
          <div className="grid grid-cols-2 gap-2 select-none">
            <div className="bg-gray-100 rounded-lg overflow-y-auto overflow-x-clip p-2 flex flex-col items-center scrollbar-none relative h-96">
              {/*<div className="w-full h-1/4 relative shrink-0" />*/}
              <div
                className={classNames(
                  "font-bold text-sm p-2 leading-tight pointer-events-none absolute-cover text-center text-gray-500 transition flex items-center justify-center",
                  hasOptions ? "opacity-0" : "opacity-100"
                )}
              >
                {t("v4.item.categorisation.noOptions")}
              </div>
              <AnimatePresence initial={false}>
                {options.map(
                  ({ id, text, media }) =>
                    !answer?.[id] && (
                      <SwipeItem
                        key={id}
                        onChange={() => {
                          set((item) => {
                            const category = items?.[visibleCategory]?.id;
                            if (!category) return;

                            item.answer[id] = category;
                          });
                        }}
                      >
                        <InputWithMediaRender {...{ text, media }} />
                      </SwipeItem>
                    )
                )}
              </AnimatePresence>
              {/*<div className="w-full h-1/4 relative shrink-0" />*/}
            </div>

            <div
              ref={categoryParentRef}
              onMouseDown={handlePointer}
              className={classNames(
                "bg-gray-100 rounded-lg overflow-y-auto text-center p-2 flex flex-col items-center gap-2 scrollbar-none relative h-96",
                !isDragging
                  ? "snap-y snap-proximity"
                  : "[&>*]:pointer-events-none"
              )}
            >
              <div className="w-full h-1/2 relative shrink-0" />
              {items.map(({ id: categoryId, text, media }, i) => {
                const hasChildren = options.some(
                  ({ id }) => answer?.[id] === categoryId
                );
                const active = i === visibleCategory;
                return (
                  <CategoryItem
                    parentRef={categoryParentRef}
                    onChange={() => setVisibleCategory(i)}
                    active={active}
                    key={categoryId}
                  >
                    <div className="rounded-lg bg-white p-1.5 flex items-center self-stretch">
                      <InputWithMediaRender {...{ text, media }} />
                    </div>
                    <div className="rounded-lg bg-black bg-opacity-10 flex flex-col px-1 text-sm w-full">
                      <AnimatePresence initial={false}>
                        {!hasChildren && (
                          <motion.div
                            initial={{ height: 0 }}
                            animate={{
                              height: "auto",
                            }}
                            exit={{ height: 0 }}
                            key="info"
                            className="flex flex-col overflow-y-clip"
                          >
                            <div
                              className={classNames(
                                "font-bold text-sm p-2 leading-tight",
                                active && "text-white"
                              )}
                            >
                              {t("v4.export.empty")}
                            </div>
                          </motion.div>
                        )}
                        {options.map(
                          (item) =>
                            answer?.[item.id] === categoryId && (
                              <motion.div
                                key={item.id}
                                className="flex flex-col overflow-clip select-none w-full shrink-0"
                                initial={{ height: 0, translateX: 0 }}
                                animate={{
                                  height: "auto",
                                  translateX: 0,
                                }}
                                exit={{ height: 0, translateX: "-100%" }}
                                drag={!isDragging && "x"}
                                dragConstraints={{ right: 0 }}
                                dragElastic={{ left: 1, right: 0 }}
                                dragSnapToOrigin
                                dragMomentum={false}
                                onDragEnd={(...data) => {
                                  const [, { point, offset }] = data;
                                  if (!point.x || !point.y || offset.x > -100)
                                    return;

                                  set((state) => {
                                    state.answer[item.id] = null;
                                  });
                                }}
                              >
                                <div className="rounded-lg p-1 flex items-center bg-white w-full shrink-0 my-1">
                                  <InputWithMediaRender {...item} />
                                </div>
                              </motion.div>
                            )
                        )}
                      </AnimatePresence>
                    </div>
                  </CategoryItem>
                );
              })}
              <div className="w-full h-1/2 relative shrink-0" />
            </div>
          </div>

          <div className="text-sm p-1 pb-0 font-bold flex items-center gap-1 text-gray-600">
            <TbExclamationCircle className="text-base" />
            {t("v4.item.categorisation.hint")}
          </div>
        </div>
      );
    }
  );

const SwipeItem = ({
  children,
  onChange,
}: PropsWithChildren<{
  onChange: () => void;
}>) => (
  <motion.div
    className="flex flex-col overflow-clip select-none w-full shrink-0"
    initial={{ height: 0, translateX: 0 }}
    animate={{ height: "auto", translateX: 0 }}
    exit={{ height: 0, translateX: "100%" }}
    dragMomentum={false}
    drag="x"
    dragConstraints={{ left: 0 }}
    dragSnapToOrigin
    dragElastic={{ left: 0, right: 1 }}
    onDragEnd={(a, { offset }) => {
      if (offset.x > 100) {
        onChange();
      }
    }}
  >
    <div className="rounded-lg p-1 flex items-center bg-white w-full shrink-0 my-1">
      {children}
    </div>
  </motion.div>
);

const CategoryItem = ({
  parentRef,
  onChange,
  active,
  children,
}: PropsWithChildren<{
  parentRef: RefObject<Element | null>;
  onChange?: (visible: boolean) => void;
  active?: boolean;
}>) => {
  const { ref } = useInView({
    rootMargin: "-50% 0% -50% 0%",
    root: parentRef?.current,
    onChange: (inView) => inView && onChange?.(true),
  });

  return (
    <div
      ref={ref}
      className={classNames(
        active ? "bg-primary" : "bg-gray-300",
        "rounded-lg p-1.5 flex flex-col items-center shrink-0 snap-center w-full gap-2"
      )}
    >
      {children}
    </div>
  );
};

export const PageCategorisationResults = ({
  item,
}: {
  item: CategorisationResults;
}) => {
  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <InstructionsRender instructions={item.instructions} />
      <div className="flex flex-col gap-2">
        {item.items?.map(({ items, ...category }) => {
          const answersForCategory = new Set(items.map((item) => item.id));
          const missing = items.filter(
            ({ id }) => item.answer[id] !== category.id
          );
          const submitted = item.options.filter(
            ({ id }) => item.answer[id] === category.id
          );
          return (
            <div
              key={category.id}
              className="bg-gray-300 rounded-xl p-2 flex flex-col gap-1.5"
            >
              <div className="bg-white rounded-lg p-1.5 flex items-center relative category-group">
                <InputWithMediaRender {...category} />
              </div>
              {(submitted.length > 0 || missing.length > 0) && (
                <div className="flex flex-col pl-3 ml-0.5 border-l-4 border-l-gray-200 gap-1.5">
                  {submitted.map((item) => {
                    const correct = answersForCategory.has(item.id);
                    return (
                      <div
                        className={classNames(
                          "flex items-stretch",
                          correct && "order-first"
                        )}
                        key={item.id}
                      >
                        <CorrectMarker correct={correct} />
                        <div className="bg-white rounded-lg p-1.5 flex items-center grow">
                          <InputWithMediaRender {...item} />
                        </div>
                      </div>
                    );
                  })}

                  {missing.map((item) => (
                    <div
                      key={item.id}
                      className="border-2 border-white border-dashed bg-white bg-opacity-40 rounded-lg p-1.5 flex items-center"
                    >
                      <InputWithMediaRender {...item} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const PageCategorisationCorrect = ({
  item,
}: {
  item: Categorisation;
}) => {
  return (
    <div className="flex items-stretch">
      <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1 grow relative">
        <InstructionsRender instructions={item.instructions} />
        <div className="flex flex-col gap-2">
          {item.items?.map(({ items, ...category }) => (
            <div
              key={category.id}
              className="bg-gray-300 rounded-xl p-2 flex flex-col gap-1.5"
            >
              <div className="bg-white rounded-lg p-1.5 flex items-center relative category-group">
                <InputWithMediaRender {...category} />
              </div>
              {items.length > 0 && (
                <div className="flex flex-col pl-3 ml-0.5 border-l-4 border-l-gray-200 gap-1.5">
                  {items.map((answer) => (
                    <div className="flex items-stretch" key={answer.id}>
                      <CorrectnessMarker ids={[item.id, answer.id]} />
                      <div className="bg-white rounded-lg p-1.5 flex items-center grow">
                        <InputWithMediaRender {...answer} />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
