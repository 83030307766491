import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  Crossword,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { withConsumption } from "app/components/Exercises/CourseEdit/render/ConsumptionContext";
import { CrosswordConsumption } from "app/components/Exercises/CourseEdit/render/courseConsumptionTypes";
import {
  InputWithMedia,
  InputWithMediaRender,
} from "app/components/Exercises/CourseEdit/components/InputWithMedia";
import {
  InstructionsRender,
  QuizDropzone,
  QuizInstructionsRender,
  QuizItemLabel,
} from "app/components/Exercises/CourseEdit/components/generate/QuizDropzone";
import { CrosswordResults } from "app/components/Exercises/CourseEdit/results/courseResultsTypes";
import { CorrectMarker } from "app/components/Exercises/CourseEdit/results/PageResults";
import { useTranslation } from "react-i18next";
import { NewButton } from "../../../../Buttons/NewButton";
import { TbArrowDown, TbArrowRight, TbCheck, TbTrash } from "react-icons/tb";
import produce, { current } from "immer";
import classNames from "classnames";
import useOnClickOutside from "use-onclickoutside";
import { generateSimpleTable } from "../../../utils/crosswordGeneration";
import { CorrectnessMarker } from "../../results/PageCorrect";
import {
  DropMedia,
  DropMediaColumns,
  MediaOneColumn,
  MediaTwoColumns,
  useDropMedia,
} from "../../../../Sources/MediaPicker/context/dropMediaContext";
import { ImportQuizFromTable } from "./PagePairingItem";
import { SelectOnlyType } from "../../../../Sources/MediaPicker/MediaPicker";

const createFiller = (
  items: Crossword["items"]
): { x: number; y: number }[] => {
  const letters = {};
  let maxX = 0;
  let maxY = 0;

  for (const key in items) {
    const answer = items[key];
    if (!answer?.position) continue;

    if (answer.position.orientation === "across") {
      maxY = Math.max(maxY, answer.position.y);
      maxX = Math.max(maxX, answer.position.x + key.length - 1);
    } else {
      maxY = Math.max(maxY, answer.position.y + key.length - 1);
      maxX = Math.max(maxX, answer.position.x);
    }

    [...new Array(key.length)].forEach((_, i) => {
      const x =
        answer.position!.x +
        (answer.position!.orientation === "across" ? i : 0);
      const y =
        answer.position!.y + (answer.position!.orientation === "down" ? i : 0);
      letters[`${x}_${y}`] = true;
    });
  }

  return [...new Array(maxX)].reduce((acc, _, iX) => {
    const arr = [...new Array(maxY)].reduce((acc, _, iY) => {
      if (letters?.[`${iX + 1}_${iY + 1}`]) return acc;
      return [...acc, { x: iX + 1, y: iY + 1 }];
    }, []);
    return [...acc, ...arr];
  }, []);
};

export const PageCrosswordItem = () => {
  const [item, set] = usePageItemContext<Crossword>();
  const { t } = useTranslation();
  const [dropMedia] = useDropMedia();

  const itemsList = useMemo(
    () =>
      Object.entries(item.items).map(([answer, item]) => ({ answer, ...item })),
    [item.items]
  );

  const hasCrossword = useMemo(
    () => Object.values(item.items).some(({ position }) => !!position),
    [item.items]
  );

  const [newWord, setNewWord] = useState("");
  const canAddWord = useMemo(
    () =>
      !!newWord &&
      newWord.length >= 2 &&
      newWord.length <= 15 &&
      !itemsList.some(
        ({ answer }) => answer.toLowerCase() === newWord.toLowerCase()
      ),
    [newWord, item.items, itemsList]
  );

  const createCrossword = useCallback((items: typeof item.items) => {
    const keys = Object.keys(items);
    if (keys.length < 1) return;

    const answers = keys.map((answer) => ({ answer }));
    const generated = generateSimpleTable(answers, 9, 20);
    if (!generated.result) return;

    return produce(items, (items) => {
      for (const { answer, ...result } of generated.result) {
        if (!items?.[answer]) continue;

        if (result.orientation === "none") {
          if (items[answer].position) delete items[answer].position;
          continue;
        }

        items[answer].position = {
          index: result.position,
          x: result.startx,
          y: result.starty,
          orientation: result.orientation,
        };
      }
    });
  }, []);

  const filler = useMemo(() => createFiller(item.items), [item.items]);

  const insertColumns = (media: MediaOneColumn | MediaTwoColumns) => {
    if (media.type === DropMediaColumns.OneColumn) {
      set<Crossword>((item) => {
        media.data.forEach((text) => {
          const answer = text
            .replace(/[^\p{L}-]/gu, "")
            .substring(0, 15)
            .toUpperCase();
          if (item.items?.[answer]) return;
          item.items[answer] = {
            text: "",
          };
        });

        const currentItems = current(item).items;
        if (Object.keys(currentItems).length) {
          const newItems = createCrossword(current(item).items);
          if (newItems) item.items = newItems;
        }
      });
      return;
    }
    if (media.type === DropMediaColumns.TwoColumns) {
      set<Crossword>((item) => {
        media.data.forEach(([left, right]) => {
          const answer = left
            .replace(/[^\p{L}-]/gu, "")
            .substring(0, 15)
            .toUpperCase();
          if (item.items?.[answer]) return;
          item.items[answer] = {
            text: right,
          };
        });

        const currentItems = current(item).items;
        if (Object.keys(currentItems).length) {
          const newItems = createCrossword(current(item).items);
          if (newItems) item.items = newItems;
        }
      });
      return;
    }
  };

  return (
    <PageItemWrapper>
      <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
        <QuizItemLabel type={PageItemType.Crossword}>
          <ImportQuizFromTable
            selectOnly={SelectOnlyType.columns}
            onInsert={(media) => insertColumns(media)}
          />
        </QuizItemLabel>
        <QuizDropzone />

        {!hasCrossword ? (
          <div className="bg-gray-300 rounded-lg text-gray-600 text-center font-bold p-4">
            {t("v4.item.crossword.addWords")}
          </div>
        ) : (
          <div className="p-2 flex items-center justify-center">
            <div
              className="grid m-auto p-2 gap-0.5 bg-gray-300 rounded-xl"
              style={{
                gridAutoColumns: "minmax(0, 48px)",
                // gridAutoRows: "minmax(0px, min(48px, 10vw))",
              }}
            >
              {filler.map(({ x, y }) => (
                <div
                  key={`${x}_${y}`}
                  className="aspect-square"
                  style={{
                    gridColumnEnd: "span 1",
                    gridRowEnd: "span 1",
                    gridColumnStart: x,
                    gridRowStart: y,
                  }}
                />
              ))}
              {itemsList.map(
                ({ position, answer }) =>
                  position &&
                  [...new Array(answer.length)].map((_, i) => (
                    <div
                      key={`${answer}_${i}`}
                      className="bg-white aspect-square rounded-lg flex items-center justify-center font-bold w-full text-lg xs:text-2xl md:text-3xl text-gray-500 relative"
                      style={{
                        gridColumnEnd: "span 1",
                        gridRowEnd: "span 1",
                        gridColumnStart:
                          position.x +
                          (position.orientation === "across" ? i : 0),
                        gridRowStart:
                          position.y +
                          (position.orientation === "down" ? i : 0),
                      }}
                    >
                      {i === 0 && (
                        <div className="text-xs xs:text-sm absolute top-0 left-0.5 xs:top-0.5 xs:left-1 text-gray-500">
                          {position.index}
                        </div>
                      )}
                      {answer?.[i]}
                    </div>
                  ))
              )}
            </div>
          </div>
        )}

        {itemsList.map(({ answer, text, media, position }) => (
          <label
            className="bg-white rounded-lg p-1 flex flex-col relative category-group"
            key={answer}
          >
            <div className="flex items-center -mb-1.5 mt-1 px-2">
              <div className="text-xl uppercase font-bold text-gray-500 mr-auto">
                {answer}
              </div>
              {position && (
                <div className="flex gap-1 text-gray-400 items-center font-bold">
                  {position.orientation === "down" ? (
                    <TbArrowDown className="text-lg" strokeWidth={2.5} />
                  ) : (
                    <TbArrowRight className="text-lg" strokeWidth={2.5} />
                  )}
                  {position.index}
                </div>
              )}
            </div>
            <InputWithMedia
              text={text}
              media={media}
              onText={(newText) =>
                set((item) => {
                  item.items[answer].text = newText;
                })
              }
              onMedia={(newMedia) =>
                set((item) => {
                  item.items[answer].media = newMedia;
                })
              }
              className="grow"
              placeholder={t("v4.item.crossword.hintPlaceholder")}
            />

            <NewButton
              iconOnly
              color="bg-red-500 text-red-500"
              size="lg"
              className="mb-auto absolute top-0 -right-2.5 transform translate-x-full opacity-0 [.category-group:hover>&]:opacity-100"
              center
              onClick={() => {
                set((item) => {
                  delete item.items[answer];
                  const newItems = createCrossword(current(item).items);
                  if (newItems) item.items = newItems;
                });
              }}
            >
              <TbTrash />
            </NewButton>
          </label>
        ))}

        {dropMedia &&
        (dropMedia.type === DropMediaColumns.OneColumn ||
          dropMedia.type === DropMediaColumns.TwoColumns) ? (
          <div className="h-24 relative w-full">
            <DropMedia
              onDrop={(media) => {
                if (
                  media.type === DropMediaColumns.OneColumn ||
                  media.type === DropMediaColumns.TwoColumns
                ) {
                  return () => insertColumns(media);
                }
              }}
            />
          </div>
        ) : (
          <form
            className="flex"
            onSubmit={(e) => {
              e.preventDefault();
              if (!canAddWord) return;

              setNewWord("");
              set((item) => {
                item.items[newWord.toUpperCase()] = { text: "" };

                const currentItems = current(item).items;
                if (Object.keys(currentItems).length) {
                  const newItems = createCrossword(current(item).items);
                  if (newItems) item.items = newItems;
                }
              });
            }}
          >
            <input
              className="outline-0 bg-gray-50 rounded-lg py-1.5 px-3 flex items-center grow"
              placeholder={t("v4.item.crossword.wordPlaceholder")}
              maxLength={15}
              value={newWord}
              onChange={(e) => {
                const v = e.target.value.replace(/[^\p{L}-]/gu, "");
                setNewWord(v);
              }}
            />
            <NewButton
              className="ml-1"
              component="button"
              type="submit"
              variant="primary"
              disabled={!canAddWord}
            >
              <TbCheck /> {t("v4.generic.add")}
            </NewButton>
          </form>
        )}
      </div>
    </PageItemWrapper>
  );
};

type FocusedLetterState = null | {
  word: string;
  x: number;
  y: number;
  navigate?: boolean;
};

type CrosswordMoveDirection =
  | "forwards"
  | "backwards"
  | "up"
  | "down"
  | "left"
  | "right"
  | "previous"
  | "next";

export const PageCrosswordRender = withConsumption<CrosswordConsumption>(
  ({ answer, letters, items, id, set }) => {
    const { t } = useTranslation();
    const [focused, setFocused] = useState<FocusedLetterState>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(containerRef, () => focused && setFocused(null));

    const order = useMemo(
      () =>
        Object.entries(items)
          .sort(([, a], [, b]) => {
            if (!a?.position) return 1;
            if (!b?.position) return -1;
            if (a.position.index !== b.position.index)
              return a.position.index - b.position.index;
            return a.position.orientation === "across" ? -1 : 1;
          })
          .map(([answer]) => answer),
      []
    );

    const filler = useMemo(() => createFiller(items), [items]);

    const moveFocus = (direction = "forwards" as CrosswordMoveDirection) => {
      if (!focused) return;
      const position = items?.[focused.word]?.position;
      if (!position) return;

      const { x, y, word } = focused;

      const adjacentWord = (direction = 1 as 1 | -1) => {
        const current = order.findIndex((w) => w === focused.word);
        if (current < 0) return;
        const newWord =
          order?.[(current + order.length + direction) % order.length];
        if (!newWord) return;
        const word = items?.[newWord];
        if (!word?.position) return;
        if (direction === 1)
          return setFocused({
            word: newWord,
            x: word.position.x,
            y: word.position.y,
          });
        if (word.position.orientation === "down")
          return setFocused({
            word: newWord,
            x: word.position.x,
            y: word.position.y + newWord.length - 1,
          });
        return setFocused({
          word: newWord,
          x: word.position.x + newWord.length - 1,
          y: word.position.y,
        });
      };

      // todo: clean this up
      if (direction === "forwards" || direction === "next") {
        if (position.orientation === "across") {
          // if at last letter, cancel (or move to the next word if next)
          if (position.x + word.length - 1 === x) {
            if (direction !== "next") return;
            return adjacentWord();
          }
          setFocused({ ...focused, x: x + 1, navigate: false });
        } else {
          if (position.y + word.length - 1 === y) {
            if (direction !== "next") return;
            return adjacentWord();
          }
          setFocused({ ...focused, y: y + 1, navigate: false });
        }
        return;
      }

      if (direction === "backwards" || direction === "previous") {
        if (position.orientation === "across") {
          // if at first letter, cancel (or move to the next word if next)
          if (position.x === x) {
            if (direction !== "previous") return;
            return adjacentWord(-1);
          }
          setFocused({ ...focused, x: x - 1, navigate: false });
        } else {
          if (position.y === y) {
            if (direction !== "previous") return;
            return adjacentWord(-1);
          }
          setFocused({ ...focused, y: y - 1, navigate: false });
        }
        return;
      }

      if (
        (direction === "right" && position.orientation === "across") ||
        (direction === "down" && position.orientation === "down")
      )
        return moveFocus("forwards");
      if (
        (direction === "left" && position.orientation === "across") ||
        (direction === "up" && position.orientation === "down")
      )
        return moveFocus("backwards");

      const findFocus = (xChange = 0, yChange = 0) => {
        const orientation = xChange ? "across" : "down";
        const newX = x + xChange;
        const newY = y + yChange;

        const letter = letters?.[`${newX}_${newY}`];
        if (!letter) return;
        const parent = letter.parent.find(
          (p) => items?.[p]?.position?.orientation === orientation
        );
        if (!parent) return;

        setFocused({ word: parent, x: newX, y: newY });
      };

      if (direction === "up") return findFocus(0, -1);
      if (direction === "down") return findFocus(0, 1);
      if (direction === "left") return findFocus(-1);
      if (direction === "right") return findFocus(1);
    };

    const handleChange = (value: string, x: number, y: number) => {
      const letter = letters?.[`${x}_${y}`];
      if (!letter) return;

      set((item) => {
        for (const parent of letter.parent) {
          const parentData = items[parent];
          const position = parentData.position!;
          const index =
            position.orientation === "across" ? x - position.x : y - position.y;

          const currentAnswer = item.answer[parent];
          item.answer[parent] =
            currentAnswer.substring(0, index) +
            value.substring(0, 1).toUpperCase() +
            currentAnswer.substring(index + 1);
        }
      });
    };

    return (
      <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
        <QuizItemLabel type={PageItemType.Crossword} />
        <QuizInstructionsRender id={id} />
        {!order.length ? (
          <div className="bg-gray-300 p-4 text-center font-bold w-full rounded-xl">
            {t("v4.item.crossword.empty")}
          </div>
        ) : (
          <div className="p-2 flex items-center justify-center">
            <div
              ref={containerRef}
              style={{
                gridAutoColumns: "minmax(0, 48px)",
                // gridAutoRows: "minmax(0px, min(48px, 10vw))",
              }}
              className="grid m-auto w-max p-2 gap-0.5 bg-gray-300 rounded-xl pointer-events-none"
            >
              {filler.map(({ x, y }) => (
                <div
                  key={`${x}_${y}`}
                  className="aspect-square"
                  style={{
                    gridColumnEnd: "span 1",
                    gridRowEnd: "span 1",
                    gridColumnStart: x,
                    gridRowStart: y,
                  }}
                />
              ))}
              {Object.values(letters).map((item) => {
                const parentData = items[item.parent[0]];
                const position = parentData.position!;
                const index =
                  position.orientation === "across"
                    ? item.x - position.x
                    : item.y - position.y;

                return (
                  <CrosswordConsumptionLetter
                    key={`${item.x}_${item.y}`}
                    {...{ focused, setFocused, item }}
                    value={answer[item.parent[0]][index]}
                    onChange={(v) => handleChange(v, item.x, item.y)}
                    moveFocus={moveFocus}
                  />
                );
              })}
            </div>
          </div>
        )}
        {order.map((key) => {
          if (!items?.[key]) return null;
          const { text, media, position } = items[key];
          const active = focused?.word === key;

          return (
            <div
              className={classNames(
                "bg-white rounded-lg p-1 flex flex-col relative overflow-hidden group",
                active
                  ? "" /*"sm:sticky sm:bottom-2 shadow-xl"*/
                  : "cursor-pointer"
              )}
              key={key}
              onClick={(e) => {
                if (!position) return;
                if (
                  (e.target as HTMLDivElement).closest(
                    ".video-player, .audio-player"
                  )
                )
                  return;
                setFocused({
                  word: key,
                  x: position.x,
                  y: position.y,
                  navigate: true,
                });
              }}
            >
              <div
                className={classNames(
                  "pointer-events-none absolute-cover bg-primary transition",
                  active ? "opacity-20" : "opacity-0 group-hover:opacity-10"
                )}
              />
              <div className="flex items-center -mb-1.5 mt-1 px-2 relative">
                <div className="text-base sm:text-xl uppercase font-bold text-gray-500 mr-auto tracking-widest">
                  {answer?.[key]?.replaceAll(" ", "_")}
                </div>
                {position && (
                  <div className="flex gap-1 text-gray-400 items-center font-bold">
                    {position.orientation === "down" ? (
                      <TbArrowDown className="text-lg" strokeWidth={2.5} />
                    ) : (
                      <TbArrowRight className="text-lg" strokeWidth={2.5} />
                    )}
                    {position.index}
                  </div>
                )}
              </div>

              <InputWithMediaRender
                className="text-sm sm:text-base"
                {...{ text, media }}
              />
            </div>
          );
        })}
      </div>
    );
  }
);

const CrosswordConsumptionLetter = ({
  item,
  focused,
  setFocused,
  value,
  onChange,
  moveFocus,
}: {
  item: CrosswordConsumption["letters"][number];
  focused: FocusedLetterState;
  setFocused: (focused: FocusedLetterState) => void;
  value: string;
  onChange: (value: string, x?: number, y?: number) => void;
  moveFocus: (direction?: CrosswordMoveDirection) => void;
}) => {
  const { parent, index, x, y } = item;
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    if (!focused) return;
    if (focused.x === x && focused.y === y) {
      if (focused.navigate)
        ref.current.scrollIntoView({ behavior: "smooth", block: "center" });

      ref.current.focus({ preventScroll: !!focused.navigate });
    }
  }, [x, y, focused]);

  const handleOnFocus = () => {
    if (focused && parent.includes(focused.word)) {
      if (focused.x === x && focused.y === y) {
        const parentIndex =
          (parent.length + parent.indexOf(focused.word) + 1) % parent.length;
        setFocused({ word: parent[parentIndex], x, y });
      } else {
        setFocused({ word: focused.word, x, y });
      }
      return;
    }
    setFocused({ word: parent[0], x, y });
  };

  return (
    <label
      className="bg-white aspect-square rounded-lg w-full relative overflow-hidden pointer-events-auto text-gray-500 crossword-box"
      style={{
        gridColumnEnd: "span 1",
        gridRowEnd: "span 1",
        gridColumnStart: x,
        gridRowStart: y,
      }}
    >
      <input
        ref={ref}
        className={classNames(
          "absolute-cover outline-0 text-transparent select-none cursor-pointer transition",
          focused?.word && parent.includes(focused.word)
            ? "bg-primary bg-opacity-20 focus:bg-opacity-50"
            : "bg-transparent"
        )}
        value=""
        onPointerDown={(e) => {
          e.preventDefault();
          handleOnFocus();
        }}
        onKeyDown={(e) => {
          if (e.key === " ") {
            e.preventDefault();
            return;
          }
          if (e.key === "Backspace" || e.key === "Delete") {
            e.preventDefault();
            onChange(" ");
            moveFocus("backwards");
            return;
          }
          if (e.key === "Tab") {
            e.preventDefault();
            moveFocus(e.shiftKey ? "previous" : "next");
            return;
          }
          if (e.key === "Enter") {
            e.preventDefault();
            handleOnFocus();
            return;
          }
          if (e.key === "ArrowLeft") {
            e.preventDefault();
            moveFocus("left");
            return;
          }
          if (e.key === "ArrowRight") {
            e.preventDefault();
            moveFocus("right");
            return;
          }
          if (e.key === "ArrowUp") {
            e.preventDefault();
            moveFocus("up");
            return;
          }
          if (e.key === "ArrowDown") {
            e.preventDefault();
            moveFocus("down");
            return;
          }
        }}
        onChange={(e) => {
          onChange(e.target.value);
          moveFocus("forwards");
        }}
      />
      {index && (
        <div className="text-xs xs:text-sm absolute top-0 left-0.5 xs:top-0.5 xs:left-1 font-bold">
          {index}
        </div>
      )}
      <div className="absolute-cover flex items-center justify-center text-center text-lg xs:text-2xl md:text-3xl font-bold pointer-events-none">
        {value}
      </div>
    </label>
  );
};

export const PageCrosswordResults = ({ item }: { item: CrosswordResults }) => {
  const order = useMemo(
    () =>
      Object.entries(item.items)
        .sort(([, a], [, b]) => {
          if (!a?.position) return 1;
          if (!b?.position) return -1;
          if (a.position.index !== b.position.index)
            return a.position.index - b.position.index;
          return a.position.orientation === "across" ? -1 : 1;
        })
        .filter(([, { position }]) => !!position)
        .map(([answer]) => answer),
    []
  );

  const itemsList = useMemo(
    () =>
      Object.entries(item.items).map(([answer, item]) => ({ answer, ...item })),
    [item.items]
  );

  const hasCrossword = useMemo(
    () => Object.values(item.items).some(({ position }) => !!position),
    [item.items]
  );

  const filler = useMemo(() => createFiller(item.items), [item.items]);

  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <InstructionsRender instructions={item.instructions} />
      {hasCrossword && (
        <div className="p-2 flex items-center justify-center">
          <div
            className="grid m-auto p-2 gap-0.5 bg-gray-300 rounded-xl"
            style={{
              gridAutoColumns: "minmax(0, 48px)",
              // gridAutoRows: "minmax(0px, min(48px, 10vw))",
            }}
          >
            {filler.map(({ x, y }) => (
              <div
                key={`${x}_${y}`}
                className="aspect-square"
                style={{
                  gridColumnEnd: "span 1",
                  gridRowEnd: "span 1",
                  gridColumnStart: x,
                  gridRowStart: y,
                }}
              />
            ))}
            {itemsList.map(
              ({ position, answer }) =>
                position &&
                [...new Array(answer.length)].map((_, i) => (
                  <div
                    key={`${answer}_${i}`}
                    className={classNames(
                      "aspect-square rounded-lg flex items-center justify-center font-bold w-full text-lg xs:text-2xl md:text-3xl relative",
                      item.answer?.[answer]?.[i] === answer?.[i]
                        ? "bg-green-100 text-green-700"
                        : "bg-red-100 text-red-700"
                    )}
                    style={{
                      gridColumnEnd: "span 1",
                      gridRowEnd: "span 1",
                      gridColumnStart:
                        position.x +
                        (position.orientation === "across" ? i : 0),
                      gridRowStart:
                        position.y + (position.orientation === "down" ? i : 0),
                    }}
                  >
                    {i === 0 && (
                      <div className="text-xs xs:text-sm absolute top-0 left-0.5 xs:top-0.5 xs:left-1">
                        {position.index}
                      </div>
                    )}
                    {item.answer?.[answer]?.[i]}
                    {/*{answer?.[i]}*/}
                  </div>
                ))
            )}
          </div>
        </div>
      )}

      {order.map((key) => {
        if (!item.items?.[key]) return null;
        const { text, media, position } = item.items[key];
        const correct = !!item.result?.[key];
        return (
          <div className="flex items-stretch" key={key}>
            <CorrectMarker correct={correct} content={key} />
            <div className="grow bg-white rounded-lg p-1 flex flex-col relative overflow-hidden group">
              <div className="flex items-center -mb-1.5 mt-1 px-2 relative">
                <div className="text-base sm:text-xl uppercase font-bold text-gray-500 mr-auto tracking-widest">
                  {item.answer?.[key]?.replaceAll(" ", "_")}
                </div>
                {position && (
                  <div className="flex gap-1 text-gray-400 items-center font-bold">
                    {position.orientation === "down" ? (
                      <TbArrowDown className="text-lg" strokeWidth={2.5} />
                    ) : (
                      <TbArrowRight className="text-lg" strokeWidth={2.5} />
                    )}
                    {position.index}
                  </div>
                )}
              </div>

              <InputWithMediaRender
                className="text-sm sm:text-base"
                {...{ text, media }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const PageCrosswordCorrect = ({ item }: { item: Crossword }) => {
  const order = useMemo(
    () =>
      Object.entries(item.items)
        .sort(([, a], [, b]) => {
          if (!a?.position) return 1;
          if (!b?.position) return -1;
          if (a.position.index !== b.position.index)
            return a.position.index - b.position.index;
          return a.position.orientation === "across" ? -1 : 1;
        })
        .filter(([, { position }]) => !!position)
        .map(([answer]) => answer),
    []
  );

  const itemsList = useMemo(
    () =>
      Object.entries(item.items).map(([answer, item]) => ({ answer, ...item })),
    [item.items]
  );

  const hasCrossword = useMemo(
    () => Object.values(item.items).some(({ position }) => !!position),
    [item.items]
  );

  const filler = useMemo(() => createFiller(item.items), [item.items]);

  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <InstructionsRender instructions={item.instructions} />

      {hasCrossword && (
        <div className="p-2 flex items-center justify-center">
          <div
            className="grid m-auto p-2 gap-0.5 bg-gray-300 rounded-xl"
            style={{
              gridAutoColumns: "minmax(0, 48px)",
              // gridAutoRows: "minmax(0px, min(48px, 10vw))",
            }}
          >
            {filler.map(({ x, y }) => (
              <div
                key={`${x}_${y}`}
                className="aspect-square"
                style={{
                  gridColumnEnd: "span 1",
                  gridRowEnd: "span 1",
                  gridColumnStart: x,
                  gridRowStart: y,
                }}
              />
            ))}
            {itemsList.map(
              ({ position, answer }) =>
                position &&
                [...new Array(answer.length)].map((_, i) => (
                  <div
                    key={`${answer}_${i}`}
                    className="bg-white aspect-square rounded-lg flex items-center justify-center font-bold w-full text-lg xs:text-2xl md:text-3xl text-gray-500 relative"
                    style={{
                      gridColumnEnd: "span 1",
                      gridRowEnd: "span 1",
                      gridColumnStart:
                        position.x +
                        (position.orientation === "across" ? i : 0),
                      gridRowStart:
                        position.y + (position.orientation === "down" ? i : 0),
                    }}
                  >
                    {i === 0 && (
                      <div className="text-xs xs:text-sm absolute top-0 left-0.5 xs:top-0.5 xs:left-1 text-gray-500">
                        {position.index}
                      </div>
                    )}
                    {answer?.[i]}
                  </div>
                ))
            )}
          </div>
        </div>
      )}

      {order.map((key) => {
        if (!item.items?.[key]) return null;
        const { text, media, position } = item.items[key];
        return (
          <div className="flex items-stretch" key={key}>
            <CorrectnessMarker ids={[item.id, key]} />
            <div className="grow bg-white rounded-lg p-1 flex flex-col relative overflow-hidden group">
              <div className="flex items-center -mb-1.5 mt-1 px-2 relative">
                <div className="text-base sm:text-xl uppercase font-bold text-gray-500 mr-auto">
                  {key.replaceAll(" ", "_")}
                </div>
                {position && (
                  <div className="flex gap-1 text-gray-400 items-center font-bold">
                    {position.orientation === "down" ? (
                      <TbArrowDown className="text-lg" strokeWidth={2.5} />
                    ) : (
                      <TbArrowRight className="text-lg" strokeWidth={2.5} />
                    )}
                    {position.index}
                  </div>
                )}
              </div>

              <InputWithMediaRender
                className="text-sm sm:text-base"
                {...{ text, media }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
