import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { activateRegistration } from "app/store/authentication/authActions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "app/store";
import { authRoutes } from "enums/routes";
import { Link } from "react-router-dom";
import { NewButton } from "app/components/Buttons/NewButton";

const ScMessage = styled.span`
  display: flex;
  margin: 10px auto;
  justify-content: center;
`;

const Activation: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { uid } = useParams<{ uid: string }>();
  const { token } = useParams<{ token: string }>();
  const dispatch: AppDispatch = useDispatch();
  const [generalMessage, setGeneralMessage] = useState<string>("");
  const [loginButton, setLoginButton] = useState(false);

  const activationAccountCallback = useCallback(async () => {
    const data = {
      uid: uid,
      token: token,
    };
    const res: any = await dispatch(activateRegistration(data));
    if (res.type === "auth/post/activate/rejected") {
      setGeneralMessage(t("authPages.register.couldNotActivate.label"));
    } else {
      setGeneralMessage(t("authPages.register.activated.label"));
      setLoginButton(true);
    }
  }, [dispatch, uid, token, t]);

  useEffect(() => {
    if (token !== "") {
      activationAccountCallback();
    }
  }, [token, activationAccountCallback]);

  return (
    <section>
      <h2 className="text-center">
        {t("authPages.register.activation.label")}
      </h2>
      <ScMessage>{generalMessage}</ScMessage>
      {loginButton && (
        <NewButton
          size="lg"
          variant="primary"
          center
          component={Link}
          to={authRoutes.login}
          className="w-full max-w-xs mx-auto mt-4"
        >
          {t("authPages.signIn.submitBtn.label")}
        </NewButton>
      )}
    </section>
  );
};

export default Activation;
