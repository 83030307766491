import React, { useEffect } from "react";
import { useSessionContext } from "app/hooks/useSessionContext";
import { CoursePageListSimple } from "app/pages/courses/CourseDetails";

export const CourseExercisesList = () => {
  const { exercises, session, selectedExercises } = useSessionContext();

  const pages = session.get?.exercises;

  useEffect(() => {
    if (pages && !exercises.get.length) {
      exercises.set(pages);

      // () => 1 set exercises checked by default, change to () => 0 to revert this
      const exercises_in_scope = session.get.status?.exercises_in_scope
        ? session.get.status?.exercises_in_scope
        : session.get.exercises.map(() => 1);
      selectedExercises.set(exercises_in_scope);
    }
  }, [pages]);

  if (!pages?.length) return null;
  return (
    <div className="flex justify-center">
      <CoursePageListSimple pages={pages ?? []} />
    </div>
  );
};
