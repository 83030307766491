import { useTranslation } from "react-i18next";
import React, { Fragment, useEffect, useState } from "react";
import {
  useSourceUploadContext,
  useSourceUploadStore,
} from "api/ws/SourceUploadContext";
import { useLibrary } from "app/components/Sources/MediaPicker/SourceList/useLibrary";
import {
  ACCEPTED_FILE_TYPES,
  FileType,
  mapFileTypeToName,
  mapMime,
  mapSourceToType,
} from "helpers/mimeType";
import {
  TbBook,
  TbCheck,
  TbChevronDown,
  TbChevronRight,
  TbDeviceMobile,
  TbDots,
  TbFile,
  TbFilter,
  TbFolder,
  TbFolderPlus,
  TbFolders,
  TbPhoto,
  TbPlus,
  TbRefresh,
  TbSearch,
  TbUpload,
  TbUser,
  TbVideo,
  TbVolume,
  TbWorld,
  TbWorldUpload,
} from "react-icons/tb";
import { NewButton } from "app/components/Buttons/NewButton";
import { FloatingMenu } from "app/components/Header";
import { FilterSelectMenu } from "app/components/Sources/MediaPicker/SourceList/SourceList";
import {
  MediaPicker,
  MediaPickerView,
  SelectOnlyType,
} from "app/components/Sources/MediaPicker/MediaPicker";
import classNames from "classnames";
import { useDateFormat } from "app/hooks/useDateFormat";
import { InView } from "react-intersection-observer";
import { useDropzone } from "react-dropzone";
import { FILE_SIZE_LIMIT } from "helpers/file";
import { toast } from "react-hot-toast";
import {
  NavigationItem,
  Skeleton,
} from "app/pages/courses/Edit/CourseSourcesPanel";
import { Checkbox } from "app/components/Buttons/InputCheckbox";
import { create } from "zustand";
import { combine } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { useScrollDirection } from "app/hooks/useScrollDirection";
import { SourceContextMenu } from "app/pages/courses/SourceContextMenu";
import {
  CreateProtectedContext,
  useProtectedContext,
} from "app/hooks/useProtectedContext";
import { useMutation, useQueryClient } from "react-query";
import { putSource } from "api/sourcesAPI";
import { postUserCreateFolder, putFolder } from "api/exerciseLibraryAPI";
import { putEditCourse } from "api/course/courseAPI";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { courseRoutes, libraryRoutes } from "enums/routes";
import { useRole } from "app/hooks/useRole";
import { Collapse } from "app/components/Collapse";
import { SaveSharedItem } from "app/pages/courses/CoursesListView";

export type SelectedSources = Record<
  string,
  {
    type: FileType;
    name: string;
    children?: number;
  }
>;

export const LibrarySwitchButtons = () => {
  const { t } = useTranslation();
  const isFolders = !!useRouteMatch({
    path: libraryRoutes.library,
    exact: true,
  });
  const isPublic = !!useRouteMatch({
    path: libraryRoutes.public,
    exact: true,
  });

  return (
    <div className="flex gap-1 p-1 bg-white rounded-xl">
      <Link
        to={libraryRoutes.library}
        className={isFolders ? "pointer-events-none" : ""}
        replace
      >
        <NewButton
          variant={isFolders ? "light" : "transparent"}
          color={isFolders ? "bg-primary text-primary" : undefined}
        >
          <TbUser /> {t("v4.library.userLibrary")}
        </NewButton>
      </Link>
      <Link
        to={libraryRoutes.public}
        className={isPublic ? "pointer-events-none" : ""}
        replace
      >
        <NewButton
          variant={isPublic ? "light" : "transparent"}
          color={isPublic ? "bg-primary text-primary" : undefined}
        >
          <TbWorld /> {t("v4.library.publicLibrary")}
        </NewButton>
      </Link>
    </div>
  );
};

export const useSelectedSources = create(
  immer(
    combine({ selected: {} as SelectedSources }, (set) => ({
      clear: () => set({ selected: {} }),
      add: ({
        id,
        type,
        name,
        children,
      }: {
        id: string;
        type: FileType;
        name: string;
        children?: number;
      }) =>
        set((state) => {
          state.selected[id] = { type, name, children };
        }),
      remove: (id: string) =>
        set((state) => {
          delete state.selected[id];
        }),
    }))
  )
);

export const CurrentFolderContext = CreateProtectedContext("");
export const useCurrentFolderContext = () =>
  useProtectedContext(CurrentFolderContext);

export const LibraryView = () => {
  const { upload } = useSourceUploadContext();
  const role = useRole();
  const isScrollDown = useScrollDirection();
  const selectedCount = useSelectedSources(
    (state) => Object.values(state.selected).length
  );
  const clearSelected = useSelectedSources((state) => state.clear);
  const { t } = useTranslation();
  const {
    list,
    query,
    folderId,
    setFolderId,
    input,
    setInput,
    bindFilter,
    filters,
  } = useLibrary({
    initialFilters: {
      lesson: false,
      course: true,
      document: true,
      image: true,
      video: true,
      audio: true,
    },
  });
  const [mediaPicker, setMediaPicker] = useState<boolean | MediaPickerView>(
    false
  );
  const [sourceId, setSourceId] = useState<string | null>(null);
  const [newFolderName, setNewFolderName] = useState<string | null>(null);
  const history = useHistory();

  const onItemClick = (type: FileType, id: string) => () => {
    if (type === FileType.Course) return history.push(courseRoutes.details(id));
    if (type === FileType.Folder) return setFolderId(id);
    setSourceId(id);
    setMediaPicker(true);
  };

  useEffect(() => {
    if (!mediaPicker) setSourceId(null);
  }, [mediaPicker]);

  const navigationStack = query.data?.pages?.[0]?.header?.navigation_stack;

  const uploaded = useSourceUploadStore((state) => Object.entries(state.data));

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: ACCEPTED_FILE_TYPES,
    noKeyboard: true,
    noClick: true,
    multiple: false,
    onDrop: async (acceptedFiles: any) => {
      const file = acceptedFiles?.[0] as File;
      if (!file) return;

      if (file.size > FILE_SIZE_LIMIT) {
        toast.error(t("sourcesPage.status.uploading.errorSize"));
        return;
      }

      const mimeType = !!file?.type && mapMime(file.type);
      if (
        mimeType === false ||
        ![
          FileType.Document,
          FileType.Image,
          FileType.Audio,
          FileType.Video,
        ].includes(mimeType)
      ) {
        toast.error(t("sourcesPage.uploadNew.uploadTypeError"));
        return;
      }
      upload({ file });
    },
  });

  const newFolderMutation = useMutation(
    () =>
      postUserCreateFolder({
        id: folderId,
        dataToSave: { name: newFolderName },
      }),
    {
      onSuccess: () => {
        query.refetch();
        setNewFolderName(null);
      },
    }
  );
  ``;

  return (
    <CurrentFolderContext.Provider value={folderId}>
      <div className="py-4 px-3 sm:px-8">
        <div className="flex gap-1 mb-2">
          <LibrarySwitchButtons />
          <div className="grow" />
          <FloatingMenu
            size="xs"
            placement="bottom-end"
            trigger={(toggle) => (
              <NewButton variant="primary" size="lg" onClick={toggle}>
                <TbPlus /> {t("v4.generic.newEllipsis")}
              </NewButton>
            )}
          >
            {({ setIsOpen }) => (
              <>
                <NewButton
                  variant="transparent"
                  onClick={() => {
                    open();
                    setIsOpen(false);
                  }}
                >
                  <TbUpload /> {t("v4.library.uploadSource")}
                </NewButton>
                <NewButton
                  variant="transparent"
                  onClick={() => {
                    setMediaPicker(MediaPickerView.Mobile);
                    setIsOpen(false);
                  }}
                >
                  <TbDeviceMobile /> {t("v4.library.mobileUpload")}
                </NewButton>
                {!role.prodMode && (
                  <NewButton
                    variant="transparent"
                    onClick={() => {
                      setMediaPicker(MediaPickerView.Website);
                      setIsOpen(false);
                    }}
                  >
                    <TbWorldUpload /> {t("v4.library.websiteUpload.text")}
                  </NewButton>
                )}
                <NewButton
                  variant="transparent"
                  onClick={() => {
                    setMediaPicker(MediaPickerView.Record);
                    setIsOpen(false);
                  }}
                >
                  <TbVideo /> {t("v4.library.record")}
                </NewButton>
                <div className="border-b border-gray-100 border-t my-0.5" />
                <NewButton
                  variant="transparent"
                  onClick={() => {
                    setNewFolderName("");
                    setIsOpen(false);
                  }}
                  disabled={newFolderMutation.isLoading}
                >
                  <TbFolderPlus /> {t("v4.library.folder")}
                </NewButton>
              </>
            )}
          </FloatingMenu>
        </div>
        <div className="flex flex-col bg-white rounded-xl">
          <div
            className="flex flex-col text-gray-600"
            {...(query.isSuccess && getRootProps())}
            role={undefined}
          >
            <div
              className={classNames(
                "flex flex-col sticky w-full bg-white pb-1 pt-1 rounded-xl",
                "top-24 md:top-14 z-[2] transition",
                isScrollDown && "-translate-y-24 md:translate-y-0"
              )}
            >
              <div className="flex gap-0.5 items-center mx-3 px-1 mt-1">
                {folderId && !query.isSuccess ? (
                  <div className="h-2 mt-1 bg-gray-200 w-1/2 rounded-full animate-pulse" />
                ) : (
                  <>
                    {!!navigationStack?.length && (
                      <>
                        <NavigationItem onClick={onItemClick} id="">
                          {t("v4.library.text")}
                        </NavigationItem>
                        <TbChevronRight className="text-gray-400 text-sm" />
                      </>
                    )}
                    {navigationStack?.length > 3 && (
                      <>
                        <TbDots className="text-gray-500 text-sm" />
                        <TbChevronRight className="text-gray-400 text-sm" />
                      </>
                    )}
                    {navigationStack?.slice(-3, -1).map(({ id, name }) => (
                      <Fragment key={id}>
                        <NavigationItem id={id} onClick={onItemClick}>
                          {name}
                        </NavigationItem>
                        <TbChevronRight className="text-gray-400 text-sm" />
                      </Fragment>
                    ))}
                  </>
                )}
              </div>
              <div className="px-2 pb-1 font-bold flex items-center gap-1">
                <TbFolders className="text-2xl shrink-0 mr-1 ml-2" />
                {navigationStack?.slice(-1)?.[0]?.name ?? t("v4.library.text")}
                <div className="grow" />

                <label
                  className={classNames(
                    !input &&
                      "[&:not(:focus-within)]:cursor-pointer [&:not(:focus-within)]:hover:bg-gray-200",
                    "hidden sm:flex bg-gray-100 group focus-within:bg-gray-200 group transition rounded-lg items-center text-gray-600"
                  )}
                >
                  <TbSearch className="text-xl ml-2" />
                  <input
                    className={classNames(
                      input
                        ? "max-w-[10rem] px-2"
                        : "max-w-0 group-focus-within:max-w-[10rem] px-1 group-focus-within:px-2",
                      "transition-all py-1.5 min-w-0 bg-transparent outline-none"
                    )}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder={t("v4.generic.search")}
                  />
                </label>

                <FloatingMenu
                  trigger={(toggle) => (
                    <NewButton onClick={toggle} className="hidden sm:flex">
                      <TbFilter /> {t("v4.generic.filter")}
                      <TbChevronDown className="!text-xs" />
                    </NewButton>
                  )}
                  placement="bottom-end"
                  size="xs"
                  className="space-y-1"
                  portal
                >
                  <FilterSelectMenu bindFilter={bindFilter} />
                </FloatingMenu>

                <NewButton
                  iconOnly
                  size="lg"
                  className="shrink-0 hidden sm:flex"
                  onClick={() => {
                    query.remove();
                    query.refetch();
                  }}
                >
                  <TbRefresh />
                </NewButton>

                <NewButton
                  iconOnly
                  variant="transparent"
                  className="shrink-0 sm:hidden"
                  onClick={() => {
                    query.remove();
                    query.refetch();
                  }}
                >
                  <TbRefresh />
                </NewButton>
              </div>
              <div className="flex sm:hidden px-2 gap-1">
                <label className="flex grow bg-gray-100 focus-within:bg-gray-200 transition rounded-lg items-center">
                  <TbSearch className="text-xl ml-2" />
                  <input
                    className="py-1 px-2 min-w-0 w-full bg-transparent outline-none"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder={t("v4.generic.search")}
                  />
                </label>

                <FloatingMenu
                  portal
                  trigger={(toggle) => (
                    <NewButton onClick={toggle} iconOnly>
                      <TbFilter />
                    </NewButton>
                  )}
                  placement="bottom-end"
                  size="sm"
                  className="space-y-1"
                >
                  <FilterSelectMenu bindFilter={bindFilter} />
                </FloatingMenu>
              </div>
              <Collapse>
                {!!selectedCount && (
                  <div className="bg-white relative p-1 text-white flex">
                    <div className="absolute-cover bg-primary opacity-20" />
                    <NewButton
                      className="font-bold relative"
                      onClick={clearSelected}
                    >
                      <TbCheck /> {selectedCount} {t("v4.library.selected")}
                    </NewButton>
                    <div className="grow" />
                    <SourceContextMenu
                      options={[["assign"], ["move", "delete"]]}
                    />
                  </div>
                )}
              </Collapse>
            </div>
            {mediaPicker && (
              <MediaPicker
                close={() => setMediaPicker(false)}
                folderId={folderId}
                sourceId={sourceId}
                onInsert={null}
                search={input}
                filters={filters}
                selectOnly={SelectOnlyType.none}
                initialView={
                  typeof mediaPicker === "boolean" ? undefined : mediaPicker
                }
              />
            )}
            {!query.isSuccess ? (
              <Skeleton />
            ) : (
              <>
                {/* upload by dragging over */}
                <input {...getInputProps()} />
                <div
                  className={classNames(
                    "absolute-cover p-8 z-20 pointer-events-none bg-white bg-opacity-50 filter transition backdrop-blur opacity-0",
                    isDragActive && "opacity-100"
                  )}
                >
                  <div className="w-full h-full border-4 rounded-lg border-dashed border-gray-300 flex flex-col gap-4 text-center items-center justify-center text-gray-500">
                    <TbUpload className="text-5xl" strokeWidth={3} />
                    <div className="text-xl font-bold">
                      {t("v4.library.uploadDrop")}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col">
                  {newFolderName != null && (
                    <div className="flex items-center mx-3 gap-2 px-1 py-1 rounded-lg transition -order-2">
                      <div className="w-9 h-9 shrink-0 relative flex items-center justify-center ml-9">
                        <TbFolder
                          className="text-3xl text-gray-500"
                          strokeWidth={1.5}
                        />
                      </div>
                      <div className="flex flex-col min-w-0 leading-none grow">
                        {newFolderMutation.isLoading ? (
                          <div className="font-bold overflow-hidden overflow-ellipsis whitespace-nowrap text-gray-400 animate-pulse">
                            {newFolderName}
                          </div>
                        ) : (
                          <form
                            className="flex grow relative"
                            onSubmit={(e) => {
                              e.preventDefault();
                              if (!newFolderName) return setNewFolderName(null);
                              newFolderMutation.mutate();
                            }}
                          >
                            <input
                              className="w-full font-bold overflow-hidden overflow-ellipsis whitespace-nowrap bg-primary bg-opacity-20 rounded-lg py-1 px-1 -mx-1 outline-none"
                              onClick={(e) => e.stopPropagation()}
                              onPointerDown={(e) => e.stopPropagation()}
                              value={newFolderName || ""}
                              placeholder={t("v4.library.newFolder")}
                              onChange={(e) => setNewFolderName(e.target.value)}
                              onBlur={() => {
                                if (!newFolderName)
                                  return setNewFolderName(null);
                                newFolderMutation.mutate();
                              }}
                              autoFocus
                            />
                          </form>
                        )}
                      </div>
                    </div>
                  )}
                  {!!uploaded.length && (
                    <div className="mx-3 mb-2 flex flex-col">
                      {uploaded.map(
                        ([
                          id,
                          { type, name, progress, data, uploadId, ...item },
                        ]) =>
                          !data &&
                          !uploadId &&
                          ((!folderId && !item.folderId) ||
                            folderId === item.folderId) && (
                            <div
                              key={id}
                              className="flex items-center gap-2 p-1 py-1 rounded-lg animate-pulse"
                            >
                              <div className="w-9 h-9 shrink-0 relative flex items-center justify-center">
                                {type === FileType.Audio ? (
                                  <TbVolume
                                    className="text-3xl text-gray-500"
                                    strokeWidth={1.5}
                                  />
                                ) : type === FileType.Video ? (
                                  <TbVideo
                                    className="text-3xl text-gray-500"
                                    strokeWidth={1.5}
                                  />
                                ) : type === FileType.Image ? (
                                  <TbPhoto
                                    className="text-3xl text-gray-500"
                                    strokeWidth={1.5}
                                  />
                                ) : (
                                  <TbFile
                                    className="text-3xl text-gray-500"
                                    strokeWidth={1.5}
                                  />
                                )}
                              </div>
                              <div className="flex flex-col min-w-0 leading-none grow pr-2">
                                <div className="font-bold overflow-hidden overflow-ellipsis whitespace-nowrap">
                                  {name}
                                </div>
                                <div className="bg-gray-200 mt-1.5 h-1.5 rounded-full w-full relative overflow-hidden">
                                  {progress != null && (
                                    <div
                                      className="transition-[width] bg-primary rounded-full h-full"
                                      style={{ width: progress + "%" }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  )}

                  {!list.length ? (
                    <div className="text-center text-gray-400 py-5">
                      {t("v4.library.noItems")}
                    </div>
                  ) : (
                    list.map((item, i) => (
                      <FoldersItem
                        key={item?.id ?? i}
                        item={item}
                        onClick={onItemClick}
                      />
                    ))
                  )}
                </div>
                {query.hasNextPage && (
                  <InView
                    as="div"
                    className="flex"
                    onChange={(inView) =>
                      inView &&
                      !query.isFetchingNextPage &&
                      query.fetchNextPage()
                    }
                    key={query.data.pages.length}
                  >
                    {({ inView, ref }) => (
                      <div
                        ref={ref}
                        className={classNames(
                          "flex flex-col gap-3 transition",
                          !inView && "opacity-0"
                        )}
                      >
                        <Skeleton />
                      </div>
                    )}
                  </InView>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <SaveSharedItem />
    </CurrentFolderContext.Provider>
  );
};

const FoldersItem = ({ item, onClick }: { item: any; onClick: any }) => {
  const { id, name, thumbnail_url, child_count } = item;
  const selected = useSelectedSources((state) => !!state.selected?.[id]);
  const [add, remove] = useSelectedSources((state) => [
    state.add,
    state.remove,
  ]);
  const [rename, setRename] = useState<null | string>(null);
  const { t } = useTranslation();
  const format = useDateFormat();
  const type = mapSourceToType(item);
  const queryClient = useQueryClient();

  const renameMutation = useMutation(
    () =>
      type === FileType.Folder
        ? putFolder(id, { name: rename })
        : type === FileType.Course
        ? putEditCourse(id, { name: rename || "" })
        : putSource(id, { name: rename }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["library"]);
        setRename(null);
      },
    }
  );

  return (
    <div
      key={item.id + "_" + item.entry_id}
      onClick={onClick(type, id)}
      className={classNames(
        "flex items-center mx-3 gap-2 px-1 py-1 rounded-lg bg-gray-800 bg-opacity-0 hover:bg-opacity-5 cursor-pointer transition",
        type === FileType.Folder && "-order-1"
      )}
    >
      <Checkbox
        isSelected={selected}
        onPointerDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (selected) remove(id);
          else add({ id, name, type, children: child_count || undefined });
        }}
      />
      <div className="w-9 h-9 shrink-0 relative flex items-center justify-center">
        {type === FileType.Folder ? (
          <TbFolder className="text-3xl text-gray-500" strokeWidth={1.5} />
        ) : type === FileType.Course ? (
          <TbBook className="text-3xl text-gray-500" strokeWidth={1.5} />
        ) : type === FileType.Audio ? (
          <TbVolume className="text-3xl text-gray-500" strokeWidth={1.5} />
        ) : (
          thumbnail_url && (
            <img
              src={thumbnail_url}
              className="absolute-cover object-cover bg-gray-200 rounded-lg overflow-hidden"
            />
          )
        )}
      </div>
      <div className="flex flex-col min-w-0 leading-none grow">
        {renameMutation.isLoading ? (
          <div className="font-bold overflow-hidden overflow-ellipsis whitespace-nowrap text-gray-400 animate-pulse">
            {rename}
          </div>
        ) : rename != null ? (
          <form
            className="flex grow relative"
            onSubmit={(e) => {
              e.preventDefault();
              if (rename === name) return setRename(null);
              renameMutation.mutate();
            }}
          >
            <input
              className="w-full font-bold overflow-hidden overflow-ellipsis whitespace-nowrap bg-primary bg-opacity-20 rounded-lg py-0.5 px-1 -my-0.5 -mx-1 outline-none"
              onClick={(e) => e.stopPropagation()}
              onPointerDown={(e) => e.stopPropagation()}
              value={rename}
              onChange={(e) => setRename(e.target.value)}
              onBlur={() => {
                if (rename === name) return setRename(null);
                renameMutation.mutate();
              }}
              autoFocus
            />
          </form>
        ) : (
          <div className="font-bold overflow-hidden overflow-ellipsis whitespace-nowrap">
            {name}
          </div>
        )}
        <div className="text-xs text-gray-400 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <span>{mapFileTypeToName(t)(type)}</span>
          <span> • </span>
          {type === FileType.Folder
            ? item.child_count != null && (
                <span>
                  {item.child_count}{" "}
                  {item.child_count === 1
                    ? t("common.exercises.item.text")
                    : t("common.exercises.item.plural")}
                </span>
              )
            : item.recent_date && (
                <span>{format(item.recent_date, "distance")}</span>
              )}
        </div>
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <SourceContextMenu
          options={[
            ["assign", "present"],
            ["edit", "preview", "send", "download", "json"],
            ["rename", "move", "delete"],
          ]}
          item={{ id, type, name, children: child_count }}
          onRename={() => setRename(name)}
        />
      </div>
    </div>
  );
};
