import { apiUrlV1 } from "enums/constant";
import axios from "axios";

export const getCourseAssignments = async (id: string, { page = 0, only }) => {
  const url =
    `${apiUrlV1}/courses/activities/${id}/` +
    `?limit=${20}` +
    `&offset=${page * 20}` +
    (only ? `&only=${only}` : "");
  const res = await axios.get(url);
  return res.data;
};

export const getGroupAssignments = async (id: string, { page = 0 }) => {
  const url =
    `${apiUrlV1}/v4/group/assignments/${id}/` +
    `?limit=${20}` +
    `&offset=${page * 20}`;
  const res = await axios.get(url);
  return res.data;
};

export const getGroupAssignmentsReceived = async (
  groupId: string,
  { page = 0 }
) => {
  const url =
    `${apiUrlV1}/courses/received/${groupId}/` +
    `?limit=${20}` +
    `&offset=${page * 20}`;
  const res = await axios.get(url);
  return res.data;
};

export const postCreateAssignment = async (
  courses: string[],
  groups: string[],
  isSingleAttempt = false
) => {
  const url = `${apiUrlV1}/courses/assign/`;
  const res = await axios.post(url, {
    courses,
    groups,
    is_single_attempt: isSingleAttempt,
  });
  return res.data;
};

export const putEditAssignment = async (
  assignments: string[] | string,
  data: { is_single_attempt?: boolean } = {}
) => {
  const url = `${apiUrlV1}/courses/edit_assignments/`;
  const res = await axios.post(url, {
    assignments: typeof assignments === "string" ? [assignments] : assignments,
    ...data,
  });
  return res.data;
};
