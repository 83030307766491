import { motion, useSpring } from "framer-motion";
import React, { PropsWithChildren, ReactNode, useEffect, useRef } from "react";
import Tippy from "@tippyjs/react/headless";
import { sticky } from "tippy.js";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbCopy, TbCut, TbTrash, TbWand } from "react-icons/tb";
import {
  usePageItemContext,
  usePageItemIndex,
  useSetList,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import { useSlideItemContext } from "app/components/Exercises/CourseEdit/items/SlideItemContext";
import { FloatingMenu } from "app/components/Header";
import { useDropMedia } from "app/components/Sources/MediaPicker/context/dropMediaContext";
import { SlideItem } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { Tooltip } from "app/components/Tooltip";
import { useRole } from "../../../../hooks/useRole";

const config = { damping: 50, stiffness: 1000, mass: 1 };
const INITIAL_SCALE = 0.9;

export const SlideItemWrapper = ({
  children,
  toolbar,
  customToolbar,
  magic,
  open,
}: PropsWithChildren<{
  customToolbar?: (trash: ReactNode) => ReactNode;
  toolbar?: ReactNode;
  magic?: ((close: () => void) => ReactNode) | false | ReactNode;
  open?: boolean;
}>) => {
  const role = useRole();
  const [data] = usePageItemContext<SlideItem>();
  const index = usePageItemIndex();
  const setList = useSetList();
  const { selected, select } = useSlideItemContext();
  const opacity = useSpring(0, config);
  const scale = useSpring(INITIAL_SCALE, config);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const blockRef = useRef<HTMLDivElement | null>(null);
  const [, setDropMedia] = useDropMedia();

  const handleDelete = () => {
    setList((list) => {
      list.splice(index, 1);
    });
  };

  const handleCut = () => {
    const { category, position, ...media } = data;
    setDropMedia(media);
    handleDelete();
  };

  const handleCopy = () => {
    const { category, id, position, ...media } = data;
    setDropMedia(media);
    select(null);
  };

  // useEffect(() => {
  //   const base = selected ? 1 : INITIAL_SCALE;
  //   scale.set(base, false);
  // }, [scaleMult]);

  useEffect(() => {
    if (!selected) return;
    const unselect = (e: MouseEvent) => {
      if (!e.target) return;
      if (!tooltipRef.current) return;
      if (!blockRef.current) return;
      if (
        !tooltipRef.current.contains(e.target as Node) &&
        !blockRef.current.contains(e.target as Node)
      ) {
        select(null);
      }
    };

    document.addEventListener("pointerdown", unselect);
    return () => {
      document.removeEventListener("pointerdown", unselect);
    };
  }, [selected]);

  const onMount = () => {
    scale.set(1);
    opacity.set(1);
  };

  const onHide = ({ unmount }) => {
    const cleanup = scale.onChange((value) => {
      if (value <= INITIAL_SCALE) {
        cleanup();
        unmount();
      }
    });

    scale.set(INITIAL_SCALE);
    opacity.set(0);
  };

  const trash = (
    <div className="p-1 bg-gray-100 border border-gray-200 shadow-lg rounded-xl flex gap-1 items-center origin-bottom z-[1]">
      <NewButton iconOnly size="lg" variant="transparent" onClick={handleCut}>
        <TbCut />
      </NewButton>
      <NewButton iconOnly size="lg" variant="transparent" onClick={handleCopy}>
        <TbCopy />
      </NewButton>
      <NewButton
        iconOnly
        size="lg"
        variant="transparent"
        color="bg-red-500 text-red-500"
        onClick={handleDelete}
      >
        <TbTrash />
      </NewButton>
    </div>
  );

  return (
    <Tippy
      zIndex={10}
      plugins={[sticky]}
      sticky="reference"
      appendTo={document.getElementById("exercise_container") || document.body}
      // boundary={document.getElementById("exercise_container") || document.body}
      maxWidth="100%"
      render={(attrs) => (
        <motion.div
          style={{ scale, opacity }}
          className="flex items-center gap-1"
          onPointerDown={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
          ref={tooltipRef}
          {...attrs}
        >
          {magic && role.aiEnabled && (
            <div className="bg-white border border-gray-200 shadow-lg rounded-xl flex items-center origin-bottom z-[1]">
              {typeof magic !== "function" ? (
                <Tooltip value={magic}>
                  <NewButton
                    iconOnly
                    disabled
                    variant="primary"
                    className="p-2.5"
                  >
                    <TbWand />
                  </NewButton>
                </Tooltip>
              ) : (
                <FloatingMenu
                  open={open === false || !selected ? false : undefined}
                  portal
                  size="xs"
                  placement="bottom-end"
                  trigger={(toggle) => (
                    <NewButton
                      iconOnly
                      variant="primary"
                      className="!p-2.5"
                      onClick={toggle}
                    >
                      <TbWand />
                    </NewButton>
                  )}
                >
                  {({ setIsOpen }) => (
                    <div className="flex flex-col gap-1 max-h-64 overflow-y-auto pr-1">
                      {magic(() => setIsOpen(false))}
                    </div>
                  )}
                </FloatingMenu>
              )}
            </div>
          )}

          {customToolbar ? (
            customToolbar(trash)
          ) : (
            <>
              {toolbar && (
                <div className="p-1 bg-white border border-gray-200 shadow-lg rounded-xl flex items-center origin-bottom z-[1]">
                  {toolbar}
                </div>
              )}

              {trash}
            </>
          )}
        </motion.div>
      )}
      animation
      placement="top"
      {...{ onMount, onHide }}
      visible={open == null ? selected : open}
      interactive
      ignoreAttributes
      popperOptions={{
        // strategy: "fixed",
        modifiers: [
          {
            name: "offset",
            options: { offset: [0, 4] },
          },
          {
            name: "preventOverflow",
            options: {
              boundary:
                document.getElementById("exercise_container") || document.body,
            },
          },
        ],
      }}
    >
      <div className="absolute-cover" ref={blockRef}>
        {children}
      </div>
    </Tippy>
  );
};
