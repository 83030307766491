import { useFocused, useSlate } from "slate-react";
import { NewButton } from "app/components/Buttons/NewButton";
import React, { ComponentProps, PropsWithChildren } from "react";
import { Editor, Node, Path, Range, Transforms } from "slate";
import styled from "styled-components";
import isHotkey from "is-hotkey";
import { IconType } from "react-icons";
import { VerticalAlign } from "app/components/Exercises/CourseEdit/courseEditTypes";

// const TEXT_ALIGN_TYPES = ["left", "center", "right"];

export const StyledEditable = styled.div<{
  vertical?: VerticalAlign;
}>`
  & > :first-child {
    margin-top: ${(p) => p.vertical !== "top" && "auto"};
  }
  & > :last-child {
    margin-bottom: ${(p) => p.vertical !== "bottom" && "auto"};
  }
`;

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+h": "highlight",
};

export const toggleMarkHotkey = (e, editor) => {
  for (const hotkey in HOTKEYS) {
    if (isHotkey(hotkey, e as any)) {
      e.preventDefault();
      const mark = HOTKEYS[hotkey];
      toggleMark(editor, mark);
    }
  }
};

export const toggleMark = (editor, format, position?: Path) => {
  const isActive = isMarkActive(editor, format, position);

  if (position)
    Transforms.select(editor, {
      anchor: Editor.start(editor, position),
      focus: Editor.end(editor, position),
    });

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }

  if (position)
    setTimeout(() => {
      Transforms.deselect(editor);
    }, 1);
};

export const isMarkActive = (editor, format, position?: Path) => {
  try {
    const marks = position
      ? Node.first(editor, position)[0]
      : Editor.marks(editor);

    return !!marks?.[format];
  } catch (e) {
    // console.error("isMarkActive error", e);
    return false;
  }
};

export const MarkButton = ({
  format,
  icon,
  position,
}: {
  format: string;
  icon: IconType;
  position?: Path;
}) => {
  const editor = useSlate();
  const { selection } = editor;
  const inFocus = useFocused();

  const isActive = (() => {
    if (!inFocus && !position) return false;
    return isMarkActive(editor, format, position);
  })();

  return (
    <ActiveButton
      {...{ isActive, icon }}
      onClick={() => toggleMark(editor, format, position)}
      disabled={
        !position && (!inFocus || !selection || Range.isCollapsed(selection))
      }
    />
  );
};

export const ActiveButton = ({
  onClick = () => {},
  icon: Icon,
  variant = "light",
  isActive = false,
  disabled = false,
  color,
  children,
  size = "lg",
  className,
}: PropsWithChildren<{
  variant?: ComponentProps<typeof NewButton>["variant"];
  size?: ComponentProps<typeof NewButton>["size"];
  onClick?: (e: React.MouseEvent<any>) => void;
  icon?: IconType;
  isActive?: boolean;
  disabled?: boolean;
  color?: string;
  className?: string;
}>) => (
  <NewButton
    iconOnly={!children}
    // variant="transparent"
    variant={isActive ? variant : "transparent"}
    color={color ?? (isActive ? "bg-primary text-primary" : undefined)}
    onMouseDown={(e) => e.preventDefault()}
    onClick={onClick}
    disabled={disabled}
    size={size}
    className={className}
  >
    {children ?? (Icon && <Icon />)}
  </NewButton>
);
