import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  TbChevronLeft,
  TbChevronRight,
  TbCornerLeftUp,
  TbFolder,
} from "react-icons/tb";
import { Tooltip } from "app/components/Tooltip";
import { useLibrary } from "app/components/Sources/MediaPicker/SourceList/useLibrary";
import { libraryRoutes } from "enums/routes";
import { InView } from "react-intersection-observer";
import classNames from "classnames";
import { CgSpinner } from "react-icons/cg";

const Skeleton = ({ size = 5 }) =>
  useMemo(
    () => (
      <div className="flex flex-col gap-1 p-1">
        {[...new Array(size)].map((_, i) => (
          <div key={i} className="flex items-center gap-2 p-2">
            <TbFolder className="text-xl text-gray-300" />
            <div className="h-3 bg-gray-100 grow rounded-full" />
            <div className="h-3 bg-gray-100 w-4 rounded-full" />
          </div>
        ))}
      </div>
    ),
    [size]
  );

export const NewFolderPicker = ({
  close,
  folders = [],
  share,
  onSelect,
  initialFolder = "",
  loading,
  hideExit,
  className,
}: {
  close: any;
  folders?: string[];
  share?: boolean;
  onSelect: (id: string) => void;
  initialFolder?: string;
  loading?: boolean;
  hideExit?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedFolder, setSelectedFolder] = useState(initialFolder);
  const { list, query, setFolderId, folderId } = useLibrary({
    initialFolderId: initialFolder,
    initialFilters: {},
  });

  const lastFolder = useMemo(() => {
    return query.data?.pages?.[0]?.header?.navigation_stack?.slice(-1)?.[0];
  }, [folderId, query.isSuccess]);

  if (loading)
    return (
      <AnimatePresence>
        <motion.div
          key="loader"
          initial={{ x: 40, opacity: 0.5 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ type: "spring", bounce: 0, duration: 0.4 }}
          style={{
            minHeight: "110px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CgSpinner className="text-3xl text-gray-500 animate-spin" />
        </motion.div>
      </AnimatePresence>
    );

  const handleBack = () => {
    if (folderId === "") {
      close();
      if (share) history.push(libraryRoutes.library);
      return;
    }

    const previous = query.data?.pages?.[0]?.header?.navigation_stack?.slice(
      -2,
      -1
    )?.[0]?.id;
    setFolderId(previous ?? "");
    setSelectedFolder(lastFolder?.id ?? "");
  };

  const handleOpenFolder = (item) => {
    setFolderId(item.id);
    setSelectedFolder(item.id);
  };

  return (
    <AnimatePresence>
      <motion.div
        key="folders"
        initial={{ x: 40, opacity: 0.5 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: "spring", bounce: 0, duration: 0.4 }}
        style={{ minHeight: "fit-content" }}
        className={classNames("h-full w-60 flex flex-col", className)}
      >
        {/* eslint-disable-next-line no-constant-condition */}
        {query.isLoading ? (
          <Skeleton />
        ) : (
          <>
            <div className="flex items-center mb-2">
              {(!!folderId || !hideExit) && (
                <Tooltip value={t("common.back")} className="mr-2">
                  <NewButton
                    onClick={handleBack}
                    iconOnly
                    size="sm"
                    variant="transparent"
                  >
                    {folderId === "" ? <TbChevronLeft /> : <TbCornerLeftUp />}
                  </NewButton>
                </Tooltip>
              )}
              <div className="font-bold text-primary">
                {folderId === ""
                  ? t("homePage.sections.exercisesLibraryTitle")
                  : lastFolder?.name}
              </div>
            </div>
            <div className="h-[8rem] relative">
              <div className="absolute-cover overflow-y-auto">
                {!list.length ? (
                  <div className="text-center text-sm text-gray-500 p-2">
                    {t("source.folder.empty")}
                  </div>
                ) : (
                  <div className="flex flex-col space-y-1">
                    {list.map((item) => {
                      if (folders?.includes(item.id)) return null;
                      return (
                        <div
                          key={item.id}
                          className="flex items-center space-x-1"
                        >
                          <NewButton
                            className="flex-grow"
                            color={
                              selectedFolder === item.id
                                ? "bg-primary text-primary"
                                : undefined
                            }
                            variant={
                              selectedFolder === item.id
                                ? "light"
                                : "transparent"
                            }
                            onClick={() =>
                              setSelectedFolder(
                                item.id === selectedFolder ? folderId : item.id
                              )
                            }
                          >
                            <TbFolder />
                            <div className="overflow-hidden text-ellipsis min-w-0">
                              {item.name}
                            </div>
                          </NewButton>

                          <Tooltip
                            value={t("source.folder.goTo", { name: item.name })}
                          >
                            <NewButton
                              iconOnly
                              variant="transparent"
                              onClick={() => handleOpenFolder(item)}
                            >
                              <TbChevronRight />
                            </NewButton>
                          </Tooltip>
                        </div>
                      );
                    })}

                    {query.hasNextPage && (
                      <InView
                        as="div"
                        className="flex"
                        onChange={(inView) =>
                          inView &&
                          !query.isFetchingNextPage &&
                          query.fetchNextPage()
                        }
                      >
                        {({ inView, ref }) => (
                          <div
                            ref={ref}
                            className={classNames(
                              "flex flex-col transition",
                              !inView && "opacity-0"
                            )}
                          >
                            <Skeleton />
                          </div>
                        )}
                      </InView>
                    )}
                  </div>
                )}
              </div>
            </div>
            <NewButton
              className="ml-auto mt-2"
              center
              variant="primary"
              disabled={selectedFolder == null}
              onClick={() => onSelect(selectedFolder)}
            >
              {selectedFolder === folderId
                ? t("common.rowSubMenu.buttons.moveHere.label")
                : t("common.rowSubMenu.buttons.moveHere.inFolder")}
            </NewButton>
          </>
        )}
      </motion.div>
    </AnimatePresence>
  );
};
