import React, { useEffect, useMemo, useState } from "react";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { getGroupsList } from "api/groups/groupsAPI";
import { postCreateAssignment } from "api/assignments/assignmentsAPI";
import { toast } from "react-hot-toast";
import {
  BigModal,
  BigModalBody,
  BigModalFooter,
  BigModalHeader,
} from "app/components/BigModal";
import {
  TbBook,
  TbCheck,
  TbChevronDown,
  TbCircleNumber1,
  TbClipboardCheck,
  TbInfinity,
  TbRotate,
  TbUsers,
  TbX,
} from "react-icons/tb";
import { NewButton } from "app/components/Buttons/NewButton";
import { BsDot } from "react-icons/bs";
import { Checkbox } from "app/components/Buttons/InputCheckbox";
import classNames from "classnames";
import { InView } from "react-intersection-observer";
import { CgSpinner } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { translateError } from "app/components/Exercises/Edit/missingTranslation";
import { FloatingMenu } from "app/components/Header";
import { ActiveButton } from "app/components/Exercises/Edit/questionType/Slide/item/ItemText";

const ClassListSkeleton = () => (
  <div className="grid gap-y-0.5 items-center">
    {[...new Array(20)].map((_, i) => (
      <div
        key={i}
        className="rounded-lg h-10 bg-gray-100 animate-pulse"
        style={{ animationDelay: i * 0.2 + "s" }}
      />
    ))}
  </div>
);

export const AttemptsOption = ({ value, setValue, disabled = false }) => {
  const { t } = useTranslation();
  return (
    <FloatingMenu
      size="xs"
      placement="bottom-end"
      trigger={(toggle) => (
        <NewButton onClick={() => !disabled && toggle()} disabled={disabled}>
          {value ? (
            <>
              <TbCircleNumber1 /> {t("v4.assignment.attempts.single")}
            </>
          ) : (
            <>
              <TbInfinity /> {t("v4.assignment.attempts.infinite")}
            </>
          )}
          <TbChevronDown />
        </NewButton>
      )}
    >
      {({ setIsOpen }) => (
        <>
          <ActiveButton
            size="md"
            isActive={value}
            onClick={() => {
              setValue(true);
              setIsOpen(false);
            }}
          >
            <TbCircleNumber1 /> {t("v4.assignment.attempts.single")}
          </ActiveButton>
          <ActiveButton
            size="md"
            isActive={!value}
            onClick={() => {
              setValue(false);
              setIsOpen(false);
            }}
          >
            <TbInfinity /> {t("v4.assignment.attempts.infinite")}
          </ActiveButton>
        </>
      )}
    </FloatingMenu>
  );
};

export const AssignCourseModal = ({
  open,
  close,
  courses,
  hideSelected = false,
}: {
  open: boolean;
  close: () => void;
  courses: { id: string; name: string }[];
  hideSelected?: boolean;
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string[]>([]);
  const [singleAttempt, setSingleAttempt] = useState(false);
  const queryClient = useQueryClient();

  const { data, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ["groups", courses.map(({ id }) => id).join(",")],
      ({ pageParam: page = 0 }) =>
        getGroupsList(
          { page },
          courses.map(({ id }) => id)
        ),
      {
        refetchOnWindowFocus: false,
        getNextPageParam: ({ groups_total }, pages) =>
          groups_total > pages.length * 20 ? pages.length : undefined,
        enabled: open,
      }
    );

  const assignMutation = useMutation(
    () =>
      postCreateAssignment(
        courses.map(({ id }) => id),
        selected,
        singleAttempt
      ),
    {
      onSuccess: () => {
        courses.forEach(({ id }) => {
          queryClient.invalidateQueries(["activities", id]);
        });
        // location.push(groupRoutes.list);
        close();
        toast.success(t("v4.assignment.success"));
      },
      onError: () => {
        toast.error(translateError(t("v4.assignment.error"), t));
      },
    }
  );

  const list = useMemo(
    () =>
      (data?.pages || []).reduce(
        (list, group) => [...list, ...group.groups],
        []
      ),
    [data?.pages]
  );

  useEffect(() => {
    if (open) setSelected([]);
  }, [open]);

  return (
    <BigModal open={open || assignMutation.isLoading} fit>
      <BigModalHeader className="ml-2">
        <TbClipboardCheck className="text-xl" />
        {t("v4.assignment.assignTrainings")}

        <NewButton
          variant="transparent"
          iconOnly
          onClick={close}
          className="ml-auto"
        >
          <TbX />
        </NewButton>
      </BigModalHeader>
      <BigModalBody
        className="overflow-y-auto !py-0 max-w-xl max-h-[30rem]"
        maxWidth
      >
        <div className="flex-1 flex flex-col gap-2 py-4">
          {!hideSelected && (
            <div className="border border-gray-200 p-4 rounded-xl min-w-0">
              <div className="font-bold text-gray-500 flex items-center gap-1.5">
                <TbBook />
                {t("v4.assignment.selectedTrainings")}
              </div>
              {courses.map(({ id, name }) => (
                <div
                  className="text-xl font-bold overflow-hidden text-gray-600 text-ellipsis flex items-center gap-2"
                  key={id}
                >
                  {courses.length > 1 && <BsDot />} {name}
                </div>
              ))}
            </div>
          )}

          <div className="border border-gray-200 p-2 pl-4 rounded-xl min-w-0 flex flex-col sm:flex-row items-center gap-2 justify-between">
            <div className="font-bold text-gray-500 flex items-center gap-1.5">
              <TbRotate />
              {t("v4.assignment.attempts.number")}
            </div>
            <AttemptsOption value={singleAttempt} setValue={setSingleAttempt} />
          </div>

          <div className="border border-gray-200 p-4 rounded-xl min-w-0">
            <div className="font-bold text-gray-600 text-lg flex items-center gap-1.5 mb-4">
              <TbUsers className="text-xl" />
              {t("v4.group.plural")}
            </div>
            {!isSuccess ? (
              <ClassListSkeleton />
            ) : (
              <>
                {!list.length ? (
                  <div className="text-center text-gray-500 py-5">
                    {t("v4.group.noItems")}
                  </div>
                ) : (
                  <div
                    className="grid gap-x-3 gap-y-0.5 items-center"
                    style={{ gridTemplateColumns: "auto auto 1fr" }}
                  >
                    {list.map(
                      (
                        {
                          id,
                          color,
                          name,
                          students_count,
                          is_assigned,
                          is_admin,
                        },
                        i
                      ) => {
                        const active = selected.includes(id);
                        const disabled = is_assigned || !is_admin;
                        return (
                          <React.Fragment key={id}>
                            <Checkbox
                              isSelected={is_assigned || active}
                              style={{
                                gridArea: `${i + 1} / 1 / span 1 / span 1`,
                              }}
                              className={classNames(
                                disabled && "opacity-50",
                                "transition cursor-default pointer-events-none"
                              )}
                            />
                            <div
                              className={classNames(
                                disabled && "opacity-25",
                                "transition flex items-center gap-1 text-gray-500"
                              )}
                              style={{
                                gridArea: `${i + 1} / 2 / span 1 / span 1`,
                              }}
                            >
                              <TbUsers className="text-lg" />
                              <span className="text-sm font-bold">
                                {students_count}
                              </span>
                            </div>
                            <div
                              className={classNames(
                                disabled && "opacity-25",
                                "transition font-bold flex text-gray-700 items-center gap-2 py-2"
                              )}
                              style={{
                                gridArea: `${i + 1} / 3 / span 1 / span 1`,
                              }}
                            >
                              <div
                                className={classNames(
                                  "w-5 h-5 rounded-full",
                                  !color &&
                                    "bg-white border-gray-200 border-dashed border-2"
                                )}
                                style={{ backgroundColor: color }}
                              />
                              {name}
                            </div>
                            {!disabled && (
                              <div
                                onClick={() =>
                                  assignMutation.isLoading
                                    ? null
                                    : !active
                                    ? setSelected([...selected, id])
                                    : setSelected(
                                        selected.filter(
                                          (selectedId) => selectedId !== id
                                        )
                                      )
                                }
                                style={{
                                  gridArea: `${i + 1} / 1 / span 1 / span 3`,
                                }}
                                className="transition bg-primary opacity-0 hover:opacity-10 min-h-[2.5rem] h-full rounded-lg cursor-pointer"
                              />
                            )}
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                )}
                {hasNextPage && (
                  <InView
                    as="div"
                    className="flex"
                    onChange={(inView) =>
                      inView && !isFetchingNextPage && fetchNextPage()
                    }
                    key={data.pages.length}
                  >
                    {({ inView, ref }) => (
                      <div
                        ref={ref}
                        className={classNames(
                          "flex flex-col divide-gray-100 transition",
                          !inView && "opacity-0"
                        )}
                      >
                        <ClassListSkeleton />
                      </div>
                    )}
                  </InView>
                )}
              </>
            )}
          </div>
        </div>
      </BigModalBody>
      <BigModalFooter>
        <div className="mx-2 font-bold text-gray-400 text-sm">
          {selected.length} {t("v4.group.selected")}
        </div>
        <NewButton
          variant="primary"
          disabled={!selected.length || assignMutation.isLoading}
          className="ml-auto"
          onClick={assignMutation.mutate}
        >
          {assignMutation.isLoading ? (
            <CgSpinner className="animate-spin" />
          ) : (
            <TbCheck />
          )}
          {t("v4.assignment.assign")}
        </NewButton>
      </BigModalFooter>
    </BigModal>
  );
};
