import axios from "axios";
import { apiUrlV1 } from "enums/constant";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { selectUserDetails } from "app/store/user";
import { useCallback } from "react";
import { IUserDetails } from "models/user.interface";

export async function getUserDetails() {
  const url = `${apiUrlV1}/user/`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function putUpdateUserDetails(dataToSave) {
  const url = `${apiUrlV1}/user/`;
  const { data } = await axios.put<any>(url, dataToSave);
  return data;
}

export const useUserProfile = () => {
  const details = useSelector(selectUserDetails);
  const update = useMutation((data: Partial<IUserDetails["profile"]>) =>
    putUpdateUserDetails({
      profile: { ...details?.profile, ...data },
    })
  );

  return { ...details?.profile, update };
};

export const getGuestData = async () => {
  const url = `${apiUrlV1}/guest/`;
  const res = await axios.get<{
    is_org_b2c?: boolean;
    is_org_external?: boolean;
    is_org_social_login?: boolean;
    contact_email?: string;
    logo_desktop_url?: string;
    logo_download_url?: string;
    logo_mobile_url?: string;
    logo_thumb_url?: string;
    small_logo_desktop_url?: string;
    small_logo_download_url?: string;
    small_logo_mobile_url?: string;
    small_logo_thumb_url?: string;
    subdomain?: string;
    theme: {
      primary_color?: string;
      top_bar_left?: string;
      top_bar_right?: string;
    };
    top_bar_desktop_url?: string;
    top_bar_download_url?: string;
    top_bar_mobile_url?: string;
    top_bar_thumb_url?: string;
  }>(url);
  return res.data;
};

export async function postUserContext(dataToSend: any) {
  const url = `${apiUrlV1}/user/context/`;
  const { data } = await axios.post<any>(url, dataToSend);
  return data;
}

export async function postUsers(dataToSend: any) {
  const url = `${apiUrlV1}/users/`;
  const { data } = await axios.post<any>(url, dataToSend);
  return data;
}

export async function postSetPassword(dataToSave: any) {
  const url = `${apiUrlV1}/helper_script/set_user_password`;
  const { data } = await axios.post<any>(url, dataToSave);
  return data;
}

export async function postUserError(dataToSave: any) {
  const url = `${apiUrlV1}/errors/`;
  const { data } = await axios.post<any>(url, dataToSave);
  return data;
}

export async function postUserImpersonate(user_id) {
  const url = `${apiUrlV1}/impersonate/${user_id}/`;
  const { data } = await axios.post<any>(url);
  return data;
}

export async function deleteUserImpersonate() {
  const url = `${apiUrlV1}/impersonate/`;
  const { data } = await axios.delete<any>(url);
  return data;
}

export const getSubscription = async () => {
  const url = `${apiUrlV1}/payment/subscription/`;
  const res = await axios.get(url);
  return res.data?.subscription;
};

export const useSubscription = (options = {}) => {
  const userDetails = useSelector(selectUserDetails);
  return useQuery("subscription", getSubscription, {
    initialData: userDetails?.subscription,
    ...options,
  });
};

export const useSubscriptionRefetch = () => {
  const queryClient = useQueryClient();
  return useCallback(() => queryClient.invalidateQueries("subscription"), []);
};
