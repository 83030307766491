import React from "react";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import {
  InstructionsRender,
  QuizDropzone,
  QuizItemLabel,
  QuizItemLabelRaw,
} from "app/components/Exercises/CourseEdit/components/generate/QuizDropzone";
import {
  Explanation,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { TbBook2 } from "react-icons/tb";
import { useTranslation } from "react-i18next";

export const PageExplanationItem = () => {
  return (
    <PageItemWrapper>
      <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
        <QuizItemLabel type={PageItemType.Explanation} />
        <QuizDropzone />
      </div>
    </PageItemWrapper>
  );
};

export const PageExplanationRender = ({ item }: { item: Explanation }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <QuizItemLabelRaw name={t("v4.item.explanation.text")} icon={TbBook2} />
      <InstructionsRender instructions={item.instructions} />
    </div>
  );
};
