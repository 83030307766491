import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { courseRoutes, guestCourseRoutes } from "enums/routes";
import { TbCalendar, TbCheck, TbPlayerPlay, TbRefresh } from "react-icons/tb";
import classNames from "classnames";
import { getCourse } from "api/course/courseAPI";
import { useDateFormat } from "app/hooks/useDateFormat";
import {
  CourseIcon,
  CourseSlideThumbnail,
} from "app/pages/courses/Edit/CoursePanel";
import { useTranslation } from "react-i18next";
import { roundTo } from "helpers/roundTo";
import { NewButton } from "app/components/Buttons/NewButton";
import { AssignmentBadges } from "app/pages/courses/CourseActivity";
import { pagePlaceholder } from "./Edit/CourseEditView";

export const CourseTestDetails = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const { isSuccess, data } = useQuery({
    queryFn: () => getCourse(courseId, { results: true }),
    queryKey: ["course", "test", courseId],
    refetchOnWindowFocus: false,
    enabled: !!courseId,
  });

  return <InnerCourseTestDetails {...{ data, isSuccess }} />;
};
export const InnerCourseTestDetails = ({ data, isSuccess }) => {
  const { t } = useTranslation();
  const { guestPin, courseId } =
    useParams<{ guestPin?: string; courseId: string }>();
  const format = useDateFormat();

  const [pagesDone, pagesTotal, nextPage] = useMemo(() => {
    if (!isSuccess) return [0, 0, null];
    const done = data.pages.filter((item) => item.score != null).length;
    const total = data.pages.length;
    const nextPage = data.pages.find((item) => item.score == null)?.id || null;
    return [done, total, nextPage];
  }, [isSuccess, data?.pages]);

  const isClosed = data?.status === "closed";

  return (
    <div className="py-8 px-3 sm:px-8">
      <div className="flex flex-col gap-4 text-gray-600">
        {!isSuccess ? (
          <>
            <div className="gap-4 bg-white rounded-2xl p-6 grow">
              <div className="flex flex-col gap-2 animate-pulse">
                <div className="bg-gray-100 rounded-xl h-10" />
                <div className="bg-gray-100 rounded-xl h-6" />
              </div>
            </div>
            <div className="bg-white rounded-2xl py-4 px-6 flex flex-col grow gap-2">
              <div className="bg-gray-100 rounded-xl h-8" />
              <div className="bg-gray-100 rounded-xl h-8" />
              <div className="bg-gray-100 rounded-xl h-8" />
              <div className="bg-gray-100 rounded-xl h-8" />
            </div>
          </>
        ) : (
          <>
            <div className="flex items-start flex-col-reverse sm:flex-row gap-4 bg-white rounded-2xl p-6">
              <div className="flex flex-col gap-1 grow overflow-hidden min-w-0">
                <div className="flex gap-1 flex-wrap items-start mb-1">
                  <AssignmentBadges item={data} />
                </div>
                <div className="font-bold text-2xl overflow-ellipsis overflow-hidden grow">
                  {data.name}
                </div>
                <div className="text-gray-500 flex items-center gap-1.5">
                  <TbCalendar className="text-lg" />
                  {format(
                    data?.date_last_edited || data?.date_created,
                    "MMM d, yyyy HH:mm:ss"
                  )}
                </div>
              </div>
              {!isClosed &&
                pagesTotal > 0 &&
                pagesDone < pagesTotal &&
                nextPage && (
                  <NewButton
                    component={Link}
                    to={
                      !guestPin
                        ? courseRoutes.pageTest(courseId, nextPage)
                        : guestCourseRoutes.pageTest(
                            guestPin,
                            courseId,
                            nextPage
                          )
                    }
                    className="pointer-events-auto z-[1] ml-auto"
                    size="lg"
                    variant="primary"
                  >
                    <TbPlayerPlay />
                    {!pagesDone
                      ? t("v4.generic.start")
                      : t("v4.generic.continue")}
                  </NewButton>
                )}
            </div>

            <div>
              <div
                className="grid gap-y-1 gap-x-3 bg-white rounded-2xl p-2"
                style={{ gridTemplateColumns: "auto 5fr auto" }}
              >
                {data.pages.map((item, i) => {
                  const canRedo =
                    item.category === "quiz" &&
                    !isClosed &&
                    !!item?.result_id &&
                    !data?.is_single_attempt;
                  return (
                    <React.Fragment key={item.id}>
                      <div
                        className="flex justify-center items-center font-bold h-full w-full pl-2"
                        style={{
                          gridColumn: "1 / span 1",
                          gridRow: `${i + 1} / span 1`,
                        }}
                      >
                        {!item.result_id ? (
                          <div className="bg-gray-200 text-gray-500 text-xl leading-none w-full rounded-full min-h-[1.75rem] min-w-[2.5rem] flex items-center justify-center text-center px-2">
                            -
                          </div>
                        ) : item.category !== "quiz" ? (
                          <div className="bg-primary text-white w-full px-2 py-0.5 rounded-full min-h-[1.75rem] min-w-[2.5rem] flex items-center justify-center">
                            <TbCheck className="text-xl" strokeWidth={3} />
                          </div>
                        ) : (
                          <div className="bg-primary text-white w-full px-2 py-0.5 rounded-full min-h-[1.75rem] min-w-[2.5rem] flex items-center justify-center">
                            {roundTo(item.score * 100, 0)}
                            <span className="text-xs">%</span>
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          gridColumn: "2 / span 1",
                          gridRow: `${i + 1} / span 1`,
                        }}
                        className="flex py-2.5 px-2 items-center gap-4 min-w-0"
                      >
                        <CourseIcon
                          category={item.category}
                          quizType={item?.quiz_type}
                        >
                          {(Icon, color) => (
                            <div
                              className={classNames(
                                "shrink-0 w-7 h-7 rounded-lg flex items-center text-lg justify-center",
                                color
                              )}
                            >
                              <Icon />
                            </div>
                          )}
                        </CourseIcon>
                        {item.category === "slide" ? (
                          <div className="aspect-video relative max-w-[5rem] mr-auto bg-white w-full border border-gray-100 rounded-xl overflow-hidden pointer-events-none">
                            <CourseSlideThumbnail items={item.items} />
                          </div>
                        ) : (
                          <div className="grow whitespace-nowrap overflow-x-clip text-ellipsis select-none">
                            {item.name ||
                              pagePlaceholder({
                                category: item.category,
                                quizType: item.quiz_type,
                              })(t)}
                          </div>
                        )}
                      </div>

                      <div
                        className="group w-full h-full flex items-center justify-center pr-2"
                        style={{
                          gridColumn: "3 / span 1",
                          gridRow: `${i + 1} / span 1`,
                        }}
                      >
                        {canRedo && (
                          <NewButton
                            component={Link}
                            size="sm"
                            to={{
                              pathname: !guestPin
                                ? courseRoutes.pageTest(courseId, item.id)
                                : guestCourseRoutes.pageTest(
                                    guestPin,
                                    courseId,
                                    item.id
                                  ),
                              state: { redo: true },
                            }}
                          >
                            <TbRefresh /> {t("v4.assignment.redo")}
                          </NewButton>
                        )}
                      </div>

                      <Link
                        to={
                          !guestPin
                            ? courseRoutes.pageTest(courseId, item.id)
                            : guestCourseRoutes.pageTest(
                                guestPin,
                                courseId,
                                item.id
                              )
                        }
                        style={{
                          gridRow: `${i + 1} / span 1`,
                          gridColumn: `1 / span ${canRedo ? 2 : 3}`,
                        }}
                        className="flex w-full h-full z-[1] relative opacity-0 bg-primary transition hover:opacity-20 rounded-xl cursor-pointer"
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
