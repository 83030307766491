import React, { useState } from "react";
import { ScCloseBtn, ScModal } from "../../components/_styled/Groups";
import Loader from "../../components/Loader";
import icClose from "../../../assets/images/menu-close.png";
import { Button, ButtonTypes } from "../../components/_styled/Button";
import styled from "styled-components";
import { useMutation, useQuery } from "react-query";
import { postUsers } from "../../../api/userAPI";
import { getAllMyOrganizations } from "../../../api/organizationsAPI";
import { toast } from "react-hot-toast";

const CreateUser = ({ open = false, close, org = "" }) => {
  const [oneUser, setOneUser] = useState(true);
  const [textarea, setTextarea] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [notify, setNotify] = useState(false);
  const [bcc, setBcc] = useState("");
  const [selectedOrg, setSelectedOrg] = useState(org || "");
  const [roles, setRole] = useState({
    is_pro: false,
  });

  const defaultRoles = { is_pro: false };

  const { data, isSuccess, isLoading } = useQuery(
    "organizations",
    () => getAllMyOrganizations(),
    { refetchOnWindowFocus: false }
  );

  const formattedMembers = () => {
    const allLines: any = textarea.split("\n");
    const members: any = [];
    for (let i = 0; i < allLines.length; i++) {
      if (allLines[i].split(";").length === 4) {
        // with password
        members.push({
          firstName: allLines[i].split(";")[0],
          lastName: allLines[i].split(";")[1],
          email: allLines[i].split(";")[2],
          password: allLines[i].split(";")[3],
          ...roles,
        });
      } else if (allLines[i].split(";").length === 3) {
        // without password
        members.push({
          firstName: allLines[i].split(";")[0],
          lastName: allLines[i].split(";")[1],
          email: allLines[i].split(";")[2],
          ...roles,
        });
      }
    }
    return members;
  };

  const create = useMutation(
    "createUser",
    () => {
      return postUsers({
        users: oneUser
          ? [
              {
                firstName: name,
                lastName: lastName,
                email: email,
                password: password,
                ...roles,
              },
            ]
          : formattedMembers(),
        notify: notify,
        password: password,
        bcc: bcc,
        organization: selectedOrg,
      });
    },
    {
      onSuccess: (data) => {
        close();
        toast.success(`Successfully created ${data.results.length} users`);
      },
      onError: () => {
        toast.error("Something went wrong");
      },
    }
  );

  console.log(roles);

  return (
    <ScModal open={open}>
      <div style={{ padding: "40px 20px" }}>
        Multiple users
        <input
          type="checkbox"
          checked={!oneUser}
          onChange={() => setOneUser(!oneUser)}
        />
        {create.isLoading ? (
          <Loader />
        ) : (
          <>
            <div>
              <ScCloseBtn src={icClose} onClick={close} />
              <div style={{ display: "flex", flexDirection: "column" }}>
                {oneUser ? (
                  <>
                    <ScInput
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Name"
                    />
                    <ScInput
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Lastname"
                    />
                  </>
                ) : (
                  <textarea
                    value={textarea}
                    onChange={(e) => setTextarea(e.target.value)}
                    style={{
                      margin: "10px 0",
                      resize: "vertical",
                      overflow: "auto",
                      height: "100px",
                    }}
                    placeholder="First name; Last name; EMail; Password"
                  />
                )}
                <ScInput
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
                {oneUser && (
                  <ScInput
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                )}
                {isSuccess && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>Organization</span>
                    <select
                      defaultValue={org ? org : "select"}
                      onChange={(e) => setSelectedOrg(e.target.value)}
                    >
                      <option value="select">Select</option>
                      {data.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                <div>
                  <span>Role</span>
                  <select
                    defaultValue=""
                    onChange={(e) =>
                      e.target.value !== "" &&
                      setRole({
                        ...defaultRoles,
                        ...(e.target.value.split(",")?.[1] && {
                          [e.target.value.split(",")?.[1]]: true,
                        }),
                        [e.target.value.split(",")[0]]: true,
                      })
                    }
                  >
                    <option value="is_pro">Pro</option>
                    <option value="">Basic</option>
                  </select>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>Notify</span>
                  <ScInput
                    type="checkbox"
                    checked={notify}
                    onChange={(e) => setNotify(!notify)}
                  />
                </div>
                {notify && (
                  <ScInput
                    value={bcc}
                    onChange={(e) => setBcc(e.target.value)}
                    placeholder="BCC"
                    defaultValue="mehdi@smartest.io"
                  />
                )}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <Button theme={ButtonTypes.light} clickAction={() => close()}>
                Cancel
              </Button>
              <Button
                disabled={
                  (oneUser &&
                    (!name.length ||
                      !password.length ||
                      !email.length ||
                      selectedOrg === "select")) ||
                  (!oneUser && (!textarea.length || selectedOrg === "select"))
                }
                clickAction={() => create.mutate()}
              >
                Create user
              </Button>
            </div>
          </>
        )}
      </div>
    </ScModal>
  );
};

export default CreateUser;

const ScInput = styled.input`
  box-sizing: border-box;
  height: 38px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  padding: 2px 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
`;
