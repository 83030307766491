import React from "react";

export interface Coordinates {
  x: number;
  y: number;
}

export interface Dimensions extends Coordinates {
  w: number;
  h: number;
}

export interface Shape extends Dimensions {
  angle?: number;
}

export const rotatePoint = (x, y, x0, y0, sin, cos) => [
  x0 + (x - x0) * cos + (y - y0) * sin,
  y0 - (x - x0) * sin + (y - y0) * cos,
];

export const getBoundingBox = ({ x, y, w, h, angle }: Shape) => {
  if (!angle) return { x, y, w, h };

  const cos = Math.cos((angle * Math.PI) / 180);
  const sin = Math.sin((angle * Math.PI) / 180);
  const x0 = x + w / 2;
  const y0 = y + h / 2;

  const rotate = ([x, y]) => rotatePoint(x, y, x0, y0, sin, cos);

  const points = (
    [
      [x, y],
      [x + w, y],
      [x, y + h],
      [x + w, y + h],
    ] as [number, number][]
  ).map(rotate);

  const minX = Math.min(...points.map(([x]) => x));
  const maxX = Math.max(...points.map(([x]) => x));
  const minY = Math.min(...points.map(([, y]) => y));
  const maxY = Math.max(...points.map(([, y]) => y));

  return {
    x: minX,
    y: minY,
    w: maxX - minX,
    h: maxY - minY,
  };
};

const TOLERANCE = (size: number) => Math.min(size * 0.4);

export const isObjectInCrop = (position: Shape, crop: Dimensions) => {
  const box = getBoundingBox(position);
  if (box.x + TOLERANCE(box.w) < crop.x) return false;
  if (box.y + TOLERANCE(box.h) < crop.y) return false;
  if (box.x + box.w > crop.x + crop.w + TOLERANCE(box.w)) return false;
  if (box.y + box.h > crop.y + crop.h + TOLERANCE(box.h)) return false;
  // if nothing got returned as false - is in crop
  return true;
};
