import { NewButton } from "app/components/Buttons/NewButton";
import {
  TbArrowAutofitLeft,
  TbArrowAutofitRight,
  TbArrowAutofitWidth,
  TbArrowBackUp,
  TbCheck,
  TbChevronDown,
  TbDots,
  TbX,
} from "react-icons/tb";
import React, { useState } from "react";
import { InputWithMediaRender } from "app/components/Exercises/CourseEdit/components/InputWithMedia";
import { FloatingMenu } from "app/components/Header";
import { useTranslation } from "react-i18next";
import { useTableSelectionContext } from "../context/useTableSelectionContext";
import { postTableFromImage } from "../../../../../api/exercisesAPI";
import { useQuery } from "react-query";
import { CgSpinner } from "react-icons/cg";
import {
  DropMediaColumns,
  MediaOneColumn,
  MediaTwoColumns,
} from "../context/dropMediaContext";
import { toast } from "react-hot-toast";
import { SelectOnlyType, SelectType } from "../MediaPicker";

export const TableSelection = ({
  close,
  onInsert,
  selectOnly,
}: {
  close: () => void;
  onInsert: (media: MediaOneColumn | MediaTwoColumns) => void;
  selectOnly: SelectType["selectOnly"];
}) => {
  const [tableSelection, setTableSelection] = useTableSelectionContext(
    (data) => [data.value, data.set]
  );
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState<number | undefined>(undefined);
  const { t } = useTranslation();
  const query = useQuery(
    ["table_cols", tableSelection],
    () => postTableFromImage(tableSelection!),
    {
      enabled: !!tableSelection,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      refetchOnMount: false,
      onSuccess: (data) => {
        if (!data.columns.length) {
          setTableSelection(null);
          toast.error(t("v4.library.tableSelection.noColumnsError"));
          return;
        }
        if (
          selectOnly === SelectOnlyType.twoColumns &&
          data.columns.length < 2
        ) {
          setTableSelection(null);
          toast.error(t("v4.library.tableSelection.oneColumnError"));
          return;
        }
        if (data.columns.length > 1) setRight(1);
      },
    }
  );

  const onLeft = (i: number) => {
    if (!query.data) return;
    if (right == null) {
      setRight((i + 1) % query.data.columns.length);
    } else if (right === i) {
      setRight(left);
    }
    setLeft(i);
  };

  const onRight = (i: number) => {
    if (!query.data) return;
    if (left === i) {
      if (right == null) {
        setLeft((i + 1) % query.data.columns.length);
      } else if (right === i) {
        setLeft(right);
      }
    }
    setRight(i);
  };
  const onSingle = (i: number) => {
    setRight(undefined);
    setLeft(i);
  };

  const slice =
    right == null || !query.data
      ? undefined
      : Math.min(
          query.data.columns[left].length,
          query.data.columns[right].length
        );

  const handleConfirm = () => {
    if (!query.data) return;

    if (right == null) {
      onInsert({
        type: DropMediaColumns.OneColumn,
        data: query.data.columns[left].map((c) => c.text),
      });
    } else {
      onInsert({
        type: DropMediaColumns.TwoColumns,
        data: [...new Array(slice)].map((_, i) => [
          query.data.columns[left][i].text,
          query.data.columns[right][i].text,
        ]),
      });
    }
  };

  return (
    <>
      <div className="p-1 pl-1 font-bold border-b-2 border-gray-100 flex items-center gap-2 text-gray-500">
        <NewButton onClick={() => setTableSelection(null)} size="sm">
          <TbArrowBackUp />
          {t("v4.generic.back")}
        </NewButton>

        <div className="font-bold text-gray-500 pl-2">
          {t("v4.library.tableSelection.useColumns")}
        </div>
        <NewButton
          iconOnly
          onClick={close}
          className="ml-auto"
          variant="transparent"
        >
          <TbX />
        </NewButton>
      </div>

      {!query.isSuccess ? (
        <div className="grow relative flex">
          <CgSpinner className="m-auto text-3xl animate-spin text-gray-500" />
        </div>
      ) : (
        <>
          <div className="grow relative">
            <div className="absolute-cover overflow-y-auto">
              <div className="min-h-full flex flex-col items-center grow p-4 text-gray-600">
                <div className="bg-gray-200 max-w-2xl w-full flex flex-col rounded-2xl">
                  <div className="font-bold text-gray-700 px-6 py-4">
                    {t("v4.library.source")}
                  </div>
                  <div className="overflow-x-auto flex gap-2 pr-6 pb-3 mb-3 ml-6">
                    {query.data.columns.map((column, colI) => (
                      <div
                        className="bg-white rounded-xl p-4 gap-1 flex flex-col w-48 shrink-0 first:ml-auto last:mr-auto"
                        key={colI}
                      >
                        {query.data.columns.length > 1 && (
                          <FloatingMenu
                            portal
                            placement="bottom-start"
                            className="w-40"
                            trigger={(toggle) => (
                              <NewButton
                                size="lg"
                                center
                                className="w-full mb-2 h-9"
                                color={
                                  left === colI || right === colI
                                    ? "bg-primary text-primary"
                                    : undefined
                                }
                                onClick={toggle}
                              >
                                {left === colI ? (
                                  right == null ? (
                                    <>
                                      <TbArrowAutofitWidth />
                                      <span className="text-sm font-bold whitespace-nowrap">
                                        {t(
                                          "v4.library.tableSelection.oneColumn"
                                        )}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <TbArrowAutofitLeft />
                                      <span className="text-sm font-bold">
                                        {t("v4.generate.table.left")}
                                      </span>
                                    </>
                                  )
                                ) : right === colI ? (
                                  <>
                                    <TbArrowAutofitRight />
                                    <span className="text-sm font-bold">
                                      {t("v4.generate.table.right")}
                                    </span>
                                  </>
                                ) : null}
                                <div className="grow" />
                                <TbChevronDown className="-mr-2 !text-base shrink-0" />
                              </NewButton>
                            )}
                            size="xs"
                          >
                            {({ setIsOpen }) => (
                              <>
                                <NewButton
                                  variant="transparent"
                                  onClick={() => {
                                    setIsOpen(false);
                                    onLeft(colI);
                                  }}
                                >
                                  <TbArrowAutofitLeft />
                                  {t("v4.generate.table.left")}
                                </NewButton>
                                <NewButton
                                  variant="transparent"
                                  onClick={() => {
                                    setIsOpen(false);
                                    onRight(colI);
                                  }}
                                >
                                  <TbArrowAutofitRight />
                                  {t("v4.generate.table.right")}
                                </NewButton>
                                {selectOnly !== SelectOnlyType.twoColumns && (
                                  <NewButton
                                    variant="transparent"
                                    className="whitespace-nowrap"
                                    onClick={() => {
                                      setIsOpen(false);
                                      onSingle(colI);
                                    }}
                                  >
                                    <TbArrowAutofitWidth />
                                    {t("v4.library.tableSelection.oneColumn")}
                                  </NewButton>
                                )}
                              </>
                            )}
                          </FloatingMenu>
                        )}

                        {column.slice(0, 4).map(({ text, id }) => (
                          <div
                            key={id}
                            className="text-sm text-ellipsis whitespace-nowrap overflow-hidden px-1"
                          >
                            <InputWithMediaRender text={text} />
                          </div>
                        ))}
                        {column.length > 4 && (
                          <div className="px-1 mt-auto mx-auto -mb-2 text-gray-400">
                            <TbDots />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="bg-gray-200 max-w-2xl w-full flex flex-col rounded-2xl mt-4">
                  <div className="font-bold text-gray-700 px-6 py-4">
                    {t("v4.generic.preview")}
                  </div>
                  <div
                    className="bg-gray-200 rounded-lg px-6 mb-6 gap-1 grid grid-cols-2 w-full max-w-2xl"
                    style={{
                      gridTemplateColumns: `repeat(${
                        right == null ? 1 : 2
                      }, 1fr)`,
                    }}
                  >
                    {query.data.columns[left]
                      .slice(0, slice)
                      .map((media, i) => (
                        <div
                          className="grow bg-white rounded-lg p-1.5 flex items-center"
                          style={{
                            gridRow: `${i + 1} / span 1`,
                            gridColumn: "1 / span 1",
                          }}
                          key={i}
                        >
                          <InputWithMediaRender {...media} />
                        </div>
                      ))}
                    {right != null &&
                      query.data.columns[right]
                        .slice(0, slice)
                        .map((media, i) => (
                          <div
                            key={i}
                            className="grow bg-gray-100 rounded-lg p-1.5 flex items-center"
                            style={{
                              gridRow: `${i + 1} / span 1`,
                              gridColumn: "2 / span 1",
                            }}
                          >
                            <InputWithMediaRender {...media} />
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="p-1 font-bold border-t-2 border-gray-100 flex items-center gap-1 text-gray-500">
            <div className="grow" />
            <NewButton onClick={handleConfirm} variant="primary">
              <TbCheck /> {t("v4.generic.confirm")}
            </NewButton>
          </div>
        </>
      )}
    </>
  );
};
