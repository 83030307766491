import {
  PageItemType,
  SlideParagraphTranslate,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { postTranslate } from "api/course/courseContentAPI";
import { textToParagraphs } from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { SlidePageGenerate } from "app/components/Exercises/CourseEdit/components/PageGenerate";

export const SlidePageParagraphTranslate = () => (
  <SlidePageGenerate
    promise={(item: SlideParagraphTranslate) =>
      postTranslate(item.data, item.language)
    }
    gen={({ translation }, { id, position, list }) =>
      ({
        id,
        position,
        verticalAlign: "center",
        type: list ? PageItemType.List : PageItemType.Paragraph,
        ...(list && { variant: list }),
        category: "slide",
        align: "left",
        data: textToParagraphs(translation),
      } as any)
    }
  />
);
