export enum ExercisesTypes {
  diagram = "diagram",
  flashcard = "flashcard",
  closed = "cloze",
  categorization = "categorization",
  sorting = "sorting",
  closed_language = "closed_language",
  mixed = "mixed",
  empty = "empty",
  from_doc = "from_doc",
  closed_long = "closed_long",
  information = "info",
  crossword = "crossword",
  slide = "slide",
  polling_mcq = "polling_mcq",
  polling_scales = "polling_scales",
  polling_cloud = "polling_cloud",
  polling_comment = "polling_comment",
}

export enum ExercisesLibraryItemType {
  folder = 0,
  exercise = 1,
  source = 2,
  course = 3,
}
