import {
  MultipleResponseGenerate,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { postMultipleResponse } from "api/course/courseContentAPI";
import { uuid } from "app/components/Exercises/utils/uuid";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { SkeletonParagraph } from "app/components/Exercises/CourseEdit/components/SkeletonParagraph";
import { usePageGenerate } from "app/components/Exercises/CourseEdit/components/PageGenerate";

export const SkeletonMultipleResponse = () => (
  <div className="bg-gray-100 rounded-lg p-1.5 flex flex-col gap-1">
    {[...new Array(4)].map((_, i) => (
      <div key={i} className="bg-white rounded-lg p-1.5 flex items-center">
        <div className="rounded-lg w-7 h-7 bg-gray-200 shrink-0" />
        <div className="px-2 py-1 grow">
          <SkeletonParagraph min={3} max={8} />
        </div>
      </div>
    ))}
  </div>
);

export const PageMultipleResponseGenerate = () => {
  usePageGenerate(
    (item: MultipleResponseGenerate) => postMultipleResponse(item.data),
    ({ items }) => {
      return {
        id: uuid(),
        type: PageItemType.MultipleChoice,
        category: "quiz",
        multi: true,
        instructions: [],
        items: items.map(({ text = "", isCorrect = false }) => ({
          id: uuid(),
          text,
          correct: isCorrect,
        })),
      };
    }
  );

  return (
    <PageItemWrapper>
      <SkeletonMultipleResponse />
    </PageItemWrapper>
  );
};
