import { create } from "zustand";
import { combine } from "zustand/middleware";
import { TbCornerDownRightDouble } from "react-icons/tb";
import React, { useMemo } from "react";
import {
  Item,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { textToParagraphs } from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";

type DistributiveOmit<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;

export enum DropMediaColumns {
  OneColumn = "oneColumn",
  TwoColumns = "twoColumns",
}

export interface MediaOneColumn {
  type: DropMediaColumns.OneColumn;
  data: string[];
}
export interface MediaTwoColumns {
  type: DropMediaColumns.TwoColumns;
  data: [string, string][];
}

export type DropMediaType<T extends Item = Item> = (
  | DistributiveOmit<T, "category" | "position" | "id">
  | MediaOneColumn
  | MediaTwoColumns
) & { id?: string };

// false in "data" means that MediaPicker needs to be closed and set to null
const useDropMediaContext = create(
  combine({ data: null as DropMediaType | null | false }, (set) => ({
    set: (data: DropMediaType | string | null | false) =>
      typeof data === "string"
        ? set({
            data: {
              type: PageItemType.Paragraph,
              data: textToParagraphs(data),
              align: "left",
            },
          })
        : set({ data }),
  }))
);

export const useDropMedia = () =>
  useDropMediaContext((state) => [state.data, state.set] as const);

export const DropHighlight = ({
  types,
}: {
  types: (type: DropMediaType["type"]) => boolean;
}) => {
  const [dropMedia] = useDropMedia();
  if (!dropMedia) return null;

  if (!types(dropMedia.type)) return null;

  return (
    <div className="absolute-cover rounded-lg pointer-events-none bg-primary animate-pulse bg-opacity-30" />
  );
};

export const DropMedia = ({
  onDrop,
}: {
  onDrop: (media: DropMediaType) => (() => void) | undefined | false;
}) => {
  const [dropMedia, setDropMedia] = useDropMedia();

  const fun = useMemo(() => {
    if (!dropMedia) return null;
    return onDrop(dropMedia);
  }, [onDrop, dropMedia]);

  if (!fun) return null;
  if (!dropMedia) return null;

  const handleMediaDrop = () => {
    fun();
    setDropMedia(false);
  };

  return (
    <div
      onClick={handleMediaDrop}
      className="absolute-cover rounded-lg cursor-pointer bg-primary animate-pulse bg-opacity-10 text-transparent hover:text-primary hover:bg-opacity-40 transition flex items-center justify-center"
    >
      <TbCornerDownRightDouble />
    </div>
  );
};
