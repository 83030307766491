import React, { createContext, useContext, useEffect, useState } from "react";
import { ExercisesTypes } from "enums/exercises";

interface Exercise {
  count: number;
  question: string;
  type: ExercisesTypes;
}

export const SessionContext = createContext<{
  isTeacher: { get: boolean; set: (value: boolean) => void };
  isDuration: { get: boolean; set: (value: boolean) => void };
  isOpen: { get: boolean; set: (value: boolean) => void };
  isLeaderboard: {
    get: boolean;
    set: (value: boolean) => void;
  };
  isInfoOnly: boolean;
  exercise: { get: any; set: (value: any) => void };
  exercises: {
    get: Exercise[];
    set: (value: any) => void;
    count: number;
  };
  selectedExercises: {
    get: number[];
    set: (value: any) => void;
    list: Exercise[];
    count: number;
  };
  creationStep: {
    get: number;
    set: (value: number) => void;
  };
  session: {
    get: any;
    set: (value: any) => void;
  };
  name: string;
}>({
  isTeacher: {
    get: false,
    set: () => {},
  },
  isDuration: {
    get: true,
    set: () => {},
  },
  isOpen: {
    get: true,
    set: () => {},
  },
  isLeaderboard: {
    get: true,
    set: () => {},
  },
  isInfoOnly: false,
  exercise: {
    get: {},
    set: () => {},
  },
  exercises: {
    get: [],
    set: () => {},
    count: 0,
  },
  selectedExercises: {
    get: [],
    set: () => {},
    list: [],
    count: 0,
  },
  creationStep: {
    get: 0,
    set: () => {},
  },
  session: {
    get: null,
    set: () => {},
  },
  name: "",
});

export const useSessionContext = () => useContext(SessionContext);

export const SessionProvider = ({ children, name }) => {
  const [isTeacher, setIsTeacher] = useState<boolean>(false);
  const [exercise, setExercise] = useState({});
  const [exercises, setExercises] = useState<Exercise[]>([]);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [creationStep, setCreationStep] = useState(-1);
  const [session, setSession] = useState(null);
  const [exercisesCount, setExercisesCount] = useState(0);
  const [selectedExercisesCount, setSelectedExercisesCount] = useState(0);
  const [selectedExercisesList, setSelectedExercisesList] = useState<
    Exercise[]
  >([]);
  const [isDuration, setIsDuration] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [isLeaderboard, setIsLeaderboard] = useState(true);
  const [isInfoOnly, setIsInfoOnly] = useState(false);

  useEffect(() => {
    setExercisesCount(
      exercises
        .filter(
          (e) =>
            e.type !== ExercisesTypes.slide &&
            e.type !== ExercisesTypes.information &&
            e.type !== ExercisesTypes.polling_cloud &&
            e.type !== ExercisesTypes.polling_mcq &&
            e.type !== ExercisesTypes.polling_scales &&
            e.type !== ExercisesTypes.polling_comment
        )
        .map((e) => e.count)
        .reduce((partialSum, a) => partialSum + a, 0)
    );
    const selectedExercisesIndexList = selectedExercises.reduce(function (
      a: any,
      e,
      i: number
    ) {
      if (e) a.push(i);
      return a;
    },
    []);
    const selectedExercisesArray = exercises.filter((e, index) => {
      if (selectedExercisesIndexList.includes(index)) {
        return e;
      }
    });

    setSelectedExercisesList(selectedExercisesArray);

    if (exercises.length) {
      if (
        selectedExercisesArray.every(
          (e) =>
            e?.type &&
            [
              ExercisesTypes.slide,
              ExercisesTypes.information,
              ExercisesTypes.polling_comment,
              ExercisesTypes.polling_mcq,
              ExercisesTypes.polling_scales,
              ExercisesTypes.polling_cloud,
            ].includes(e.type)
        )
      ) {
        setIsInfoOnly(true);
        setIsLeaderboard(false);
      } else {
        setIsInfoOnly(false);
      }
    }

    setSelectedExercisesCount(
      selectedExercisesArray
        .filter(
          (e) =>
            e.type !== ExercisesTypes.slide &&
            e.type !== ExercisesTypes.information &&
            e.type !== ExercisesTypes.polling_cloud &&
            e.type !== ExercisesTypes.polling_mcq &&
            e.type !== ExercisesTypes.polling_scales &&
            e.type !== ExercisesTypes.polling_comment
        )
        .map((e) => e.count)
        .reduce((partialSum, a) => partialSum + a, 0)
    );
  }, [exercises.length, selectedExercises]);

  return (
    <SessionContext.Provider
      value={{
        isTeacher: {
          get: isTeacher,
          set: setIsTeacher,
        },
        isDuration: {
          get: isDuration,
          set: setIsDuration,
        },
        isOpen: {
          get: isOpen,
          set: setIsOpen,
        },
        isLeaderboard: {
          get: isLeaderboard,
          set: setIsLeaderboard,
        },
        isInfoOnly,
        exercise: {
          get: exercise,
          set: setExercise,
        },
        exercises: {
          get: exercises,
          set: setExercises,
          count: exercisesCount,
        },
        selectedExercises: {
          get: selectedExercises,
          set: setSelectedExercises,
          list: selectedExercisesList,
          count: selectedExercisesCount,
        },
        creationStep: {
          get: creationStep,
          set: setCreationStep,
        },
        session: {
          get: session,
          set: setSession,
        },
        name,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
