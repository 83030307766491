import { createGlobalStyle } from "styled-components";

export const GlobalFlexStyles = createGlobalStyle`
  #root, #main {
    display: flex;
    flex-direction: column;
    > main {
      flex-grow: 1;
      position: relative;
    }
  }
`;
