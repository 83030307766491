import { useEffect, useMemo, useState } from "react";
import useLocalStorageState_ from "use-local-storage-state";

export const useBrowserStorageState =
  (storage: Storage) => (key: string, defaultValue?: string) => {
    const stored = useMemo(() => storage.getItem(key), [key]);
    const [state, setState_] = useState<string | undefined>(
      stored || defaultValue || undefined
    );
    useEffect(() => {
      if (!stored && defaultValue) {
        storage.setItem(key, defaultValue);
      }
    }, []);
    const setState = (value: string) => {
      setState_(value);
      storage.setItem(key, value);
    };
    return [state, setState] as [string | undefined, (value: string) => void];
  };

export const useLocalStorageState = useBrowserStorageState(localStorage);
useBrowserStorageState(sessionStorage);

export const usePersistentStorage = <T,>(key: string, defaultValue?: T) =>
  useLocalStorageState_(key, defaultValue ? { defaultValue } : undefined);
