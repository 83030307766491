import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  PageItemType,
  Paragraph,
  RichParagraph,
  SlideList,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { SlideItemWrapper } from "app/components/Exercises/CourseEdit/slide/SlideItemWrapper";
import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Editable,
  ReactEditor,
  Slate,
  useFocused,
  useSlate,
} from "slate-react";
import { Descendant, Editor, Range, Transforms } from "slate";
import { usePageItemFocus } from "app/components/Exercises/CourseEdit/items/PageItemFocus";
import {
  PageLeaf,
  paragraphsToText,
  useTextEditor,
} from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import {
  StyledEditable,
  toggleMarkHotkey,
} from "app/components/Exercises/Edit/questionType/Slide/item/ItemText";
import classNames from "classnames";
import { useSlideItemContext } from "app/components/Exercises/CourseEdit/items/SlideItemContext";
import {
  EditableWrapper,
  PageBlock,
  PageListRender,
} from "app/components/Exercises/CourseEdit/items/content/PageListItem";
import { isEmptyTextItem } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { ListGenerate } from "app/components/Exercises/CourseEdit/components/generate/ListGenerate";
import { DefaultElementType } from "app/components/Exercises/CourseEdit/components/ftg/FillTheGapUtils";
import {
  DropMedia,
  DropMediaType,
} from "app/components/Sources/MediaPicker/context/dropMediaContext";
import { useTranslation } from "react-i18next";
import { SlideFormatMenu } from "app/components/Exercises/CourseEdit/slide/items/SlidePageParagraph";

export const SlidePageList = () => {
  const [item, set] = usePageItemContext<SlideList>();
  const editor = useTextEditor();

  const handleTextChange = (text: Descendant[]) => {
    if (
      !editor?.operations ||
      (editor.operations.length === 1 &&
        editor.operations[0]?.type === "set_selection")
    )
      return;

    set((item) => {
      item.data = text.map((item) => (item as any).children);
    });
  };

  const text = useMemo<DefaultElementType[]>(() => {
    return item.data.map((children) => ({ type: "paragraph", children }));
  }, [item.data]);

  return (
    <Slate editor={editor} value={text} onChange={handleTextChange}>
      <SlidePageListInner />
    </Slate>
  );
};
export const SlidePageListInner = () => {
  const { t } = useTranslation();
  const editor = useSlate();
  const [item, set] = usePageItemContext<SlideList>();
  const [editing, setEditing] = useState(false);
  const { selected } = useSlideItemContext();

  const renderBlock = useCallback(
    (props) => <PageBlock slide {...props} />,
    []
  );
  const renderLeaf = useCallback((props) => <PageLeaf slide {...props} />, []);

  const focus = () => {
    setEditing(true);
    ReactEditor.focus(editor);
    Transforms.select(editor, Editor.end(editor, []));
  };

  useEffect(() => {
    if (!selected) setEditing(false);
  }, [selected]);

  const setFocus = usePageItemFocus((distance, reverse) => {
    ReactEditor.focus(editor);
    editor.children = item.data.map<any>((children) => ({
      type: "paragraph",
      children,
    }));
    if (reverse) {
      Transforms.select(editor, {
        anchor: Editor.end(editor, []),
        focus: Editor.end(editor, []),
      });
    } else {
      Transforms.select(editor, {
        anchor: Editor.start(editor, []),
        focus: Editor.start(editor, []),
      });
    }
    Transforms.move(editor, {
      distance: distance,
      reverse: reverse,
    });
  });

  const handlePointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
    if (editing) {
      e.stopPropagation();
      return false;
    }
    if (!selected) return;
    const startX = e.clientX;
    const startY = e.clientY;

    document.addEventListener(
      "pointerup",
      (e) => {
        if ((e.clientX - startX) ** 2 + (e.clientY - startY) ** 2 <= 5 ** 2) {
          focus();
        }
      },
      { once: true }
    );
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    toggleMarkHotkey(e, editor);
    // has to be backspace
    if (e.key === "Backspace") {
      // deleting the item without moving focus
      if (isEmptyTextItem(item)) {
        e.preventDefault();
        set<Paragraph>((item) => {
          item.type = PageItemType.Paragraph;
          item.data = [[{ text: "" }]];
          item.align = "left";
          delete item["variant"];
        });

        setFocus(item.id, 0, true);
        return;
      }
    }
  };

  const inFocus = useFocused();
  const hasSelected =
    inFocus && editor.selection && !Range.isCollapsed(editor.selection);

  const drop = (media: DropMediaType) => {
    const setData = (appendData: RichParagraph[]) => {
      set((item) => {
        item.data.push(...appendData);
      });
      setFocus(item.id, 0, true);
    };

    switch (media.type) {
      case PageItemType.Paragraph:
      case PageItemType.List:
        return () => {
          setData(media.data);
        };
    }
  };

  return (
    <SlideItemWrapper
      magic={
        hasSelected
          ? false
          : paragraphsToText(item.data).trim().length < 30
          ? t("v4.generate.prompt.minLength", { number: 30 }) || ""
          : (close) => <ListGenerate close={close} />
      }
      customToolbar={(trash) => (
        <>
          <SlideFormatMenu selected={hasSelected} list />
          {!hasSelected && trash}
        </>
      )}
    >
      <EditableWrapper
        variant={item.variant}
        className="absolute-cover"
        count={item.data.length}
      >
        <StyledEditable
          as={HasListEditable}
          list
          className={classNames(
            "absolute-cover text-gray-500 p-4 overflow-y-auto rounded-2xl flex flex-col select-text leading-relaxed text-2xl",
            editing
              ? "bg-gray-100 cursor-text select-text"
              : "cursor-default select-none"
          )}
          vertical={item.verticalAlign}
          readOnly={!editing}
          onKeyDown={handleKeyDown}
          onMouseDown={(e) => editing && e.stopPropagation()}
          renderLeaf={renderLeaf}
          renderElement={renderBlock}
          onPointerDown={handlePointerDown}
        />
        <DropMedia onDrop={drop} />
      </EditableWrapper>
    </SlideItemWrapper>
  );
};

const HasListEditable = ({ list = false, ...props }) => (
  <Editable as={list ? "ul" : undefined} {...props} />
);

export const SlideListRender = ({ item }: { item: SlideList }) => (
  <StyledEditable
    vertical={item.verticalAlign}
    className="absolute-cover flex flex-col p-4 pl-0 bg-white text-2xl [&_ul]:!font-sans overflow-y-auto"
  >
    <PageListRender slide item={item} />
  </StyledEditable>
);
