import React, { useEffect, useLayoutEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { authRoutes, miscRoutes, publicRoutes } from "enums/routes";
import { Link, useLocation } from "react-router-dom";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  TbArrowUp,
  TbBulb,
  TbMail,
  TbSparkles,
  TbWriting,
} from "react-icons/tb";
import { SubscriptionTile } from "app/pages/payment/Subscriptions";
import { InView } from "react-intersection-observer";
import classNames from "classnames";

import mainVideo from "assets/landingPage/Webpage Video Main.webm";
import smartVideo from "assets/landingPage/Webpage Video Smart.webm";
import engagingVideo from "assets/landingPage/Webpage Video Engaging.webm";
import resultsVideo from "assets/landingPage/Webpage Video Results.webm";
import contactBg from "assets/landingPage/contact_bg.webp";
import innoSuisseLogo from "assets/landingPage/InnoSuisse logo.webp";
import edTechLogo from "assets/landingPage/EdTech collider logo.webp";
import msStartupsLogo from "assets/landingPage/Microsoft for startups logo.webp";
import nvidiaLogo from "assets/landingPage/NVDIA Inception logo.webp";
import { useHistory } from "react-router";
import { IoLogoLinkedin, IoMail } from "react-icons/io5";

export const B2BLandingPage = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center text-center py-12 whitespace-pre-wrap text-gray-600 px-4 md:px-8 leading-relaxed">
      <div className="flex flex-col items-center text-center my-28">
        <h1 className="text-3xl md:text-4xl lg:text-5xl mb-6">
          {t("v4.landingPage.b2b.title")}
        </h1>
        <p className="md:text-lg lg:text-xl">
          {t("v4.landingPage.b2b.description")}
        </p>
        <NewButton
          component={Link}
          to={authRoutes.register}
          size="lg"
          className="!px-6 !py-3 text-xl mt-16"
          variant="primary"
        >
          {t("authPages.register.submitBtn.label")}
        </NewButton>
      </div>

      <h1 className="mt-16 text-3xl md:text-4xl lg:text-5xl mb-4">
        {t("v4.landingPage.contact")}
      </h1>
      <div className="md:text-lg lg:text-xl">
        <Trans
          i18nKey="v4.landingPage.email"
          components={{
            a: (
              <a
                href="mailto:support@smartest.io"
                className="text-primary hover:underline"
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

export const LandingPage = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation<{ anchor?: string }>();
  const history = useHistory();
  const anchor = location.state?.anchor;
  const mountedRef = useRef(false);

  useLayoutEffect(() => {
    if (anchor) {
      setTimeout(
        () => {
          document
            .getElementById(anchor)
            ?.scrollIntoView({ behavior: "smooth" });
          history.replace(publicRoutes.home);
        },
        mountedRef.current ? 0 : 100
      );
    }
  }, [anchor]);

  useEffect(() => {
    mountedRef.current = true;
  }, []);

  return (
    <>
      <div
        id="home"
        className="flex flex-col items-center text-center py-12 whitespace-pre-wrap text-gray-600 px-4 md:px-12 leading-relaxed w-full max-w-7xl mx-auto"
      >
        <div className="flex flex-col items-center text-center my-28">
          <h1 className="text-3xl md:text-4xl lg:text-5xl mb-6">
            {t("v4.landingPage.b2c.title")}
          </h1>
          <p className="md:text-lg lg:text-xl">
            {t("v4.landingPage.b2c.description")}
          </p>
          <NewButton
            component={Link}
            to={authRoutes.register}
            size="lg"
            className="!px-6 !py-3 text-xl mt-16"
            variant="primary"
          >
            {t("v4.landingPage.register.forFree")}
          </NewButton>
          <span className="mt-1 text-gray-500">
            {t("v4.landingPage.register.noCard")}
          </span>
        </div>

        <div className="w-full md:my-24 flex flex-col justify-center relative">
          <InView
            as="div"
            className="flex"
            threshold={0.4}
            onChange={(inView, e) =>
              inView && (e.target as HTMLVideoElement)?.play?.()
            }
          >
            {({ inView, ref }) => (
              <video
                ref={ref}
                src={mainVideo}
                playsInline
                className={classNames(
                  "w-auto max-h-[max(25rem,70vh)] mx-auto rounded-2xl shadow-2xl shadow-black/30 border-gray-100 border transition",
                  !inView && "opacity-80"
                )}
                controls={false}
                autoPlay={false}
                muted
                loop
              />
            )}
          </InView>
        </div>

        {[
          {
            title: t("v4.landingPage.b2c.smart"),
            description: t("v4.landingPage.b2c.smartText"),
            video: smartVideo,
            icon: TbBulb,
          },
          {
            title: t("v4.landingPage.b2c.engaging"),
            description: t("v4.landingPage.b2c.engagingText"),
            video: engagingVideo,
            right: true,
            icon: TbSparkles,
          },
          {
            title: t("v4.landingPage.b2c.results"),
            description: t("v4.landingPage.b2c.resultsText"),
            video: resultsVideo,
            icon: TbWriting,
          },
        ].map((s) => (
          <div
            key={s.title}
            className={classNames(
              "grid items-center gap-10 md:gap-12 my-32",
              s.right ? "lg:grid-cols-[2fr_1fr]" : "lg:grid-cols-[1fr_2fr]"
            )}
          >
            <div
              className={classNames(
                "flex flex-col gap-4 text-left",
                s.right && "lg:col-start-2"
              )}
            >
              <div className="text-3xl sm:text-4xl font-bold leading-none">
                <s.icon className="inline text-4xl sm:text-5xl -mt-2 mr-3" />
                {s.title}
              </div>
              <div className="">{s.description}</div>
            </div>
            <InView
              as="div"
              threshold={0.4}
              onChange={(inView, e) =>
                inView && (e.target as HTMLVideoElement)?.play?.()
              }
            >
              {({ inView, ref }) => (
                <video
                  ref={ref}
                  src={s.video}
                  playsInline
                  className={classNames(
                    "w-full rounded-2xl shadow-2xl shadow-black/30 border-gray-100 border transition",
                    !inView && "opacity-80",
                    s.right && "lg:col-start-1 lg:row-start-1"
                  )}
                  controls={false}
                  autoPlay={false}
                  loop
                  muted
                />
              )}
            </InView>
          </div>
        ))}

        <div
          className="flex flex-col items-center pb-32 pt-16 gap-16"
          id="pricing"
        >
          <h1 className="text-3xl md:text-4xl">
            {t("v4.subscriptions.pricing")}
          </h1>
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 place-content-center justify-center justify-items-center gap-2 mx-auto px-2 lg:px-0">
            <SubscriptionTile
              name={t("v4.subscriptions.plans.free")}
              enabled={[t("v4.subscriptions.features.consumption")]}
              disabled={[
                t("v4.subscriptions.features.credits"),
                t("v4.subscriptions.features.liveSession"),
                t("v4.subscriptions.features.creation"),
                t("v4.subscriptions.features.assign"),
              ]}
            >
              <>
                <div className="flex flex-col gap-2 mt-auto">
                  <div className="flex gap-0.5 mt-4">
                    <span className="text-4xl font-bold leading-none">
                      {t("v4.subscriptions.plans.free")}
                    </span>
                  </div>
                  <span className="opacity-80 ml-0.5 leading-none text-sm my-2">
                    {t("v4.subscriptions.freeSubtitle")}
                  </span>
                </div>
                <NewButton
                  component={Link}
                  to={authRoutes.register}
                  className="font-bold"
                  color="bg-primary text-primary"
                  center
                  size="lg"
                >
                  {t("v4.subscriptions.plans.getFree")}
                </NewButton>
              </>
            </SubscriptionTile>
            <SubscriptionTile
              name={t("v4.subscriptions.plans.basic")}
              price={15}
              enabled={[
                t("v4.subscriptions.features.consumption"),
                <Trans
                  i18nKey="v4.subscriptions.features.creditsLimit"
                  values={{ credits: 2500, trainings: 15 }}
                  components={{ b: <b /> }}
                />,
                <Trans
                  i18nKey="v4.subscriptions.features.liveSessionLimit"
                  values={{ number: 100 }}
                  components={{ b: <b /> }}
                />,
                t("v4.subscriptions.features.creation"),
                t("v4.subscriptions.features.assign"),
              ]}
            >
              <NewButton
                component={Link}
                to={miscRoutes.subscriptions}
                className="font-bold"
                color="bg-primary text-primary"
                center
                size="lg"
              >
                {t("v4.subscriptions.plans.getBasic")}
              </NewButton>
            </SubscriptionTile>
            <SubscriptionTile
              color="bg-primary text-white"
              name={t("v4.subscriptions.plans.pro")}
              price={30}
              enabled={[
                t("v4.subscriptions.features.consumption"),
                <Trans
                  i18nKey="v4.subscriptions.features.creditsLimit"
                  values={{ credits: 7500, trainings: 45 }}
                  components={{ b: <b /> }}
                />,
                <Trans
                  i18nKey="v4.subscriptions.features.liveSessionLimit"
                  values={{ number: 500 }}
                  components={{ b: <b /> }}
                />,
                t("v4.subscriptions.features.creation"),
                t("v4.subscriptions.features.assign"),
              ]}
            >
              <NewButton
                component={Link}
                to={miscRoutes.subscriptions}
                className="font-bold"
                variant="primary"
                color="bg-white text-primary"
                center
                size="lg"
              >
                {t("v4.subscriptions.plans.getPro")}
              </NewButton>
            </SubscriptionTile>

            <div className="flex flex-col gap-6 text-left max-w-sm p-4 rounded-2xl bg-white w-full shadow-2xl shadow-black/20">
              <div className="text-2xl font-bold">
                {t("v4.subscriptions.plans.enterprise")}
              </div>
              <div className="flex flex-col grow justify-center items-center text-center">
                {t("v4.subscriptions.features.custom")}
              </div>
              <NewButton
                component="a"
                href="mailto:support@smartest.io?subject=Smartest Enterprise Licence"
                className="font-bold"
                color="bg-primary text-primary"
                center
                size="lg"
              >
                <TbMail />
                {t("v4.subscriptions.contactUs")}
              </NewButton>
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex flex-col py-32 px-8 gap-12 text-center justify-center items-center w-full bg-center bg-cover bg-fixed relative"
        style={{ backgroundImage: `url(${contactBg})` }}
      >
        <div className="absolute-cover bg-slate-800 bg-opacity-90" />
        <div className="relative text-2xl md:text-4xl font-bold text-white max-w-7xl mx-auto w-full">
          {t("v4.landingPage.b2c.generateCTA")}
        </div>

        <NewButton
          component={Link}
          to={authRoutes.register}
          size="lg"
          className="!px-8 !py-3 text-xl relative"
          variant="primary"
        >
          {t("v4.subscriptions.plans.getFree")}
        </NewButton>
      </div>

      <div className="flex flex-col bg-slate-900">
        <div className="flex flex-col gap-8 px-8 py-16 text-center justify-center items-center">
          <div className="text-xl font-bold text-white">
            {t("v4.landingPage.b2c.supportedBy")}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 place-items-center">
            <img src={innoSuisseLogo} />
            <img src={edTechLogo} />
            <img src={msStartupsLogo} />
            <img src={nvidiaLogo} />
          </div>
        </div>
        <div className="flex gap-2 justify-center items-center p-2">
          <NewButton
            iconOnly
            component="a"
            href="https://www.linkedin.com/company/smartestlearning"
            variant="transparent"
            color="bg-white text-white"
          >
            <IoLogoLinkedin className="!text-xl" />
          </NewButton>
          <NewButton
            iconOnly
            component="a"
            href="mailto:support@smartest.io"
            variant="transparent"
            color="bg-white text-white"
          >
            <IoMail className="!text-xl" />
          </NewButton>
          <SimpleLink
            to={publicRoutes.terms}
            target="_blank"
            className="flex sm:hidden text-white text-sm"
          >
            {t("v4.landingPage.b2c.termsPrivacy")}
          </SimpleLink>
        </div>
        <div className="relative flex items-center py-4 px-2 gap-4 text-white max-w-7xl w-full mx-auto border-t border-slate-700 text-sm">
          <span className="text-left mr-auto text-xs xs:text-sm">
            &copy; 2023 Smartest Learning AG
          </span>
          <SimpleLink
            to={publicRoutes.terms}
            target="_blank"
            className="hidden sm:flex top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute"
          >
            {t("v4.landingPage.b2c.termsPrivacy")}
          </SimpleLink>
          <SimpleLink
            className="text-right ml-auto"
            to={{ pathname: publicRoutes.home, state: { anchor: "home" } }}
          >
            <TbArrowUp className="inline text-lg -mt-1" />{" "}
            {t("v4.landingPage.b2c.backToTop")}
          </SimpleLink>
        </div>
      </div>
    </>
  );
};

const SimpleLink = ({ to, children, target, className }: any) => (
  <Link
    to={to}
    target={target}
    className={classNames("cursor-pointer hover:underline", className)}
  >
    {children}
  </Link>
);
