import React, { ReactNode } from "react";
import { Header, OnboardingAuthHeader } from "../components/Header";
import { AuthUserRoutes } from "../../enums/routes";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";

type Props = {
  children: ReactNode;
};

const ScHeader = styled.header<{ isExercise: boolean }>`
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;
  left: 20px;
  background-color: ${(p) =>
    p.isExercise ? "transparent" : "rgba(247, 247, 247, 0.7)"};
  backdrop-filter: blur(3px);
  display: ${(p) => p.isExercise && "none"};
`;

export const ExerciseLayout = ({ children }: Props) => {
  const isLiveSession = !!useRouteMatch({ path: AuthUserRoutes.session });

  return (
    <>
      <ScHeader isExercise={isLiveSession} />
      {children}
    </>
  );
};

export const FullWidthLayout = ({ children }: Props) => (
  <>
    <Header />
    <main>{children}</main>
  </>
);

export const Layout = ({ children }: Props) => (
  <>
    <Header />
    <main className="max-w-5xl mx-auto">{children}</main>
  </>
);

export const OnboardingLayout = ({ children }: Props) => (
  <>
    <OnboardingAuthHeader />
    <main className="max-w-5xl mx-auto">{children}</main>
  </>
);
