import { motion } from "framer-motion";
import React, { CSSProperties, ReactNode } from "react";

type Props = {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
  id?: string;
};
const Transition = ({ children, style, className, id }: Props) => (
  <motion.div
    style={{ width: "100%", overflow: "hidden", ...style }}
    initial={{ opacity: 0, scale: 0.9 }}
    animate={{ opacity: 1, scale: 1 }}
    className={className}
    id={id}
  >
    {children}
  </motion.div>
);

export default Transition;
