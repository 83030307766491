import {
  usePageItemContext,
  usePageItemIndex,
  useSetList,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import { PageGenerate } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { postPageFromSource } from "api/course/courseContentAPI";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SkeletonParagraph } from "app/components/Exercises/CourseEdit/components/SkeletonParagraph";
import { useMountedRef } from "app/hooks/useMounted";
import { handleOpenAIError } from "app/components/Exercises/CourseEdit/items/generate/PageParagraphAutocomplete";
import { useSubscriptionRefetch } from "api/userAPI";
import { useRole } from "app/hooks/useRole";

export const PagePageGenerate = () => {
  const mountedRef = useMountedRef();
  const { t } = useTranslation();
  const index = usePageItemIndex();
  const indexRef = useRef(index);
  indexRef.current = index;
  const setList = useSetList();
  const [item] = usePageItemContext<PageGenerate>();
  const refetchSubscription = useSubscriptionRefetch();
  const role = useRole();

  useEffect(() => {
    postPageFromSource({ sourceId: item.data.sourceId })
      .then((data) => {
        if (!data.items) return;
        setList((list) => {
          list.splice(indexRef.current, 1, ...data.items);
        });
        refetchSubscription();
      })
      .catch((e) => {
        handleOpenAIError(e, t, role);
        if (!mountedRef.current) return;
        setList((list) => {
          list.splice(indexRef.current, 1);
        });
      });
  }, []);

  return (
    <PageItemWrapper>
      <div className="flex flex-col">
        <div className="font-bold text-gray-400 px-1 mb-1">
          {t("v4.generate.generating")}
        </div>
        <div className="leading-relaxed">
          <SkeletonParagraph min={30} max={60} />
        </div>
      </div>
    </PageItemWrapper>
  );
};
