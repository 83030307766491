import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  TbAlertTriangle,
  TbCheck,
  TbCircleCheck,
  TbPlus,
  TbRefresh,
  TbRefreshAlert,
  TbRotate360,
  TbSubtask,
  TbTrash,
  TbX,
} from "react-icons/tb";
import {
  usePageItemContext,
  usePageItemIndex,
  useSetList,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  ConsumptionType,
  ContentField,
  Flashcards,
  FlashcardsQuiz,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import {
  MediaData,
  SelectOnlyType,
} from "app/components/Sources/MediaPicker/MediaPicker";
import { useItemFocus } from "app/components/Exercises/CourseEdit/items/PageItemFocus";
import { uuid } from "app/components/Exercises/utils/uuid";
import {
  InputWithMedia,
  InputWithMediaRender,
} from "app/components/Exercises/CourseEdit/components/InputWithMedia";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  DropMedia,
  DropMediaColumns,
  useDropMedia,
} from "app/components/Sources/MediaPicker/context/dropMediaContext";
import { TwoColumnsModal } from "app/components/Exercises/CourseEdit/components/generate/TableGenerate";
import classNames from "classnames";
import arrayShuffle from "array-shuffle";
import { motion } from "framer-motion";
import styled from "styled-components";
import {
  QuizDropzone,
  QuizItemLabel,
} from "app/components/Exercises/CourseEdit/components/generate/QuizDropzone";
import { useTranslation } from "react-i18next";
import { ImportQuizFromTable } from "../quiz/PagePairingItem";

export const FlashcardsItem = ({ quiz = false }) => {
  const [isPairingModal, setIsPairingModal] = useState(false);
  const [item, set] = quiz
    ? usePageItemContext<FlashcardsQuiz>()
    : usePageItemContext<Flashcards>();
  const index = usePageItemIndex();
  const setList = useSetList();

  const [dropMedia, setDropMedia] = useDropMedia();
  const handleConfirm = (data: [ContentField, ContentField][]) => {
    setDropMedia({
      type: PageItemType.Pairing,
      items: data,
      instructions: [],
    });
  };
  const { t } = useTranslation();

  const insertTwoColumns = (data: [string, string][]) => {
    set<Flashcards>((item) => {
      // delete empty items
      item.items
        .slice()
        .reverse()
        .forEach(([left, right], i, list) => {
          if (!left.text && !left.media && !right.text && !right.media)
            item.items.splice(list.length - 1 - i, 1);
        });

      item.items.push(
        ...(data.map(([left, right]) => [
          { text: left, id: uuid() },
          { text: right, id: uuid() },
        ]) as Flashcards["items"])
      );
    });
  };

  return (
    <PageItemWrapper
      magic={(close) => (
        <>
          <NewButton
            variant="transparent"
            onClick={() => {
              setIsPairingModal(true);
              close();
            }}
          >
            <TbSubtask /> {t("v4.item.pairing.text")}
          </NewButton>
        </>
      )}
    >
      {isPairingModal && (
        <TwoColumnsModal
          close={() => setIsPairingModal(false)}
          data={item.items}
          onConfirm={handleConfirm}
        />
      )}
      <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
        <QuizItemLabel
          type={quiz ? PageItemType.FlashcardsQuiz : PageItemType.Flashcards}
          consumptionType={
            quiz
              ? [
                  ConsumptionType.multipleChoice,
                  ConsumptionType.strictTypeTheAnswer,
                ]
              : undefined
          }
        >
          <ImportQuizFromTable
            selectOnly={SelectOnlyType.twoColumns}
            onInsert={({ data }) => insertTwoColumns(data)}
          />
        </QuizItemLabel>
        {quiz && <QuizDropzone />}
        {item.items.map(([left, right], i) => (
          <div
            className="flex pairing-group relative rounded-xl shadow-xl"
            key={left.id + "-" + right.id}
          >
            <div className="flex-1 flex bg-white rounded-l-xl p-1.5">
              <SingleFlashcardSide {...left} index={i} left />
            </div>
            <div className="flex-1 flex bg-gray-600 rounded-r-xl p-1.5">
              <SingleFlashcardSide {...right} index={i} />
            </div>
            <NewButton
              iconOnly
              color="bg-red-500 text-red-500"
              size="lg"
              className="mb-auto absolute top-0 -right-2.5 transform translate-x-full opacity-0 [.pairing-group:hover>&]:opacity-100"
              center
              onClick={() => {
                if (item.items.length === 1) {
                  setList((list) => {
                    list.splice(index, 1);
                  });
                } else {
                  set((item) => {
                    item.items.splice(i, 1);
                  });
                }
              }}
            >
              <TbTrash />
            </NewButton>
          </div>
        ))}

        {dropMedia &&
        (dropMedia.type === PageItemType.Flashcards ||
          dropMedia.type === PageItemType.FlashcardsQuiz ||
          dropMedia.type === DropMediaColumns.TwoColumns) ? (
          <div className="h-24 relative w-full">
            <DropMedia
              onDrop={(media) => {
                if (media.type === DropMediaColumns.TwoColumns) {
                  return () => insertTwoColumns(media.data);
                }
                if (
                  media.type === PageItemType.Flashcards ||
                  media.type === PageItemType.FlashcardsQuiz
                ) {
                  return () =>
                    set<Flashcards>((item) => {
                      item.items.push(
                        ...(media.items.map(([left, right]) => [
                          { ...left, id: uuid() },
                          { ...right, id: uuid() },
                        ]) as Flashcards["items"])
                      );
                    });
                }
              }}
            />
          </div>
        ) : (
          <NewButton
            center
            variant="bg-opacity-0 hover:bg-opacity-80"
            color="bg-white text-gray-500"
            size="lg"
            className="font-bold mt-1"
            onClick={() => {
              set((page: Flashcards) => {
                const id = uuid();
                page.items.push([
                  { id, text: "" },
                  { id, text: "" },
                ]);
              });
            }}
          >
            <TbPlus /> {t("v4.generic.addItem")}
          </NewButton>
        )}
      </div>
    </PageItemWrapper>
  );
};

export const SingleFlashcardSide = ({
  id: itemId,
  text,
  media,
  index: itemIndex,
  left,
}: {
  index: number;
  left?: boolean;
} & Flashcards["items"][number][number]) => {
  const [item, set] = usePageItemContext<Flashcards | FlashcardsQuiz>();
  const sideIndex = left ? 0 : 1;
  const { t } = useTranslation();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  useItemFocus(itemId, (distance, reverse) => {
    const pos = reverse ? text.length - distance : distance;
    inputRef.current?.focus();
    inputRef.current?.setSelectionRange(pos, pos);
  });

  const isTTA =
    item.type === PageItemType.FlashcardsQuiz &&
    item.consumptionType === ConsumptionType.strictTypeTheAnswer;
  const hideMedia = useMemo(() => !left && isTTA, [!left, isTTA]);

  const handleText = useCallback(
    (text: string) => {
      set((page: Flashcards) => {
        page.items[itemIndex][sideIndex].text = text;
      });
    },
    [set]
  );

  const handleMedia = useCallback(
    (media?: MediaData) => {
      set((page: Flashcards) => {
        if (!media) {
          delete page.items[itemIndex][sideIndex]["media"];
        } else {
          page.items[itemIndex][sideIndex].media = media;
        }
      });
    },
    [set]
  );

  return (
    <div
      className={classNames(
        left ? "bg-gray-100" : "bg-white",
        "rounded-lg p-1.5 flex grow"
      )}
    >
      <InputWithMedia
        ref={inputRef}
        onText={handleText}
        text={text}
        onMedia={hideMedia ? undefined : handleMedia}
        media={hideMedia ? undefined : media}
        className="grow"
        placeholder={t("v4.generic.textPlaceholder")}
        maxLength={500}
      />
    </div>
  );
};

export const FlashcardsItemRender = ({ item }: { item: Flashcards }) => {
  const { t } = useTranslation();
  const { items } = item;
  const [shuffled, setShuffled] = useState(() => arrayShuffle(items));
  const [progress, setProgress] = useState<boolean[]>([]);
  const [incorrectCount, setIncorrectCount] = useState(0);

  const length = progress.length;
  const isDone = progress.length === shuffled.length;

  useEffect(() => {
    if (isDone) setIncorrectCount(progress.filter((item) => !item).length);
  });

  const resetAll = () => {
    if (!isDone) return;
    setShuffled(arrayShuffle(items));
    setProgress([]);
  };

  const resetIncorrect = () => {
    if (!isDone) return;
    const items = shuffled.filter((_, i) => !progress?.[i]);
    setShuffled(arrayShuffle(items));
    setProgress([]);
  };

  const handleAnswer = (value: boolean) => {
    if (isDone) return;
    setIncorrectCount(0);
    setProgress([...progress, value]);
  };

  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1 relative overflow-hidden select-none">
      <QuizItemLabel type={PageItemType.Flashcards} />
      <div className="grid grid-cols-1 grid-rows-1 items-center">
        <motion.div
          initial="hidden"
          animate={isDone ? "visible" : "hidden"}
          style={{
            gridColumn: "1 / span 1",
            gridRow: "1 / span 1",
          }}
          variants={{
            hidden: { opacity: 0, pointerEvents: "none" },
            visible: { opacity: 1, pointerEvents: "auto" },
          }}
          className="bg-white rounded-3xl font-bold p-6 text-gray-600 shadow-2xl w-full text-center flex gap-2 flex-col justify-center items-center max-w-screen-md"
        >
          {incorrectCount > 0 ? (
            <TbAlertTriangle className="text-5xl" />
          ) : (
            <TbCircleCheck className="text-5xl" />
          )}
          <div>{t("v4.item.flashcards.allMarked")}</div>
          {incorrectCount > 0 ? (
            <div className="text-xl text-red-700">
              {incorrectCount} {t("v4.item.flashcards.incorrect")}
            </div>
          ) : (
            <div className="text-xl text-green-700">
              {t("v4.item.flashcards.allCorrect")}
            </div>
          )}
          <div className="flex gap-1 mt-2">
            {incorrectCount > 0 && (
              <NewButton
                color="bg-green-600 text-green-600"
                onClick={resetIncorrect}
              >
                <TbRefreshAlert className="text-5xl" />
                {t("v4.item.flashcards.restartIncorrect")}
              </NewButton>
            )}
            <NewButton onClick={resetAll}>
              <TbRefresh className="text-5xl" />
              {t("v4.item.flashcards.restartAll")}
            </NewButton>
          </div>
        </motion.div>
        {items.map((item, i) => (
          <SingleFlashcardRender
            key={item[0].id}
            item={item}
            status={progress?.[i]}
            hidden={i > length || isDone}
            onAnswer={handleAnswer}
          />
        ))}
      </div>
    </div>
  );
};

enum AnimationState {
  None,
  Incorrect,
  Correct,
}

const SingleFlashcardRender = ({
  status,
  item,
  hidden,
  onAnswer,
}: {
  hidden: boolean;
  status?: boolean;
  item: [ContentField, ContentField];
  onAnswer: (value: boolean) => void;
}) => {
  const { t } = useTranslation();
  const [animationState, setAnimationState] = useState(AnimationState.None);
  // const changeAnim = (state: AnimationState) => () => setAnimationState(state);
  const [flipped_, setFlipped] = useState(false);

  const flipped = useMemo(() => flipped_ && !hidden, [flipped_, hidden]);

  useEffect(() => {
    if (status == null || hidden) setFlipped(false);
  }, [status]);

  return (
    <div
      style={{
        gridColumn: "1 / span 1",
        gridRow: "1 / span 1",
        gridTemplateRows: "1fr auto",
        gridTemplateColumns: "1fr 4fr 1fr",
      }}
      onClick={() => setFlipped(!flipped)}
      className={classNames(
        "grid grid-cols-1 items-center h-full w-full relative gap-1 transition cursor-pointer",
        (hidden || status != null) && "opacity-0 pointer-events-none"
      )}
    >
      <motion.div
        style={{
          originY: 0.5,
          gridColumn: "2 / span 1",
          gridRow: "1 / span 1",
          gridTemplateRows: "1fr auto",
        }}
        initial="initial"
        exit="exit"
        animate={
          hidden
            ? "initial"
            : status === true
            ? "correct"
            : status === false
            ? "incorrect"
            : animationState === AnimationState.Incorrect
            ? "preIncorrect"
            : animationState === AnimationState.Correct
            ? "preCorrect"
            : "visible"
        }
        className="grid grid-cols-1 items-center h-full w-full relative"
        transition={{ rotate: { duration: 0.3 } }}
        variants={{
          initial: { scale: 0.9, opacity: 0, pointerEvents: "none" },
          visible: { scale: 1, opacity: 1, pointerEvents: "auto" },
          preCorrect: {
            scale: 1,
            translateX: "1rem",
            rotate: "2deg",
            opacity: 1,
          },
          preIncorrect: {
            scale: 1,
            translateX: "-1rem",
            rotate: "-2deg",
            opacity: 1,
          },
          correct: {
            scale: 1,
            opacity: 0,
            pointerEvents: "none",
            translateX: "100%",
          },
          incorrect: {
            scale: 1,
            opacity: 0,
            pointerEvents: "none",
            translateX: "-100%",
          },
        }}
      >
        <Card
          flipped={flipped}
          className="bg-white rounded-3xl font-bold p-6 text-gray-600 shadow-2xl transition w-full text-center flex flex-col max-w-screen-md"
        >
          <InputWithMediaRender
            className="[&>div:first-child]:text-center [&>div:first-child]:justify-center gap-4"
            text={item[0].text}
            media={item[0].media}
          />
        </Card>
        <Card
          flipped={!flipped}
          className="bg-gray-100 rounded-3xl font-bold p-6 text-gray-600 shadow-2xl transition w-full text-center flex flex-col max-w-screen-md"
        >
          <InputWithMediaRender
            className="[&>div:first-child]:text-center [&>div:first-child]:justify-center gap-4"
            text={item[1].text}
            media={item[1].media}
          />
        </Card>
      </motion.div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onAnswer(false);
        }}
        // onPointerOver={changeAnim(AnimationState.Incorrect)}
        // onPointerLeave={changeAnim(AnimationState.None)}
        className={classNames(
          "h-full bg-gradient-to-r from-red-500 to-transparent rounded-lg transition duration-500",
          animationState === AnimationState.Incorrect
            ? "opacity-20"
            : "opacity-0"
        )}
        style={{ gridColumn: "1 / span 1", gridRow: "1 / span 2" }}
      />
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onAnswer(true);
        }}
        // onPointerOver={changeAnim(AnimationState.Correct)}
        // onPointerLeave={changeAnim(AnimationState.None)}
        className={classNames(
          "h-full bg-gradient-to-l from-green-500 to-transparent rounded-lg transition duration-500",
          animationState === AnimationState.Correct ? "opacity-20" : "opacity-0"
        )}
        style={{ gridColumn: "3 / span 1", gridRow: "1 / span 2" }}
      />
      <div
        style={{
          gridRow: "2 / span 1",
          gridColumn: "1 / span 3",
          gridTemplateColumns: "1fr 4fr 1fr",
        }}
        className="grid grid-rows-1 gap-1 relative pointer-events-none"
      >
        <div className="shadow-lg rounded-lg bg-red-50 text-red-700 flex items-center justify-center text-center p-1">
          <TbX className="text-2xl" strokeWidth={3} />
        </div>
        <div className="shadow-lg rounded-lg bg-gray-50 text-gray-700 flex gap-1 items-center justify-center text-center p-1">
          <TbRotate360 className="text-xl" strokeWidth={2} />{" "}
          {t("v4.item.flashcards.flip")}
        </div>
        <div className="shadow-lg rounded-lg bg-green-50 text-green-700 flex items-center justify-center text-center p-1">
          <TbCheck className="text-2xl" strokeWidth={3} />
        </div>
      </div>
    </div>
  );
};

const Card = styled.div<{ flipped: boolean }>`
  backface-visibility: hidden;
  transform: ${(p) => `rotateX(${p.flipped ? 180 : 0}deg)`};
  transition-duration: 0.5s;
  opacity: ${(p) => (p.flipped ? 0 : 1)};
  pointer-events: ${(p) => p.flipped && "none"};
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
`;
