import React, { useMemo } from "react";
import { Header } from "app/components/Header";
import { GroupInfo, GroupSidebar } from "app/pages/groups/GroupMembers";
import { useInfiniteQuery } from "react-query";
import {
  getGroupAssignments,
  getGroupAssignmentsReceived,
} from "api/assignments/assignmentsAPI";
import { Link, useParams } from "react-router-dom";
import { InView } from "react-intersection-observer";
import classNames from "classnames";
import { courseRoutes } from "enums/routes";
import { TbCalendar } from "react-icons/tb";
import { useDateFormat } from "app/hooks/useDateFormat";
import { useTranslation } from "react-i18next";
import { useIsGroupAdmin } from "app/hooks/useRole";
import {
  AssignmentBadges,
  AssignmentButtons,
} from "app/pages/courses/CourseActivity";
import { ConsumptionAssignmentItem } from "app/pages/courses/CoursesListView";

const Skeleton = () => (
  <>
    {[...new Array(15)].map((_, i) => (
      <div
        key={i}
        className="rounded-lg h-32 bg-gray-200 animate-pulse"
        style={{ animationDelay: i * 0.2 + "s" }}
      />
    ))}
  </>
);

export const GroupAssignments = () => {
  const { t } = useTranslation();
  const { id: groupId } = useParams<{ id: string }>();
  const isGroupAdmin = useIsGroupAdmin(groupId);
  const assignedQuery = useInfiniteQuery(
    ["assignments", "group", groupId],
    ({ pageParam: page = 0 }) => getGroupAssignments(groupId, { page }),
    {
      getNextPageParam: ({ assignments_total }, pages) =>
        assignments_total > pages.length * 20 ? pages.length : undefined,
      enabled: isGroupAdmin.isSuccess && isGroupAdmin.data,
    }
  );

  const receivedQuery = useInfiniteQuery(
    ["courses", "student", "group", groupId],
    ({ pageParam: page = 0 }) => getGroupAssignmentsReceived(groupId, { page }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: ({ courses_total }, pages) =>
        courses_total > pages.length * 20 ? pages.length : undefined,
      enabled: isGroupAdmin.isSuccess && !isGroupAdmin.data,
    }
  );

  const { data, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage } =
    isGroupAdmin.data ? assignedQuery : receivedQuery;

  const list = useMemo(
    () =>
      (data?.pages || []).reduce(
        (list, group) => [
          ...list,
          ...(isGroupAdmin.data ? group.assignments : group.courses),
        ],
        []
      ),
    [data?.pages, isGroupAdmin.data]
  );

  return (
    <>
      <Header />
      <main className="flex flex-col w-full justify-center py-6 px-4 text-gray-600">
        <GroupInfo />
        <div className="flex flex-col md:flex-row justify-center">
          <GroupSidebar />
          <div className="max-w-3xl grow">
            {!isSuccess ? (
              <div className="flex flex-col space-y-3">
                <Skeleton />
              </div>
            ) : (
              <>
                <div className="flex flex-col space-y-3">
                  {!list.length ? (
                    <div className="text-xl text-center text-gray-500 py-5">
                      {t("v4.assignment.noItems")}
                    </div>
                  ) : isGroupAdmin.data ? (
                    list.map((item) => (
                      <AdminAssignmentItem key={item.id} item={item} />
                    ))
                  ) : (
                    list.map((item) => (
                      <ConsumptionAssignmentItem
                        key={item.course_id}
                        item={item}
                      />
                    ))
                  )}
                </div>
                {hasNextPage && (
                  <InView
                    as="div"
                    className="flex"
                    onChange={(inView) =>
                      inView && !isFetchingNextPage && fetchNextPage()
                    }
                    key={data.pages.length}
                  >
                    {({ inView, ref }) => (
                      <div
                        ref={ref}
                        className={classNames(
                          "flex flex-col gap-3 transition",
                          !inView && "opacity-0"
                        )}
                      >
                        <Skeleton />
                      </div>
                    )}
                  </InView>
                )}
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export const AdminAssignmentItem = ({ item }) => {
  const { id, date_assigned, course_id, status, course_name, assigner_name } =
    item;
  const { t } = useTranslation();
  const { id: groupId } = useParams<{ id: string }>();
  const format = useDateFormat();

  return (
    <div className="px-6 py-5 rounded-lg shadow-2xl transition relative group user-select-none bg-white text-gray-600">
      <Link
        to={{
          pathname: courseRoutes.activity(course_id, id),
          state: { group: groupId },
        }}
        className="absolute rounded-lg left-0 top-0 w-full h-full bg-primary bg-opacity-0 md:hover:bg-opacity-5 transition cursor-pointer"
      />
      <div className="flex items-start pointer-events-none flex-col-reverse sm:flex-row sm:items-start gap-2">
        <div
          className={classNames(
            "flex flex-col flex-grow transition-[padding] relative min-w-0"
          )}
        >
          <div className="flex gap-1 flex-wrap items-start mb-1">
            <AssignmentBadges item={item} />
          </div>
          <h4 className="text-md sm:text-lg font-bold flex-grow whitespace-nowrap text-ellipsis overflow-hidden text-inherit">
            {course_name}
          </h4>
          <div className="inline-flex items-center text-sm text-gray-500 mr-auto">
            {t("assignments.assignedBy")}
            &nbsp;
            <b>{assigner_name}</b>
          </div>
          <span className="flex items-center text-sm mt-2">
            <TbCalendar className="mr-1 text-lg" />
            {format(date_assigned, "MMM d, yyyy")}
          </span>
        </div>

        <div className="flex gap-1 ml-auto">
          <AssignmentButtons
            assignmentId={id}
            courseId={course_id}
            closed={status === "closed"}
          />
        </div>
      </div>
    </div>
  );
};
