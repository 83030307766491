import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import confetti from "../../../../assets/animations/confetti.json";
import place1 from "../../../../assets/images/place_1.svg";
import place2 from "../../../../assets/images/place_2.svg";
import place3 from "../../../../assets/images/place_3.svg";
import { useSessionContext } from "../../../hooks/useSessionContext";
import CountUp from "react-countup";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import { selectUserDetails } from "../../../store/user";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AiOutlineClockCircle } from "react-icons/ai";
import { Textfit } from "@outofaxis/react-textfit";

const SessionSummary = () => {
  const { t } = useTranslation();
  const { session } = useSessionContext();
  const detail = useSelector(selectUserDetails);
  const isDuration = session?.get?.status?.is_duration || false;
  const gid: string = localStorage.getItem("gid") || "";

  useEffect(() => {
    localStorage.removeItem("es");
  });

  const user = detail.id || `guest_${gid.split("_")[0]}`;

  // const participants = session.session.status?.participants;

  const leaderboard = session.get.status?.leaderboard_results;

  //   .filter(
  //   (e) => {
  //     const user = participants.filter((p) => p.id === e.id)[0];
  //     if (user && user.status !== "banned") return user;
  //   }
  // );

  const podiumArray = leaderboard.slice(0, 3);

  const firstPlace = session.get.status?.is_duration
    ? leaderboard.filter(
        (item) =>
          item.score === leaderboard[0].score &&
          item?.duration === leaderboard[0]?.duration
      )
    : leaderboard.filter((item) => item.score === leaderboard[0].score);

  const secondPlace = session.get.status?.is_duration
    ? leaderboard.filter(
        (item) =>
          item.score === leaderboard[firstPlace.length]?.score &&
          item?.duration === leaderboard[firstPlace.length]?.duration
      )
    : leaderboard.filter(
        (item) => item.score === leaderboard[firstPlace.length]?.score
      );

  const thirdPlace = session.get.status?.is_duration
    ? leaderboard.filter(
        (item) =>
          item.score ===
            leaderboard[firstPlace.length + secondPlace.length]?.score &&
          item.duration ===
            leaderboard[firstPlace.length + secondPlace.length]?.duration
      )
    : leaderboard.filter(
        (item) =>
          item.score ===
          leaderboard[firstPlace.length + secondPlace.length]?.score
      );

  const personalScore = leaderboard.filter((i) => i.id === user);

  useEffect(() => {
    return () => {
      localStorage.removeItem("gid");
    };
  }, []);

  const ref = useRef<any>(null);

  const podium = {
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i - 2,
        delayChildren: i - 2 + 0.5,
        staggerChildren: 1,
        staggerDirection: -1,
      },
    }),
    hidden: { opacity: 0, y: 200 },
  };

  const popupEffect = {
    visible: {
      opacity: 1,
      scale: [1, 2, 1],
    },
    hidden: { opacity: 0, scale: 0.1 },
  };

  const width =
    (ref?.current && ref.current.getBoundingClientRect().width) || 0;

  return (
    <ScOrangeWrapper ref={ref} className="bg-primary text-white">
      <Lottie
        style={{
          margin: "-24px",
          position: "absolute",
          top: "0",
          width: "100%",
        }}
        animationData={confetti}
        loop={true}
      />
      {width < 550 && (
        <Lottie
          animationData={confetti}
          loop={true}
          style={{
            margin: "-24px",
            position: "absolute",
            top: "400px",
            width: "100%",
          }}
        />
      )}
      {personalScore.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ScName variants={popupEffect}>{t("liveSession.score")}</ScName>
          <ScScore
            style={{ margin: "0 10px", fontSize: "16px", width: "55px" }}
            variants={popupEffect}
          >
            <CountUp
              end={parseInt((personalScore[0].score * 100).toFixed(0))}
              delay={1}
              duration={2}
            />
            %
          </ScScore>
        </div>
      )}
      <ScPodium>
        {podiumArray.map((item, index) => {
          const img = index === 0 ? place1 : index === 1 ? place2 : place3;
          const array =
            index === 0 ? firstPlace : index === 1 ? secondPlace : thirdPlace;
          return (
            <ScPlaceWrapper
              custom={index + 2}
              initial="hidden"
              animate="visible"
              variants={podium}
              style={{ order: `${index === 0 ? 2 : index === 1 ? 1 : 3}` }}
            >
              {array.map((i) => {
                const duration = i?.duration || 0;
                const minutes = Math.floor(duration / 60);
                const seconds = duration - minutes * 60;
                return (
                  <>
                    <ScName style={{ maxWidth: "100%" }} variants={popupEffect}>
                      <Textfit ref={undefined}>{i.name}</Textfit>
                    </ScName>

                    <ScScore variants={popupEffect}>
                      <CountUp
                        end={parseInt((i.score * 100).toFixed(0))}
                        delay={1}
                        duration={2}
                      />
                      %
                    </ScScore>
                    {duration !== 0 && isDuration && (
                      <span
                        style={{
                          marginBottom: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <AiOutlineClockCircle
                          style={{ marginRight: "5px", fontSize: "1em" }}
                        />
                        {minutes >= 10 ? minutes : `0${minutes}`}:
                        {seconds >= 10
                          ? seconds.toFixed(2)
                          : `0${seconds.toFixed(2)}`}
                      </span>
                    )}
                  </>
                );
              })}
              {array.length > 0 && <ScPlace src={img} />}
            </ScPlaceWrapper>
          );
        })}
      </ScPodium>
    </ScOrangeWrapper>
  );
};

export default SessionSummary;

export const ScOrangeWrapper = styled.div`
  position: relative;
  padding: 24px 32px;
  width: 100%;
  height: calc(100vh - 200px);
  border-radius: 8px;
  margin: 8px 0;
  overflow: hidden;
  max-height: 600px;
`;

const ScPodium = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  margin: 0 auto;
  font-weight: bold;
`;

const ScPlaceWrapper = styled(motion.div)`
  max-width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ScName = styled(motion.div)`
  font-size: 20px;
  text-align: center;
`;

const ScScore = styled(motion.div)`
  justify-content: center;
  align-items: center;
  min-width: 40px;
  width: 40px;
  min-height: 24px;
  background: #fff;
  color: rgb(var(--brand-color));
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 4px;
  margin: 8px 0;
`;

const ScPlace = styled.img`
  max-width: 100%;
`;
