import React, { useState } from "react";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbSlideshow } from "react-icons/tb";
import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  ConsumptionType,
  Image,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { useDropMedia } from "app/components/Sources/MediaPicker/context/dropMediaContext";
import { CanvasPreview } from "app/components/Exercises/CourseEdit/items/quiz/PageImageLabelingItem";
import { useTranslation } from "react-i18next";

export const ImageGenerate = ({ close }) => {
  const [item] = usePageItemContext<Image>();
  const [generateLabeling, setGenerateLabeling] = useState(false);
  const [, setDropMedia] = useDropMedia();
  const { t } = useTranslation();

  return (
    <>
      <NewButton
        variant="transparent"
        onClick={() => {
          setGenerateLabeling(true);
          close();
        }}
      >
        <TbSlideshow /> {t("v4.item.imageLabeling.text")}
      </NewButton>

      {generateLabeling && (
        <CanvasPreview
          image={item.data}
          close={() => setGenerateLabeling(false)}
          onCreate={(items) => {
            setDropMedia({
              type: PageItemType.ImageLabeling,
              items,
              data: item.data,
              size: 1,
              instructions: [],
              showOptions: true,
              consumptionType: ConsumptionType.multipleChoice,
            });
          }}
        />
      )}
    </>
  );
};
