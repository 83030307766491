import { useParams } from "react-router";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getUser, putUser } from "../../../api/organizationsAPI";
import Navigation from "./Navigation";
import Loader from "../../components/Loader";
import styled from "styled-components";
import React, { useState } from "react";
import { postSetPassword, postUserImpersonate } from "../../../api/userAPI";
import { toast } from "react-hot-toast";
import AdminUserDetaiilsOrg from "./AdminUserDetaiilsOrg";
import { Button } from "../../components/_styled/Button";

const AdminUserDetails = () => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id?: string }>();
  const [newPassword, setNewPassword] = useState("");

  const { data, isSuccess, isLoading, isFetching } = useQuery(
    ["user", id],
    () => getUser({ id: id as string }),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  const changePassword = useMutation(
    (email) => {
      return postSetPassword({ user: email, password: newPassword });
    },
    {
      onSuccess: () => {
        setNewPassword("");
        toast.success("Success");
      },
    }
  );

  const changeAdmin = useMutation(
    (admin: boolean) =>
      putUser({ id: data?.id, dataToSave: { is_superuser: admin } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", id]);
      },
    }
  );

  const changeActive = useMutation(
    (active: boolean) =>
      putUser({ id: data?.id, dataToSave: { is_active: active } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", id]);
      },
    }
  );

  const changeVerified = useMutation(
    (is_verified: boolean) =>
      putUser({ id: data?.id, dataToSave: { is_verified } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", id]);
      },
    }
  );

  const changeGeneration = useMutation(
    (is_no_ai: boolean) => putUser({ id: data?.id, dataToSave: { is_no_ai } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", id]);
      },
    }
  );

  const changeSelectedOrg = useMutation(
    (org_id: string) => {
      return putUser({
        id: data?.id,
        dataToSave: { organization_id: org_id },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", id]);
      },
    }
  );

  const impersonateUser = useMutation(
    (user_id) => postUserImpersonate(user_id),
    { onSuccess: () => window.location.reload() }
  );

  return (
    <section>
      <Navigation id={id} name={data?.username || ""} />
      {isLoading ? (
        <Loader />
      ) : isSuccess ? (
        <div style={{ margin: "30px 0" }}>
          <ScRow>
            <div>
              <strong>Profile</strong>
              <ScRow style={{ flexDirection: "row" }}>
                <ScRowElement>
                  Name
                  <input
                    className="outline-0"
                    readOnly
                    value={data.first_name + " " + data.last_name}
                  />
                </ScRowElement>
                <ScRowElement>
                  Email
                  <input readOnly className="outline-0" value={data.email} />
                </ScRowElement>
                <ScRowElement>
                  Menu{" "}
                  <input readOnly className="outline-0" value={data.language} />
                </ScRowElement>
                <ScRowElementMin style={{ accentColor: "red" }}>
                  Admin{" "}
                  {changeAdmin.isLoading || isFetching ? (
                    <Loader />
                  ) : (
                    <input
                      onChange={() => changeAdmin.mutate(!data.is_superuser)}
                      type="checkbox"
                      checked={data.is_superuser}
                    />
                  )}
                </ScRowElementMin>
                <ScRowElementMin>
                  Active{" "}
                  {changeActive.isLoading || isFetching ? (
                    <Loader />
                  ) : (
                    <input
                      onChange={() => changeActive.mutate(!data.is_active)}
                      type="checkbox"
                      checked={data.is_active}
                    />
                  )}
                </ScRowElementMin>
                <ScRowElement>
                  New password{" "}
                  <div style={{ display: "flex" }}>
                    <input
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    {!changePassword.isLoading ? (
                      <button
                        disabled={newPassword.length < 1}
                        onClick={() => changePassword.mutate(data.email)}
                      >
                        Change password
                      </button>
                    ) : (
                      <Loader />
                    )}
                  </div>
                </ScRowElement>
              </ScRow>
            </div>
          </ScRow>

          <div className="flex items-center">
            <ScRowElementMin style={{ accentColor: "gray" }}>
              Verified author{" "}
              {changeVerified.isLoading || isFetching ? (
                <Loader />
              ) : (
                <input
                  onChange={() => changeVerified.mutate(!data?.is_verified)}
                  type="checkbox"
                  checked={!!data?.is_verified}
                />
              )}
            </ScRowElementMin>

            <ScRowElementMin style={{ accentColor: "orange" }}>
              Generation enabled{" "}
              {changeGeneration.isLoading || isFetching ? (
                <Loader />
              ) : (
                <input
                  onChange={() => changeGeneration.mutate(!data?.is_no_ai)}
                  type="checkbox"
                  checked={!data?.is_no_ai}
                />
              )}
            </ScRowElementMin>
          </div>

          <ScRow>
            <Button small clickAction={() => impersonateUser.mutate(data.id)}>
              Impersonate
            </Button>
          </ScRow>
          {changeSelectedOrg.isLoading || isLoading || isFetching ? (
            <Loader />
          ) : (
            <ScRow style={{ flexDirection: "row" }}>
              <ScRowElement>
                <strong>Member of organization</strong>{" "}
                <span style={{ fontSize: "12px" }}>
                  {data.organization?.name || "null"}
                </span>
              </ScRowElement>
            </ScRow>
          )}

          <ScRow style={{ marginTop: "50px" }}>
            {data.organizations.length > 0 && (
              <>
                <div style={{ display: "flex" }}>
                  <strong style={{ width: "50%" }}>Organization</strong>
                  <strong style={{ width: "50%" }}>Classes</strong>
                </div>
                {data.organizations.map((item) => {
                  return (
                    <AdminUserDetaiilsOrg data={item} member_id={data.id} />
                  );
                })}
              </>
            )}
          </ScRow>
        </div>
      ) : (
        <div>Something went wrong</div>
      )}
    </section>
  );
};
export default AdminUserDetails;

export const ScRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0;
  font-size: 16px;
`;

export const ScRowElement = styled.div`
  flex: 1;
  margin: 10px 10px 10px 0;
  display: flex;
  flex-direction: column;
  input {
    &:first-child {
      margin-left: 0;
    }
    margin: 10px;
    height: 30px;
    font-size: 16px;
  }
`;

export const ScRowElementMin = styled(ScRowElement)`
  max-width: 70px;
  align-items: center;
`;
