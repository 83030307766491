import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  courseRoutes,
  guestCourseRoutes,
  libraryRoutes,
  miscRoutes,
  sessionRoutes,
} from "enums/routes";
import {
  TbAlertTriangle,
  TbCalendar,
  TbChecks,
  TbClipboardCheck,
  TbFile,
  TbPlayerPlay,
  TbPlus,
  TbPresentationAnalytics,
  TbRefresh,
  TbShare,
  TbSparkles,
  TbWand,
  TbX,
} from "react-icons/tb";
import { Link, useLocation } from "react-router-dom";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { InView } from "react-intersection-observer";
import { getTrainings } from "api/course/courseAPI";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  BigModal,
  BigModalBody,
  BigModalClose,
  BigModalHeader,
} from "app/components/BigModal";
import { AssignCourseModal } from "app/pages/courses/components/AssignCourseModal";
import { useDateFormat } from "app/hooks/useDateFormat";
import { SourceContextMenu } from "app/pages/courses/SourceContextMenu";
import { FileType } from "helpers/mimeType";
import {
  useSourceUploadContext,
  useSourceUploadStore,
} from "api/ws/SourceUploadContext";
import { NewFolderPicker } from "app/pages/courses/NewFolderPicker";
import { CgSpinner } from "react-icons/cg";
import { toast } from "react-hot-toast";
import { translateError } from "app/components/Exercises/Edit/missingTranslation";
import { GenerateTraining } from "app/pages/courses/components/GenerateTraining";
import { AssignmentBadges } from "app/pages/courses/CourseActivity";
import { useRole } from "app/hooks/useRole";
import { roundTo } from "helpers/roundTo";
import { InputCheckbox } from "app/components/Buttons/InputCheckbox";
import { getItemFromPin, postImportItem } from "api/sourcesAPI";
import { pinTypes } from "enums/pinTypes";
import useSessionStorageState from "use-session-storage-state";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetails, userDetails } from "app/store/user";
import { useUserProfile } from "api/userAPI";
import { useTrialMutation } from "app/pages/payment/Subscriptions";

const Skeleton = () => (
  <>
    {[...new Array(15)].map((_, i) => (
      <div
        key={i}
        className="rounded-lg h-32 bg-gray-200 animate-pulse"
        style={{ animationDelay: i * 0.2 + "s" }}
      />
    ))}
  </>
);

export const ConsumptionAssignmentItem = ({ item, tagged = false }) => {
  const { guestPin } = useParams<{ guestPin?: string }>();
  const {
    last_assigned,
    course_id,
    course_name,
    pages_done,
    pages_total,
    next_page_id,
    status,
  } = item;
  const isClosed = status === "closed";
  const { t } = useTranslation();
  const location = useLocation<{ selected?: string[] }>();
  const format = useDateFormat();
  const percentage = !pages_total
    ? 0
    : roundTo((pages_done / pages_total) * 100, 1);

  const highlighted = location.state?.selected?.includes(course_id);

  return (
    <div
      key={course_id}
      className={classNames(
        "px-6 py-5 rounded-lg shadow-2xl transition relative group user-select-none bg-white text-gray-600",
        highlighted ? "shadow-primary/30 border-2 border-primary/30" : ""
      )}
    >
      <Link
        to={
          !guestPin
            ? courseRoutes.test(course_id)
            : guestCourseRoutes.test(guestPin, course_id)
        }
        className="absolute rounded-lg left-0 top-0 w-full h-full bg-primary bg-opacity-0 md:hover:bg-opacity-5 transition cursor-pointer"
      />
      <div className="flex items-start pointer-events-none">
        <div
          className={classNames(
            "flex flex-col flex-grow transition-[padding] relative min-w-0"
          )}
        >
          <div className="flex gap-1 flex-wrap items-start [&>*]:mb-1">
            {tagged && (
              <div className="bg-gray-200 text-gray-600 font-bold text-sm gap-1 flex items-center py-0.5 px-1.5 rounded-lg">
                <TbShare className="text-base" />
                {t("v4.training.received")}
              </div>
            )}
            <AssignmentBadges item={item} />
          </div>
          <h4 className="text-md sm:text-lg font-bold flex-grow whitespace-nowrap text-ellipsis overflow-hidden text-inherit">
            {course_name}
          </h4>
          <span className="flex items-center text-sm">
            <TbCalendar className="mr-1 text-lg" />
            {format(last_assigned, "MMM d, yyyy")}
          </span>
          {pages_total > 0 && pages_done === pages_total && (
            <span className="flex items-center text-sm text-primary font-bold mt-1">
              <TbChecks className="mr-1 text-lg" />
              {t("v4.assignment.completed")}
            </span>
          )}
        </div>
        {!isClosed &&
          pages_total > 0 &&
          pages_done < pages_total &&
          next_page_id && (
            <>
              <NewButton
                component={Link}
                to={
                  !guestPin
                    ? courseRoutes.pageTest(course_id, next_page_id)
                    : guestCourseRoutes.pageTest(
                        guestPin,
                        course_id,
                        next_page_id
                      )
                }
                color="bg-primary text-primary pointer-events-auto z-[1]"
              >
                <TbPlayerPlay />
                {!pages_done ? t("v4.generic.start") : t("v4.generic.continue")}
              </NewButton>
            </>
          )}
      </div>
      {pages_total > 0 && pages_done < pages_total && (
        <div className="flex w-full items-center gap-4 mt-4 pointer-events-none">
          <span className="font-bold text-sm">
            {pages_done} / {pages_total}
          </span>
          <div className="h-4 rounded bg-gray-100 grow relative overflow-hidden">
            <div
              className="absolute-cover bg-primary bg-opacity-50 origin-left"
              style={{
                transform: `scaleX(${percentage}%)`,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export const CoursesListView = () => {
  const dispatch = useDispatch();
  const [isGenerate, setIsGenerate] = useState(false);
  const role = useRole();
  const { t } = useTranslation();
  const { startTask, cancelTask } = useSourceUploadContext();
  const tasks = useSourceUploadStore((state) =>
    Object.entries(state.task).filter(
      ([, task]) => !task.data && !task?.input?.training_id
    )
  );
  const userInfo = useSelector(selectUserDetails);
  const profile = useUserProfile();
  const trialMutation = useTrialMutation();
  const [isWelcome, setIsWelcome] = useState(
    (role.student && !profile.welcome_free) ||
      (role.teacher && !profile.welcome_pro)
  );

  const name = userInfo?.first_name;

  const [received, setReceived] = useSessionStorageState<boolean | undefined>(
    "trainings_filter",
    {
      defaultValue: undefined,
    }
  );

  const toggleOnly = (value: boolean) => () => {
    if (received == null) {
      setReceived(!value);
      return;
    }

    if (value) {
      if (received) return setReceived(false);
      return setReceived(undefined);
    }

    if (!received) return setReceived(true);
    return setReceived(undefined);
  };

  const { data, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ["courses", received],
      ({ pageParam = 0 }) => getTrainings({ page: pageParam, received }),
      {
        refetchOnWindowFocus: false,
        // staleTime: 5 * 60 * 1000,
        getNextPageParam: (lastPage, pages) =>
          lastPage.courses_total > pages.length * 15 ? pages.length : undefined,
      }
    );

  const list = useMemo(
    () =>
      (data?.pages || []).reduce(
        (list, group) => [...list, ...group.courses],
        []
      ),
    [data?.pages]
  );

  const dismissWelcome = () => {
    profile.update.mutate(
      role.teacher ? { welcome_pro: true } : { welcome_free: true },
      {
        onSuccess: () => {
          dispatch(userDetails(""));
        },
      }
    );
    setIsWelcome(false);
  };

  return (
    <div className="py-4 px-3 sm:px-8">
      <BigModal
        fit
        open={
          !role.isB2B && role.student && (isWelcome || trialMutation.isLoading)
        }
      >
        <BigModalBody className="text-gray-600 p-8 gap-4 max-w-lg" maxWidth>
          <div className="text-2xl font-bold">
            {"🎉 "}
            {t("v4.welcomeMessage.header")}
          </div>
          <p className="text-gray-500">
            <Trans
              i18nKey="v4.welcomeMessage.freeBody"
              values={{
                name: name ? " " + name : "",
              }}
              components={{ b: <b /> }}
            />
          </p>
          <p className="text-gray-500">
            <Trans
              i18nKey="v4.welcomeMessage.freePrompt"
              components={{ b: <b /> }}
            />
          </p>
          <div className="flex flex-col-reverse sm:flex-row justify-end gap-1 font-bold mt-2">
            <NewButton
              size="lg"
              center
              variant="transparent"
              disabled={trialMutation.isLoading}
              onClick={dismissWelcome}
            >
              {t("v4.generic.dismiss")}
            </NewButton>
            {!userInfo.subscription ? (
              <NewButton
                size="lg"
                center
                variant="primary"
                disabled={trialMutation.isLoading}
                onClick={() => {
                  dismissWelcome();
                  trialMutation.mutate();
                }}
              >
                {trialMutation.isLoading ? (
                  <CgSpinner className="animate-spin" />
                ) : (
                  <TbSparkles />
                )}
                {t("v4.welcomeMessage.freeConfirm")}
              </NewButton>
            ) : (
              <NewButton
                component={Link}
                to={miscRoutes.subscriptions}
                size="lg"
                center
                variant="primary"
                onClick={dismissWelcome}
              >
                {t("v4.subscriptions.subscriptions")}
              </NewButton>
            )}
          </div>
        </BigModalBody>
      </BigModal>

      <BigModal fit open={role.teacher && isWelcome}>
        <BigModalBody className="text-gray-600 p-8 gap-4 max-w-lg" maxWidth>
          <div className="text-2xl font-bold">
            {"🎉 "}
            {t("v4.welcomeMessage.header")}
          </div>
          <p className="text-gray-500">
            <Trans
              i18nKey="v4.welcomeMessage.proBody"
              values={{
                name: name ?? "",
                prefix: name ? ", " : "",
              }}
              components={{ b: <b /> }}
            />
          </p>
          <p className="text-gray-500">
            <Trans
              i18nKey="v4.welcomeMessage.proPrompt"
              components={{ b: <b /> }}
            />
          </p>
          <div className="flex flex-col-reverse sm:flex-row justify-end gap-1 font-bold mt-2">
            <NewButton
              size="lg"
              center
              variant="transparent"
              onClick={dismissWelcome}
            >
              {t("v4.generic.dismiss")}
            </NewButton>
            <NewButton
              size="lg"
              center
              variant="primary"
              onClick={() => {
                dismissWelcome();
                setIsGenerate(true);
              }}
            >
              <TbSparkles /> {t("v4.welcomeMessage.proConfirm")}
            </NewButton>
          </div>
        </BigModalBody>
      </BigModal>

      {/*<HomeToolbar />*/}
      <div className="flex flex-col-reverse sm:flex-row items-center gap-2 flex-wrap mb-2">
        {!!list?.length && (
          <div className="flex flex-wrap mt-auto gap-2 select-none">
            <label className="flex items-center gap-1 font-bold cursor-pointer bg-primary bg-opacity-20 text-primary py-0.5 pl-1 pr-2 rounded-lg">
              <InputCheckbox
                value={received == null || !received}
                onChange={toggleOnly(false)}
                color="bg-primary"
              />
              <TbSparkles className="text-lg ml-1" />
              {t("v4.training.created")}
            </label>

            <label className="flex items-center gap-1 font-bold cursor-pointer bg-gray-200 text-gray-600 py-0.5 pl-1 pr-2 rounded-lg">
              <InputCheckbox
                value={received == null || received}
                onChange={toggleOnly(true)}
                color="bg-gray-600"
              />
              <TbShare className="text-lg ml-1" />
              {t("v4.training.received")}
            </label>
          </div>
        )}

        <div className="flex-grow" />
        {role.teacher && (
          <>
            <NewButton
              size="lg"
              className="hidden md:flex"
              component={Link}
              to={courseRoutes.new}
              {...(role.aiEnabled
                ? { color: "text-primary bg-primary" }
                : { variant: "primary" })}
            >
              <TbPlus />{" "}
              {role.aiEnabled ? t("v4.training.empty") : t("v4.training.new")}
            </NewButton>

            {role.aiEnabled && (
              <>
                <NewButton
                  size="lg"
                  variant="primary"
                  onClick={() => setIsGenerate(true)}
                >
                  <TbWand /> {t("v4.training.generate.text")}
                </NewButton>

                {isGenerate && (
                  <GenerateTraining close={() => setIsGenerate(false)} />
                )}
              </>
            )}
          </>
        )}
      </div>

      {!!tasks.length && role.teacher && (
        <div className="flex flex-col space-y-3 mb-3">
          {tasks.map(([id, item]) => (
            <div
              key={id}
              className={classNames(
                "flex flex-col px-6 py-5 rounded-lg transition relative group user-select-none bg-white text-gray-400",
                item.error ? "order-1" : "animate-pulse "
              )}
            >
              {item.error && (
                <div className="bg-red-100 text-red-400 font-bold text-xs gap-1 flex items-center px-1.5 py-0.5 rounded-br-lg rounded-tl-lg absolute top-0 left-0">
                  <TbAlertTriangle className="text-base" />
                  {t("v4.generic.failed")}
                </div>
              )}
              <div className="flex flex-col sm:flex-row sm:items-center gap-4">
                <div className="flex flex-col gap-1">
                  <div className="sm:text-lg font-bold">
                    {t("v4.training.generate.generating")}
                  </div>
                  {!!item.input?.focus && (
                    <div className="pl-3 text-sm border-l-4 border-gray-100 italic sm:hidden">
                      {item.input.focus}
                    </div>
                  )}
                </div>
                <div
                  className={classNames(
                    "h-1 grow rounded-full relative overflow-hidden transition",
                    !item.error ? "bg-gray-100" : "bg-red-200"
                  )}
                >
                  <div
                    className={classNames(
                      "absolute-cover bg-primary bg-opacity-50 origin-left transition",
                      item.error && "opacity-0"
                    )}
                    style={{
                      transform: `scaleX(${item?.progress || 0}%)`,
                    }}
                  />
                </div>

                {item.error ? (
                  <div className="flex items-center gap-1">
                    {(!!item.input?.source_id || !!item.input?.focus) && (
                      <NewButton
                        size="sm"
                        className="ml-auto"
                        onClick={() => {
                          cancelTask(id);
                          startTask("generate_slides", item.input);
                        }}
                      >
                        <TbRefresh /> {t("v4.generic.retry")}
                      </NewButton>
                    )}
                    <NewButton iconOnly onClick={() => cancelTask(id)}>
                      <TbX />
                    </NewButton>
                  </div>
                ) : (
                  <NewButton
                    size="sm"
                    className="ml-auto"
                    onClick={() => cancelTask(id)}
                  >
                    <TbX /> {t("v4.generic.cancel")}
                  </NewButton>
                )}
              </div>
              {(item.input?.source_id || item.input?.focus) && (
                <div
                  className={classNames(
                    "pl-3 text-sm border-l-4 border-gray-100 hidden sm:flex items-center gap-1.5",
                    item.input?.focus != null && "italic"
                  )}
                >
                  {item.input?.source_id ? (
                    <>
                      <TbFile className="text-base" />{" "}
                      {t("v4.generate.fromSource")}
                    </>
                  ) : (
                    item.input.focus
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {!isSuccess ? (
        <div className="flex flex-col space-y-3">
          <Skeleton />
        </div>
      ) : (
        <>
          <div className="flex flex-col space-y-3">
            {!list.length ? (
              <div className="text-xl text-center text-gray-500 py-5">
                {t("v4.training.noItems")}
              </div>
            ) : (
              list.map(({ received, ...item }) =>
                received ? (
                  <ConsumptionAssignmentItem
                    key={item.course_id}
                    item={item}
                    tagged
                  />
                ) : (
                  <CourseItem key={item.id} item={item} tagged />
                )
              )
            )}
          </div>
          {hasNextPage && (
            <InView
              as="div"
              className="flex"
              onChange={(inView) =>
                inView && !isFetchingNextPage && fetchNextPage()
              }
              key={data.pages.length}
            >
              {({ inView, ref }) => (
                <div
                  ref={ref}
                  className={classNames(
                    "flex flex-col gap-3 transition",
                    !inView && "opacity-0"
                  )}
                >
                  <Skeleton />
                </div>
              )}
            </InView>
          )}
        </>
      )}

      <SaveSharedItem />
    </div>
  );
};

export const CourseItem = ({
  item,
  tagged,
}: {
  item: any;
  tagged?: boolean;
}) => {
  const { t } = useTranslation();
  const role = useRole();
  const history = useHistory();
  const { name, id, recent_date } = item;
  const [assign, setAssign] = useState(false);
  const format = useDateFormat();

  return (
    <div className="px-6 py-5 rounded-lg shadow-2xl transition relative group user-select-none bg-white text-gray-600">
      <a
        onContextMenu={(e) => e.preventDefault()}
        onClick={() => {
          history.push(courseRoutes.details(id));
        }}
        className="absolute rounded-lg left-0 top-0 w-full h-full bg-primary bg-opacity-0 md:hover:bg-opacity-5 transition cursor-pointer"
      />
      <div className="flex flex-col gap-2 pointer-events-none">
        <div className="flex items-start">
          <div className="flex flex-col flex-grow relative min-w-0">
            <div className="flex gap-1 flex-wrap items-start [&>*]:mb-1">
              {tagged && (
                <div className="bg-primary bg-opacity-20 text-primary font-bold text-sm gap-1 flex items-center py-0.5 px-1.5 rounded-lg">
                  <TbSparkles className="text-base" />
                  {t("v4.training.created")}
                </div>
              )}
            </div>
            <h4 className="sm:text-lg font-bold flex-grow whitespace-nowrap text-ellipsis overflow-hidden text-inherit">
              {name}
            </h4>
            <span className="flex items-center text-sm sm:text-md text-inherit opacity-80">
              <TbCalendar className="mr-1 text-lg" />
              {format(recent_date, "MMM d, yyyy")}
            </span>
          </div>
          <SourceContextMenu
            buttonClassName="pointer-events-auto z-[1]"
            item={{ ...item, type: FileType.Course }}
            options={[["edit", "preview", "send"], ["delete"]]}
          />
        </div>
        {role.teacher && (
          <>
            <div className="flex gap-1 ml-auto pointer-events-auto relative z-[1]">
              <NewButton
                color="text-primary bg-primary"
                onClick={() => setAssign(true)}
              >
                <TbClipboardCheck />
                {t("common.rowSubMenu.buttons.assign.label")}
              </NewButton>
              <NewButton
                component={Link}
                to={sessionRoutes.startSession(item.id)}
                color="text-primary bg-primary"
                className="hidden md:flex"
              >
                <TbPresentationAnalytics />
                {t("lessons.live")}
              </NewButton>
            </div>
            <AssignCourseModal
              open={assign}
              close={() => setAssign(false)}
              courses={[{ id, name }]}
            />
          </>
        )}
      </div>
    </div>
  );
};

export const SaveSharedItem = () => {
  const role = useRole();
  const { t } = useTranslation();
  const history = useHistory();
  const [savingCourse, setSavingCourse] = useState(false);

  const params = useParams<{ pin?: string }>();

  useEffect(() => {
    if (!role.student) {
      setSavingCourse(!!params?.pin);
      return;
    }

    if (params?.pin) {
      toast.error(t("v4.training.importError"));
      history.push(courseRoutes.list);
    }
  }, [params]);

  if (!params?.pin || !savingCourse) return null;

  return (
    <SaveSharedItemInner
      pin={params.pin}
      close={() => setSavingCourse(false)}
    />
  );
};

const SaveSharedItemInner = ({
  pin,
  close,
}: {
  pin: string;
  close: () => void;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const isTraining = pin[0] === pinTypes.Courses;

  const sharedDetails = useQuery(
    ["item", "pin", pin],
    () => getItemFromPin(pin),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: () => {
        toast.error(
          translateError(
            isTraining
              ? t("v4.training.retrieveError")
              : t("v4.library.receiveError"),
            t
          )
        );
        close();
      },
    }
  );

  const saveMutation = useMutation(
    (folder: string) => postImportItem(pin, folder),
    {
      onSuccess: (_, folder) => {
        toast.success(
          isTraining
            ? t("v4.training.saveSuccess")
            : t("v4.library.save.success")
        );
        queryClient.invalidateQueries(["courses"]);
        queryClient.invalidateQueries(
          folder ? ["library", folder] : ["library"]
        );
        if (isTraining) history.push(courseRoutes.list);
        else history.push(libraryRoutes.library);
      },
      onError: () => {
        toast.error(
          translateError(
            isTraining
              ? t("v4.training.saveError")
              : t("v4.library.save.error"),
            t
          )
        );
      },
      onSettled: () => {
        close();
      },
    }
  );

  return (
    <BigModal fit>
      <BigModalHeader className="pl-4">
        {isTraining ? t("v4.training.saveShared") : t("v4.library.save.info")}
        <BigModalClose close={close} />
      </BigModalHeader>
      <BigModalBody className="text-gray-600 max-w-sm" maxWidth>
        {!sharedDetails.isSuccess ? (
          <CgSpinner className="m-auto text-3xl animate-spin" />
        ) : (
          <>
            <div className="text-sm font-bold mb-1">
              {isTraining ? t("v4.training.text") : t("v4.library.source")}
            </div>
            <div className="rounded-lg bg-gray-100 px-2 py-1 font-bold mb-4 pr-6">
              {sharedDetails.data.item.name}
            </div>

            <div className="text-sm font-bold mb-1">
              {t("v4.library.folder")}
            </div>
            <div className="rounded-xl border border-gray-100 p-2 flex items-center justify-center relative">
              <NewFolderPicker
                close={close}
                loading={saveMutation.isLoading}
                onSelect={saveMutation.mutate}
                hideExit
                className="!w-full"
              />
            </div>
          </>
        )}
      </BigModalBody>
    </BigModal>
  );
};
