import { Link } from "react-router-dom";
import styled from "styled-components";

export const ScExercisesLibraryNavigation = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ScLink = styled(Link)`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #1e2257;
  text-decoration: none;
`;

export const ScIconArrow = styled.img`
  margin: 0 5px;
`;
