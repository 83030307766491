import React from "react";
import { VideoPlayer } from "app/components/MediaPlayer";
import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import { Video } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";

export const PageVideoItem = () => {
  const [item] = usePageItemContext<Video>();

  return (
    <PageItemWrapper>
      <VideoPlayer src={item.data.src} />
    </PageItemWrapper>
  );
};

export const PageVideoRender = ({ item }: { item: Video }) => (
  <VideoPlayer src={item.data.src} />
);
