import {
  ActiveButton,
  MarkButton,
} from "app/components/Exercises/Edit/questionType/Slide/item/ItemText";
import {
  TbAlignCenter,
  TbAlignLeft,
  TbAlignRight,
  TbBold,
  TbChevronDown,
  TbClearFormatting,
  TbHighlight,
  TbItalic,
  TbLayoutAlignBottom,
  TbLayoutAlignMiddle,
  TbLayoutAlignTop,
  TbLetterA,
  TbLetterCase,
  TbListDetails,
  TbTextSize,
  TbTypography,
  TbUnderline,
} from "react-icons/tb";
import React, { useState } from "react";
import {
  List,
  PageItemType,
  Paragraph,
  SlideList,
  SlideParagraph,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import { Editor, Node, Path, Range, Transforms } from "slate";
import { useFocused, useSlate } from "slate-react";
import { FloatingMenu } from "app/components/Header";
import { NewButton } from "app/components/Buttons/NewButton";
import { PageLeaf } from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { useTranslation } from "react-i18next";

export const TextVerticalAlignChange = () => {
  const [item, set] = usePageItemContext<SlideParagraph | SlideList>();

  const handleChangeAlign = (align: typeof item.verticalAlign) => () =>
    set((item) => {
      item.verticalAlign = align;
    });

  return (
    <>
      <ActiveButton
        isActive={item.verticalAlign === "top"}
        onClick={handleChangeAlign("top")}
        icon={TbLayoutAlignTop}
      />
      <ActiveButton
        isActive={item.verticalAlign === "center"}
        onClick={handleChangeAlign("center")}
        icon={TbLayoutAlignMiddle}
      />
      <ActiveButton
        isActive={item.verticalAlign === "bottom"}
        onClick={handleChangeAlign("bottom")}
        icon={TbLayoutAlignBottom}
      />
    </>
  );
};

const FontStyle = ({ serif = false }) => {
  const { t } = useTranslation();
  if (serif)
    return (
      <>
        <TbTypography />
        {t("v4.item.text.font.styleSerif")}
      </>
    );
  return (
    <>
      <TbLetterA /> {t("v4.item.text.font.styleSans")}
    </>
  );
};

export const FontSizeFormats = ({
  block = false,
  position,
}: {
  block?: boolean;
  position?: Path;
}) => {
  const { t } = useTranslation();
  const editor = useSlate();
  const inFocus = useFocused();
  const [sizeOpen, setSizeOpen] = useState(false);
  const [serifOpen, setSerifOpen] = useState(false);

  const marks: null | [number, number] = (() => {
    if (!position && !inFocus) return null;
    try {
      const marks = position
        ? Node.first(editor, position)[0]
        : Editor.marks(editor);
      // @ts-ignore
      return [marks?.size, marks?.serif];
    } catch (e) {
      // console.error("isMarkActive error", e);
      return null;
    }
  })();

  const [size, serif] = marks || [];

  const handleSet = (callback: () => void) => {
    if (!position && !block && !editor.selection) return;
    console.log(position, block, editor.selection);

    const selection = editor.selection;
    const at = position || [];
    if (block || position)
      Transforms.select(editor, {
        anchor: Editor.start(editor, at),
        focus: Editor.end(editor, at),
      });

    callback();

    if (block || position) {
      if (selection) Transforms.select(editor, selection);
      else
        setTimeout(() => {
          Transforms.deselect(editor);
        }, 1);
    }
  };

  const handleSetSize = (value: number | null) => () => {
    handleSet(() => {
      if (value == null) Editor.removeMark(editor, "size");
      else Editor.addMark(editor, "size", value);
    });
  };

  const handleSetSerif = (value: boolean) => () => {
    handleSet(() => {
      if (!value) Editor.removeMark(editor, "serif");
      else Editor.addMark(editor, "serif", true);
    });
  };

  return (
    <>
      <FloatingMenu
        size="xs"
        placement="bottom-start"
        open={sizeOpen ? undefined : false}
        onSetOpen={(open) => !open && setSizeOpen(false)}
        trigger={(_, setIsOpen) => (
          <NewButton
            onPointerDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onClick={() => {
              setIsOpen(!sizeOpen);
              setSizeOpen(!sizeOpen);
              setSerifOpen(false);
            }}
            // variant="transparent"
          >
            <TbTextSize />
            {size === 2
              ? t("v4.item.text.font.sizeHuge")
              : size === 1
              ? t("v4.item.text.font.sizeBig")
              : t("v4.item.text.font.sizeNormal")}
            <TbChevronDown />
          </NewButton>
        )}
      >
        <ActiveButton isActive={!size} onClick={handleSetSize(null)}>
          <PageLeaf>{t("v4.item.text.font.sizeNormal")}</PageLeaf>
        </ActiveButton>
        <ActiveButton isActive={size === 1} onClick={handleSetSize(1)}>
          <PageLeaf leaf={{ size: 1 }}>
            {t("v4.item.text.font.sizeBig")}
          </PageLeaf>
        </ActiveButton>
        <ActiveButton isActive={size === 2} onClick={handleSetSize(2)}>
          <PageLeaf leaf={{ size: 2 }}>
            {t("v4.item.text.font.sizeHuge")}
          </PageLeaf>
        </ActiveButton>
      </FloatingMenu>

      <FloatingMenu
        size="xs"
        placement="bottom-start"
        open={serifOpen ? undefined : false}
        onSetOpen={(open) => !open && setSerifOpen(false)}
        trigger={(_, setIsOpen) => (
          <NewButton
            onPointerDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onClick={() => {
              setIsOpen(!serifOpen);
              setSerifOpen(!serifOpen);
              setSizeOpen(false);
            }}
            className={serif && "font-serif"}
            // variant="transparent"
          >
            <FontStyle serif={!!serif} />
            <TbChevronDown />
          </NewButton>
        )}
      >
        <ActiveButton isActive={!serif} onClick={handleSetSerif(false)}>
          <FontStyle />
        </ActiveButton>
        <ActiveButton
          className="font-serif"
          isActive={!!serif}
          onClick={handleSetSerif(true)}
        >
          <FontStyle serif />
        </ActiveButton>
      </FloatingMenu>
    </>
  );
};

// export const expandSelection = (editor) => {
//   if (!editor.selection) return;
//   const start = Range.start(editor.selection);
//   const end = Range.end(editor.selection);
//   const [, firstPath] = Editor.first(editor, start.path.slice(0, 1));
//   const [lastNode, lastPath] = Editor.last(editor, end.path.slice(0, 1));
//   // @ts-ignore
//   const offset = lastNode?.text?.length;
//   if (offset == null) return;
//
//   Transforms.select(editor, {
//     anchor: { path: firstPath, offset: 0 },
//     focus: { path: lastPath, offset: offset },
//   });
// };

export const TextFormats = ({ position }: { position?: Path }) => (
  <>
    <MarkButton position={position} format="bold" icon={TbBold} />
    <MarkButton position={position} format="italic" icon={TbItalic} />
    {!position && (
      <MarkButton position={position} format="underline" icon={TbUnderline} />
    )}
    {!position && (
      <MarkButton position={position} format="highlight" icon={TbHighlight} />
    )}
  </>
);

export const TextClearFormat = ({ position }: { position?: Path }) => {
  const editor = useSlate();
  const { selection } = editor;
  const inFocus = useFocused();

  const removeMarks = () => {
    if (position)
      Transforms.select(editor, {
        anchor: Editor.start(editor, position),
        focus: Editor.end(editor, position),
      });

    ["bold", "italic", "underline", "highlight"].forEach((mark) =>
      Editor.removeMark(editor, mark)
    );

    setTimeout(() => {
      Transforms.deselect(editor);
    }, 1);
  };

  return (
    <ActiveButton
      icon={TbClearFormatting}
      onClick={removeMarks}
      disabled={
        !position && (!inFocus || !selection || Range.isCollapsed(selection))
      }
    />
  );
};

export const TextAlignToolbar = () => {
  const [item, set] = usePageItemContext<Paragraph | SlideParagraph>();

  const handleChangeAlign = (align: typeof item.align) => () =>
    set<Paragraph | SlideParagraph>((item) => {
      item.align = align;
    });

  return (
    <div className="flex gap-1">
      <ActiveButton
        isActive={item.align === "left"}
        onClick={handleChangeAlign("left")}
        icon={TbAlignLeft}
      />
      <ActiveButton
        isActive={item.align === "center"}
        onClick={handleChangeAlign("center")}
        icon={TbAlignCenter}
      />
      <ActiveButton
        isActive={item.align === "right"}
        onClick={handleChangeAlign("right")}
        icon={TbAlignRight}
      />
    </div>
  );
};

type TypeVariant = "Paragraph" | "List";
type ItemVariant = Paragraph | List | SlideParagraph | SlideList;

export const TextVariantToolbar = () => {
  const [item, set] = usePageItemContext<ItemVariant>();
  const isParagraph = item.type === PageItemType.Paragraph;
  // const isHeading = item.type === PageItemType.Heading;
  const isList = item.type === PageItemType.List;

  const handleChange = (type: TypeVariant) => () => {
    const text = item.data;

    if (type === "Paragraph") {
      set<Paragraph>((item) => {
        item.type = PageItemType.Paragraph;
        item.data = text;
        item.align = item.align ?? "left";
        // @ts-ignore
        delete item.variant;
      });
    } else {
      set<List>((item) => {
        item.type = PageItemType.List;
        item.data = text;
        item.variant = "bullet";
        // @ts-ignore
        delete item.align;
      });
    }
  };

  return (
    <div className="flex gap-1 mx-auto">
      <ActiveButton
        onClick={handleChange("Paragraph")}
        icon={TbLetterCase}
        isActive={isParagraph}
      />
      <ActiveButton
        onClick={handleChange("List")}
        icon={TbListDetails}
        isActive={isList}
      />
    </div>
  );
};
