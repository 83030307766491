import { reportError } from "../enums/constant";
import { postUserError } from "../api/userAPI";

export const sendAppError = (data: { [key: string]: any }) => {
  const { appVersion, platform, userAgent } = window.navigator;

  const errorData = {
    appVersion,
    platform,
    userAgent,
    path: location.href,
    ...data,
  };

  console.log(errorData);
  if (reportError == "1") postUserError(errorData);
};
