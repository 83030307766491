export const setSortingHelper = (sortBy, currentSorting) => {
  if (currentSorting.id === sortBy && currentSorting.desc === true) {
    return { id: "", desc: false };
  } else {
    if (currentSorting.id === "") {
      return { id: sortBy, desc: false };
    } else if (currentSorting.id === sortBy) {
      return { id: sortBy, desc: true };
    }
    return { id: sortBy, desc: false };
  }
};
