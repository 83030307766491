import React, {
  KeyboardEventHandler,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Editable,
  ReactEditor,
  Slate,
  useFocused,
  useSlate,
  withReact,
} from "slate-react";
import { withHistory } from "slate-history";
import {
  BaseRange,
  createEditor,
  Descendant,
  Editor,
  Node,
  Path,
  Point,
  Range,
  Transforms,
} from "slate";
import { createPortal } from "react-dom";
import classNames from "classnames";
import {
  TbAlignLeft,
  TbFileSearch,
  TbFileSymlink,
  TbLink,
  TbLinkOff,
} from "react-icons/tb";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  HorizontalLine,
  isEmptyTextItem,
  PageItemWrapper,
  useOpenMenu,
} from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { usePageItemFocus } from "app/components/Exercises/CourseEdit/items/PageItemFocus";
import {
  useGetList,
  usePageItemContext,
  usePageItemIndex,
  useSetList,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  List,
  PageItemType,
  Paragraph,
  ParagraphAutocomplete,
  RichParagraph,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { uuid } from "app/components/Exercises/utils/uuid";
import {
  FontSizeFormats,
  TextAlignToolbar,
  TextClearFormat,
  TextFormats,
  TextVariantToolbar,
} from "app/components/Exercises/CourseEdit/items/TextEditMenu";
import objectHash from "object-hash";
import { TextGenerate } from "app/components/Exercises/CourseEdit/components/generate/TextGenerate";
import {
  ActiveButton,
  toggleMarkHotkey,
} from "app/components/Exercises/Edit/questionType/Slide/item/ItemText";
import { useTranslation } from "react-i18next";
import { TooltipRaw } from "app/components/Tooltip";
import { ListChangeVariant } from "app/components/Exercises/CourseEdit/items/content/PageListItem";
import {
  LinkElementType,
  SlateText,
} from "../../components/ftg/FillTheGapUtils";
import { create } from "zustand";
import { combine } from "zustand/middleware";
import { InputText } from "../../../../Buttons/InputText";
import {
  MediaPicker,
  SelectOnlyType,
} from "../../../../Sources/MediaPicker/MediaPicker";

export const paragraphToText = (data: RichParagraph): string =>
  data
    .map((item) => {
      if ("text" in item) return item.text;
      return item.children.map(({ text }) => text).join("");
    })
    .join("");

export const paragraphsToText = (data: RichParagraph[]): string =>
  data.map(paragraphToText).join("\n");

export const textToParagraphs = (data: string): RichParagraph[] =>
  data.split(/\r?\n/).map((text) => [{ text }]);

export const textToParagraph = (data: string) =>
  data.split(/\r?\n/).map((text) => ({ text }));

export const PageParagraphItem = () => {
  const [item] = usePageItemContext<Paragraph>();
  const index = usePageItemIndex();
  const setList = useSetList();
  const { t } = useTranslation();

  const autocomplete = () => {
    setList((list) => {
      const placeholder: ParagraphAutocomplete = {
        id: item.id,
        type: PageItemType.ParagraphAutocomplete,
        category: "generate",
        data: item,
      };
      list.splice(index, 1, placeholder);
    });
  };

  return (
    <>
      <PageItemWrapper
        magic={
          paragraphsToText(item.data).trim().length < 30
            ? t("v4.generate.prompt.minLength", { number: 30 }) || ""
            : (close) => (
                <>
                  <NewButton variant="transparent" onClick={autocomplete}>
                    <TbAlignLeft /> {t("v4.item.autocomplete.text")}
                  </NewButton>

                  <HorizontalLine />

                  <TextGenerate close={close} />
                </>
              )
        }
      >
        <InnerParagraph />
      </PageItemWrapper>
    </>
  );
};

export const LinkFormats = () => {
  const editor = useSlate();
  const inFocus = useFocused();
  const [mediaPicker, setMediaPicker] = useState<BaseRange | null>(null);
  const setActiveLink = usePageLinkContext(({ set }) => set);
  const createLink =
    (source?: string) => (e?: React.MouseEvent<HTMLButtonElement>) => {
      e?.preventDefault();
      // const isCollapsed = editor.selection && Range.isCollapsed(editor.selection);

      const id = uuid();
      const position = mediaPicker
        ? mediaPicker
        : inFocus
        ? editor.selection
        : null;

      if (!position) return;

      Transforms.wrapNodes(
        editor,
        {
          id,
          type: "link",
          ...(source == null ? { url: "" } : { source }),
          children: [],
        },
        {
          at: position,
          split: true,
        }
      );
      setTimeout(() => {
        Transforms.deselect(editor);
      }, 1);
      setActiveLink(id);
    };

  return (
    <>
      <ActiveButton icon={TbLink} onClick={createLink()} />
      <ActiveButton
        icon={TbFileSymlink}
        onClick={() => setMediaPicker(editor.selection)}
      />
      {mediaPicker && (
        <MediaPicker
          close={() => setMediaPicker(null)}
          selectOnly={SelectOnlyType.source}
          onInsert={(item) => {
            if (!item?.download_url) return;
            createLink(item.download_url)();
            setMediaPicker(null);
          }}
        />
      )}
    </>
  );
};

export const FormatMenu = () => {
  const editor = useSlate();
  const inFocus = useFocused();
  const [position, setPosition] = useState<{ x: number; y: number } | null>(
    null
  );
  const [isDown, setIsDown] = useState(false);
  const element: any = document.getElementById("exercise_scroll");
  useEffect(() => {
    const up = () => {
      // console.log("up");
      setIsDown(false);
    };
    const down = () => {
      // console.log("down");
      setIsDown(true);
    };
    document.addEventListener("pointerup", up);
    document.addEventListener("pointerdown", down);
    document.addEventListener("dragend", up);
    return () => {
      document.removeEventListener("pointerup", up);
      document.removeEventListener("pointerdown", down);
      document.removeEventListener("dragend", up);
    };
  }, []);

  const visible = useMemo(() => {
    // if (linkOpen) return;
    if (isDown) return;
    const { selection } = editor;
    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ""
    )
      return false;

    const domSelection = window.getSelection();
    if (!domSelection || !domSelection.rangeCount) return null;
    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    setPosition({
      y: rect.top + (element?.scrollTop || 0) - elementRect.top,
      x: rect.left + rect.width / 2 - elementRect.left,
    });
    return true;
  }, [editor.selection, element?.scrollTop, inFocus, isDown]);

  if (!element) return null;

  return createPortal(
    <div
      onPointerDown={(e) => e.stopPropagation()}
      style={{ left: position?.x, top: position?.y }}
      className={classNames(
        "flex flex-col items-center absolute z-[1] bg-white rounded-xl gap-1 p-1.5 border border-gray-100 shadow-xl transform -translate-y-[110%] -translate-x-1/2 transition",
        !visible && "opacity-0 pointer-events-none"
      )}
    >
      <div className="flex items-center gap-1">
        <FontSizeFormats />
      </div>
      <div className="flex items-center gap-1">
        <TextFormats />
        <div className="h-6 border-l border-r border-gray-200 mx-1" />
        <LinkFormats />
        <div className="h-6 border-l border-r border-gray-200 mx-1" />
        <TextClearFormat />
      </div>
    </div>,
    element
  );
};

export const PageLeaf = ({
  attributes = {} as any,
  children,
  leaf = {} as any,
  slide = false,
  className: className_ = "",
}) => {
  const className = classNames(
    slide
      ? leaf.size === 2
        ? "text-6xl"
        : leaf.size === 1
        ? "text-4xl"
        : "text-2xl"
      : leaf.size === 2
      ? "text-3xl"
      : leaf.size === 1
      ? "text-xl"
      : "",
    leaf?.serif && "font-serif",
    className_
  );

  if (leaf.bold) {
    children = <strong className={className}>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em className={className}>{children}</em>;
  }

  if (leaf.underline) {
    children = <u className={className}>{children}</u>;
  }

  if (leaf.highlight) {
    children = (
      <mark
        className={classNames(
          "text-inherit bg-primary bg-opacity-30",
          className
        )}
      >
        {children}
      </mark>
    );
  }

  return (
    <span {...attributes} className={className}>
      {children}
    </span>
  );
};

const paragraphsToSlate = (data: RichParagraph[]) =>
  data.map((children) => ({
    type: "paragraph",
    children,
  }));

export const collapsedAtStart = (editor: Editor) =>
  editor.selection &&
  Range.isCollapsed(editor.selection) &&
  Point.compare(Range.start(editor.selection), Editor.start(editor, [])) === 0;

export const collapsedAtEnd = (editor: Editor) =>
  editor.selection &&
  Range.isCollapsed(editor.selection) &&
  Point.compare(Range.end(editor.selection), Editor.end(editor, [])) === 0;

const normalizeLinks = (nodes: (Editor | Descendant)[]) => {
  for (const node of nodes) {
    if (!("type" in node)) continue;
    if (node.type === "link") {
      node.id = uuid();
      continue;
    }
    normalizeLinks(node.children);
  }
};

const withLinks = (editor: Editor) => {
  const { isInline, normalizeNode, insertFragment } = editor;

  editor.isInline = (element) => element.type === "link" || isInline(element);
  editor.insertFragment = (data) => {
    normalizeLinks(data);
    return insertFragment(data);
  };
  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // updating node id of link if another one exists
    if ("type" in node && node.type === "link") {
      const list = Node.elements(editor, {
        pass: ([n]) => "type" in n && n.type === "link" && n.id === node.id,
      });
      let count = 0;
      for (const [item] of list) {
        if ("type" in item && item.type === "link" && item.id === node.id) {
          if (count > 0) {
            Transforms.setNodes(editor, { id: uuid() }, { at: path });
            return;
          }
          count++;
        }
      }
    }

    // merging adjacent links split by nothing
    if (Path.hasPrevious(path) && Path.hasPrevious(Path.previous(path))) {
      const prevPath = Path.previous(path);
      const prev = Node.get(editor, prevPath);
      const prevPrevPath = Path.previous(Path.previous(path));
      const prevPrev = Node.get(editor, prevPrevPath);

      // if the window is [url, empty, url], merge them into a single [url]
      if (
        "url" in node &&
        "text" in prev &&
        !prev.text &&
        "url" in prevPrev &&
        (node?.url
          ? node.url === prevPrev.url
          : node?.source
          ? node.source === prevPrev.source
          : false)
      ) {
        Transforms.removeNodes(editor, { at: path });
        Transforms.removeNodes(editor, { at: prevPath });
        Transforms.removeNodes(editor, { at: prevPrevPath });
        Transforms.insertNodes(
          editor,
          {
            ...prevPrev,
            children: [...prevPrev.children, ...node.children],
          },
          {
            at: prevPrevPath,
          }
        );
        return;
      }
    }

    if ("type" in node && node.type === "link") {
      let index = 0;
      for (const [child, childPath] of Node.children(editor, path)) {
        if (index > 0 && "text" in child && !child.text) {
          Transforms.removeNodes(editor, { at: childPath });
          return;
        }

        if ("type" in child && child.type === "link") {
          Transforms.unwrapNodes(editor, { at: childPath });
          return;
        }

        index++;
      }
    }

    return normalizeNode(entry);
  };

  return editor;
};

export const useTextEditor = () =>
  useState(() => withReact(withHistory(withLinks(createEditor()))))[0];

const InnerParagraph = () => {
  const { t } = useTranslation();
  const [item, set] = usePageItemContext<Paragraph>();
  const index = usePageItemIndex();
  const setList = useSetList();
  const getList = useGetList();
  const openMenu = useOpenMenu();
  const isOnlyItem = getList().length === 1;
  const data = item?.data ?? [];

  const editor = useTextEditor();
  const renderBlock = useCallback((props) => <PageBlock {...props} />, []);
  const renderLeaf = useCallback((props) => <PageLeaf {...props} />, []);

  const text = useMemo(() => {
    return paragraphsToSlate(data) as any;
  }, [objectHash(data)]);

  useEffect(() => {
    editor.children = text;
  }, []);

  const setFocus = usePageItemFocus((distance, reverse) => {
    ReactEditor.focus(editor);
    editor.children = text;
    if (reverse) {
      Transforms.select(editor, {
        anchor: Editor.end(editor, []),
        focus: Editor.end(editor, []),
      });
    } else {
      Transforms.select(editor, {
        anchor: Editor.start(editor, []),
        focus: Editor.start(editor, []),
      });
    }
    Transforms.move(editor, {
      distance: distance,
      reverse: reverse,
    });
  });

  const handleTextChange = (text: Descendant[]) => {
    if (
      !editor?.operations ||
      (editor.operations.length === 1 &&
        editor.operations[0]?.type === "set_selection")
    )
      return;

    set((item) => {
      item.data = (text as any).map(({ children }) => children);
    });
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    toggleMarkHotkey(e, editor);
    // convert to list on space after a "-" or a "1."
    if (e.key === " ") {
      if (e.shiftKey) return;
      if (item.data.length > 1) return;
      if (item.align !== "left") return;
      const textValue = paragraphsToText(item.data);
      if (textValue !== "-" && textValue !== "1.") return;

      const variant = textValue === "1." ? "number" : "bullet";

      e.preventDefault();
      set<List>((item) => {
        item.type = PageItemType.List;
        item.variant = variant;
        item.data = [[{ text: "" }]];
        delete item["align"];
      });
      setFocus(item.id);
      return;
    }

    if (e.key === "Tab") {
      e.preventDefault();
      openMenu?.();
      return;
    }

    const list = getList();

    // on arrow up
    if (e.key === "ArrowUp") {
      if (!index) return;

      // selection has to be collapsed at beginning
      if (collapsedAtStart(editor)) {
        e.preventDefault();
        const previousItem = list[index - 1];
        setFocus(previousItem.id, 0, true);
      }
      return;
    }

    // on arrow down
    if (e.key === "ArrowDown") {
      if (index === list.length - 1) return;

      // selection has to be collapsed at end
      if (collapsedAtEnd(editor)) {
        e.preventDefault();
        const nextItem = list[index + 1];
        setFocus(nextItem.id, 0);
      }
      return;
    }

    // has to be delete
    if (e.key === "Delete") {
      if (index === list.length - 1) return;

      // moving focus to the next item
      const nextItem = list[index + 1];

      // deleting empty item
      if (isEmptyTextItem(item)) {
        e.preventDefault();
        setList((list) => {
          list.splice(index, 1);
        });

        setFocus(nextItem.id, 0);
        return;
      }

      if (nextItem.type !== PageItemType.Paragraph) return;

      // selection has to be collapsed at end
      if (!collapsedAtEnd(editor)) return;

      // if next item is a text item - append and move focus
      const textValue = paragraphsToText(item.data);

      e.preventDefault();

      setList((list) => {
        const item = list[index] as Paragraph;
        item.data.push(...nextItem.data);

        list.splice(index + 1, 1);
      });
      setFocus(item.id, textValue.length);
      return;
    }

    // has to be backspace
    if (e.key === "Backspace") {
      // moving focus to the previous item
      const previousItem = index ? list?.[index - 1] : null;

      if (isEmptyTextItem(item)) {
        e.preventDefault();
        setList((list) => {
          list.splice(index, 1);
        });

        if (previousItem) setFocus(previousItem.id, 0, true);
        return;
      }

      if (!previousItem) return;
      if (previousItem.type !== PageItemType.Paragraph) return;

      // selection has to be collapsed at beginning
      if (!collapsedAtStart(editor)) return;

      // if previous item is a text item - append and move focus
      const textValue = paragraphsToText(item.data);

      e.preventDefault();
      setList((list) => {
        const prevItem = list[index - 1] as Paragraph;
        prevItem.data.push(...item.data);

        list.splice(index, 1);
      });
      setFocus(previousItem.id, textValue.length, true);
    }

    if (e.key === "Enter" && !e.shiftKey) {
      if (!editor.selection || !Range.isCollapsed(editor.selection)) return;
      const cutoff = Range.start(editor.selection).path[0];

      // if block has more than one paragraph, or the singular paragraph is not empty
      if (item.data?.[cutoff]?.length <= 1) return;
      const firstParagraph = item.data[cutoff][0];
      if ("text" in firstParagraph && firstParagraph?.text) return;
      if ("url" in firstParagraph && firstParagraph.children.length > 0) return;

      e.preventDefault();

      const first = item.data.slice(0, cutoff);
      const second = item.data.slice(cutoff + 1);
      if (!first.length) first.push([{ text: "" }]);
      if (!second.length) second.push([{ text: "" }]);

      const oldId = uuid();
      const newId = uuid();
      setList((list) => {
        list.splice(
          index,
          1,
          { ...item, id: oldId, data: first },
          { ...item, id: newId, data: second }
        );
      });
      editor.children = paragraphsToSlate(first) as any;
      setTimeout(() => {
        setFocus(newId, 0, false);
      }, 0);
    }
  };

  return (
    <Slate editor={editor} value={text} onChange={handleTextChange}>
      <FormatMenu />
      <PageBlockFormatMenu>
        <Editable
          style={{ textAlign: item.align }}
          onKeyDown={handleKeyDown}
          className="leading-relaxed cursor-text border-2 border-black border-opacity-0 focus-within:border-opacity-10 transition rounded-lg p-2 -m-2"
          renderLeaf={renderLeaf}
          renderElement={renderBlock}
          placeholder={
            isOnlyItem ? t("v4.generic.typingPlaceholder") : undefined
          }
        />
      </PageBlockFormatMenu>
    </Slate>
  );
};

export const PageBlock = ({ attributes, children, element }) => {
  if (element.type === "link") {
    // console.log({ attributes, children, element });
    return (
      <PageBlockLink {...{ attributes, element }}>{children}</PageBlockLink>
    );
  }

  return <p {...attributes}>{children}</p>;
};

export const usePageLinkContext = create(
  combine({ value: null as null | string }, (set) => ({
    set: (value: null | string) => set({ value }),
  }))
);

export const useOnClickOutsideMultiple = (
  refs: RefObject<any>[],
  callback: false | (() => void)
) => {
  useEffect(() => {
    if (!callback) return;
    const unselect = (e: MouseEvent) => {
      if (!e.target) return;
      for (const ref of refs) if (!ref.current) return;

      const target = e.target as any;
      if (refs.every((ref) => !ref.current.contains(target))) {
        callback();
      }
    };

    document.addEventListener("pointerdown", unselect);
    return () => {
      document.removeEventListener("pointerdown", unselect);
    };
  }, [!!callback]);
};

export const PageBlockLink = ({
  attributes,
  element,
  children,
}: {
  attributes: any;
  element: LinkElementType;
  children: any;
}) => {
  const { t } = useTranslation();
  const linkRef = useRef<HTMLSpanElement | null>(null);
  const panelRef1 = useRef<HTMLDivElement | null>(null);
  const panelRef2 = useRef<HTMLDivElement | null>(null);
  const set = usePageLinkContext((s) => s.set);
  const open = usePageLinkContext((s) => s.value === element.id);

  useOnClickOutsideMultiple(
    [linkRef, panelRef1, panelRef2],
    open && (() => set(null))
  );

  const editor = useSlate();
  const getPosition = useCallback(() => {
    for (const [item, location] of Node.nodes(editor)) {
      if ("type" in item && item.type === "link" && item.id === element.id)
        return location;
    }
  }, [editor, element.id]);

  const selected = !!editor.selection && !Range.isCollapsed(editor.selection);

  const handleChangeUrl = (url: string) => {
    Transforms.setNodes(editor, { url }, { at: getPosition() });
  };

  const position = getPosition();

  const collapsed = !editor.selection || Range.isCollapsed(editor.selection);

  return (
    <>
      <span {...attributes}>
        <span
          className={classNames(
            element.url || element.source ? "text-primary" : "text-red-600",
            "bg-primary underline relative transition rounded",
            open && collapsed
              ? "bg-opacity-20"
              : "hover:bg-opacity-10 bg-opacity-0"
          )}
          ref={linkRef}
          onPointerDown={() => set(element.id)}
        >
          {children}
        </span>
      </span>

      <TooltipRaw
        visible={open && !selected}
        offset={[0, 8]}
        placement="top"
        interactive
        appendTo={document.body}
        reference={linkRef}
        content={
          <div className="flex flex-col items-center gap-0.5">
            <div
              className="flex flex-col items-center bg-white rounded-xl gap-1 p-1.5 border border-gray-100 shadow-xl"
              ref={panelRef1}
            >
              <div className="flex items-center gap-1">
                <FontSizeFormats position={position} />
              </div>
              <div className="flex items-center gap-1">
                <TextFormats position={position} />
                <div className="h-6 border-l border-r border-gray-200 mx-1" />
                <TextClearFormat position={position} />
              </div>
            </div>
            <div
              className="flex items-center bg-white rounded-xl gap-1 p-1.5 border border-gray-100 shadow-xl"
              ref={panelRef2}
            >
              {element.source ? (
                <a href={element.source} target="_blank">
                  <ActiveButton icon={TbFileSearch} />
                </a>
              ) : (
                <InputText
                  placeholder={t("v4.item.text.link")}
                  value={element?.url || ""}
                  onChange={handleChangeUrl}
                  icon={TbLink}
                  className="mr-1"
                />
              )}

              <div className="h-6 border-l border-r border-gray-200 ml-0.5 mx-1" />

              <ActiveButton
                icon={TbLinkOff}
                onClick={() => {
                  Transforms.unwrapNodes(editor, { at: getPosition() });
                }}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export const PageBlockFormatMenu = ({ children, list = false }) => {
  const editor = useSlate();
  const inFocus = useFocused();
  const linkOpen = usePageLinkContext(({ value }) => !!value);

  const modal = (
    <div
      onPointerDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      className={classNames(
        "flex flex-col w-max items-center absolute z-[1] bg-white rounded-xl gap-1 p-1.5 border border-gray-100 shadow-xl shadow-black/5 transform -translate-y-[110%] -translate-x-1/2 transition",
        !inFocus && "opacity-0 pointer-events-none"
      )}
    >
      <div className="flex items-center gap-1">
        <FontSizeFormats block />
      </div>
      <div className="flex items-center gap-1">
        <TextVariantToolbar />
        <div className="h-6 border-l border-r border-gray-200 mx-1" />
        {list ? <ListChangeVariant /> : <TextAlignToolbar />}
      </div>
    </div>
  );

  return (
    <TooltipRaw
      offset={[0, 0]}
      interactive
      visible={
        inFocus &&
        !linkOpen &&
        (!editor.selection || Range.isCollapsed(editor.selection))
      }
      content={modal}
    >
      <div>{children}</div>
    </TooltipRaw>
  );
};

export const PageLeafRender = ({
  item,
  slide = false,
}: {
  item: LinkElementType | SlateText;
  slide?: boolean;
}) => {
  if ("type" in item && item.type === "link") {
    const children = item.children.map(({ text, ...leaf }, i) => (
      <PageLeaf slide={slide} key={i} leaf={leaf} attributes={null}>
        {text}
      </PageLeaf>
    ));

    let url = item?.url || item?.source || "";

    if (url && !url?.startsWith("http://") && !url?.startsWith("https://")) {
      url = "http://" + url;
    }

    return (
      <a
        className={classNames(
          url && "text-primary cursor-pointer",
          "underline"
        )}
        {...(!!url && { href: url, target: "_blank" })}
      >
        {children}
      </a>
    );
  }

  const { text, ...leaf } = item as SlateText;
  return (
    <PageLeaf slide={slide} leaf={leaf} attributes={null}>
      {text}
    </PageLeaf>
  );
};

export const PageParagraphRender = ({ item }: { item: Paragraph }) => {
  // console.log(item.data);
  return (
    <div
      className="leading-relaxed flex flex-col break-words"
      style={{ textAlign: item.align }}
    >
      {item.data.map((paragraph, i) => (
        <div key={i}>
          {paragraph.map((item, i) => (
            <PageLeafRender item={item} key={i} />
          ))}
        </div>
      ))}
    </div>
  );
};
