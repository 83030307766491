import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const SessionSummaryNoLeaderboard = () => {
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.removeItem("es");
  });

  return (
    <div className="flex flex-col py-2 grow">
      <div className="flex items-center justify-center bg-primary text-center grow rounded-xl">
        <h1 className="text-white">{t("liveSession.finish")}</h1>
      </div>
    </div>
  );
};

export default SessionSummaryNoLeaderboard;
