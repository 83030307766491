import React, { useState } from "react";
import SessionLeaderboard from "./SessionLeaderboard";
import SessionSection from "./SessionSection";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import progressGraphIcon from "../../../../assets/images/progressGraph.svg";
import progressIcon from "../../../../assets/images/progress.svg";
import SessionSummary from "./SessionSummary";
import { useSessionContext } from "../../../hooks/useSessionContext";
import SessionSummaryNoLeaderboard from "./SessionSummaryNoLeaderboard";

const SessionTeacherSummary = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const { session } = useSessionContext();
  const status = session.get?.status;
  const isLeaderboard = status.is_leaderboard && !!status?.leaderboard_results;

  const tabs = [
    {
      label: "Podium",
      icon: <img src={progressGraphIcon} />,
      id: 0,
    },
    {
      label: t("common.tabSwitch.leaderboard"),
      icon: <img src={progressIcon} />,
      id: 1,
    },
  ];

  return (
    <>
      {isLeaderboard ? (
        <>
          <div className="px-8 py-6 bg-white shadow-xl w-full rounded-xl mt-2 text-center">
            <ScTitle>{t("liveSession.finish")}</ScTitle>
          </div>
          <TabBar>
            {tabs.map((t, index) => (
              <Tab active={tab === index} onClick={() => setTab(index)}>
                {t.label}
              </Tab>
            ))}
          </TabBar>
          {tab === 0 && <SessionSummary />}
          {tab === 1 && (
            <SessionSection>
              <SessionLeaderboard summary />
            </SessionSection>
          )}
        </>
      ) : (
        <SessionSummaryNoLeaderboard />
      )}
    </>
  );
};

export default SessionTeacherSummary;

const ScTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const TabBar = styled.div`
  display: flex;
  font-weight: bold;
`;

const Tab = styled.div<{ active: boolean }>`
  cursor: pointer;
  opacity: ${({ active }) => !active && "0.6"};
  transition: all 0.1s ease-out;
  margin: 0 1px -6px;
  background: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  max-height: 40px;
  &:hover {
    transform: translateY(-2px);
  }
`;
