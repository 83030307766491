import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  BigModal,
  BigModalBody,
  BigModalFooter,
  BigModalHeader,
} from "app/components/BigModal";
import {
  TbChevronDown,
  TbClipboardCheck,
  TbDownload,
  TbFileExport,
  TbFileText,
  TbLanguage,
  TbPencil,
  TbRectangle,
  TbX,
} from "react-icons/tb";
import { NewButton } from "app/components/Buttons/NewButton";
import { Checkbox } from "app/components/Buttons/InputCheckbox";
import classNames from "classnames";
import { CgSpinner } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { FloatingMenu } from "app/components/Header";
import { ActiveButton } from "app/components/Exercises/Edit/questionType/Slide/item/ItemText";
import { Page } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { getCourse, postExportCourse } from "api/course/courseAPI";
import download from "downloadjs";
import { MIME } from "helpers/mimeType";
import {
  CourseIcon,
  CourseSlideThumbnail,
} from "app/pages/courses/Edit/CoursePanel";
import { IconType } from "react-icons";
import { pagePlaceholder } from "../Edit/CourseEditView";
import { Collapse } from "../../../components/Collapse";
import { mt } from "../../../components/Exercises/Edit/missingTranslation";
import { InputToggle } from "../../../components/Buttons/InputToggle";

const CATEGORIES = (
  t: (key: string) => string
): {
  [key in Page["category"]]?: { name: string; icon: IconType };
} => ({
  slide: {
    name: t("v4.slide.pluralName"),
    icon: TbRectangle,
  },
  content: {
    name: t("v4.page.pluralName"),
    icon: TbFileText,
  },
  quiz: {
    name: t("v4.quiz.pluralName"),
    icon: TbPencil,
  },
  // poll: {
  //   name: mt("Polling"),
  //   icon: TbChartBar,
  // },
});

export const ExportCategoryOption = ({
  value,
  setValue,
  disabled = false,
  categories,
}: {
  value: Page["category"];
  setValue: (value: Page["category"]) => void;
  disabled?: boolean;
  categories: Page["category"][];
}) => {
  const { t } = useTranslation();
  return (
    <FloatingMenu
      size="xs"
      placement="bottom-end"
      trigger={(toggle) => (
        <NewButton onClick={() => !disabled && toggle()} disabled={disabled}>
          {value === "slide" && (
            <>
              <TbRectangle /> {t("v4.slide.pluralName")}
            </>
          )}
          {value === "content" && (
            <>
              <TbFileText /> {t("v4.page.pluralName")}
            </>
          )}
          {value === "quiz" && (
            <>
              <TbPencil /> {t("v4.quiz.pluralName")}
            </>
          )}
          <TbChevronDown />
        </NewButton>
      )}
    >
      {({ setIsOpen }) => (
        <>
          {categories.map((category) => {
            const data = CATEGORIES(t)?.[category];
            if (!data) return null;

            const { name, icon: Icon } = data;
            return (
              <ActiveButton
                size="md"
                isActive={value === category}
                onClick={() => {
                  setValue(category);
                  setIsOpen(false);
                }}
              >
                <Icon /> {name}
              </ActiveButton>
            );
          })}
        </>
      )}
    </FloatingMenu>
  );
};

const LANGUAGES = (t) => [
  {
    name: t("v4.language.english"),
    value: "en",
  },
  {
    name: t("v4.language.german"),
    value: "de",
  },
  {
    name: t("v4.language.french"),
    value: "fr",
  },
];

export const ExportLanguageOption = ({
  value,
  setValue,
  disabled = false,
}: {
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <FloatingMenu
      size="xs"
      placement="bottom-end"
      trigger={(toggle) => (
        <NewButton onClick={() => !disabled && toggle()} disabled={disabled}>
          {LANGUAGES(t).find((language) => language.value === value)?.name}
          <TbChevronDown />
        </NewButton>
      )}
    >
      {({ setIsOpen }) => (
        <>
          {LANGUAGES(t).map((language) => (
            <ActiveButton
              size="md"
              isActive={value === language.value}
              onClick={() => {
                setValue(language.value);
                setIsOpen(false);
              }}
            >
              {language.name}
            </ActiveButton>
          ))}
        </>
      )}
    </FloatingMenu>
  );
};

export const ExportCourseModal = ({
  open,
  close,
  courseId,
}: {
  open: boolean;
  close: () => void;
  courseId: string;
}) => {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState<string[]>([]);
  const [category, setCategory] = useState<Page["category"]>("slide");
  const [answers, setAnswers] = useState(false);
  const [language, setLanguage] = useState(i18n.language || "en");

  const { isSuccess, data } = useQuery({
    queryFn: () => getCourse(courseId),
    queryKey: ["course", courseId],
    refetchOnWindowFocus: false,
    enabled: !!courseId,
  });

  const exportMutation = useMutation(
    (params: { category: Page["category"]; language?: string }) =>
      postExportCourse(courseId, selected, language, answers),
    {
      onSuccess: (data, { category }) => {
        if (category === "slide")
          download(data, `Training_${courseId}.pptx`, MIME.pptx);
        else if (category === "content")
          download(data, `Training_${courseId}.docx`, MIME.docx);
        else if (category === "quiz")
          download(data, `Quiz_${courseId}.docx`, MIME.docx);
      },
    }
  );

  useEffect(() => {
    if (!isSuccess) return;
    const selected = data.pages
      .filter((item) => item.category === category)
      .map(({ id }) => id);

    setSelected(selected);
    setAnswers(false);
  }, [category, isSuccess]);

  const categories = useMemo(() => {
    if (!data?.pages) return [];
    const isValidCategory = (category: Page["category"]) => {
      const items = data.pages.filter((item) => item.category === category);
      return !!items.length;
    };

    const categories = (
      Object.keys(CATEGORIES(t)) as Page["category"][]
    ).filter(isValidCategory);
    setCategory(categories?.[0] ?? "slide");

    return categories;
  }, [data?.pages]);

  const filtered = useMemo(
    () =>
      !data?.pages
        ? []
        : data.pages.filter((item) => item.category === category),
    [data?.pages, category]
  );

  return (
    <BigModal open={open || exportMutation.isLoading} fit>
      <BigModalHeader className="ml-2">
        <TbFileExport className="text-xl" />
        {t("v4.export.downloadTraining")}

        <NewButton
          variant="transparent"
          iconOnly
          onClick={close}
          className="ml-auto"
        >
          <TbX />
        </NewButton>
      </BigModalHeader>
      <BigModalBody
        className="overflow-y-auto !py-0 max-w-xl max-h-[30rem]"
        maxWidth
      >
        <div className="flex-1 flex flex-col gap-2 py-4">
          {!isSuccess ? (
            <>
              <div className="bg-gray-100 rounded-xl h-8" />
              <div className="bg-gray-100 rounded-xl h-8" />
              <div className="bg-gray-100 rounded-xl h-8" />
              <div className="bg-gray-100 rounded-xl h-8" />
            </>
          ) : (
            <>
              <div className="flex flex-col border border-gray-200 rounded-xl pl-2">
                <div className="p-2 min-w-0 flex flex-col sm:flex-row items-center gap-2 justify-between">
                  <div className="font-bold text-gray-500 flex items-center gap-1.5">
                    {/*<TbRotate />*/}
                    {t("v4.export.downloadedContent")}
                  </div>
                  <ExportCategoryOption
                    value={category}
                    setValue={setCategory}
                    disabled={exportMutation.isLoading}
                    categories={categories}
                  />
                </div>
                <Collapse>
                  {category === "quiz" && (
                    <>
                      <div className="px-2 min-w-0 flex flex-col sm:flex-row items-center gap-2 justify-between">
                        <div className="font-bold text-gray-500 flex items-center gap-1.5">
                          <TbLanguage className="text-lg" />
                          {t("v4.export.instructionLanguage")}
                        </div>
                        <ExportLanguageOption
                          value={language}
                          setValue={setLanguage}
                          disabled={exportMutation.isLoading}
                        />
                      </div>
                      <label className="p-2 pr-3 pb-3 min-w-0 flex flex-col sm:flex-row items-center gap-2 justify-between">
                        <div className="font-bold text-gray-500 flex items-center gap-1.5">
                          <TbClipboardCheck className="text-lg" />
                          {mt("Include answers")}
                        </div>
                        <InputToggle
                          onChange={setAnswers}
                          value={answers}
                          disabled={exportMutation.isLoading}
                        />
                      </label>
                    </>
                  )}
                </Collapse>
              </div>

              <div className="border border-gray-200 p-4 rounded-xl min-w-0">
                {!filtered.length ? (
                  <div className="text-center text-gray-500 font-bold">
                    {t("v4.export.empty")}
                  </div>
                ) : (
                  <div
                    className="grid gap-y-1 gap-x-3 bg-white rounded-2xl"
                    style={{ gridTemplateColumns: "auto 1fr" }}
                  >
                    {filtered.map((item, i) => {
                      const active = selected.includes(item.id);
                      return (
                        <React.Fragment key={item.id}>
                          <div
                            className="flex justify-center items-center font-bold h-full w-full pl-2"
                            style={{
                              gridColumn: "1 / span 1",
                              gridRow: `${i + 1} / span 1`,
                            }}
                          >
                            <Checkbox isSelected={active} />
                          </div>

                          <div
                            style={{
                              gridColumn: "2 / span 1",
                              gridRow: `${i + 1} / span 1`,
                            }}
                            className="flex py-2.5 px-2 items-center gap-4 min-w-0"
                          >
                            <CourseIcon
                              category={item.category}
                              quizType={item?.quiz_type}
                            >
                              {(Icon, color) => (
                                <div
                                  className={classNames(
                                    "shrink-0 w-7 h-7 rounded-lg flex items-center text-lg justify-center",
                                    color
                                  )}
                                >
                                  <Icon />
                                </div>
                              )}
                            </CourseIcon>
                            {item.category === "slide" ? (
                              <div className="aspect-video relative max-w-[5rem] mr-auto bg-white w-full border border-gray-100 rounded-xl overflow-hidden pointer-events-none">
                                <CourseSlideThumbnail items={item.items} />
                              </div>
                            ) : (
                              <div className="grow whitespace-nowrap overflow-x-clip text-ellipsis select-none">
                                {item.name ||
                                  pagePlaceholder({
                                    category: item.category,
                                    quizType: item.quiz_type,
                                  })(t)}
                              </div>
                            )}
                          </div>

                          <div
                            style={{
                              gridRow: `${i + 1} / span 1`,
                              gridColumn: `1 / span 2`,
                            }}
                            className={classNames(
                              "flex w-full h-full z-[1] relative opacity-0 bg-primary transition rounded-xl",
                              !exportMutation.isLoading &&
                                "hover:opacity-20 cursor-pointer"
                            )}
                            onClick={() => {
                              if (exportMutation.isLoading) return;
                              if (!active)
                                return setSelected([...selected, item.id]);

                              const index = selected.indexOf(item.id);
                              setSelected([
                                ...selected.slice(0, index),
                                ...selected.slice(index + 1),
                              ]);
                            }}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </BigModalBody>
      <BigModalFooter>
        <div className="mx-2 font-bold text-gray-400 text-sm">
          {selected.length} {t("v4.library.selected")}
        </div>
        <NewButton
          variant="primary"
          disabled={!selected.length || exportMutation.isLoading}
          className="ml-auto"
          onClick={() => exportMutation.mutate({ category, language })}
        >
          {exportMutation.isLoading ? (
            <CgSpinner className="animate-spin" />
          ) : (
            <TbDownload />
          )}
          {t("v4.export.download")}
        </NewButton>
      </BigModalFooter>
    </BigModal>
  );
};
