import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";
import { courseRoutes, groupRoutes, miscRoutes } from "enums/routes";
import {
  TbAlertCircle,
  TbClipboardCheck,
  TbStar,
  TbStarsOff,
  TbX,
} from "react-icons/tb";
import { NewButton } from "app/components/Buttons/NewButton";
import { useDateFormat } from "app/hooks/useDateFormat";

export enum NotificationCode {
  TrialExpire = "FREE_TRIAL_EXPIRATION",
  SubscriptionExpired = "SUBSCRIPTION_EXPIRED",
  SubscriptionCanceled = "SUBSCRIPTION_CANCELED",
  SubscriptionActive = "SUBSCRIPTION_ACTIVATED",
  CourseAssignment = "COURSE_ASSIGNMENT",
}

export const getNotificationDetails = (item: Record<string, any>, t: any) => {
  const { payload, message } = item;
  const code: string | null = payload?.notification_code || null;

  if (!code) return null;

  if (code === NotificationCode.TrialExpire) {
    return {
      icon: TbAlertCircle,
      text: (
        <Trans
          defaults={t("v4.subscriptions.notifications.trialExpire")}
          components={{ b: <b className="text-primary" /> }}
          values={{ name: payload.name }}
        />
      ),
      location: miscRoutes.subscriptions,
    };
  }

  if (code === NotificationCode.SubscriptionExpired) {
    return {
      icon: TbAlertCircle,
      text: (
        <Trans
          defaults={t("v4.subscriptions.notifications.subscriptionExpire")}
          components={{ b: <b className="text-primary" /> }}
          values={{ name: payload.name }}
        />
      ),
      location: miscRoutes.subscriptions,
    };
  }

  if (code === NotificationCode.SubscriptionCanceled) {
    return {
      icon: TbStarsOff,
      text: (
        <Trans
          defaults={t("v4.subscriptions.notifications.subscriptionCancel")}
          components={{ b: <b className="text-primary" /> }}
          values={{ name: payload.name }}
        />
      ),
      location: miscRoutes.subscriptions,
    };
  }

  if (code === NotificationCode.SubscriptionActive) {
    return {
      icon: TbStar,
      text: (
        <Trans
          defaults={t("v4.subscriptions.notifications.subscriptionActive")}
          components={{ b: <b className="text-primary" /> }}
          values={{ name: payload.name }}
        />
      ),
      location: miscRoutes.subscriptions,
    };
  }

  if (code === NotificationCode.CourseAssignment) {
    if (payload?.courses && payload.courses.length > 1)
      return {
        location: {
          pathname: groupRoutes.assignments(payload.group_id),
          state: { selected: payload.courses.map(({ id }) => id) },
        },
        text: t("v4.assignment.notificationMultiple", {
          number: payload.courses.length,
        }),
        icon: TbClipboardCheck,
      };

    const course = payload.course || payload.courses?.[0];
    if (!course) return null;

    return {
      location: courseRoutes.test(course.id),
      text: t("v4.assignment.notificationSingle", {
        name: course.name,
      }),
      icon: TbClipboardCheck,
    };
  }

  return null;
};

export const Notification = ({
  item,
  handleClose,
  close,
}: {
  item: any;
  handleClose?: () => void;
  close?: () => void;
}) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const { t } = useTranslation();
  const dateFormat = useDateFormat();

  const data = getNotificationDetails(item, t);
  const Component = data?.location ? Link : "div";

  if (!data) return null;

  return (
    // @ts-ignore
    <Component
      className={classNames(
        "w-full px-3 py-1.5 grid gap-y-1 bg-opacity-5 transition rounded-lg text-gray-600",
        item.date_read ? "bg-gray-700" : "bg-primary",
        !!data?.location && "hover:bg-opacity-10 cursor-pointer"
      )}
      style={{ gridTemplateColumns: "auto 1fr auto" }}
      {...(data?.location && { to: data.location, onClick: handleClose })}
    >
      <div className="w-full flex items-center justify-center">
        {data.icon && <data.icon className="text-2xl shrink-0 m-auto mr-3" />}
      </div>
      <div className="w-full flex items-center min-w-0">
        <div className="leading-tight text-base font-bold overflow-hidden text-ellipsis min-w-0">
          {data.text}
        </div>
      </div>
      {item.date_created && (
        <div
          className="text-gray-400 text-xs"
          style={{ gridColumn: "2 / span 1" }}
        >
          {dateFormat(item.date_created, "distance")}
        </div>
      )}

      {close && (
        <NewButton
          style={{ gridArea: "1 / 3 / span 1 / span 1" }}
          className="mb-auto -mr-2 -mt-0.5 ml-3"
          size="sm"
          iconOnly
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            close?.();
          }}
        >
          <TbX className="!text-base" />
        </NewButton>
      )}
    </Component>
  );
};
