import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ConsumptionType,
  FlashcardsQuiz,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import {
  InstructionsRender,
  QuizInstructionsRender,
  QuizItemLabel,
} from "app/components/Exercises/CourseEdit/components/generate/QuizDropzone";
import { useTranslation } from "react-i18next";
import { FlashcardsItem } from "../content/PageFlashcardsItem";
import { withConsumption } from "../../render/ConsumptionContext";
import { FlashcardsQuizConsumption } from "../../render/courseConsumptionTypes";
import { InputWithMediaRender } from "../../components/InputWithMedia";
import classNames from "classnames";
import { Radio } from "./PageMultipleChoiceItem";
import { InputText } from "../../../../Buttons/InputText";
import { NewButton } from "../../../../Buttons/NewButton";
import { TbArrowRight, TbCheck } from "react-icons/tb";
import {
  SnapItem,
  SnapList,
  useDragToScroll,
  useScroll,
  useVisibleElements,
} from "react-snaplist-carousel";
import { useWheelForHorizontalScroll } from "../../../LiveSession/TeacherControlBar";
import { FlashcardsQuizResults } from "../../results/courseResultsTypes";
import { CorrectMarker } from "../../results/PageResults";
import { CorrectnessMarker } from "../../results/PageCorrect";

export const FlashcardsQuizItem = () => <FlashcardsItem quiz />;

export const FlashcardsQuizItemRender =
  withConsumption<FlashcardsQuizConsumption>(
    ({ set, id, items: items_, answer, options, consumptionType }) => {
      const { t } = useTranslation();
      const parentRef = useRef<HTMLDivElement | null>(null);

      const items = useMemo(() => items_.map(([item]) => item), [items_]);

      const [scrolling, setScrolling] = useState<undefined | number>(undefined);
      const goToSnapItem = useScroll({ ref: parentRef });
      const goTo = useCallback(
        (item: number) => {
          goToSnapItem(item);
          setScrolling(item);
        },
        [goToSnapItem]
      );

      const visible = useVisibleElements(
        { ref: parentRef },
        ([element]) => element
      );
      const activeIndex = scrolling ?? visible;

      const active = useMemo(() => items[activeIndex], [activeIndex, items]);

      const currentOptions = useMemo(() => {
        if (consumptionType !== ConsumptionType.multipleChoice) return;
        return options?.[active.id];
      }, [active, options, consumptionType]);

      const reachedItem = visible === scrolling;
      const isScrolling = scrolling != null;

      useEffect(() => {
        if (reachedItem) setScrolling(undefined);
      }, [reachedItem]);

      useDragToScroll({ ref: parentRef, disabled: isScrolling });

      const goToNext = () => {
        if (activeIndex >= items.length - 1) return;
        goTo((items.length + activeIndex + 1) % items.length);
      };

      const scrollRef = useWheelForHorizontalScroll();
      useEffect(() => {
        const el = scrollRef.current;
        if (!el) return;
        const item = el.children.item(activeIndex) as HTMLDivElement;
        if (!item) return;
        el.scroll({
          left: (item.offsetLeft ?? 0) - el.clientWidth / 4,
          behavior: "smooth",
        });
      }, [activeIndex]);

      return (
        <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1 relative overflow-hidden select-none">
          <QuizItemLabel type={PageItemType.FlashcardsQuiz} />
          <QuizInstructionsRender id={id} />
          <SnapList
            direction="horizontal"
            ref={parentRef}
            className="pb-6 pt-4"
            onPointerDown={() => setScrolling(undefined)}
          >
            {items.map((item, i) => (
              <SnapItem
                snapAlign="center"
                margin={{
                  left: !i ? "calc(50% - min(20rem,60vw) / 2)" : "1rem",
                  right:
                    i === items.length - 1
                      ? "calc(50% - min(20rem,60vw) / 2)"
                      : "1rem",
                }}
              >
                <div
                  key={item.id}
                  // ref={ref}
                  className={classNames(
                    active.id === item.id ? "bg-primary" : "bg-gray-300",
                    "shrink-0 snap-center bg-gray-100 rounded-2xl sm:rounded-3xl font-bold p-6 text-gray-600 shadow-xl shadow-black/5 transition w-[min(20rem,60vw)] text-center flex flex-col my-auto"
                  )}
                >
                  <InputWithMediaRender
                    className="[&>div:first-child]:text-center [&>div:first-child]:justify-center gap-4"
                    text={item.text}
                    media={item.media}
                  />
                </div>
              </SnapItem>
            ))}
          </SnapList>
          <div
            className="-mx-1.5 py-1 px-2 overflow-x-auto gap-1 flex items-center relative"
            ref={scrollRef}
          >
            {items.map((item, i) => (
              <div
                key={item.id}
                onClick={() => goTo(i)}
                className={classNames(
                  "w-16 h-10 shrink-0 rounded-lg shadow-sm shadow-black/5 flex items-center justify-center font-bold text-lg text-center first:ml-auto last:mr-auto scrollbar-none cursor-pointer relative",
                  active.id === item.id
                    ? "bg-primary text-white"
                    : "bg-white text-gray-600"
                )}
              >
                {i + 1}
                <div
                  className={classNames(
                    "absolute right-0.5 top-0.5 px-1 py-0.5 rounded text-xs transition transform",
                    active.id === item.id
                      ? "bg-white bg-opacity-40"
                      : "bg-gray-600 bg-opacity-20",
                    !answer?.[item.id] && "opacity-0 scale-50"
                  )}
                >
                  <TbCheck strokeWidth={5} />
                </div>
              </div>
            ))}
          </div>

          {consumptionType === ConsumptionType.multipleChoice ? (
            currentOptions?.map(({ id, text, media }) => (
              <div
                className="bg-white cursor-pointer hover:bg-gray-50 transition rounded-lg p-1.5 flex items-center"
                key={id}
                onClick={() => {
                  set((item) => {
                    item.answer[active.id] = id;
                  });
                  goToNext();
                }}
              >
                <Radio active={answer?.[active.id] === id} />
                <InputWithMediaRender {...{ text, media }} />
              </div>
            ))
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                goToNext();
              }}
              className="flex items-center gap-1 pt-0.5"
            >
              <InputText
                value={answer?.[active.id] ?? ""}
                placeholder={t("v4.item.flashcards.answer") + "..."}
                className="px-1"
                onChange={(v) =>
                  set((item) => {
                    item.answer[active.id] = v;
                  })
                }
              />
              <NewButton
                disabled={activeIndex === items.length - 1}
                component="button"
                type="submit"
              >
                <TbArrowRight />
                {t("v4.generic.next")}
              </NewButton>
            </form>
          )}
        </div>
      );
    }
  );

export const PageFlashcardsQuizResults = ({
  item,
}: {
  item: FlashcardsQuizResults;
}) => {
  const answers = useMemo(
    () => Object.fromEntries(item.items.map(([_, right]) => [right.id, right])),
    [item.items]
  );
  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <InstructionsRender instructions={item.instructions} />

      {item.items.map(([left, right], i) => (
        <div className="flex items-stretch" key={left.id + "-" + right.id}>
          <CorrectMarker
            correct={!!item?.result[left.id]}
            content={
              answers?.[right.id] && (
                <InputWithMediaRender
                  text={answers[right.id].text}
                  media={
                    item.consumptionType === "stta"
                      ? undefined
                      : answers[right.id].media
                  }
                />
              )
            }
          />
          <div className="grow flex relative rounded-xl shadow-xl">
            <div className="flex-1 flex bg-white rounded-l-xl p-1.5">
              <div className="rounded-lg p-1.5 flex grow bg-gray-100">
                <InputWithMediaRender className="grow" {...left} />
              </div>
            </div>
            <div className="flex-1 flex bg-gray-600 rounded-r-xl p-1.5">
              <div className="rounded-lg p-1.5 flex grow bg-white">
                {item.consumptionType === "stta" ? (
                  <InputWithMediaRender
                    className="grow"
                    text={item.answer[left.id]}
                  />
                ) : (
                  answers?.[item.answer[left.id]] && (
                    <InputWithMediaRender
                      className="grow"
                      {...answers[item.answer[left.id]]}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const PageFlashcardsQuizCorrect = ({
  item,
}: {
  item: FlashcardsQuiz;
}) => (
  <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
    <InstructionsRender instructions={item.instructions} />

    {item.items.map(([left, right], i) => (
      <div className="flex items-stretch" key={left.id + "-" + right.id}>
        <CorrectnessMarker ids={[item.id, left.id]} />
        <div className="grow flex relative rounded-xl shadow-xl">
          <div className="flex-1 flex bg-white rounded-l-xl p-1.5">
            <div className="rounded-lg p-1.5 flex grow bg-gray-100">
              <InputWithMediaRender className="grow" {...left} />
            </div>
          </div>
          <div className="flex-1 flex bg-gray-600 rounded-r-xl p-1.5">
            <div className="rounded-lg p-1.5 flex grow bg-white">
              <InputWithMediaRender className="grow" {...right} />
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
);
