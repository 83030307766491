import React from "react";

export type Position = [number, number];

interface WithCoordinates {
  start_point: [number, number];
}

export const distanceBetween = (a: WithCoordinates, b: WithCoordinates) =>
  Math.sqrt(
    (a.start_point[0] - b.start_point[0]) ** 2 +
      (a.start_point[1] - b.start_point[1]) ** 2
  );
