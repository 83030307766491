import {
  PageItemType,
  ParagraphSummarize,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { postSummarize } from "api/course/courseContentAPI";
import { textToParagraphs } from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { PageGenerate } from "app/components/Exercises/CourseEdit/components/PageGenerate";

export const PageParagraphSummarize = () => (
  <PageGenerate
    promise={(item: ParagraphSummarize) => postSummarize(item.data)}
    gen={({ summary }, { id }) => ({
      id,
      type: PageItemType.Paragraph,
      category: "content",
      align: "left",
      data: textToParagraphs(summary),
    })}
  />
);
