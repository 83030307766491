import { createStore, useStore } from "zustand";
import { immer } from "zustand/middleware/immer";
import produce, { Draft } from "immer";
import {
  CreateProtectedContext,
  useProtectedContext,
} from "app/hooks/useProtectedContext";
import {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  ConsumptionType,
  ContentField,
  CourseItem,
  PageItemType,
  PollItem,
  QuizItem,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import arrayShuffle from "array-shuffle";
import { ItemConsumption } from "app/components/Exercises/CourseEdit/render/courseConsumptionTypes";
import objectHash from "object-hash";

interface ConsumptionProps {
  items: Record<string, ItemConsumption>;
}

export interface ConsumptionState extends ConsumptionProps {
  load: (items?: CourseItem[]) => void;
  setItem: (
    id: string
  ) => <T = ItemConsumption>(fun: (item: Draft<T>) => void) => void;
}

type ConsumptionStore = ReturnType<typeof createConsumptionStore>;

export const createConsumptionStore = (items?: CourseItem[]) => {
  const load = (items?: CourseItem[]) => {
    const filtered = (items ?? []).filter(
      ({ category }) => category === "quiz" || category === "poll"
    ) as (QuizItem | PollItem)[];

    const withAnswers = filtered
      .map((item) => {
        if (item.type === PageItemType.MultipleChoice)
          return {
            ...item,
            items: arrayShuffle(item.items),
            answer: Object.fromEntries(item.items.map(({ id }) => [id, false])),
            // result: Object.fromEntries(
            //   item.items.map(({ id }) => [id, Math.random() > 1])
            // ),
          };
        if (item.type === PageItemType.ImageLabeling)
          return {
            ...item,
            items:
              item.consumptionType !== ConsumptionType.multipleChoice
                ? item.items
                : item.items.map((i) => {
                    const optionsSet = new Set(item.items.map((i) => i.text));
                    optionsSet.delete(i.text);
                    const selectedOptions = arrayShuffle(
                      [...optionsSet].slice(0, 3)
                    );

                    return {
                      ...i,
                      options: arrayShuffle([...selectedOptions, i.text]),
                    };
                  }),
            // items: arrayShuffle(item.items),
            answer: Object.fromEntries(item.items.map(({ id }) => [id, ""])),
            // result: Object.fromEntries(
            //   item.items.map(({ id }) => [id, Math.random() > 1])
            // ),
          };
        if (item.type === PageItemType.Sorting)
          return {
            ...item,
            answer: arrayShuffle(item.items.map(({ id }) => id)),
            // result: Object.fromEntries(
            //   item.items.map(({ id }) => [id, Math.random() > 1])
            // ),
          };
        if (item.type === PageItemType.Pairing)
          return {
            ...item,
            answer: [
              arrayShuffle(item.items.map(([{ id }]) => id)),
              arrayShuffle(item.items.map(([, { id }]) => id)),
            ],
            // result: Object.fromEntries(
            //   item.items.map(([id]) => [id, Math.random() > 1])
            // ),
          };
        if (item.type === PageItemType.ShortAnswer)
          return {
            ...item,
            answer: "",
            // result: Math.random() > 1,
          };
        if (item.type === PageItemType.FillTheGap)
          return {
            ...item,
            items:
              item.consumptionType !== ConsumptionType.multipleChoiceSemantic
                ? item.items
                : produce(item.items, (items) => {
                    for (const key in items) {
                      const item = items[key];
                      const options = new Set(item?.options ?? []);
                      item.options = arrayShuffle([...options, item.text]);
                    }
                  }),
            answer: Object.fromEntries(
              Object.keys(item.items).map((id) => [id, ""])
            ),
            // result: Object.fromEntries(
            //   item.items.map(({ id }) => [id, Math.random() > 1])
            // ),
          };

        if (item.type === PageItemType.Categorisation)
          return {
            ...item,
            options: arrayShuffle(
              item.items.reduce(
                (acc: ContentField[], category) => [...acc, ...category.items],
                []
              )
            ),
            answer: Object.fromEntries(
              item.items
                .reduce(
                  (acc: ContentField[], item) => [...acc, ...item.items],
                  []
                )
                .map(({ id }) => [id, null])
            ),
            // result: Object.fromEntries(
            //   item.items.map(({ id }) => [id, Math.random() > 1])
            // ),
          };

        if (item.type === PageItemType.FlashcardsQuiz) {
          const options =
            item.consumptionType === ConsumptionType.strictTypeTheAnswer
              ? undefined
              : Object.fromEntries(
                  item.items.map(([field, answer]) => {
                    const options = arrayShuffle(
                      item.items.filter(([{ id }]) => id !== field.id)
                    )
                      .slice(0, 3)
                      .map(([, right]) => right);
                    return [field.id, arrayShuffle([answer, ...options])];
                  })
                );

          return {
            ...item,
            items: arrayShuffle(item.items),
            options,
            answer: Object.fromEntries(
              item.items.map(([item]) => [item.id, ""])
            ),
          };
        }

        if (item.type === PageItemType.Crossword) {
          const letters = {};

          for (const answer in item.items) {
            const data = item.items[answer];
            if (!data?.position) continue;
            const position = data.position;

            [...new Array(answer.length)].forEach((_, i) => {
              const x =
                position.x + (position.orientation === "across" ? i : 0);
              const y = position.y + (position.orientation === "down" ? i : 0);
              const key = `${x}_${y}`;

              if (letters?.[key]) {
                if (i === 0 && letters[key]?.index == null)
                  letters[key].index = position.index;
                // sort according to priority score for a given answer letter
                // if answer is the index, add 10, if answer is across, add 1
                const getSortScore = (answer?: typeof item.items[string]) => {
                  if (!answer?.position) return -1;
                  let value = 0;
                  if (answer.position.orientation === "across") value += 1;
                  if (answer.position.x === x && answer.position.y === y)
                    value += 10;

                  return value;
                };
                letters[key].parent = [...letters[key].parent, answer].sort(
                  (a, b) =>
                    getSortScore(item.items?.[b]) -
                    getSortScore(item.items?.[a])
                );
                return;
              }

              letters[key] = {
                parent: [answer],
                index: i === 0 ? position.index : undefined,
                x,
                y,
              };
            });
          }

          const filtered = Object.entries(item.items).filter(
            ([, p]) => !!p.position
          );

          return {
            ...item,
            items: Object.fromEntries(filtered),
            letters: letters,
            answer: Object.fromEntries(
              filtered.map(([answer]) => [answer, " ".repeat(answer.length)])
            ),
          };
        }

        if (item.type === PageItemType.CommentPoll)
          return {
            ...item,
            answer: "",
            // result: Math.random() > 1,
          };

        if (item.type === PageItemType.MultipleChoicePoll)
          return {
            ...item,
            answer: Object.fromEntries(item.items.map(({ id }) => [id, false])),
            // result: Math.random() > 1,
          };
        return item;
      })
      .map((item) => [item.id, item]);

    return Object.fromEntries(withAnswers);
  };

  return createStore<ConsumptionState>()(
    immer((set) => ({
      items: load(items),

      load: (items?: CourseItem[]) =>
        set((state) => {
          state.items = load(items);
        }),
      setItem: (id: string) =>
        function <T = ItemConsumption>(fun: (item: Draft<T>) => void) {
          set((state) => {
            if (!state.items?.[id]) return;
            const item = state.items[id];
            fun(item as Draft<T>);
          });
        },
    }))
  );
};

export const ConsumptionStoreContext =
  CreateProtectedContext<ConsumptionStore>();

export const useConsumptionStore = () =>
  useProtectedContext(ConsumptionStoreContext);

export const ConsumptionContextProvider = ({
  children,
  items,
}: PropsWithChildren<{ items?: CourseItem[] }>) => {
  const storeRef = useRef<ConsumptionStore>();
  if (!storeRef.current) {
    storeRef.current = createConsumptionStore(items);
  }
  const store = storeRef.current;

  useEffect(() => {
    store.getState().load(items);
  }, [objectHash(items || null)]);

  return (
    <ConsumptionStoreContext.Provider value={store}>
      {children}
    </ConsumptionStoreContext.Provider>
  );
};

export const useConsumptionContext = <T,>(
  selector: (state: ConsumptionState) => T,
  equalityFn?: (left: T, right: T) => boolean
): T | null => {
  const storeRef = useContext(ConsumptionStoreContext);
  if (!storeRef) return null;
  return useStore(storeRef, selector, equalityFn);
};

export function useConsumptionItemContext<
  T extends ItemConsumption = ItemConsumption
>(id: string) {
  const store = useConsumptionStore();
  const data = useStore(store, (store) => store.items[id]);
  const setData_ = useStore(store, (store) => store.setItem);
  const setData = useMemo(() => setData_(id), [setData_, id]);
  return [
    data as T | undefined,
    setData as <G = T>(fun: (item: Draft<G>) => void) => void,
  ] as const;
}

export const withConsumption =
  <T extends ItemConsumption>(
    Component: FC<T & { set: <G = T>(fun: (item: Draft<G>) => void) => void }>
  ) =>
  ({ id }: { id: string }): JSX.Element | null => {
    const [item, set] = useConsumptionItemContext<T>(id);
    if (!item) return null;
    // @ts-ignore
    return <Component {...item} set={set} />;
  };
