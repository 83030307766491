import { useMutation, useQueryClient } from "react-query";
import { postCreateOrganization } from "../../../../api/organizationsAPI";

export const useCreateNewOrg = (name: string) => {
  const queryClient = useQueryClient();
  return useMutation(() => postCreateOrganization({ name, is_b2c: false }), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["organizations"], data);
    },
  });
};
