import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  InstructionContentContextProvider,
  PageItemIndexContext,
  usePageItemContext,
  usePageItemIndex,
  useSetList,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  ConsumptionType,
  ContentItem,
  COURSE_ITEM_DATA,
  FillTheGap,
  ImageLabeling,
  PageItemType,
  QuizItem,
  ShortAnswer,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import React, { ComponentProps, PropsWithChildren, ReactNode } from "react";
import { PageItem } from "app/components/Exercises/CourseEdit/items/PageItem";
import { GapInsertButton } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import classNames from "classnames";
import { useConsumptionItemContext } from "app/components/Exercises/CourseEdit/render/ConsumptionContext";
import { ItemConsumption } from "app/components/Exercises/CourseEdit/render/courseConsumptionTypes";
import { PageItemRender } from "app/components/Exercises/CourseEdit/render/PageRender";
import { Trans, useTranslation } from "react-i18next";
import { Tooltip } from "app/components/Tooltip";
import { FaQuestionCircle } from "react-icons/fa";
import { IconType } from "react-icons";
import {
  TbChevronDown,
  TbLanguage,
  TbListCheck,
  TbWriting,
} from "react-icons/tb";
import { FloatingMenu } from "app/components/Header";

export const QUIZ_ITEM_HINTS = (t: any) =>
  ({
    [PageItemType.MultipleChoice]: t("v4.item.selectAnswers.instructions"),
    [PageItemType.ShortAnswer]: t("v4.item.shortAnswer.instructions"),
    [PageItemType.Sorting]: t("v4.item.sorting.instructions"),
    [PageItemType.Pairing]: t("v4.item.pairing.instructions"),
    [PageItemType.ImageLabeling]: t("v4.item.imageLabeling.instructions"),
    [PageItemType.FillTheGap]: t("v4.item.fillTheGap.instructions"),
    [PageItemType.Flashcards]: t("v4.item.flashcards.instructions"),
    [PageItemType.Categorisation]: t("v4.item.categorisation.description"),
    [PageItemType.Crossword]: t("v4.item.crossword.description"),
    [PageItemType.FlashcardsQuiz]: t("v4.item.flashcards.quizInstructions"),
  } as const);

export const CONSUMPTION_TYPES = (t) => ({
  // [ConsumptionType.typeTheAnswer]: {
  //   name: mt("Type The Answer"),
  //   description: mt("Type the correct answer using your keyboard."),
  //   icon: TbWriting,
  // },
  [ConsumptionType.strictTypeTheAnswer]: {
    // name: mt("Strict Type The Answer"),
    // description: (
    //   <Trans
    //     defaults={mt(
    //       "Type <b>exactly</b> the correct answer using your keyboard."
    //     )}
    //     components={{ b: <b /> }}
    //   />
    // ),
    // icon: TbLock,
    name: t("v4.consumption.typeTheAnswer"),
    description: t("v4.consumption.typeTheAnswerText"),
    icon: TbWriting,
  },
  [ConsumptionType.multipleChoice]: {
    name: t("v4.item.multipleChoice.text"),
    description: t("v4.consumption.mcqText"),
    icon: TbListCheck,
  },
  [ConsumptionType.multipleChoiceSemantic]: {
    name: t("v4.consumption.mcqSemantic"),
    description: (
      <>
        {t("v4.consumption.mcqText")}{" "}
        <Trans
          i18nKey="v4.consumption.mcqSemanticText"
          components={{ b: <b /> }}
        />
      </>
    ),
    icon: TbListCheck,
  },
  [ConsumptionType.multipleChoiceLanguage]: {
    name: t("v4.consumption.mcqLanguage"),
    description: (
      <>
        {t("v4.consumption.mcqText")}{" "}
        <Trans
          i18nKey="v4.consumption.mcqLanguageText"
          components={{ b: <b /> }}
        />
      </>
    ),
    icon: TbLanguage,
  },
});

export const NullishIcon = ({
  component: Component,
  ...props
}: ComponentProps<"svg"> & { component?: IconType | false | null }) => {
  if (!Component) return null;
  return <Component {...props} />;
};

export const QuizItemLabelRaw = ({
  icon: Icon,
  name,
  tooltip = "",
  consumptionType,
  children,
}: {
  icon: IconType;
  name: string;
  tooltip?: string;
  consumptionType?: ConsumptionType | ConsumptionType[];
  children?: ReactNode;
}) => {
  const { t } = useTranslation();

  const consumptionTypeData =
    !!consumptionType &&
    typeof consumptionType === "string" &&
    CONSUMPTION_TYPES(t)[consumptionType];

  return (
    <div className="flex -mt-1.5 -mx-1.5 mb-0.5">
      <div className="text-sm font-bold flex items-center gap-1 text-gray-500 pl-2 pr-3 py-1 bg-gray-100 rounded-tl-lg rounded-br-lg">
        <Icon className="text-xl" strokeWidth={1.75} />
        {name}
        {!!tooltip && (
          <Tooltip className="px-1 -mr-1 text-gray-400" value={tooltip}>
            <FaQuestionCircle />
          </Tooltip>
        )}
      </div>

      {children}

      {!consumptionType ? null : typeof consumptionType === "string" ? (
        <div className="text-sm font-bold flex items-center gap-1 text-gray-500 ml-auto pl-2 pr-3 py-1 bg-gray-300 rounded-bl-lg rounded-tr-lg">
          <NullishIcon
            component={consumptionTypeData.icon}
            className="text-xl"
            strokeWidth={1.75}
          />
          {consumptionTypeData.name}
          <Tooltip
            className="px-1 -mr-1 text-gray-400"
            value={consumptionTypeData.description}
          >
            <FaQuestionCircle />
          </Tooltip>
        </div>
      ) : (
        <ConsumptionTypeSelector types={consumptionType} />
      )}
    </div>
  );
};

export const CornerSelector = (props: {
  value: string;
  setValue: (value: string) => void;
  options: Record<
    string,
    { icon?: IconType; name: string; description?: string }
  >;
}) => {
  const selected = props.options?.[props.value];

  return (
    <FloatingMenu
      placement="bottom-end"
      containerClassName="ml-auto"
      size="xs"
      trigger={(trigger) => (
        <div
          onClick={trigger}
          className="text-sm font-bold flex items-center gap-1 text-gray-500 ml-auto pl-2 pr-3 py-1 bg-gray-300 transition hover:bg-opacity-50 cursor-pointer rounded-bl-lg rounded-tr-lg"
        >
          {selected && (
            <>
              <NullishIcon
                component={selected.icon}
                className="text-xl"
                strokeWidth={1.75}
              />
              {selected.name}
            </>
          )}
          <TbChevronDown className="ml-0.5" />
        </div>
      )}
      className="gap-0.5 max-w-sm"
    >
      {({ setIsOpen }) =>
        Object.entries(props.options).map(([key, data]) => {
          const active = props.value === key;

          return (
            <div
              key={key}
              className={classNames(
                "rounded-lg items-center w-full transition p-1.5 pr-3 flex gap-2 text-gray-600",
                active
                  ? "bg-opacity-10 bg-primary cursor-default"
                  : "bg-opacity-0 hover:bg-opacity-10 cursor-pointer bg-gray-500"
              )}
              onClick={() => {
                setIsOpen(false);
                props.setValue(key);
              }}
            >
              {data.icon && <data.icon className="text-2xl shrink-0 mx-0.5" />}
              <div className="flex flex-col leading-tight">
                <div className="font-bold mb-0.5">{data.name}</div>
                {data.description && (
                  <div className="text-gray-500 text-xs">
                    {data.description}
                  </div>
                )}
              </div>
            </div>
          );
        })
      }
    </FloatingMenu>
  );
};

const ConsumptionTypeSelector = ({ types }: { types: ConsumptionType[] }) => {
  const { t } = useTranslation();
  const [{ consumptionType }, set] = usePageItemContext<
    ShortAnswer | ImageLabeling | FillTheGap
  >();

  const options = Object.fromEntries(
    types.map((type) => [type, CONSUMPTION_TYPES(t)[type]])
  );

  return (
    <CornerSelector
      value={consumptionType}
      setValue={(type) => {
        set((item) => {
          item.consumptionType = type as any;
        });
      }}
      options={options}
    />
  );
};

export const QuizItemLabel = ({
  type,
  consumptionType,
  children,
}: PropsWithChildren<{
  type: QuizItem["type"] | PageItemType.Flashcards;
  consumptionType?: ConsumptionType | ConsumptionType[];
}>) => {
  const { t } = useTranslation();
  const data = COURSE_ITEM_DATA(t)[type];
  const tooltip = QUIZ_ITEM_HINTS(t)[type];
  return (
    <QuizItemLabelRaw
      icon={data.icon}
      name={data.name}
      tooltip={tooltip}
      consumptionType={consumptionType}
    >
      {children}
    </QuizItemLabelRaw>
  );
};

export const QuizDropzone = () => {
  const [item] = usePageItemContext<QuizItem>();
  const index = usePageItemIndex();

  const instructions = item?.instructions || [];

  return (
    <div
      className={classNames(
        "flex flex-col transition-all rounded-lg",
        instructions.length ? "bg-gray-50 py-1.5" : "bg-transparent pb-0.5"
      )}
    >
      <InstructionContentContextProvider>
        <PageDropzone id={index} list={item?.instructions || []} />
      </InstructionContentContextProvider>
    </div>
  );
};

export const PageDropzone = ({ list, id, bigButton = false }) => {
  const setList = useSetList();

  const handleDrop = ({ destination, source }: any) => {
    if (!source || !destination) return;
    if (source.index === destination.index) return;

    setList((list) => {
      const [item] = list.splice(source.index, 1);
      list.splice(destination.index, 0, item);
    });
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable
          droppableId={`page-item ${id}`}
          // isDropDisabled={dragType !== "content"}
        >
          {({ innerRef, droppableProps, placeholder }) => (
            <div
              ref={innerRef}
              {...droppableProps}
              className="gap-0.5 pt-1.5 flex flex-col px-2"
            >
              {list.map((item, i) => (
                <PageItemIndexContext.Provider
                  key={item.id}
                  value={[i, item.id]}
                >
                  <PageItem type={item.type} index={i} />
                </PageItemIndexContext.Provider>
              ))}
              {placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="pb-1.5">
        <GapInsertButton index={list.length} big={bigButton} />
      </div>
    </>
  );
};

export const QuizInstructionsRender = ({ id }: { id: string }) => {
  const [item] = useConsumptionItemContext<ItemConsumption>(id);
  const instructions = item?.instructions || [];

  return <InstructionsRender instructions={instructions} />;
};

export const InstructionsRender = ({
  instructions,
}: {
  instructions: ContentItem[];
}) => {
  if (!instructions?.length) return null;

  return (
    <div className="flex flex-col bg-gray-50 py-3 px-4 mb-1 relative text-gray-700 rounded-lg">
      <InstructionContentContextProvider>
        {instructions.map((item) => (
          <PageItemRender key={item.id} item={item} />
        ))}
      </InstructionContentContextProvider>
    </div>
  );
};
