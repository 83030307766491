import { AnimatePresence, motion } from "framer-motion";
import React, { useRef } from "react";
import { useSessionContext } from "app/hooks/useSessionContext";
import defaultAvatar from "assets/images/avatar-40-x-40.png";
import { useTranslation } from "react-i18next";
import { VscDebugDisconnect } from "react-icons/vsc";
import classNames from "classnames";
import { TbCheck, TbUserOff, TbUsers, TbX } from "react-icons/tb";
import { ContextMenu } from "app/components/ContextMenu";
import { CgSpinner } from "react-icons/cg";
import { NewButton } from "app/components/Buttons/NewButton";
import useOnClickOutside from "use-onclickoutside";

const Participant = ({ data, consumption }) => {
  const { t } = useTranslation();
  const { session } = useSessionContext();
  const { id, name, avatar, status } = data;

  const matches = name.match(/\b(\w)/g);
  const acronym = matches && matches.join("");
  const isGuest = data.id.includes("guest_");

  return (
    <motion.div
      // notSubmitted={consumption && !data?.results}
      key={id}
      className={classNames(
        "flex items-center rounded-lg bg-gray-200 p-2",
        consumption && !data?.results && "[&>:not(:last-child)]:opacity-50",
        consumption && "transition"
      )}
      initial="hidden"
      animate="show"
      exit="hidden"
      variants={{
        hidden: { opacity: 0, scale: 0.7 },
        show: { opacity: 1, scale: 1 },
      }}
    >
      {consumption && !!data?.results && (
        <TbCheck className="mr-1 text-green-500 shrink-0 text-xl" />
      )}
      {avatar || !matches ? (
        <img
          className="w-7 h-7 shrink-0 rounded-full mr-2"
          alt="image"
          src={avatar || defaultAvatar}
        />
      ) : (
        <div className="w-7 h-7 shrink-0 rounded-full mr-2 bg-primary flex items-center justify-center text-white">
          {acronym.substring(0, 2).toUpperCase()}
        </div>
      )}
      <div className="text-sm text-gray-600 grow shrink break-all size-sm">
        {name} {isGuest && `(${t("liveSession.guest")})`}
      </div>
      {status === "disconnected" && (
        <VscDebugDisconnect className="text-red-500 ml-1 text-2xl" />
      )}
      <ContextMenu
        disablePortal
        strategy="absolute"
        className="floating-menu"
        options={[
          [
            {
              text: t("common.remove"),
              icon: <TbUserOff />,
              color: "bg-red-500 text-red-500",
              onClick: ({ setIsOpen }) => {
                session.get.banUser(id);
                setIsOpen(false);
              },
            },
          ],
        ]}
      />
    </motion.div>
  );
};

const Participants = ({
  isFloating,
  close,
  consumption = false,
}: {
  isFloating?: boolean;
  close?: () => void;
  consumption?: boolean;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const { session } = useSessionContext();

  useOnClickOutside(ref, (e) => {
    if (!isFloating || !close) return;
    if (
      e?.target &&
      document.getElementById("participants-button")?.contains(e.target as Node)
    )
      return;
    close();
  });

  const participants = session.get.status.participants.filter(
    (participant) => participant.status !== "banned"
  );

  return (
    <AnimatePresence>
      {(isFloating == null || isFloating) && (
        <motion.div
          ref={ref}
          initial="hidden"
          animate="show"
          exit="hidden"
          variants={{
            show: { translateX: 0 },
            hidden: { translateX: "150%" },
          }}
          transition={{ translateX: { duration: 0.3 } }}
          className={classNames(
            "p-2 flex flex-col",
            isFloating != null &&
              "fixed top-0 right-0 z-10 mt-11 drop-shadow-2xl h-[calc(100%-2.75rem)]"
          )}
        >
          <div className="bg-gray-100 rounded-lg flex flex-col w-80 grow">
            <div className="flex items-center w-full p-4 font-bold">
              <span className="mr-auto">{t("liveSession.participants")}</span>
              {isFloating != null ? (
                <NewButton iconOnly onClick={close} size="sm" className="ml-1">
                  <TbX />
                </NewButton>
              ) : (
                <div className="flex font-bold items-center gap-1 ml-auto">
                  {participants.length}
                  <TbUsers className="text-lg" />
                </div>
              )}
            </div>
            <div className="flex-1 relative">
              <div className="absolute-cover flex flex-col gap-2">
                <div className="px-4 overflow-y-auto overflow-x-hidden flex-1 gap-1 flex flex-col">
                  <AnimatePresence initial={false}>
                    {participants.map((participant) => (
                      <Participant
                        key={participant.id}
                        consumption={consumption}
                        data={participant}
                      />
                    ))}
                  </AnimatePresence>
                  {isFloating == null && (
                    <div className="flex gap-2 justify-center items-center text-gray-500 mt-1">
                      <CgSpinner className="animate-spin text-xl" />
                      <div className="font-bold text-center">
                        {t("liveSession.waitingParticipants")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Participants;
