import { NewButton } from "app/components/Buttons/NewButton";
import {
  TbArrowAutofitLeft,
  TbArrowAutofitRight,
  TbCheck,
  TbChevronDown,
  TbDots,
  TbX,
} from "react-icons/tb";
import React, { useMemo, useState } from "react";
import { ContentField } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { uuid } from "app/components/Exercises/utils/uuid";
import { BigModal } from "app/components/BigModal";
import { InputWithMediaRender } from "app/components/Exercises/CourseEdit/components/InputWithMedia";
import { FloatingMenu } from "app/components/Header";
import { useTranslation } from "react-i18next";

export const TwoColumnsModal = ({
  close,
  data,
  onConfirm,
}: {
  close: () => void;
  data: ContentField[][];
  onConfirm: (data: [ContentField, ContentField][]) => void;
}) => {
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(1);
  const columns = data[0].length;
  const rows = data.length;
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm(
      data.map((rows) => [
        {
          ...rows[left],
          id: uuid(),
        },
        {
          ...rows[right],
          id: uuid(),
        },
      ])
    );
    close();
  };

  const items = useMemo(() => {
    const items: typeof data = [...new Array(columns)].map(() => []);
    for (const row of data) {
      row.forEach((col, colI) => {
        items[colI].push(col);
      });
    }
    return items;
  }, [data]);

  const onLeft = (i: number) => {
    if (left === i) return;
    if (right === i) {
      setRight(left);
    }
    setLeft(i);
  };

  const onRight = (i: number) => {
    if (right === i) return;
    if (left === i) {
      setLeft(right);
    }
    setRight(i);
  };

  return (
    <BigModal>
      <div className="p-1 pl-3 font-bold border-b-2 border-gray-100 flex items-center gap-2 text-gray-500">
        <div className="font-bold text-gray-500">
          {t("v4.generate.table.selectTableColumn")}
        </div>
        <NewButton
          iconOnly
          onClick={close}
          className="ml-auto"
          variant="transparent"
        >
          <TbX />
        </NewButton>
      </div>

      <div className="grow relative">
        <div className="absolute-cover overflow-y-auto">
          <div className="min-h-full flex flex-col items-center grow p-4 text-gray-600">
            <div className="bg-gray-200 max-w-2xl w-full flex flex-col rounded-2xl">
              <div className="font-bold text-gray-700 px-6 py-4">
                {t("v4.library.source")}
              </div>
              <div className="overflow-x-auto flex gap-2 pr-6 pb-3 mb-3 ml-6">
                {items.map((column, colI) => (
                  <div
                    className="bg-white rounded-xl p-4 gap-1 flex flex-col w-48 shrink-0 first:ml-auto last:mr-auto"
                    key={colI}
                  >
                    <FloatingMenu
                      portal
                      placement="bottom-start"
                      className="w-40"
                      trigger={(toggle) => (
                        <NewButton
                          size="lg"
                          center
                          className="w-full mb-2 h-9"
                          color={
                            left === colI || right === colI
                              ? "bg-primary text-primary"
                              : undefined
                          }
                          onClick={toggle}
                        >
                          {left === colI ? (
                            <>
                              <TbArrowAutofitLeft />
                              <span className="text-sm font-bold">
                                {t("v4.generate.table.left")}
                              </span>
                            </>
                          ) : right === colI ? (
                            <>
                              <TbArrowAutofitRight />
                              <span className="text-sm font-bold">
                                {t("v4.generate.table.right")}
                              </span>
                            </>
                          ) : null}
                          <div className="grow" />
                          <TbChevronDown className="-mr-2 !text-base" />
                        </NewButton>
                      )}
                      size="xs"
                    >
                      {({ setIsOpen }) => (
                        <>
                          <NewButton
                            variant="transparent"
                            onClick={() => {
                              setIsOpen(false);
                              onLeft(colI);
                            }}
                          >
                            <TbArrowAutofitLeft /> {t("v4.generate.table.left")}
                          </NewButton>
                          <NewButton
                            variant="transparent"
                            onClick={() => {
                              setIsOpen(false);
                              onRight(colI);
                            }}
                          >
                            <TbArrowAutofitRight />{" "}
                            {t("v4.generate.table.right")}
                          </NewButton>
                        </>
                      )}
                    </FloatingMenu>

                    {column.slice(0, 4).map((media, rowI) => (
                      <div
                        key={rowI}
                        className="text-sm text-ellipsis whitespace-nowrap overflow-hidden px-1"
                      >
                        <InputWithMediaRender {...media} />
                      </div>
                    ))}
                    {rows > 4 && (
                      <div className="px-1 mt-auto -mb-2 text-gray-400">
                        <TbDots />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-gray-200 max-w-2xl w-full flex flex-col rounded-2xl mt-4">
              <div className="font-bold text-gray-700 px-6 py-4">
                {t("v4.generic.preview")}
              </div>
              <div
                className="bg-gray-200 rounded-lg px-6 mb-6 gap-1 grid grid-cols-2 gap-1 w-full max-w-2xl"
                style={{ gridTemplateRows: `repeat(${rows}, 1fr)` }}
              >
                {data
                  .map((rows) => rows[left])
                  .map((media, i) => (
                    <div
                      className="grow bg-white rounded-lg p-1.5 flex items-center"
                      key={i}
                    >
                      <InputWithMediaRender {...media} />
                    </div>
                  ))}
                {data
                  .map((rows) => rows[right])
                  .map((media, i) => (
                    <div
                      key={i}
                      className="grow bg-gray-100 rounded-lg p-1.5 flex items-center"
                      style={{
                        gridRow: `${i + 1} / span 1`,
                        gridColumn: "2 / span 1",
                      }}
                    >
                      <InputWithMediaRender {...media} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-1 font-bold border-t-2 border-gray-100 flex items-center gap-1 text-gray-500">
        <div className="grow" />
        <NewButton onClick={handleConfirm} variant="primary">
          <TbCheck /> {t("v4.generic.confirm")}
        </NewButton>
      </div>
    </BigModal>
  );
};
