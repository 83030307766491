import React, { ReactNode, useEffect, useState } from "react";
import { useDetectKeyPress } from "helpers/hooks/detectKeyPress";
import Loader from "../Loader";
import { GlobalModal } from "app/components/Exercises/Edit/ExerciseModal";
import { TbAlertTriangle } from "react-icons/tb";
import { NewButton } from "app/components/Buttons/NewButton";

interface Props {
  onConfirm: any;
  open: boolean;
  closeModal: any;
  text?: string;
  confirmButton: string;
  closeButton: string;
  children?: ReactNode;
}

const WarningModal = ({
  onConfirm,
  open,
  closeModal,
  text,
  confirmButton,
  closeButton,
  children,
}: Props) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleConfirm = () => {
    setButtonDisabled(true);
    onConfirm();
  };

  useDetectKeyPress("Escape", () => open && closeModal());
  useDetectKeyPress("Enter", () => open && handleConfirm());

  useEffect(() => {
    setButtonDisabled(false);
  }, [open]);

  const setModalClose = () => {
    closeModal();
  };

  if (!open) return null;

  return (
    <GlobalModal close={setModalClose}>
      <TbAlertTriangle className="text-red-400 text-4xl" />
      {text && <div className="text-gray-600 mb-8 text-xl">{text}</div>}
      {children}
      <div className="flex flex-col space-y-2 w-full">
        <NewButton
          size="lg"
          disabled={buttonDisabled}
          center
          onClick={(e) => {
            e.preventDefault();
            handleConfirm();
          }}
        >
          {buttonDisabled ? <Loader spinner /> : confirmButton}
        </NewButton>
        <NewButton
          size="lg"
          variant="primary"
          center
          onClick={(e) => {
            e.preventDefault();
            setModalClose();
          }}
        >
          {closeButton}
        </NewButton>
      </div>
    </GlobalModal>
  );
};

export default WarningModal;
