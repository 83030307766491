import {
  usePageItemContext,
  usePageItemIndex,
  useSetList,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  Audio,
  Image,
  MediaUpload,
  PageItemType,
  Video,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
  useSourceUploadContext,
  useSourceUploadStore,
  WSStatus,
} from "api/ws/SourceUploadContext";
import { useQuery } from "react-query";
import { getSource } from "api/sourcesAPI";
import { uuid } from "app/components/Exercises/utils/uuid";
import { useMountedRef } from "app/hooks/useMounted";

export const PageMediaUpload = () => {
  const mountedRef = useMountedRef();
  const { t } = useTranslation();
  const index = usePageItemIndex();
  const { get } = useSourceUploadContext();
  const setList = useSetList();
  const [item, set] = usePageItemContext<MediaUpload>();
  const upload = useSourceUploadStore((store) => store.data?.[item.uploadId]);
  const { deleteTempFile } = useSourceUploadContext();
  const connected = useSourceUploadStore(
    (store) => store.status === WSStatus.Connected
  );

  const isTemp = upload?.uploadId != null;

  useEffect(() => {
    if (!upload) {
      setList((list) => {
        list.splice(index, 1);
      });
    }
  }, [!!upload]);

  useEffect(() => {
    const uploadId = upload?.uploadId;
    if (uploadId) {
      set((item) => {
        item.uploadId = uploadId;
      });
      deleteTempFile(item.uploadId);
    }
  }, [upload?.uploadId]);

  useEffect(() => {
    if (connected && !isTemp) {
      get(item.uploadId);
    }
  }, [item.uploadId, connected, isTemp]);

  useQuery({
    queryKey: ["source", upload?.data?.id],
    queryFn: () => getSource(upload?.data?.id),
    enabled: !!upload?.data?.id && !isTemp,
    onError: () => {
      toast.error(t(`common.errors.no_found_resource`));
      if (!mountedRef.current) return;
      setList((list) => {
        list.splice(index, 1);
      });
    },
    onSuccess: (data) => {
      if (!mountedRef.current) return;
      const newItem = {
        id: uuid(),
        category: "content",
        type: item.mediaType,
        data: {
          src:
            item.mediaType === PageItemType.Image
              ? data.image_url
              : data.download_url,
          sourceId: data.id,
        },
      } as Audio | Video | Image;

      setList((list) => {
        list.splice(index, 1, newItem);
      });
    },
    refetchOnWindowFocus: false,
  });

  return (
    <PageItemWrapper>
      <div className="w-full h-48 rounded-lg bg-gray-200 animate-pulse pointer-events-none flex justify-center items-center">
        <div className="w-full max-w-md overflow-hidden rounded-lg relative bg-gray-100 h-8">
          <div
            className="absolute-cover bg-primary transition origin-left"
            style={{
              transform: `scaleX(${(upload?.progress ?? 0) / 100})`,
            }}
          />
          <div className="absolute-cover flex items-center justify-center text-center text-sm font-bold">
            {upload?.progress ?? 0}%
          </div>
        </div>
      </div>
    </PageItemWrapper>
  );
};
