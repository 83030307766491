import React, { useCallback } from "react";
import { userSignInGoogle } from "../../store/authentication/authActions";
import urlParametersAsObj from "../../../helpers/urlParametersAsObj";
import { useDispatch } from "react-redux";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbBrandGoogle } from "react-icons/tb";
import GoogleLogin from "react-google-login";
import { clientIdGoogleAuth } from "enums/constant";
import { AppDispatch } from "app/store";
import { useTranslation } from "react-i18next";

export const LoginGoogle = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const errGoogleResponse = (response: any) => {
    console.log("GoogleResponse Error", response);
  };

  const handleLoginGoogleCallback = useCallback(
    async (response) => {
      const res: any = await dispatch(
        userSignInGoogle({
          access_token: response.accessToken,
          context: urlParametersAsObj(),
        })
      );

      // if (userSignInGoogle.fulfilled.match(res)) {
      //   setRequestError(false);
      // } else {
      //   setRequestError(true);
      // }
    },
    [dispatch]
  );

  return (
    <GoogleLogin
      clientId={clientIdGoogleAuth as string}
      onSuccess={handleLoginGoogleCallback}
      onFailure={errGoogleResponse}
      render={(props) => (
        <NewButton
          size="lg"
          center
          {...props}
          variant="primary"
          color="bg-white text-gray-600"
          className="whitespace-nowrap border border-gray-400"
        >
          <TbBrandGoogle /> {t("v4.account.continueGoogle")}
        </NewButton>
      )}
    />
  );
};
