import React from "react";
import { useSessionContext } from "../../../hooks/useSessionContext";
import { useTranslation } from "react-i18next";
import { CgSpinner } from "react-icons/cg";

const StatusDisplay = (/*{ loading }*/) => {
  const { t } = useTranslation();
  const { session } = useSessionContext();
  const name = session.get.status?.teacher?.name;

  // if (!loading) return <Ready />;

  return (
    <div className="flex flex-col grow justify-center items-center p-16 bg-gray-100 rounded-xl text-center font-bold">
      <div className="text-xl text-gray-600">{t("liveSession.lobbyText")}</div>
      <div className="text-lg text-gray-500 mb-2">
        {t("liveSession.lobbyWaiting", { name })}
      </div>
      <CgSpinner className="animate-spin text-primary text-3xl" />
    </div>
  );
};

export default StatusDisplay;
