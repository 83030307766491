import { useCallback, useEffect, useRef, useState } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import {
  CreateProtectedContext,
  useProtectedContext,
} from "app/hooks/useProtectedContext";
import { useSelector } from "react-redux";
import { selectUserDetails } from "app/store/user";
import { AccessToken, wsUrl } from "enums/constant";
import { FileType, mapMime } from "helpers/mimeType";
import { useQueryClient } from "react-query";
import { postSourceAsync } from "api/sourcesAPI";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { uuid } from "app/components/Exercises/utils/uuid";
import { translateError } from "app/components/Exercises/Edit/missingTranslation";
import { useSubscriptionRefetch } from "api/userAPI";
import { useRole } from "app/hooks/useRole";

import { ifExistsThen } from "helpers/ifExistsThen";

export enum WSStatus {
  Disconnected,
  Connected,
  Reconnecting,
}

interface SourceUploadProps {
  data: Record<
    string,
    {
      name: string;
      folderId: string | null;
      // false -> temporary file
      // string -> redirect to async upload
      // nullish -> async upload
      uploadId?: string | false;
      type?: FileType;
      progress?: number;
      error?: string;
      data?: {
        id: string;
        name: string;
      };
    }
  >;
  transcript: Record<
    string,
    {
      progress: number;
      error?: string;
      data?: {
        text: string;
      };
    }
  >;
  task: Record<
    string,
    {
      name: string;
      progress: number;
      input: {
        training_id?: string;
        focus?: string;
        language?: string;
        n_slides?: number;
        source_id?: string;
      };
      data?: Record<string, any>;
      error?: string;
    }
  >;
  status: WSStatus;
  qr: string | null;
}

interface SourceUploadState extends SourceUploadProps {
  reset: () => void;
  setState: (gen: (state: SourceUploadState) => void) => void;
}

export const useSourceUploadStore = create<SourceUploadState>()(
  immer((set) => ({
    data: {},
    transcript: {},
    task: {},
    qr: null,
    status: WSStatus.Disconnected,

    setState: set,
    reset: () =>
      set((state) => {
        state.data = {};
        state.transcript = {};
        state.task = {};
        state.qr = null;
        state.status = WSStatus.Disconnected;
      }),
  }))
);

// export const createSourceUploadStore = () =>
//   createStore<SourceUploadState>()(
//     immer((set) => ({
//       data: {},
//       transcript: {},
//       task: {},
//       qr: null,
//       status: WSStatus.Disconnected,
//
//       reset: () =>
//         set((state) => {
//           state.data = {};
//           state.transcript = {};
//           state.task = {};
//           state.qr = null;
//           state.status = WSStatus.Disconnected;
//         }),
//       setStatus: (status: WSStatus) =>
//         set((state) => {
//           state.status = status;
//         }),
//     }))
//   );

export const SourceUploadStoreContext = CreateProtectedContext<{
  upload: (data: {
    file: File;
    name?: string | undefined;
    folderId?: string | undefined;
    resolve?: (uploadId: string) => void;
    reject?: () => void;
  }) => string;
  deleteTempFile: (tempId: string) => void;
  get: (uploadId: string) => void;
  resetUpload: (uploadId: string) => void;

  getQr: () => void;
  listenQr: (listener: null | ((uploadId: string) => void)) => void;

  startTranscript: (transcriptId: string) => void;
  getTranscript: (transcriptId: string) => void;
  resetTranscript: (transcriptId: string) => void;

  startTask: (name: string, input: Record<string, any>) => void;
  // getTask: (id: string) => void;
  cancelTask: (id: string) => void;
}>();

export const useSourceUploadContext = () =>
  useProtectedContext(SourceUploadStoreContext);

export const SourceUploadContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const [reconnect, setReconnect] = useState(0);
  const webSocket = useRef<any>(null);
  const userDetails = useSelector(selectUserDetails);
  const queryClient = useQueryClient();
  const qrListenerRef = useRef<((id: string) => void) | null>(null);
  const refetchSubscription = useSubscriptionRefetch();
  const role = useRole();
  const timeoutMap = useRef(new Map<string, ReturnType<typeof setTimeout>>());

  const store = useSourceUploadStore();

  const resetUpload = useCallback((uploadId: string) => {
    store.setState((state) => {
      delete state.data[uploadId];
    });
    webSocket.current.send(
      JSON.stringify({
        method: "delete",
        params: { upload_id: uploadId },
      })
    );
  }, []);

  const getQr = useCallback(() => {
    webSocket.current.send(
      JSON.stringify({
        method: "create_qr",
      })
    );
  }, []);

  const listenQr = useCallback(
    (listener: null | ((uploadId: string) => void)) => {
      qrListenerRef.current = listener;
    },
    []
  );

  const resetTranscript = useCallback((sourceId: string) => {
    store.setState((state) => {
      delete state.transcript[sourceId];
    });
    webSocket.current.send(
      JSON.stringify({
        method: "delete_transcript",
        params: { transcript_id: sourceId },
      })
    );
  }, []);

  const startTranscript = useCallback((sourceId: string) => {
    store.setState((state) => {
      state.transcript[sourceId] = { progress: 0 };
    });
    webSocket.current.send(
      JSON.stringify({
        method: "start_transcript",
        params: { transcript_id: sourceId },
      })
    );

    ifExistsThen(timeoutMap.current.get(sourceId), (timeout) => {
      clearTimeout(timeout);
      timeoutMap.current.delete(sourceId);
    });
    timeoutMap.current.set(
      sourceId,
      setTimeout(() => {
        store.setState((state) => {
          state.transcript[sourceId].progress = 15;
        });
        timeoutMap.current.delete(sourceId);
      }, 1500)
    );
  }, []);

  const getTranscript = useCallback((sourceId: string) => {
    webSocket.current.send(
      JSON.stringify({
        method: "get_transcript",
        params: { transcript_id: sourceId },
      })
    );
  }, []);

  // tasks
  const startTask = useCallback((name: string, input: Record<string, any>) => {
    webSocket.current.send(
      JSON.stringify({
        method: "start_task",
        params: { task_name: name, task_input: input },
      })
    );
  }, []);

  // const getTask = useCallback((id: string) => {
  //   webSocket.current.send(
  //     JSON.stringify({
  //       method: "get_task",
  //       params: { task_id: id },
  //     })
  //   );
  // }, []);

  const cancelTask = useCallback((id: string) => {
    store.setState((state) => {
      delete state.task[id];
    });
    webSocket.current.send(
      JSON.stringify({
        method: "cancel_task",
        params: { task_id: id },
      })
    );
  }, []);

  const start = useCallback((uploadId: string, name: string, folderId = "") => {
    webSocket.current.send(
      JSON.stringify({
        method: "start",
        params: { upload_id: uploadId, name, folder_id: folderId },
      })
    );
  }, []);

  const get = useCallback((uploadId: string) => {
    webSocket.current.send(
      JSON.stringify({
        method: "get",
        params: { upload_id: uploadId },
      })
    );
  }, []);

  const deleteTempFile = (id: string) => {
    store.setState((state) => {
      delete state.data[id];
    });
  };

  const upload = useCallback(
    ({
      file,
      name = file.name,
      folderId: folder_id,
      resolve = () => {},
      reject = () => {},
    }: {
      file: File;
      name?: string;
      folderId?: string;
      resolve?: (uploadId: string) => void;
      reject?: () => void;
    }): string => {
      const item: SourceUploadProps["data"][string] = {
        folderId: folder_id || null,
        name: name,
        progress: undefined,
        uploadId: false,
        type: mapMime(file.type),
      };
      const temp_id = uuid();
      store.setState((state) => {
        state.data[temp_id] = item;
      });

      postSourceAsync({ file, name, folder_id })
        .then(({ upload_id }) => {
          start(upload_id, name, folder_id);
          store.setState((state) => {
            state.data[temp_id].uploadId = upload_id;
            const { uploadId, ...item_ } = item;
            state.data[upload_id] = item_;
          });
          resolve?.(upload_id);
          timeoutMap.current.set(
            upload_id,
            setTimeout(() => {
              store.setState((state) => {
                state.data[upload_id].progress = 15;
              });
              timeoutMap.current.delete(upload_id);
            }, 1500)
          );
        })
        .catch((e) => {
          console.error(e);
          toast.error(t("sourcesPage.status.uploading.error"));

          store.setState((state) => {
            delete state.data[temp_id];
          });
          reject();
        });

      return temp_id;
    },
    []
  );

  useEffect(() => {
    if (!userDetails.id) return;
    const token = localStorage.getItem(AccessToken);

    const socketUrl = `${wsUrl}/source?token=${token}`;

    webSocket.current = new WebSocket(socketUrl);

    webSocket.current.onopen = () => {
      store.setState((state) => {
        state.status = WSStatus.Connected;
      });

      webSocket.current.send(
        JSON.stringify({
          method: "list_tasks",
        })
      );
    };

    webSocket.current.onreconnect = () => {
      store.setState((state) => {
        state.status = WSStatus.Reconnecting;
      });
      JSON.stringify({
        method: "list_tasks",
      });
    };

    const close = (e) => {
      console.log("onclose callback", e);
      store.setState((state) => {
        state.status = WSStatus.Disconnected;
      });
    };

    webSocket.current.onclose = (e) => {
      close(e);
      setTimeout(() => {
        setReconnect((v) => v + 1);
      }, 1000);
    };

    webSocket.current.onerror = (e) => {
      console.log(e);
    };

    webSocket.current.onmessage = (e) => {
      const data = JSON.parse(e.data);
      // handle task updates
      if (data.tasks) {
        store.setState((state) => {
          data.tasks.forEach(({ task_id, task_input, progress, task_name }) => {
            state.task[task_id] = {
              input: task_input,
              progress: progress,
              name: task_name,
            };
          });
        });
        return;
      }
      if (data.task) {
        const id = data.task.task_id;

        store.setState((state) => {
          const item: SourceUploadProps["task"][string] = {
            ...state.task?.[id],
            name: data.task.task_name,
            input: data.task.task_input,
            progress: data.task.progress,
          };
          if (data.task.error) {
            refetchSubscription();
            item.error = data.task.error;
            if (data.task.error === "NO_CREDITS_LEFT")
              if (role.pro) toast.error(t("v4.subscriptions.creditsErrorPro"));
              else toast.error(t("v4.subscriptions.creditsError"));
            else
              toast.error(translateError(t("v4.training.generate.error"), t));
          } else if (data.task.result) {
            item.data = { id: data.task.result.id };
            if (data.task?.task_input?.training_id) {
              queryClient.invalidateQueries([
                "course",
                data.task.task_input.training_id,
              ]);
            }
            refetchSubscription();
            toast.success(t("v4.training.generate.success"));
            queryClient.invalidateQueries(["courses"]);
          }
          state.task[id] = item;
        });
        return;
      }

      // handle transcript updates
      // transcript_id -> sourceId
      // transcription_id -> transcriptionId
      if (data.transcript) {
        const id = data.transcript.transcript_id;

        store.setState((state) => {
          const item: SourceUploadProps["transcript"][string] = {
            ...state.transcript?.[id],
            progress: data.transcript.progress,
          };

          if (
            data.transcript.error ||
            data?.transcript?.result ||
            data?.transcript?.progress > 0.15
          ) {
            ifExistsThen(timeoutMap.current.get(id), (timeout) => {
              clearTimeout(timeout);
              timeoutMap.current.delete(id);
            });
          }

          if (data.transcript.error) {
            item.error = data.transcript.error;
          } else if (data.transcript.result) {
            item.data = {
              text: data.transcript.result.text,
            };
            queryClient.invalidateQueries(["source", id]);
          }
          state.transcript[id] = item;
        });
        return;
      }

      // handle upload updates
      if (data.upload) {
        const id = data.upload.upload_id;
        const error = data.upload.result?.error;

        store.setState((state) => {
          const item: SourceUploadProps["data"][string] = {
            ...state.data?.[id],
            folderId: data.upload.folder_id,
            name: data.upload.name,
            progress: data.upload.progress,
          };

          if (error || data?.upload?.result || data?.upload?.progress > 0.15) {
            ifExistsThen(timeoutMap.current.get(id), (timeout) => {
              clearTimeout(timeout);
              timeoutMap.current.delete(id);
            });
          }

          if (error) {
            item.error = error;
          } else if (data.upload.result) {
            item.data = {
              id: data.upload.result.id,
              name: data.upload.result.name,
            };
            queryClient.invalidateQueries([
              "library",
              data.upload.folder_id || "",
            ]);
          }
          state.data[id] = item;
        });
      }

      // handle qr code
      if (data.qr_code) {
        if (data.qr_code.status === "consumed") {
          getQr();
          const uploadId = data?.qr_code?.upload?.upload_id;
          // if there's an uploadId, get upload data
          if (uploadId) {
            start(uploadId, data.qr_code.upload.name || t("v4.library.source"));
            // if listener is active, send the uploadId to listener
            if (qrListenerRef.current) {
              qrListenerRef.current(uploadId);
            }
          }
        } else {
          const code = data.qr_code.code;
          store.setState((state) => {
            state.qr = code;
          });
        }
      }
    };

    return () => {
      webSocket.current.onclose = (e) => close(e);
      webSocket.current.close();
    };
  }, [userDetails.id, reconnect]);

  return (
    <SourceUploadStoreContext.Provider
      value={{
        upload,
        deleteTempFile,
        get,
        startTranscript,
        getTranscript,
        resetUpload,
        resetTranscript,
        getQr,
        listenQr,
        startTask,
        // getTask,
        cancelTask,
      }}
    >
      {children}
    </SourceUploadStoreContext.Provider>
  );
};
