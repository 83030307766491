import {
  MultipleChoice,
  MultipleChoiceGenerate,
  PageItemType,
  Paragraph,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { postMultipleChoice } from "api/course/courseContentAPI";
import { uuid } from "app/components/Exercises/utils/uuid";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { SkeletonParagraph } from "app/components/Exercises/CourseEdit/components/SkeletonParagraph";
import { textToParagraphs } from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { usePageGenerate } from "app/components/Exercises/CourseEdit/components/PageGenerate";

export const SkeletonMultipleChoice = () => (
  <div className="leading-relaxed">
    <SkeletonParagraph min={10} max={20} />
    <div className="bg-gray-100 rounded-lg p-1.5 flex flex-col gap-1 mt-4">
      {[...new Array(4)].map((_, i) => (
        <div key={i} className="bg-white rounded-lg p-1.5 flex items-center">
          <div className="rounded-full w-7 h-7 bg-gray-200 shrink-0" />
          <div className="px-2 py-1 grow">
            <SkeletonParagraph min={3} max={8} />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export const PageMultipleChoiceGenerate = () => {
  usePageGenerate(
    (item: MultipleChoiceGenerate) => postMultipleChoice(item.data, 1),
    ({ items }) => {
      const newList: MultipleChoice[] = items.map(({ question, answers }) => {
        const paragraph: Paragraph = {
          id: uuid(),
          type: PageItemType.Paragraph,
          category: "content",
          align: "left",
          data: textToParagraphs(question.text),
        };
        const exercise: MultipleChoice = {
          id: uuid(),
          type: PageItemType.MultipleChoice,
          category: "quiz",
          multi: false,
          instructions: [paragraph],
          items: answers.map<MultipleChoice["items"][number]>(
            ({ isCorrect, text }) => ({
              id: uuid(),
              correct: isCorrect,
              text,
            })
          ),
        };
        return exercise;
      });
      return newList;
    }
  );

  return (
    <PageItemWrapper>
      <SkeletonMultipleChoice />
    </PageItemWrapper>
  );
};
