import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getAllMyOrganizations,
  putRemoveOrganization,
} from "../../../api/organizationsAPI";
import Loader from "../../components/Loader";
import Navigation from "./Navigation";
import React, { useEffect, useState } from "react";
import { Button } from "../../components/_styled/Button";
import NewOrganization from "./NewOrganization";
import { ScTableRow } from "../../components/Tables/ExercisesLibraryHome/TableRowNew";
import { InputCheckboxControlled } from "../../components/Buttons/InputCheckbox";
import SearchBar from "../../components/SearchBar";
import { useSelector } from "react-redux";
import { selectSearchPhase } from "../../store/exercise-library-user";
import { Link } from "react-router-dom";
import { adminRoutes } from "../../../enums/routes";
import { useDebounce } from "../../hooks/useDebounce";
import { toast } from "react-hot-toast";

const AdminOrganizations = () => {
  const queryClient = useQueryClient();
  const search = useSelector(selectSearchPhase);
  const [newOrg, setNewOrg] = useState(false);
  const [searchPhase, setSearchPhase] = useState("");
  const [selectedOrg, setSelectedOrg] = useState<any>([]);
  const debounce = useDebounce((e) => setSearchPhase(e.substring(0, 50)), 500);

  useEffect(() => {
    debounce(search);
  }, [search]);

  const { data, isSuccess, isLoading } = useQuery(
    "organizations",
    () => getAllMyOrganizations(),
    { refetchOnWindowFocus: false }
  );

  const deleteOrg = useMutation(
    "deleteOrg",
    (org: string) => putRemoveOrganization({ organizations: [org] }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("organizations");
        setSelectedOrg([]);
        toast.success("Success");
      },
      onError: () => {
        toast.error("Ooops! Something went wrong");
      },
    }
  );

  const handleSelectOrg = (org) => {
    const orgIdExist = selectedOrg.some((o) => o.id === org.id);
    if (orgIdExist) {
      const index = selectedOrg.findIndex((o) => o.id === org.id);
      if (index > -1) {
        setSelectedOrg(selectedOrg.filter((o) => o.id !== org.id));
      }
    } else {
      setSelectedOrg([...selectedOrg, org]);
    }
  };

  const handleDeleteOrg = (org) => {
    if (
      window.confirm(`Are you sure you want remove ${selectedOrg[0].name}?`)
    ) {
      const prompt = window.prompt("To remove enter org name", "org name");
      if (prompt === selectedOrg[0].name) {
        deleteOrg.mutate(org);
      } else {
        toast.error("Entered name invalid");
      }
    }
  };

  return (
    <section>
      <div>
        <SearchBar />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Navigation />
        </div>
        <div style={{ display: "flex" }}>
          <Button clickAction={() => setNewOrg(true)}>Create new org</Button>
          <Button
            disabled={selectedOrg.length !== 1}
            clickAction={() => {
              handleDeleteOrg(selectedOrg[0].id);
            }}
          >
            Delete org
          </Button>
        </div>
      </div>
      <ScTableWrapper>
        {isLoading ? (
          <Loader />
        ) : isSuccess ? (
          data.map((item) => {
            if (
              search !== undefined &&
              item.name.toLowerCase().includes(search.toLowerCase())
            ) {
              return (
                <TableRow key={item.id}>
                  <div style={{ display: "flex" }}>
                    <InputCheckboxControlled
                      value={selectedOrg.some((org) => org.id === item.id)}
                      toogleValue={() => handleSelectOrg(item)}
                      id={item.id}
                    />
                    {item.name}
                  </div>
                  <Link to={adminRoutes.admin_organization(item.id)}>
                    <button>Details</button>
                  </Link>
                </TableRow>
              );
            }
          })
        ) : (
          <Loader />
        )}
      </ScTableWrapper>
      <NewOrganization isOpen={newOrg} close={() => setNewOrg(false)} />
    </section>
  );
};
export default AdminOrganizations;

const ScTableWrapper = styled.div`
  box-sizing: border-box;
  background-color: white;
  border-radius: 5px;
  margin: 40px auto;
  width: 100%;
  max-width: 1264px;
  @media screen and (max-width: 1000px) {
    padding: 0 12px;
  }
  @media screen and (max-width: 500px) {
    padding: 0 8px;
  }
`;

const TableRow = styled(ScTableRow)`
  justify-content: space-between;
  &:hover {
    background: #f1f1f1;
  }
`;
