function urlParametersAsObj() {
  let params = {};
  if (window.location.search !== "") {
    params = JSON.parse(
      '{"' +
        decodeURI(window.location.search.substring(1))
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  }
  return params;
}

export default urlParametersAsObj;
