import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import smartestLogo from "../../../assets/images/s-02.png";
import { useParams } from "react-router";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import uriToBlob from "data-uri-to-blob";
import { toast } from "react-hot-toast";
import { ACCEPTED_FILE_TYPES, FileType, mapMime } from "helpers/mimeType";
import { Cropper, CropperRef } from "react-mobile-cropper";
import "react-mobile-cropper/dist/style.css";
import {
  PrimaryButton,
  SecondaryButton,
} from "app/components/Buttons/GenericButtons";
import { postFileWithCode } from "api/sourcesAPI";
import { FILE_SIZE_LIMIT } from "helpers/file";
import { SourcePreview } from "app/components/Forms/SourceForm";
import { GlobalFlexStyles } from "app/components/Helpers/GlobalFlexStyles";

const UploadButton = styled.div`
  background-color: var(--primary-color);
  color: var(--main-white);
  border-radius: 1rem;
  border: 0;
  outline: none;
  padding: 3rem 2rem;
  font-size: 1.2rem;
  cursor: pointer;
  text-align: center;
`;

const ScToast = styled.div`
  color: var(--success-main);
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
`;

const Upload = () => {
  const { t } = useTranslation();
  const params: any = useParams();
  const id = params.id;
  const [file, setFile] = useState<File | null>(null);
  const [isEditOpen, setEditOpen] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const [success, setSuccess] = useState(false);

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    e.target.value = "";
    e.target.files = null;

    if (!file) {
      setFile(null);
      return;
    }

    if (file.size > FILE_SIZE_LIMIT) {
      setFile(null);
      toast.error(t("sourcesPage.status.uploading.errorSize"));
      return;
    }

    const mimeType = !!file?.type && mapMime(file.type);
    if (
      typeof mimeType !== "number" ||
      ![
        FileType.Document,
        FileType.Image,
        FileType.Audio,
        FileType.Video,
      ].includes(mimeType)
    ) {
      setFile(null);
      toast.error(t("sourcesPage.uploadNew.uploadTypeError"));
      return;
    }

    setFile(file);
    if (mimeType === FileType.Image) setEditOpen(true);
  };

  const uploadFile = async (file: File, name: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("code", id);
    formData.append("name", name);

    await postFileWithCode(formData);
    // if (user.id !== null) {
    //   dispatch(
    //     fetchSources({
    //       page: page,
    //       sortParam: convertSortBy(sorting[0]),
    //     })
    //   );
    // }
    setSuccess(true);
  };

  const handleSave = (src: string) => {
    if (!file) return;
    const newFile = new File([uriToBlob(src)], file.name, {
      type: file.type,
      lastModified: file.lastModified,
    });
    setFile(newFile);
    setEditOpen(false);
  };

  const handleCancel = () => {
    setEditOpen(false);
    setFile(null);
    if (fileRef.current) {
      fileRef.current.value = "";
      fileRef.current.files = null;
    }
  };

  if (isEditOpen)
    return (
      <ImageEditor file={file} onCancel={handleCancel} onSave={handleSave} />
    );

  return (
    <div className="absolute bg-white flex justify-center items-center flex-col p-16 w-full h-full">
      <GlobalFlexStyles />
      {success ? (
        <ScToast>
          {t("sourcesPage.status.uploading.success")}
          <div
            style={{
              color: "black",
              marginTop: "1rem",
              fontSize: "1rem",
            }}
          >
            {t("sourcesPage.status.uploading.success_mobile")}
          </div>
        </ScToast>
      ) : !file ? (
        <div className="flex flex-col items-center text-center justify-center">
          <label>
            <input
              type="file"
              capture
              accept={ACCEPTED_FILE_TYPES.join(",")}
              onChange={handleFile}
              hidden={true}
              ref={fileRef}
            />
            <UploadButton>
              {t("sourcesPage.uploadNew.tapToUploadBtn")}
            </UploadButton>
          </label>
          {/*<div className="text-gray-400 font-bold text-sm mt-8">*/}
          {/*  {t("sourcesPage.uploadNew.extensions")}*/}
          {/*  {*/}
          {/*    " pdf, png, jpg, gif, heic, heif, pdf, doc, docx, ppt, pptx, odt, odp"*/}
          {/*  }*/}
          {/*</div>*/}
          <div className="text-gray-400 font-bold text-sm mt-4">
            {t("sourcesPage.fileLimits")}
          </div>
        </div>
      ) : (
        <SourcePreview
          {...{ file, setFile }}
          onUpload={uploadFile}
          onCancel={handleCancel}
        />
      )}
      <img className="w-8 mt-20" src={smartestLogo} />
    </div>
  );
  /*
  return (
    <div>
      <ScTableDiv>
        <ScRow>
          <ScCell id="upload-form" className="cell-div">
            {!file ? (
              uploadStatus !== UploadStatus.Success && (
                <label>
                  <input
                    type="file"
                    accept={[
                      "image/*",
                      ".heic",
                      ".heif",
                      MIME.pdf,
                      MIME.doc,
                      MIME.docx,
                      MIME.ppt,
                      MIME.pptx,
                      MIME.odt,
                      MIME.odp,
                    ].join(",")}
                    onChange={handleFile}
                    hidden={true}
                    disabled={isLoading}
                    ref={fileRef}
                  />
                  {uploadStatus === UploadStatus.Loading ? (
                    <Loader light />
                  ) : (
                    t("sourcesPage.uploadNew.tapToUploadBtn")
                  )}
                </label>
              )
            ) : (
              <SourcePreview
                {...{ file, setFile }}
                onUpload={console.log}
              />
            )}
            <ScToast>
              {uploadStatus === UploadStatus.Success && (
                <>
                  {t("sourcesPage.status.uploading.success")}
                  <div
                    style={{
                      color: "black",
                      marginTop: "20px",
                      fontSize: "16px",
                    }}
                  >
                    {t("sourcesPage.status.uploading.success_mobile")}
                  </div>
                </>
              )}
            </ScToast>
            <img alt="smartestLogo" src={smartestLogo} />
          </ScCell>
        </ScRow>
      </ScTableDiv>
    </div>
  );*/
};

export default Upload;

const ImageEditor = ({ file, onCancel, onSave, isLoading = false }) => {
  const ref = useRef<CropperRef>();
  const { t } = useTranslation();
  const [image, setImage] = useState<any>(null);

  useEffect(() => {
    if (!file) return;
    const blob = URL.createObjectURL(file);
    setImage({
      src: blob,
      type: file.type,
    });

    return () => {
      if (image && image.src) {
        URL.revokeObjectURL(image.src);
      }
    };
  }, [file]);

  const handleSave = () => {
    if (!ref.current) return;
    onSave(ref.current.getCanvas()?.toDataURL());
  };

  return (
    <CropperContainer disabled={isLoading}>
      <div style={{ flexGrow: 1, position: "relative" }}>
        <StyledCropper ref={ref} src={image && image.src} />
      </div>
      <StyledToolbar>
        <SecondaryButton
          style={{ padding: "0.5rem 1rem", marginRight: "0.5rem" }}
          onClick={onCancel}
        >
          {t("common.cancel")}
        </SecondaryButton>
        <PrimaryButton
          style={{ padding: "0.5rem 1rem", marginLeft: "auto" }}
          onClick={handleSave}
          disabled={isLoading}
        >
          {isLoading && (
            <i
              className="fas fa-spinner fa-spin"
              style={{ marginRight: "0.5rem" }}
            />
          )}
          {t("common.save")}
        </PrimaryButton>
      </StyledToolbar>
    </CropperContainer>
  );
};

const CropperContainer = styled.div<{ disabled: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background-color: black;
  > div:first-of-type {
    pointer-events: ${(p) => p.disabled && "none"};
    opacity: ${(p) => p.disabled && "0.7"};
    transition: opacity 0.2s ease;
  }
`;

const StyledCropper = styled(Cropper)`
  height: 100%;
  max-height: calc(100vh - 130px);
  color: rgb(var(--brand-color));
  z-index: 2;
`;

const StyledToolbar = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  flex-shrink: 0;
  z-index: 1;
`;
