import React, { useEffect, useState } from "react";
import Notifications from "./Notifications";
import { useNotifications } from "../../../api/ws/notificationsWS";
import { toast } from "react-hot-toast";
import { BiBell } from "react-icons/bi";
import { FloatingMenu, HeaderIconButton } from "../Header";

const NotificationButton = () => {
  const [notificationCounter, setNotificationCounter] = useState(0);

  const { unread, notifications, handleReadNotifications } = useNotifications();

  useEffect(() => {
    setNotificationCounter(unread.length);
  }, [unread.length]);

  return (
    <FloatingMenu
      size="xs"
      onSetOpen={(open) => {
        if (open) setNotificationCounter(0);
        else handleReadNotifications();
      }}
      trigger={(toggle) => (
        <HeaderIconButton
          onClick={() => {
            toggle();
            toast.dismiss();
          }}
        >
          <BiBell />
          {!!notificationCounter && (
            <div className="flex w-4 h-4 text-[0.6rem] leading-none items-center justify-center text-white bg-primary font-bold absolute right-1 top-1 rounded-full">
              {notificationCounter}
            </div>
          )}
        </HeaderIconButton>
      )}
      className="w-80 space-y-1 max-h-[80vh] overflow-y-auto"
    >
      {({ setIsOpen }) => (
        <Notifications close={() => setIsOpen(false)} list={notifications} />
      )}
    </FloatingMenu>
  );
};

export default NotificationButton;
