import React, { ReactElement, ReactNode } from "react";
import { FloatingMenu } from "./Header";
import { NewButton } from "./Buttons/NewButton";
import { TbDotsVertical } from "react-icons/tb";
import { CgSpinner } from "react-icons/cg";

export interface OptionItemType {
  icon?: ReactElement;
  text: string;
  disabled?: boolean;
  onClick?: (data: any) => void;
  color?: string;
  loading?: boolean;
}

export const ContextMenu = ({
  options,
  buttonClassName,
  className,
  open,
  disablePortal = false,
  trigger,
  children,
  handleToggle,
  strategy,
}: {
  options: OptionItemType[][];
  buttonClassName?: string;
  className?: string;
  open?: boolean;
  disablePortal?: boolean;
  trigger?: (toggle: () => void) => ReactElement;
  children?: (props: {
    setIsOpen: (value: boolean) => void;
    content: ReactElement;
  }) => ReactNode;
  handleToggle?: (toggle: () => void) => () => void;
  strategy?: any;
}) => (
  <FloatingMenu
    placement="bottom-end"
    size="xs"
    open={open}
    className={className}
    portal={!disablePortal}
    strategy={strategy}
    trigger={(t_: () => void) => {
      const toggle = handleToggle ? handleToggle(t_) : t_;
      if (trigger) return trigger(toggle);
      return (
        <NewButton
          color="bg-gray-400 text-gray-400"
          iconOnly
          variant="transparent"
          component="button"
          onClick={toggle}
          className={buttonClassName}
        >
          <TbDotsVertical />
        </NewButton>
      );
    }}
  >
    {({ setIsOpen }) => {
      const content = (
        <div className="flex flex-col space-y-1 divide-gray-100 divide-y">
          {options.map((group) => {
            if (group.every((item) => item?.disabled)) return null;
            return (
              <div
                key={group.map((i) => i.text).join("-")}
                className="flex flex-col pointer-events-auto space-y-1 [&:not(:first-of-type)]:pt-1"
              >
                {group.map((item) => {
                  if (item?.disabled) return null;

                  return (
                    <NewButton
                      key={item.text}
                      color={item.color || undefined}
                      variant="transparent"
                      className="text-left"
                      onClick={(e) => {
                        e.preventDefault();
                        if (item?.loading) return;
                        item.onClick?.({ setIsOpen });
                      }}
                    >
                      {item?.loading ? (
                        <CgSpinner className="animate-spin" />
                      ) : (
                        item?.icon
                      )}{" "}
                      {item.text}
                    </NewButton>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
      if (children) return children({ setIsOpen, content });
      return content;
    }}
  </FloatingMenu>
);
