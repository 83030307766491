import { createStore, useStore } from "zustand";
import { immer } from "zustand/middleware/immer";
import produce, { Draft } from "immer";
import {
  Columns,
  ConsumptionType,
  ContentItem,
  CourseItem,
  Item,
  Page,
  PageItemType,
  Paragraph,
  PollItem,
  QuizItem,
  SlideItem,
} from "./courseEditTypes";
import {
  CreateProtectedContext,
  useProtectedContext,
} from "app/hooks/useProtectedContext";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { getCoursePage } from "api/course/courseAPI";
import objectHash from "object-hash";

interface PageProps {
  data: null | Page;
  hash: null | string;
  loading: boolean;
}

interface PageState extends PageProps {
  load: (data: PageProps["data"]) => void;
  clear: () => void;
  setLoading: (loading: boolean) => void;
  setName: (name: string) => void;
  updateHash: (page?: Page) => void;
  setPageItem: (
    index: number
  ) => <T = Item>(fun: (item: Draft<T>) => void) => void;
  setList: (
    fun: (items: Draft<(CourseItem | SlideItem | PollItem)[]>) => void
  ) => void;
  set: <T extends Page = Page>(fun: (page: Draft<T>) => void) => void;
}

type PageStore = ReturnType<typeof createPageStore>;

/**
 * Function to transform the course schema based on the version
 */
export const updatePageSchema = produce((page: Page) => {
  // @ts-ignore
  if (page.quiz_type) {
    // @ts-ignore
    page.quizType = page.quiz_type;
    // @ts-ignore
    delete page.quiz_type;
  }

  if (!page?.items) return;

  // change paragraphs
  if (page.version === 0) {
    page.version = 1;
    const nonEmptyParagraph = (page.items as Item[]).find(
      (item) =>
        item.category === "content" &&
        item.type === PageItemType.Paragraph &&
        item.data.length > 0
    ) as Paragraph | undefined;

    if (nonEmptyParagraph && !Array.isArray(nonEmptyParagraph.data[0])) {
      for (const item of page.items) {
        if (
          item.category === "content" &&
          item.type === PageItemType.Paragraph
        ) {
          // @ts-ignore
          item.data = [item.data];
        }
      }
    }
  }
  if (page.version === 1) {
    page.version = 2;
    for (const item of page.items) {
      if (item.category === "quiz" && !item.instructions) {
        // @ts-ignore
        item.instructions = [];
      }
    }
  }
  if (page.version === 2) {
    page.version = 3;
    for (const item of page.items) {
      if (item.category === "content" && item.type === PageItemType.Embed) {
        // @ts-ignore
        item.data = item?.src || item.data;
        // @ts-ignore
        delete item.src;
      }
    }
  }
  if (page.version === 3) {
    // remove slide placeholders
    page.version = 4;
    for (let i = page.items.length - 1; i >= 0; i--) {
      const item = page.items[i];
      // @ts-ignore
      if (item.category === "slide" && item.type === PageItemType.Placeholder)
        page.items.splice(i, 1);
    }
  }
  if (page.version === 4) {
    // add showOptions = false to FTG/labeling
    page.version = 5;
    for (const item of page.items) {
      if (
        item.category === "quiz" &&
        (item.type === PageItemType.ImageLabeling ||
          item.type === PageItemType.FillTheGap)
      ) {
        item.showOptions = item?.showOptions ?? false;
      }
    }
  }
  if (page.version === 5) {
    page.version = 6;
    // remove short answer
    if (page.category === "quiz") {
      page.items = page.items.filter(
        (item) =>
          item.category !== "quiz" || item.type !== PageItemType.ShortAnswer
      );
    }
  }
  if (page.version === 6) {
    // mcq options
    page.version = 7;
    for (const item of page.items) {
      if (item.category !== "quiz") continue;
      switch (item.type) {
        case PageItemType.ImageLabeling: {
          item.consumptionType = ConsumptionType.strictTypeTheAnswer;
          return;
        }
        case PageItemType.FillTheGap: {
          item.consumptionType = ConsumptionType.strictTypeTheAnswer;
          return;
        }
      }
    }
  }
  if (page.version === 7) {
    // convert headings
    page.version = 8;

    const convertHeading = (item: any) => {
      if (item.type === PageItemType.Heading) {
        item.type = PageItemType.Paragraph;
        item.data = item.data
          .split(/\r?\n/)
          .map((text) => [{ text, size: 2, bold: true }]);
      }
    };

    const convertPageParagraph = (item: CourseItem) => {
      if (
        item.type === PageItemType.Paragraph ||
        item.type === PageItemType.List
      ) {
        for (const paragraph of item.data)
          for (const leaf of paragraph) {
            if ("text" in leaf) leaf.serif = true;
          }
      }
    };

    for (const item of page.items) {
      if (page.category === "content") convertPageParagraph(item as any);
      convertHeading(item);
      if ("instructions" in item) {
        for (const instructionsItem of item.instructions) {
          convertHeading(instructionsItem);
        }
      }
    }
  }
});

export const createPageStore = (initial?: Partial<PageProps>) => {
  const DEFAULT_PROPS: PageProps = {
    data: null,
    hash: null,
    loading: false,
  };
  return createStore<PageState>()(
    immer((set) => ({
      ...DEFAULT_PROPS,
      ...initial,

      load: (data: PageProps["data"]) =>
        set((state) => {
          const page = data ? updatePageSchema(data) : data;
          state.data = page;
          state.hash = objectHash(page);
        }),
      clear: () =>
        set((state) => {
          state.data = null;
          state.hash = null;
        }),
      setLoading: (loading: boolean) =>
        set((state) => {
          state.loading = loading;
        }),
      setName: (name: string) =>
        set((state) => {
          if (!state.data || state.data.category === "slide") return;
          state.data.name = name;
        }),
      updateHash: (page?: Page) =>
        set((state) => {
          state.hash = objectHash(page ?? state.data);
        }),
      setPageItem: (index: number) =>
        function <T = Item>(fun: (item: Draft<T>) => void) {
          set((state) => {
            if (!state.data?.items?.[index]) return;
            const item = state.data.items[index];
            fun(item as Draft<T>);
          });
        },
      setList: (
        fun: (item: Draft<(CourseItem | SlideItem | PollItem)[]>) => void
      ) =>
        set((state) => {
          if (!state.data?.items) return;
          fun(state.data.items);
        }),
      set: <T extends Page = Page>(fun: (item: Draft<T>) => void) =>
        set((state) => {
          if (!state.data) return;
          // @ts-ignore
          fun(state.data);
        }),
    }))
  );
};

export const PageStoreContext = CreateProtectedContext<PageStore>();

type PageProviderProps = PropsWithChildren<{
  courseId?: string;
  pageId?: string;
}>;

export const usePageStore = () => useProtectedContext(PageStoreContext);

export const PageContextProvider = ({
  children,
  courseId,
  pageId,
}: PageProviderProps) => {
  const storeRef = useRef<PageStore>();
  if (!storeRef.current) {
    storeRef.current = createPageStore();
  }
  const paramsRef = useRef<any>();
  paramsRef.current = { courseId, pageId };
  const store = storeRef.current;
  const { load, clear } = useStore(store, ({ load, clear }) => ({
    load,
    clear,
  }));

  useEffect(() => {
    if (!courseId || !pageId) return;
    getCoursePage(courseId, pageId).then((data) => {
      if (
        courseId === paramsRef.current.courseId &&
        pageId === paramsRef.current.pageId
      ) {
        load(data);
      }
    });
    return () => {
      clear();
    };
  }, [courseId, pageId]);

  return (
    <PageStoreContext.Provider value={store}>
      {children}
    </PageStoreContext.Provider>
  );
};

export const usePageContext = <T,>(
  selector: (state: PageState) => T,
  equalityFn?: (left: T, right: T) => boolean
): T => {
  const storeRef = usePageStore();
  return useStore(storeRef, selector, equalityFn);
};

/**
 * Context to inform child components about them being in an instruction field
 */
export const InstructionContentContext = createContext<boolean>(false);
export const useIsInstructionContent = () =>
  useContext(InstructionContentContext);
export const InstructionContentContextProvider = ({ children }) => (
  <InstructionContentContext.Provider value={true}>
    {children}
  </InstructionContentContext.Provider>
);

/**
 * Context to inform child component about them being in a column
 */
export const ColumnContentContext = createContext<string>("");
export const useIsColumnContent = () => !!useContext(ColumnContentContext);
export const ColumnContentContextProvider = ({ children, id = "" }) => (
  <ColumnContentContext.Provider value={id}>
    {children}
  </ColumnContentContext.Provider>
);

/**
 * Hook to get the item list - depending on whether the current component is in a column, in instructions, or in the top level course layer
 */
export const useGetList = () => {
  const isInstructions = useContext(InstructionContentContext);
  const column = useContext(ColumnContentContext);
  const pageStore = usePageStore();

  if (column) {
    const id = useTopLevelIdContext();
    const item = useStore(
      pageStore,
      (store) => (store.data!.items as CourseItem[]).find((i) => i.id === id)!
    ) as Columns;
    const list = item.items.find((c) => c.id === column)?.data!;
    const ref = useRef(list);
    ref.current = list;

    return useCallback(() => {
      return ref.current;
    }, []);
  }

  if (isInstructions) {
    const id = useTopLevelIdContext();
    const { instructions } = useStore(
      pageStore,
      (store) => (store.data!.items as QuizItem[]).find((i) => i.id === id)!
    );
    const ref = useRef(instructions);
    ref.current = instructions;

    return useCallback(() => {
      return ref.current;
    }, []);
  }

  return useCallback(() => {
    return pageStore.getState().data?.items as CourseItem[];
  }, []);
};

/**
 * Hook to retrieve the correct setter for a component list - depending whether the item is in instructions, columns or top level
 */
export const useSetList = () => {
  const setList = usePageContext((state) => state.setList);
  const isInstructions = useIsInstructionContent();
  const column = useContext(ColumnContentContext);

  if (column) {
    const index = useTopLevelIndexContext();
    const store = usePageStore();
    const setData_ = useStore(store, (store) => store.setPageItem);
    return useCallback(
      (gen: (draft: Draft<ContentItem[]>) => void) => {
        setData_(index)((item: Columns) => {
          const itemIndex = item.items.findIndex((c) => c.id === column);
          if (itemIndex < 0) return;
          gen(item.items[itemIndex].data);
        });
      },
      [setData_, index]
    );
  }

  if (isInstructions) {
    const index = useTopLevelIndexContext();
    const store = usePageStore();
    const setData_ = useStore(store, (store) => store.setPageItem);
    return useCallback(
      (gen: (draft: Draft<ContentItem[]>) => void) => {
        setData_(index)((item: QuizItem) => {
          item.instructions = item?.instructions ?? [];
          gen(item.instructions);
        });
      },
      [setData_, index]
    );
  }

  return setList;
};

export const PageItemIndexContext = CreateProtectedContext<[number, string]>();
export const usePageItemIndex = () =>
  useProtectedContext(PageItemIndexContext)[0];
export const usePageItemId = () => useProtectedContext(PageItemIndexContext)[1];

export const TopLevelIndexContext = CreateProtectedContext<[number, string]>();
export const useTopLevelIndexContext = () =>
  useProtectedContext(TopLevelIndexContext)[0];
export const useTopLevelIdContext = () =>
  useProtectedContext(TopLevelIndexContext)[1];

export function useGetPageItem<T extends Item = Item>() {
  const id = usePageItemId();
  const getList = useGetList();

  return useCallback(() => {
    return getList().find((item) => item.id === id) as T;
  }, [id]);
}

/**
 * Getting page item data depending on which context they are in (instruction/column/top level)
 */
export function usePageItemContext<T extends Item = Item>() {
  const index = useTopLevelIndexContext();
  const id = useTopLevelIdContext();

  const store = usePageStore();
  const isInstructionContent = useContext(InstructionContentContext);
  const column = useContext(ColumnContentContext);

  if (column) {
    const subIndex = usePageItemIndex();
    const subId = usePageItemId();

    const data = useStore(
      store,
      (store) =>
        (store.data!.items as Columns[])
          .find((i) => i.id === id)!
          .items.find((c) => c.id === column)!
          .data.find((i) => i.id === subId)!
    );
    const setData_ = useStore(store, (store) => store.setPageItem);
    const setData = useCallback(
      (gen: (draft: Draft<T>) => void) => {
        setData_(index)((item: Columns) => {
          const itemIndex = item.items.findIndex((c) => c.id === column);
          // @ts-ignore
          gen(item.items[itemIndex].data[subIndex]);
        });
      },
      [setData_, index, subIndex]
    );
    return [
      data as T,
      setData as <G = T>(fun: (item: Draft<G>) => void) => void,
    ] as const;
  }

  if (isInstructionContent) {
    const subIndex = usePageItemIndex();
    const subId = usePageItemId();

    const data = useStore(
      store,
      (store) =>
        (store.data!.items as QuizItem[])
          .find((i) => i.id === id)!
          .instructions.find((i) => i.id === subId)!
    );
    const setData_ = useStore(store, (store) => store.setPageItem);
    const setData = useCallback(
      (gen: (draft: Draft<T>) => void) => {
        setData_(index)((item: QuizItem) => {
          // @ts-ignore
          gen(item.instructions[subIndex]);
        });
      },
      [setData_, index, subIndex]
    );
    return [
      data as T,
      setData as <G = T>(fun: (item: Draft<G>) => void) => void,
    ] as const;
  }

  const data = useStore(
    store,
    (store) => (store.data!.items as QuizItem[]).find((i) => i.id === id)!
  );
  const setData_ = useStore(store, (store) => store.setPageItem);
  const setData = useMemo(() => setData_(index), [setData_, index]);
  return [
    data as T,
    setData as <G = T>(fun: (item: Draft<G>) => void) => void,
  ] as const;
}

// SLIDE CONTEXT
