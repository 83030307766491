import React, { useState } from "react";
import arrow from "../../../../assets/images/arrowRigth.svg";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import {
  putAddUsersToOrganizationGroup,
  putRemoveUsersToOrganizationGroup,
  putUsersRoleToOrganizationGroup,
} from "../../../../api/organizationsAPI";
import Loader from "../../../components/Loader";
import { toast } from "react-hot-toast";
import { InteractiveIcon } from "../../../components/Exercises/Edit/components/ExerciseComponents";
import { Link } from "react-router-dom";
import { adminRoutes } from "../../../../enums/routes";

const ClassDetails = ({ members, admins, id, group_id }) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [userMail, setUserMail] = useState("");
  const [addUserActive, setAddUserActive] = useState(false);

  const add = useMutation(
    "add",
    () => {
      const dataToSave = {
        members: [userMail],
      };
      return putAddUsersToOrganizationGroup(id, group_id, dataToSave);
    },
    {
      onSuccess: (data) => {
        setUserMail("");
        setAddUserActive(false);
        queryClient.invalidateQueries(["groups", id]);
        if (data.added_count < 1) {
          toast.error("User not found or already exist in this group");
        } else {
          toast.success("Success");
        }
      },
      onError: () => {
        setUserMail("");
        setAddUserActive(false);
        toast.error("Something went wrong");
      },
    }
  );

  const remove = useMutation(
    "remove",
    (member_id) => {
      return putRemoveUsersToOrganizationGroup(id, group_id, {
        members: [member_id],
      });
    },
    { onSuccess: () => queryClient.invalidateQueries(["groups", id]) }
  );

  const updateRole = useMutation(
    "update",
    ({ member_id, admin }: { member_id: string; admin: boolean }) => {
      return putUsersRoleToOrganizationGroup(id, group_id, member_id, {
        is_admin: admin,
      });
    },
    { onSuccess: () => queryClient.invalidateQueries(["groups", id]) }
  );

  return (
    <ScClass>
      <ScArrow
        direction={open}
        onClick={() => setOpen(!open)}
        alt="arrow"
        src={arrow}
      />
      {open ? (
        <div style={{ width: "100%" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <strong>Name</strong>
            <strong style={{ marginRight: "60px" }}>Admin</strong>
          </div>
          {updateRole.isLoading || remove.isLoading ? (
            <Loader />
          ) : (
            <>
              {admins.map((item) => {
                return (
                  <div
                    style={{
                      borderBottom: "1px solid #f1f1f1",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                    key={item.id}
                  >
                    <Link to={adminRoutes.admin_user_details(item.id)}>
                      <span>{item.name}</span>
                    </Link>

                    <div>
                      <input
                        style={{ marginRight: "78px" }}
                        type="checkbox"
                        checked={true}
                        onChange={() =>
                          updateRole.mutate({
                            member_id: item.id,
                            admin: false,
                          })
                        }
                      />
                    </div>
                  </div>
                );
              })}
              {members.map((item) => {
                return (
                  <div
                    style={{
                      borderBottom: "1px solid #b7b7b7",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                    key={item.id}
                  >
                    <Link to={adminRoutes.admin_user_details(item.id)}>
                      <span>{item.name}</span>
                    </Link>
                    <div>
                      <input
                        style={{ marginRight: "50px" }}
                        type="checkbox"
                        checked={false}
                        onChange={() =>
                          updateRole.mutate({ member_id: item.id, admin: true })
                        }
                      />
                      <InteractiveIcon
                        className="far fa-trash-alt"
                        style={{ marginLeft: "auto" }}
                        onClick={() => remove.mutate(item.id)}
                      />
                    </div>
                  </div>
                );
              })}
            </>
          )}
          <div>
            <button onClick={() => setAddUserActive(true)}>Add user</button>
            {addUserActive && (
              <input
                type="email"
                placeholder="Email"
                value={userMail}
                onChange={(e) => setUserMail(e.target.value)}
              />
            )}
            {userMail.length > 0 && (
              <button onClick={() => add.mutate()}>Add</button>
            )}
          </div>
        </div>
      ) : null}
    </ScClass>
  );
};

export default ClassDetails;

const ScClass = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding-top: 10px;
  min-height: 20px;
`;

const ScArrow = styled.img<{ direction: boolean }>`
  transform: ${(p) => p.direction && "rotate(90deg)"};
  transition: transform 0.2s ease-in-out;
  margin-right: 30px;
  cursor: pointer;
`;
