import axios from "axios";
import { apiUrlV1 } from "enums/constant";

export async function getSubscriptions() {
  const url = `${apiUrlV1}/payment/subscriptions/`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function postCheckout(id: string, annual?: boolean) {
  const url = `${apiUrlV1}/payment/checkout/`;
  const { data } = await axios.post<any>(url, {
    subscription_id: id,
    is_annual: annual,
  });
  return data;
}
export async function postCustomerPortal() {
  const url = `${apiUrlV1}/payment/customer_portal/`;
  const { data } = await axios.post<any>(url);
  return data;
}
export async function postTrial() {
  const url = `${apiUrlV1}/payment/trial/`;
  const { data } = await axios.post<any>(url);
  return data;
}
