import React, { useEffect, useState } from "react";
import { publicRoutes } from "enums/routes";
import { useSessionContext } from "app/hooks/useSessionContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUserDetails } from "app/store/user";
import { GuestInfo } from "api/ws/sessionWS";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  TbEye,
  TbEyeOff,
  TbMaximize,
  TbMinimize,
  TbShare,
  TbUser,
  TbUsers,
} from "react-icons/tb";
import { Tooltip } from "app/components/Tooltip";
import { FloatingMenu, OrganizationLogo } from "app/components/Header";
import ShareInfo from "app/components/Exercises/LiveSession/ShareInfo";
import fscreen from "fscreen";
import { createPortal } from "react-dom";
import Participants from "app/components/Exercises/LiveSession/Participants";
import { getSessionStatus } from "app/components/Exercises/utils/sessionStatus";
import WarningModal from "app/components/Modal/WarningModal";
import { FaFlagCheckered } from "react-icons/fa";
import classNames from "classnames";

export const LiveSessionHeader = ({ isFullscreen, setIsFullscreen }) => {
  const { t } = useTranslation();
  const { isTeacher, session } = useSessionContext();
  const user = useSelector(selectUserDetails);

  const userName =
    user.first_name.length > 0
      ? { name: user.first_name, guest: false }
      : { name: GuestInfo()?.name, guest: true };

  return (
    <div className="flex flex-col group">
      {/*{isFullscreen && <div className="absolute top-0 h-0.5 w-full z-10" />}*/}
      <div
        className={classNames(
          "flex items-center px-2 py-1 sm:py-2 xs:px-4 gap-1 transition",
          isFullscreen && "opacity-50 hover:opacity-100"
          // isFullscreen &&
          //   "absolute top-0 w-full bg-black bg-opacity-50 backdrop-blur z-10 opacity-0 pointer-events-none group-hover:pointer-events-auto group-hover:opacity-100"
        )}
      >
        <Link to={publicRoutes.home} className="mr-2">
          <OrganizationLogo small />
        </Link>
        {isTeacher.get ? (
          <div className="leading-none font-bold text-sm md:text-base">
            {t("liveSession.liveSession")}
          </div>
        ) : (
          <div className="text-xs md:text-sm font-bold flex items-center">
            <TbUser className="mr-1 text-base" />
            {userName.name} {userName.guest && `(${t("liveSession.guest")})`}
          </div>
        )}

        {isTeacher.get && session.get.status.pin && (
          <FloatingMenu
            size="sm"
            placement="bottom-start"
            trigger={(toggle) => (
              <NewButton
                onClick={toggle}
                className="text-sm font-bold !gap-1 ml-1"
                size="sm"
                color="bg-black"
              >
                {session.get.status.pin} <TbShare className="!text-lg" />
              </NewButton>
            )}
          >
            <div className="flex flex-col sm:flex-row items-center text-gray-600">
              <ShareInfo isBig />
            </div>
          </FloatingMenu>
        )}
        {isTeacher.get && (
          <FullScreenButton {...{ isFullscreen, setIsFullscreen }} />
        )}
        <div className="flex-grow" />
        {isTeacher.get ? <TeacherButtons /> : null}
      </div>
    </div>
  );
};

const TeacherButtons = () => {
  const { t } = useTranslation();
  const { creationStep, session } = useSessionContext();

  if (creationStep.get === 0) return null;

  const { status } = session.get?.status ?? {};
  const { initiation, leaderboard } = getSessionStatus(status);

  if (leaderboard) return null;
  if (initiation) return null;

  return <ConsumptionButtons />;
};

const ConsumptionButtons = () => {
  const { t } = useTranslation();
  const { session } = useSessionContext();
  const [isParticipantsOpen, setParticipantsOpen] = useState(false);
  const [showFinish, setShowFinish] = useState(false);

  const {
    current_exercise,
    status,
    exercises: all_exercises,
    exercises_in_scope,
    results_submitted,
  } = session.get?.status ?? {};

  const exercises = all_exercises.filter((_, i) => !!exercises_in_scope?.[i]);

  const participants = session.get.status.participants.filter(
    (participant) => participant.status !== "banned"
  );
  const { exercise } = getSessionStatus(status);

  return (
    <>
      <WarningModal
        onConfirm={() => session.get.closeSession()}
        open={showFinish}
        closeModal={() => setShowFinish(false)}
        text={t("liveSession.endSessionPrompt")}
        confirmButton={t("warningPopup.deleteGroup.confirmButton")}
        closeButton={t("warningPopup.deleteGroup.closeButton")}
      />
      {!exercise && (
        <NewButton
          className="whitespace-nowrap flex space-x-2 items-center text-sm font-bold"
          size="sm"
          variant={
            current_exercise === exercises.length - 1 ? "primary" : "light"
          }
          color={
            current_exercise === exercises.length - 1 ? undefined : "bg-black"
          }
          onClick={() => setShowFinish(true)}
        >
          <FaFlagCheckered className="!text-base" />
          {t("liveSession.endSession")}
        </NewButton>
      )}

      {status !== "exercise_closing" && (
        <Tooltip value={t("liveSession.hideTT")}>
          <NewButton
            className="whitespace-nowrap flex space-x-2 items-center text-sm font-bold"
            color="bg-primary text-primary"
            size="sm"
            onClick={() =>
              session.get.setProperties({
                hide_exercise: !session.get?.status?.properties?.hide_exercise,
              })
            }
          >
            {!session.get?.status?.properties?.hide_exercise ? (
              <>
                <TbEyeOff className="!text-lg" /> {t("liveSession.hide")}
              </>
            ) : (
              <>
                <TbEye className="!text-lg" /> {t("common.show")}
              </>
            )}
          </NewButton>
        </Tooltip>
      )}
      <NewButton
        className="whitespace-nowrap flex gap-1 items-center text-sm font-bold"
        id="participants-button"
        color={
          exercise && results_submitted === participants.length
            ? "bg-primary text-primary"
            : "bg-black"
        }
        variant={
          exercise && results_submitted === participants.length
            ? "light"
            : undefined
        }
        size="sm"
        onClick={() => setParticipantsOpen(!isParticipantsOpen)}
      >
        {exercise && results_submitted + " / "}
        {participants.length}
        <TbUsers className="!text-lg" />
      </NewButton>
      {createPortal(
        <Participants
          close={() => setParticipantsOpen(false)}
          isFloating={isParticipantsOpen}
          consumption={exercise}
        />,
        document.getElementById("exercise-mixed-consumption") ?? document.body
      )}
    </>
  );
};

export const FullScreenButton = ({ isFullscreen, setIsFullscreen }) => {
  const { t } = useTranslation();
  const enabled = fscreen.fullscreenEnabled;

  useEffect(() => {
    if (!enabled) return;

    const checkFullscreen = () => setIsFullscreen(!!fscreen.fullscreenElement);

    fscreen.addEventListener("fullscreenchange", checkFullscreen, false);

    return () => {
      fscreen.removeEventListener("fullscreenchange", checkFullscreen, false);
      if (fscreen.fullscreenElement) fscreen.exitFullscreen();
    };
  }, [enabled]);

  if (!enabled) return null;

  return (
    <NewButton
      className="whitespace-nowrap flex space-x-2 items-center text-sm font-bold"
      size="sm"
      color="bg-black"
      onClick={() =>
        isFullscreen
          ? fscreen.exitFullscreen()
          : fscreen.requestFullscreen(document.body)
      }
    >
      {isFullscreen ? (
        <>
          <TbMinimize className="!text-lg" /> {t("liveSession.exitFullScreen")}
        </>
      ) : (
        <>
          <TbMaximize className="!text-lg" /> {t("liveSession.fullscreen")}
        </>
      )}
    </NewButton>
  );
};
