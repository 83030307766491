import React from "react";
import { useSessionContext } from "app/hooks/useSessionContext";
import { CoursePageResults } from "app/components/Exercises/CourseEdit/results/PageResults";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import { FaFlagCheckered } from "react-icons/fa";

export const CourseStudentResults = () => {
  const { t } = useTranslation();
  const { session } = useSessionContext();
  const { exercise_content, exercise_results, exercise_percentage } =
    session.get.status;

  return (
    <>
      {exercise_percentage != null && exercise_content?.category !== "poll" && (
        <div className="bg-green-500 w-full px-6 py-10 gap-2 rounded-xl text-white flex flex-col text-center items-center justify-center relative">
          <div className="font-bold text-2xl">{t("liveSession.score")}</div>
          <div className="bg-white rounded-lg px-6 py-3 text-3xl font-bold text-green-500">
            <CountUp end={Math.round(exercise_percentage * 100)} duration={2} />
            %
          </div>
          <FaFlagCheckered
            className="absolute-cover px-[10%] opacity-10"
            preserveAspectRatio="xMidYMid slice"
          />
        </div>
      )}
      <div className="bg-white pt-1 pb-3 rounded-lg">
        <CoursePageResults
          items={exercise_content.items}
          results={exercise_results}
        />
      </div>
    </>
  );
};
