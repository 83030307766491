import React from "react";
import { isPureProd } from "helpers/isProd";
import { InputToggle } from "app/components/Buttons/InputToggle";
import useLocalStorageState from "use-local-storage-state";
import { useRole } from "app/hooks/useRole";

export const useLocalStorageToggle = (key: string) => {
  const [value_, setValue, { removeItem }] = useLocalStorageState(key);
  const value = value_ === "";
  const toggle = () => (value ? removeItem() : setValue(""));
  return [value, toggle] as const;
};

export const ProdSwitches = () => {
  const role = useRole();
  const [prod, toggleProd] = useLocalStorageToggle("smartest_prod");

  return (
    <>
      {(!isPureProd() || role.superuser) && (
        <label className="flex items-center my-1">
          <span className="mr-auto text-sm">Prod mode</span>
          <InputToggle value={prod} onChange={toggleProd} />
        </label>
      )}
    </>
  );
};
