import React, { ComponentProps, ReactElement } from "react";
import useWindowDimensions from "app/hooks/useWindowDimensions";
import { pictureVariant } from "app/components/Helpers/picture";
import { CropSize } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { useImageMediaCanvas } from "app/components/Exercises/Edit/useImageCanvas";
import { useImageQuery } from "app/components/Exercises/Edit/context/SourceContext";
import useResizeObserver from "use-resize-observer";

export const ProgressiveCanvas = ({
  crop,
  src,
  className,
  style,
  thumbOnly,
}: {
  crop?: Record<string, any> | CropSize;
  src: string;
  className?: string;
  style?: React.CSSProperties;
  thumbOnly?: boolean;
}) => (
  <ProgressiveImage
    src={src}
    hasVariants={crop && "sourceId" in crop}
    thumbOnly={thumbOnly}
  >
    {(src, ref) => (
      <CanvasMediaImage
        crop={crop && "w" in crop ? (crop as CropSize) : undefined}
        {...{ src, className, style }}
        setRef={ref}
      />
    )}
  </ProgressiveImage>
);

const CanvasMediaImage = ({
  crop,
  src,
  setRef,
  ...props
}: {
  crop?: CropSize;
  src: string;
  setRef?: (ref: HTMLCanvasElement | null) => void;
} & ComponentProps<"canvas">) => {
  const handleCanvasRef = useImageMediaCanvas(src, crop);
  return (
    <canvas
      ref={(ref) => {
        handleCanvasRef(ref);
        setRef?.(ref);
      }}
      width={crop?.w}
      height={crop?.h}
      {...props}
    />
  );
};

export const ProgressiveImage = ({
  src,
  children,
  hasVariants = true,
  thumbOnly,
}: {
  src: string;
  children: (src: string, ref: any) => ReactElement;
  hasVariants?: boolean;
  thumbOnly?: boolean;
}) => {
  const { width: windowWidth } = useWindowDimensions();
  const isDesktop = windowWidth >= 650;

  const { ref, width, height } = useResizeObserver<any>();

  const isBiggerThan = (size: number) => {
    if (width == null || height == null) return true;
    return width > size || height > size;
  };

  const picture = pictureVariant(src);
  const thumbQuery = useImageQuery(picture.thumb, {
    enabled: hasVariants,
  });
  const mobileQuery = useImageQuery(picture.mobile, {
    enabled: thumbQuery.isSuccess && !thumbOnly && isBiggerThan(200),
  });
  const desktopQuery = useImageQuery(picture.desktop, {
    enabled:
      mobileQuery.isSuccess && isDesktop && !thumbOnly && isBiggerThan(1200),
  });

  const currentSrc = !hasVariants
    ? src
    : desktopQuery.isSuccess
    ? picture.desktop
    : mobileQuery.isSuccess
    ? picture.mobile
    : picture.thumb;

  return children(currentSrc, ref);

  // return useMemo(() => {
  //   const picture = pictureVariant(src);
  //
  //   const images = [
  //     picture.thumb,
  //     picture.mobile,
  //     isDesktop ? picture.desktop : false,
  //   ].filter(Boolean) as string[];
  //
  //   // a recursive loop - if there's next source image, then load it progressively, else - load children
  //   const image = (index = 0) => (
  //     <InnerProgressive placeholder={images[index]} src={images[index + 1]}>
  //       {(src, loading) =>
  //         loading || index + 2 >= images.length
  //           ? children(src)
  //           : image(index + 1)
  //       }
  //     </InnerProgressive>
  //   );
  //
  //   return image();
  // }, [isDesktop, src, children]);
};
