import axios from "axios";
import { apiUrlV1 } from "enums/constant";
import { Dimensions } from "../app/components/Exercises/CourseEdit/courseEditTypes";

export async function copyExerciseById(
  exercise_id: string,
  formData: { name?: string; folder_id: string }
) {
  const url = `${apiUrlV1}/exercise/copy/${exercise_id}/`;
  const { data } = await axios.post<any>(url, formData);
  return data;
}

export async function postBlocksFromImage(formData: any) {
  const url = `${apiUrlV1}/get_img_labeling_blocks/`;
  const { data } = await axios.post<any>(url, formData);
  return data;
}

export interface ColumnSelectionData {
  source_id: string;
  page_number: number;
  get_col_rects: boolean;
  detect_langs: boolean;
  crop: {
    x: number;
    y: number;
    w: number;
    h: number;
  };
}

interface ColumnLine {
  text: string;
  id: string;
  break: number;
}

export async function postTableFromImage(data: ColumnSelectionData) {
  const url = `${apiUrlV1}/get_table_cols/`;
  const res = await axios.post<{
    columns: ColumnLine[][];
    column_rectangles: Dimensions[];
  }>(url, data);

  // join columns in reverse
  const columns = res.data.columns.map((column) => {
    const { first, list } = [...column].reverse().reduce(
      ({ first, list }, line) => {
        if (!first) return { first: line, list: [] };
        if (line.break === 5) return { first: line, list: [first, ...list] };

        const newText =
          line.text +
          (line.break === 0 ? "" : line.break === 4 ? "-" : " ") +
          first.text;
        const newFirst = { ...first, text: newText };
        return { first: newFirst, list: list };
      },
      { list: [] } as { first?: ColumnLine; list: ColumnLine[] }
    );
    return [first as ColumnLine, ...list];
  });
  return { columns };
}
