import React, {
  KeyboardEventHandler,
  useCallback,
  useRef,
  useState,
} from "react";
import { TbGripVertical, TbPlus, TbTableImport, TbTrash } from "react-icons/tb";
import {
  useGetList,
  usePageItemContext,
  usePageItemIndex,
  useSetList,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  PageItemType,
  Pairing,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import {
  MediaData,
  MediaPicker,
  SelectOnlyType,
} from "app/components/Sources/MediaPicker/MediaPicker";
import { useItemFocus } from "app/components/Exercises/CourseEdit/items/PageItemFocus";
import { uuid } from "app/components/Exercises/utils/uuid";
import { withConsumption } from "app/components/Exercises/CourseEdit/render/ConsumptionContext";
import { PairingConsumption } from "app/components/Exercises/CourseEdit/render/courseConsumptionTypes";
import {
  InputWithMedia,
  InputWithMediaRender,
} from "app/components/Exercises/CourseEdit/components/InputWithMedia";
import { NewButton } from "app/components/Buttons/NewButton";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  InstructionsRender,
  QuizDropzone,
  QuizInstructionsRender,
  QuizItemLabel,
} from "app/components/Exercises/CourseEdit/components/generate/QuizDropzone";
import { PairingResults } from "app/components/Exercises/CourseEdit/results/courseResultsTypes";
import { CorrectMarker } from "app/components/Exercises/CourseEdit/results/PageResults";
import { CorrectnessMarker } from "app/components/Exercises/CourseEdit/results/PageCorrect";
import { useTranslation } from "react-i18next";
import {
  DropMedia,
  DropMediaColumns,
  MediaOneColumn,
  MediaTwoColumns,
  useDropMedia,
} from "../../../../Sources/MediaPicker/context/dropMediaContext";

export const PagePairingItem = () => {
  const { t } = useTranslation();
  const [item, set] = usePageItemContext<Pairing>();
  const setList = useSetList();
  const index = usePageItemIndex();
  const [dropMedia, setDropMedia] = useDropMedia();

  const insertTwoColumns = (data: [string, string][]) => {
    set<Pairing>((item) => {
      // delete empty items
      item.items
        .slice()
        .reverse()
        .forEach(([left, right], i, list) => {
          if (!left.text && !left.media && !right.text && !right.media)
            item.items.splice(list.length - 1 - i, 1);
        });

      item.items.push(
        ...(data.map(([left, right]) => [
          { text: left, id: uuid() },
          { text: right, id: uuid() },
        ]) as Pairing["items"])
      );
    });
  };

  return (
    <PageItemWrapper>
      <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
        <QuizItemLabel type={item.type}>
          <ImportQuizFromTable
            selectOnly={SelectOnlyType.twoColumns}
            onInsert={({ data }) => insertTwoColumns(data)}
          />
        </QuizItemLabel>
        <QuizDropzone />
        {item.items.map(([left, right], i) => (
          <div
            className="flex pairing-group gap-1 relative"
            key={left.id + "-" + right.id}
          >
            <SinglePairingItem {...left} index={i} left />
            <SinglePairingItem {...right} index={i} />
            <NewButton
              iconOnly
              color="bg-red-500 text-red-500"
              size="lg"
              className="mb-auto absolute top-0 -right-2.5 transform translate-x-full opacity-0 [.pairing-group:hover>&]:opacity-100"
              center
              onClick={() => {
                if (item.items.length === 1) {
                  setList((list) => {
                    list.splice(index, 1);
                  });
                } else {
                  set((item) => {
                    item.items.splice(i, 1);
                  });
                }
              }}
            >
              <TbTrash />
            </NewButton>
          </div>
        ))}
        {dropMedia && dropMedia.type === DropMediaColumns.TwoColumns ? (
          <div className="h-24 relative w-full">
            <DropMedia
              onDrop={(media) => {
                if (media.type === DropMediaColumns.TwoColumns) {
                  return () => insertTwoColumns(media.data);
                }
              }}
            />
          </div>
        ) : (
          <NewButton
            center
            variant="bg-opacity-0 hover:bg-opacity-80"
            color="bg-white text-gray-500"
            size="lg"
            className="font-bold mt-0.5"
            onClick={() => {
              set((item) => {
                const id = uuid();
                item.items.push([
                  { id, text: "" },
                  { id, text: "" },
                ]);
              });
            }}
          >
            <TbPlus /> {t("v4.generic.addItem")}
          </NewButton>
        )}
      </div>
    </PageItemWrapper>
  );
};

export const ImportQuizFromTable = ({
  selectOnly,
  onInsert,
}: // when select = oneColumn -> select only one column
| {
      onInsert: (media: MediaOneColumn) => void;
      selectOnly: SelectOnlyType.oneColumn;
    }
  // when select = twoColumns -> select only two columns
  | {
      onInsert: (media: MediaTwoColumns) => void;
      selectOnly: SelectOnlyType.twoColumns;
    }
  // when select = columns -> select one or two columns
  | {
      onInsert: (media: MediaOneColumn | MediaTwoColumns) => void;
      selectOnly: SelectOnlyType.columns;
    }) => {
  const [mediaPicker, setMediaPicker] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div
        onClick={() => setMediaPicker(true)}
        className="text-sm font-bold flex items-center gap-1 text-gray-500 mr-auto pl-2 pr-3 py-1 bg-primary bg-opacity-30 transition hover:bg-opacity-40 cursor-pointer rounded-b-lg"
      >
        <TbTableImport className="text-lg" strokeWidth={1.75} />
        {t("v4.library.tableSelection.importFrom")}
      </div>
      {mediaPicker && (
        <MediaPicker
          close={() => setMediaPicker(false)}
          onInsert={(data) => {
            setMediaPicker(false);
            onInsert(data);
          }}
          selectOnly={selectOnly as any}
        />
      )}
    </>
  );
};

export const SinglePairingItem = ({
  id: itemId,
  text,
  media,
  index: itemIndex,
  left,
}: { index: number; left?: boolean } & Pairing["items"][number][number]) => {
  const { t } = useTranslation();
  const [item, set] = usePageItemContext<Pairing>();
  const index = usePageItemIndex();
  const getList = useGetList();
  const sideIndex = left ? 0 : 1;

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const setFocus = useItemFocus(itemId, (distance, reverse) => {
    const pos = reverse ? text.length - distance : distance;
    inputRef.current?.focus();
    inputRef.current?.setSelectionRange(pos, pos);
  });

  const handleText = useCallback(
    (text: string) => {
      set((item) => {
        item.items[itemIndex][sideIndex].text = text;
      });
    },
    [set, itemIndex, sideIndex]
  );

  const handleMedia = useCallback(
    (media?: MediaData) => {
      set((item) => {
        if (!media) {
          delete item.items[itemIndex][sideIndex]["media"];
        } else {
          item.items[itemIndex][sideIndex].media = media;
        }
      });
    },
    [set, itemIndex, sideIndex]
  );

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    const target = e.target as HTMLTextAreaElement;
    const list = getList();

    // on arrow up
    if (e.key === "ArrowUp") {
      // selection has to be collapsed at start
      if (
        !!target.selectionStart ||
        target.selectionStart !== target.selectionEnd
      )
        return;

      if (!itemIndex) {
        if (!index) return;
        // moving focus to the previous item
        const previousItem = list[index - 1];

        // next item has to be text
        if (
          previousItem.type !== PageItemType.Paragraph &&
          previousItem.type !== PageItemType.List &&
          previousItem.type !== PageItemType.Pairing
        )
          return;

        // selection has to be collapsed at beginning
        if (
          target.selectionStart ||
          target.selectionStart !== target.selectionEnd
        )
          return;

        setFocus(previousItem.id, 0, true);
        return;
      }

      const previousListItem = item.items[itemIndex - 1];
      setFocus(previousListItem[sideIndex].id, 0, true);
      return;
    }
  };

  return (
    <div className="bg-white flex-1 rounded-lg p-1.5 flex">
      <InputWithMedia
        {...{ text, media }}
        ref={inputRef}
        onText={handleText}
        onMedia={handleMedia}
        className="grow"
        placeholder={t("v4.generic.textPlaceholder")}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export const PagePairingRender = withConsumption<PairingConsumption>(
  ({ answer, items, set, id, type }) => {
    const handleDrop = ({ destination, source }: any) => {
      if (!source || !destination) return;
      if (source.index === destination.index) return;

      set((item) => {
        const [single] = item.answer[0].splice(source.index, 1);
        item.answer[0].splice(destination.index, 0, single);
      });
    };

    return (
      <DragDropContext onDragEnd={handleDrop}>
        <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
          <QuizItemLabel type={type} />
          <QuizInstructionsRender id={id} />
          <Droppable
            droppableId={`pairing-consumption-${id}`}
            type={`pairing-consumption-${id}`}
          >
            {({ innerRef, droppableProps, placeholder }) => (
              <div
                className="gap-1 grid grid-cols-2"
                style={{ gridTemplateRows: `repeat(${answer[0].length}, 1fr)` }}
                ref={innerRef}
                {...droppableProps}
              >
                {answer[0].map((id, i) => {
                  const { text, media } = items.find(
                    (item) => item[0].id === id
                  )![0];
                  return (
                    <Draggable key={id + "-l"} draggableId={id} index={i}>
                      {({ innerRef, draggableProps, dragHandleProps }) => (
                        <div
                          className="bg-white rounded-lg p-1.5 flex items-center"
                          ref={innerRef}
                          {...draggableProps}
                        >
                          <div
                            {...dragHandleProps}
                            className="shrink-0 text-gray-400 flex items-center text-sm self-stretch -my-1.5 -ml-1.5 px-1"
                          >
                            <TbGripVertical />
                          </div>
                          <InputWithMediaRender {...{ text, media }} />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {answer[1].map((id, i) => {
                  const { text, media } = items.find(
                    (item) => item[1].id === id
                  )![1];
                  return (
                    <div
                      className="bg-gray-100 transition rounded-lg p-1.5 flex items-center"
                      style={{
                        gridRow: `${i + 1} / span 1`,
                        gridColumn: "2 / span 1",
                      }}
                      key={id + "r"}
                    >
                      <InputWithMediaRender {...{ text, media }} />
                    </div>
                  );
                })}
                {placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    );
  }
);

export const PagePairingResults = ({ item }: { item: PairingResults }) => (
  <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
    <InstructionsRender instructions={item.instructions} />
    <div
      className="gap-1 grid grid-flow-col"
      style={{
        gridTemplateRows: `repeat(${item.answer[0].length}, 1fr)`,
        gridTemplateColumns: "auto repeat(2, 1fr)",
      }}
    >
      {item.answer[0].map((id, i) => {
        const correct = !!item.result?.[id];
        const answer = item.items.find(
          ([, { id }]) => id === item.answer[1][i]
        )?.[0];
        return (
          <CorrectMarker
            key={id + "-ll"}
            correct={correct}
            className="!-mr-2"
            content={
              answer && (
                <InputWithMediaRender text={answer.text} media={answer.media} />
              )
            }
          />
        );
      })}
      {item.answer[0].map((id, i) => {
        const { text, media } = item.items.find(
          (item) => item[0].id === id
        )![0];

        return (
          <div
            className="bg-white rounded-lg p-1.5 flex items-center grow"
            key={id + "-l"}
            style={{
              gridRow: `${i + 1} / span 1`,
              gridColumn: "2 / span 1",
            }}
          >
            <InputWithMediaRender {...{ text, media }} />
          </div>
        );
      })}
      {item.answer[1].map((id, i) => {
        const { text, media } = item.items.find(
          (item) => item[1].id === id
        )![1];
        return (
          <div
            className="bg-gray-100 transition rounded-lg p-1.5 flex items-center"
            style={{
              gridRow: `${i + 1} / span 1`,
              gridColumn: "3 / span 1",
            }}
            key={id + "-r"}
          >
            <InputWithMediaRender {...{ text, media }} />
          </div>
        );
      })}
    </div>
  </div>
);

export const PagePairingCorrect = ({ item }: { item: Pairing }) => (
  <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
    <InstructionsRender instructions={item.instructions} />
    <div
      className="gap-1 grid grid-flow-col"
      style={{
        gridTemplateRows: `repeat(${item.items[0].length}, 1fr)`,
        gridTemplateColumns: "auto repeat(2, 1fr)",
      }}
    >
      {item.items.map(([{ id }]) => (
        <CorrectnessMarker
          ids={[item.id, id]}
          key={id + "-ll"}
          className="!-mr-2"
        />
      ))}
      {item.items.map(([left, right], i) => (
        <>
          <div
            className="bg-white rounded-lg p-1.5 flex items-center grow relative"
            key={left.id + "-l"}
            style={{
              gridRow: `${i + 1} / span 1`,
              gridColumn: "2 / span 1",
            }}
          >
            <InputWithMediaRender text={left.text} media={left.media} />
          </div>
          <div
            className="bg-gray-100 transition rounded-lg p-1.5 flex items-center"
            style={{
              gridRow: `${i + 1} / span 1`,
              gridColumn: "3 / span 1",
            }}
            key={right.id + "-r"}
          >
            <InputWithMediaRender text={right.text} media={right.media} />
          </div>
        </>
      ))}
    </div>
  </div>
);
