import { useHistory } from "react-router";
import React, { ReactElement, useMemo, useRef, useState } from "react";
import {
  TbArrowBigRightLines,
  TbClipboardCheck,
  TbCursorText,
  TbDownload,
  TbEye,
  TbPencil,
  TbPresentationAnalytics,
  TbShare,
  TbTool,
  TbTrash,
} from "react-icons/tb";
import { courseRoutes, sessionRoutes } from "enums/routes";
import WarningModal from "app/components/Modal/WarningModal";
import { toast } from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { useRole } from "app/hooks/useRole";
import { ContextMenu, OptionItemType } from "app/components/ContextMenu";
import { FileType, mapTypeToItemType } from "helpers/mimeType";
import {
  useCurrentFolderContext,
  useSelectedSources,
} from "app/pages/library/LibraryView";
import { addUserItemsToFolder, removeUserItems } from "api/exerciseLibraryAPI";
import { NewFolderPicker } from "app/pages/courses/NewFolderPicker";
import { copyExerciseById } from "api/exercisesAPI";
import { getCourse } from "api/course/courseAPI";
import download from "downloadjs";
import UTILS from "app/components/Exercises/utils";
import { CgSpinner } from "react-icons/cg";
import { AssignCourseModal } from "app/pages/courses/components/AssignCourseModal";
import { TrainingPreview } from "app/pages/courses/Edit/CourseHeader";
import { useWindowSize } from "react-use";
import { SharedSource } from "app/pages/library/components/SharedSource";
import { ExportCourseModal } from "app/pages/courses/components/ExportCourseModal";
import { getSource } from "../../../api/sourcesAPI";
import useDownloader from "react-use-downloader";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const contextMenuOptions = [
  "assign",
  "present",
  "download",
  "send",
  "rename",
  "move",
  "edit",
  "preview",
  "json",
  // "duplicate",
  "delete",
] as const;
type contextMenuOption = typeof contextMenuOptions[number];

enum FolderPickerType {
  Move,
}

const sourcesCountMessage = (t, { courses, sources, folders }) =>
  [
    courses &&
      `${courses} ${
        courses === 1 ? t("v4.training.text") : t("v4.training.plural")
      }`,
    sources &&
      `${sources} ${
        sources === 1 ? t("media.source.name") : t("media.source.plural")
      }`,
    folders &&
      `${folders} ${
        folders === 1 ? t("source.folder.name") : t("source.folder.plural")
      }`,
  ]
    .filter(Boolean)
    .join(", ");

export const SourceContextMenu = ({
  item,
  options,
  buttonClassName,
  onRename,
  open,
  disablePortal = false,
  trigger,
}: {
  item?: {
    id: string;
    name: string;
    type: FileType;
    children?: number;
  };
  options: contextMenuOption[][];
  buttonClassName?: string;
  onRename?: () => void;
  open?: boolean;
  disablePortal?: boolean;
  trigger?: (toggle: () => void) => ReactElement;
}) => {
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const closeRef = useRef<null | (() => void)>(null);
  const role = useRole();
  const history = useHistory();
  const [openWarn, setOpenWarn] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  const [openSend, setOpenSend] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [openExport, setOpenExport] = useState(false);

  const queryClient = useQueryClient();
  const [folderPicker, setFolderPicker] = useState<FolderPickerType | null>(
    null
  );
  const isInCourse = useRouteMatch({
    path: item && courseRoutes.edit(item.id),
    exact: true,
    strict: false,
  });
  const isInDetails = useRouteMatch({
    path: item && courseRoutes.details(item.id),
    exact: true,
    strict: false,
  });
  const folderId = useCurrentFolderContext();
  const selected = useSelectedSources((state) => state.selected);
  const clearSelected = useSelectedSources((state) => state.clear);
  const selectedList = useMemo(
    () => Object.entries(selected).map(([id, item]) => ({ id, ...item })),
    [selected]
  );
  const selectedLength = useMemo(() => selectedList.length, [selected]);

  const [folders, courses, sources] = useMemo(() => {
    return (item ? [item] : selectedList).reduce(
      ([f, c, s], { type, id }) => {
        if (type === FileType.Folder) return [[...f, id], c, s];
        if (type === FileType.Course) return [f, [...c, id], s];
        return [f, c, [...s, id]];
      },
      [[], [], []] as string[][]
    );
  }, [!!item, selectedList]);

  const isMultiSelect = !item && selectedLength > 1;
  const isFolder = !!folders.length;
  const isSource = !!sources.length;
  const isCourse = !!courses.length;
  const isNotCourse = isFolder || isSource;

  const downloadJsonMutation = useMutation(
    () => getCourse(item!.id, { full: true }),
    {
      onSuccess: (data) => {
        download(
          new Blob([JSON.stringify(data)], { type: "text/json" }),
          `smartest-${UTILS.makeId()}.json`,
          "application/json"
        );
      },
    }
  );

  const downloader = useDownloader();
  const downloadSourceMutation = useMutation(async () => getSource(item!.id), {
    onSuccess: (data) => {
      if (!data?.download_url) return;
      downloader.download(data.download_url, data.name);
    },
  });

  const items: { [key in contextMenuOption]: OptionItemType } = {
    assign: {
      icon: <TbClipboardCheck />,
      text: t("common.rowSubMenu.buttons.assign.label"),
      disabled: isNotCourse || !role.teacher,
      color: "bg-primary text-primary",
      onClick: () => {
        if (!courses?.length) return;
        setOpenAssign(true);
        closeRef.current?.();
      },
    },
    present: {
      icon: <TbPresentationAnalytics />,
      text: t("lessons.live"),
      disabled: isMultiSelect || !isCourse || !role.teacher,
      color: "bg-primary text-primary hidden md:flex",
      onClick: () => item && history.push(sessionRoutes.startSession(item.id)),
    },
    preview: {
      icon: <TbEye />,
      text: t("v4.generic.preview"),
      disabled: isMultiSelect || !isCourse,
      onClick: () => {
        setOpenPreview(true);
        closeRef.current?.();
      },
    },
    json: {
      icon: <TbTool />,
      text: t("v4.editView.saveJSON"),
      disabled: isMultiSelect || !isCourse || role.prodMode || !role.teacher,
      onClick: downloadJsonMutation.mutate,
    },
    download: {
      icon: downloader.isInProgress ? (
        <CircularProgressbar
          value={downloader.percentage}
          className="!w-[1rem] h-[1rem] mr-1"
          strokeWidth={15}
          styles={buildStyles({
            pathColor: "var(--primary-color)",
            strokeLinecap: "butt",
          })}
        />
      ) : (
        <TbDownload />
      ),
      text: isCourse ? t("v4.export.downloadAs") : t("v4.export.download"),
      disabled: isMultiSelect || !role.teacher || isFolder,
      onClick: () => {
        if (downloader.isInProgress) return downloader.cancel();

        if (isCourse) {
          setOpenExport(true);
          closeRef.current?.();
        } else downloadSourceMutation.mutate();
      },
      loading: downloadSourceMutation.isLoading,
    },
    send: {
      icon: <TbShare />,
      text: t("v4.generic.share"),
      disabled: isMultiSelect || isFolder || !role.teacher,
      onClick: () => {
        setOpenSend(true);
        closeRef.current?.();
      },
    },
    edit: {
      icon: <TbPencil />,
      text: t("v4.generic.edit"),
      disabled: isMultiSelect || !isCourse || width < 768 || !role.teacher,
      onClick: () => item && history.push(courseRoutes.edit(item.id)),
    },
    rename: {
      icon: <TbCursorText />,
      text: t("common.rowSubMenu.buttons.rename.label"),
      disabled: isMultiSelect || !onRename,
      onClick: ({ setIsOpen }) => {
        setIsOpen(false);
        onRename?.();
      },
    },
    move: {
      icon: <TbArrowBigRightLines />,
      text: t("common.rowSubMenu.buttons.move.label"),
      onClick: () => setFolderPicker(FolderPickerType.Move),
    },
    // duplicate: {
    //   icon: <TbCopy />,
    //   text: t("common.rowSubMenu.buttons.duplicate.label"),
    //   disabled: isMultiSelect || isFolderOrSource,
    //   onClick: () => setFolderPicker(FolderPickerType.Duplicate),
    // },
    delete: {
      icon: <TbTrash />,
      text: t("common.delete"),
      color: "bg-red-500 text-red-500",
      onClick: () => setOpenWarn(true),
    },
  };

  const deleteMutation = useMutation(
    () => removeUserItems({ dataToSave: { folders, sources, courses } }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("recent_lessons");
        queryClient.invalidateQueries("recent_assignments");
        queryClient.invalidateQueries(["library"]);
        queryClient.invalidateQueries(["courses"]);
        setOpenWarn(false);
        clearSelected();
        toast.success(
          t("source.removed") +
            ": " +
            sourcesCountMessage(t, {
              folders: data.removed_folders,
              courses: data.removed_courses,
              sources: data.removed_sources,
            })
        );
        if (!!item && (!!isInCourse || !!isInDetails)) {
          history.replace(courseRoutes.list);
        }
      },
    }
  );

  const moveMutation = useMutation(
    (id: string) =>
      addUserItemsToFolder({
        id,
        dataToSave: { folders, sources, courses },
      }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["library"]);
        clearSelected();
        toast.success(
          t("source.moved") +
            ": " +
            sourcesCountMessage(t, {
              folders: data.moved_folders,
              courses: data.moved_courses,
              sources: data.moved_sources,
            })
        );
      },
    }
  );

  const duplicateMutation = useMutation(
    (id: string) =>
      copyExerciseById(courses[0], {
        folder_id: id,
        name: item?.name ?? "",
      }),
    {
      onSuccess: (data, id) => {
        clearSelected();
        queryClient.invalidateQueries(["library", id]);
      },
    }
  );

  const folderWarn = useMemo(
    () =>
      item
        ? "children" in item && item?.children && item.children > 0
        : folders.some((id) => {
            const element = selected?.[id];
            if (!element) return false;
            if (!("children" in element)) return false;
            return element?.children && element.children > 0;
          }),
    [folders]
  );

  const handleToggle = (toggle: () => void) => () => {
    toggle();
    setOpenWarn(false);
    setFolderPicker(null);
  };

  return (
    <>
      <ContextMenu
        open={open || openWarn || undefined}
        className="min-w-[10rem] overflow-hidden"
        buttonClassName={buttonClassName}
        handleToggle={handleToggle}
        disablePortal={disablePortal}
        trigger={trigger}
        options={options.map((g) => g.map((key) => ({ key, ...items[key] })))}
      >
        {({ setIsOpen, content }) => {
          closeRef.current = () => setIsOpen(false);

          if (downloadJsonMutation.isLoading)
            return (
              <div className="m-auto">
                <CgSpinner className="m-auto text-3xl m-4 text-gray-400 animate-spin" />
              </div>
            );

          if (folderPicker != null)
            return (
              <NewFolderPicker
                folders={folders}
                close={() => setFolderPicker(null)}
                loading={moveMutation.isLoading || duplicateMutation.isLoading}
                onSelect={(id) => {
                  folderPicker === FolderPickerType.Move
                    ? moveMutation.mutate(id)
                    : duplicateMutation.mutate(id);

                  setIsOpen(false);
                }}
                initialFolder={folderId}
              />
            );
          return content;
        }}
      </ContextMenu>

      <WarningModal
        onConfirm={deleteMutation.mutate}
        open={openWarn}
        closeModal={() => setOpenWarn(false)}
        text={
          !folderWarn
            ? t("warningPopup.deleteGroup.text")
            : t("warningPopup.deleteFolder.text")
        }
        confirmButton={t("warningPopup.deleteFolder.confirmButton")}
        closeButton={t("warningPopup.deleteFolder.closeButton")}
      />
      {openSend && !!item?.id && (
        <SharedSource
          id={item.id}
          type={mapTypeToItemType(item.type)}
          close={() => setOpenSend(false)}
        />
      )}
      {openAssign && (
        <AssignCourseModal
          open={openAssign}
          close={() => setOpenAssign(false)}
          courses={
            item ? [item] : courses.map((id) => ({ id, ...selected[id] }))
          }
        />
      )}
      {openExport && !!item?.id && (
        <ExportCourseModal
          open={openExport}
          close={() => setOpenExport(false)}
          courseId={item.id}
        />
      )}
      {openPreview && !!item?.id && (
        <TrainingPreview
          courseId={item.id}
          close={() => setOpenPreview(false)}
        />
      )}
    </>
  );
};
