import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SpinnerButton from "app/components/Buttons/SpinnerButton";
import { AppDispatch } from "app/store";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { forgotPasswordMail } from "app/store/authentication/authActions";
import useInterval from "helpers/hooks/useInterval";
import { MaxLengthInput90 } from "enums/constant";

const ForgotPassword: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <section>
      <h1 className="text-4xl mx-auto text-center font-bold mb-8">
        {t("authPages.signIn.forgotPassword.label")}
      </h1>

      <ForgotPasswordForm />
    </section>
  );
};

const ScForm = styled.form`
  display: flex;
  margin: auto;
  max-width: 300px;
  flex-direction: column;
  justify-content: center;
`;

const ScInput = styled.input`
  box-sizing: border-box;
  height: 38px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  padding: 2px 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
`;

const ScErrorMessage = styled.span`
  display: flex;
  margin: 10px auto;
  justify-content: center;
`;

const ScSubmitButton = styled(SpinnerButton)`
  min-height: 40px;
  padding: 0 20px;
  border-radius: 4px;
  background-color: #f07f0e;
  font-weight: 600;
  color: white;
  &:disabled {
    cursor: not-allowed;
  }
`;

const ForgotPasswordForm = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<boolean>(false);
  const [generalMessage, setGeneralMessage] = useState<string>("");
  const mountedRef = useRef(true);
  const [disable, setDisable] = useState(false);

  const { register, handleSubmit, getValues } = useForm<{
    email: string;
  }>();

  const handleSubmitCallback = useCallback(async () => {
    setLoading(true);
    if (getValues().email === "") {
      toast.error(t("authPages.register.errors.emptyEmail"));
      setLoading(false);
      return;
    }
    const res: any = (await dispatch(forgotPasswordMail(getValues()))) as any;
    if (res.type === "auth/post/resend_activation_email/rejected") {
      setGeneralMessage(
        t("authPages.register.confirmForgotPasswordEmailNotSent.label")
      );
    } else {
      setGeneralMessage(
        t("authPages.register.confirmForgotPasswordEmailSent.label")
      );
      setDisable(true);
    }

    setLoading(false);
  }, [dispatch, t, getValues]);

  useEffect(
    () => () => {
      mountedRef.current = false;
    },
    []
  );

  useInterval(
    () => {
      setRequestError(false);
    },
    requestError ? 5000 : null
  );
  useInterval(
    () => {
      setGeneralMessage("");
    },
    generalMessage ? 5000 : null
  );

  return (
    <>
      <ScForm onSubmit={handleSubmit(handleSubmitCallback)}>
        <ScInput
          className="input input-icon"
          type="text"
          autoComplete={t("authPages.signIn.email._name")}
          placeholder={t("authPages.signIn.email.placeholder")}
          maxLength={MaxLengthInput90}
          {...register("email")}
        />
        <ScSubmitButton isLoading={loading} type="submit" disabled={disable}>
          {t("authPages.register.sendForgotPasswordEmailBtn.label")}
        </ScSubmitButton>
        {requestError && (
          <ScErrorMessage id="general-error">
            {t("authPages.register.errors.general")}
          </ScErrorMessage>
        )}
        <ScErrorMessage>{generalMessage}</ScErrorMessage>
      </ScForm>
    </>
  );
};

export default ForgotPassword;
