import React, { useState } from "react";
import watermark from "../../../../assets/images/logo-watermark.svg";
import { handleGuest } from "../utils/handleGuest";
import { useDetectKeyPress } from "../../../../helpers/hooks/detectKeyPress";
import { useTranslation } from "react-i18next";
import { NewButton } from "app/components/Buttons/NewButton";
import { InputText } from "app/components/Buttons/InputText";
import { ScWatermark } from "app/components/Exercises/LiveSession/LiveSession";

const GuestScreen = ({ setValid }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");

  const handleSave = () => {
    if (name.length) {
      handleGuest(name);
      setValid();
    }
  };

  useDetectKeyPress(
    "Enter",
    () => {
      handleSave();
    },
    [name]
  );

  return (
    <div className="flex-1 h-full flex flex-col w-full overflow-hidden bg-gray-300 text-gray-700">
      <ScWatermark src={watermark} />
      <div className="relative flex flex-col items-center justify-center bg-white m-auto shadow-2xl rounded-2xl p-4">
        <InputText
          value={name}
          onChange={setName}
          placeholder={t("liveSession.name")}
          className="[&>input]:text-center"
          autoFocus
          maxLength={22}
        />
        <NewButton
          variant="primary"
          onClick={() => handleSave()}
          size="lg"
          className="mt-4"
        >
          {t("common.save")}
        </NewButton>
      </div>
    </div>
  );
};

export default GuestScreen;
