import styled, { keyframes } from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import { useLocalStorageState } from "app/hooks/useBrowserStorageState";
import { FiMic, FiSettings, FiVideo } from "react-icons/fi";
import { VolumeMeter } from "app/components/Forms/MediaForm/VolumeMeter";
import fixWebmDuration from "webm-duration-fix";
import classNames from "classnames";
import { CgSpinner } from "react-icons/cg";

const isWebm = (isVideo: boolean) => {
  if (isVideo) return MediaRecorder.isTypeSupported("video/webm;codecs=vp8");
  return MediaRecorder.isTypeSupported("audio/webm");
};

const getMediaType = (isVideo: boolean) => {
  if (isVideo) {
    return isWebm(isVideo) ? "video/webm;codecs=vp8" : "video/mp4";
  }
  return isWebm(isVideo) ? "audio/webm" : "audio/mp4";
};

const MAX_LENGTH = 30 * 1000;

const RecordButton = styled.button<{ isRecording?: boolean }>`
  width: 4rem;
  height: 4rem;
  font-size: 1.25rem;
  background-color: #bd382e;
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    background-color: white;
    border-radius: ${({ isRecording }) => (isRecording ? "15%" : "50%")};
    transition: border-radius 0.2s ease;
  }
`;

const AbsoluteRecordButton = styled(RecordButton)`
  position: absolute;
  left: calc(50% - 2rem);
  bottom: 2rem;
  box-shadow: 0 0.5rem 2rem 0 black;
`;

interface Devices {
  cameras: { value: string; label: string }[];
  microphones: { value: string; label: string }[];
}

export const VideoPicker = ({
  setRecordedBlob,
  isVideo,
  setIsVideo,
  isCamera,
}) => {
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [devices, setDevices] = useState<Devices | undefined>();
  const [isSettings, setIsSettings] = useState(false);
  const [selectedCam, setSelectedCam] = useLocalStorageState("default_cam");
  const [selectedMic, setSelectedMic] = useLocalStorageState(
    "default_mic",
    "default"
  );
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [canRecord, setCanRecord] = useState(false);
  const mediaChunks = useRef<Blob[]>([]);
  const durationRef = useRef<number>();

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const cameras = devices
        .filter(({ kind }) => kind === "videoinput")
        .map(({ deviceId, label }) => ({ label, value: deviceId }));

      const microphones = devices
        .filter(({ kind }) => kind === "audioinput")
        .map(({ deviceId, label }) => ({ label, value: deviceId }));

      if (!selectedCam || !cameras.some(({ value }) => value === selectedCam)) {
        setSelectedCam(cameras?.[0]?.value);
      }

      if (
        !selectedMic ||
        !microphones.some(({ value }) => value === selectedMic)
      ) {
        setSelectedMic(microphones?.[0]?.value);
      }
      setDevices({ cameras, microphones });
    });
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = mediaStream;
    }

    if (audioRef.current) {
      audioRef.current.srcObject = mediaStream;
    }

    setCanRecord(false);

    if (!mediaStream) return;

    return () => {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
    };
  }, [mediaStream]);

  useEffect(() => {
    if (!devices) return;
    if ((!selectedCam && isVideo) || !selectedMic) return;
    try {
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: { exact: selectedMic },
          },
          video: isVideo && {
            frameRate: { max: 30 },
            deviceId: { exact: selectedCam },
            width: { max: 800 },
            height: { max: 800 },
          },
        })
        .then(setMediaStream);
    } catch (err) {
      // Removed for brevity
    }
  }, [selectedMic, selectedCam, devices, isVideo]);

  // useEffect(() => {
  //   return () => {
  //     if (!recorder) return;
  //     recorder.destroy();
  //   };
  // }, [recorder]);

  useEffect(() => {
    if (!recorder && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, [recorder]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleRecordStart = async () => {
    if (!mediaStream) return;
    const recorder = new MediaRecorder(mediaStream, {
      mimeType: getMediaType(isVideo),
    });
    recorder.onstop = handleRecordStop;
    recorder.ondataavailable = ({ data }: BlobEvent) => {
      mediaChunks.current.push(data);
    };
    recorder.start();
    setRecorder(recorder);
    durationRef.current = new Date().getTime();
    timeoutRef.current = setTimeout(handleRecordStop, MAX_LENGTH);
  };

  const handleRecordStop = async () => {
    const [chunk] = mediaChunks.current;
    const blob = new Blob(mediaChunks.current, {
      type: chunk.type,
    });
    setRecorder(null);
    if (isWebm(isVideo)) {
      const newBlob = await fixWebmDuration(blob);
      console.log({ type: newBlob.type }, newBlob);
      setRecordedBlob(newBlob);
      return;
    }
    setRecordedBlob(blob);
    console.log({ type: blob.type }, blob);
    return;
  };

  function handleVideoLoad() {
    if (!videoRef.current) return;
    setCanRecord(true);
    videoRef.current.play();
  }

  function handleAudioLoad() {
    if (!audioRef.current) return;
    setCanRecord(true);
    audioRef.current.muted = true;
  }

  return (
    <>
      {isVideo ? (
        <VideoContainer>
          <video ref={videoRef} onCanPlay={handleVideoLoad} playsInline muted />
          {recorder && <ProgressBar duration={MAX_LENGTH} />}
          {canRecord ? (
            <>
              <AbsoluteRecordButton
                onClick={() =>
                  recorder ? recorder.stop() : handleRecordStart()
                }
                isRecording={!!recorder}
              />
              <VolumeMeter mediaStream={mediaStream} />
            </>
          ) : (
            <Spinner />
          )}
        </VideoContainer>
      ) : (
        <AudioContainer>
          <audio autoPlay ref={audioRef} onCanPlay={handleAudioLoad} />
          {recorder && <ProgressBar duration={MAX_LENGTH} />}
          {canRecord ? (
            <>
              <RecordButton
                onClick={() =>
                  recorder ? recorder.stop() : handleRecordStart()
                }
                isRecording={!!recorder}
              />
              <VolumeMeter mediaStream={mediaStream} />
            </>
          ) : (
            <Spinner />
          )}
        </AudioContainer>
      )}
      {!recorder && devices && (
        <>
          <div
            style={{ display: "flex", marginTop: "1rem", paddingLeft: "3rem" }}
          >
            {isCamera && (
              <CamMicToggle isFirst={!isVideo}>
                <input
                  type="checkbox"
                  onChange={() => setIsVideo(!isVideo)}
                  checked={isVideo && !!devices.cameras.length}
                  disabled={!devices.cameras.length}
                />
                <div className="bg-primary">
                  <FiMic />
                </div>
                <div className="bg-primary">
                  <FiVideo />
                </div>
              </CamMicToggle>
            )}
            <SettingsButton
              className={classNames(
                !isSettings
                  ? "bg-gray-500 hover:bg-primary hover:bg-opacity-30 bg-opacity-10"
                  : "bg-primary bg-opacity-50 hover:bg-opacity-40"
              )}
            >
              <input
                type="checkbox"
                onChange={() => setIsSettings(!isSettings)}
                checked={isSettings}
              />
              <FiSettings />
            </SettingsButton>
          </div>
          {isSettings && (
            <StyledMediaSelection>
              {isCamera && (
                <label>
                  <FiVideo />
                  <select
                    onChange={(e) => setSelectedCam(e.target.value)}
                    value={selectedCam}
                  >
                    {devices.cameras.map(({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </label>
              )}
              <label>
                <FiMic />
                <select
                  onChange={(e) => setSelectedMic(e.target.value)}
                  value={selectedMic}
                >
                  {devices.microphones.map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </label>
            </StyledMediaSelection>
          )}
        </>
      )}
    </>
  );
};

const Spinner = () => (
  <div className="absolute-cover flex items-center justify-center">
    <CgSpinner className="animate-spin text-3xl text-white" />
  </div>
);

const AudioContainer = styled.div`
  width: 100%;
  border-radius: 1rem;
  padding: 2rem;
  margin: auto;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const VideoContainer = styled.div`
  width: 100%;
  aspect-ratio: 4 / 3;
  max-width: 500px;
  max-height: 360px;
  border-radius: 1rem;
  margin: auto;
  position: relative;
  overflow: hidden;
  background-color: var(--dark-grey-background);

  > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const loadingAnim = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
`;
const ProgressBar = styled.div<{ duration: number }>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0.75rem 1rem;
  overflow: hidden;
  border-radius: 8px;
  height: 8px;
  background-color: #f07f0e30;
  border: 1px solid rgb(var(--brand-color));

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transform-origin: left center;
    background-color: rgb(var(--brand-color));
    animation: ${loadingAnim} linear ${({ duration }) => `${duration}`}ms both;
  }
`;
const CamMicToggle = styled.label<{ isFirst: boolean }>`
  input {
    display: none;
  }
  display: flex;
  background-color: #0001;
  border-radius: 3rem;
  margin-left: auto;
  cursor: pointer;

  > div {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
    transition: all 0.1s ease;

    &:nth-of-type(1) {
      background-color: ${({ isFirst }) => !isFirst && "transparent"};
      color: ${({ isFirst }) => isFirst && "white"};
    }
    &:nth-of-type(2) {
      background-color: ${({ isFirst }) => isFirst && "transparent"};
      color: ${({ isFirst }) => !isFirst && "white"};
    }
  }
`;
const SettingsButton = styled.label`
  input {
    display: none;
  }
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: auto;
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`;
const StyledMediaSelection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    opacity: 0.7;
    margin-right: 1rem;
  }

  > label {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    select {
      font-size: 1rem;
      border-radius: 0.5em;
      flex-grow: 1;
      height: auto;
      padding: 0.5rem;
      min-width: 0;
    }
  }
`;
