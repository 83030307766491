import {
  CourseItem,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import React, { ReactNode } from "react";
import { ItemResults } from "app/components/Exercises/CourseEdit/results/courseResultsTypes";
import { PageItemRender } from "app/components/Exercises/CourseEdit/render/PageRender";
import { PageMultipleChoiceResults } from "app/components/Exercises/CourseEdit/items/quiz/PageMultipleChoiceItem";
import { PageSortingResults } from "app/components/Exercises/CourseEdit/items/quiz/PageSortingItem";
import { PagePairingResults } from "app/components/Exercises/CourseEdit/items/quiz/PagePairingItem";
import { PageShortAnswerResults } from "app/components/Exercises/CourseEdit/items/quiz/PageShortAnswerItem";
import { InnerLabelingResults } from "app/components/Exercises/CourseEdit/items/quiz/PageImageLabelingItem";
import classNames from "classnames";
import { TbCheck, TbX } from "react-icons/tb";
import { TooltipRaw } from "app/components/Tooltip";
import { sticky } from "tippy.js";
import { PageFillTheGapResults } from "app/components/Exercises/CourseEdit/items/quiz/PageFillTheGapItem";
import { PageExplanationRender } from "app/components/Exercises/CourseEdit/items/quiz/PageExplanationItem";
import { PollMultipleChoiceResults } from "../items/poll/PollMultipleChoiceItem";
import { PollCommentResults } from "../items/poll/PollCommentItem";
import { PageCategorisationResults } from "../items/quiz/PageCategorisationItem";
import { PageCrosswordResults } from "../items/quiz/PageCrosswordItem";
import { PageFlashcardsQuizResults } from "../items/quiz/PageFlashcardsQuizItem";

export const CoursePageResults = ({
  items,
  results,
}: {
  items: CourseItem[];
  results: Record<string, ItemResults>;
}) => (
  <div className="max-w-2xl w-full mx-auto relative flex flex-col gap-6 my-12 px-4 sm:px-1 md:px-0 text-gray-700">
    {items.map((item) => (
      <PageItemResults key={item.id} item={results?.[item.id] ?? item} />
    ))}
  </div>
);

export const PageItemResults = ({
  item,
}: {
  item: CourseItem | ItemResults;
}) => {
  const { type } = item;

  if (item.category === "content") return <PageItemRender item={item} />;
  // if (item.category === "poll") return null;
  if (type === PageItemType.Explanation)
    return <PageExplanationRender item={item} />;
  if (!("result" in item)) return null;

  if (type === PageItemType.MultipleChoice)
    return <PageMultipleChoiceResults item={item} />;
  if (type === PageItemType.ShortAnswer)
    return <PageShortAnswerResults item={item} />;
  if (type === PageItemType.ImageLabeling)
    return <InnerLabelingResults item={item} />;
  if (type === PageItemType.Sorting) return <PageSortingResults item={item} />;
  if (type === PageItemType.Pairing) return <PagePairingResults item={item} />;
  if (type === PageItemType.FillTheGap)
    return <PageFillTheGapResults item={item} />;
  if (type === PageItemType.Categorisation)
    return <PageCategorisationResults item={item} />;
  if (type === PageItemType.Crossword)
    return <PageCrosswordResults item={item} />;
  if (type === PageItemType.FlashcardsQuiz)
    return <PageFlashcardsQuizResults item={item} />;

  if (type === PageItemType.MultipleChoicePoll)
    return <PollMultipleChoiceResults item={item} />;

  if (type === PageItemType.CommentPoll)
    return <PollCommentResults item={item} />;

  return null;
};

export const CorrectTooltip = ({ children, content }) => (
  <TooltipRaw
    touch
    interactive
    appendTo={document.body}
    content={
      <div className="bg-white rounded-xl p-1 shadow-xl border-gray-200 border">
        <div className="flex items-center bg-green-100 px-1.5 py-1 rounded-xl max-w-xs">
          <TbCheck className="text-xl mr-1 text-green-700 shrink-0" />
          {content}
        </div>
      </div>
    }
    plugins={[sticky]}
    sticky="reference"
    placement="auto"
  >
    {children}
  </TooltipRaw>
);

export const CorrectMarker = ({
  correct = false,
  className = "",
  content,
}: {
  correct?: boolean;
  className?: string;
  content?: ReactNode;
}) => {
  if (content != null && correct != null && !correct)
    return (
      <CorrectTooltip content={content}>
        <div
          className={classNames(
            "p-2 pr-3 -mr-1 rounded-l-lg flex items-center justify-center shrink-0 border-l-2 border-current",
            "bg-red-100 text-red-700",
            className
          )}
        >
          <TbX className="text-xl" strokeWidth={3} />
        </div>
      </CorrectTooltip>
    );

  return (
    <div
      className={classNames(
        "p-2 pr-3 -mr-1 rounded-l-lg flex items-center justify-center shrink-0 border-l-2 border-current",
        correct ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700",
        className
      )}
    >
      {correct ? (
        <TbCheck className="text-xl" strokeWidth={3} />
      ) : (
        <TbX className="text-xl" strokeWidth={3} />
      )}
    </div>
  );
};
