import { create } from "zustand";
import { combine } from "zustand/middleware";
import { ColumnSelectionData } from "../../../../../api/exercisesAPI";

export const useTableSelectionContext = create(
  combine({ value: null as ColumnSelectionData | null }, (set) => ({
    clear: () => set({ value: null }),
    set: (value: null | ColumnSelectionData) => set({ value }),
  }))
);
