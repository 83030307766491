import { usePersistentStorage } from "app/hooks/useBrowserStorageState";
import React, { useState } from "react";
import { Resizable } from "react-resizable";
import classNames from "classnames";
import styled from "styled-components";
import { CoursePanel } from "app/pages/courses/Edit/CoursePanel";
import { CourseSourcesPanel } from "app/pages/courses/Edit/CourseSourcesPanel";
import { TbCornerLeftUp } from "react-icons/tb";
import { Link, useParams } from "react-router-dom";
import { courseRoutes } from "enums/routes";
import { useTranslation } from "react-i18next";

export const LeftPanel = () => {
  const { t } = useTranslation();
  const [width, setWidth] = usePersistentStorage("left_panel_size", 300);
  const [resizing, setResizing] = useState(false);
  const { courseId } = useParams<{ courseId: string }>();

  const handleResize = (_, { size }) => {
    if (size?.width == null) return;
    setWidth(size.width);
  };

  return (
    <Resizable
      key="slides"
      onResize={handleResize}
      width={width}
      maxConstraints={[450, 0]}
      minConstraints={[300, 0]}
      height={900}
      axis="x"
      resizeHandles={["e"]}
      handle={(_, ref) => (
        <ResizeHandle
          className={classNames(
            "before:transition hover:before:bg-primary -right-4 [.slides-group:hover>&]:opacity-100 transition",
            resizing ? "before:bg-primary" : "before:bg-gray-300 opacity-25"
          )}
          ref={ref}
        />
      )}
      onResizeStart={() => setResizing(true)}
      onResizeStop={() => setResizing(false)}
      className="transition transform"
    >
      <div
        style={{ width }}
        className="bg-white border-r border-gray-200 relative flex flex-col"
      >
        <Link
          to={courseRoutes.details(courseId)}
          className="h-12 border-b border-gray-200 flex items-center px-3 gap-2 cursor-pointer hover:bg-gray-100 text-gray-500 font-bold transition"
        >
          <TbCornerLeftUp className="text-2xl" /> {t("v4.training.backTo")}
        </Link>
        <div className="grow shrink relative">
          <div className="absolute-cover overflow-y-auto pb-2">
            <CoursePanel />
          </div>
        </div>
        <CourseSourcesPanel />
      </div>
    </Resizable>
  );
};

const ResizeHandle = styled.div`
  -ms-touch-action: none;
  touch-action: none;

  cursor: ew-resize;
  height: 100%;
  width: 1rem;
  top: 0;
  position: absolute;
  z-index: 10;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    width: 0.25rem;
    height: 4rem;
    border-radius: 100000px;
  }
`;
