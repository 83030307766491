import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { QRCode } from "react-qrcode-logo";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useInterval from "../../../helpers/hooks/useInterval";
import { format, parseISO } from "date-fns";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbCircleCheck, TbLink, TbRefresh } from "react-icons/tb";
import { toast } from "react-hot-toast";

export const SendView = ({
  pin,
  link,
  due,
  generate,
}: {
  pin: string;
  link: string;
  due?: string;
  generate?: () => void;
}) => {
  const { t } = useTranslation();
  const [copy, setCopied] = useState(false);

  useInterval(() => setCopied(false), copy ? 5000 : null);

  return (
    <>
      <div className="bg-gray-200 p-4 rounded-xl [&>canvas]:rounded-xl mb-1">
        <QRCode value={link} />
      </div>
      <div className="text-2xl text-gray-600 font-bold text-center mb-4">
        {t("groupsPage.labels.PIN")}:
        <CopyToClipboard
          text={pin}
          onCopy={() =>
            toast.success(t("groupsPage.labels.copiedPIN"), {
              position: "bottom-center",
            })
          }
        >
          <span className="bg-primary py-1 px-2 -mr-2 rounded-xl cursor-pointer bg-opacity-0 hover:bg-opacity-40 transition">
            {pin}
          </span>
        </CopyToClipboard>
      </div>
      <CopyToClipboard
        text={link}
        onCopy={() => {
          setCopied(true);
          toast.success(t("common.linkCopy.copied"), {
            position: "bottom-center",
          });
        }}
      >
        <NewButton variant="primary" size="lg" center>
          {copy ? <TbCircleCheck /> : <TbLink />}
          {copy ? t("common.linkCopy.copied") : t("common.linkCopy.copy")}
        </NewButton>
      </CopyToClipboard>
      {generate && (
        <NewButton onClick={generate} size="sm" className="mt-2" center>
          <TbRefresh /> {t("groupsPage.labels.generateNew")}
        </NewButton>
      )}
      {due && (
        <div className="text-gray-500 mt-4">
          {t("warningPopup.generateCode.valid_until")}{" "}
          {format(parseISO(due.split("T")[0]), "MMM d, yyyy")}
        </div>
      )}
    </>
  );
};
