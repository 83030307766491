import {
  updatePageSchema,
  usePageContext,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import { Link, useParams } from "react-router-dom";
import { publicRoutes } from "enums/routes";
import {
  TbArrowLeft,
  TbArrowRight,
  TbBook,
  TbChevronLeft,
  TbChevronRight,
  TbCornerLeftUp,
  TbHistory,
  TbPlayerPlay,
} from "react-icons/tb";
import {
  SavingIndicator,
  useDebounce,
} from "app/components/Exercises/CourseEdit/components/SavingIndicator";
import NotificationButton from "app/components/Notifications/NotificationButton";
import UserMenu from "app/components/UserMenu";
import React, { ChangeEvent, useMemo, useState } from "react";
import { HeaderIconButton, OrganizationLogo } from "app/components/Header";
import { NewButton } from "app/components/Buttons/NewButton";
import { PageRender } from "app/components/Exercises/CourseEdit/render/PageRender";
import { useTranslation } from "react-i18next";
import { CgSpinner } from "react-icons/cg";
import classNames from "classnames";
import { parseISO } from "date-fns";
import { getCourseName, getCoursePage } from "api/course/courseAPI";
import {
  CourseIcon,
  CourseSlideThumbnail,
  useCourseData,
  useCourseMutation,
} from "app/pages/courses/Edit/CoursePanel";
import { useQuery } from "react-query";
import { createPortal } from "react-dom";
import { SlideRender } from "app/components/Exercises/CourseEdit/slide/SlideRender";
import { HelpButton } from "app/pages/help/HelpRoutes";
import { CourseVersionHistoryModal } from "app/components/Exercises/CourseEdit/components/CourseVersionHistoryModal";
import { Tooltip } from "app/components/Tooltip";
import { ifExistsThen } from "helpers/ifExistsThen";
import { pagePlaceholder } from "./CourseEditView";

const useCatch = (hook: () => any) => {
  try {
    return hook();
  } catch (e) {
    return undefined;
  }
};

export const CourseHeader = () => {
  const { t } = useTranslation();
  const { courseId, pageId } =
    useParams<{ courseId: string; pageId: string }>();
  const isPage = useCatch(() => usePageContext((state) => !!state.data));
  const [historyOpen, setHistoryOpen] = useState(false);

  const [isPreview, setIsPreview] = useState(false);

  return (
    <div className="bg-white h-12 border-b border-gray-200 flex items-center p-2">
      <Link
        to={publicRoutes.home}
        className="p-1.5 bg-gray-500 rounded-lg bg-opacity-0 transition hover:bg-opacity-10 shrink-0"
      >
        <OrganizationLogo small />
      </Link>

      <div className="grow" />

      {!!isPage && <SavingIndicator />}

      <Tooltip value={t("creation.versionHistory.name")} delay={[500, 0]}>
        <HeaderIconButton onClick={() => setHistoryOpen(true)}>
          <TbHistory />
        </HeaderIconButton>
      </Tooltip>

      <Tooltip value={t("v4.generic.preview")} delay={[500, 0]}>
        <HeaderIconButton onClick={() => setIsPreview(true)}>
          <TbPlayerPlay />
        </HeaderIconButton>
      </Tooltip>

      {isPreview && (
        <TrainingPreview
          courseId={courseId}
          defaultPageId={pageId}
          close={() => setIsPreview(false)}
        />
      )}
      {historyOpen && (
        <CourseVersionHistoryModal close={() => setHistoryOpen(false)} />
      )}

      <div className="h-6 border-l border-r mx-2 border-gray-200" />

      <HelpButton editMode />
      <NotificationButton />
      <UserMenu hideName placement="bottom-end" />
    </div>
  );
};

export const CourseNameEdit = () => {
  const { t, i18n } = useTranslation();
  const { data: courseData } = useCourseData();

  // course placeholder name
  const placeholder = useMemo(() => {
    const date = courseData?.date_created
      ? parseISO(courseData.date_created)
      : undefined;
    return getCourseName(t, i18n.language, date);
  }, [i18n.language, courseData?.date_created]);

  // update course name
  const courseEditMutation = useCourseMutation();
  const courseNameDebounce = useDebounce(courseEditMutation.mutate, 2000);
  const [courseName, setCourseName] = useState(
    courseData.name === placeholder ? "" : courseData.name
  );
  const handleChangeCourseName = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target) return;
    const name = e.target.value;
    setCourseName(name);
    courseNameDebounce.set({ name: name || placeholder });
  };
  return (
    <div className="flex min-w-0 text-gray-600 items-center px-4 py-1.5 sticky top-0 w-full bg-white z-[1]">
      <TbBook className="text-2xl shrink-0" />
      <div className="flex-grow relative flex flex-col h-full">
        <div className="flex gap-1 items-center font-bold text-xs text-gray-400 absolute top-0 left-2 pointer-events-none">
          {t("v4.training.name")}
          {courseEditMutation.isLoading && (
            <CgSpinner className="animate-spin" />
          )}
        </div>
        <input
          value={courseName}
          onChange={handleChangeCourseName}
          maxLength={50}
          placeholder={placeholder}
          // readOnly={isPreview}
          className={classNames(
            "outline-none rounded-lg pt-2.5 text-gray-600 w-full font-bold transition text-lg px-2",
            "bg-gray-800 bg-opacity-0 focus:bg-opacity-5 hover:bg-opacity-5"
          )}
        />
      </div>
    </div>
  );
};

export const TrainingPreview = ({
  courseId,
  defaultPageId,
  close,
}: {
  courseId: string;
  close: () => void;
  defaultPageId?: string;
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [pageId, setPageId] = useState(defaultPageId || null);
  const currentPage = useCatch(() => usePageContext((state) => state.data));
  const isCurrentPage = currentPage?.id != null && currentPage.id === pageId;

  const { t } = useTranslation();
  const course = useCourseData({
    courseId,
    onSuccess: (data) => {
      if (!pageId) setPageId(data.pages?.[0]?.id || null);
    },
  });

  const pageQuery = useQuery(
    ["page", courseId, pageId],
    () => getCoursePage(courseId, pageId || ""),
    { enabled: pageId != null && !isCurrentPage, refetchOnWindowFocus: false }
  );

  const page = ifExistsThen(
    isCurrentPage ? currentPage : pageQuery.data,
    updatePageSchema
  );

  const nextPageId = useMemo(() => {
    if (!course.data?.pages) return null;
    const index = course.data.pages.findIndex((item) => item.id === pageId) + 1;

    if (index >= course.data.pages.length) return null;
    return course.data.pages[index].id;
  }, [pageId, course.data?.pages]);

  const previousPageId = useMemo(() => {
    if (!course.data?.pages) return null;
    const index = course.data.pages.findIndex((item) => item.id === pageId) - 1;

    if (index < 0) return null;
    return course.data.pages[index].id;
  }, [pageId, course.data?.pages]);

  return createPortal(
    <div className="fixed flex w-full h-full top-0 left-0 z-40 bg-white animate-fadeIn overflow-y-auto">
      <div className="fixed z-10 p-1 lg:hidden">
        <NewButton
          size="lg"
          variant="primary"
          color="bg-gray-400 text-white"
          iconOnly
          className="lg:hidden"
          onClick={() => setCollapsed(false)}
        >
          <TbChevronRight />
        </NewButton>
      </div>
      <div
        className={classNames(
          "flex flex-col w-[300px] border-r border-gray-200 shadow-2xl fixed lg:relative lg:shadow-none h-full z-10 bg-white transform transition",
          collapsed && "-translate-x-full lg:translate-x-0"
        )}
      >
        <div className="flex items-center border-b border-gray-200 relative">
          <div
            className="flex-grow h-full p-3 font-bold flex items-center gap-2 text-gray-500 cursor-pointer hover:bg-gray-100 transition"
            onClick={close}
          >
            <TbCornerLeftUp className="text-2xl" /> {t("v4.generic.back")}
          </div>
          <div
            className="p-3 font-bold h-full border-l border-gray-100 flex items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-100 transition lg:hidden"
            onClick={() => setCollapsed(true)}
          >
            <TbChevronLeft className="text-2xl" />
          </div>
        </div>
        <div className="flex flex-col grow p-2 overflow-y-auto">
          {!course.isSuccess ? (
            <CgSpinner className="animate-spin m-auto text-2xl text-gray-400" />
          ) : (
            course.data.pages.map(
              ({ category, quiz_type, name, items, id }) => {
                const active = id === pageId;
                return (
                  <div
                    key={id}
                    onClick={() => {
                      if (active) return;
                      setPageId(id);
                      setCollapsed(true);
                      // history.replace(courseRoutes.editPage(courseId, item.id));
                    }}
                    className={classNames(
                      "rounded-xl items-center w-full transition p-1.5 flex text-gray-600",
                      category !== "slide" && "pr-3",
                      active
                        ? "bg-gray-500 bg-opacity-10"
                        : "cursor-pointer bg-gray-500 bg-opacity-0 hover:bg-opacity-10"
                    )}
                  >
                    <CourseIcon category={category} quizType={quiz_type}>
                      {(Icon, color) => (
                        <div
                          className={classNames(
                            "shrink-0 w-6 h-6 mr-2 rounded-lg flex items-center justify-center",
                            color
                          )}
                        >
                          <Icon />
                        </div>
                      )}
                    </CourseIcon>
                    {category === "slide" ? (
                      <div className="aspect-video relative max-w-[15rem] mr-auto bg-white w-full border border-gray-100 rounded-xl overflow-hidden pointer-events-none">
                        <CourseSlideThumbnail items={items} />
                      </div>
                    ) : (
                      <div
                        className={classNames(
                          "grow whitespace-nowrap overflow-x-clip text-ellipsis select-none",
                          name && "font-bold"
                        )}
                      >
                        {name ||
                          pagePlaceholder({ category, quizType: quiz_type })(t)}
                      </div>
                    )}
                  </div>
                );
              }
            )
          )}
        </div>
      </div>
      <div className="grow flex flex-col relative overflow-y-auto bg-gray-100">
        {!page ? (
          <CgSpinner className="animate-spin m-auto text-4xl text-gray-400" />
        ) : (
          <div
            className={classNames(
              "grow flex flex-col relative px-4",
              page.category === "slide" && "overflow-hidden"
            )}
          >
            {page.category === "slide" ? (
              <SlideRender page={page} />
            ) : (
              <div className="bg-white rounded-2xl my-4 max-w-4xl w-full mx-auto flex flex-col">
                <PageRender page={page} />
              </div>
            )}
          </div>
        )}
        {(!!nextPageId || !!previousPageId) && (
          <div className="bg-white border-t border-gray-100 p-1 flex items-center mt-auto">
            {!!previousPageId && (
              <NewButton
                onClick={() => setPageId(previousPageId)}
                className="mr-auto"
              >
                <TbArrowLeft /> {t("v4.generic.back")}
              </NewButton>
            )}
            {!!nextPageId && (
              <NewButton
                onClick={() => setPageId(nextPageId)}
                className="ml-auto"
              >
                {t("v4.generic.next")} <TbArrowRight />
              </NewButton>
            )}
          </div>
        )}
        <div
          onClick={() => setCollapsed(true)}
          className={classNames(
            "absolute-cover !fixed bg-black bg-opacity-50 transition lg:pointer-events-none",
            collapsed
              ? "opacity-0 pointer-events-none"
              : "opacity-100 lg:opacity-0"
          )}
        />
      </div>
    </div>,
    document.body
  );
};
