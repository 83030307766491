import { ScActionButton } from "app/components/_styled/ActionButton.styled";
import styled from "styled-components";

const AdminUserNew = () => {
  return (
    <ScAllOrgMemberBox id="add-user-table">
      <div style={{ border: "1px solid grey", margin: "8px", padding: "4px" }}>
        <h4 style={{ textAlign: "center" }}>Create a new user(s)</h4>
        <textarea
          id="csv-input"
          style={{
            width: "100%",
            height: "300px",
            overflow: "hidden",
            resize: "none",
          }}
          placeholder="First name; Last name; EMail; Password"
        ></textarea>
        <div style={{ width: "100%", height: "32px", margin: "4px" }}>
          Password
          <input
            id="password-input"
            style={{
              width: "80%",
              height: "32px",
              overflow: "hidden",
              float: "right",
            }}
            placeholder="default password"
          ></input>
        </div>
        <div style={{ width: "100%", height: "32px", margin: "4px" }}>
          Notify
          <input
            type="checkbox"
            id="notify-input"
            style={{ width: "80%", height: "24px", float: "right" }}
          ></input>
        </div>
        <div style={{ width: "100%", height: "32px", margin: "4px" }}>
          BCC
          <input
            id="bcc-input"
            style={{
              width: "80%",
              height: "32px",
              overflow: "hidden",
              float: "right",
            }}
            placeholder="bcc"
            value="mehdi@smartest.io"
          ></input>
        </div>
        <ScDeleteGroupButton onClick={() => {}} style={{ width: "100%" }}>
          Create
        </ScDeleteGroupButton>
        <textarea
          id="output"
          style={{
            width: "100%",
            height: "300px",
            overflow: "hidden",
            resize: "none",
            display: "none",
          }}
          placeholder="The output will be printed here"
        ></textarea>
      </div>
    </ScAllOrgMemberBox>
  );
};

export default AdminUserNew;

const ScAllOrgMemberBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 25px;
  border-radius: 8px;
  background-color: white;
  transition: transform 0.25s linear 0s;
  overflow: auto;
`;

const ScDeleteGroupButton = styled(ScActionButton)`
  && {
    min-width: 146px;
    display: inline-block;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    border: solid 1px #ffffff;
    transition: all 0.15s ease-in;
    color: #000;
    cursor: pointer;
    padding: 8px 24px;
    gap: 12px;
    border-radius: 8px;
    color: #303030;
    background-color: #f1f1f1;
    line-height: 1.5;
    :hover {
      transition: all 0.25s ease-out;
      background-color: #f07f0e;
    }
  }
`;
