import styled from "styled-components";

export const ScModal = styled.div<{ open: boolean; tab?: number }>`
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  min-height: 100px;
  max-height: calc(100%);
  max-width: 600px;
  overflow-y: auto;
  width: 80%;
  background: var(--white-background);
  border-radius: 8px;
  z-index: 100;
  transition: all 0.5s ease;
  display: flex !important;
  flex-flow: column nowrap;
  align-items: center;
  box-shadow: 0 0 10px 10px rgba(92, 92, 92, 0.3);
  opacity: ${(props) => (props.open ? "100%" : 0)};
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  transform: ${(props) =>
    props.open ? "translate(-50%, -50%)" : "translate(-50%, -300px)"};

  @media screen and (max-width: 1000px) {
    transform: ${(props) => props.tab && "translateX(20%)"};
  }
  @media screen and (max-width: 768px) {
    transform: ${(props) =>
      props.open ? "translate(-50%, 0)" : "translate(-50%, -300px)"};
    margin: 10% auto;
    min-height: calc(100% - 20%);
    max-height: 80%;
    padding: 0 0 20px;
  }
`;
export const ScCloseBtn = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
