import React, { KeyboardEventHandler, useCallback, useMemo } from "react";
import { TbList, TbListNumbers } from "react-icons/tb";
import {
  ActiveButton,
  toggleMarkHotkey,
} from "app/components/Exercises/Edit/questionType/Slide/item/ItemText";
import { Editable, ReactEditor, Slate } from "slate-react";
import { Descendant, Editor, Range, Transforms } from "slate";
import styled from "styled-components";
import {
  List,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { uuid } from "app/components/Exercises/utils/uuid";
import {
  isEmptyTextItem,
  PageItemWrapper,
  useOpenMenu,
} from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import {
  useGetList,
  usePageItemContext,
  usePageItemIndex,
  useSetList,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  collapsedAtEnd,
  collapsedAtStart,
  FormatMenu,
  PageBlockFormatMenu,
  PageBlockLink,
  PageLeaf,
  PageLeafRender,
  paragraphsToText,
  useTextEditor,
} from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { usePageItemFocus } from "app/components/Exercises/CourseEdit/items/PageItemFocus";
import { ListGenerate } from "app/components/Exercises/CourseEdit/components/generate/ListGenerate";
import { DefaultElementType } from "app/components/Exercises/CourseEdit/components/ftg/FillTheGapUtils";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { PageBlockLinkSlide } from "../../slide/items/SlidePageParagraph";

export const PageListItem = () => {
  const [item] = usePageItemContext<List>();
  const { t } = useTranslation();

  return (
    <PageItemWrapper
      magic={
        paragraphsToText(item.data).trim().length < 30
          ? t("v4.generate.prompt.minLength", { number: 30 }) || ""
          : (close) => <ListGenerate close={close} />
      }
    >
      <InnerListItem />
    </PageItemWrapper>
  );
};

export const ListChangeVariant = () => {
  const [item, set] = usePageItemContext<List>();

  const handleVariant = (variant: List["variant"]) => () => {
    set((item) => {
      item.variant = variant;
    });
  };

  return (
    <>
      <ActiveButton
        icon={TbList}
        isActive={item.variant === "bullet"}
        onClick={handleVariant("bullet")}
      />
      <ActiveButton
        icon={TbListNumbers}
        isActive={item.variant === "number"}
        onClick={handleVariant("number")}
      />
    </>
  );
};

export const InnerListItem = () => {
  const [item, set] = usePageItemContext<List>();
  const index = usePageItemIndex();
  const setList = useSetList();
  const getList = useGetList();
  const openMenu = useOpenMenu();
  const editor = useTextEditor();

  const renderBlock = useCallback((props) => <PageBlock {...props} />, []);
  const renderLeaf = useCallback((props) => <PageLeaf {...props} />, []);

  const setFocus = usePageItemFocus((distance, reverse) => {
    ReactEditor.focus(editor);
    editor.children = item.data.map<any>((children) => ({
      type: "paragraph",
      children,
    }));
    if (reverse) {
      Transforms.select(editor, {
        anchor: Editor.end(editor, []),
        focus: Editor.end(editor, []),
      });
    } else {
      Transforms.select(editor, {
        anchor: Editor.start(editor, []),
        focus: Editor.start(editor, []),
      });
    }
    Transforms.move(editor, {
      distance: distance,
      reverse: reverse,
    });
  });

  const handleTextChange = (text: Descendant[]) => {
    if (
      !editor?.operations ||
      (editor.operations.length === 1 &&
        editor.operations[0]?.type === "set_selection")
    )
      return;

    set((item) => {
      item.data = (text as any).map(({ children }) => children);
    });
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    toggleMarkHotkey(e, editor);
    // on enter in empty bullet point - create an empty paragraph
    if (e.key === "Enter") {
      if (e.shiftKey) return;
      // selection has to be collapsed
      if (!editor.selection || !Range.isCollapsed(editor.selection)) return;
      const { children } = editor;
      const [paragraph, child] = editor.selection.focus.path;
      if (!paragraph) return;
      const paragraphChild = children[paragraph];
      if (
        child > 0 ||
        !("type" in paragraphChild) ||
        paragraphChild.type !== "paragraph"
      )
        return;
      if (
        paragraphChild.children.find(
          (child) => !("text" in child) || !!child.text
        )
      )
        return;

      const id = uuid();
      e.preventDefault();
      const newText = children.slice(0, paragraph);
      Transforms.deselect(editor);
      editor.children = newText;
      setList((list) => {
        const current = list[index] as List;
        current.data = (newText as any).map(({ children }) => children);
        list.splice(index + 1, 0, {
          id,
          type: PageItemType.Paragraph,
          category: "content",
          align: "left",
          data: [[{ text: "" }]],
        });

        if (paragraph < children.length - 1) {
          list.splice(index + 2, 0, {
            id: uuid(),
            type: PageItemType.List,
            category: "content",
            variant: item.variant,
            data: (children.slice(paragraph + 1) as any).map(
              ({ children }) => children
            ),
          });
        }
      });
      setFocus(id, 0);
      return;
    }
    if (e.key === "Tab") {
      e.preventDefault();
      openMenu?.();
      return;
    }

    const list = getList();

    // on arrow up
    if (e.key === "ArrowUp") {
      if (!index) return;

      // selection has to be collapsed at beginning
      if (collapsedAtStart(editor)) {
        e.preventDefault();
        const previousItem = list[index - 1];
        setFocus(previousItem.id, 0, true);
      }
      return;
    }

    // on arrow down
    if (e.key === "ArrowDown") {
      if (index === list.length - 1) return;

      // selection has to be collapsed at end
      if (collapsedAtEnd(editor)) {
        e.preventDefault();
        const nextItem = list[index + 1];
        setFocus(nextItem.id, 0);
      }
      return;
    }

    // has to be delete
    if (e.key === "Delete") {
      if (index === list.length - 1) return;

      // moving focus to the next item
      const nextItem = list[index + 1];

      // deleting empty item
      if (isEmptyTextItem(item)) {
        e.preventDefault();
        setList((list) => {
          list.splice(index, 1);
        });

        setFocus(nextItem.id, 0);
        return;
      }

      // if next item is a text item - append and move focus
      if (nextItem.type !== PageItemType.List) return;

      // selection has to be collapsed at end
      if (!collapsedAtEnd(editor)) return;

      const textValue = paragraphsToText(item.data);

      e.preventDefault();

      setList((list) => {
        list.splice(index + 1, 1);
      });
      set((item) => {
        item.data.push(...nextItem.data);
      });
      setFocus(item.id, textValue.length);
      return;
    }

    // has to be backspace
    if (e.key === "Backspace") {
      // moving focus to the previous item
      const previousItem = index ? list?.[index - 1] : null;

      if (isEmptyTextItem(item)) {
        e.preventDefault();
        setList((list) => {
          list.splice(index, 1);
        });

        if (previousItem) setFocus(previousItem.id, 0, true);
        return;
      }

      if (!previousItem) return;
      if (previousItem.type !== PageItemType.List) return;

      // selection has to be collapsed at beginning
      if (!collapsedAtStart(editor)) return;

      // if previous item is a text item - append and move focus
      const textValue = paragraphsToText(item.data);

      e.preventDefault();
      setList((list) => {
        list.splice(index, 1);
        const prev = list[index - 1] as List;
        prev.data.push(...item.data);
      });
      setFocus(previousItem.id, textValue.length, true);
    }
  };

  const text = useMemo<DefaultElementType[]>(() => {
    return item.data.map((children) => ({ type: "paragraph", children }));
  }, [item.data]);

  return (
    <Slate editor={editor} value={text} onChange={handleTextChange}>
      <FormatMenu />
      <PageBlockFormatMenu list>
        <EditableWrapper variant={item.variant} count={item.data.length}>
          <Editable
            as="ul"
            onKeyDown={handleKeyDown}
            className="leading-relaxed cursor-text border-2 border-black border-opacity-0 focus-within:border-opacity-10 transition rounded-lg p-2 -m-2"
            renderLeaf={renderLeaf}
            renderElement={renderBlock}
          />
        </EditableWrapper>
      </PageBlockFormatMenu>
    </Slate>
  );
};

export const PageBlock = ({ attributes, children, element, slide = false }) => {
  if (element.type === "link") {
    if (slide)
      return (
        <PageBlockLinkSlide {...{ attributes, element }}>
          {children}
        </PageBlockLinkSlide>
      );
    return (
      <PageBlockLink {...{ attributes, element }}>{children}</PageBlockLink>
    );
  }

  const first = element?.children?.[0];
  const className = !first
    ? ""
    : classNames(
        slide
          ? first.size === 2
            ? "text-6xl"
            : first.size === 1
            ? "text-4xl"
            : "text-2xl"
          : first.size === 2
          ? "text-3xl"
          : first.size === 1
          ? "text-xl"
          : "",
        first?.serif && "font-serif"
      );

  return (
    <li className={className} {...attributes}>
      {children}
    </li>
  );
};

export const EditableWrapper = styled.div<{
  variant: List["variant"];
  count: number;
}>`
  li {
    display: list-item;
    margin-left: ${(p) =>
      p.variant !== "number"
        ? 0.5
        : Math.floor(
            p.count < 10 ? 0 : p.count < 100 ? 1 : p.count < 1000 ? 2 : 3
          ) + 0.6}em;
    &:not(:last-of-type) {
      margin-bottom: 0.5em;
    }
    ::marker {
      font-weight: bold;
    }
  }

  ul {
    padding-inline-start: revert;
    //list-style-position: outside;
    list-style-type: ${(p) => (p.variant === "bullet" ? "disc" : "decimal")};
  }
`;

export const PageListRender = ({
  item,
  slide = false,
}: {
  item: Pick<List, "variant" | "data">;
  slide?: boolean;
}) => (
  <EditableWrapper variant={item.variant} count={item.data.length}>
    <ul className="leading-relaxed cursor-text">
      {item.data.map((paragraph, i) => (
        <li key={i}>
          {paragraph.map((item, i) => (
            <PageLeafRender slide={slide} item={item} key={i} />
          ))}
        </li>
      ))}
    </ul>
  </EditableWrapper>
);
