import React from "react";
import { useTranslation } from "react-i18next";
import { CoursePageRender } from "app/components/Exercises/CourseEdit/render/PageRender";
import termsEn from "./terms/terms-en.json";
import termsFr from "./terms/terms-fr.json";
import termsDe from "./terms/terms-de.json";

const pages = {
  en: termsEn,
  fr: termsFr,
  de: termsDe,
};

export const TermsPrivacy = () => {
  const { i18n } = useTranslation();
  const language = i18n.language in pages ? i18n.language : "en";

  return (
    <div className="bg-white rounded-2xl my-4 max-w-4xl w-full mx-auto flex flex-col">
      <CoursePageRender items={pages[language].items as any} />
    </div>
  );
};
