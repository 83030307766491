import React, { useEffect, useState } from "react";
import { useDetectKeyPress } from "../../../helpers/hooks/detectKeyPress";
import { useTranslation } from "react-i18next";
import { NewButton } from "app/components/Buttons/NewButton";
import { InputText } from "app/components/Buttons/InputText";
import { postGenerate2FACode, postVerify2FACode } from "api/authAPI";
import classNames from "classnames";
import { toast } from "react-hot-toast";

const Verify2FAForm = ({ setValid, cancel }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const [resend, setResend] = useState(false);
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    if (!timer) return;
    const t = setTimeout(() => setTimer((t) => t - 1), 1000);
    return () => {
      clearTimeout(t);
    };
  }, [timer]);

  const verifyCode = async () => {
    try {
      await postVerify2FACode(code);
      setValid();
    } catch (err: any) {
      const error = err?.response?.data?.error;
      if (error === "INVALID_CODE") {
        cancel();
        toast.error(t("v4.2fa.codeFailed"));
        return;
      }
      if (error === "INVALID_CODE_ATTEMPTS_LEFT_2") {
        toast.error(t("v4.2fa.invalidCode", { number: 2 }));
        return;
      }
      if (error === "INVALID_CODE_ATTEMPTS_LEFT_1") {
        toast.error(t("v4.2fa.invalidCode", { number: 1 }));
        return;
      }
    }
  };

  const generateCode = async () => {
    setTimer(30);
    try {
      await postGenerate2FACode();
      setResend(true);
    } catch (err: any) {
      setTimer(0);
      cancel();
    }
  };

  const handleSend = () => {
    if (code.length == 6) {
      verifyCode();
    }
  };

  const handleChange = (v) => {
    const regex = /^[0-9]+$/;
    if (v === "" || regex.test(v)) {
      setCode(v);
    }
  };

  useDetectKeyPress(
    "Enter",
    () => {
      handleSend();
    },
    [code]
  );

  const handleLogout = () => {
    cancel();
  };

  return (
    <div className="min-h-full min-w-full flex">
      <div className="flex flex-col items-center justify-center w-full max-w-screen-sm bg-white m-auto bg-opacity-25 backdrop-blur shadow-2xl rounded-2xl p-4 md:p-6 text-gray-700">
        <div className="text-2xl font-bold">
          {t("v4.2fa.smartestVerificationCode")}
        </div>
        <div className="mt-4 text-center">
          {!resend ? t("v4.2fa.codePrompt") : t("v4.2fa.newCodePrompt")}
        </div>
        <InputText
          value={code}
          onChange={handleChange}
          placeholder={"------"}
          className="[&>input]:text-center mt-8"
          autoFocus
          maxLength={6}
        />
        <div
          className={classNames(
            "text-sm text-gray-500 mt-2",
            !timer && "hover:underline cursor-pointer"
          )}
          onClick={() => !timer && generateCode()}
        >
          {t("v4.2fa.noCodeInfo")}{" "}
          {timer ? (
            <span>
              {t("v4.2fa.resendIn")} {timer}
            </span>
          ) : (
            <span className="text-primary font-bold">{t("v4.2fa.resend")}</span>
          )}
        </div>
        <NewButton
          variant="primary"
          onClick={handleSend}
          size="lg"
          className="mt-4"
          disabled={code.length !== 6}
        >
          {t("packagesPage.resultPopup.continueButtonLabel")}
        </NewButton>
        <NewButton onClick={handleLogout} className="mt-2">
          {t("common.cancel")}
        </NewButton>
      </div>
    </div>
  );
};

export default Verify2FAForm;
