import React, { ComponentProps, forwardRef } from "react";
import styled from "styled-components";
import { getTrackBackground, Range, useThumbOverlap } from "react-range";

export const InputRange = <T extends number | [number, number]>({
  value: value_,
  onChange,
  max,
  min = 1,
  step = 1,
}: {
  value: T;
  onChange: (value: T) => void;
  max: number;
  min?: number;
  step?: number;
}) => {
  const value: number[] = typeof value_ === "number" ? [value_] : value_;
  const rangeRef: any = React.useRef<Range>();
  return (
    <div className="flex flex-col px-4">
      <Range
        allowOverlap
        ref={rangeRef}
        values={value}
        onChange={
          (typeof value_ === "number"
            ? ([value]) => onChange(value)
            : onChange) as any
        }
        step={step}
        min={min}
        max={max}
        renderTrack={({ props, children }) => (
          <StyledTrack {...props} min={1} max={max} values={value_}>
            {children}
          </StyledTrack>
        )}
        renderThumb={({ props, isDragged, index }) => (
          <StyledHandle {...props} {...{ isDragged }}>
            <ThumbLabel
              rangeRef={rangeRef.current}
              values={value}
              index={index}
            />
          </StyledHandle>
        )}
      />
    </div>
  );
};

export const ThumbLabel = ({
  rangeRef,
  values,
  index,
}: {
  rangeRef: Range | null;
  values: number[];
  index: number;
}) => {
  const [labelValue, style] = useThumbOverlap(rangeRef, values, index, 1);
  return (
    <StyledLabel data-label={index} style={style as React.CSSProperties}>
      {labelValue}
    </StyledLabel>
  );
};

const StyledLabel = styled.div`
  display: block;
  position: absolute;
  top: -0.25rem;
  transform: translateY(-100%) translateX(-50%) !important;
  color: var(--main-white);
  font-weight: bold;
  font-size: 14px;
  padding: 0.25rem;
  border-radius: 0.25rem;
  background-color: rgb(var(--brand-color));
  white-space: nowrap;
  margin: auto;
  left: 50%;
`;

export const StyledHandle = styled.div<{ isDragged: boolean }>`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background-color: rgb(var(--brand-color));
  opacity: ${(p) => (p.isDragged ? 0.75 : 1)};
  outline: 0;
`;

export const StyledTrack = forwardRef<
  HTMLDivElement,
  {
    values: [number, number] | number;
    min: number;
    max: number;
  } & Omit<ComponentProps<"div">, "ref">
>(({ values, min, max, ...props }, ref) => (
  <StyledTrack_
    {...props}
    ref={ref}
    style={{
      background: getTrackBackground({
        values: Array.isArray(values) ? values : [values],
        min: min,
        max: max,
        colors: Array.isArray(values)
          ? [
              "var(--light-grey)",
              "rgb(var(--brand-color))",
              "var(--light-grey)",
            ]
          : ["rgb(var(--brand-color))", "var(--light-grey)"],
      }),
    }}
  />
));

export const StyledTrack_ = styled.div`
  height: 5px;
  width: 100%;
  border-radius: 4px;
  align-self: center;
  margin: 3rem 0 2rem;
`;
