import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSourcePageQuery } from "app/components/Exercises/Edit/context/SourceContext";
import { useTranslation } from "react-i18next";
import useResizeObserver from "use-resize-observer";
import { CgSpinner } from "react-icons/cg";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  TbArrowAutofitWidth,
  TbCheck,
  TbMarquee,
  TbMinus,
  TbPlus,
  TbTable,
} from "react-icons/tb";
import {
  ImageData,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { SelectOnlyType } from "app/components/Sources/MediaPicker/MediaPicker";
import {
  Selecting,
  SourceSelectionContextProvider,
  useSourceSelection,
  useSourceSelectionRange,
} from "app/components/Sources/MediaPicker/SingleSource/useSourceSelection";
import { DocumentPage } from "app/components/Sources/MediaPicker/SingleSource/DocumentPage";
import useSessionStorageState from "use-session-storage-state";
import { useDebounce } from "app/hooks/useDebounce";
import { useTableSelectionContext } from "../context/useTableSelectionContext";
import { useRole } from "../../../../hooks/useRole";
import { Tooltip } from "../../../Tooltip";

const BREAKPOINTS = [
  25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500,
];

export type DocumentRangeData = {
  sourceId: string;
  start: number;
  end: number;
};

export type DocumentSelectType =
  | {
      onConfirm: any;
      selectOnly: SelectOnlyType.none;
    }
  | {
      onConfirm: (insertData: ImageData | string) => void;
      selectOnly: null | SelectOnlyType.media;
    }
  | {
      onConfirm: (courseId: string) => void;
      selectOnly: SelectOnlyType.source;
    }
  | {
      onConfirm: (insertData: DocumentRangeData) => void;
      selectOnly: SelectOnlyType.documentRange;
    }
  | {
      onConfirm: (image: ImageData) => void;
      selectOnly: SelectOnlyType.image;
    }
  | {
      onConfirm: any;
      selectOnly:
        | SelectOnlyType.columns
        | SelectOnlyType.oneColumn
        | SelectOnlyType.twoColumns;
    };

export const DocumentImageSource = ({
  sourceId,
  isImage,
  onConfirm,
  selectOnly,
}: {
  isImage: boolean;
  sourceId: string;
} & DocumentSelectType) => {
  const sourcePage = useSourcePageQuery(sourceId, 1, false, {}, false);

  if (!sourcePage.isSuccess)
    return (
      <div className="grow flex flex-col justify-center">
        <CgSpinner className="m-auto text-3xl animate-spin text-gray-400" />
      </div>
    );

  const pages = sourcePage.data?.no_of_pages || 1;

  return (
    <SourceSelectionContextProvider
      pages={pages}
      initialPage={{
        src: sourcePage.data.image_url,
        width: sourcePage.data.image_width,
        height: sourcePage.data.image_height,
        words: [],
      }}
    >
      <InnerDocumentImageSource
        dataSize={{
          width: sourcePage.data.image_width,
          height: sourcePage.data.image_height,
        }}
        {...{
          pages,
          sourceId,
          isImage,
          onConfirm,
          selectOnly,
        }}
      />
    </SourceSelectionContextProvider>
  );
};

const SCROLL_MARGIN = 25;
const SCROLL_MAX_SPEED = 50;

export const useEdgeScroll = (containerRef, isSelecting) => {
  const [verticalScroll, setVerticalScroll] = useState(0);
  const [horizontalScroll, setHorizontalScroll] = useState(0);

  useEffect(() => {
    if (!verticalScroll) return;
    if (!containerRef.current) return;
    if (verticalScroll < 0 && !containerRef.current.scrollTop) return;
    if (
      verticalScroll > 0 &&
      containerRef.current.scrollTop ===
        containerRef.current.scrollHeight - containerRef.current.offsetHeight
    )
      return;

    let to = setTimeout(function scroll() {
      containerRef.current?.scrollBy(0, verticalScroll);
      if (!containerRef.current) return;
      if (verticalScroll < 0 && !containerRef.current.scrollTop) return;
      if (
        verticalScroll > 0 &&
        containerRef.current.scrollTop ===
          containerRef.current.scrollHeight - containerRef.current.offsetHeight
      )
        return;

      to = setTimeout(scroll, 1);
    }, 1);

    return () => {
      clearTimeout(to);
    };
  }, [verticalScroll]);

  useEffect(() => {
    if (!horizontalScroll) return;
    if (!containerRef.current) return;
    if (horizontalScroll < 0 && !containerRef.current.scrollLeft) return;
    if (
      horizontalScroll > 0 &&
      containerRef.current.scrollLeft ===
        containerRef.current.scrollWidth - containerRef.current.offsetWidth
    )
      return;

    let to = setTimeout(function scroll() {
      containerRef.current?.scrollBy(horizontalScroll, 0);
      if (!containerRef.current) return;
      if (horizontalScroll < 0 && !containerRef.current.scrollLeft) return;
      if (
        horizontalScroll > 0 &&
        containerRef.current.scrollLeft ===
          containerRef.current.scrollWidth - containerRef.current.offsetWidth
      )
        return;

      to = setTimeout(scroll, 1);
    }, 1);

    return () => {
      clearTimeout(to);
    };
  }, [horizontalScroll]);

  useEffect(() => {
    if (!isSelecting) return;
    const move = (e: PointerEvent) => {
      if (!e.target) return;
      const parent = containerRef.current?.getBoundingClientRect();
      if (!parent) return;
      const clientX = e.clientX;
      const clientY = e.clientY;

      // top edge
      if (clientY <= parent.top + SCROLL_MARGIN) {
        const aligned = clientY - parent.top - SCROLL_MARGIN;
        const scaled = aligned / SCROLL_MARGIN / 2;
        const translated = Math.max(scaled, -1) * SCROLL_MAX_SPEED;
        setVerticalScroll(Math.floor(translated));
        // bottom edge
      } else if (clientY >= parent.bottom - SCROLL_MARGIN) {
        const aligned = clientY - parent.bottom + SCROLL_MARGIN;
        const scaled = aligned / SCROLL_MARGIN / 2;
        const translated = Math.min(scaled, 1) * SCROLL_MAX_SPEED;
        setVerticalScroll(Math.floor(translated));
        // middle
      } else setVerticalScroll(0);

      // left edge
      if (clientX <= parent.left + SCROLL_MARGIN) {
        const aligned = clientX - parent.left - SCROLL_MARGIN;
        const scaled = aligned / SCROLL_MARGIN / 2;
        const translated = Math.max(scaled, -1) * SCROLL_MAX_SPEED;
        setHorizontalScroll(Math.floor(translated));
        // right edge
      } else if (clientX >= parent.right - SCROLL_MARGIN) {
        const aligned = clientX - parent.right + SCROLL_MARGIN;
        const scaled = aligned / SCROLL_MARGIN / 2;
        const translated = Math.min(scaled, 1) * SCROLL_MAX_SPEED;
        setHorizontalScroll(Math.floor(translated));
        // middle
      } else setHorizontalScroll(0);
    };
    document.addEventListener("pointermove", move);
    return () => {
      setVerticalScroll(0);
      document.removeEventListener("pointermove", move);
    };
  }, [isSelecting]);
};

const InnerDocumentImageSource = ({
  sourceId,
  isImage,
  onConfirm,
  selectOnly,
  dataSize,
  pages,
}: {
  isImage: boolean;
  sourceId: string;
  dataSize: { width: number; height: number };
  pages: number;
} & DocumentSelectType) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { ref: scaleRef, width, height } = useResizeObserver();
  const [store, setStore_] = useSessionStorageState(`document_${sourceId}`, {
    defaultValue: { scale: null, scroll: 0 },
  });
  const { scale: scale_, scroll } = store;

  const setStore = (key: keyof typeof store, value: number) =>
    setStore_((store) => ({ ...store, [key]: value }));

  const saveScroll = useDebounce(
    (value: number) => setStore("scroll", value),
    500
  );

  const isLoading = width == null || height == null;

  const initialSize = useMemo(() => {
    if (isLoading) return 100;
    return Math.floor(
      // Math.min(
      (width / dataSize.width) * 100
      // height / sourcePage.data.image_height
      // ) * 100
    );
  }, [width, height, isLoading]);

  const scale = scale_ || initialSize;

  useEffect(() => {
    if (isLoading) return;
    if (scale_ == null) setStore("scale", initialSize);
    containerRef.current?.scrollBy(0, scroll);
    return () => {};
  }, [isLoading]);

  const isSelecting = useSourceSelection(
    (state) => state.selecting !== Selecting.None
  );
  useEdgeScroll(containerRef, isSelecting);

  return (
    <>
      <div className="py-0.5 px-1 font-bold border-b-2 border-gray-100 flex items-center gap-3 text-gray-500 justify-center">
        {/*{sourcePage?.data?.no_of_pages > 1 && (*/}
        {/*  <>*/}
        {/*    <div className="flex items-center gap-1 text-sm">*/}
        {/*      <span className="flex text-center justify-center items-center bg-gray-200 rounded-lg w-8 px-1 py-0.5">*/}
        {/*        1*/}
        {/*      </span>*/}
        {/*      / {sourcePage?.data?.no_of_pages}*/}
        {/*    </div>*/}

        {/*    <div className="h-3 border-l border-gray-200" />*/}
        {/*  </>*/}
        {/*)}*/}
        {/*<ActiveButton*/}
        {/*  isActive={textVisible}*/}
        {/*  onClick={() => setTextVisible(!textVisible)}*/}
        {/*  icon={textVisible ? TbTypography : TbTypographyOff}*/}
        {/*/>*/}

        {/*<div className="h-3 border-l border-gray-200" />*/}

        <div className="flex items-center gap-1">
          <NewButton
            iconOnly
            variant="transparent"
            disabled={scale <= BREAKPOINTS[0]}
            onClick={() =>
              setStore(
                "scale",
                BREAKPOINTS.slice()
                  .reverse()
                  .find((val) => val < scale) || scale
              )
            }
          >
            <TbMinus />
          </NewButton>
          <span className="flex text-center justify-center items-center bg-gray-200 rounded-lg text-sm w-16 py-0.5 px-0.1">
            {scale}%
          </span>
          <NewButton
            iconOnly
            variant="transparent"
            disabled={scale >= BREAKPOINTS.slice(-1)[0]}
            onClick={() =>
              setStore("scale", BREAKPOINTS.find((val) => val > scale) || scale)
            }
          >
            <TbPlus />
          </NewButton>
        </div>

        <div className="h-3 border-l border-gray-200" />

        <NewButton
          iconOnly
          variant="transparent"
          disabled={scale === initialSize}
          onClick={() => setStore("scale", initialSize)}
        >
          <TbArrowAutofitWidth />
        </NewButton>
      </div>

      <div className="grow flex flex-col justify-center relative">
        <div
          id="media-scroll"
          className="absolute-cover overflow-y-auto bg-gray-200 scrollbar-thin scrollbar scrollbar-thumb-gray-400 hover:scrollbar-thumb-gray-500 scrollbar-track-white/50 scrollbar-track-transparent"
          ref={(ref) => {
            scaleRef(ref);
            containerRef.current = ref;
          }}
          onScroll={(e: any) =>
            e.target?.scrollTop != null && saveScroll(e.target?.scrollTop)
          }
        >
          {width != null && height != null && (
            <div
              className="flex flex-col min-w-full min-h-full absolute gap-8 origin-top-left"
              style={{
                transform: `scale(${scale / 100})`,
                left: Math.max(0, (width - (dataSize.width * scale) / 100) / 2),
                top:
                  pages === 1
                    ? Math.max(
                        0,
                        (height - (dataSize.height * scale) / 100) / 2
                      )
                    : undefined,
              }}
            >
              {[...new Array(pages)].map((_, i) => (
                <DocumentPage
                  key={i}
                  page={i}
                  sourceId={sourceId}
                  scale={scale / 100}
                  // textVisible={textVisible}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {selectOnly !== SelectOnlyType.none && (
        <DocumentImageFooter
          onConfirm={onConfirm as any}
          {...{ sourceId, isImage, selectOnly }}
        />
      )}
    </>
  );
};

enum BreakType {
  UNKNOWN,
  SPACE,
  HYPHEN,
}

const DocumentImageFooter = ({
  onConfirm,
  sourceId,
  isImage,
  selectOnly,
}: {
  sourceId: string;
  isImage: boolean;
  selectOnly: SelectOnlyType | null;
  onConfirm: (insertData: ImageData | string) => void;
}) => {
  const setTableSelection = useTableSelectionContext((data) => data.set);
  const role = useRole();
  const { t } = useTranslation();
  const pages = useSourceSelection((state) => state.pages);
  const crop = useSourceSelection((state) => state.crop);
  const [from, to] = useSourceSelectionRange();

  const handleConfirm = () => {
    if (selectOnly === SelectOnlyType.source) onConfirm(sourceId);

    if (crop) {
      const page = pages[crop.page]!;
      return onConfirm({
        type: PageItemType.Image,
        data: {
          ...crop,
          page: crop.page + 1,
          src: page.src,
          sizeX: page.width,
          sizeY: page.height,
          sourceId: sourceId,
        },
      });
    }

    if (from && to) {
      const text = pages
        .map((page, i) => [page, i] as const)
        .slice(from[0], to[0] + 1)
        .reduce((acc, [item, page]) => {
          if (!item) return acc;
          const start = page === from[0] ? from[1] : 0;
          const end = page === to[0] ? to[1] + 1 : undefined;
          const words = item.words.slice(start, end);

          return (
            acc +
            words.reduce((acc, { text, break: eolType, isLast }, i, list) => {
              const end = (() => {
                if (
                  i === list.length - 1 ||
                  eolType === BreakType.UNKNOWN ||
                  eolType === BreakType.HYPHEN
                )
                  return "";

                if (!isLast || eolType === BreakType.SPACE) return " ";

                return "\n\n";
              })();

              return acc + text + end;
            }, "")
          );
        }, "");

      onConfirm(text);
    }
  };

  const handleEntireImage = () => {
    const page = pages[0]!;
    return onConfirm({
      type: PageItemType.Image,
      data: {
        x: 0,
        y: 0,
        w: page.width,
        h: page.height,
        page: 1,
        src: page.src,
        sizeX: page.width,
        sizeY: page.height,
        sourceId: sourceId,
      },
    });
  };

  return (
    <div className="p-1 font-bold border-t-2 border-gray-100 flex items-center gap-1 text-gray-500">
      <div className="grow" />
      {(!selectOnly ||
        ![
          SelectOnlyType.source,
          SelectOnlyType.columns,
          SelectOnlyType.oneColumn,
          SelectOnlyType.twoColumns,
        ].includes(selectOnly)) &&
        isImage && (
          <NewButton onClick={handleEntireImage}>
            <TbMarquee /> {t("creation.selectionType.image.selectAll")}
          </NewButton>
        )}
      {role.aiEnabled &&
        (!selectOnly ||
          [
            SelectOnlyType.columns,
            SelectOnlyType.oneColumn,
            SelectOnlyType.twoColumns,
          ].includes(selectOnly)) && (
          <Tooltip value={!crop && t("v4.library.tableSelection.instruction")}>
            <NewButton
              color={selectOnly ? undefined : "bg-primary text-primary"}
              variant={selectOnly ? "primary" : undefined}
              className="mr-auto"
              onPointerDown={(e) => {
                e.stopPropagation();
              }}
              disabled={!crop}
              onClick={() => {
                if (!crop) return;
                setTableSelection({
                  source_id: sourceId,
                  page_number: crop.page + 1,
                  get_col_rects: true,
                  detect_langs: false,
                  crop: {
                    x: crop.x,
                    y: crop.y,
                    w: crop.w,
                    h: crop.h,
                  },
                });
              }}
            >
              <TbTable /> {t("v4.library.tableSelection.detect")}
            </NewButton>
          </Tooltip>
        )}
      {(!selectOnly ||
        ![
          SelectOnlyType.columns,
          SelectOnlyType.oneColumn,
          SelectOnlyType.twoColumns,
        ].includes(selectOnly)) && (
        <NewButton
          onClick={handleConfirm}
          variant="primary"
          disabled={
            selectOnly !== SelectOnlyType.source &&
            (from == null || to == null) &&
            !crop
          }
        >
          <TbCheck /> {t("v4.generic.confirm")}
        </NewButton>
      )}
    </div>
  );
};
