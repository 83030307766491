import React, { useContext } from "react";

export const useProtectedContext = <T,>(
  context: React.Context<T | undefined>
): T => {
  const data = useContext(context);
  if (data === undefined) {
    throw new Error("Context error! Got undefined");
  }
  return data;
};

export const CreateOptContext = <T,>(value?: T) =>
  React.createContext<T | undefined>(value ?? undefined);

export const CreateProtectedContext = CreateOptContext;
