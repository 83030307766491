export const FILE_SIZE_LIMIT = 40 * 1024 * 1024;

export const getErrorData = (e: any) => ({
  status: e?.response?.status,
  statusText: e?.response?.statusText,
  data: e?.response?.data,
  message: e?.message,
  stack: e?.stack,
});

export const getFileErrorData = (e: any, file: File) => ({
  ...getErrorData(e),
  file: {
    name: file.name,
    size: file.size,
    type: file.type,
    lastModified: file.lastModified,
  },
});
