import React, {
  ComponentProps,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  authRoutes,
  courseRoutes,
  groupRoutes,
  guestCourseRoutes,
  libraryRoutes,
  publicRoutes,
} from "enums/routes";
import smartestLogo from "../../assets/images/logo.png";
import smartestLogoIcon from "../../assets/images/logo.svg";
import UserMenu, { GuestMenu, LanguageChange } from "./UserMenu";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PINInput from "./PINInput";
import { selectUserDetails } from "app/store/user";
import { TbBook, TbFolders, TbLink, TbUser, TbUsers } from "react-icons/tb";
import classNames from "classnames";
import NotificationButton from "./Notifications/NotificationButton";
import { useWindowSize } from "react-use";
import Tippy from "@tippyjs/react/headless";
import { motion, useSpring } from "framer-motion";
import useOnClickOutside from "use-onclickoutside";
import { logoutUser } from "../store/authentication";
import { Placement } from "@popperjs/core";
import { useRole } from "../hooks/useRole";
import { useDetectKeyPress } from "helpers/hooks/detectKeyPress";
import { NewButton } from "app/components/Buttons/NewButton";
import { useGlobalQuery } from "app/App";
import { HelpButton } from "app/pages/help/HelpRoutes";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";

export const ScrollResponsiveHeader = ({
  className,
  ...props
}: ComponentProps<"header">) => {
  // const isScrollDown = useScrollDirection();
  return (
    <header
      {...props}
      className={classNames(
        className,
        "transition"
        // isScrollDown &&
        //   "transform -translate-y-full opacity-0 md:opacity-100 md:translate-y-0 md:pointer-events-auto pointer-events-none"
      )}
    />
  );
};

export const OrganizationLogo = ({ small = false }) => {
  const globalQuery = useGlobalQuery();

  return (
    <div className="bg-white rounded p-1 -m-1">
      {small ? (
        <img
          src={globalQuery.data?.small_logo_download_url || smartestLogoIcon}
          alt="Logo"
          className="max-h-7 max-w-[2rem]"
        />
      ) : (
        <>
          <img
            src={globalQuery.data?.small_logo_download_url || smartestLogoIcon}
            alt="Logo"
            className="xs:hidden max-h-7 max-w-[2rem]"
          />
          <img
            src={globalQuery.data?.logo_download_url || smartestLogo}
            alt="Logo"
            className="hidden xs:block max-h-7 max-w-[9rem]"
          />
        </>
      )}
    </div>
  );
};

export const OrganizationTopbar = () => {
  const globalQuery = useGlobalQuery();
  if (
    !!globalQuery.data?.theme?.top_bar_left &&
    !!globalQuery?.data?.theme?.top_bar_right
  )
    return (
      <div
        className="w-full h-1.5 shrink-0"
        style={{
          backgroundImage: `linear-gradient(to right, ${globalQuery.data.theme.top_bar_left}, ${globalQuery.data.theme.top_bar_right}`,
        }}
      />
    );

  if (globalQuery.data?.top_bar_desktop_url)
    return (
      <img
        src={globalQuery.data.top_bar_desktop_url}
        className="w-full h-2 shrink-0"
      />
    );

  return null;
};

export const PublicHeader = () => {
  const { t } = useTranslation();
  const role = useRole();

  return (
    <ScrollResponsiveHeader className="bg-white h-12 border-b border-gray-100 flex items-center px-4 text-gray-600 sticky top-0 left-0 w-full z-10">
      <Link
        to={{ pathname: publicRoutes.home, state: { anchor: "home" } }}
        className="mr-4"
      >
        <OrganizationLogo />
      </Link>
      {!role.isB2B && (
        <>
          <Link
            to={{ pathname: publicRoutes.home, state: { anchor: "pricing" } }}
            className="hidden sm:flex"
          >
            <NewButton
              component="div"
              variant="transparent"
              className="text-sm font-bold"
              size="lg"
            >
              {t("v4.subscriptions.pricing")}
            </NewButton>
          </Link>
          <a href="mailto:support@smartest.io" className="hidden sm:flex">
            <NewButton
              component="div"
              variant="transparent"
              className="text-sm font-bold"
              size="lg"
            >
              {t("v4.subscriptions.contactUs")}
            </NewButton>
          </a>
        </>
      )}
      <div className="flex-grow" />
      <PINInput />
      <LanguageChange small local />
      <Link
        to={authRoutes.login}
        className="text-sm font-bold text-gray-600 px-4 py-2 rounded-lg bg-primary bg-opacity-0 hover:bg-opacity-20 transition"
      >
        {t("authPages.signIn.submitBtn.label")}
      </Link>
      <Link
        to={authRoutes.register}
        className="text-sm font-bold text-white px-4 py-2 rounded-lg bg-primary hover:bg-opacity-80 transition ml-2"
      >
        {t("authPages.register.submitBtn.label")}
      </Link>
    </ScrollResponsiveHeader>
  );
};

const useGetThemeLabel = (key: string) => {
  const { t, i18n } = useTranslation();
  const theme = useGlobalQuery()?.data?.theme;

  return useMemo(() => {
    return theme?.[key]?.[i18n.language];
  }, [i18n.language, theme, key]);
};

export const AuthHeader = () => {
  const { t, i18n } = useTranslation();
  const { width } = useWindowSize();
  const userDetails = useSelector(selectUserDetails);
  const role = useRole();
  const trainingsLabel = useGetThemeLabel("trainingsLabel");
  const groupsLabel = useGetThemeLabel("groupsLabel");

  if (width <= 768) {
    return (
      <ScrollResponsiveHeader className="bg-white h-24 border-b border-gray-100 flex flex-col px-4 text-gray-600 sticky top-0 left-0 w-full z-10">
        <div className="flex items-center h-12">
          <Link to={publicRoutes.home} className="mr-4">
            <img
              src={userDetails.logo || smartestLogo}
              alt="Logo"
              className="max-h-8 max-w-[9rem]"
            />
          </Link>
          <div className="flex-grow" />
          <PINInput />
          <HelpButton />
          <NotificationButton />
          <UserMenu />
        </div>
        <div className="flex items-center h-12">
          <HeaderButton
            to={courseRoutes.list}
            className="flex-grow justify-center"
          >
            <TbBook className="hidden xs:block text-xl mr-2" />
            <span className="text-sm sm:text-base">
              {trainingsLabel || t("v4.training.plural")}
            </span>
          </HeaderButton>
          {role.teacher && (
            <HeaderButton
              to={libraryRoutes.library}
              className="flex-grow justify-center"
            >
              <TbFolders className="hidden xs:block text-xl mr-2" />
              <span className="text-sm sm:text-base">
                {t("v4.library.text")}
              </span>
            </HeaderButton>
          )}
          <HeaderButton
            to={groupRoutes.list}
            className="flex-grow justify-center"
          >
            <TbUsers className="hidden xs:block text-xl mr-2" />
            <span className="text-sm sm:text-base">
              {groupsLabel || t("v4.group.plural")}
            </span>
          </HeaderButton>
        </div>
      </ScrollResponsiveHeader>
    );
  }

  return (
    <ScrollResponsiveHeader className="bg-white h-14 border-b border-gray-100 flex items-center pl-4 pr-2 text-gray-600 sticky top-0 left-0 w-full z-10">
      <Link to={publicRoutes.home} className="pr-2">
        <OrganizationLogo />
      </Link>

      <HeaderButton to={courseRoutes.list}>
        <TbBook className="text-xl mr-2" />
        <span className="text-sm sm:text-base">
          {trainingsLabel || t("v4.training.plural")}
        </span>
      </HeaderButton>
      {role.teacher && (
        <HeaderButton to={libraryRoutes.library}>
          <TbFolders className="text-xl mr-2" />
          {t("v4.library.text")}
        </HeaderButton>
      )}
      <HeaderButton to={groupRoutes.list}>
        <TbUsers className="text-xl mr-2" />
        {groupsLabel || t("v4.group.plural")}
      </HeaderButton>

      <div className="flex-grow" />
      <PINInput />
      <HelpButton />
      <NotificationButton />
      <UserMenu />
    </ScrollResponsiveHeader>
  );
};

export const GuestHeader = () => {
  const { t } = useTranslation();
  const { guestPin } = useParams<{ guestPin: string }>();

  return (
    <ScrollResponsiveHeader className="bg-white h-14 border-b border-gray-100 flex items-center pl-4 pr-2 text-gray-600 sticky top-0 left-0 w-full z-10">
      <Link to={guestCourseRoutes.list(guestPin)} className="pr-2">
        <OrganizationLogo />
      </Link>
      <div className="flex-grow" />
      <CopyToClipboard
        text={guestPin}
        onCopy={() => toast.success(t("groupsPage.labels.copiedPIN"))}
      >
        <span className="flex items-center gap-1 bg-gray-100 hover:bg-primary py-2.5 px-4 mx-auto rounded-xl cursor-pointer hover:bg-opacity-30 transition text-lg font-bold text-gray-500 leading-none">
          <TbLink className="-mt-0.5 text-xl" strokeWidth={2.5} />
          {guestPin}
        </span>
      </CopyToClipboard>
      <div className="flex-grow" />
      <GuestMenu />
    </ScrollResponsiveHeader>
  );
};

export const OnboardingAuthHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <header className="bg-white h-12 border-b border-gray-100 flex items-center px-2 text-gray-600 sticky top-0 left-0 w-full z-10">
      <Link to={publicRoutes.home} className="mr-4">
        <img
          src={smartestLogo}
          alt="Logo"
          className="max-h-6 max-w-[9rem] ml-2"
        />
      </Link>
      <div className="flex-grow" />
      <LanguageChange small local />
      <FloatingMenu
        placement="bottom-end"
        size="xs"
        trigger={(toggle) => (
          <div
            className="transition hover:bg-opacity-10 bg-primary bg-opacity-0 rounded-xl flex items-center p-2 cursor-pointer"
            onClick={toggle}
          >
            <TbUser className="text-2xl" />
          </div>
        )}
        className="w-40 max-h-[80vh] overflow-y-auto"
      >
        <div className="flex flex-col justify-center text-center">
          <NewButton center onClick={() => dispatch(logoutUser(history))}>
            {t("header.main.logoutBtn")}
          </NewButton>
        </div>
      </FloatingMenu>
    </header>
  );
};

export const Header = () => {
  const { guestPin } = useParams<{ guestPin?: string }>();
  const role = useRole();
  if (guestPin) return <GuestHeader />;
  if (role.guest) return <PublicHeader />;

  return <AuthHeader />;
};

export const HeaderButton = ({
  children,
  to,
  className,
  exact = false,
}: {
  children: ReactNode;
  to: string;
  exact?: boolean;
} & ComponentProps<"a">) => {
  const match = useRouteMatch({
    path: to,
    exact,
    strict: false,
  });

  return (
    <Link
      to={to}
      className={classNames(
        "h-full flex items-center group relative transition",
        !!match && "text-primary",
        className
      )}
    >
      <div className="flex items-center justify-center font-bold m-1 py-3 px-4 transition rounded-xl group-hover:bg-opacity-10 bg-primary bg-opacity-0 w-full md:w-auto">
        {children}
      </div>
      <div
        className={classNames(
          "absolute w-[80%] left-[10%] bottom-0 h-1 bg-primary rounded-t-2xl transition",
          !match && "opacity-0"
        )}
      />
    </Link>
  );
};

export const HeaderIconButton = ({
  children,
  active,
  ...props
}: { active?: boolean } & ComponentProps<"button">) => {
  return (
    <button
      className={classNames(
        "h-11 w-11 rounded-xl transition hover:bg-opacity-10 bg-primary bg-opacity-0 flex items-center justify-center text-2xl relative",
        active ? "text-primary" : "text-gray-600"
      )}
      {...props}
    >
      {children}
    </button>
  );
};

const config = { damping: 50, stiffness: 1000, mass: 1 };
const INITIAL_SCALE = 0.9;

export const FloatingMenu = ({
  children,
  trigger,
  className,
  placement = "bottom",
  size = "md",
  open = undefined,
  portal = false,
  strategy = "fixed",
  options = [],
  onSetOpen,
  containerClassName,
  layout,
}: {
  layout?: string;
  children: ReactNode | ((data: any) => ReactNode);
  trigger: (
    toggleOpen: () => void,
    setIsOpen: (open: boolean) => void
  ) => ReactElement;
  className?: string;
  placement?: Placement;
  size?: "xs" | "sm" | "md";
  open?: boolean;
  portal?: boolean;
  options?: any[];
  strategy?: any;
  onSetOpen?: (value: boolean) => void;
  containerClassName?: string;
}) => {
  const opacity = useSpring(0, config);
  const scale = useSpring(INITIAL_SCALE, config);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, (e) => {
    if (open != null) return;
    if (e?.target && triggerRef.current?.contains(e.target as Node)) return;
    setIsOpen(false);
  });

  useEffect(() => {
    onSetOpen?.(isOpen);
  }, [isOpen]);

  const onMount = () => {
    scale.set(1);
    opacity.set(1);
  };

  const onHide = ({ unmount }) => {
    const cleanup = scale.onChange((value) => {
      if (value <= INITIAL_SCALE) {
        cleanup();
        unmount();
      }
    });

    scale.set(INITIAL_SCALE);
    opacity.set(0);
  };

  useDetectKeyPress(
    "Escape",
    () => {
      if (isOpen) setIsOpen(false);
    },
    [isOpen]
  );

  return (
    <div
      className={classNames("relative shrink-0", containerClassName)}
      ref={!portal ? ref : undefined}
    >
      <Tippy
        appendTo={portal ? document.body : undefined}
        maxWidth="100%"
        render={(attrs) => (
          <motion.div
            style={{ scale, opacity }}
            className={classNames(
              "z-40 relative origin-top flex flex-col",
              layout == null &&
                "rounded-lg bg-white shadow-xl border border-gray-100",
              {
                "p-1": size === "xs",
                "p-2": size === "sm",
                "p-4": size === "md",
              },
              className
            )}
            {...attrs}
            ref={portal ? ref : undefined}
          >
            {typeof children === "function"
              ? children({ isOpen, setIsOpen })
              : children}
          </motion.div>
        )}
        animation
        placement={placement}
        {...{ onMount, onHide }}
        visible={open == null ? isOpen : open}
        interactive
        popperOptions={{
          strategy,
          modifiers: [
            {
              name: "offset",
              options: { offset: [0, 4] },
            },
            ...options,
          ],
        }}
      >
        <div ref={triggerRef}>
          {trigger(() => setIsOpen(!isOpen), setIsOpen)}
        </div>
      </Tippy>
    </div>
  );
};
