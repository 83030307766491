import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useState } from "react";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { CgSpinner } from "react-icons/cg";
import { useDateFormat } from "app/hooks/useDateFormat";
import {
  TbArrowRotaryStraight,
  TbCalendar,
  TbCircleDot,
  TbHistory,
  TbListSearch,
  TbX,
} from "react-icons/tb";
import { NewButton } from "app/components/Buttons/NewButton";
import { InView } from "react-intersection-observer";
import classNames from "classnames";
import {
  BigModal,
  BigModalBody,
  BigModalHeader,
} from "app/components/BigModal";
import { useParams } from "react-router-dom";
import {
  getCoursePageVersion,
  getCoursePageVersions,
  postRevertPageVersion,
} from "api/course/courseAPI";
import { PageRender } from "app/components/Exercises/CourseEdit/render/PageRender";
import { usePageContext } from "app/components/Exercises/CourseEdit/PageStoreContext";

export const CourseVersionHistoryModal = ({ close }) => {
  const { t } = useTranslation();
  const load = usePageContext((store) => store.load);
  const setLoading = usePageContext((store) => store.setLoading);
  const { pageId } = useParams<{ pageId: string }>();
  const queryClient = useQueryClient();
  const { data, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ["page", "versions", pageId],
      ({ pageParam: page = 0 }) => getCoursePageVersions({ pageId, page }),
      {
        enabled: !!pageId,
        refetchOnWindowFocus: false,
        getNextPageParam: ({ versions_total }, pages) =>
          versions_total > pages.length * 15 ? pages.length : undefined,
      }
    );

  const revertMutation = useMutation(
    (id: string) => postRevertPageVersion(id),
    {
      onSuccess: (data) => {
        load(data);
        close();
        queryClient.invalidateQueries(["page", "versions", pageId]);
      },
    }
  );

  useEffect(() => {
    setLoading(revertMutation.isLoading);
  }, [revertMutation.isLoading]);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const list = useMemo(
    () =>
      (data?.pages || []).reduce(
        (list, group) => [...list, ...group.versions],
        []
      ),
    [data?.pages]
  );

  return (
    <BigModal>
      <BigModalHeader className="ml-2">
        <TbHistory className="text-xl" />
        {t("creation.versionHistory.name")}

        <NewButton
          variant="transparent"
          iconOnly
          onClick={close}
          className="ml-auto"
        >
          <TbX />
        </NewButton>
      </BigModalHeader>
      <BigModalBody className="overflow-y-auto !py-0">
        {!isSuccess ? (
          <div className="flex flex-col divide-gray-100 divide-y">
            <Skeleton items={15} />
          </div>
        ) : (
          <>
            <div className="flex flex-col divide-gray-100 divide-y">
              {!list?.length ? (
                <div className="text-xl text-center text-gray-500 py-5">
                  {t("creation.versionHistory.empty")}
                </div>
              ) : (
                list.map(({ id, action, date_created }) => (
                  <Item
                    key={id}
                    {...{ id, action, date_created }}
                    onRevert={() => revertMutation.mutate(id)}
                    disabled={
                      revertMutation.isLoading &&
                      (revertMutation.variables || false)
                    }
                  />
                ))
              )}
            </div>
            {hasNextPage && (
              <InView
                as="div"
                className="flex"
                onChange={(inView) =>
                  inView && !isFetchingNextPage && fetchNextPage()
                }
                key={data.pages.length}
              >
                {({ inView, ref }) => (
                  <div
                    ref={ref}
                    className={classNames(
                      "flex flex-col divide-gray-100 transition",
                      !inView && "opacity-0"
                    )}
                  >
                    <Skeleton />
                  </div>
                )}
              </InView>
            )}
          </>
        )}
      </BigModalBody>
    </BigModal>
  );
};

const Skeleton = ({ items = 15 }) => (
  <>
    {[...new Array(items)].map((_, i) => (
      <div
        key={i}
        className="flex py-4 animate-pulse"
        style={{ animationDelay: i * 0.2 + "s" }}
      >
        <div className="flex flex-col grow gap-1">
          <div className="rounded-lg bg-gray-200 h-3 w-16" />
          <div className="rounded-lg bg-gray-300 h-4 w-32" />
        </div>
        <div className="flex gap-1 shrink-0 self-end">
          <div className="rounded-lg bg-gray-300 h-8 w-20" />
          <div className="rounded-lg bg-gray-300 h-8 w-20" />
        </div>
      </div>
    ))}
  </>
);

const Item = ({ id, action, date_created, onRevert, disabled }) => {
  const { t } = useTranslation();
  const format = useDateFormat();
  const [openPreview, setOpenPreview] = useState(false);

  return (
    <div className="flex py-4">
      <div className="flex flex-col grow">
        <div className="font-bold text-gray-500 flex items-center">
          <TbCalendar className="mr-1 text-lg" />
          <span className="text-sm leading-none">
            {t("myExercisesPage.tables.exercisesHeader.date")}
          </span>
        </div>
        <div className="font-bold text-gray-600">
          {format(date_created, "MMM d, yyyy HH:mm:ss")}
        </div>
        {action === "undo" ? (
          <div className="font-bold text-gray-400 flex items-center">
            <TbArrowRotaryStraight className="mr-1 text-xl transform -rotate-90" />
            <span className="text-sm leading-none">
              {t("creation.versionHistory.previous")}
            </span>
          </div>
        ) : action === "redo" ? (
          <div className="font-bold text-gray-400 flex items-center">
            <TbArrowRotaryStraight className="mr-1 text-xl transform rotate-90" />
            <span className="text-sm leading-none">
              {t("creation.versionHistory.next")}
            </span>
          </div>
        ) : (
          <div className="font-bold text-primary flex items-center">
            <TbCircleDot className="mr-1 text-xl" />
            <span className="text-sm leading-none">
              {t("creation.versionHistory.current")}
            </span>
          </div>
        )}
      </div>
      {action && (
        <div className="flex gap-1 shrink-0 self-end">
          <NewButton onClick={() => setOpenPreview(true)}>
            {openPreview ? (
              <CgSpinner className="animate-spin" />
            ) : (
              <TbListSearch />
            )}
            {t("common.preview")}
          </NewButton>
          <NewButton
            color="text-primary bg-primary"
            onClick={onRevert}
            disabled={disabled}
          >
            {disabled === id ? (
              <CgSpinner className="animate-spin" />
            ) : (
              <TbHistory />
            )}
            {t("creation.versionHistory.restore")}
          </NewButton>
          {openPreview && (
            <CoursePreviewVersion id={id} close={() => setOpenPreview(false)} />
          )}
        </div>
      )}
    </div>
  );
};

export const CoursePreviewVersion = ({
  id,
  close,
}: {
  close: () => void;
  id: string;
}) => {
  const page = useQuery(["page", "version", id], () =>
    getCoursePageVersion(id)
  );

  return (
    <div className="fixed w-full h-full top-0 left-0 z-20 bg-white animate-fadeIn overflow-y-auto">
      {page.isLoading ? (
        <div className="absolute-cover flex">
          <CgSpinner className="text-gray-400 m-auto animate-spin text-4xl" />
        </div>
      ) : (
        <PageRender page={page.data} />
      )}
      <NewButton iconOnly className="fixed top-2 left-2" onClick={close}>
        <TbX />
      </NewButton>
    </div>
  );
};
