import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUserDetails,
  postSetPassword,
  postUserContext,
  postUsers,
  putUpdateUserDetails,
} from "../../../api/userAPI";
import { useRoleContext } from "app/hooks/useRole";

export const userDetails = createAsyncThunk(
  "user/get/details",
  async (userData: string, thunkApi) => {
    const response = await getUserDetails();
    if (response.status > 400) {
      // Return the known error for future handling
      return thunkApi.rejectWithValue(await response.json());
    }
    useRoleContext.getState().readPayload(response);
    return response;
  }
);
export const updateUserDetails = createAsyncThunk(
  "user/post/details",
  async (dataToSave: any, thunkApi) => {
    const response = await putUpdateUserDetails(dataToSave);
    if (response.status > 400) {
      // Return the known error for future handling
      return thunkApi.rejectWithValue(await response.json());
    }

    useRoleContext.getState().readPayload(response);
    return response;
  }
);

export const saveUserContext = createAsyncThunk(
  "user/post/context",
  async (context: any, thunkApi) => {
    const response = await postUserContext(context);
    if (response.status > 400) {
      // Return the known error for future handling
      return thunkApi.rejectWithValue(await response.json());
    }
    return response;
  }
);

export const createUser = createAsyncThunk(
  "users",
  async (data: any, thunkApi) => {
    const response = await postUsers(data);
    if (response.status > 400) {
      // Return the known error for future handling
      return thunkApi.rejectWithValue(await response.json());
    }
    return response;
  }
);

createAsyncThunk("users/password", async (data: any, thunkApi) => {
  const response = await postSetPassword(data);
  if (response.status > 400) {
    // Return the known error for future handling
    return thunkApi.rejectWithValue(await response.json());
  }
  return response;
});
