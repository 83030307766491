import { AccessToken, wsUrl } from "enums/constant";
import { useEffect, useRef, useState } from "react";
import { WSStatus } from "enums/webSocketStatus";
import { useParams } from "react-router";
import { useOnlineState } from "app/hooks/useOnlineState";
import { toast } from "react-hot-toast";
import { onlineBack } from "app/components/Exercises/utils/onlineBack";
import { screenAwake } from "app/components/Exercises/utils/screenAwake";
import { useAccessToken, useRole } from "app/hooks/useRole";

export const GuestInfo = () => {
  const guestInfo = localStorage.getItem("gid");
  if (guestInfo !== null) {
    const data = guestInfo.split("_");
    return { id: data[0], name: data[1] };
  }
};

const initialProperties = {
  hide_exercise: false,
};

export const useSession = (role: ReturnType<typeof useRole>) => {
  const [status, setStatus] = useState<any>({});
  const [name, setName] = useState("");
  const [exercises, setExercises] = useState([]);
  const [isDuration, setIsDuration] = useState(false);
  const [pinValid, setPinValid] = useState(true);
  const [sessionLocked, setSessionLocked] = useState(false);
  const [nameTaken, setNameTaken] = useState(false);
  const [userBanned, setUserBanned] = useState(false);
  const [userRejected, setUserRejected] = useState(false);
  const [state, setState] = useState<WSStatus>(WSStatus.disconnected);
  const token = useAccessToken();
  const webSocket = useRef<any>(null);
  const [reconnect, setReconnect] = useState(0);
  const params: { id: string } = useParams();
  const online = useOnlineState();

  const initSession = (id) => {
    webSocket.current.send(
      JSON.stringify({
        method: "init",
        params: {
          unit_id: id,
        },
      })
    );
  };

  const createSession = ({
    id,
    exercises_in_scope,
    is_duration,
    is_open,
    is_leaderboard,
  }) => {
    webSocket.current.send(
      JSON.stringify({
        method: "create",
        params: {
          unit_id: id,
          exercises_in_scope,
          is_duration,
          is_open,
          is_leaderboard,
          properties: initialProperties,
        },
      })
    );
  };

  const joinSession = () => {
    const pin = params?.id?.slice(0, 5);
    if (!pin) return;

    var extParams = {};
    if (role.guest) {
      const info = GuestInfo();
      if (info === undefined) {
        localStorage.removeItem("joining");
        return;
      }
      extParams = info;
    }
    localStorage.setItem("joining", "true");
    webSocket.current.send(
      JSON.stringify({
        method: "join",
        params: {
          pin: params?.id?.slice(0, 5),
          ...extParams,
        },
      })
    );
  };

  const banUser = (id) => {
    webSocket.current.send(
      JSON.stringify({
        method: "ban",
        params: {
          participant_id: id,
        },
      })
    );
  };

  const startSession = () => {
    console.log("start");
    webSocket.current.send(
      JSON.stringify({
        method: "start",
      })
    );
  };

  const submitSession = (results) => {
    console.log("submit");
    webSocket.current.send(
      JSON.stringify({
        method: "submit",
        params: results,
      })
    );
  };

  const closeExercise = () => {
    console.log("close_exercise");
    webSocket.current.send(
      JSON.stringify({
        method: "close_exercise",
      })
    );
  };

  const forceCloseExercise = () => {
    console.log("close_exercise");
    webSocket.current.send(
      JSON.stringify({
        method: "force_close_exercise",
      })
    );
  };

  const startExercise = () => {
    console.log("start exercise");
    webSocket.current.send(
      JSON.stringify({
        method: "start_exercise",
      })
    );
  };

  const cancelExercise = () => {
    console.log("cancel exercise");
    webSocket.current.send(
      JSON.stringify({
        method: "cancel_exercise",
      })
    );
  };

  const jumpExercise = (index: number) => {
    console.log("jump", index);
    webSocket.current.send(
      JSON.stringify({
        method: "jump",
        params: {
          target: index,
        },
      })
    );
  };

  // const nextExercise = () => {
  //   console.log("next");
  //   webSocket.current.send(
  //     JSON.stringify({
  //       method: "next",
  //     })
  //   );
  // };

  const setProperties = (props) => {
    webSocket.current.send(
      JSON.stringify({
        method: "set_properties",
        params: { properties: props },
      })
    );
  };

  const closeSession = () => {
    console.log("close_sesion");
    webSocket.current.send(
      JSON.stringify({
        method: "close_session",
      })
    );
  };

  useEffect(() => {
    if (!online) {
      toast.promise(onlineBack(), {
        loading: "Offline",
        success: "Online",
        error: "",
      });
    }
  }, [online]);

  useEffect(() => {
    if (online) {
      const token = localStorage.getItem(AccessToken);
      const socketUrl = !role.guest
        ? `${wsUrl}/live_session?token=${token}`
        : `${wsUrl}/live_session`;

      webSocket.current = new WebSocket(socketUrl);

      webSocket.current.onopen = (e) => {
        setState(WSStatus.connected);
        joinSession();
        screenAwake().getScreenLock();
        console.log("onopen callback");
      };

      webSocket.current.onmessage = (e) => {
        const data = JSON.parse(e.data);
        if (data?.error === "INVALID_PIN") {
          setPinValid(false);
        }
        if (data?.error === "SESSION_LOCKED") {
          setSessionLocked(true);
        }
        if (data?.error === "USER_BANNED") {
          setUserBanned(true);
        }
        if (data?.error === "NAME_ALREADY_TAKEN") {
          setNameTaken(true);
        }
        if (data?.error === "USER_REJECTED") {
          setUserRejected(true);
        }
        if (data?.error === "NO_PARTICIPANTS") {
          toast("Invite participants to start session", { icon: "ℹ️" });
        }
        if (data.method === "update" && data.unit?.exercises) {
          setExercises(data.unit.exercises);
        }
        if (data.method === "update") {
          setName(data.session?.unit_name ?? data.unit?.name);
        }
        if (data.method === "update" && data?.session?.pin) {
          console.log("session update");
          localStorage.removeItem("joining");
          setStatus(data.session);
        }
        if (JSON.parse(e.data).error) return;
      };

      webSocket.current.onreconnect = (e) => {
        setState(WSStatus.reconnecting);
        joinSession();
        console.log("onreconnect callback");
      };

      webSocket.current.onclose = (e) => {
        console.log("onclose callback", e);
        setTimeout(() => {
          setReconnect(reconnect + 1);
        }, 1000);
      };

      webSocket.current.onerror = (e) => {
        console.log(e);
      };

      return () => webSocket.current.close();
    } else {
      webSocket.current.close();
    }
  }, [online, reconnect]);

  if (!GuestInfo()?.name && !token) return;

  return {
    sessionLocked,
    userBanned,
    nameTaken,
    userRejected,
    pinValid,
    state,
    status,
    name,
    exercises,
    isDuration,
    initSession,
    createSession,
    joinSession,
    banUser,
    startSession,
    submitSession,
    closeExercise,
    forceCloseExercise,
    // nextExercise,
    setProperties,
    closeSession,
    jumpExercise,
    startExercise,
    cancelExercise,
  };
};
