import React, { useState } from "react";
import { useMediaPlayer } from "../hooks/usePlayer";
import { getTrackBackground, Range, useThumbOverlap } from "react-range";
import classNames from "classnames";
import { FaPause, FaPlay, FaSpinner, FaUndoAlt } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { checkTouch } from "helpers/detectMobileDevice";
import styled from "styled-components";
import { roundTo } from "helpers/roundTo";
import useResizeObserver from "use-resize-observer";

const doubleDigit = (number: number) => (number < 10 ? "0" + number : number);

const formatTime = (time: number, withMs = false) => {
  const minute = Math.floor(time / 10 / 60);
  const seconds = Math.floor((time / 10) % 60);
  const ms = time % 10;
  return minute + ":" + doubleDigit(seconds) + (withMs ? "." + ms : "");
};

const ThumbLabel = ({
  rangeRef,
  values,
  index,
  isDragged,
}: {
  rangeRef: Range | null;
  values: [number];
  index: number;
  isDragged: boolean;
}) => {
  const [labelValue, style] = useThumbOverlap(
    rangeRef,
    values,
    index,
    1,
    undefined,
    (value) => formatTime(parseInt(value), true)
  );
  return (
    <div
      className={classNames(
        "absolute -top-1 text-white bg-primary font-bold text-sm p-1 whitespace-nowrap rounded m-auto left-1/2 transform -translate-x-1/2 transition",
        isDragged
          ? "opacity-100 -translate-y-full"
          : "opacity-0 -translate-y-3/4"
      )}
      data-label={index}
      style={style as React.CSSProperties}
    >
      {labelValue}
    </div>
  );
};

const MediaRange = ({ duration, currentTime, goToTime }) => {
  const rangeRef: any = React.useRef<Range>();

  if (duration == null)
    return (
      <div
        className="h-2 w-full rounded-lg self-center"
        style={{ background: "#0003" }}
      />
    );

  return (
    <Range
      ref={rangeRef}
      values={[currentTime]}
      step={1}
      min={0}
      max={duration}
      onChange={([time]) => goToTime(time)}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          style={{
            ...props.style,
            background: getTrackBackground({
              values: [currentTime],
              min: 0,
              max: duration,
              colors: ["rgb(var(--brand-color))", "#0003"],
            }),
          }}
          className="h-2 w-full rounded-lg self-center panningDisabled"
        >
          {children}
        </div>
      )}
      renderThumb={({ props, value, isDragged, index }) => (
        <NoTransformDiv
          className="w-6 h-6 rounded-full outline-0 bg-primary panningDisabled"
          {...props}
          style={{
            ...props.style,
            left: roundTo((value / duration) * 100) + "%",
          }}
        >
          <ThumbLabel
            rangeRef={rangeRef.current}
            isDragged={isDragged}
            values={[currentTime]}
            index={index}
          />
        </NoTransformDiv>
      )}
    />
  );
};

const NoTransformDiv = styled.div`
  transform: translate(-30%, -30%) !important;
`;

export const PlayerBar = ({
  player,
  isOpen,
}: {
  player: ReturnType<typeof useMediaPlayer>;
  isOpen?: boolean | undefined;
}) => (
  <div
    className={classNames(
      "flex items-center text-white p-4 bg-black bg-opacity-20 backdrop-blur rounded-lg transition panningDisabled",
      isOpen === false && "opacity-0 translate-y-1"
    )}
    onMouseDown={(e) => e.stopPropagation()}
    onPointerDown={(e) => e.stopPropagation()}
  >
    <div
      className={classNames(
        "text-left text-sm whitespace-nowrap panningDisabled hidden sm:block",
        player.duration == null ? "w-10" : "w-20"
      )}
    >
      {player.isLoading ? (
        <Skeleton height={12} width="100%" />
      ) : (
        <>
          {formatTime(player.currentTime)}
          {player.duration != null && ` / ${formatTime(player.duration)}`}
        </>
      )}
    </div>
    <div className="flex-grow sm:mx-2 panningDisabled">
      {player.isLoading ? (
        <Skeleton height={12} width="100%" />
      ) : (
        <MediaRange {...player} />
      )}
    </div>
  </div>
);

export const AudioPlayer = ({
  src,
  cover,
}: {
  src: string;
  cover?: boolean;
}) => {
  const player = useMediaPlayer<HTMLAudioElement>();

  const handleClick =
    player.currentTime === player.duration
      ? player.restart
      : player.isPlaying
      ? player.pause
      : player.play;

  const { ref, width, height } = useResizeObserver();
  const isSmall = cover && ((width && width < 150) || (height && height < 150));

  return (
    <div
      className={classNames(
        "flex flex-col select-none p-2 relative bg-gray-200 rounded-xl group w-full mx-auto audio-player",
        cover ? "absolute-cover" : "max-w-2xl"
      )}
      ref={cover ? ref : undefined}
    >
      <audio src={src} {...player.bind} preload="metadata" />
      {!cover && (
        <div
          className="rounded-xl absolute w-full h-full left-0 top-0 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
        />
      )}
      <div
        className={classNames(
          "flex items-center justify-center text-white flex-grow",
          !isSmall && "p-4"
        )}
      >
        <button
          className={classNames(
            "flex items-center justify-center bg-black backdrop-blur bg-opacity-20 rounded-full z-[1]",
            cover ? "cursor-pointer" : "pointer-events-none",
            isSmall
              ? "w-16 h-16 [&>svg]:text-xl"
              : "w-16 h-16 [&>svg]:text-xl sm:w-24 sm:h-24 [&>svg]:sm:text-4xl"
          )}
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
          onMouseDown={(e) => e.stopPropagation()}
          onPointerDown={(e) => e.stopPropagation()}
        >
          {player.isLoading ? (
            <FaSpinner className="animate-spin" />
          ) : player.currentTime === player.duration ? (
            <FaUndoAlt />
          ) : player.isPlaying ? (
            <FaPause />
          ) : (
            <FaPlay />
          )}
        </button>
      </div>
      {!isSmall && <PlayerBar player={player} />}
    </div>
  );
};

export const VideoPlayer = ({
  src,
  cover,
}: {
  src: string;
  cover?: boolean;
}) => {
  const player = useMediaPlayer<HTMLVideoElement>();
  const [isOpen, setIsOpen] = useState(true);

  const isTouch = checkTouch();

  const showOverlay = isOpen || !player.isPlaying;

  const handleBgClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isTouch) return setIsOpen((open) => !open);
    toggle();
  };

  const withHide = (action: () => void) => () => {
    if (isTouch) setIsOpen(false);
    action();
  };

  const toggle =
    player.currentTime === player.duration
      ? withHide(player.restart)
      : player.isPlaying
      ? player.pause
      : withHide(player.play);

  const aspectRatio = player.size
    ? `${player.size.width} / ${player.size.height}`
    : "16 / 9";

  const { ref, width, height } = useResizeObserver();
  const isSmall = cover && ((width && width < 150) || (height && height < 200));

  return (
    <div
      className={classNames(
        "flex flex-col select-none p-2 relative bg-gray-200 rounded-xl w-full mx-auto video-player",
        cover ? "absolute-cover" : "max-w-2xl"
      )}
      style={!cover ? { aspectRatio } : {}}
      ref={cover ? ref : undefined}
      onMouseEnter={() => !isTouch && setIsOpen(true)}
      onMouseLeave={() => !isTouch && setIsOpen(false)}
    >
      <video
        src={src}
        {...player.bind}
        playsInline
        preload="metadata"
        className="pointer-events-none absolute left-0 top-0 w-full h-full object-cover rounded-xl"
      />
      <div
        className={classNames(
          "flex items-center justify-center text-white flex-grow",
          !showOverlay && "opacity-0 group-hover:opacity-100 transition",
          !isSmall && "p-6",
          (!cover || isTouch) && "cursor-pointer"
        )}
        onContextMenu={(e) => e.preventDefault()}
        onClick={(e) => (!cover || isTouch) && handleBgClick(e)}
      >
        <button
          className={classNames(
            "flex items-center justify-center bg-black backdrop-blur bg-opacity-20 rounded-full z-[1]",
            !showOverlay && "pointer-events-none",
            isSmall
              ? "w-16 h-16 [&>svg]:text-xl"
              : "w-16 h-16 [&>svg]:text-xl sm:w-24 sm:h-24 [&>svg]:sm:text-4xl"
          )}
          onClick={(e) => {
            e.stopPropagation();
            showOverlay && toggle();
          }}
          onMouseDown={(e) => e.stopPropagation()}
          onPointerDown={(e) => e.stopPropagation()}
        >
          {player.isLoading ? (
            <FaSpinner className="animate-spin" />
          ) : player.currentTime === player.duration ? (
            <FaUndoAlt />
          ) : player.isPlaying ? (
            <FaPause />
          ) : (
            <FaPlay />
          )}
        </button>
      </div>
      {!isSmall && <PlayerBar player={player} isOpen={showOverlay} />}
    </div>
  );
};
