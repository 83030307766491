import { useSessionContext } from "app/hooks/useSessionContext";
import { getSessionStatus } from "app/components/Exercises/utils/sessionStatus";
import { NewButton } from "app/components/Buttons/NewButton";
import { CgSpinner } from "react-icons/cg";
import React from "react";
import { TbAlertCircle, TbCheck, TbPlayerPlay } from "react-icons/tb";
import { useTranslation } from "react-i18next";

export const TeacherExerciseBar = () => {
  const { t } = useTranslation();
  const { session, isTeacher } = useSessionContext();
  const { status, participants, results_submitted } = session.get?.status ?? {};
  const { slide, exerciseBefore, exerciseClosing } = getSessionStatus(status);

  if (!isTeacher.get || slide) return null;

  // const isPolling = exercise_content?.type?.startsWith("polling_");

  const activeParticipantsCount = participants.filter(
    (participant) => participant.status !== "banned"
  ).length;

  const { startExercise, cancelExercise, closeExercise } = session.get;

  return (
    <div className="bg-gray-700 shadow-xl rounded-xl p-2 absolute z-[5] flex items-center text-white top-2 right-2 overflow-hidden">
      <div
        className="mr-auto font-bold ml-1.5 flex items-center gap-2 animate-slideUp"
        key={`text-${exerciseBefore ? "waiting" : "exercise"}`}
      >
        <TbAlertCircle className="text-2xl" />
        {exerciseBefore
          ? t("liveSession.exerciseBefore")
          : t("liveSession.exercisePending")}
      </div>
      <div
        className="ml-6 gap-1 flex items-center animate-slideUp"
        key={`buttons-${exerciseBefore ? "waiting" : "exercise"}`}
      >
        {exerciseBefore ? (
          <NewButton variant="primary" onClick={startExercise} key="start">
            <TbPlayerPlay />
            {t("liveSession.start")}
          </NewButton>
        ) : (
          <>
            <NewButton
              key="cancel"
              color="bg-red-400 text-red-400"
              className="font-bold "
              variant="strongTransparent"
              disabled={exerciseClosing}
              onClick={cancelExercise}
            >
              {t("common.cancel")}
            </NewButton>
            <NewButton
              key="stop"
              variant="primary"
              onClick={closeExercise}
              disabled={exerciseClosing}
              className="relative"
            >
              {results_submitted === activeParticipantsCount && (
                <div className="absolute-cover rounded-xl bg-primary pointer-events-none animate-ping bg-opacity-50" />
              )}
              {exerciseClosing ? (
                <CgSpinner className="animate-spin" />
              ) : (
                <TbCheck />
              )}
              {t("exercisePage.consumption.formatsCommands.finish")}
            </NewButton>
          </>
        )}
      </div>
    </div>
  );
};
