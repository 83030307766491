import React, { useEffect } from "react";
import StatusDisplay from "./StatusDisplay";
import { useSessionContext } from "app/hooks/useSessionContext";
import SessionConsumption from "./SessionConsumption";
import { CourseStudentResults } from "./StudentResults";
import SessionSummary from "./SessionSummary";
import { screenAwake } from "../utils/screenAwake";
import SessionSummaryNoLeaderboard from "./SessionSummaryNoLeaderboard";
import Transition from "app/components/Exercises/LiveSession/transition";
import { getSessionStatus } from "app/components/Exercises/utils/sessionStatus";
import { FaChalkboardTeacher } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const StudentView = () => {
  const { t } = useTranslation();
  const { session } = useSessionContext();
  const { current_exercise, status, exercise_content, exercise_submitted } =
    session.get.status;

  const has_leaderboard =
    !!session.get.status?.is_leaderboard &&
    !!session.get.status?.leaderboard_results;

  const { consumption, results, leaderboard, exerciseBefore } =
    getSessionStatus(status);

  const submitted = exercise_submitted;

  useEffect(() => {
    screenAwake().getScreenLock();
  }, [status, current_exercise, exercise_content, exercise_submitted]);

  useEffect(() => {}, [status, results, leaderboard, current_exercise]);

  return (
    <div className="relative z-[2] flex-1 flex" id="exercise-mixed-consumption">
      <Transition
        key={status?.slice(0, 5)}
        className="absolute-cover flex flex-col !overflow-y-auto p-1 xs:p-2 gap-1 md:gap-2 !pt-0"
      >
        {exerciseBefore ? (
          <>
            <div className="bg-gray-100 rounded-lg flex flex-col items-center justify-center text-gray-500 flex-1 relative p-8 shadow-xl">
              <FaChalkboardTeacher className="text-5xl mb-2" />
              <div className="font-bold text-center text-2xl leading-tight">
                {t("liveSession.studentExerciseWait")}
              </div>
            </div>
          </>
        ) : consumption ? (
          <SessionConsumption submitted={submitted} />
        ) : results ? (
          <CourseStudentResults />
        ) : leaderboard ? (
          has_leaderboard ? (
            <SessionSummary />
          ) : (
            <SessionSummaryNoLeaderboard />
          )
        ) : (
          <StatusDisplay />
          // <StatusDisplay loading={loading} />
        )}
      </Transition>
    </div>
  );
};

export default StudentView;
