import { Redirect, Switch } from "react-router";
import { groupRoutes } from "enums/routes";
import React from "react";
import { GroupsList } from "app/pages/groups/GroupsList";
import { GroupAssignments } from "app/pages/groups/GroupAssignments";
import { AppRoute } from "app/routes/helpers/AppSwitch";
import { GroupMembers } from "app/pages/groups/GroupMembers";
import { GroupSettings } from "app/pages/groups/GroupSettings";

export const GroupsRoutes = () => {
  return (
    <Switch>
      <AppRoute
        path={[groupRoutes.list, groupRoutes.join()]}
        component={GroupsList}
        exact
      />
      <Redirect
        from={groupRoutes.details()}
        to={groupRoutes.assignments()}
        exact
      />
      <AppRoute
        path={groupRoutes.assignments()}
        component={GroupAssignments}
        exact
        noLayout
      />
      <AppRoute
        path={groupRoutes.members()}
        component={GroupMembers}
        exact
        noLayout
      />
      <AppRoute
        path={groupRoutes.settings()}
        component={GroupSettings}
        exact
        noLayout
      />
    </Switch>
  );
};
