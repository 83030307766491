import {
  IPaginationSortInfo,
  ISearchPhrase,
} from "../models/api/request.interface";
import axios from "axios";
import { apiUrlV1 } from "../enums/constant";

export async function getAllMyOrganizations() {
  const url = `${apiUrlV1}/organizations/`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function getAdminOrganization(id: string) {
  const url = `${apiUrlV1}/organizations/${id}/`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function getOrganizationPatterns(id: string) {
  const url = `${apiUrlV1}/organization/${id}/email_patterns/`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function deleteOrganizationPatterns(
  id: string,
  pattern_id: number
) {
  const url = `${apiUrlV1}/organization/${id}/email_patterns/${pattern_id}/`;
  const { data } = await axios.delete<any>(url);
  return data;
}

export async function postOrganizationPatterns(id: string, dataToSave: any) {
  const url = `${apiUrlV1}/organization/${id}/email_patterns/`;
  const { data } = await axios.post<any>(url, dataToSave);
  return data;
}

export async function getUsersOrganization(
  id: string,
  page: number,
  search: string
) {
  const url = `${apiUrlV1}/organization/${id}/members/?page_size=100&page=${page}&search=${search}`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function getGroupsForUser(id: string, member_id: string) {
  const url = `${apiUrlV1}/organization/${id}/groups/${member_id}/`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function putOrganizationUserDetails(
  id: string,
  member_id: string,
  dataToSave
) {
  const url = `${apiUrlV1}/organization/${id}/member/${member_id}/`;
  const { data } = await axios.put<any>(url, dataToSave);
  return data;
}

export async function getOrganizationGroups(id: string) {
  const url = `${apiUrlV1}/organization/${id}/groups/?page_size=500`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function getAllUsers({
  page,
  sortParam,
  phrase,
}: IPaginationSortInfo & ISearchPhrase) {
  const url = `${apiUrlV1}/users/?page_size=100&page=${page}&order_by=${sortParam}&search=${phrase}`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function getUser({ id }: { id: string }) {
  const url = `${apiUrlV1}/user/${id}/`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function putUser({
  id,
  dataToSave,
}: {
  id: string;
  dataToSave: any;
}) {
  const url = `${apiUrlV1}/user/${id}/`;
  const { data } = await axios.put<any>(url, dataToSave);
  return data;
}

export async function putAddUserToOrganization({ id, dataToSave }: any) {
  const url = `${apiUrlV1}/organization/members/add/${id}/`;
  const { data } = await axios.put<any>(url, dataToSave);
  return data;
}

export async function updateOrganization(id: string, data) {
  const url = `${apiUrlV1}/organizations/${id}/`;
  const res = await axios.put<any>(url, data);
  return res.data;
}

export async function postCreateOrganization(dataToSave: {
  name: string;
  is_b2c: boolean;
}) {
  const url = `${apiUrlV1}/organizations/`;
  const { data } = await axios.post<any>(url, dataToSave);
  return data;
}

export async function putRemoveGroupFromOrganization({ id, dataToSave }: any) {
  const url = `${apiUrlV1}/organization/${id}/groups/remove/`;
  const { data } = await axios.put<any>(url, dataToSave);
  return data;
}

export async function putRemoveUserFromOrganization({ id, dataToSave }: any) {
  const url = `${apiUrlV1}/organization/members/remove/${id}/`;
  const { data } = await axios.put<any>(url, dataToSave);
  return data;
}

export async function putAddUsersToOrganizationGroup(id, group_id, dataToSave) {
  const url = `${apiUrlV1}/organization/${id}/group/members/add/${group_id}/`;
  const { data } = await axios.put<any>(url, dataToSave);
  return data;
}

export async function putRemoveUsersToOrganizationGroup(
  id,
  group_id,
  dataToSave
) {
  const url = `${apiUrlV1}/organization/${id}/group/members/remove/${group_id}/`;
  const { data } = await axios.put<any>(url, dataToSave);
  return data;
}

export async function putUsersRoleToOrganizationGroup(
  id,
  group_id,
  member_id,
  dataToSave
) {
  const url = `${apiUrlV1}/organization/${id}/group/${group_id}/member/${member_id}/`;
  const { data } = await axios.put<any>(url, dataToSave);
  return data;
}

export async function putRemoveOrganization(dataToSave) {
  const url = `${apiUrlV1}/organizations/remove/`;
  const { data } = await axios.put<any>(url, dataToSave);
  return data;
}
