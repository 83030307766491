import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import classNames from "classnames";
import { LibrarySwitchButtons } from "app/pages/library/LibraryView";
import { useLibrary } from "app/components/Sources/MediaPicker/SourceList/useLibrary";
import { FileType, mapFileTypeToName, mapSourceToType } from "helpers/mimeType";
import {
  MediaPicker,
  SelectOnlyType,
} from "app/components/Sources/MediaPicker/MediaPicker";
import { Skeleton } from "app/pages/courses/Edit/CourseSourcesPanel";
import {
  TbBook,
  TbChevronDown,
  TbCloudDownload,
  TbDiscountCheckFilled,
  TbDownload,
  TbFilter,
  TbFolder,
  TbRefresh,
  TbSearch,
  TbUpload,
  TbVolume,
  TbWorld,
} from "react-icons/tb";
import { FloatingMenu } from "app/components/Header";
import { NewButton } from "app/components/Buttons/NewButton";
import { FilterSelectMenu } from "app/components/Sources/MediaPicker/SourceList/SourceList";
import { translateError } from "app/components/Exercises/Edit/missingTranslation";
import { InView } from "react-intersection-observer";
import { TrainingPreview } from "app/pages/courses/Edit/CourseHeader";
import { useMutation, useQueryClient } from "react-query";
import { postImportPublicItem } from "api/sourcesAPI";
import { toast } from "react-hot-toast";
import {
  BigModal,
  BigModalBody,
  BigModalClose,
  BigModalHeader,
} from "app/components/BigModal";
import { NewFolderPicker } from "app/pages/courses/NewFolderPicker";
import { Tooltip } from "app/components/Tooltip";
import { ExportCourseModal } from "../courses/components/ExportCourseModal";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import useDownloader from "react-use-downloader";

export const PublicLibraryView = () => {
  const { t } = useTranslation();

  const { list, query, input, setInput, bindFilter, filters } = useLibrary({
    initialFilters: {
      lesson: false,
      course: true,
      document: true,
      image: true,
      video: true,
      audio: true,
    },
    publicLibrary: true,
  });

  const [imported, setImported] = useState<any>(null);
  const [sourceId, setSourceId] = useState<string | null>(null);
  const [trainingId, setTrainingId] = useState<string | null>(null);

  const onItemClick = (type: FileType, id: string) => () => {
    if (type === FileType.Course) return setTrainingId(id);
    if (type === FileType.Folder) return;
    setSourceId(id);
  };

  return (
    <div className="py-4 px-3 sm:px-8">
      <div className="flex gap-1 mb-2">
        <LibrarySwitchButtons />
        <div className="grow" />
      </div>
      <div className="flex flex-col bg-white rounded-xl">
        <div className="flex flex-col text-gray-600">
          <div
            className={classNames(
              "flex flex-col sticky w-full bg-white pb-1 pt-1 rounded-xl",
              "top-24 md:top-14 z-[2] transition"
            )}
          >
            <div className="px-2 py-1 font-bold flex items-center gap-1">
              <TbWorld className="text-2xl shrink-0 mr-1 ml-2" />
              {t("v4.library.publicLibrary")}
              <div className="grow" />

              <label
                className={classNames(
                  "hidden sm:flex bg-gray-100 group focus-within:bg-gray-200 group transition rounded-lg items-center text-gray-600"
                )}
              >
                <TbSearch className="text-xl ml-2" />
                <input
                  className={classNames(
                    "max-w-[10rem] px-2 transition-all py-1.5 min-w-0 bg-transparent outline-none"
                  )}
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder={t("v4.generic.search")}
                />
              </label>

              <FloatingMenu
                trigger={(toggle) => (
                  <NewButton onClick={toggle} className="hidden sm:flex">
                    <TbFilter /> {t("v4.generic.filter")}
                    <TbChevronDown className="!text-xs" />
                  </NewButton>
                )}
                placement="bottom-end"
                size="xs"
                className="space-y-1"
                portal
              >
                <FilterSelectMenu bindFilter={bindFilter} />
              </FloatingMenu>

              <NewButton
                iconOnly
                size="lg"
                className="shrink-0 hidden sm:flex"
                onClick={() => {
                  query.remove();
                  query.refetch();
                }}
              >
                <TbRefresh />
              </NewButton>

              <NewButton
                iconOnly
                variant="transparent"
                className="shrink-0 sm:hidden"
                onClick={() => {
                  query.remove();
                  query.refetch();
                }}
              >
                <TbRefresh />
              </NewButton>
            </div>
            <div className="flex sm:hidden px-2 gap-1">
              <label className="flex grow bg-gray-100 focus-within:bg-gray-200 transition rounded-lg items-center">
                <TbSearch className="text-xl ml-2" />
                <input
                  className="py-1 px-2 min-w-0 w-full bg-transparent outline-none"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder={t("v4.generic.search")}
                />
              </label>

              <FloatingMenu
                portal
                trigger={(toggle) => (
                  <NewButton onClick={toggle} iconOnly>
                    <TbFilter />
                  </NewButton>
                )}
                placement="bottom-end"
                size="sm"
                className="space-y-1"
              >
                <FilterSelectMenu bindFilter={bindFilter} />
              </FloatingMenu>
            </div>
          </div>

          {!!trainingId && (
            <TrainingPreview
              courseId={trainingId}
              close={() => setTrainingId(null)}
            />
          )}

          {!!sourceId && (
            <MediaPicker
              close={() => setSourceId(null)}
              sourceId={sourceId}
              onInsert={null}
              selectOnly={SelectOnlyType.none}
            />
          )}

          {!query.isSuccess ? (
            <Skeleton />
          ) : (
            <>
              <div
                className={classNames(
                  "absolute-cover p-8 z-20 pointer-events-none bg-white bg-opacity-50 filter transition backdrop-blur opacity-0"
                )}
              >
                <div className="w-full h-full border-4 rounded-lg border-dashed border-gray-300 flex flex-col gap-4 text-center items-center justify-center text-gray-500">
                  <TbUpload className="text-5xl" strokeWidth={3} />
                  <div className="text-xl font-bold">
                    {t("v4.library.uploadDrop")}
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                {!list.length ? (
                  <div className="text-center text-gray-400 py-5">
                    {t("v4.library.noItems")}
                  </div>
                ) : (
                  list.map((item, i) => (
                    <PublicLibraryItem
                      key={item?.id ?? i}
                      item={item}
                      onClick={onItemClick}
                      onImport={setImported}
                    />
                  ))
                )}
              </div>
              {query.hasNextPage && (
                <InView
                  as="div"
                  className="flex"
                  onChange={(inView) =>
                    inView && !query.isFetchingNextPage && query.fetchNextPage()
                  }
                  key={query.data.pages.length}
                >
                  {({ inView, ref }) => (
                    <div
                      ref={ref}
                      className={classNames(
                        "flex flex-col gap-3 transition",
                        !inView && "opacity-0"
                      )}
                    >
                      <Skeleton />
                    </div>
                  )}
                </InView>
              )}
            </>
          )}
        </div>
      </div>
      {!!imported && (
        <ImportPublishedItem item={imported} close={() => setImported(null)} />
      )}
    </div>
  );
};

const PublicLibraryItem = ({ item, onClick, onImport }) => {
  const { id, name, thumbnail_url } = item;
  const { t } = useTranslation();
  const type = mapSourceToType(item);
  const [openExport, setOpenExport] = useState(false);
  const downloader = useDownloader();

  return (
    <>
      <div
        key={item.id + "_" + item.entry_id}
        onClick={onClick(type, id)}
        className={classNames(
          "flex items-center mx-3 gap-2 px-1 py-1 rounded-lg bg-gray-800 bg-opacity-0 hover:bg-opacity-5 cursor-pointer transition group",
          type === FileType.Folder && "-order-1"
        )}
      >
        <div className="w-9 h-9 shrink-0 relative flex items-center justify-center">
          {type === FileType.Folder ? (
            <TbFolder className="text-3xl text-gray-500" strokeWidth={1.5} />
          ) : type === FileType.Course ? (
            <TbBook className="text-3xl text-gray-500" strokeWidth={1.5} />
          ) : type === FileType.Audio ? (
            <TbVolume className="text-3xl text-gray-500" strokeWidth={1.5} />
          ) : (
            thumbnail_url && (
              <img
                src={thumbnail_url}
                className="absolute-cover object-cover bg-gray-200 rounded-lg overflow-hidden"
              />
            )
          )}
        </div>
        <div className="flex flex-col min-w-0 leading-none grow">
          <div className="font-bold overflow-hidden overflow-ellipsis whitespace-nowrap">
            {name}
          </div>
          <div className="text-xs text-gray-400 overflow-hidden overflow-ellipsis whitespace-nowrap">
            <span>{mapFileTypeToName(t)(type)}</span>
            {item?.author && (
              <>
                <span> • </span>
                <span>
                  {t("v4.library.sharedBy")}{" "}
                  {item?.is_verified ? (
                    <Tooltip
                      value={t("v4.library.verifiedAuthor")}
                      className="inline"
                    >
                      <div className="inline-flex items-center gap-0.5 font-bold">
                        <div>
                          <TbDiscountCheckFilled className="text-sm inline -mt-0.5" />
                        </div>
                        {item.author}
                      </div>
                    </Tooltip>
                  ) : (
                    item.author
                  )}
                </span>
              </>
            )}
          </div>
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex items-center gap-1"
        >
          <Tooltip value={t("v4.generic.import")}>
            <NewButton
              iconOnly
              variant="transparent"
              className="opacity-50 group-hover:opacity-100"
              color="bg-primary"
              size="lg"
              onClick={() => onImport(item)}
            >
              <TbCloudDownload />
            </NewButton>
          </Tooltip>
          <Tooltip value={t("v4.export.download")}>
            <NewButton
              iconOnly
              variant="transparent"
              className="opacity-50 group-hover:opacity-100"
              color="bg-primary"
              size="lg"
              onClick={() => {
                if (downloader.isInProgress) return downloader.cancel();
                if (type === FileType.Course) setOpenExport(true);
                else if (item?.download_url)
                  downloader.download(item.download_url, name);
              }}
            >
              {downloader.isInProgress ? (
                <CircularProgressbar
                  value={downloader.percentage}
                  className="!w-[1em] h-[1em]"
                  strokeWidth={15}
                  styles={buildStyles({
                    pathColor: "var(--primary-color)",
                    strokeLinecap: "butt",
                  })}
                />
              ) : (
                <TbDownload />
              )}
            </NewButton>
          </Tooltip>
          {/*<SourceContextMenu*/}
          {/*  options={[*/}
          {/*    ["assign", "present"],*/}
          {/*    ["edit", "preview", "send", "download"],*/}
          {/*    ["rename", "move", "delete"],*/}
          {/*  ]}*/}
          {/*  item={{ id, type, name, children: child_count }}*/}
          {/*  onRename={() => setRename(name)}*/}
          {/*/>*/}
        </div>
      </div>

      {openExport && !!item?.id && type === FileType.Course && (
        <ExportCourseModal
          open
          close={() => setOpenExport(false)}
          courseId={item.id}
        />
      )}
    </>
  );
};

const ImportPublishedItem = ({
  item,
  close,
}: {
  item: any;
  close: () => void;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const isTraining = item.item_type === 3;

  const saveMutation = useMutation(
    (folder: string) => postImportPublicItem(item.publication_id, folder),
    {
      onSuccess: (_, folder) => {
        toast.success(
          isTraining
            ? t("v4.training.saveSuccess")
            : t("v4.library.save.success")
        );
        queryClient.invalidateQueries(["courses"]);
        queryClient.invalidateQueries(
          folder ? ["library", folder] : ["library"]
        );
      },
      onError: () => {
        toast.error(
          translateError(
            isTraining
              ? t("v4.training.saveError")
              : t("v4.library.save.error"),
            t
          )
        );
      },
      onSettled: () => {
        close();
      },
    }
  );

  return (
    <BigModal fit>
      <BigModalHeader className="pl-4">
        {isTraining ? t("v4.training.saveShared") : t("v4.library.save.info")}
        <BigModalClose close={close} />
      </BigModalHeader>
      <BigModalBody className="text-gray-600 max-w-sm" maxWidth>
        <div className="text-sm font-bold mb-1">
          {isTraining ? t("v4.training.text") : t("v4.library.source")}
        </div>
        <div className="rounded-lg bg-gray-100 px-2 py-1 font-bold mb-4 pr-6">
          {item.name}
        </div>

        <div className="text-sm font-bold mb-1">{t("v4.library.folder")}</div>
        <div className="rounded-xl border border-gray-100 p-2 flex items-center justify-center relative">
          <NewFolderPicker
            close={close}
            loading={saveMutation.isLoading}
            onSelect={saveMutation.mutate}
            hideExit
            className="!w-full"
          />
        </div>
      </BigModalBody>
    </BigModal>
  );
};
