import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import { SlideEmbed } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { SlideItemWrapper } from "app/components/Exercises/CourseEdit/slide/SlideItemWrapper";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { youtubeId } from "helpers/extractYoutubeVideoId";
import { useSlideItemContext } from "app/components/Exercises/CourseEdit/items/SlideItemContext";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
import objectHash from "object-hash";
import {
  gridToItem,
  SLIDE_COLS,
  SLIDE_HEIGHT,
  SLIDE_ROWS,
  SLIDE_WIDTH,
} from "app/components/Exercises/Edit/questionType/Slide/slideUtils";
import { TbPlayerPlayFilled } from "react-icons/tb";
import useOnClickOutside from "use-onclickoutside";
import { youtubeThumbnail } from "app/components/Exercises/CourseEdit/items/content/PageEmbedItem";

export const SlidePageEmbed = () => {
  const { t } = useTranslation();
  const [{ data, position, id }, set] = usePageItemContext<SlideEmbed>();
  const { selected } = useSlideItemContext();
  const [ref, setRef] = useState<HTMLInputElement | null>(null);
  const handleRef = useCallback(setRef, []);

  useEffect(() => {
    if (!selected) return;
    if (!ref) return;

    setTimeout(() => {
      ref.focus();
    }, 0);
  }, [selected, ref]);

  const videoId = youtubeId(data);

  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, () => preview && setPreview(false));
  const [preview, setPreview] = useState(false);

  const size = useMemo(() => {
    if (position.w === SLIDE_COLS && position.h === SLIDE_ROWS)
      return { w: SLIDE_WIDTH, h: SLIDE_HEIGHT };
    return gridToItem({ ...position, i: id });
  }, [objectHash(position)]);

  return (
    <SlideItemWrapper
      toolbar={
        <label className="h-full flex items-center">
          <input
            ref={handleRef}
            value={data}
            onChange={(e) =>
              set((item) => {
                item.data = e.target.value;
              })
            }
            className="h-full w-64 text-gray-500 outline-none p-2 bg-transparent focus:bg-gray-100 rounded-lg"
            placeholder={t("media.youtube.link")}
          />
        </label>
      }
    >
      <img
        src={youtubeThumbnail({ id: videoId })}
        className="absolute-cover object-cover rounded-2xl"
      />
      <div className="absolute-cover flex relative" ref={containerRef}>
        <div
          className="bg-white shadow-xl w-20 h-20 rounded-full m-auto opacity-80 text-gray-600 flex hover:opacity-100 transition cursor-pointer"
          onClick={() => setPreview(true)}
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
        >
          <TbPlayerPlayFilled className="m-auto text-5xl" />
        </div>
      </div>

      {preview && (
        <YouTube
          opts={{
            width: `${size.w}`,
            height: `${size.h}`,
            playerVars: { autoplay: 1 },
          }}
          videoId={videoId}
          className="w-full mx-auto rounded-xl"
          containerClassName="absolute-cover"
        />
      )}
    </SlideItemWrapper>
  );
};

export const SlideEmbedRender = ({ item }: { item: SlideEmbed }) => {
  const videoId = youtubeId(item.data);

  const size = useMemo(() => {
    if (item.position.w === SLIDE_COLS && item.position.h === SLIDE_ROWS)
      return { w: SLIDE_WIDTH, h: SLIDE_HEIGHT };
    return gridToItem({ ...item.position, i: item.id });
  }, [objectHash(item.position)]);

  return (
    <YouTube
      opts={{ width: `${size.w}`, height: `${size.h}` }}
      videoId={videoId}
      className="w-full mx-auto rounded-xl"
      containerClassName="absolute-cover"
    />
  );
};
