import React, { useCallback } from "react";
import { userSignInApple } from "../../store/authentication/authActions";
import urlParametersAsObj from "../../../helpers/urlParametersAsObj";
import { useDispatch } from "react-redux";
import AppleSignin from "react-apple-signin-auth";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbBrandApple } from "react-icons/tb";
import { useTranslation } from "react-i18next";

export const LoginApple = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleAppleLoginCallback = useCallback(
    async (response) => {
      console.log(response);
      const res: any = await dispatch(
        userSignInApple({
          access_token: response.authorization.id_token,
          user: response?.user ? response.user : {},
          context: urlParametersAsObj(),
        })
      );

      // if (userSignInApple.fulfilled.match(res)) {
      //   setRequestError(false);
      // } else {
      //   setRequestError(true);
      // }
    },
    [dispatch]
  );

  const errAppleResponse = (response: any) => {
    console.log("AppleResponse Error", response);
  };

  return (
    <AppleSignin
      authOptions={{
        clientId: "io.smartest.webapp",
        scope: "name email",
        redirectURI: `${window.location.href}`,
        state: "",
        nonce: "nonce",
        usePopup: true,
      }}
      uiType="light"
      onSuccess={handleAppleLoginCallback}
      onError={errAppleResponse}
      render={(props) => (
        <NewButton
          size="lg"
          center
          {...props}
          variant="primary"
          color="bg-gray-700 text-white"
          className="whitespace-nowrap"
        >
          <TbBrandApple /> {t("v4.account.continueApple")}
        </NewButton>
      )}
    />
  );
};
