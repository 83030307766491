import React from "react";
import styled from "styled-components";

export const ScTableRow = styled.div<{
  isDraggingOver?: boolean;
}>`
  min-height: 48px;
  height: fit-content;
  width: calc(100% - 64px);
  margin: 0 32px;
  border-top: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  background-color: ${(p) =>
    p.isDraggingOver ? "var(--main-white-hover)" : "var(--main-white)"};

  //:first-child {
  //  border-top: 1px solid #d0d0d0;
  //}
  @media screen and (max-width: 800px) {
    margin: 0 16px;
    width: calc(100% - 32px);
  }
`;
