import React, { useEffect } from "react";
import { TbPlus } from "react-icons/tb";
import classNames from "classnames";
import {
  ColumnContentContextProvider,
  usePageItemContext,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  Columns,
  PageItemType,
  Paragraph,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { useTranslation } from "react-i18next";
import { PageDropzone } from "app/components/Exercises/CourseEdit/components/generate/QuizDropzone";
import { uuid } from "app/components/Exercises/utils/uuid";
import { PageItemRender } from "app/components/Exercises/CourseEdit/render/PageRender";

const VerticalPlusButton = ({ onClick, right = false }) => {
  const [item] = usePageItemContext<Columns>();
  if (item.items.length >= 3) return null;

  return (
    <div
      className={classNames(
        right ? "-right-3" : "-left-3",
        "absolute top-0 h-full w-4 cursor-pointer opacity-0 hover:opacity-100 transition rounded bg-gray-600 bg-opacity-10 text-gray-500 flex items-center justify-center"
      )}
      onClick={onClick}
    >
      <TbPlus className="text-sm" />
    </div>
  );
};

export const PageColumnsItem = () => {
  const [item, set] = usePageItemContext<Columns>();
  const { t } = useTranslation();
  // const setList = useSetList();
  // const index = usePageItemIndex();
  // const isEmpty = !!item?.items && !item.items.length;
  //
  // useEffect(() => {
  //   if (isEmpty)
  //     setList((list) => {
  //       list.splice(index, 1);
  //     });
  // }, [isEmpty]);

  const handleInsertColumn = (index: number) => () => {
    if (item.items.length >= 3) return;
    set((item) => {
      const paragraph: Paragraph = {
        id: uuid(),
        data: [[{ text: "" }]],
        align: "left",
        type: PageItemType.Paragraph,
        category: "content",
      };
      item.items.splice(index, 0, { id: uuid(), data: [paragraph] });
    });
  };

  return (
    <PageItemWrapper>
      <div className="relative">
        <div
          className="grid gap-2"
          style={{
            gridTemplateColumns: `repeat(${item.items.length}, minmax(0, 1fr))`,
          }}
        >
          {item.items.map(({ id, data }, i) => (
            <div
              className="flex flex-col shrink-0 w-full relative bg-gray-50 rounded-xl pb-2 pt-1.5"
              key={id}
            >
              <VerticalPlusButton onClick={handleInsertColumn(i)} />
              <PageColumnChecker index={i} />
              <ColumnContentContextProvider id={id}>
                <PageDropzone
                  id={i}
                  list={data || []}
                  bigButton={!data.length}
                />
              </ColumnContentContextProvider>
            </div>
          ))}
        </div>
        <VerticalPlusButton
          right
          onClick={handleInsertColumn(item.items.length)}
        />
      </div>
    </PageItemWrapper>
  );
};

export const PageColumnChecker = ({ index }) => {
  const [item, set] = usePageItemContext<Columns>();
  const column = item?.items?.[index]?.data;
  const isEmpty = !!column && !column.length;

  useEffect(() => {
    if (isEmpty)
      set((item) => {
        if (item.items.length > 1) item.items.splice(index, 1);
      });
  }, [isEmpty]);

  return null;
};

export const PageColumnsRender = ({ item }: { item: Columns }) => {
  return (
    <div
      className="grid gap-6"
      style={{
        gridTemplateColumns: `repeat(${item.items.length}, minmax(0, 1fr))`,
      }}
    >
      {item.items.map(({ id, data }, i) => (
        <div
          className="flex flex-col shrink-0 w-full relative rounded-xl pb-2 pt-1.5"
          key={id}
        >
          {data.map((item) => (
            <PageItemRender key={item.id} item={item} />
          ))}
        </div>
      ))}
    </div>
  );
};
