import React from "react";
import {
  ScExercisesLibraryNavigation,
  ScIconArrow,
  ScLink,
} from "../../components/Tables/ExerciesLibrary/ExercisesLibraryNavigation";
import { adminRoutes } from "../../../enums/routes";
import icNavArrow from "../../../assets/images/table/ic-right-arrow.svg";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

type Props = {
  name?: string;
  id?: string;
};
const Navigation = ({ name, id }: Props) => {
  const location = useLocation();

  return (
    <>
      <ScExercisesLibraryNavigation>
        <div>
          <ScNavigationLink>
            <ScLink to={`${adminRoutes.admin}`}>Admin</ScLink>
            <ScIconArrow src={icNavArrow} />
          </ScNavigationLink>
        </div>
        {(location.pathname === adminRoutes.admin_organizations ||
          location.pathname.includes(adminRoutes.admin_organization(""))) && (
          <ScNavigationLink>
            <ScLink to={`${adminRoutes.admin_organizations}`}>
              Organizations
            </ScLink>
            <ScIconArrow src={icNavArrow} />
          </ScNavigationLink>
        )}
        {(location.pathname === adminRoutes.admin_users ||
          location.pathname.includes(adminRoutes.admin_user_details(""))) && (
          <ScNavigationLink>
            <ScLink to={`${adminRoutes.admin_users}`}>Users</ScLink>
            <ScIconArrow src={icNavArrow} />
          </ScNavigationLink>
        )}
        {location.pathname.includes(adminRoutes.admin_user_details("")) && id && (
          <ScNavigationLink>
            <ScLink to={`${adminRoutes.admin_user_details(id)}`}>
              {name ? name : "User details"}
            </ScLink>
            <ScIconArrow src={icNavArrow} />
          </ScNavigationLink>
        )}
        {location.pathname.includes(adminRoutes.admin_organization("")) && id && (
          <ScNavigationLink>
            <ScLink to={`${adminRoutes.admin_organization(id)}`}>
              {name ? name : "My org"}
            </ScLink>
            <ScIconArrow src={icNavArrow} />
          </ScNavigationLink>
        )}
      </ScExercisesLibraryNavigation>
    </>
  );
};

export default Navigation;

const ScNavigationLink = styled.div`
  display: flex;
  align-items: center;
`;
