import { ConnectedRouter } from "connected-react-router";
import MainRoutes from "./routes/Routes";
import React, { useCallback, useEffect, useState } from "react";
import { History } from "history";
import initInterceptors from "../config/axios/interceptors";
import { useDispatch, useSelector } from "react-redux";
import { selectUserLoading, userDetails } from "./store/user";
import styled from "styled-components";
import Loader from "./components/Loader";
import { AppDispatch } from "./store";
import setAuthTokenAxios from "../config/axios/setAuthTokenAxios";
import { Requires2FA } from "enums/constant";
import { Toaster } from "react-hot-toast";
import i18n from "i18next";
import { logoutUser } from "app/store/authentication";
import Verify2FAForm from "./components/Forms/Verify2FAForm";
import { update2FA } from "./store/authentication/authActions";
import { useAccessToken, useRole, useRoleContext } from "app/hooks/useRole";
import { create } from "zustand";
import { combine } from "zustand/middleware";
import { useQuery } from "react-query";
import { getGuestData } from "api/userAPI";
import { OrganizationTopbar } from "app/components/Header";
import { getBrandColor } from "./pages/admin/organizationDetails/OrganizationSettings";

export interface AppProps {
  history: History;
}

const ScLoading = styled.div`
  display: flex;
  margin: auto;
  height: 100%;
  color: #f07f0e;
`;

export const use2FAContext = create(
  combine({ enabled: localStorage.getItem(Requires2FA) != null }, (set) => ({
    set: (value: boolean) => {
      if (value) localStorage.setItem(Requires2FA, "true");
      else localStorage.removeItem(Requires2FA);

      return set({ enabled: value });
    },
  }))
);

export const useGlobalQuery = () =>
  useQuery("guest", () => getGuestData(), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: Infinity,
    onSuccess: (data) => {
      if (!data?.theme?.primary_color) return;
      const brandColor = getBrandColor(data.theme.primary_color);
      document.documentElement.style.setProperty("--brand-color", brandColor);
    },
  });

const App: React.FC<AppProps> = ({ history }: AppProps) => {
  const [languageLoaded, setLanguageLoaded] = useState(true);
  const dispatch: AppDispatch = useDispatch();
  const isLoading = useSelector(selectUserLoading);
  const role = useRole();
  const is2FA_ = use2FAContext((s) => s.enabled);
  const remove2FA = use2FAContext((s) => () => s.set(false));
  const is2FA = !role.guest && is2FA_;
  const accessToken = useAccessToken();

  const globalQuery = useGlobalQuery();

  useEffect(() => {
    initInterceptors(history);
  }, [history]);

  const getUserDetailsCallback = useCallback(async () => {
    setLanguageLoaded(false);
    const res: any = await dispatch(userDetails(""));
    if (userDetails.fulfilled.match(res)) {
      update2FA(res.payload);
      if (res.payload.language) {
        i18n.changeLanguage(res.payload.language);
      } else {
        i18n.changeLanguage();
      }
    }
    setLanguageLoaded(true);
  }, []);

  // useEffect(() => {
  //   if (!accessToken) useRoleContext.getState().reset();
  // }, [!accessToken]);

  useEffect(() => {
    if (accessToken) {
      setAuthTokenAxios(accessToken);

      getUserDetailsCallback();
    } else {
      useRoleContext.getState().reset();
    }
  }, [accessToken]);

  if (is2FA) {
    return (
      <>
        <Toaster
          position="top-right"
          containerClassName="mt-24 md:mt-16 !z-[10000]"
          toastOptions={{
            duration: 4000,
            success: {
              duration: 3000,
            },
          }}
        />
        <Verify2FAForm
          setValid={() => {
            remove2FA();
            dispatch(userDetails(""));
          }}
          cancel={() => {
            remove2FA();
            dispatch(logoutUser(history));
          }}
        />
      </>
    );
  }

  if (isLoading || globalQuery.isLoading || !languageLoaded) {
    return (
      <ScLoading>
        <Loader />
      </ScLoading>
    );
  }

  return (
    <div className="h-full w-full flex flex-col">
      <OrganizationTopbar />
      <div className="grow relative overflow-auto scroll-smooth" id="main">
        <ConnectedRouter history={history}>
          <Toaster
            position="top-right"
            containerClassName="mt-24 md:mt-16 !z-[10000]"
            toastOptions={{
              duration: 4000,
              success: {
                duration: 3000,
              },
            }}
          />
          <MainRoutes />
        </ConnectedRouter>
      </div>
    </div>
  );
};

export default App;
