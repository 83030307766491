import React from "react";
import { ScRow, ScRowElement } from "./AdminUserDetails";
import { useMutation, useQueryClient } from "react-query";
import { putOrganizationUserDetails } from "../../../api/organizationsAPI";
import UsersClass from "./organizationDetails/UsersClass";
import styled from "styled-components";
import { adminRoutes } from "../../../enums/routes";
import { Link } from "react-router-dom";

const UserDetailsOrg = ({ data, member_id }) => {
  const queryClient = useQueryClient();
  const changeUserRole = useMutation(
    ({
      role,
      member_id,
      org_id,
    }: {
      role: string;
      member_id: string;
      org_id: string;
    }) => {
      const defaultData = { is_pro: false };
      const dataToSave = { ...defaultData, [`is_${role.toLowerCase()}`]: true };
      return putOrganizationUserDetails(org_id, member_id, dataToSave);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["user", member_id]),
    }
  );

  const roles = {
    Pro: data?.is_pro,
    Basic: !data?.is_pro,
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1 }}>
        <ScRow
          style={{
            flexDirection: "row",
            borderTop: "1px solid #b7b7b7",
            marginTop: "10px",
          }}
        >
          <ScRowElement>
            Name{" "}
            <Link to={adminRoutes.admin_organization(data.id)}>
              {data.name}
            </Link>
          </ScRowElement>
          <ScRowElement>
            Account type
            <select
              style={{ margin: "10px", height: "30px" }}
              onChange={(e) => {
                console.log(e.target.value);
                changeUserRole.mutate({
                  org_id: data.id,
                  role: e.target.value,
                  member_id: member_id,
                });
              }}
              defaultValue={data.is_pro ? "Pro" : "Basic"}
            >
              {Object.keys(roles).map((item, index) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </ScRowElement>
        </ScRow>
        {/*))}*/}
      </div>
      <ScCol
        style={{
          minWidth: "calc(100% - 420px)",
          maxWidth: "calc(100% - 420px)",
        }}
      >
        <UsersClass id={data.id} member_id={member_id} />
      </ScCol>
      {/*<div style={{ flex: 1 }}>*/}
      {/*  <strong>Classes</strong>*/}
      {/*  <ScRow style={{ flexDirection: "row" }}>*/}
      {/*    <ScRowElement>*/}
      {/*      Name <input value="class name" />*/}
      {/*    </ScRowElement>*/}
      {/*    <ScRowElementMin>*/}
      {/*      Owner*/}
      {/*      <input type="checkbox" />*/}
      {/*    </ScRowElementMin>*/}
      {/*    <ScRowElement>*/}
      {/*      Owner <span>Owner name</span>*/}
      {/*    </ScRowElement>*/}
      {/*  </ScRow>*/}
      {/*</div>*/}
    </div>
  );
};

export default UserDetailsOrg;

const ScCol = styled.div`
  display: flex;
  flex-direction: column;

  input {
    &:first-child {
      margin-left: 0;
    }
    margin: 10px 0;
    max-width: 300px;
    height: 30px;
    font-size: 16px;
  }
`;
