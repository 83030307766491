import * as msal from "@azure/msal-browser";

export const MSLogin = () => {
  const msalConfig = {
    auth: {
      clientId: "5d428d2e-a7a5-4c9d-82c6-7e4f94fdb627",
    },
  };

  const msalInstance = new msal.PublicClientApplication(msalConfig);

  return msalInstance;
};
