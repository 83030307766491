import { useSelector } from "react-redux";
import { selectUserDetails } from "app/store/user";
import useLocalStorageState from "use-local-storage-state";
import { useQuery, useQueryClient } from "react-query";
import { getGroup } from "api/groups/groupsAPI";
import { isPureProd } from "helpers/isProd";
import { create } from "zustand";
import { combine } from "zustand/middleware";
import { PRO_ID } from "app/pages/payment/Subscriptions";
import { AccessToken } from "enums/constant";

export enum UserRole {
  Guest,
  Learner,
  Teacher,
}

export const useRoleContext = create(
  combine({ role: UserRole.Guest, admin: false, aiEnabled: false }, (set) => ({
    reset: () => set({ role: UserRole.Guest, admin: false, aiEnabled: false }),
    // set: (newState: { role?: UserRole; admin?: boolean }) => {
    //   set((state) => ({ ...state, ...newState }));
    // },
    readPayload: (payload: {
      is_superuser?: boolean;
      is_org_pro?: boolean;
      is_no_ai?: boolean;
    }) => {
      set((state) => ({
        admin: payload?.is_superuser ?? state.admin,
        aiEnabled:
          payload?.is_no_ai != null ? !payload.is_no_ai : state.aiEnabled,
        role:
          payload.is_org_pro == null
            ? state.role
            : payload.is_org_pro
            ? UserRole.Teacher
            : UserRole.Learner,
      }));
    },
  }))
);

export const useAccessToken = (): string | undefined =>
  useLocalStorageState<string>(AccessToken, {
    storageSync: false,
  })?.[0] ||
  localStorage.getItem(AccessToken) ||
  undefined;

export const useRole = () => {
  const role = useRoleContext((s) => s.role);
  const isAdmin = useRoleContext((s) => s.admin);
  const aiEnabled = useRoleContext((s) => s.aiEnabled);
  const isProd = useLocalStorageState("smartest_prod")[0] != null;
  const isAuth = useAccessToken();
  const queryClient = useQueryClient();

  const subscription = queryClient.getQueryData<any>("subscription");

  return {
    guest: !isAuth || role === UserRole.Guest,
    student: role === UserRole.Learner,
    teacher: role === UserRole.Teacher,
    pro: subscription?.id === PRO_ID,
    superuser: isAdmin,
    aiEnabled,
    prodMode: isPureProd() ? isProd || !isAdmin : isProd,
    isB2B: !queryClient.getQueryData<any>("guest")?.is_org_b2c,
  };
};

export const useIsGroupAdmin = (id: string) => {
  const { isSuccess, data } = useQuery(["group", id], () => getGroup(id));
  const user = useSelector(selectUserDetails);
  return {
    isSuccess,
    data:
      isSuccess && !!data?.members?.find((m) => m?.id === user?.id)?.is_admin,
  };
};
