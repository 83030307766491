import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useResizeObserver from "use-resize-observer";
import {
  PageItemIndexContext,
  TopLevelIndexContext,
  usePageContext,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  Dimensions,
  GridItem,
  PageItemType,
  SlideAudio,
  SlideContentItem,
  SlideEmbed,
  SlideImage,
  SlideItem,
  SlideListGenerate,
  SlideMediaUpload,
  SlideParagraph,
  SlideVideo,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Coordinates } from "app/components/Exercises/Edit/questionType/Diagram/DiagramExercise";
import { useRefCopy } from "app/hooks/useRefCopy";
import GridLayout from "react-grid-layout";
import {
  ROW_HEIGHT,
  SLIDE_COLS,
  SLIDE_GAP,
  SLIDE_HEIGHT,
  SLIDE_PADDING,
  SLIDE_ROWS,
  SLIDE_WIDTH,
} from "app/components/Exercises/Edit/questionType/Slide/slideUtils";
import { uuid } from "app/components/Exercises/utils/uuid";
import objectHash from "object-hash";
import { GridStyles } from "app/components/Exercises/Edit/questionType/Slide/GridStyles";
import classNames from "classnames";
import {
  TbAlignLeft,
  TbCheck,
  TbChevronRight,
  TbDragDrop,
  TbRectangle,
  TbTrash,
  TbWand,
  TbX,
} from "react-icons/tb";
import { SlideItemContext } from "app/components/Exercises/CourseEdit/items/SlideItemContext";
import { SlidePageItem } from "app/components/Exercises/CourseEdit/slide/SlidePageItem";
import { useDropMedia } from "app/components/Sources/MediaPicker/context/dropMediaContext";
import { FloatingMenu } from "app/components/Header";
import {
  COURSE_ITEMS,
  LargeListItem,
} from "app/components/Exercises/CourseEdit/items/InsertPageItem";
import {
  getPageText,
  MediaModal,
} from "app/components/Exercises/CourseEdit/components/generate/TextGenerate";
import { usePageFocusContext } from "app/components/Exercises/CourseEdit/items/PageItemFocus";
import { AnimatePresence, motion, useIsPresent } from "framer-motion";
import {
  MediaPicker,
  SelectOnlyType,
} from "app/components/Sources/MediaPicker/MediaPicker";
import { textToParagraphs } from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import TextareaAutosize from "react-textarea-autosize";
import { useDropzone } from "react-dropzone";
import {
  FileType,
  SUPPORTED_AUDIO_MIMETYPES,
  SUPPORTED_VIDEO_MIMETYPES,
} from "helpers/mimeType";
import {
  BigModal,
  BigModalBody,
  BigModalFooter,
  BigModalHeader,
} from "app/components/BigModal";
import { NewButton } from "app/components/Buttons/NewButton";
import { Tooltip } from "app/components/Tooltip";
import { useRole } from "app/hooks/useRole";
import {
  RejectedSourceUpload,
  useDropSource,
} from "app/components/Exercises/CourseEdit/items/PageItemWrapper";

enum SelectingState {
  Unselected,
  Selecting,
  Selected,
}

export const SlideCoursePage = () => {
  const { t } = useTranslation();
  const { pageId } = useParams<{ pageId: string }>();
  const { ref, width, height } = useResizeObserver<HTMLDivElement>();
  const items = usePageContext(({ data }) => data!.items as SlideItem[]);
  const setList = usePageContext((state) => state.setList);
  const setFocus = usePageFocusContext((state) => state.select);

  const [selected, setSelected] = useState<string | null>(null);

  const [start, setStart] = useState<Coordinates | null>(null);
  const [selection, setSelection] = useState<Coordinates | null>(null);
  const [selecting, setSelecting] = useState(SelectingState.Unselected);

  const selectionCopy = useRefCopy(selection);
  const [dropMedia, setDropMedia] = useDropMedia();

  const newItemRef = useRef<any>();
  newItemRef.current = (position: Dimensions) => {
    if (!dropMedia) return setSelecting(SelectingState.Selected);

    const insert = <T extends SlideItem = SlideItem>(
      category: SlideItem["category"] = "slide",
      additionalData: Partial<T> = {}
    ) => {
      setList((list) => {
        const id = uuid();
        list.push({
          id,
          category,
          position,
          ...dropMedia,
          ...additionalData,
        } as T);

        setSelected(id);
        setFocus(id, 0, true);
        setStart(null);
        setSelection(null);
        setSelecting(SelectingState.Unselected);
        setDropMedia(false);
      });
    };

    switch (dropMedia.type) {
      case PageItemType.Paragraph:
      case PageItemType.List:
      case PageItemType.Video:
      case PageItemType.Audio:
      case PageItemType.Embed:
        return insert();
      case PageItemType.Image:
        return insert<SlideImage>("slide", { fit: "contain" });

      case PageItemType.ParagraphSummarize:
      case PageItemType.ParagraphSimplify:
      case PageItemType.ParagraphNext:
      case PageItemType.ParagraphParaphrase:
      case PageItemType.ParagraphTranslate:
        return insert("slideGenerate");
    }

    setSelecting(SelectingState.Selected);
  };

  const scale = Math.min(
    (width || 0) / SLIDE_WIDTH,
    (height || 0) / SLIDE_HEIGHT
  );

  const [layout, validGrid] = useMemo(() => {
    const layout = items.map(({ id, position, ...item }) => ({
      i: id,
      ...position,
      static: id !== selected,
      minW: 1,
      ...(item.type === PageItemType.Embed && { minW: 2 }),
      item,
    }));

    const validGrid = [...new Array(SLIDE_ROWS)].map(() =>
      [...new Array(SLIDE_COLS)].map(() => true)
    );
    for (const { position } of items) {
      for (let x = position.x; x < position.x + position.w; x++) {
        for (let y = position.y; y < position.y + position.h; y++) {
          if (x >= SLIDE_COLS) continue;
          if (y >= SLIDE_ROWS) continue;
          validGrid[y][x] = false;
        }
      }
    }

    return [layout, validGrid];
  }, [selected, items]);

  const getValidInsert = ({ x, y }: Coordinates): Dimensions => {
    const options = [
      // 2x2 top left
      { x, y, w: 2, h: 2 },
      // 2x2 top right
      { x: x - 1, y, w: 2, h: 2 },
      // 2x2 bottom left
      { x, y: y - 1, w: 2, h: 2 },
      // 2x2 bottom right
      { x: x - 1, y: y - 1, w: 2, h: 2 },
    ];

    for (const dimensions of options) {
      if (validateSelection(dimensions)) return dimensions;
    }
    return { x, y, w: 1, h: 1 };
  };

  useEffect(() => {
    if (selected && selecting === SelectingState.Selected) {
      setStart(null);
      setSelection(null);
      setSelecting(SelectingState.Unselected);
    }
  }, [selected]);

  const handleDown =
    (data: Coordinates) => (e: React.PointerEvent<HTMLDivElement>) => {
      // e.preventDefault();
      e.stopPropagation();
      setSelected(null);

      const startX = e.clientX;
      const startY = e.clientY;
      const dimensions = getValidInsert(data);
      if (selecting === SelectingState.Selected || selected != null) {
        setStart(null);
        setSelection(null);
      } else {
        setStart({ x: dimensions.x, y: dimensions.y });
        setSelection({
          x: dimensions.x + dimensions.w - 1,
          y: dimensions.y + dimensions.h - 1,
        });
      }
      setSelecting(SelectingState.Unselected);

      let moved = false;

      const handleMove = (e: PointerEvent) => {
        if (
          !moved &&
          (e.clientX - startX) ** 2 + (e.clientY - startY) ** 2 > 5 ** 2
        ) {
          setStart(data);
          setSelection(data);
          setSelecting(SelectingState.Selecting);
          moved = true;
        }
      };

      document.addEventListener("pointermove", handleMove);
      document.addEventListener(
        "pointerup",
        (e) => {
          e.stopPropagation();
          e.preventDefault();
          document.removeEventListener("pointermove", handleMove);
          const start = data;
          const end = selectionCopy.current;
          if (!end) return;
          // if (selectingCopy.current !== SelectingState.Selecting) return;

          newItemRef.current({
            x: Math.min(start.x, end.x),
            y: Math.min(start.y, end.y),
            w: Math.abs(start.x - end.x) + 1,
            h: Math.abs(start.y - end.y) + 1,
          });
        },
        { once: true }
      );
    };

  const validateSelection = (area: Dimensions) => {
    for (let x = area.x; x < area.x + area.w; x++) {
      if (x < 0 || x > SLIDE_COLS) return false;
      for (let y = area.y; y < area.y + area.h; y++) {
        if (y < 0 || y > SLIDE_ROWS) return false;
        if (!validGrid?.[y]?.[x]) return false;
      }
    }
    return true;
  };

  // useEffect(() => {
  //   if (isDragActive) {
  //     setSelecting(SelectingState.Selecting);
  //     setStart(null);
  //     setSelection(null);
  //   }
  // }, [isDragActive]);

  const handleOver =
    (data: Coordinates) => (e: React.PointerEvent<HTMLDivElement>) => {
      if (!start) return;
      if (selecting !== SelectingState.Selecting) return;
      e.preventDefault();
      const x = Math.min(start.x, data.x);
      const y = Math.min(start.y, data.y);
      const w = Math.abs(start.x - data.x) + 1;
      const h = Math.abs(start.y - data.y) + 1;

      if (!validateSelection({ x, y, w, h })) return;

      setSelection(data);
    };

  const handleSelect =
    (id: string | null) => (e: React.PointerEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setSelected(id);
    };

  const handleLayoutChange = (items: GridItem[]) => {
    // compare layout changes, return on none
    const oldSet = layout.map(({ w, h, x, y, i }) => ({ w, h, x, y, i }));
    const newSet = items.map(({ w, h, x, y, i }) => ({ w, h, x, y, i }));
    if (objectHash(oldSet) === objectHash(newSet)) return;

    const data = Object.fromEntries(items.map(({ i, ...rest }) => [i, rest]));
    setList((list) => {
      for (const item of list as SlideItem[]) {
        const newItem = data[item.id];
        if (!newItem) continue;
        item.position = {
          x: newItem.x,
          y: newItem.y,
          w: newItem.w,
          h: newItem.h,
        };
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: [
      "image/*",
      ...SUPPORTED_VIDEO_MIMETYPES,
      ...SUPPORTED_AUDIO_MIMETYPES,
    ],
    noKeyboard: true,
    noClick: true,
    multiple: false,
    noDragEventsBubbling: true,
    onDragLeave: () => {
      setSelecting(SelectingState.Unselected);
      setStart(null);
      setSelection(null);
    },
  });

  return (
    <div
      ref={ref}
      className="p-4 absolute-cover overflow-y-hidden"
      onPointerDown={() => {
        if (selecting === SelectingState.Selected) {
          setStart(null);
          setSelection(null);
          setSelecting(SelectingState.Unselected);
        }
      }}
    >
      <GridStyles />
      <div
        style={{
          width: 1280,
          height: 720,
          transform: `translate(-50%, -50%) scale(${scale})`,
        }}
        className="absolute left-1/2 top-1/2 shadow-2xl rounded-2xl bg-white"
        {...getRootProps()}
        role={undefined}
      >
        <input {...getInputProps()} />
        {/* SELECTION */}
        <div
          className="grid absolute left-0 top-0 w-full h-full"
          style={{
            gridTemplateColumns: `repeat(${SLIDE_COLS}, 1fr)`,
            gridTemplateRows: `repeat(${SLIDE_ROWS}, 1fr)`,
            padding: 20,
            gap: 10,
          }}
        >
          <AnimatePresence initial={false}>
            {start && selection && selecting !== SelectingState.Unselected && (
              <motion.div
                key="selection"
                initial={{ opacity: 0, scale: 1 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
                transition={{ opacity: { duration: 0.1, scale: 0.1 } }}
                className={classNames(
                  "rounded-2xl pointer-events-none relative overflow-hidden border-gray-200 border-4 border-dashed bg-gray-300 bg-opacity-10",
                  selecting === SelectingState.Selecting && "animate-pulse"
                )}
                style={{
                  gridColumnStart: Math.min(start.x, selection.x) + 1,
                  gridColumnEnd: `span ${Math.abs(start.x - selection.x) + 1}`,
                  gridRowStart: Math.min(start.y, selection.y) + 1,
                  gridRowEnd: `span ${Math.abs(start.y - selection.y) + 1}`,
                }}
              >
                {selecting === SelectingState.Selected && (
                  <div className="absolute-cover flex">
                    <TextareaAutosize
                      className="bg-transparent outline-none p-4 text-2xl text-gray-600 my-auto w-full overflow-hidden resize-none leading-tight"
                      value=""
                      placeholder={
                        !Math.abs(start.x - selection.x)
                          ? "..."
                          : t("v4.generic.typingPlaceholder")
                      }
                      autoFocus
                      onChange={(e) => {
                        const data = textToParagraphs(e.target.value);
                        const id = uuid();
                        setList((list) => {
                          list.push({
                            id,
                            type: PageItemType.Paragraph,
                            data,
                            focus: true,
                            align: "left",
                            verticalAlign: "center",
                            position: {
                              x: Math.min(start.x, selection.x),
                              y: Math.min(start.y, selection.y),
                              w: Math.abs(start.x - selection.x) + 1,
                              h: Math.abs(start.y - selection.y) + 1,
                            },
                            category: "slide",
                          } as SlideParagraph);
                        });
                        setSelected(id);
                        setFocus(id, 0, true);
                        setStart(null);
                        setSelection(null);
                        setSelecting(SelectingState.Unselected);
                      }}
                    />
                  </div>
                )}
                <NewSlideItemMenu
                  selecting={selecting}
                  disableEmbed={Math.abs(start.x - selection.x) === 0}
                  onInsert={<T extends SlideContentItem | SlideListGenerate>(
                    data: Omit<T, "id" | "position">
                  ) => {
                    const id = uuid();
                    setList((list) => {
                      list.push({
                        id,
                        position: {
                          x: Math.min(start.x, selection.x),
                          y: Math.min(start.y, selection.y),
                          w: Math.abs(start.x - selection.x) + 1,
                          h: Math.abs(start.y - selection.y) + 1,
                        },
                        ...data,
                      } as T);
                    });
                    setSelected(id);
                    setFocus(id, 0, true);
                    setStart(null);
                    setSelection(null);
                    setSelecting(SelectingState.Unselected);
                  }}
                  onCancel={() => {
                    setStart(null);
                    setSelection(null);
                    setSelecting(SelectingState.Unselected);
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
          {validGrid.map((row, y) =>
            row.map(
              (valid, x) =>
                !!valid && (
                  <HoverCell
                    key={x + "-" + y}
                    {...{ selecting, x, y }}
                    onPointerDown={handleDown({ x, y })}
                    onPointerOver={handleOver({ x, y })}
                    select={(start, end) => {
                      setSelecting(SelectingState.Selecting);
                      setStart(start);
                      setSelection(end);
                    }}
                    clearSelection={() => {
                      setSelecting(SelectingState.Unselected);
                      setStart(null);
                      setSelection(null);
                    }}
                    getValidInsert={() => getValidInsert({ x, y })}
                  />
                )
            )
          )}
        </div>
        {/* empty state */}
        {!layout.length && (
          <div
            className={classNames(
              "absolute-cover text-gray-400 flex flex-col p-10 items-center justify-center text-xl select-none pointer-events-none transition",
              (!!start || !!selection) && "opacity-0"
            )}
          >
            <TbDragDrop className="text-5xl mb-4" />
            {t("v4.slide.prompt")}
          </div>
        )}

        {/* grid */}
        <GridLayout
          key={pageId}
          layout={layout}
          onLayoutChange={handleLayoutChange}
          resizeHandles={["se"]}
          containerPadding={[SLIDE_PADDING, SLIDE_PADDING]}
          margin={[SLIDE_GAP, SLIDE_GAP]}
          cols={SLIDE_COLS}
          maxRows={SLIDE_ROWS}
          width={SLIDE_WIDTH}
          height={SLIDE_HEIGHT}
          rowHeight={ROW_HEIGHT}
          preventCollision
          compactType={null}
          transformScale={scale}
          // isDraggable={!isPreview}
          // isResizable={!isPreview}
          isBounded
          style={{ width: SLIDE_WIDTH, height: SLIDE_HEIGHT }}
          className="pointer-events-none"
        >
          {layout.map((data, i) => {
            const id = data.i;
            const isFull =
              selected == null &&
              data.w === 12 &&
              data.h === 6 &&
              data.item.type &&
              [
                PageItemType.Image,
                PageItemType.Video,
                PageItemType.Embed,
              ].includes(data.item.type);

            return (
              <div
                key={id}
                className={classNames(
                  "relative pointer-events-auto bg-white rounded-2xl border-2 text-primary transition-[border]",
                  isFull
                    ? "border-transparent"
                    : id !== selected
                    ? "border-gray-200"
                    : "border-primary z-[1]"
                )}
                onPointerDown={handleSelect(id)}
              >
                <div
                  style={
                    isFull
                      ? {
                          width: 1280,
                          height: 720,
                          transform: "translate(-22px,-22px)",
                        }
                      : undefined
                  }
                  className="transition-all duration-300 absolute left-0 top-0 w-full h-full"
                >
                  <PageItemIndexContext.Provider value={[i, id]}>
                    <TopLevelIndexContext.Provider value={[i, id]}>
                      <SlideItemContext.Provider
                        value={{
                          selected: id === selected,
                          select: setSelected,
                        }}
                      >
                        <SlidePageItem type={data.item.type} />
                      </SlideItemContext.Provider>
                    </TopLevelIndexContext.Provider>
                  </PageItemIndexContext.Provider>
                </div>
              </div>
            );
          })}
        </GridLayout>
      </div>
    </div>
  );
};

const HoverCell = ({
  onPointerDown,
  onPointerOver,
  x,
  y,
  selecting,
  select,
  clearSelection,
  getValidInsert,
}) => {
  const [dropMedia] = useDropMedia();
  const { t } = useTranslation();
  const setList = usePageContext((state) => state.setList);
  const dropSource = useDropSource();

  const { getRootProps, getInputProps } = useDropzone({
    accept: [
      "image/*",
      ...SUPPORTED_VIDEO_MIMETYPES,
      ...SUPPORTED_AUDIO_MIMETYPES,
    ],
    noKeyboard: true,
    noClick: true,
    multiple: false,
    noDragEventsBubbling: true,
    onDropRejected: dropSource.onDropRejected,
    onDragEnter: () => {
      const position = getValidInsert();
      select(
        { x: position.x, y: position.y },
        {
          x: position.x + position.w - 1,
          y: position.y + position.h - 1,
        }
      );
    },
    onDrop: async (acceptedFiles) => {
      clearSelection();

      const { uploadId, mimeType } = dropSource.onDrop(acceptedFiles) || {};
      if (!uploadId) return;

      const position = getValidInsert();

      setList((list) => {
        const placeholder: SlideMediaUpload = {
          id: uuid(),
          uploadId,
          type: PageItemType.MediaUpload,
          category: "slideGenerate",
          position,
          mediaType:
            mimeType === FileType.Audio
              ? PageItemType.Audio
              : mimeType === FileType.Video
              ? PageItemType.Video
              : PageItemType.Image,
        };
        list.push(placeholder);
      });
    },
  });

  const isDrop =
    dropMedia &&
    [
      PageItemType.Paragraph,
      PageItemType.List,
      PageItemType.Image,
      PageItemType.Video,
      PageItemType.Audio,
      PageItemType.Embed,
      PageItemType.ParagraphSummarize,
      PageItemType.ParagraphSimplify,
      PageItemType.ParagraphNext,
      PageItemType.ParagraphParaphrase,
      PageItemType.ParagraphTranslate,
    ].includes(dropMedia.type as any);

  return (
    <>
      <RejectedSourceUpload
        cancel={dropSource.cancelRejectedFile}
        confirm={dropSource.confirmRejectedFile}
      />
      <div
        className={classNames(
          "w-[calc(100%_+_10px)] h-[calc(100%_+_10px)] -mx-[5px] -my-[5px] transition",
          selecting !== SelectingState.Selected &&
            (isDrop ? "hover:bg-opacity-20" : "hover:bg-opacity-5"),
          selecting === SelectingState.Selecting && "cursor-crosshair",
          selecting === SelectingState.Unselected && "cursor-pointer",
          isDrop
            ? "bg-primary animate-pulse bg-opacity-10 opacity-100"
            : "bg-gray-700 bg-opacity-0",
          dropMedia && !isDrop && "hover:!bg-opacity-0 !cursor-default"
        )}
        style={{
          gridColumn: `${x + 1} / span 1`,
          gridRow: `${y + 1} / span 1`,
        }}
        onPointerDown={(e) => (dropMedia && !isDrop ? null : onPointerDown(e))}
        onPointerOver={onPointerOver}
        {...getRootProps()}
        role={undefined}
      >
        <input {...getInputProps()} />
      </div>
    </>
  );
};

const NewSlideItemMenu = ({
  selecting,
  onInsert,
  onCancel,
  disableEmbed,
}: {
  onInsert: <T extends SlideContentItem | SlideListGenerate>(
    data: Omit<T, "id" | "position">
  ) => void;
  onCancel: () => void;
  selecting: SelectingState;
  disableEmbed: boolean;
}) => {
  const [panel, setPanel] = useState(false);
  const [insertMedia, setInsertMedia] = useState(false);
  const [action, setAction] = useState<{
    type: PageItemType;
    text: string;
  } | null>(null);
  const [prompt, setPrompt] = useState("");
  const isPresent = useIsPresent();
  const slideContent = getPageText();
  const role = useRole();
  const { t } = useTranslation();

  useEffect(() => {
    if (!action) setPrompt("");
  }, [!!action]);

  const handleAction = (type: PageItemType) => {
    setAction({ type, text: "" });
    // onAction(type, text);
  };

  const onAction = (type: PageItemType, text: string) => {
    switch (type) {
      case PageItemType.ImageGenerate: {
        setAction({ type, text });
        return;
      }
      case PageItemType.ListGenerate: {
        onInsert<SlideListGenerate>({
          type: PageItemType.ListGenerate,
          category: "slideGenerate",
          text,
        });
        setAction(null);
        return;
      }
    }
  };

  const generate = [
    {
      key: PageItemType.ImageGenerate,
      onClick: () => {
        handleAction(PageItemType.ImageGenerate);
      },
    },
    {
      key: PageItemType.ListGenerate,
      onClick: () => {
        handleAction(PageItemType.ListGenerate);
      },
    },
  ];

  const content = [
    {
      key: PageItemType.Paragraph,
      onClick: () =>
        onInsert<SlideParagraph>({
          type: PageItemType.Paragraph,
          category: "slide",
          data: [[{ text: "" }]],
          align: "left",
          verticalAlign: "center",
          focus: true,
        }),
    },
    {
      key: PageItemType.Image,
      onClick: () => setInsertMedia(true),
    },
    {
      key: PageItemType.Embed,
      hidden: disableEmbed,
      onClick: () =>
        onInsert<SlideEmbed>({
          type: PageItemType.Embed,
          category: "slide",
          data: "",
        }),
    },
  ];

  const handleMedia = (
    link: string,
    isVideo: boolean,
    attribution?: string
  ) => {
    setAction(null);
    if (isVideo) {
      onInsert<SlideEmbed>({
        type: PageItemType.Embed,
        category: "slide",
        data: link,
      });
    } else {
      onInsert<SlideImage>({
        type: PageItemType.Image,
        category: "slide",
        data: { src: link },
        fit: "contain",
        ...(attribution && { attribution }),
      });
    }
  };

  const handleDropMedia = {
    [PageItemType.Paragraph]: (data) => {
      onInsert<SlideParagraph>({
        type: PageItemType.Paragraph,
        category: "slide",
        data: data.split(/\r?\n/).map((text) => [{ text }]),
        verticalAlign: "center",
        align: "left",
      });
    },
    [PageItemType.Image]: (data) => {
      onInsert<SlideImage>({
        type: PageItemType.Image,
        category: "slide",
        data: data,
        fit: "contain",
      });
    },
    [PageItemType.Video]: (data) => {
      onInsert<SlideVideo>({
        type: PageItemType.Video,
        category: "slide",
        data: data,
      });
    },
    [PageItemType.Audio]: (data) => {
      onInsert<SlideAudio>({
        type: PageItemType.Audio,
        category: "slide",
        data: data,
      });
    },
  };

  return (
    <FloatingMenu
      portal
      size="xs"
      placement="auto-start"
      containerClassName="w-full h-full flex [&>div]:absolute-cover"
      trigger={() => <div className="w-full h-full relative" />}
      open={
        selecting === SelectingState.Selected &&
        isPresent &&
        !action &&
        !insertMedia
      }
      layout=""
    >
      <div
        className="flex flex-col gap-1"
        onPointerDown={(e) => {
          e.stopPropagation();
        }}
      >
        {role.aiEnabled && (
          <div
            className="flex flex-col p-0.5 rounded-lg bg-white shadow-xl border border-gray-100"
            onPointerEnter={() => setPanel(true)}
            onPointerLeave={() => setPanel(false)}
          >
            <FloatingMenu
              size="xs"
              trigger={() => (
                <LargeListItem
                  name={t("v4.generate.ellipsis")}
                  icon={TbWand}
                  color="bg-primary"
                >
                  <div className="w-2" />
                  <TbChevronRight className="text-gray-400 ml-auto text-xl -mr-1.5" />
                </LargeListItem>
              )}
              open={panel}
              placement="right-start"
            >
              <div className="flex flex-col gap-1 max-h-64 overflow-y-auto pr-1">
                {generate.map(({ key, onClick }) => {
                  const item = COURSE_ITEMS(t)[key];
                  return (
                    <LargeListItem
                      key={key}
                      {...item}
                      color="bg-primary"
                      onClick={() => {
                        onClick();
                        setPanel(false);
                      }}
                    />
                  );
                })}
              </div>
            </FloatingMenu>
          </div>
        )}
        <div
          className="flex flex-col p-0.5 rounded-lg bg-white shadow-xl border border-gray-100"
          onPointerEnter={() => setPanel(false)}
        >
          {content.map(({ key, onClick, hidden }) => {
            const item = COURSE_ITEMS(t)[key];
            if (hidden) return null;
            return (
              <LargeListItem
                key={key}
                {...item}
                color="bg-blue-700"
                onClick={onClick}
              />
            );
          })}

          {insertMedia && (
            <MediaPicker
              close={() => setInsertMedia(false)}
              selectOnly={SelectOnlyType.media}
              onInsert={(media) => {
                if (typeof media === "string")
                  return handleDropMedia[PageItemType.Paragraph](media);
                handleDropMedia[media.type](media.data);
              }}
            />
          )}
          {!!action && !action?.text ? (
            <BigModal fit>
              <BigModalHeader className="leading-none">
                <TbAlignLeft className="ml-2 shrink-0" />
                {t("v4.generate.prompt.generate")}
                <NewButton
                  variant="transparent"
                  className="ml-auto"
                  iconOnly
                  onClick={() => setAction(null)}
                >
                  <TbX />
                </NewButton>
              </BigModalHeader>
              <BigModalBody className="text-gray-600 max-w-md w-[calc(100vw_-_4rem)]">
                <div className="text-sm font-bold mb-1">
                  {t("v4.generate.prompt.text")}
                </div>
                <textarea
                  autoFocus
                  onChange={(e) => setPrompt(e.target.value.substring(0, 400))}
                  value={prompt}
                  placeholder={t("v4.generate.prompt.placeholder")}
                  className="rounded-xl h-36 w-full resize-none text-gray-600 px-4 py-3 bg-gray-100 outline-none focus-within:bg-primary focus-within:bg-opacity-10 transition"
                />
                <div className="flex items-center justify-between gap-2 text-sm text-gray-400 mt-1">
                  <div
                    className={classNames(
                      "transition",
                      prompt.length >= 15 && "opacity-0"
                    )}
                  >
                    {t("v4.generate.prompt.minLength", { number: 15 })}
                  </div>
                  <div className="text-right whitespace-nowrap">
                    {prompt.length} / 400
                  </div>
                </div>
              </BigModalBody>
              <BigModalFooter>
                {slideContent.length < 15 ? (
                  <Tooltip
                    value={t("v4.slide.notEnoughContent")}
                    className="ml-auto"
                  >
                    <NewButton disabled color="bg-primary text-primary">
                      <TbRectangle /> {t("v4.slide.useContent")}
                    </NewButton>
                  </Tooltip>
                ) : (
                  <NewButton
                    className="ml-auto"
                    color="bg-primary text-primary"
                    onClick={() => onAction(action.type, slideContent)}
                  >
                    <TbRectangle /> {t("v4.slide.useContent")}
                  </NewButton>
                )}
                <NewButton
                  variant="primary"
                  disabled={prompt.length < 15}
                  onClick={() => onAction(action.type, prompt)}
                >
                  <TbCheck /> {t("v4.generic.confirm")}
                </NewButton>
              </BigModalFooter>
            </BigModal>
          ) : action?.type === PageItemType.ImageGenerate ? (
            <MediaModal
              close={() => setAction(null)}
              onConfirm={handleMedia}
              text={action.text}
            />
          ) : null}
        </div>
        <div className="flex flex-col p-0.5 rounded-lg bg-gray-50 font-normal border border-gray-100 text-gray-500">
          <LargeListItem
            icon={TbTrash}
            name={t("v4.generic.cancel")}
            color="bg-gray-300 !text-gray-500"
            onClick={onCancel}
          />
        </div>
      </div>
    </FloatingMenu>
  );
};
