import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  ConsumptionType,
  CropSize,
  Image,
  ImageLabeling,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { ProgressiveCanvas } from "app/components/Helpers/ProgressiveImage";
import useResizeObserver from "use-resize-observer";
import { withConsumption } from "app/components/Exercises/CourseEdit/render/ConsumptionContext";
import { ImageLabelingConsumption } from "app/components/Exercises/CourseEdit/render/courseConsumptionTypes";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  TbArrowBackUp,
  TbArrowsDiagonal2,
  TbCheck,
  TbEye,
  TbEyeOff,
  TbLayersDifference,
  TbLayoutList,
  TbMinus,
  TbPencil,
  TbPhoto,
  TbPhotoOff,
  TbPlus,
  TbQuestionMark,
  TbRotate,
  TbSlideshow,
  TbSquarePlus,
  TbTag,
  TbTagOff,
  TbTrash,
  TbX,
} from "react-icons/tb";
import { createPortal } from "react-dom";
import {
  ExerciseActionButton,
  Separator,
} from "app/components/Exercises/Edit/input/ExerciseActionButton";
import { Tooltip, TooltipRaw } from "app/components/Tooltip";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  Dimensions,
  getBoundingBox,
  isObjectInCrop,
} from "app/components/Exercises/Edit/questionType/Diagram/DiagramExercise";
import { Textfit } from "@outofaxis/react-textfit";
import TextareaAutosize from "react-textarea-autosize";
import produce from "immer";
import { roundTo } from "helpers/roundTo";
import UTILS from "app/components/Exercises/utils";
import { uuid } from "app/components/Exercises/utils/uuid";
import { IconType } from "react-icons";
import { rotatePoint } from "app/components/Exercises/Edit/questionType/Diagram/DiagramWord";
import {
  InstructionsRender,
  QuizDropzone,
  QuizInstructionsRender,
  QuizItemLabel,
} from "app/components/Exercises/CourseEdit/components/generate/QuizDropzone";
import {
  MediaData,
  MediaPicker,
  SelectOnlyType,
} from "app/components/Sources/MediaPicker/MediaPicker";
import { useQuery } from "react-query";
import { postBlocksFromImage } from "api/exercisesAPI";
import { toast } from "react-hot-toast";
import { BigModal } from "app/components/BigModal";
import { SplitSelector } from "app/components/Exercises/Edit/questionType/Diagram/Modal/DiagramImageModal";
import { CgSpinner } from "react-icons/cg";
import {
  DropMedia,
  useDropMedia,
} from "app/components/Sources/MediaPicker/context/dropMediaContext";
import { ImageLabelingResults } from "app/components/Exercises/CourseEdit/results/courseResultsTypes";
import { CorrectTooltip } from "app/components/Exercises/CourseEdit/results/PageResults";
import {
  LabelingAnswers,
  LabelingAnswersRender,
  useInputFocus,
} from "app/components/Exercises/CourseEdit/items/quiz/PageFillTheGapItem";
import { useSuccessRate } from "app/components/Exercises/CourseEdit/results/PageCorrect";
import { FloatingMenu } from "app/components/Header";
import { ActiveButton } from "app/components/Exercises/Edit/questionType/Slide/item/ItemText";

enum BlockSplit {
  Block,
  Mix,
}

export const CanvasPreview = ({
  image,
  back,
  close,
  onCreate,
}: {
  image: Image["data"];
  back?: () => void;
  close: () => void;
  onCreate: (blocks: ImageLabeling["items"]) => void;
}) => {
  const { t } = useTranslation();
  const [blockSplit, setBlockSplit] = useState(BlockSplit.Mix);
  const { ref: sizeRef, width, height } = useResizeObserver<HTMLDivElement>();

  const handleConfirm = () => {
    if (!blocks) return;
    onCreate(blocks);
    close();
  };

  if (image?.w == null) return null;

  const scale = useMemo(
    () => Math.min((width || 0) / image.w, (height || 0) / image.h),
    [width, height, image.w, image.h]
  );

  const blocksQuery = useQuery({
    queryKey: ["diagramBlocks", image.sourceId, image.page],
    queryFn: async () =>
      postBlocksFromImage({
        source_id: image.sourceId,
        page_number: image.page,
        blocks_margin: 0.025,
        use_img_dims: true,
      }),
    onError: () => {
      close();
      toast.error(t(`common.errors.no_found_resource`));
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnMount: false,
    retry: 2,
  });

  const blocks = useMemo<ImageLabeling["items"] | null>(() => {
    if (!blocksQuery.data) return null;
    try {
      const blocks =
        blockSplit === BlockSplit.Mix
          ? blocksQuery.data?.opt_blocks
          : blocksQuery.data?.blocks;

      if (!blocks) throw new Error(t("creation.error.noTextDetected"));

      const newBlocks = [] as any[];

      const iterateOverLines = (lines) => {
        for (const { words, ...line } of lines) {
          // if line is inside the crop
          if (isObjectInCrop(line, image as Dimensions)) {
            newBlocks.push(line);
            continue;
          }
          for (const word of words)
            if (isObjectInCrop(word, image as Dimensions)) newBlocks.push(word);
        }
      };

      if (blockSplit === BlockSplit.Mix) {
        iterateOverLines(blocks);
      } else {
        for (const { lines, ...block } of blocks) {
          // if block is inside the crop
          if (
            blockSplit === BlockSplit.Block &&
            isObjectInCrop(block, image as Dimensions)
          ) {
            newBlocks.push({
              ...block,
              text: lines
                .map(({ text, break: b }) => (b === 4 ? text + "-" : text))
                .join("\n"),
            });
            continue;
          }
          iterateOverLines(lines);
        }
      }

      if (!newBlocks.length) {
        throw new Error(t("creation.error.noTextDetected"));
      }

      return newBlocks.map(({ x, y, w, h, angle, text }) => {
        const box = getBoundingBox({
          x,
          y,
          w,
          h,
          angle,
        });
        const xDiff = box.x - x;
        const yDiff = box.y - y;

        const newX = Math.min(
          Math.max(-xDiff, x - image.x),
          image.w - box.w - xDiff
        );
        const newY = Math.min(
          Math.max(-yDiff, y - image.y),
          image.h - box.h - yDiff
        );

        return {
          id: uuid(),
          text,
          angle: (angle + 360) % 360,
          x: newX,
          y: newY,
          w: w,
          h: h,
        };
      });
    } catch (e: any) {
      const message =
        (!e?.response?.data && e?.message) ||
        t("common.exercises.error.generic");
      if (message) toast.error(message);
      close();
      return null;
    }
  }, [blocksQuery.data, blockSplit]);

  return (
    <BigModal>
      <div className="p-1 font-bold border-b-2 border-gray-100 flex items-center gap-2 text-gray-500">
        {back && (
          <NewButton onClick={back}>
            <TbArrowBackUp />
            {t("v4.generic.back")}
          </NewButton>
        )}
        <NewButton
          iconOnly
          onClick={close}
          className="ml-auto"
          variant="transparent"
        >
          <TbX />
        </NewButton>
      </div>

      <div className="flex flex-col grow p-4">
        <div className="flex flex-col max-w-md w-full mx-auto shrink-0">
          <SplitSelector value={blockSplit} setValue={setBlockSplit} />
        </div>
        <div
          className="w-full grow bg-gray-100 rounded-xl relative"
          ref={sizeRef}
        >
          <div className="absolute-cover flex filter drop-shadow-xl">
            {!blocks ? (
              <CgSpinner className="text-5xl text-gray-500 m-auto animate-spin" />
            ) : (
              <div
                className="absolute left-1/2 top-1/2 pointer-events-none overflow-hidden"
                style={{
                  height: image.h,
                  width: image.w,
                  transform: `translate(-50%, -50%) scale(${scale})`,
                }}
              >
                <ProgressiveCanvas
                  crop={image}
                  src={image.src}
                  className="w-full h-full"
                />
                {blocks.map((label) => (
                  <DiagramWord key={label.id} size={1} {...label} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="p-1 font-bold border-t-2 border-gray-100 flex items-center gap-1 text-gray-500">
        <div className="grow" />
        <NewButton onClick={handleConfirm} variant="primary">
          <TbCheck /> {t("v4.generic.confirm")}
        </NewButton>
      </div>
    </BigModal>
  );
};

export const DiagramWord = forwardRef<
  HTMLLabelElement,
  ImageLabelingConsumption["items"][number] & {
    onChange?: (text: string) => void;
    result?: boolean;
    content?: ReactNode;
    active?: boolean;
    setActive?: (value: boolean) => void;
    setNextActive?: () => void;
    size: number;
  }
>(
  (
    {
      id,
      text,
      w,
      h,
      x,
      y,
      angle,
      size,
      options: options_,
      onChange,
      result,
      content,
      active,
      setActive,
      setNextActive,
    },
    ref
  ) => {
    const options = options_;
    const inputRef = useInputFocus<HTMLTextAreaElement>(!!active && !!onChange);

    return useMemo(() => {
      const children = (
        <label
          ref={ref}
          className={classNames(
            "absolute block rounded-lg text-white shadow-xl",
            onChange && "focus-within:bg-gray-600 transition group",
            result == null
              ? "bg-gray-800"
              : result
              ? "bg-green-800"
              : "bg-red-800"
          )}
          style={{
            left: x,
            top: y,
            width: w,
            height: h,
            transform: `rotate(-${angle}deg) scale(${size})`,
          }}
        >
          {onChange && (
            <TbQuestionMark
              className={classNames(
                "w-[70%] h-[70%] top-[15%] left-[15%] absolute object-contain transition",
                text ? "opacity-0" : "group-focus-within:opacity-0"
              )}
            />
          )}
          <Textfit
            max={500}
            className="absolute-cover flex items-center justify-center text-center leading-none font-bold"
            ref={undefined}
            style={{ minHeight: 1, minWidth: 1 }}
          >
            {result == null ? null : result ? (
              <TbCheck className="inline" strokeWidth={3} />
            ) : (
              <TbX className="inline" strokeWidth={3} />
            )}
            {result != null && text && " "}
            <div
              className={classNames(
                "inline",
                (!text || (!!onChange && !options)) &&
                  "opacity-0 select-none pointer-events-none w-0 overflow-hidden relative"
              )}
            >
              {text || (!options && (onChange ? "a" : " "))}
            </div>
            {/*{console.log(!!onChange, !!options) || <div>:D</div>}*/}
            {onChange && !options && (
              <div className="absolute-cover flex items-center justify-center text-center cursor-text">
                <TextareaAutosize
                  ref={inputRef}
                  onFocus={() => setActive?.(true)}
                  onBlur={() => setActive?.(false)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setNextActive?.();
                    }
                  }}
                  className="text-center w-full max-h-full leading-none bg-transparent text-white resize-none outline-none font-inherit scrollbar-none min-h-[1em]"
                  value={text}
                  onChange={(e) => onChange(e.target.value)}
                />
              </div>
            )}
          </Textfit>
          {onChange && !!options && (
            <FloatingMenu
              size="xs"
              portal
              placement="bottom-start"
              containerClassName="absolute-cover [&>div]:absolute-cover !absolute"
              trigger={(trigger) => (
                <div
                  className="absolute-cover cursor-pointer"
                  onClick={trigger}
                />
              )}
            >
              {({ setIsOpen }) => (
                <>
                  {options.map((option) => (
                    <ActiveButton
                      key={option}
                      isActive={text === option}
                      onClick={() => {
                        onChange(option);
                        setIsOpen(false);
                      }}
                    >
                      {option}
                    </ActiveButton>
                  ))}
                </>
              )}
            </FloatingMenu>
          )}
        </label>
      );

      if (!onChange && !result && result != null && content)
        return <CorrectTooltip content={content}>{children}</CorrectTooltip>;

      return children;
    }, [
      id,
      text,
      w,
      h,
      x,
      y,
      angle,
      result,
      !!onChange,
      setNextActive,
      setActive,
    ]);
  }
);

export const PageImageLabelingItem = () => {
  const [item] = usePageItemContext<ImageLabeling>();
  if (item.data) return <InnerImageLabeling />;
  return <ImageLabelingSelect />;
};

const ImageLabelingSelect = () => {
  const [, set] = usePageItemContext<ImageLabeling>();
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState<Image["data"] | null>(null);
  const [sourceId, setSourceId] = useState<string | null>(null);
  const [dropMedia] = useDropMedia();
  const { t } = useTranslation();

  const handlePick = (media: MediaData | string) => {
    if (typeof media === "string" || media.type !== PageItemType.Image) return;
    setSourceId(media.data.sourceId || null);
    setImage(media.data);
  };

  const close = () => {
    setImage(null);
    setIsOpen(false);
  };

  if (image)
    return (
      <CanvasPreview
        back={() => setImage(null)}
        close={close}
        image={image}
        onCreate={(items) => {
          set((item) => {
            item.items = items;
            item.data = image;
          });
        }}
      />
    );

  if (isOpen)
    return (
      <MediaPicker
        sourceId={sourceId}
        close={close}
        onInsert={handlePick}
        selectOnly={SelectOnlyType.image}
      />
    );

  return (
    <PageItemWrapper>
      <div className="bg-gray-100 rounded-lg p-1.5 flex flex-col gap-1 relative">
        <div
          onClick={() => !dropMedia && setIsOpen(true)}
          className={classNames(
            "py-8 px-4 border-4 border-gray-300 transition rounded-xl flex flex-col gap-1 justify-center text-center items-center border-dashed",
            !dropMedia &&
              "bg-primary bg-opacity-0 hover:bg-opacity-10 cursor-pointer"
          )}
        >
          <div className="text-sm font-bold flex gap-1 text-gray-500">
            <TbSlideshow className="text-xl" strokeWidth={1.75} />
            {t("v4.item.imageLabeling.text")}
          </div>
          <div className="font-bold text-gray-500">
            {t("v4.item.imageLabeling.mediaPrompt")}
          </div>
        </div>
        <DropMedia
          onDrop={(data) => {
            if (data.type !== PageItemType.Image) return;
            return () => {
              setIsOpen(true);
              setImage(data.data);
            };
          }}
        />
      </div>
    </PageItemWrapper>
  );
};

const InnerImageLabeling = () => {
  const { t } = useTranslation();
  const [item, set] = usePageItemContext<ImageLabeling>();
  const image = item.data;
  if (!image || image?.w == null) return null;

  const { ref: sizeRef, width, height } = useResizeObserver<HTMLDivElement>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const scale = useMemo(
    () => Math.min((width || 0) / image.w, (height || 0) / image.h),
    [width, height, image?.w, image?.h]
  );

  return (
    <PageItemWrapper
      toolbar={(trash, close) => (
        <div className="flex flex-col gap-1">
          <div className="flex justify-end gap-1">
            <NewButton
              iconOnly
              variant="transparent"
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => {
                setIsEditOpen(true);
                close();
              }}
              size="lg"
            >
              <TbPencil />
            </NewButton>
            {item.consumptionType !== ConsumptionType.multipleChoice && (
              <Tooltip
                value={
                  item.showOptions
                    ? t("v4.quiz.answers.hide")
                    : t("v4.quiz.answers.show")
                }
              >
                <NewButton
                  iconOnly
                  variant="transparent"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    set((item) => {
                      item.showOptions = !item.showOptions;
                    });
                  }}
                  size="lg"
                >
                  {item.showOptions ? <TbTagOff /> : <TbTag />}
                </NewButton>
              </Tooltip>
            )}
          </div>
          {trash}
        </div>
      )}
    >
      <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
        <QuizItemLabel
          type={item.type}
          consumptionType={[
            ConsumptionType.multipleChoice,
            ConsumptionType.strictTypeTheAnswer,
          ]}
        />
        <QuizDropzone />
        <LabelingAnswers />
        <div
          className="mx-auto w-full overflow-hidden max-h-90 relative bg-gray-200 rounded-xl"
          style={{
            aspectRatio: image.w + " / " + image.h,
          }}
          ref={sizeRef}
        >
          <div
            className="absolute left-1/2 top-1/2 pointer-events-none overflow-hidden"
            style={{
              height: image.h,
              width: image.w,
              transform: `translate(-50%, -50%) scale(${scale})`,
            }}
          >
            <ProgressiveCanvas
              crop={image}
              src={image.src}
              className="w-full h-full"
            />
            {item.items.map((label) => (
              <DiagramWord key={label.id} size={item.size} {...label} />
            ))}
          </div>
        </div>
      </div>
      {isEditOpen && <EditLabelingModal close={() => setIsEditOpen(false)} />}
    </PageItemWrapper>
  );
};

const EditLabelingModal = ({ close }) => {
  const { t } = useTranslation();
  const [item, set] = usePageItemContext<ImageLabeling>();
  const image = item.data;

  if (image?.w == null) return null;

  const { ref: sizeRef, width, height } = useResizeObserver<HTMLDivElement>();
  const parentRef = useRef<HTMLDivElement | null>(null);
  const scale = useMemo(
    () => Math.min((width || 0) / image.w, (height || 0) / image.h),
    [width, height, image.w, image.h]
  );

  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});
  const [isCreateBlock, setCreateBlock] = useState(false);
  const [isImageHidden, setImageHidden] = useState(false);
  const [isOverlayHidden, setOverlayHidden] = useState(false);
  const [editHistory, setEditHistory] = useState<ImageLabeling["items"][]>([]);

  const selectedCount = useMemo(
    () => Object.keys(selected)?.length,
    [selected]
  );

  const size = roundTo(item.size ?? 1, 1);
  const handleReadability = (value: number) => {
    set((item) => {
      item.size = Math.min(Math.max(size + value, 0.6), 2.0);
    });
  };

  const pushHistory = () => {
    setEditHistory([item.items, ...editHistory.slice(0, 5)]);
  };

  const handleMergeBlocks = () => {
    const selectedBlocks = item.items
      .filter(({ id }) => selected?.[id])
      .sort((a, b) => {
        const y = a.y + a.h / 2 - (b.y + b.h / 2);
        if (y) return y;
        return a.x + a.w / 2 - (b.x + b.w / 2);
      });

    const [first] = selectedBlocks;

    const maxWidth = selectedBlocks.reduce((acc: number, { x, w }) => {
      const width = x + w - first.x;
      return width > acc ? width : acc;
    }, 0);

    const maxHeight = selectedBlocks.reduce((acc: number, { y, h }) => {
      const height = y + h - first.y;
      return height > acc ? height : acc;
    }, 0);

    pushHistory();
    setSelected({});
    set((item) => {
      const items = item.items.filter(({ id }) => !selected?.[id]);
      items.push({
        id: uuid(),
        angle: 0,
        text: selectedBlocks.map(({ text }) => text).join("\n"),
        x: first.x,
        y: first.y,
        w: maxWidth,
        h: maxHeight,
      });
      item.items = items;
    });
  };

  const selectedBlock = useMemo(() => {
    if (Object.keys(selected).length > 1) return null;
    return item.items.find(({ id }) => selected?.[id]) || null;
  }, [item.items, selected]);

  const handleSplitBlock = () => {
    if (!selectedBlock) return;
    const split = selectedBlock.text.split(/\r?\n/);
    const height = Math.floor(selectedBlock.h / split.length);

    const createdBlocks: ImageLabeling["items"] = split.map((text, i) => ({
      id: UTILS.makeId(),
      angle: selectedBlock.angle,
      text,
      x: selectedBlock.x,
      w: selectedBlock.w,
      h: height,
      y: i * height + selectedBlock.y,
    }));

    pushHistory();
    set((item) => {
      const items = item.items.filter(({ id }) => !selected?.[id]);
      items.push(...createdBlocks);
      item.items = items;
    });
    setSelected({});
  };

  return createPortal(
    <div className="fixed w-full h-full z-40 left-0 top-0 flex flex-col p-4 animate-fadeIn">
      <div className="bg-black bg-opacity-40 backdrop-blur absolute-cover" />
      <div className="w-full grow m-auto bg-white h-min max-h-full rounded-xl shadow-xl relative flex flex-col">
        <div className="p-1 border-b-2 border-gray-100 flex items-center text-gray-500">
          <div className="flex items-center justify-center gap-1">
            <ExerciseActionButton
              icon={TbSquarePlus}
              text={t("common.exercises.diagram.addTextblock")}
              onClick={() => {
                setSelected({});
                setCreateBlock(!isCreateBlock);
              }}
              isActive={isCreateBlock}
            />
            <ExerciseActionButton
              icon={TbTrash}
              text={t("common.exercises.diagram.delete")}
              onClick={() => {
                pushHistory();
                set((item) => {
                  item.items = item.items.filter(({ id }) => !selected?.[id]);
                });
                setSelected({});
              }}
              disabled={!selectedCount}
            />
            <Separator />
            <ExerciseActionButton
              icon={TbLayoutList}
              text={t("common.exercises.diagram.split")}
              onClick={handleSplitBlock}
              disabled={
                Object.keys(selected).length !== 1 ||
                (!!selectedBlock?.text && selectedBlock.text.indexOf("\n") < 0)
              }
            />
            <ExerciseActionButton
              icon={TbLayersDifference}
              text={t("common.exercises.diagram.merge")}
              onClick={handleMergeBlocks}
              disabled={Object.keys(selected)?.length < 2}
            />
            <Separator />
            <ExerciseActionButton
              icon={TbMinus}
              onClick={() => handleReadability(-0.1)}
              disabled={size < 0.65}
            />
            <Tooltip
              value={t("common.exercises.diagram.blockSize")}
              delay={[500, 0]}
            >
              <div
                className={classNames(
                  "w-5 font-bold flex items-center justify-center text-sm transition",
                  size === 1 ? "text-gray-600" : "text-primary"
                )}
              >
                {size}x
              </div>
            </Tooltip>
            <ExerciseActionButton
              icon={TbPlus}
              onClick={() => handleReadability(0.1)}
              disabled={size > 1.95}
            />
            <Separator />
            <ExerciseActionButton
              icon={isOverlayHidden ? TbEye : TbEyeOff}
              text={t("common.exercises.diagram.hideShow")}
              isActive={isOverlayHidden}
              onClick={() => {
                if (!isOverlayHidden) setSelected({});
                if (isImageHidden) setImageHidden(false);
                setOverlayHidden(!isOverlayHidden);
              }}
            />
            <ExerciseActionButton
              icon={isImageHidden ? TbPhoto : TbPhotoOff}
              text={t("common.exercises.diagram.hideShowImage")}
              isActive={isImageHidden}
              onClick={() => {
                if (isOverlayHidden) setOverlayHidden(false);
                setImageHidden(!isImageHidden);
              }}
            />
            <Separator />
            <ExerciseActionButton
              icon={TbArrowBackUp}
              text={t("common.exercises.diagram.undo")}
              disabled={!editHistory.length}
              onClick={() => {
                if (!editHistory.length) return;
                set((item) => {
                  item.items = editHistory[0];
                  setEditHistory(editHistory.slice(1, 5));
                });
              }}
            />
          </div>

          <NewButton
            onClick={close}
            className="ml-auto !p-2.5"
            variant="transparent"
          >
            {t("v4.generic.saveAndExit")}
            <TbX />
          </NewButton>
        </div>

        <div
          className="relative grow p-4"
          ref={sizeRef}
          onPointerDown={() => setSelected({})}
        >
          <div className="absolute-cover flex filter drop-shadow-xl">
            <div
              ref={parentRef}
              className={classNames(
                "absolute left-1/2 top-1/2 overflow-hidden bg-gray-200",
                isOverlayHidden
                  ? "[&_.diagramWord]:opacity-0 [&_.diagramWord]:pointer-events-none"
                  : isCreateBlock &&
                      "[&_.diagramWord]:opacity-50 [&_.diagramWord]:pointer-events-none"
              )}
              style={{
                height: image.h,
                width: image.w,
                transform: `translate(-50%, -50%) scale(${scale})`,
              }}
            >
              <ProgressiveCanvas
                crop={image}
                src={image.src}
                className={classNames(
                  "w-full h-full transition",
                  isImageHidden && "opacity-0"
                )}
              />
              <RangeLayer
                scale={scale}
                onSelect={(items, isMulti) => {
                  setSelected((selected) => ({
                    ...(isMulti && selected),
                    ...Object.fromEntries(items.map((id) => [id, true])),
                  }));
                }}
                onCreate={(area) => {
                  set((item) => {
                    const w = area.w / size;
                    const h = area.h / size;
                    item.items.push({
                      id: uuid(),
                      text: "",
                      angle: 0,
                      w,
                      h,
                      x: area.x + (area.w - w) / 2,
                      y: area.y + (area.h - h) / 2,
                    });
                  });
                  setCreateBlock(false);
                }}
                create={isCreateBlock}
              />
              {item.items.map((item, index) => (
                <DiagramEditWord
                  key={item.id}
                  selected={!!selected?.[item.id]}
                  {...{ item, index }}
                  handleSelect={(isMulti) => {
                    if (isMulti == null) {
                      setSelected(
                        produce((selected) => {
                          delete selected[item.id];
                        })
                      );
                    } else {
                      setSelected((selected) => ({
                        ...(isMulti && selected),
                        [item.id]: true,
                      }));
                    }
                  }}
                  parent={parentRef}
                  scale={1 / scale}
                  pushHistory={pushHistory}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export const RangeLayer = ({
  scale,
  onCreate,
  onSelect,
  create,
}: {
  scale: number;
  onCreate: (area: Dimensions) => void;
  onSelect: (items: string[], isMulti: boolean) => void;
  create: boolean;
}) => {
  const [item] = usePageItemContext<ImageLabeling>();
  const [area, setArea] = useState<Dimensions | null>(null);

  const handlePointer = (e: React.PointerEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const startX = e.clientX;
    const startY = e.clientY;
    const isMulti = e.shiftKey;

    const position = (e.target as HTMLDivElement).getBoundingClientRect();
    const x = (startX - position.x) / scale;
    const y = (startY - position.y) / scale;
    let area = {
      x,
      y,
      w: 0,
      h: 0,
    };
    setArea(area);

    const handleMove = (e: PointerEvent) => {
      e.stopPropagation();
      e.preventDefault();
      const { clientX, clientY } = e;
      const w = Math.min(
        Math.max(-x, (clientX - startX) / scale),
        position.width / scale - x
      );
      const h = Math.min(
        Math.max(-y, (clientY - startY) / scale),
        position.height / scale - y
      );
      area = {
        x: w > 0 ? x : x + w,
        y: h > 0 ? y : y + h,
        w: Math.abs(w),
        h: Math.abs(h),
      };
      setArea(area);
    };

    document.addEventListener("pointermove", handleMove);
    document.addEventListener(
      "pointerup",
      (e) => {
        e.stopPropagation();
        e.preventDefault();
        document.removeEventListener("pointermove", handleMove);

        const scaledArea = {
          x: area.x,
          y: area.y,
          w: area.w,
          h: area.h,
        };
        if (create) {
          onCreate(scaledArea);
        } else {
          const selection = item.items
            .filter((block) => isObjectInCrop(block, scaledArea))
            .map(({ id }) => id);

          onSelect(selection, isMulti);
        }
        setArea(null);
      },
      { once: true }
    );
  };

  return (
    <div className="absolute-cover" onPointerDown={handlePointer}>
      {!area ? null : !create ? (
        <div
          className="absolute bg-primary border border-primary bg-opacity-40 z-10"
          style={{
            left: area.x,
            top: area.y,
            width: area.w,
            height: area.h,
          }}
        />
      ) : (
        <div
          className="absolute transition text-white rounded-lg shadow-xl bg-gray-800 z-10"
          style={{
            left: area.x,
            top: area.y,
            width: area.w,
            height: area.h,
          }}
        />
      )}
    </div>
  );
};

export const DiagramEditWord = ({
  item: { text: initialText, w, h, x, y, angle },
  index,
  selected,
  handleSelect,
  parent,
  scale,
  pushHistory,
}: {
  item: ImageLabeling["items"][number];
  index: number;
  selected: boolean;
  handleSelect: (isMulti: boolean | null) => void;
  parent: React.RefObject<HTMLDivElement | null>;
  scale: number;
  pushHistory: () => void;
}) => {
  const [position, setPosition] = useState({ w, h, x, y, angle });
  const [isMoving, setMoving] = useState(false);
  const [item, set] = usePageItemContext<ImageLabeling>();
  const [isEditing, setIsEditing] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const [text, setText] = useState(initialText);

  useEffect(() => {
    setText(initialText);
  }, [initialText]);

  useEffect(() => {
    setPosition({ w, h, x, y, angle });
  }, [w, h, x, y, angle]);

  useEffect(() => {
    if (!isEditing && text !== initialText) {
      pushHistory();
      set((item) => {
        item.items[index].text = text;
      });
    }
  }, [isEditing]);

  useEffect(() => {
    if (!selected) setIsEditing(false);
  }, [selected]);

  const handleMovePointer = (e: React.PointerEvent<HTMLDivElement>) => {
    if (item.data?.w == null) return null;
    e.stopPropagation();
    // e.preventDefault()
    const startX = e.clientX;
    const startY = e.clientY;
    let hasMoved = false;

    const box = getBoundingBox(position);
    const xDiff = box.x - position.x;
    const yDiff = box.y - position.y;

    const maxLeft = item.data.w - box.w - xDiff;
    const maxTop = item.data.h - box.h - yDiff;

    const calculated = (x: number, y: number) => ({
      x: Math.min(Math.max(-xDiff, position.x + (x - startX) * scale), maxLeft),
      y: Math.min(Math.max(-yDiff, position.y + (y - startY) * scale), maxTop),
    });

    const handleMove = (e: PointerEvent) => {
      e.stopPropagation();
      // e.preventDefault()
      const { clientX, clientY } = e;
      if (isEditing) return;
      if (
        !hasMoved &&
        (clientX - startX) ** 2 + (clientY - startY) ** 2 > 5 ** 2
      ) {
        hasMoved = true;
        setMoving(true);
      }
      if (hasMoved) {
        setPosition((position) => ({
          ...position,
          ...calculated(clientX, clientY),
        }));
      }
    };

    document.addEventListener("pointermove", handleMove);
    document.addEventListener(
      "pointerup",
      (e) => {
        e.stopPropagation();
        // e.preventDefault()
        document.removeEventListener("pointermove", handleMove);
        setMoving(false);
        if (hasMoved) {
          const { clientX, clientY } = e;

          pushHistory();
          const area = calculated(clientX, clientY);
          set((item) => {
            item.items[index].x = area.x;
            item.items[index].y = area.y;
          });
        } else {
          if (selected) {
            // unselect
            if (e.shiftKey) return handleSelect(null);

            setIsEditing(true);
            handleSelect(false);
          } else {
            handleSelect(e.shiftKey);
          }
        }
      },
      { once: true }
    );
  };

  const handleRotatePointer = (e: React.PointerEvent<HTMLDivElement>) => {
    e.stopPropagation();
    // e.preventDefault()
    if (!ref.current) return;
    const position = ref.current.getBoundingClientRect();
    const centerX = position.x + position.width / 2;
    const centerY = position.y + position.height / 2;
    let newAngle = angle;

    const handleMove = (e: PointerEvent) => {
      e.stopPropagation();
      // e.preventDefault()
      const { clientX, clientY } = e;
      setMoving(true);
      const angle =
        (Math.atan2(clientY - centerY, clientX - centerX) * (-180 / Math.PI) +
          270) %
        360;
      const snap = 45;
      const closest = Math.round(angle / snap) * snap;
      newAngle = Math.abs(closest - angle) < 5 ? closest : angle;
      setPosition((position) => ({
        ...position,
        angle: newAngle,
      }));
    };

    document.addEventListener("pointermove", handleMove);
    document.addEventListener(
      "pointerup",
      (e) => {
        e.stopPropagation();
        // e.preventDefault()
        document.removeEventListener("pointermove", handleMove);
        setMoving(false);

        pushHistory();
        set((item) => {
          item.items[index].angle = newAngle;
        });
      },
      { once: true }
    );
  };

  const handleResizePointer = (e: React.PointerEvent<HTMLDivElement>) => {
    e.stopPropagation();
    // e.preventDefault()
    const rotatedStart = rotatePoint(
      position.x,
      position.y,
      position.x + position.w / 2,
      position.y + position.h / 2,
      -angle
    );

    if (!parent.current) return;
    const size = parent.current?.getBoundingClientRect();

    const calculated = (x: number, y: number) => {
      const end = [(x - size.x) * scale, (y - size.y) * scale];

      const newCenter = [
        (rotatedStart[0] + end[0]) / 2,
        (rotatedStart[1] + end[1]) / 2,
      ];

      const newStart = rotatePoint(
        rotatedStart[0],
        rotatedStart[1],
        newCenter[0],
        newCenter[1],
        angle
      );

      const newEnd = rotatePoint(
        end[0],
        end[1],
        newCenter[0],
        newCenter[1],
        angle
      );

      return {
        x: newStart[0],
        y: newStart[1],
        w: Math.max(20, newEnd[0] - newStart[0]),
        h: Math.max(20, newEnd[1] - newStart[1]),
      };
    };

    const handleMove = (e: PointerEvent) => {
      e.stopPropagation();
      // e.preventDefault()
      const { clientX, clientY } = e;
      setMoving(true);
      setPosition((position) => ({
        ...position,
        ...calculated(clientX, clientY),
      }));
    };

    document.addEventListener("pointermove", handleMove);
    document.addEventListener(
      "pointerup",
      (e) => {
        e.stopPropagation();
        // e.preventDefault()
        document.removeEventListener("pointermove", handleMove);
        setMoving(false);
        const { clientX, clientY } = e;

        pushHistory();
        const area = calculated(clientX, clientY);
        set((item) => {
          item.items[index].x = area.x;
          item.items[index].y = area.y;
          item.items[index].w = area.w;
          item.items[index].h = area.h;
        });
      },
      { once: true }
    );
  };

  const textRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (isEditing) textRef.current?.focus();
  }, [isEditing]);

  const content = useMemo(
    () => (
      <Textfit
        min={1}
        max={500}
        className="absolute-cover flex items-center justify-center text-center leading-none font-bold whitespace-pre-wrap"
        ref={undefined}
        style={{ minHeight: 1, minWidth: 1 }}
      >
        <div
          className={classNames(
            isEditing && "opacity-0 select-none pointer-events-none"
          )}
        >
          {text || (isEditing ? "a" : " ")}
        </div>
        {isEditing && (
          <label className="absolute-cover flex items-center justify-center text-center">
            <TextareaAutosize
              className="text-center w-full max-h-full leading-none bg-transparent text-white resize-none outline-none font-inherit scrollbar-none min-h-[1rem]"
              value={text}
              ref={textRef}
              onBlur={() => setIsEditing(false)}
              onChange={(e) => setText(e.target.value)}
            />
          </label>
        )}
      </Textfit>
    ),
    [text, isEditing, set, position, item]
  );

  return (
    <div
      ref={ref}
      className={classNames(
        "absolute transition-[color,background-color,opacity] text-white rounded-lg shadow-xl group diagramWord hover:z-10",
        isEditing ? "bg-gray-600" : "bg-gray-800 select-none",
        isMoving && "opacity-50 z-20",
        selected && "outline-8 outline-primary outline"
      )}
      onPointerDown={handleMovePointer}
      style={{
        left: position.x,
        top: position.y,
        width: position.w,
        height: position.h,
        transform: `rotate(-${position.angle}deg) scale(${item.size ?? 1})`,
      }}
    >
      {content}
      {!isEditing && (
        <>
          <HandleButton
            size={item.size}
            icon={TbRotate}
            onPointerDown={handleRotatePointer}
            visible={!isMoving}
            className="top-0 left-1/2 cursor-grab"
            translate="-50%, -50%"
            scale={scale}
          />
          <HandleButton
            size={item.size}
            icon={TbArrowsDiagonal2}
            onPointerDown={handleResizePointer}
            visible={!isMoving}
            className="bottom-0 right-0 cursor-grab"
            translate="25%, 25%"
            scale={scale}
          />
        </>
      )}
    </div>
  );
};

const HandleButton = ({
  size,
  icon: Icon,
  onPointerDown,
  visible = false,
  className = "",
  translate,
  scale,
}: {
  size: number;
  icon: IconType;
  onPointerDown: (e: React.PointerEvent<HTMLDivElement>) => void;
  visible?: boolean;
  className?: string;
  translate: string;
  scale: number;
}) => (
  <div
    onPointerDown={onPointerDown}
    className={classNames(
      "absolute w-4 h-4 rounded bg-primary transition opacity-0",
      className,
      visible && "group-hover:opacity-80 group-hover:hover:opacity-100"
    )}
    style={{
      transform: `translate(${translate}) scale(${(1 / size) * scale})`,
    }}
  >
    <Icon className="absolute-cover p-[10%] object-contain" strokeWidth={3} />
  </div>
);

export const PageImageLabelingRender =
  withConsumption<ImageLabelingConsumption>((item) => {
    const data = item.data;
    if (data?.w == null) return null;
    return <InnerLabelingRender {...item} data={data} />;
  });

export const InnerLabelingRender = ({
  answer,
  items,
  data: image,
  set,
  id,
  type,
  showOptions,
  consumptionType,
  size,
}: ImageLabelingConsumption & {
  set: any;
  data: CropSize;
}) => {
  const { ref: sizeRef, width, height } = useResizeObserver<HTMLDivElement>();
  const scale = useMemo(
    () => Math.min((width || 0) / image.w, (height || 0) / image.h),
    [width, height, image.w, image.h]
  );

  const handleChange = useCallback(
    (id: string) => (text: string) => {
      set((item) => {
        item.answer[id] = text;
      });
      return;
    },
    [set]
  );

  const [selected, setSelected] = useState<number | null>(null);

  const sorted = useMemo(
    () =>
      [...items].sort((a, b) => {
        const centerA = { x: (a.x + a.w) / 2, y: (a.y + a.h) / 2 };
        const centerB = { x: (b.x + b.w) / 2, y: (b.y + b.h) / 2 };
        const tolerance = (a.h + b.h) / 2 / 4;

        if (Math.abs(centerA.y - centerB.y) > tolerance)
          return centerA.y > centerB.y ? 1 : -1;
        return centerA.x > centerB.x ? 1 : -1;
      }),
    [items]
  );

  const setActive = useCallback(
    (i: number) => (value: boolean) => setSelected(value ? i : null),
    []
  );
  const setNextActive = useCallback(
    (i: number) => () => {
      const nextAfter = sorted.findIndex(
        ({ id }, index) => index > i && !answer?.[id]
      );
      if (nextAfter > -1) return setSelected(nextAfter);

      const nextBefore = sorted.findIndex(
        ({ id }, index) => index < i && !answer?.[id]
      );
      if (nextBefore > -1) return setSelected(nextBefore);
    },
    [sorted, answer]
  );

  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <QuizItemLabel type={type} consumptionType={consumptionType} />
      <QuizInstructionsRender id={id} />
      <LabelingAnswersRender
        {...{ answer, type, items, showOptions, consumptionType }}
      />
      <div
        className="mx-auto w-full overflow-hidden max-h-90 relative bg-gray-200 rounded-xl"
        style={{
          aspectRatio: image.w + " / " + image.h,
        }}
        ref={sizeRef}
      >
        <div
          className="absolute left-1/2 top-1/2 overflow-hidden"
          style={{
            height: image.h,
            width: image.w,
            transform: `translate(-50%, -50%) scale(${scale})`,
          }}
        >
          <ProgressiveCanvas
            crop={image}
            src={image.src}
            className="w-full h-full"
          />
          {sorted.map((item, i) => (
            <DiagramWord
              key={item.id}
              size={size}
              {...item}
              text={answer[item.id]}
              onChange={handleChange(item.id)}
              active={selected === i}
              setActive={setActive(i)}
              setNextActive={setNextActive(i)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const InnerLabelingResults = ({
  item,
}: {
  item: ImageLabelingResults;
}) => {
  const { data: image, items, answer, result, instructions } = item;
  const { ref: sizeRef, width, height } = useResizeObserver<HTMLDivElement>();
  const scale = useMemo(() => {
    if (image?.w == null) return 1;
    return Math.min((width || 0) / image.w, (height || 0) / image.h);
  }, [width, height, image?.w, image?.h]);

  if (!image) return null;

  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <InstructionsRender instructions={instructions} />
      <div
        className="mx-auto w-full overflow-hidden max-h-90 relative bg-gray-200 rounded-xl"
        style={{
          aspectRatio: image.w + " / " + image.h,
        }}
        ref={sizeRef}
      >
        <div
          className="absolute left-1/2 top-1/2 overflow-hidden"
          style={{
            height: image.h,
            width: image.w,
            transform: `translate(-50%, -50%) scale(${scale})`,
          }}
        >
          <ProgressiveCanvas
            crop={image}
            src={image.src}
            className="w-full h-full"
          />
          {items.map((item) => (
            <DiagramWord
              key={item.id}
              {...item}
              text={answer[item.id]}
              result={result?.[item.id]}
              content={item.text}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const InnerLabelingCorrect = ({ item }: { item: ImageLabeling }) => {
  const successRate = useSuccessRate<Record<string, number>>([item.id]);
  const { data: image, items, instructions } = item;
  const { ref: sizeRef, width, height } = useResizeObserver<HTMLDivElement>();
  const scale = useMemo(() => {
    if (image?.w == null) return 1;
    return Math.min((width || 0) / image.w, (height || 0) / image.h);
  }, [width, height, image?.w, image?.h]);

  if (!image) return null;

  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <InstructionsRender instructions={instructions} />
      <div
        className="mx-auto w-full overflow-hidden max-h-90 relative bg-gray-200 rounded-xl"
        style={{
          aspectRatio: image.w + " / " + image.h,
        }}
        ref={sizeRef}
      >
        <div
          className="absolute left-1/2 top-1/2 overflow-hidden"
          style={{
            height: image.h,
            width: image.w,
            transform: `translate(-50%, -50%) scale(${scale})`,
          }}
        >
          <ProgressiveCanvas
            crop={image}
            src={image.src}
            className="w-full h-full"
          />
          {items.map((label) => (
            <InnerLabelingCorrectItem
              key={label.id}
              item={label}
              size={item.size}
              successRate={successRate?.[label.id] || null}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const InnerLabelingCorrectItem = ({
  item,
  successRate,
  size,
}: {
  item: ImageLabeling["items"][number];
  successRate: number | null;
  size: number;
}) => {
  const ref = useRef<HTMLLabelElement | null>(null);

  return (
    <>
      <DiagramWord ref={ref} size={size} {...item} text={item.text} />
      {successRate != null && (
        <TooltipRaw
          visible
          key={item.id}
          content={
            <div className="shrink-0 rounded relative bg-white">
              <div className="bg-primary bg-opacity-10 border-l-2 border-primary text-sm font-bold px-1 text-primary rounded">
                {Math.round(successRate * 100)}
                <span className="text-xs">%</span>
              </div>
            </div>
          }
          appendTo={
            document.getElementById("session-teacher-scroll") || document.body
          }
          reference={ref}
          placement="left"
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: { offset: [0, 4] },
              },
            ],
          }}
        />
      )}
    </>
  );
};
