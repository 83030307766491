import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { useQuery } from "react-query";
import { getSource, openSource } from "api/sourcesAPI";
import { FileType, mapMime } from "helpers/mimeType";
import {
  AudioData,
  PageItemType,
  VideoData,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  TbAlertTriangle,
  TbArrowBackUp,
  TbCheck,
  TbFile,
  TbMarquee,
  TbPhoto,
  TbPlaylist,
  TbPlaylistOff,
  TbVideo,
  TbVolume,
  TbX,
} from "react-icons/tb";
import { CgSpinner } from "react-icons/cg";
import {
  DocumentImageSource,
  DocumentRangeData,
  useEdgeScroll,
} from "app/components/Sources/MediaPicker/SingleSource/DocumentImageSource";
import { AudioPlayer, VideoPlayer } from "app/components/MediaPlayer";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  MediaData,
  SelectOnlyType,
} from "app/components/Sources/MediaPicker/MediaPicker";
import classNames from "classnames";
import {
  useSourceUploadContext,
  useSourceUploadStore,
} from "api/ws/SourceUploadContext";
import { SkeletonParagraph } from "app/components/Exercises/CourseEdit/components/SkeletonParagraph";
import { useSelectOnly } from "app/components/Sources/MediaPicker/context/selectOnlyContext";
import { useSourcePageQuery } from "app/components/Exercises/Edit/context/SourceContext";
import { ProgressiveCanvas } from "app/components/Helpers/ProgressiveImage";
import { useRouteMatch } from "react-router-dom";
import { libraryRoutes } from "enums/routes";
import { useRole } from "../../../../hooks/useRole";

export const SingleSource = ({
  close,
  back,
  sourceId,
  onInsert,
}: {
  close: () => void;
  back: () => void;
  sourceId: string;
  onInsert: (media: MediaData | string | DocumentRangeData) => void;
}) => {
  const isPublicView = !!useRouteMatch(libraryRoutes.public);
  const selectOnly = useSelectOnly();
  const { t } = useTranslation();

  const handleError = () => {
    back();
    toast.error(t(`common.errors.no_found_resource`));
  };

  const { isSuccess, data } = useQuery({
    queryKey: ["source", sourceId],
    queryFn: () => getSource(sourceId),
    enabled: !!sourceId,
    onError: handleError,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!isPublicView && sourceId) openSource(sourceId);
  }, [sourceId, isPublicView]);

  const type = data?.mime_type ? mapMime(data.mime_type) : null;

  return (
    <>
      <div className="p-1 font-bold border-b-2 border-gray-100 flex items-center gap-2 text-gray-500">
        {selectOnly !== SelectOnlyType.none && (
          <NewButton onClick={back} size="sm">
            <TbArrowBackUp />
            {t("v4.generic.back")}
          </NewButton>
        )}

        {isSuccess && (
          <>
            {type === FileType.Document ? (
              <TbFile className="text-lg" />
            ) : type === FileType.Image ? (
              <TbPhoto className="text-lg" />
            ) : type === FileType.Video ? (
              <TbVideo className="text-lg" />
            ) : type === FileType.Audio ? (
              <TbVolume className="text-lg" />
            ) : null}
            <div className="font-bold text-gray-500">{data.name}</div>
          </>
        )}
        <NewButton
          iconOnly
          onClick={close}
          className="ml-auto"
          variant="transparent"
        >
          <TbX />
        </NewButton>
      </div>
      {!isSuccess ? (
        <div className="px-8 py-16 m-auto text-gray-400 text-lg text-center">
          <CgSpinner className="text-3xl m-auto animate-spin" />
        </div>
      ) : type === FileType.Image || type === FileType.Document ? (
        <DocumentImageSource
          sourceId={sourceId}
          onConfirm={onInsert}
          isImage={type === FileType.Image}
          selectOnly={selectOnly}
        />
      ) : type === FileType.Video || FileType.Audio ? (
        <AudioVideoSource
          sourceId={sourceId}
          onConfirm={onInsert}
          isVideo={type === FileType.Video}
        />
      ) : null}
    </>
  );
};

export const DocumentPage = ({
  sourceId,
  page,
  size,
  skipTimeout,
}: {
  sourceId: string;
  page: number;
  size?: { width: number; height: number };
  skipTimeout?: boolean;
}) => {
  const initialMemoSize = {
    width: size?.width ?? 1,
    height: size?.height ?? 1,
  };
  const [memoSize, setMemoSize] = useState(initialMemoSize);

  useEffect(() => {
    setMemoSize(initialMemoSize);
  }, [sourceId]);

  const pageData = useSourcePageQuery(
    sourceId,
    page,
    false,
    {
      enabled: skipTimeout,
      onSuccess: (data) => {
        setMemoSize({ height: data.image_height, width: data.image_width });
      },
    },
    false
  );

  const aspectRatio = memoSize.width + " / " + memoSize.height;

  const isHorizontal = memoSize.width / memoSize.height > 1;

  useEffect(() => {
    if (pageData.isSuccess) return;
    const timeout = setTimeout(pageData.refetch, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [page]);

  if (!pageData.isSuccess)
    return (
      <div
        style={{ aspectRatio }}
        className={classNames(
          "bg-gray-100 max-w-[min(45vw,100%)] max-h-[min(40vh, 100%)] rounded-xl shadow-xl flex items-center justify-center",
          isHorizontal ? "w-full h-fit" : "w-fit h-full"
        )}
      >
        <CgSpinner className="text-xl text-gray-500 animate-spin" />
      </div>
    );
  // s
  return (
    <ProgressiveCanvas
      className={classNames(
        "max-w-[min(45vw,100%)] max-h-[min(40vh, 100%)] rounded-xl shadow-xl border border-gray-100",
        isHorizontal ? "w-full h-fit" : "w-fit h-full"
      )}
      src={pageData?.data?.image_url}
      crop={{
        x: 0,
        y: 0,
        w: pageData.data.image_width,
        h: pageData.data.image_height,
        sizeX: pageData.data.image_width,
        sizeY: pageData.data.image_height,
        sourceId,
      }}
    />
  );
};

const AudioVideoSource = ({
  sourceId,
  onConfirm,
  isVideo,
}: {
  sourceId: string;
  isVideo: boolean;
  onConfirm: (data: VideoData | AudioData | string) => void;
}) => {
  const role = useRole();
  const { t } = useTranslation();
  const selectOnly = useSelectOnly();
  const { getTranscript, startTranscript, resetTranscript } =
    useSourceUploadContext();
  const transcript = useSourceUploadStore(
    (store) => store.transcript?.[sourceId]
  );
  const [view_, setView] = useState<"media" | "transcript">("media");
  const view = role.aiEnabled ? view_ : "media";
  const { data } = useQuery({
    queryKey: ["source", sourceId],
    queryFn: () => getSource(sourceId),
    enabled: !!sourceId,
    refetchOnWindowFocus: false,
  });
  // const transcription = useQuery({
  //   queryKey: ["sourceTranscription", sourceId],
  //   queryFn: () => getVideoTranscription(data.transcription_id),
  //   enabled: !!data?.transcription_id && view === "transcript",
  //   refetchOnWindowFocus: false,
  // });

  useEffect(() => {
    getTranscript(sourceId);
  }, [sourceId]);

  const handleConfirm = () => {
    if (view === "media") {
      if (isVideo) {
        return onConfirm({
          type: PageItemType.Video,
          data: {
            sourceId,
            src: data.download_url,
          },
        });
      }

      return onConfirm({
        type: PageItemType.Audio,
        data: {
          sourceId,
          src: data.download_url,
        },
      });
    }

    if (!words?.length) return;
    if (from == null || to == null) return;
    onConfirm(words.slice(from, to + 1).join(""));
  };

  const text = transcript?.data?.text; // || transcription?.data?.text;

  const words = useMemo<string[]>(() => {
    if (!text) return [];
    return text.split(/(\p{Letter}+|[^\p{Letter}\s]+)/u).filter(Boolean);
  }, [text]);

  const [start, setStart] = useState<number | null>(null);
  const [end, setEnd] = useState<number | null>(null);
  const [selecting, setSelecting] = useState(false);
  const [from, to] = useMemo(() => {
    if (start == null || end == null) return [null, null];
    if (end < start) return [end, start];
    return [start, end];
  }, [start, end]);

  useEffect(() => {
    if (view === "transcript") {
      setStart(null);
      setEnd(null);
      setSelecting(false);
    }
  }, [view]);

  const canSelect = useMemo(() => !selectOnly, [!selectOnly]);

  const handleTextPointerDown = useCallback(
    (pos: number) => (e: React.PointerEvent<HTMLDivElement>) => {
      if (!canSelect) return;
      e.stopPropagation();
      e.preventDefault();
      // @ts-ignore
      e?.target?.releasePointerCapture?.(e.pointerId);
      // if (from != null && to != null) return;
      setStart(pos);
      setEnd(pos);
      setSelecting(true);

      document.addEventListener(
        "pointerup",
        (e) => {
          e.stopPropagation();
          e.preventDefault();
          setSelecting(false);
        },
        { once: true }
      );
    },
    [canSelect]
  );

  const containerRef = useRef<HTMLDivElement | null>(null);
  useEdgeScroll(containerRef, selecting);

  const handleTextPointerOver = useCallback(
    (pos: number) => (e: React.PointerEvent<HTMLDivElement>) => {
      if (!canSelect) return;
      if (selecting) {
        e.stopPropagation();
        e.preventDefault();
        setEnd(pos);
      }
    },
    [selecting, canSelect]
  );

  const handleEntireText = () => {
    return onConfirm(text || "");
  };

  return (
    <>
      {role.aiEnabled && (
        <div className="font-bold bg-gray-100 pt-0.5 flex text-gray-500 overflow-hidden">
          {[
            {
              icon: isVideo ? TbVideo : TbVolume,
              text: isVideo ? t("v4.item.video.text") : t("v4.item.audio.text"),
              value: "media" as const,
            },
            {
              icon: TbPlaylist,
              text: t("v4.library.transcript.text"),
              value: "transcript" as const,
            },
          ].map(({ icon: Icon, text, value }, i) => (
            <div
              key={i}
              className={classNames(
                "flex flex-1 items-center justify-center bg-white text-center gap-1.5 transition p-1 rounded-t-xl shadow-xl",
                value === view
                  ? "text-primary"
                  : "bg-opacity-0 hover:bg-opacity-50 cursor-pointer"
              )}
              onClick={() => setView(value)}
            >
              <Icon className="text-2xl" /> {text}
            </div>
          ))}
        </div>
      )}
      <div className="grow flex flex-col justify-center relative">
        <div className="absolute-cover overflow-y-auto" ref={containerRef}>
          {view === "media" ? (
            <div className="flex flex-col justify-center min-h-full min-w-full">
              {isVideo ? (
                <div className="p-8">
                  <VideoPlayer src={data.download_url} />
                </div>
              ) : (
                <div className="p-8">
                  <AudioPlayer src={data.download_url} />
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center p-4 gap-2 min-h-full min-w-full text-center">
              {text != null ? (
                <div
                  className={classNames(
                    "max-w-prose w-full leading-relaxed whitespace-pre-line bg-gray-100 p-4 rounded-xl text-gray-700",
                    canSelect && "select-none"
                  )}
                >
                  {!words?.length ? (
                    <span className="text-sm text-gray-500 italic">
                      {t("v4.library.transcript.empty")}
                    </span>
                  ) : (
                    words.map((word, i) => (
                      <span
                        key={i}
                        onPointerDown={handleTextPointerDown(i)}
                        onPointerOver={handleTextPointerOver(i)}
                        className={classNames(
                          "bg-primary bg-opacity-0",
                          canSelect && "cursor-text",
                          !canSelect
                            ? ""
                            : from != null && to != null && i >= from && i <= to
                            ? "bg-opacity-50"
                            : "hover:bg-opacity-20 transition"
                        )}
                      >
                        {word}
                      </span>
                    ))
                  )}
                </div>
              ) : data.transcription_id != null ? (
                <div className="max-w-prose w-full leading-relaxed whitespace-pre-line bg-gray-100 p-4 rounded-xl text-gray-700 select-none">
                  <SkeletonParagraph min={50} max={60} />
                </div>
              ) : !transcript ? (
                <>
                  <TbPlaylistOff
                    className="text-gray-400 text-5xl"
                    strokeWidth={1.5}
                  />
                  <div className="text-gray-600 text-xl font-bold">
                    {t("v4.library.transcript.notGenerated")}
                  </div>
                  <div className="text-gray-400 max-w-sm text-sm">
                    {t("v4.library.transcript.generateInfo")}
                  </div>
                  <NewButton
                    variant="primary"
                    className="text-xl mt-4"
                    size="lg"
                    onClick={() => startTranscript(sourceId)}
                  >
                    {t("v4.generic.start")}
                  </NewButton>
                </>
              ) : transcript.error ? (
                <>
                  <TbAlertTriangle
                    className="text-gray-400 text-5xl"
                    strokeWidth={1.5}
                  />
                  <div className="text-gray-600 text-xl font-bold">
                    {t("v4.library.transcript.generateError")}
                  </div>
                  <div className="text-gray-400 max-w-sm text-sm">
                    {t("v4.error.tryAgainLater")}
                  </div>
                  <NewButton
                    variant="primary"
                    className="text-xl mt-4"
                    size="lg"
                    onClick={() => resetTranscript(sourceId)}
                  >
                    {t("v4.generic.restart")}
                  </NewButton>
                </>
              ) : (
                <>
                  <div className="text-gray-600 text-xl font-bold">
                    {t("v4.library.transcript.generating")}
                  </div>
                  <div className="text-gray-400 max-w-sm text-sm">
                    {t("v4.library.transcript.generatingInfo")}
                  </div>
                  {!transcript?.error && (
                    <div className="w-full max-w-md overflow-hidden rounded-lg relative bg-gray-100 text-gray-600 h-8">
                      <div
                        className="absolute-cover bg-primary transition origin-left"
                        style={{
                          transform: `scaleX(${
                            (transcript?.progress ?? 0) / 100
                          })`,
                        }}
                      />
                      <div className="absolute-cover flex items-center justify-center text-center text-sm font-bold">
                        {Math.round(transcript?.progress ?? 0)}%
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {selectOnly !== SelectOnlyType.none && (
        <div className="p-1 font-bold border-t-2 border-gray-100 flex items-center gap-1 text-gray-500">
          <div className="grow" />
          {view === "transcript" && text && (
            <NewButton
              onClick={handleEntireText}
              color="bg-primary text-primary"
            >
              <TbMarquee /> {t("v4.library.useEntireText")}
            </NewButton>
          )}
          <NewButton
            onClick={handleConfirm}
            variant="primary"
            disabled={
              view === "transcript" &&
              (from == null || to == null || !words?.length)
            }
          >
            <TbCheck /> {t("v4.generic.confirm")}
          </NewButton>
        </div>
      )}
    </>
  );
};
