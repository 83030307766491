import {
  usePageItemContext,
  usePageItemIndex,
  useSetList,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  List,
  PageItemType,
  RichParagraph,
  SlideParagraph,
  SlideParagraphAutocomplete,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { SlideItemWrapper } from "app/components/Exercises/CourseEdit/slide/SlideItemWrapper";
import React, {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Editable, ReactEditor, Slate, useSlate } from "slate-react";
import { Descendant, Editor, Node, Range, Transforms } from "slate";
import { usePageItemFocus } from "app/components/Exercises/CourseEdit/items/PageItemFocus";
import {
  LinkFormats,
  PageLeaf,
  PageLeafRender,
  paragraphsToText,
  usePageLinkContext,
  useTextEditor,
} from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import {
  ActiveButton,
  StyledEditable,
  toggleMarkHotkey,
} from "app/components/Exercises/Edit/questionType/Slide/item/ItemText";
import classNames from "classnames";
import { useSlideItemContext } from "app/components/Exercises/CourseEdit/items/SlideItemContext";
import {
  FontSizeFormats,
  TextAlignToolbar,
  TextClearFormat,
  TextFormats,
  TextVariantToolbar,
  TextVerticalAlignChange,
} from "app/components/Exercises/CourseEdit/items/TextEditMenu";
import { TextGenerate } from "app/components/Exercises/CourseEdit/components/generate/TextGenerate";
import {
  DefaultElementType,
  LinkElementType,
} from "app/components/Exercises/CourseEdit/components/ftg/FillTheGapUtils";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbAlignLeft, TbFileSearch, TbLink, TbLinkOff } from "react-icons/tb";
import { HorizontalLine } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import {
  DropMedia,
  DropMediaType,
} from "app/components/Sources/MediaPicker/context/dropMediaContext";
import { useTranslation } from "react-i18next";
import { ListChangeVariant } from "app/components/Exercises/CourseEdit/items/content/PageListItem";
import { InputText } from "../../../../Buttons/InputText";

export const SlidePageParagraph = () => {
  const [item, set] = usePageItemContext<SlideParagraph>();
  const editor = useTextEditor();

  const text = useMemo<DefaultElementType[]>(() => {
    return item.data.map((children) => ({ type: "paragraph", children }));
  }, [item.data]);

  // useEffect(() => {
  //   editor.children = item.data.map((children) => ({
  //     children,
  //     type: "paragraph",
  //   }));
  // }, []);

  const handleTextChange = (text: Descendant[]) => {
    if (
      !editor?.operations ||
      (editor.operations.length === 1 &&
        editor.operations[0]?.type === "set_selection")
    )
      return;

    set((item) => {
      item.data = text.map((item) => (item as any).children);
    });
  };

  return (
    <Slate editor={editor} value={text} onChange={handleTextChange}>
      <SlidePageParagraphInner />
    </Slate>
  );
};

export const SlideFormatMenu = ({
  selected,
  list = false,
  editing = false,
}) => {
  const { t } = useTranslation();
  const openLink = usePageLinkContext((s) => s.value);
  const set = usePageLinkContext((s) => s.set);

  const editor = useSlate();
  const [item, position] = (() => {
    if (!openLink) return [null, null];
    for (const [item, location] of Node.nodes(editor)) {
      if ("type" in item && item.type === "link" && item.id === openLink)
        return [item, location] as const;
    }

    return [null, null];
  })();

  const handleChangeUrl = (url: string) => {
    if (!position) return;
    Transforms.setNodes(editor, { url }, { at: position });
  };

  useEffect(() => {
    if (!editing) set(null);
  }, [editing]);

  const openLinkRef = useRef(openLink);
  useEffect(() => {
    const current = openLinkRef.current;
    openLinkRef.current = openLink;
    if (openLink && openLink !== current) return;

    if (!position) return;
    if (!editor.selection) return;

    if (!Range.includes(editor.selection, position)) set(null);
  }, [openLink, editor.selection]);

  if (!selected && position)
    return (
      <div className="flex flex-col items-center gap-1 origin-bottom z-[1]">
        <div className="p-1 bg-white border border-gray-200 shadow-lg rounded-xl flex items-center">
          <div className="flex flex-col items-center gap-2">
            <div className="flex items-center gap-1">
              <FontSizeFormats position={position} />
            </div>
            <div className="flex items-center gap-1">
              <TextFormats position={position} />
              <div className="h-6 border-l border-r border-gray-200 mx-1" />
              <TextClearFormat position={position} />
            </div>
          </div>
        </div>
        <div className="p-1 bg-white border border-gray-200 shadow-lg rounded-xl flex items-center origin-bottom z-[1]">
          {item.source ? (
            <a href={item.source} target="_blank">
              <ActiveButton icon={TbFileSearch} />
            </a>
          ) : (
            <InputText
              placeholder={t("v4.item.text.link")}
              value={item?.url || ""}
              onChange={handleChangeUrl}
              icon={TbLink}
              className="mr-1"
            />
          )}

          <div className="h-6 border-l border-r border-gray-200 ml-0.5 mx-1" />

          <ActiveButton
            icon={TbLinkOff}
            onClick={() => {
              Transforms.unwrapNodes(editor, { at: position });
            }}
          />
        </div>
      </div>
    );

  return (
    <div className="p-1 bg-white border border-gray-200 shadow-lg rounded-xl flex items-center origin-bottom z-[1]">
      <div className="flex flex-col items-center gap-2">
        {selected ? (
          <>
            <div className="flex items-center gap-1">
              <FontSizeFormats />
            </div>
            <div className="flex items-center gap-1">
              <TextFormats />
              <div className="h-6 border-l border-r border-gray-200 mx-1" />
              <LinkFormats />
              <div className="h-6 border-l border-r border-gray-200 mx-1" />
              <TextClearFormat />
            </div>
          </>
        ) : position ? (
          <>
            <div className="flex items-center gap-1">
              <FontSizeFormats position={position} />
            </div>
            <div className="flex items-center gap-1">
              <TextFormats position={position} />
              <div className="h-6 border-l border-r border-gray-200 mx-1" />
              <TextClearFormat position={position} />
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center gap-1">
              <FontSizeFormats block />
            </div>

            <div className="flex items-center gap-1">
              <TextVariantToolbar />
              <div className="h-6 border-l border-r border-gray-200 mx-1" />
              {list ? <ListChangeVariant /> : <TextAlignToolbar />}
              <div className="h-6 border-l border-r border-gray-200 mx-1" />
              <TextVerticalAlignChange />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const PageBlockSlide = ({ attributes, children, element }) => {
  if (element.type === "link") {
    // console.log({ attributes, children, element });
    return (
      <PageBlockLinkSlide {...{ attributes, element }}>
        {children}
      </PageBlockLinkSlide>
    );
  }

  return <p {...attributes}>{children}</p>;
};

export const PageBlockLinkSlide = ({
  attributes,
  element,
  children,
}: {
  attributes: any;
  element: LinkElementType;
  children: any;
}) => {
  const { selection } = useSlate();
  const open = usePageLinkContext((s) => s.value === element.id);
  const set = usePageLinkContext((s) => s.set);

  const collapsed = !selection || Range.isCollapsed(selection);

  return (
    <span {...attributes}>
      <span
        className={classNames(
          element.url || element.source ? "text-primary" : "text-red-600",
          "bg-primary underline relative transition rounded",
          open && collapsed
            ? "bg-opacity-20"
            : "hover:bg-opacity-10 bg-opacity-0"
        )}
        onPointerDown={() => set(element.id)}
      >
        {children}
      </span>
    </span>
  );
};

export const SlidePageParagraphInner = () => {
  const editor = useSlate();
  const [item, set] = usePageItemContext<SlideParagraph>();
  const [editing, setEditing] = useState(!!item?.focus);
  const { selected } = useSlideItemContext();
  const index = usePageItemIndex();
  const setList = useSetList();
  const { t } = useTranslation();

  useEffect(() => {
    if (item?.focus)
      set((item) => {
        delete item.focus;
      });
  }, [!!item?.focus]);

  const renderLeaf = useCallback((props) => <PageLeaf slide {...props} />, []);
  const renderBlock = useCallback((props) => <PageBlockSlide {...props} />, []);

  useEffect(() => {
    if (!selected) setEditing(false);
  }, [selected]);

  const setFocus = usePageItemFocus((distance, reverse) => {
    ReactEditor.focus(editor);
    editor.children = item.data.map<any>((children) => ({
      type: "paragraph",
      children,
    }));
    if (reverse) {
      Transforms.select(editor, {
        anchor: Editor.end(editor, []),
        focus: Editor.end(editor, []),
      });
    } else {
      Transforms.select(editor, {
        anchor: Editor.start(editor, []),
        focus: Editor.start(editor, []),
      });
    }
    Transforms.move(editor, {
      distance: distance,
      reverse: reverse,
    });
  });

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    toggleMarkHotkey(e, editor);
    // convert to list on space after a "-" or a "1."
    if (e.key === " ") {
      if (e.shiftKey) return;
      if (item.data.length > 1) return;
      if (item.align !== "left") return;
      const textValue = paragraphsToText(item.data);
      if (textValue !== "-" && textValue !== "1.") return;

      const variant = textValue === "1." ? "number" : "bullet";

      e.preventDefault();
      set<List>((item) => {
        item.type = PageItemType.List;
        item.variant = variant;
        item.data = [[{ text: "" }]];
        delete item["align"];
      });
      setFocus(item.id);
      return;
    }
  };

  const handlePointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
    if (editing) {
      e.stopPropagation();
      return false;
    }
    if (!selected) return;
    const startX = e.clientX;
    const startY = e.clientY;

    document.addEventListener(
      "pointerup",
      (e) => {
        if ((e.clientX - startX) ** 2 + (e.clientY - startY) ** 2 <= 5 ** 2) {
          setEditing(true);
          ReactEditor.focus(editor);
          Transforms.select(editor, Editor.end(editor, []));
        }
      },
      { once: true }
    );
  };

  useEffect(() => {
    editor.children = item.data.map((children) => ({
      children,
      type: "paragraph",
    }));
  }, []);

  // todo: debug lack of inFocus
  // const inFocus = useFocused();
  const hasSelected =
    /*inFocus &&*/ editor.selection && !Range.isCollapsed(editor.selection);

  const autocomplete = () => {
    setList((list) => {
      const placeholder: SlideParagraphAutocomplete = {
        id: item.id,
        type: PageItemType.ParagraphAutocomplete,
        category: "slideGenerate",
        position: item.position,
        data: item,
      };
      list.splice(index, 1, placeholder);
    });
  };

  const drop = (media: DropMediaType) => {
    const setData = (appendData: RichParagraph[]) => {
      set((item) => {
        item.data.push(...appendData);
      });
      setFocus(item.id, 0, true);
    };

    switch (media.type) {
      case PageItemType.Paragraph:
      case PageItemType.List:
        return () => {
          setData(media.data);
        };
    }
  };

  return (
    <SlideItemWrapper
      magic={
        hasSelected
          ? false
          : paragraphsToText(item.data).trim().length < 30
          ? t("v4.generate.prompt.minLength", { number: 30 }) || ""
          : (close) => (
              <>
                <NewButton variant="transparent" onClick={autocomplete}>
                  <TbAlignLeft /> {t("v4.item.autocomplete.text")}
                </NewButton>

                <HorizontalLine />
                <TextGenerate close={close} />
              </>
            )
      }
      customToolbar={(trash) => (
        <>
          <SlideFormatMenu selected={hasSelected} editing={editing} />
          {!hasSelected && trash}
        </>
      )}
    >
      <div className="absolute-cover">
        <StyledEditable
          className={classNames(
            "absolute-cover text-gray-500 p-4 overflow-y-auto rounded-2xl flex flex-col select-text leading-relaxed",
            editing
              ? "bg-gray-100 cursor-text select-text"
              : "cursor-default select-none"
          )}
          vertical={item.verticalAlign}
          style={{ textAlign: item.align }}
          onKeyDown={handleKeyDown}
          as={Editable}
          readOnly={!editing}
          renderLeaf={renderLeaf}
          renderElement={renderBlock}
          onPointerDown={handlePointerDown}
          onMouseDown={(e) => editing && e.stopPropagation()}
          placeholder={t("v4.generic.typingPlaceholder")}
        />
        <DropMedia onDrop={drop} />
      </div>
    </SlideItemWrapper>
  );
};

export const SlideParagraphRender = ({ item }: { item: SlideParagraph }) => (
  <StyledEditable
    className="absolute-cover flex flex-col p-4 bg-white leading-relaxed overflow-auto whitespace-pre-wrap"
    vertical={item.verticalAlign}
    style={{ textAlign: item.align }}
  >
    {item.data.map((paragraph, i) => (
      <div key={i} className="">
        {!paragraph.length ||
          (paragraph.length === 1 &&
            "text" in paragraph[0] &&
            !paragraph[0]?.text &&
            " ")}

        {paragraph.map((item, i) => (
          <PageLeafRender slide item={item} key={i} />
        ))}
      </div>
    ))}
  </StyledEditable>
);
