import { useTranslation } from "react-i18next";
import React from "react";
import { IoSparkles } from "react-icons/io5";
import { TbAlignJustified, TbLayoutList } from "react-icons/tb";

enum BlockSplit {
  Block,
  Mix,
  Line,
}

export const SplitSelector = ({ value, setValue }) => {
  const { t } = useTranslation();

  return (
    <>
      <h4 className="text-gray-500 font-bold text-center">
        {t("common.exercises.diagram.modal.header")}
      </h4>
      <div
        className="flex rounded-xl bg-gray-100 my-2 relative overflow-hidden"
        style={{ boxShadow: "inset #0001 0px 0px 1rem" }}
      >
        <div
          className="absolute overflow-hidden left-0 top-0 h-full w-1/3 bg-white transition rounded-xl"
          style={{ transform: `translate3d(${value * 100}%, 0, 0)` }}
        >
          <div className="absolute-cover bg-primary bg-opacity-20" />
        </div>
        <button
          onClick={() => setValue(BlockSplit.Block)}
          className="relative font-bold p-3 flex-col gap-0.5 text-center flex flex-1 items-center justify-center text-gray-500"
        >
          <TbLayoutList className="text-2xl" />
          <span>{t("common.exercises.diagram.modal.paragraphs")}</span>
        </button>
        <button
          onClick={() => setValue(BlockSplit.Mix)}
          className="relative font-bold p-3 flex-col gap-0.5 text-center flex flex-1 items-center justify-center text-primary"
        >
          <IoSparkles className="text-2xl" />
          <span>{t("common.exercises.diagram.modal.smart")}</span>
        </button>
        <button
          onClick={() => setValue(BlockSplit.Line)}
          className="relative font-bold p-3 flex-col gap-0.5 text-center flex flex-1 items-center justify-center text-gray-500"
        >
          <TbAlignJustified className="text-2xl" />
          <span>{t("common.exercises.diagram.modal.lines")}</span>
        </button>
      </div>
    </>
  );
};
