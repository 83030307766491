import React, { useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getOrganizationGroups,
  putRemoveGroupFromOrganization,
} from "../../../../api/organizationsAPI";
import Loader from "../../../components/Loader";
import ClassDetails from "./ClassDetails";
import { InputCheckboxControlled } from "../../../components/Buttons/InputCheckbox";
import { toast } from "react-hot-toast";
import { useParams } from "react-router";

export const OrganizationGroups = () => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const { data, isLoading, isSuccess } = useQuery(
    ["groups", id],
    () => getOrganizationGroups(id),
    { refetchOnWindowFocus: false, enabled: !!open }
  );
  const [selectedGroups, setSelectedGroups] = useState<any>([]);

  const deleteGroups = useMutation(
    () => {
      return putRemoveGroupFromOrganization({
        id,
        dataToSave: { groups: selectedGroups.map((item) => item.group_id) },
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["groups", id]);
        setSelectedGroups([]);
        if (data.removed_count < 1) {
          toast.error("Groups not found");
        } else {
          toast.success("Success");
        }
      },
      onError: () => {
        toast.error("Something went wrong");
      },
    }
  );

  const handleSelectUser = (org) => {
    const groupIdExist = selectedGroups.some(
      (o) => o.group_id === org.group_id
    );
    if (groupIdExist) {
      const index = selectedGroups.findIndex(
        (o) => o.group_id === org.group_id
      );
      if (index > -1) {
        setSelectedGroups(
          selectedGroups.filter((o) => o.group_id !== org.group_id)
        );
      }
    } else {
      setSelectedGroups([...selectedGroups, org]);
    }
  };

  return (
    <div className="bg-white rounded-2xl shadow-xl p-6 flex flex-col gap-2">
      {isLoading ? (
        <Loader />
      ) : isSuccess ? (
        <>
          <button
            style={{ margin: "0 20px 20px 0" }}
            onClick={() => deleteGroups.mutate()}
            disabled={selectedGroups.length < 1 || deleteGroups.isLoading}
          >
            Delete selected groups
          </button>
          <ScRow>
            <ScCol
              style={{
                minWidth: "20%",
                maxWidth: "20%",
                marginLeft: "40px",
              }}
            >
              <strong>Name</strong>
            </ScCol>
            <ScCol style={{ minWidth: "30%", maxWidth: "30%" }}>
              <strong>Owner</strong>
            </ScCol>
            <ScCol
              style={{ minWidth: "50%", maxWidth: "50%", textAlign: "center" }}
            >
              <strong>Members</strong>
            </ScCol>
          </ScRow>
          {data.results.map((item, index) => {
            return (
              <ScRow key={item.group_id}>
                <ScCol>
                  <ScColElement>
                    <InputCheckboxControlled
                      value={selectedGroups.some(
                        (org) => org.group_id === item.group_id
                      )}
                      toogleValue={() => handleSelectUser(item)}
                      id={item.group_id}
                    />
                  </ScColElement>
                </ScCol>
                <ScCol style={{ minWidth: "20%", maxWidth: "20%" }}>
                  <ScColElement>{item.group}</ScColElement>
                </ScCol>
                <ScCol style={{ minWidth: "30%", maxWidth: "30%" }}>
                  <ScColElement>{item.owner.name}</ScColElement>
                </ScCol>
                <ScCol style={{ minWidth: "50%", maxWidth: "50%" }}>
                  <ScColElement style={{ flexDirection: "column" }}>
                    {item.members.length || item.admins.length ? (
                      <>
                        <ClassDetails
                          members={item.members}
                          admins={item.admins}
                          id={id}
                          group_id={item.group_id}
                        />
                      </>
                    ) : (
                      <div>-</div>
                    )}
                  </ScColElement>
                </ScCol>
              </ScRow>
            );
          })}
        </>
      ) : (
        <div>Something went wrong</div>
      )}
    </div>
  );
};

const ScCol = styled.div`
  display: flex;
  flex-direction: column;

  input {
    &:first-child {
      margin-left: 0;
    }
    margin: 10px 0;
    max-width: 300px;
    height: 30px;
    font-size: 16px;
  }
`;

const ScRow = styled.div`
  display: flex;
`;

const ScColElement = styled.div`
  flex: 1;
  margin: 10px 0;
  padding-top: 10px;
  display: flex;
  align-items: center;
  border-top: 1px solid #b7b7b7;

  input {
    &:first-child {
      margin-left: 0;
    }

    margin: 10px;
    height: 30px;
    font-size: 16px;
  }
`;
