import React, { useEffect, useMemo, useState } from "react";
import { ProgressiveCanvas } from "app/components/Helpers/ProgressiveImage";
import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import { Image } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { ImageGenerate } from "app/components/Exercises/CourseEdit/components/generate/ImageGenerate";
import { CourseImage } from "app/components/Exercises/CourseEdit/components/InputWithMedia";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  TbArrowAutofitWidth,
  TbCheck,
  TbGripVertical,
  TbInfoCircle,
  TbPhotoStar,
  TbX,
} from "react-icons/tb";
import { Tooltip } from "app/components/Tooltip";
import {
  BigModal,
  BigModalBody,
  BigModalFooter,
  BigModalHeader,
} from "app/components/BigModal";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Resizable } from "react-resizable";
import styled from "styled-components";
import useResizeObserver from "use-resize-observer";
import { useRole } from "app/hooks/useRole";

const attributionRegex = /\[([^\]]+)\]\(([^)]+)\)/gu;

export const ImageAttribution = ({ data = "" }) => {
  const content = useMemo(() => {
    const split = data.split(attributionRegex).reduce((acc, text, i) => {
      if (i % 3 === 0) return [...acc, text];
      if (i % 3 === 1) return [...acc, [text]];
      return [...acc.slice(0, -1), [...acc.slice(-1)[0], text]];
    }, [] as (string | string[])[]);

    return split.map((item, i) => {
      if (typeof item === "string") return item;
      return (
        <a
          href={item[1]}
          key={`${i}${item[0]}${item[1]}`}
          target="_blank"
          className="underline text-primary hover:opacity-80 transition"
        >
          {item[0]}
        </a>
      );
    });
  }, [data]);

  return <div>{content}</div>;
};

export const ImageAttributionModal = ({ open = false, setOpen }) => {
  const [item, set] = usePageItemContext<Image>();
  const { t } = useTranslation();

  return (
    <BigModal fit open={open} stopPropagation>
      <BigModalHeader className="leading-none">
        <TbPhotoStar className="ml-2 shrink-0 text-xl" />
        {t("v4.item.image.attribution.text")}
        <NewButton
          variant="transparent"
          className="ml-auto"
          iconOnly
          onClick={() => setOpen(false)}
        >
          <TbX />
        </NewButton>
      </BigModalHeader>
      <BigModalBody className="text-gray-600 max-w-md w-[calc(100vw_-_4rem)]">
        <div className="text-sm font-bold mb-1">
          {t("v4.item.image.attribution.textLabel")}
        </div>
        <label className="flex flex-col bg-gray-100 rounded-xl focus-within:bg-primary focus-within:bg-opacity-10">
          <textarea
            onChange={(e) =>
              set((item) => {
                item.attribution = e.target.value
                  .replace(/\r?\n/, " ")
                  .substring(0, 600);
              })
            }
            value={item?.attribution ?? ""}
            placeholder={t("v4.generic.typingPlaceholder")}
            className="h-36 w-full resize-none text-gray-600 px-4 py-3 bg-transparent outline-none transition"
          />

          <div className="flex items-center gap-2 text-sm text-gray-400 mt-1 pr-2 pb-2">
            <div className="text-right whitespace-nowrap ml-auto">
              {(item?.attribution || "").length} / 600
            </div>
          </div>
        </label>
        <div className="text-gray-600 text-sm gap-1 mt-2">
          <TbInfoCircle className="text-base inline -mt-0.5 mr-1" />
          {t("v4.item.image.attribution.linkHint")}
          <span className="bg-gray-100 text-gray-500 rounded-lg px-1.5 py-0.5 font-bold ml-1">
            {t("v4.item.image.attribution.linkFormat")}
          </span>
        </div>

        {!!item?.attribution && (
          <>
            <div className="text-sm font-bold mb-1 mt-4">
              {t("v4.generic.preview")}
            </div>
            <div className="py-1 px-2 rounded-lg border border-gray-100">
              <ImageAttribution data={item?.attribution} />
            </div>
          </>
        )}
      </BigModalBody>
      <BigModalFooter>
        <NewButton
          variant="primary"
          className="ml-auto"
          onClick={() => setOpen(false)}
        >
          <TbCheck /> {t("v4.generic.confirm")}
        </NewButton>
      </BigModalFooter>
    </BigModal>
  );
};

export const PageImageItem = () => {
  const [item, set] = usePageItemContext<Image>();
  const [attributionOpen, setAttributionOpen] = useState(false);
  const { t } = useTranslation();
  const { ref, width } = useResizeObserver<HTMLDivElement>();

  const hasData = "w" in item.data;

  useEffect(() => {
    if (!hasData) {
      const img = new window.Image();
      img.onload = () => {
        set((item) => {
          item.data.page = 1;
          item.data.x = 0;
          item.data.y = 0;
          item.data.w = img.naturalWidth;
          item.data.h = img.naturalHeight;
          item.data.sizeX = img.naturalWidth;
          item.data.sizeY = img.naturalHeight;
        });
      };
      img.src = item.data.src;
    }
  }, [hasData]);

  if (!hasData || item.data.w == null) {
    return (
      <PageItemWrapper>
        <div className="w-full h-48 rounded-lg bg-gray-200 animate-pulse pointer-events-none" />
      </PageItemWrapper>
    );
  }

  return (
    <PageItemWrapper
      magic={
        !!item.data.sourceId && ((close) => <ImageGenerate close={close} />)
      }
      toolbar={(trash, close) => (
        <div className="flex flex-col gap-1">
          <div className="flex gap-1 justify-center">
            <Tooltip value={t("v4.item.image.attribution.text")}>
              <NewButton
                iconOnly
                size="lg"
                variant="transparent"
                onClick={() => {
                  setAttributionOpen(true);
                  close();
                }}
              >
                <TbPhotoStar />
              </NewButton>
            </Tooltip>
            <NewButton
              iconOnly
              size="lg"
              variant="transparent"
              disabled={item.width == null}
              onClick={() => {
                set((item) => {
                  delete item.width;
                });
                close();
              }}
            >
              <TbArrowAutofitWidth />
            </NewButton>
          </div>
          {trash}
        </div>
      )}
    >
      <div className="w-full" ref={ref} />

      {width != null && <InnerPageImageItem widthLimit={width} />}

      <div className="p-1 text-sm text-center">
        <ImageAttribution data={item?.attribution} />
      </div>
      <ImageAttributionModal
        open={attributionOpen}
        setOpen={setAttributionOpen}
      />
    </PageItemWrapper>
  );
};

const InnerPageImageItem = ({ widthLimit }: { widthLimit: number }) => {
  const role = useRole();
  const [item, set] = usePageItemContext<Image>();

  const getInitialWidth = (width: number | undefined) => {
    // if manual width is set, set to manual width, or maximum width if exceeding
    if (width != null) return Math.min(widthLimit, width);
    // set to initial width by default, max if exceeding
    return Math.min(item.data?.w ?? widthLimit, widthLimit);
  };

  const [width, setWidth] = useState(getInitialWidth(item.width));
  const [resizing, setResizing] = useState(false);

  useEffect(() => {
    if (item?.width == null) setWidth(getInitialWidth(undefined));
  }, [item?.width == null]);

  const handleResize = (_, { size }) => {
    if (size?.width == null) return;
    setWidth(Math.round(size.width));
  };

  const maxResizeWidth = Math.min(
    (item.data.w! / item.data.h!) * 800,
    widthLimit
  );

  return (
    <div className="image-group">
      <Resizable
        key={item.id}
        onResize={handleResize}
        width={Math.min(width, widthLimit)}
        height={100}
        maxConstraints={[maxResizeWidth, 0]}
        minConstraints={[50, 0]}
        axis="x"
        resizeHandles={["e"]}
        handle={(_, ref) => (
          <ResizeHandle
            className={classNames(
              "before:transition before:bg-primary -right-1.5 [.image-group:hover_&]:opacity-50 opacity-0 transition before:border-2 before:border-white",
              resizing ? "!opacity-50" : "hover:!opacity-100"
            )}
            ref={ref}
          >
            <TbGripVertical
              strokeWidth={3}
              className="relative left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-xs text-white"
            />
          </ResizeHandle>
        )}
        onResizeStart={() => setResizing(true)}
        onResizeStop={(e) => {
          setResizing(false);
          set((item) => {
            item.width = width;
          });
        }}
        className="transition transform"
      >
        <div
          className="relative flex max-w-full mx-auto"
          style={{ width: Math.min(width, widthLimit) }}
        >
          <ProgressiveCanvas
            crop={item.data}
            src={item.data.src}
            className="rounded-xl overflow-hidden mx-auto border-2 border-gray-100 w-full"
          />
        </div>
      </Resizable>
    </div>
  );
};

export const ResizeHandle = styled.div`
  -ms-touch-action: none;
  touch-action: none;

  cursor: ew-resize;
  height: 100%;
  width: 1rem;
  top: 0;
  position: absolute;
  z-index: 10;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    width: 0.75rem;
    height: calc(100% - 1rem);
    border-radius: 0.25rem;
  }
`;

export const PageImageRender = ({ item }: { item: Image }) => (
  <div className="max-w-full relative">
    <CourseImage
      media={item}
      className={classNames(item.width == null && "max-h-[50rem]")}
      preview
    />
    <div className="p-1 text-sm text-center">
      <ImageAttribution data={item?.attribution} />
    </div>
  </div>
);
