import { createBrowserHistory } from "history";
import { connectRouter } from "connected-react-router";
import {
  Action,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import userReducer from "./user/userSlice";
import authReducer from "./authentication/authSlice";
import exerciseUserLibraryReducer from "./exercise-library-user/exercise-library-user.slice";

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const reducers = combineReducers({
  router: connectRouter(history),
  user: userReducer,
  auth: authReducer,
  exerciseUserLibrary: exerciseUserLibraryReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return reducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
  devTools: process.env.NODE_ENV !== "production",
});

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./", () => store.replaceReducer(rootReducer));
}

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
