import React, { CSSProperties, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { StringLiteralLike } from "typescript";
import { LocationDescriptorObject } from "history";

export enum ButtonTypes {
  primary = "primary",
  secondary = "secondary",
  light = "light",
  transparent = "transparent",
}

interface Props {
  children: React.ReactNode;
  clickAction?: (data) => void;
  path?: string | LocationDescriptorObject;
  theme?: ButtonTypes;
  iconOnly?: boolean;
  disabled?: boolean;
  height?: string;
  width?: string;
  small?: boolean;
  mobile?: boolean;
  className?: string;
  style?: CSSProperties;
  ref?: React.Ref<any>;
}

const Button = ({
  disabled,
  clickAction = () => {},
  //onClick button action

  path,
  //use when button should be router <Link/>, it disables clickAction

  theme = ButtonTypes.primary,
  iconOnly = false,
  //remove button background, adjust dimensions

  children,
  height,
  //button height, ignore when "small" props

  width,
  //button width

  small,
  // smaller version of button

  mobile,
  //add fixed position on bottom screen on mobile

  style,
  className,

  ref,
}: Props) => {
  return path ? (
    <Link
      style={{
        textDecoration: "none",
        color: "#fff",
        ...style,
      }}
      to={path}
    >
      <ScButton
        className={className}
        ref={ref}
        style={{ ...style }}
        small={small}
        height={height}
        width={width}
        iconOnly={iconOnly}
        onClick={clickAction}
        theme={theme}
        disabled={disabled}
        mobile={mobile}
      >
        {children}
      </ScButton>
    </Link>
  ) : (
    <ScButton
      className={className}
      ref={ref}
      style={{ ...style }}
      small={small}
      height={height}
      width={width}
      iconOnly={iconOnly}
      onClick={clickAction}
      theme={theme}
      disabled={disabled}
      mobile={mobile}
    >
      {children}
    </ScButton>
  );
};

export { Button };

export const ScButton = styled.button<{
  height?: string | undefined;
  width?: string | undefined;
  theme?: ButtonTypes;
  iconOnly?: boolean | undefined;
  disabled?: boolean;
  small?: boolean;
  mobile?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${(p) => (p.iconOnly ? "20px" : p.small ? "70px" : "120px")};
  width: ${(p) => (p.width ? p.width : "fit-content")};
  height: ${(p) => (p.height ? p.height : "42px")};
  height: ${(p) => p.small && "32px"};
  pointer-events: ${(p) => p.disabled && "none"};
  line-height: 16px;
  background: ${(p) =>
    p.disabled
      ? "#f1f1f1"
      : p.iconOnly
      ? "transparent"
      : p.theme === ButtonTypes.light
      ? "var(--primary-button-background)"
      : p.theme === ButtonTypes.secondary
      ? "#e0e0e0"
      : "var(--primary-color)"};
  color: ${(p) =>
    p.theme === ButtonTypes.light
      ? "var(--primary-color)"
      : p.theme === ButtonTypes.secondary
      ? "var(--dark-grey)"
      : "#fff"};
  border-radius: 8px;
  padding: ${(p) => (p.small ? "16px" : "16px 24px")};
  font-weight: ${(p) =>
    p.theme === ButtonTypes.light || p.theme === ButtonTypes.secondary
      ? "normal"
      : "bold"};
  cursor: pointer;
  text-decoration: none;
  margin: ${(p) => (p.iconOnly ? "0" : "0 4px")};
  height: ${(p) => p.iconOnly && "18px"};
  font-size: ${(p) => (p.small ? "12px" : "16px")};
  @media screen and (max-width: 420px) {
    z-index: ${(p) => p.mobile && "110"};
    position: ${(p) => p.mobile && "fixed"};
    bottom: ${(p) => p.mobile && "10px"};
    width: ${(p) => p.mobile && "calc(90%)"};
    left: ${(p) => p.mobile && "50%"};
    transform: ${(p) => p.mobile && " translateX(-50%)"};
    margin: ${(p) => p.mobile && "0 auto"};
  }
  &:hover,
  &:focus-visible {
    background: ${(p) =>
      p.theme === ButtonTypes.light
        ? "#d9d8d8"
        : p.theme === ButtonTypes.secondary
        ? "#d9d8d8"
        : "var(--primary-color)"};
    background: ${(p) => p.iconOnly && "transparent"};
  }
  img {
    padding-right: 10px;
    height: 20px;
  }
  i {
    font-size: 1.3em;
  }
`;
