import React from "react";
import { useSessionContext } from "app/hooks/useSessionContext";
import { useTranslation } from "react-i18next";
import { TbClock, TbInfoSquare } from "react-icons/tb";
import { InputToggle } from "app/components/Buttons/InputToggle";
import classNames from "classnames";

export const LiveSessionToggles = () => {
  const { t } = useTranslation();
  const { isDuration, isOpen /*, isLeaderboard, isInfoOnly */ } =
    useSessionContext();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
      <label className="flex items-center gap-1.5 bg-gray-100 rounded-lg py-1.5 px-2 cursor-pointer transition">
        <TbInfoSquare
          className={classNames(
            "text-xl shrink-0 transition",
            isOpen.get && "text-primary"
          )}
        />
        <span className="mr-auto text-sm font-bold leading-none">
          {t("liveSession.lateJoin")}
        </span>
        <InputToggle
          value={isOpen.get}
          onChange={() => isOpen.set(!isOpen.get)}
        />
      </label>
      {/*<label*/}
      {/*  className={classNames(*/}
      {/*    "flex items-center gap-1.5 bg-white rounded-lg py-1.5 px-2 cursor-pointer transition bg-white bg-opacity-20",*/}
      {/*    isInfoOnly && "opacity-20 pointer-events-none"*/}
      {/*  )}*/}
      {/*>*/}
      {/*  <MdOutlineLeaderboard className="text-xl shrink-0" />*/}
      {/*  <span className="mr-auto text-sm font-bold leading-none">{t("liveSession.showLeaderboard")}</span>*/}
      {/*  <InputToggle*/}
      {/*    value={isLeaderboard.get}*/}
      {/*    onChange={() => isLeaderboard.set(!isLeaderboard.get)}*/}
      {/*  />*/}
      {/*</label>*/}
      <label className="flex items-center gap-1.5 bg-gray-100 rounded-lg py-1.5 px-2 cursor-pointer transition">
        <TbClock
          className={classNames(
            "text-xl shrink-0 transition",
            isDuration.get && "text-primary"
          )}
        />
        <span className="mr-auto text-sm font-bold leading-none">
          {t("liveSession.trackTime")}
        </span>
        <InputToggle
          value={isDuration.get}
          onChange={() => isDuration.set(!isDuration.get)}
        />
      </label>
    </div>
  );
};
