import { apiUrlV1 } from "enums/constant";
import axios from "axios";
import {
  Page,
  PageItemType,
  SlidePage,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { uuid } from "app/components/Exercises/utils/uuid";
import { format } from "date-fns";
import { getDateLocale } from "app/hooks/useDateFormat";

export async function getTrainings({
  page = 0,
  received,
}: {
  page?: number;
  received?: boolean;
}) {
  const url =
    `${apiUrlV1}/courses/combined/` +
    `?limit=${15}` +
    `&offset=${page * 15}` +
    (received != null ? `&received=${received ? "true" : "false"}` : "");
  const { data } = await axios.get(url);
  return data;
}

export async function getUserCourses({
  sortParam = "-recent_date",
  page = 0,
  search = "",
}: any) {
  const url =
    `${apiUrlV1}/courses/` +
    `?limit=${15}` +
    `&offset=${page * 15}` +
    (search ? `&search=${search}` : "") +
    `&order_by=${sortParam}`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function getReceivedCourses({
  sortParam = "-recent_date",
  page = 0,
  search = "",
}: any) {
  const url =
    `${apiUrlV1}/courses/received/` +
    `?limit=${15}` +
    `&offset=${page * 15}` +
    (search ? `&search=${search}` : "") +
    `&order_by=${sortParam}`;
  const { data } = await axios.get<any>(url);
  return data;
}

export const getCourseName = (t, language: string, date = new Date()) =>
  t("v4.training.new") +
  " - " +
  format(date, "PP", {
    locale: getDateLocale(language),
  });

export async function postNewCourse(name) {
  const url = `${apiUrlV1}/courses/`;
  const { data } = await axios.post<any>(url, {
    name,
    pages: [],
    type: "course",
  });
  return data;
}

export async function getCourse(
  courseId: string,
  { full = false, results = false } = {}
) {
  const url =
    `${apiUrlV1}/courses/${courseId}/?` +
    (full ? `&full=true` : "") +
    (results ? `&results=true` : "");
  const { data } = await axios.get<any>(url);
  return data;
}

export async function getGuestCourse(
  guestPin: string,
  courseId: string,
  { full = false, results = false } = {}
) {
  const url =
    `${apiUrlV1}/guest/${guestPin}/courses/${courseId}/?` +
    (full ? `&full=true` : "") +
    (results ? `&results=true` : "");
  const { data } = await axios.get<any>(url);
  return data;
}

export async function putEditCourse(
  courseId: string,
  editData: { name?: string; pages?: string[] }
) {
  const url = `${apiUrlV1}/courses/${courseId}/`;
  const { data } = await axios.put<any>(url, editData);
  return data;
}

export async function getCoursePage(courseId: string, pageId: string) {
  const url = `${apiUrlV1}/courses/${courseId}/${pageId}/`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function postCoursePageDocx(pageData: Page) {
  const url = `${apiUrlV1}/docx_export/`;
  const { data } = await axios.post<any>(url, pageData, {
    responseType: "blob",
  });
  return data;
}

export type NewCourseData<T extends Page = Page> = {
  category: T["category"];
  index?: number;
  name?: T extends SlidePage ? never : string;
  version?: number;
  items?: T["items"];
  quizType?: string | null;
};

export async function postNewCoursePage<T extends Page = Page>(
  courseId: string,
  { category, items, name, version, quizType, index }: NewCourseData<T>
) {
  const url = `${apiUrlV1}/courses/${courseId}/`;
  const newCourseData: NewCourseData<T> = {
    name,
    category,
    version: version ?? 8,
    ...(index != null && { insert_index: index }),
    ...(quizType && { quiz_type: quizType }),
    items:
      items ??
      (category === "slide"
        ? []
        : [
            {
              id: uuid(),
              category: "content",
              type: PageItemType.Paragraph,
              align: "left",
              data: [[{ text: "" }]],
            },
          ]),
  };
  const { data } = await axios.post<any>(url, newCourseData);
  return data;
}

export async function putEditCoursePage(
  courseId: string,
  pageId: string,
  editData: {
    name?: string;
    items?: Page["items"];
    version?: number;
    category?: string;
    quiz_type?: string;
  }
) {
  const url = `${apiUrlV1}/courses/${courseId}/${pageId}/`;
  const { data } = await axios.put<any>(url, editData);
  return data;
}

export async function deleteCoursePage(courseId: string, pageId: string) {
  const url = `${apiUrlV1}/courses/${courseId}/${pageId}/`;
  const { data } = await axios.delete<any>(url);
  return data;
}

export async function getCoursePageVersions({ pageId = "", page = 1 }) {
  const url =
    `${apiUrlV1}/courses/page/versions/${pageId}/` +
    `?limit=${15}` +
    `&offset=${page * 15}`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function getCoursePageVersion(id) {
  const url = `${apiUrlV1}/courses/page/version/${id}/`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function postRevertPageVersion(id) {
  const url = `${apiUrlV1}/courses/page/revert/${id}/`;
  const { data } = await axios.post<any>(url);
  return data;
}

export async function postExportCourse(
  id,
  scope?: string[],
  language?: string,
  answers?: boolean
) {
  const url = `${apiUrlV1}/courses/export/${id}/`;
  const { data } = await axios.post<any>(
    url,
    { scope, language, answers },
    { responseType: "blob" }
  );
  return data;
}

export interface SlideOutline {
  title: string;
  topic: string;
  slides: { title: string }[];
}

export async function postGenerateOutline(
  prompt,
  { content_volume, exercise_volume }
) {
  const url = `${apiUrlV1}/generate_slides/`;
  const { data } = await axios.post<SlideOutline>(url, {
    focus: prompt,
    training_type: "slides_outline",
    content_volume,
    exercise_volume,
  });
  return data;
}

export async function postUpdateOutline(prompt: string, outline: SlideOutline) {
  const url = `${apiUrlV1}/update_slides_outline/`;
  const { data } = await axios.post<{ slides_outline: SlideOutline }>(url, {
    instructions: prompt,
    slides_outline: outline,
  });
  return data.slides_outline;
}

export async function postGenerateFromOutline(
  outline: SlideOutline,
  { content_volume, exercise_volume }
) {
  const url = `${apiUrlV1}/generate_slides/`;
  const { data } = await axios.post<{ slides_outline: SlideOutline }>(url, {
    training_type: "slides_outline",
    content_volume,
    exercise_volume,
    slides_outline: outline,
  });
  return data;
}
