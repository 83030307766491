import {
  PageItemType,
  ParagraphNext,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { postNext } from "api/course/courseContentAPI";
import { textToParagraphs } from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { PageGenerate } from "app/components/Exercises/CourseEdit/components/PageGenerate";

export const PageParagraphNext = () => (
  <PageGenerate
    promise={(item: ParagraphNext) => postNext(item.data)}
    gen={({ text_predicted }, { id }) => ({
      id,
      type: PageItemType.Paragraph,
      category: "content",
      align: "left",
      data: textToParagraphs(text_predicted),
    })}
  />
);
