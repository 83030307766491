export const apiUrlV1 = process.env.REACT_APP_API_URL;
export const gitBuildSHA = process.env.REACT_APP_BUILD_SHA;
export const wsUrl = process.env.REACT_APP_WS_URL;

export const reportError = process.env.REACT_APP_REPORT_ERROR;
export const clientIdGoogleAuth =
  process.env.REACT_APP_GOOGLE_OAUTH_PASS_CLIENT_SECRET;
export const AccessToken = "AccessToken";
export const Requires2FA = "Requires2FA";
export const SessionToken = "sessionid";
export const SessionMessagesToken = "messages";

export const MaxLengthInput90 = 90;
export const MaxLengthInput100 = 100;
