import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalModal } from "../../../components/Exercises/Edit/ExerciseModal";
import { useMutation, useQueryClient } from "react-query";
import { NewButton } from "../../../components/Buttons/NewButton";
import { InputText } from "../../../components/Buttons/InputText";
import { TbUsers } from "react-icons/tb";
import { CgSpinner } from "react-icons/cg";
import { ColorPicker } from "app/pages/groups/components/ColorPicker";
import { toast } from "react-hot-toast";
import { postCreateGroup } from "api/groups/groupsAPI";
import { useHistory } from "react-router-dom";
import { groupRoutes } from "enums/routes";
import { translateError } from "app/components/Exercises/Edit/missingTranslation";

export const NewGroup = ({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();

  const create = useMutation(
    ({ name, color }: { name: string; color: string }) =>
      postCreateGroup({ name, color }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("groups");
        toast.success(t("v4.group.createSuccess"));
        close();

        history.push({
          pathname: groupRoutes.members(data.group_id),
          state: {
            invite: true,
          },
        });
      },
      onError: () => {
        toast.error(translateError(t("v4.group.createError"), t));
      },
    }
  );

  if (!open && !create.isLoading) return null;

  return (
    <GlobalModal close={close}>
      <h2 className="text-2xl text-center mb-8 mx-auto">
        {t("v4.group.create")}
      </h2>
      <GroupForm query={create} />
    </GlobalModal>
  );
};

export const GroupForm = ({
  query,
  defaultValue,
}: {
  query: any;
  defaultValue?: {
    name: string;
    color: string;
  };
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState(defaultValue?.name ?? "");
  const [color, setColor] = useState(defaultValue?.color ?? null);
  const handleChange = (value: string) => setName(value.substring(0, 50));

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) return;
    query.mutate({ name, color });
  };

  return (
    <form className="flex flex-col items-center" onSubmit={handleSubmit}>
      <InputText
        value={name}
        maxLength={50}
        onChange={handleChange}
        className="w-full"
        icon={TbUsers}
        placeholder={t("v4.group.name")}
      />
      <span className="text-lg font-bold mt-4 mb-1">
        {t("groupsPage.labels.selectColor")}
      </span>

      <div className="grid grid-cols-5 gap-2 flex-wrap justify-center">
        <ColorPicker setSelectedColor={setColor} selectedColor={color} />
      </div>

      <NewButton
        variant="primary"
        className="mt-4"
        size="lg"
        disabled={!name.length || query.isLoading}
        onClick={handleSubmit}
        component="button"
        type="submit"
      >
        {query.isLoading && <CgSpinner className="animate-spin" />}
        {t("v4.group.create")}
      </NewButton>
    </form>
  );
};
