import { useTranslation } from "react-i18next";
import { useLibrary } from "app/components/Sources/MediaPicker/SourceList/useLibrary";
import {
  ACCEPTED_FILE_TYPES,
  FileType,
  mapMime,
  mapSourceToType,
} from "helpers/mimeType";
import React, { Fragment, useCallback, useMemo } from "react";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import { FILE_SIZE_LIMIT } from "helpers/file";
import { toast } from "react-hot-toast";
import {
  TbBook,
  TbBooks,
  TbChevronDown,
  TbChevronRight,
  TbDeviceMobile,
  TbDots,
  TbFile,
  TbFileTypography,
  TbFilter,
  TbFolder,
  TbFolders,
  TbPhoto,
  TbPlus,
  TbSearch,
  TbUpload,
  TbVideo,
  TbVolume,
  TbWorldUpload,
  TbX,
} from "react-icons/tb";
import { NewButton } from "app/components/Buttons/NewButton";
import { FloatingMenu } from "app/components/Header";
import { InView } from "react-intersection-observer";
import { InputCheckboxControlled } from "app/components/Buttons/InputCheckbox";
import {
  MediaPickerView,
  SelectOnlyType,
} from "app/components/Sources/MediaPicker/MediaPicker";
import { useRole } from "app/hooks/useRole";
import { useSelectOnly } from "app/components/Sources/MediaPicker/context/selectOnlyContext";

const FilterSelectButton = ({ children, selected, onClick }) => (
  <NewButton
    onClick={onClick}
    variant="transparent"
    className="[&>svg]:!text-2xl [&>svg]:!mr-3"
  >
    {children}
    <div className="pl-12 ml-auto [&>div>label]:!min-w-0 [&>div]:!min-w-[1.5rem]">
      <InputCheckboxControlled
        value={!!selected}
        middleValue={selected == null}
      />
    </div>
  </NewButton>
);

export const FilterSelectMenu = ({ bindFilter, sourcesOnly = false }) => {
  const { t } = useTranslation();
  const sources = (
    <>
      <FilterSelectButton {...bindFilter("document")}>
        <TbFileTypography /> {t("media.document.plural")}
      </FilterSelectButton>
      <FilterSelectButton {...bindFilter("image")}>
        <TbPhoto /> {t("media.image.plural")}
      </FilterSelectButton>
      <FilterSelectButton {...bindFilter("video")}>
        <TbVideo /> {t("media.video.plural")}
      </FilterSelectButton>
      <FilterSelectButton {...bindFilter("audio")}>
        <TbVolume /> {t("media.audio.name")}
      </FilterSelectButton>
    </>
  );
  if (sourcesOnly) return sources;
  return (
    <>
      <FilterSelectButton {...bindFilter("course")}>
        <TbBook /> {t("v4.training.plural")}
      </FilterSelectButton>
      <FilterSelectButton {...bindFilter("source")}>
        <TbBooks /> {t("media.source.plural")}
      </FilterSelectButton>
      <div className="border-l-2 border-gray-200 pl-1 ml-1 space-y-1">
        {sources}
      </div>
    </>
  );
};

const Skeleton = ({ size = 30 }) =>
  useMemo(
    () => (
      <>
        {[...new Array(size)].map((_, i) => (
          <div
            key={i}
            className="flex flex-col p-2 animate-pulse"
            style={{ animationDelay: i * 0.2 + "s" }}
          >
            <div className="w-full h-24 rounded-lg bg-gray-200" />
            <div className="h-6 bg-gray-100 w-full mt-2 rounded-full" />
          </div>
        ))}
      </>
    ),
    [size]
  );

export const SourceList = ({
  close,
  openSource,
  initialFolderId,
  onFile,
  initialSearch = "",
  initialFilters,
  setView,
}) => {
  const selectOnly = useSelectOnly();
  const { t } = useTranslation();
  const role = useRole();
  const forceFilter =
    selectOnly &&
    [
      SelectOnlyType.image,
      SelectOnlyType.documentRange,
      SelectOnlyType.columns,
      SelectOnlyType.oneColumn,
      SelectOnlyType.twoColumns,
    ].includes(selectOnly);

  const { bindFilter, list, query, folderId, setFolderId, input, setInput } =
    useLibrary({
      initialFolderId,
      initialSearch,
      initialFilters: forceFilter
        ? { image: true, document: true }
        : initialFilters,
    });

  const onItemClick = (type: FileType, item: any) => () => {
    if (type === FileType.Course) return;
    if (type === FileType.Folder) return setFolderId(item.id);
    openSource(item);
  };

  const NavigationItem = useCallback(
    ({ name, id }) => (
      <div
        onClick={() => setFolderId(id)}
        className={classNames(
          "font-bold",
          id === folderId
            ? "text-gray-500"
            : "text-gray-600 transition hover:text-primary cursor-pointer"
        )}
      >
        {name}
      </div>
    ),
    [folderId]
  );

  const navigationStack = query.data?.pages?.[0]?.header?.navigation_stack;

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: ACCEPTED_FILE_TYPES,
    noKeyboard: true,
    noClick: true,
    multiple: false,
    noDragEventsBubbling: true,
    onDrop: async (acceptedFiles: any) => {
      const file = acceptedFiles?.[0] as File;
      if (!file) return;

      if (file.size > FILE_SIZE_LIMIT) {
        toast.error(t("sourcesPage.status.uploading.errorSize"));
        return;
      }

      const mimeType = !!file?.type && mapMime(file.type);
      if (
        mimeType === false ||
        ![
          FileType.Document,
          FileType.Image,
          FileType.Audio,
          FileType.Video,
        ].includes(mimeType)
      ) {
        toast.error(t("sourcesPage.uploadNew.uploadTypeError"));
        return;
      }
      onFile(file);
    },
  });

  return (
    <>
      <div className="py-1 border-b-2 border-gray-100 flex flex-col text-gray-500">
        <div className="px-1 flex items-center gap-1">
          <TbFolders className="text-xl ml-2 mr-1" />
          <div className="flex gap-1 items-center">
            <NavigationItem id="" name={t("v4.library.text")} />
            {navigationStack?.length > 3 && (
              <>
                <TbChevronRight className="text-gray-400 text-lg" />
                <TbDots className="text-gray-500 text-lg" />
              </>
            )}
            {navigationStack?.slice(-3).map(({ id, name }) => (
              <Fragment key={id}>
                <TbChevronRight className="text-gray-400 text-lg" />
                <NavigationItem {...{ id, name }} />
              </Fragment>
            ))}
          </div>
          <div className="grow" />

          <NewButton iconOnly onClick={close} variant="transparent">
            <TbX />
          </NewButton>
        </div>
      </div>
      <div
        className="grow relative overflow-y-auto"
        {...(query.isSuccess && getRootProps())}
        role={undefined}
      >
        <div className="absolute-cover">
          <div className="flex items-center gap-1 px-4 mt-2">
            {/*<label*/}
            {/*  className={classNames(*/}
            {/*    "flex max-w-[10rem] group bg-gray-100 focus-within:bg-gray-200 grow transition rounded-lg items-center text-gray-500"*/}
            {/*  )}*/}
            {/*>*/}
            {/*  <TbSearch className="text-xl ml-2 shrink-0" />*/}
            {/*  <input*/}
            {/*    className="py-1 min-w-0 grow bg-transparent outline-none px-2"*/}
            {/*    value={input}*/}
            {/*    onChange={(e) => setInput(e.target.value)}*/}
            {/*    placeholder={mt("Search...")}*/}
            {/*  />*/}
            {/*</label>*/}

            <label
              className={classNames(
                !input &&
                  "[&:not(:focus-within)]:cursor-pointer [&:not(:focus-within)]:hover:bg-gray-200",
                "flex bg-gray-100 group focus-within:bg-gray-200 group transition rounded-lg items-center text-gray-600"
              )}
            >
              <TbSearch className="text-xl ml-2" />
              <input
                className={classNames(
                  input || forceFilter
                    ? "max-w-[7rem] sm:max-w-[15rem] px-2"
                    : "max-w-0 group-focus-within:max-w-[7rem] sm:group-focus-within:max-w-[15rem] px-1 group-focus-within:px-2",
                  "transition-all py-1.5 min-w-0 bg-transparent outline-none"
                )}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={t("v4.generic.search")}
              />
            </label>

            {!forceFilter && (
              <FloatingMenu
                trigger={(toggle) => (
                  <NewButton onClick={toggle}>
                    <TbFilter /> {t("v4.generic.filter")}
                    <TbChevronDown className="!text-xs" />
                  </NewButton>
                )}
                placement="bottom-start"
                size="sm"
                className="space-y-1"
              >
                <FilterSelectMenu bindFilter={bindFilter} sourcesOnly />
              </FloatingMenu>
            )}

            <div className="grow" />
            <FloatingMenu
              size="xs"
              placement="bottom-end"
              trigger={(toggle) => (
                <NewButton variant="primary" size="lg" onClick={toggle}>
                  <TbPlus /> {t("v4.generic.newEllipsis")}
                </NewButton>
              )}
            >
              <NewButton variant="transparent" onClick={open}>
                <TbUpload /> {t("v4.library.uploadSource")}
              </NewButton>
              <NewButton
                variant="transparent"
                onClick={() => setView(MediaPickerView.Mobile)}
              >
                <TbDeviceMobile /> {t("v4.library.mobileUpload")}
              </NewButton>
              {!role.prodMode && (
                <NewButton
                  variant="transparent"
                  onClick={() => setView(MediaPickerView.Website)}
                >
                  <TbWorldUpload />
                  {t("v4.library.websiteUpload.text")}
                </NewButton>
              )}
              <NewButton
                variant="transparent"
                onClick={() => setView(MediaPickerView.Record)}
              >
                <TbVideo /> {t("v4.library.record")}
              </NewButton>
            </FloatingMenu>
            {/*<NewButton color="text-primary bg-primary">*/}
            {/*  <TbVideoPlus /> {mt("Record")}*/}
            {/*</NewButton>*/}
            {/*<NewButton iconOnly>*/}
            {/*  <TbFolderPlus />*/}
            {/*</NewButton>*/}
          </div>
          {!query.isSuccess ? (
            <div
              className="p-4 content-start flex-1 grid gap-3 w-full h-full"
              style={{
                gridTemplateColumns: "repeat(auto-fill, minmax(10rem, 12fr))",
              }}
            >
              <Skeleton />
            </div>
          ) : (
            <>
              {/* upload by dragging over */}
              <input {...getInputProps()} />
              <div
                className={classNames(
                  "absolute-cover p-4 z-20 pointer-events-none bg-white bg-opacity-50 filter transition backdrop-blur opacity-0",
                  isDragActive && "opacity-100"
                )}
              >
                <div className="w-full h-full border-4 rounded-lg border-dashed border-gray-300 flex flex-col gap-4 text-center items-center justify-center text-gray-500">
                  <TbUpload className="text-5xl" strokeWidth={3} />
                  <div className="text-xl font-bold">
                    {t("v4.library.uploadDrop")}
                  </div>
                </div>
              </div>

              {!list.length ? (
                <div className="px-8 py-16 m-auto text-gray-400 text-lg text-center">
                  {t("v4.library.noItems")}
                </div>
              ) : (
                <>
                  <div
                    className="p-4 content-start flex-1 grid gap-3"
                    style={{
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(10rem, 12fr))",
                    }}
                  >
                    {list.map((item) => {
                      const { id, name, thumbnail_url } = item;
                      const type = mapSourceToType(item);
                      return (
                        <div
                          key={id}
                          onClick={onItemClick(type, item)}
                          className="transition hover:bg-gray-100 rounded-xl cursor-pointer flex flex-col p-2"
                        >
                          <div className="mx-auto p-2 text-gray-500">
                            {type === FileType.Folder ? (
                              <TbFolder
                                className="text-7xl"
                                strokeWidth={1.5}
                              />
                            ) : type === FileType.Course ? (
                              <TbBook className="text-7xl" strokeWidth={1.5} />
                            ) : type === FileType.Audio ? (
                              <TbVolume
                                className="text-7xl"
                                strokeWidth={1.5}
                              />
                            ) : type === FileType.Image ||
                              type === FileType.Video ||
                              type === FileType.Document ? (
                              thumbnail_url && (
                                <img
                                  src={thumbnail_url}
                                  className="w-full h-16 object-contain object-center drop-shadow-lg filter"
                                />
                              )
                            ) : (
                              <TbFile className="text-7xl" />
                            )}
                          </div>
                          <div className="break-all text-gray-600 bg-gray-100 py-0.5 px-1 leading-tight w-full rounded-lg font-bold text-center text-sm line-clamp-2 text-ellipsis overflow-hidden my-auto">
                            {type === FileType.Document ? (
                              <TbFile className="inline shrink-0 mb-0.5 text-lg mr-0.5" />
                            ) : type === FileType.Image ? (
                              <TbPhoto className="inline shrink-0 mb-0.5 text-lg mr-0.5" />
                            ) : type === FileType.Video ? (
                              <TbVideo className="inline shrink-0 mb-0.5 text-lg mr-0.5" />
                            ) : type === FileType.Audio ? (
                              <TbVolume className="inline shrink-0 mb-0.5 text-lg mr-0.5" />
                            ) : null}
                            {name}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {query.hasNextPage && (
                    <InView
                      as="div"
                      className="flex"
                      onChange={(inView) =>
                        inView &&
                        !query.isFetchingNextPage &&
                        query.fetchNextPage()
                      }
                      key={query.data.pages.length}
                    >
                      {({ inView, ref }) => (
                        <div
                          ref={ref}
                          className={classNames(
                            "grid gap-3 w-full px-4 pb-4 -mt-4 transition",
                            !inView && "opacity-0"
                          )}
                          style={{
                            gridTemplateColumns:
                              "repeat(auto-fill, minmax(10rem, 12fr))",
                          }}
                        >
                          <Skeleton />
                        </div>
                      )}
                    </InView>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
