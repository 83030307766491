import {
  PageItemType,
  ParagraphTranslate,
  RichParagraph,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { postTranslate } from "api/course/courseContentAPI";
import { paragraphsToText } from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { PageGenerate } from "app/components/Exercises/CourseEdit/components/PageGenerate";

const textToRichParagraphs = (text: string, previous: RichParagraph[]) =>
  text.split(/\r?\n/).map((text, i) => {
    const prev = previous?.[i];
    if (prev && prev.length && "text" in prev[0]) {
      const { text: t, ...format } = prev[0];
      return [{ text, ...format }];
    }
    return [{ text }];
  });

export const PageParagraphTranslate = () => (
  <PageGenerate
    promise={(item: ParagraphTranslate) =>
      postTranslate(paragraphsToText(item.data), item.language)
    }
    gen={({ translation }, { id, list, data }) => {
      return {
        id,
        type: list ? PageItemType.List : PageItemType.Paragraph,
        category: "content",
        align: "left",
        data: textToRichParagraphs(translation, data),
        ...(list && { variant: list }),
      } as any;
    }}
  />
);
