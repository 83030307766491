import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router";
import { Action, Location } from "history";
import {
  ModalBackground,
  ModalClose,
  ModalContainer,
  StyledModal,
} from "app/components/Exercises/Edit/ExerciseModal";
import { PrimaryButton } from "app/components/Buttons/GenericButtons";
import { useHistory, useParams } from "react-router-dom";
import Loader from "app/components/Loader";
import { usePageContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import objectHash from "object-hash";
import { CgSpinner } from "react-icons/cg";
import { putEditCoursePage } from "api/course/courseAPI";
import { useQueryClient } from "react-query";

export enum SaveState {
  HasUnsaved,
  Saving,
  Saved,
}

export const useDebounce = (
  action: (...params: any[]) => void,
  delay = 1000 * 5
) => {
  const debounceRef = useRef<ReturnType<typeof setTimeout>>();

  const clear = useCallback(() => {
    if (debounceRef.current) clearTimeout(debounceRef.current);
  }, []);

  const set = useCallback(
    (...params: any[]) => {
      clear();
      debounceRef.current = setTimeout(() => action(...params), delay);
    },
    [action, delay]
  );

  return { set, clear };
};

/**
 * The hook controlling course autosaving feature
 * After state changes, a debounce timer is started
 * After 5 seconds, if there is a difference between the hashes of states, the save request is sent
 * @param delay
 */
export const useCourseAutosave = (delay = 1000 * 2) => {
  const { courseId, pageId } =
    useParams<{ courseId: string; pageId: string }>();
  const currentData = usePageContext((state) => state.data);

  const hash = usePageContext((state) => state.hash);
  const updateHash = usePageContext((state) => state.updateHash);

  const [status, setStatus] = useState<SaveState>(SaveState.Saved);
  const queryClient = useQueryClient();

  const save = () => {
    if (!currentData) return;
    const current = currentData;
    const currentHash = objectHash(current);
    if (currentHash === hash) {
      setStatus(SaveState.Saved);
      return;
    }
    if (status === SaveState.Saving) return;

    setStatus(SaveState.Saving);
    putEditCoursePage(courseId, pageId, {
      name: current.category === "slide" ? undefined : current.name,
      items: current.items,
      version: current.version,
      category: current.category,
      quiz_type: current?.quizType,
    })
      .then(() => {
        updateHash(current);
        queryClient.invalidateQueries(["course", courseId]);
        setStatus(SaveState.Saved);
      })
      .catch(() => {
        setStatus(SaveState.HasUnsaved);
      });
  };

  const debounce = useDebounce(save, delay);

  const [loadedId, setLoadedId] = useState<string | null>(null);
  useEffect(() => {
    if (!currentData?.id) return;

    // prevent autosave on first load for Id
    if (loadedId !== currentData.id) {
      setLoadedId(currentData.id);
      return;
    }
    if (status === SaveState.Saving) return;

    setStatus(SaveState.HasUnsaved);
    debounce.set();
  }, [currentData]);

  const handleImmediateSave = () => {
    debounce.clear();
    save();
  };

  return { saveState: status, handleImmediateSave };
};

export const SavingIndicator = () => {
  const { t } = useTranslation();
  const { saveState, handleImmediateSave } = useCourseAutosave();

  return (
    <div className="ml-auto mr-2 text-right text-xs text-gray-500 leading-tight">
      <NavigationBlock {...{ saveState, handleImmediateSave }} />
      {saveState === SaveState.HasUnsaved ? (
        <span
          className="hover:underline cursor-pointer"
          onClick={handleImmediateSave}
        >
          {t("common.saving.unsaved")}
        </span>
      ) : saveState === SaveState.Saving ? (
        <span className="flex items-center">
          {t("common.saving.inProgress")}
          <CgSpinner className="animate-spin ml-1" />
        </span>
      ) : (
        t("common.saving.saved")
      )}
    </div>
  );
};

/**
 * Component handling the action of navigating outside of the course, while it's being saved/has unsaved changes
 * It will trigger the save by default, the user can also leave immediately
 * @param saveState
 * @param handleImmediateSave
 * @constructor
 */
const NavigationBlock = ({
  saveState,
  handleImmediateSave,
}: {
  saveState: SaveState;
  handleImmediateSave: () => void;
}) => {
  const [isLeaving, setIsLeaving] = useState(false);
  const [action, setAction] = useState<(() => void) | null>(null);
  const [confirmed, setConfirmed] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const when = [SaveState.HasUnsaved, SaveState.Saving].includes(saveState);

  const handleBlockedNavigation = (
    location: Location<any>,
    action: Action
  ): boolean => {
    // if (location.state?.isInner) return true;
    if (confirmed) return true;

    setIsLeaving(true);
    setAction(() =>
      action === "REPLACE"
        ? () => history.replace(location)
        : () => history.push(location)
    );
    if (saveState === SaveState.HasUnsaved) handleImmediateSave();
    return false;
  };

  useEffect(() => {
    if (!action) return;
    if (!isLeaving) return;

    if (!when || confirmed) {
      setIsLeaving(false);
      action();
    }
  }, [when, confirmed, action]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {isLeaving && (
        <ModalContainer style={{ position: "fixed", zIndex: 11 }}>
          <ModalBackground onClick={() => setIsLeaving(false)} />
          <SavingModal>
            <ModalClose onClick={() => setIsLeaving(false)} />
            <Loader />
            <h1>{t("warningPopup.savingExercise.text")}</h1>
            <PrimaryButton
              style={{
                backgroundColor: "var(--light-grey)",
                color: "var(--dark-grey)",
                marginTop: "0.5em",
              }}
              onClick={() => setConfirmed(true)}
            >
              {t("warningPopup.savingExercise.exit")}
            </PrimaryButton>
          </SavingModal>
        </ModalContainer>
      )}
    </>
  );
};

const SavingModal = styled(StyledModal)`
  max-width: 30rem;
  text-align: center;
  h1 {
    font-size: 1.2rem;
    line-height: 1.5em;
    white-space: pre;
    margin: 1rem 0 1.5rem;
  }
`;
