import React, { useEffect, useRef } from "react";
import { NewLoader } from "../../Loader";
import { useSessionContext } from "app/hooks/useSessionContext";
import { useTranslation } from "react-i18next";
import { TbArrowRight, TbEyeOff } from "react-icons/tb";
import { CgSpinner } from "react-icons/cg";
import { getSessionStatus } from "app/components/Exercises/utils/sessionStatus";
import { TeacherExerciseBar } from "app/components/Exercises/LiveSession/TeacherExerciseBar";
import { CoursePageRender } from "app/components/Exercises/CourseEdit/render/PageRender";
import { SlideRender } from "app/components/Exercises/CourseEdit/slide/SlideRender";
import { useConsumptionContext } from "app/components/Exercises/CourseEdit/render/ConsumptionContext";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import { NewButton } from "app/components/Buttons/NewButton";

const SessionConsumption = ({ submitted = false }) => {
  const { t } = useTranslation();
  const { session, isTeacher } = useSessionContext();
  const timer = useRef<any>(null);

  const { results_submitted, results_total, exercise_content } =
    session.get?.status ?? {};
  const { slide } = getSessionStatus(session.get?.status?.status);

  // force close after two seconds
  useEffect(() => {
    if (session.get.status?.status !== "exercise_closing") return;
    if (!isTeacher.get) return;

    timer.current = setTimeout(() => session.get.forceCloseExercise(), 2000);
    return () => clearTimeout(timer.current);
  }, [session.get.status?.status]);

  // force close after all submissions
  useEffect(() => {
    if (session.get.status?.status !== "exercise_closing") return;
    if (!isTeacher.get) return;

    if (results_total === results_submitted) {
      session.get.forceCloseExercise();
    }
  }, [session.get.status?.status, results_submitted, results_total]);

  if (!exercise_content)
    return (
      <div className="bg-gray-100 rounded-lg flex flex-col flex-1">
        <NewLoader className="text-4xl m-auto" />
      </div>
    );

  if (submitted)
    return (
      <div className="m-auto text-center font-bold">
        {/*<div className="text-2xl text-gray-100">*/}
        {/*  {t("liveSession.submitted")}*/}
        {/*</div>*/}
        <CgSpinner className="animate-spin m-auto text-3xl" />
        <div className="text-xl text-gray-500 mt-2 mb-4">
          {t("liveSession.waiting")}
        </div>
      </div>
    );

  return (
    <>
      <div className="bg-white rounded-lg flex flex-col flex-1 relative shadow-xl">
        {/*{isTeacher.get && !slide && (*/}
        {/*  <div className="bg-gray-700 shadow-xl rounded-xl p-2 top-2 right-2"></div>*/}
        {/*)}*/}
        {isTeacher.get && !slide && <TeacherExerciseBar />}
        {!isTeacher.get && session.get.status?.properties?.hide_exercise ? (
          <TbEyeOff className="text-5xl m-auto text-gray-500" />
        ) : exercise_content.category === "slide" ? (
          <SlideRender page={exercise_content} />
        ) : (
          <>
            <CoursePageRender items={exercise_content?.items ?? []} />
            {!isTeacher.get && (
              <div className="max-w-2xl w-full mx-auto flex px-4 sm:px-1 md:px-0">
                <StudentButtons />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
const StudentButtons = () => {
  const { t } = useTranslation();
  const { session, isTeacher } = useSessionContext();
  const { status, exercise_content, exercise_submitted } =
    session.get?.status ?? {};
  const { exerciseClosing, slide } = getSessionStatus(status);
  const consumptionState = useConsumptionContext((state) => state.items);

  const submitMutation = useMutation(
    async () => {
      if (submitMutation.isLoading) return;
      if (!consumptionState) return;

      session.get.submitSession({ items: consumptionState });
      return;
    },
    {
      onError: () => {
        toast.error(t("common.exercises.error.generic"));
      },
    }
  );

  useEffect(() => {
    if (exerciseClosing && !isTeacher.get) {
      submitMutation.mutate();
    }
  }, [session.get.status?.status]);

  if (!exercise_content) return null;

  if (slide || exercise_submitted) return null;

  return (
    <NewButton
      variant="primary"
      onClick={submitMutation.mutate}
      disabled={submitMutation.isLoading}
      className="ml-auto mt-auto mr-1 mb-1 text-lg"
      size="lg"
    >
      {t("groupsPage.buttons.send")}
      {submitMutation.isLoading ? (
        <CgSpinner className="animate-spin" />
      ) : (
        <TbArrowRight />
      )}
    </NewButton>
  );
};

export default SessionConsumption;
