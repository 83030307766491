import { createStore, useStore } from "zustand";
import React, { PropsWithChildren, useMemo, useRef } from "react";
import { Word } from "app/components/Exercises/Edit/context/SourceContext";
import { Position } from "app/components/Exercises/Edit/sourcePanel/selectionType/TextSelection";
import { immer } from "zustand/middleware/immer";
import {
  CreateProtectedContext,
  useProtectedContext,
} from "app/hooks/useProtectedContext";
import produce from "immer";

export interface SelectionCrop {
  w: number;
  h: number;
  x: number;
  y: number;
  page: number;
}

export enum Selecting {
  None,
  Text,
  Image,
}

interface SourceSelectionProps {
  crop: SelectionCrop | null;
  pages: PageData[];
  start: [number, number] | null;
  end: [number, number] | null;
  selecting: Selecting;
}

type PageData = {
  words: WordWithPos[];
  width: number;
  height: number;
  src: string;
} | null;

interface SourceSelectionState extends SourceSelectionProps {
  setPage: (page: number, data: Partial<PageData>) => void;
  setStart: (pos: [number, number] | null) => void;
  setEnd: (pos: [number, number] | null) => void;
  setSelecting: (value: Selecting) => void;
  setCrop: (crop: Partial<SelectionCrop> | null) => void;
}

type SourceSelectionStore = ReturnType<typeof createSourceSelectionStore>;
const createSourceSelectionStore = (pages: number, initialPage?: PageData) =>
  createStore<SourceSelectionState>()(
    immer((set) => ({
      crop: null,
      pages: produce(
        [...new Array(pages)].map(() => null as PageData | null),
        (data) => {
          if (initialPage) data[0] = initialPage;
        }
      ),
      start: null,
      end: null,
      selecting: Selecting.None,

      setPage: (page: number, data: Partial<PageData>) => {
        set((state) => {
          state.pages[page] = { ...state.pages[page], ...data } as PageData;
        });
      },
      setStart: (pos: [number, number] | null) =>
        set((store) => {
          store.start = pos;
        }),
      setEnd: (pos: [number, number] | null) =>
        set((store) => {
          store.end = pos;
        }),
      setSelecting: (value) =>
        set((state) => {
          state.selecting = value;
        }),
      setCrop: (crop) =>
        set((state) => {
          if (!crop) {
            state.crop = null;
            return;
          }
          // @ts-ignore
          state.crop = { ...state.crop, ...crop };
        }),
    }))
  );

const SourceSelectionStoreContext =
  CreateProtectedContext<SourceSelectionStore>();

const useSourceSelectionStore = () =>
  useProtectedContext(SourceSelectionStoreContext);

export const SourceSelectionContextProvider = ({
  children,
  pages = 1,
  initialPage,
}: PropsWithChildren<{ pages?: number; initialPage?: PageData }>) => {
  const storeRef = useRef<SourceSelectionStore>();
  if (!storeRef.current) {
    storeRef.current = createSourceSelectionStore(pages, initialPage);
  }
  const store = storeRef.current;

  return (
    <SourceSelectionStoreContext.Provider value={store}>
      {children}
    </SourceSelectionStoreContext.Provider>
  );
};

export const useSourceSelection = <T,>(
  selector: (state: SourceSelectionState) => T,
  equalityFn?: (left: T, right: T) => boolean
): T => {
  const storeRef = useSourceSelectionStore();
  return useStore(storeRef, selector, equalityFn);
};

export const useSourceSelectionRange = ():
  | [null, null]
  | [[number, number], [number, number]] => {
  const pages = useSourceSelection((state) => state.pages);
  const start = useSourceSelection((state) => state.start);
  const end = useSourceSelection((state) => state.end);

  return useMemo(() => {
    if (
      start == null ||
      end == null ||
      pages[start[0]] == null ||
      pages[end[0]] == null
    )
      return [null, null];
    let [from, to] =
      end[0] < start[0] || (end[0] === start[0] && end[1] < start[1])
        ? [end, start]
        : [start, end];

    // if selection after very first word
    if (from[0] > 0 && from[1] > 0) {
      const prev = [from[0], from[1] - 1] as [number, number];
      if (pages?.[prev[0]]?.[prev[1]]?.break === 4) from = prev;
    }
    if (
      to[0] < pages.length - 1 &&
      pages[to[0]] &&
      to[1] < pages[to[0]]!.words.length - 1
    ) {
      const next = [from[0], from[1] + 1] as [number, number];
      if (pages?.[next[0]]?.[next[1]]?.break === 4) to = next;
    }

    return [from, to];
  }, [start, end, pages]);
};

export interface WordWithPos extends Word {
  position: Position;
  isLast: boolean;
}
