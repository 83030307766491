import axios from "axios";

const setAuthTokenAxios = (token: string) => {
  if (token !== "") {
    axios.defaults.headers.common.Authorization = `Token ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

export default setAuthTokenAxios;
