import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { sendAppError } from "helpers/appError";
import { GlobalFlexStyles } from "./GlobalFlexStyles";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbAlertTriangle, TbRefresh } from "react-icons/tb";
import { withTranslation } from "react-i18next";
import { mapStackTrace } from "sourcemapped-stacktrace";

type MyProps = {
  value?: any;
  children: ReactNode;
  state: any;
  height?: any;
  errorMessage?: any;
  t: any;
};
type MyState = { hasError: boolean; error: any };

export const sendError = (error: Error) => {
  const err = { message: error.message, stack: error.stack };
  setTimeout(() => {
    const data = {
      error: err,
      type: "CRITICAL_CRASH",
    };
    const beforeUnload = (e: BeforeUnloadEvent) => {
      sendAppError(data);
    };

    window.addEventListener("beforeunload", beforeUnload);

    try {
      mapStackTrace(
        error.stack,
        (mapped) => {
          window.removeEventListener("beforeunload", beforeUnload);
          sendAppError({ ...data, mapped });
        },
        {
          cacheGlobally: true,
        }
      );
    } catch (e) {
      window.removeEventListener("beforeunload", beforeUnload);
      sendAppError(data);
    }
  }, 500);
};

class ErrorBoundary extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error) {
    this.setState(
      {
        error: { message: error.message, stack: error.stack },
      },
      () => sendError(error)
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col m-auto text-center justify-center items-center">
          <GlobalFlexStyles />
          <TbAlertTriangle className="text-5xl mb-1 text-gray-500" />
          <h1 className="text-3xl mb-2 text-gray-700">
            {this.props.t("common.errors.general")}
          </h1>
          <div className="text-xl text-gray-500 mb-8">
            {this.props.t("common.errors.description")}
          </div>
          <NewButton
            variant="primary"
            size="lg"
            className="px-5 py-2.5 text-xl rounded-lg"
            onClick={() => window.location.reload()}
          >
            <TbRefresh className="!text-2xl" />
            {this.props.t("common.refresh")}
          </NewButton>
        </div>
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = (state: any) => ({
  state: [state.router, state.auth],
});

export default connect(mapStateToProps)(withTranslation()(ErrorBoundary));
