export const getSessionStatus = (status: string) => ({
  get initiation() {
    return status === "initiation";
  },
  get consumption() {
    return [
      "slide",
      "exercise_closing",
      "exercise",
      "exercise_started",
    ].includes(status);
  },
  get slide() {
    return status === "slide";
  },
  get exerciseBefore() {
    return status === "exercise";
  },
  get exercise() {
    return status === "exercise_started" || status === "exercise_closing";
  },
  get exerciseClosing() {
    return status === "exercise_closing";
  },
  get results() {
    return status === "results";
  },
  get leaderboard() {
    return status === "leaderboard";
  },
});
