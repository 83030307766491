import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ExercisesLibraryItemType } from "enums/exercises";
import hash from "object-hash";
import { useInfiniteQuery } from "react-query";
import { getPublicLibrary, getUserLibrary } from "api/exerciseLibraryAPI";

export interface LibraryFilter {
  lesson?: boolean;
  course?: boolean;
  document?: boolean;
  image?: boolean;
  video?: boolean;
  audio?: boolean;
}

export const useLibrary = ({
  publicLibrary = false,
  initialFilters = {
    lesson: false,
    course: false,
    document: true,
    image: true,
    video: true,
    audio: true,
  } as LibraryFilter,
  initialSearch = "",
  initialFolderId = "",
} = {}) => {
  const [filters, setFilters] = useState(initialFilters);
  const [folderId, setFolderId] = useState(initialFolderId);
  const [input, setInput] = useState(initialSearch);
  const search = input.trim();
  const filter = useMemo(() => {
    const { lesson, course, ...mime } = filters;
    const enabledMime = Object.entries(mime)
      .filter(([, value]) => !!value)
      .map(([key]) => key);

    const itemTypes = [
      ExercisesLibraryItemType.folder,
      lesson ? ExercisesLibraryItemType.exercise : null,
      course ? ExercisesLibraryItemType.course : null,
      enabledMime.length ? ExercisesLibraryItemType.source : null,
    ]
      .filter((value) => value !== null)
      .join(",");
    const mimeTypes = enabledMime.join(",");

    return [itemTypes, mimeTypes] as [string, string];
  }, [filters]);

  const keys = publicLibrary
    ? { total: "items_total", items: "items" }
    : { total: "results_total", items: "results" };

  const bindFilter = useCallback(
    (key: keyof LibraryFilter | "source") => {
      if (key === "source") {
        const allSelected =
          filters.document && filters.image && filters.video && filters.audio;
        const someSelected =
          filters.document || filters.image || filters.video || filters.audio;

        const onClick = () =>
          setFilters((filters) => {
            if (allSelected) {
              return {
                ...filters,
                document: false,
                image: false,
                video: false,
                audio: false,
              };
            }
            return {
              ...filters,
              document: true,
              image: true,
              video: true,
              audio: true,
            };
          });
        return {
          selected: allSelected ? true : someSelected ? null : false,
          onClick,
        };
      }
      return {
        selected: !!filters?.[key],
        onClick: () =>
          setFilters((state) => ({
            ...state,
            [key]: !state?.[key],
          })),
      };
    },
    [filters, setFilters]
  );

  const isInitialFilter = hash(initialFilters) === hash(filters);

  const query = useInfiniteQuery(
    [publicLibrary ? "publicLibrary" : "library", folderId, search, filter],
    ({ pageParam = 0 }) =>
      publicLibrary
        ? getPublicLibrary({
            pageSize: 30,
            page: pageParam,
            search,
            filter,
          })
        : getUserLibrary({
            folderId,
            pageSize: 30,
            page: pageParam,
            search,
            filter,
          }),
    {
      refetchOnWindowFocus: false,
      enabled: !search && isInitialFilter,
      // staleTime: 5 * 60 * 1000,
      getNextPageParam: (lastPage, pages) =>
        lastPage[keys.total] > pages.length * 30 ? pages.length : undefined,
    }
  );

  useEffect(() => {
    if (query.isSuccess) return;
    query.refetch();
  }, [folderId]);

  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    if (query.isSuccess) return;
    if (!search) return;

    timeoutRef.current = setTimeout(query.refetch, 500);
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [search]);

  useEffect(() => {
    if (query.isSuccess) return;
    if (isInitialFilter) return;

    timeoutRef.current = setTimeout(query.refetch, 1000);
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [filter]);

  const list = useMemo<
    {
      id: string;
      entry_id: string | null;
      name: string;
      thumbnail_url: string | null;
      item_type: 0 | 2 | 3;
      mime_type: string;
      recent_date: string;
      child_count: number | null;
    }[]
  >(
    () =>
      (query.data?.pages || []).reduce(
        (list, group) => [...list, ...group[keys.items]],
        []
      ),
    [query.data?.pages]
  );
  return {
    query,
    list,
    folderId,
    setFolderId,
    input,
    setInput,
    bindFilter,
    filters,
  };
};
