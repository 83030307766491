import React, { useState } from "react";
import { SessionProvider } from "../../../hooks/useSessionContext";
import LiveSession from "./LiveSession";
import { useSession } from "../../../../api/ws/sessionWS";
import { WSStatus } from "../../../../enums/webSocketStatus";
import Loader from "../../Loader";
import GuestScreen from "./GuestScreen";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useRole } from "app/hooks/useRole";
import { LoginForm } from "app/pages/auth/Login";
import { NewButton } from "app/components/Buttons/NewButton";
import { Header } from "app/components/Header";
import { OrSeparator } from "app/pages/auth/Registration";
import { GlobalFlexStyles } from "app/components/Helpers/GlobalFlexStyles";
import { TbArrowRight } from "react-icons/tb";

const SessionRender = () => {
  const { t } = useTranslation();
  const role = useRole();
  const [isGuest, setGuest] = useState(location.search.includes("guest"));
  const session = useSession(role);
  const guestData = localStorage.getItem("gid") || null;
  const [validGuest, setValidGuest] = useState(
    guestData && guestData.length > 0
  );

  if (role.guest && !isGuest && !validGuest) {
    return (
      <>
        <GlobalFlexStyles />
        <Header />
        <div className="flex flex-col items-center p-6 grow justify-center">
          <div className="flex flex-col mb-12">
            <h1 className="text-2xl mx-auto text-center font-bold mb-4">
              {t("authPages.signIn.submitBtn.label")}
            </h1>
            <LoginForm />

            <OrSeparator className="w-full max-w-md mx-auto my-12 text-gray-500" />

            <NewButton
              center
              variant="primary"
              className="font-bold mx-auto text-xl px-8 py-4"
              size="lg"
              onClick={() => setGuest(true)}
            >
              {t("packagesPage.doPackage.joinAsGuest")}
              <TbArrowRight className="!text-2xl" />
            </NewButton>
          </div>
        </div>
      </>
    );
  }

  return (
    <SessionProvider name={session?.name}>
      {session?.state === WSStatus.connected ||
      session?.state === WSStatus.reconnecting ? (
        <LiveSession session={session} />
      ) : !validGuest && role.guest ? (
        <GuestScreen setValid={() => setValidGuest(true)} />
      ) : (
        <div className="absolute-cover !fixed flex items-center justify-center">
          <Loader />
        </div>
      )}
    </SessionProvider>
  );
};

export default SessionRender;

const ScTitle = styled.h2`
  text-align: center;
`;

const ScJoinExercisePackages = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ScCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 12px 32px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  max-width: 368px;
  width: 100%;
  margin: 10px;
`;
