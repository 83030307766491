import React from "react";
import { AudioPlayer } from "app/components/MediaPlayer";
import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import { Audio } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";

export const PageAudioItem = () => {
  const [item] = usePageItemContext<Audio>();

  return (
    <PageItemWrapper>
      <AudioPlayer src={item.data.src} />
    </PageItemWrapper>
  );
};

export const PageAudioRender = ({ item }: { item: Audio }) => (
  <AudioPlayer src={item.data.src} />
);
