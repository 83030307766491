import { v4 as uuidv4 } from "uuid";

export const handleGuest = (name) => {
  if (localStorage.getItem("gid")) {
    return localStorage.getItem("gid");
  } else {
    const id = uuidv4();
    localStorage.setItem("gid", `${id}_${name}`);
    return id;
  }
};
