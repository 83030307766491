import React, { KeyboardEventHandler, useCallback, useRef } from "react";
import {
  useGetList,
  usePageItemContext,
  usePageItemIndex,
  useSetList,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  MultipleChoice,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { MediaData } from "app/components/Sources/MediaPicker/MediaPicker";
import classNames from "classnames";
import { FaCheck } from "react-icons/fa";
import { useItemFocus } from "app/components/Exercises/CourseEdit/items/PageItemFocus";
import { uuid } from "app/components/Exercises/utils/uuid";
import { withConsumption } from "app/components/Exercises/CourseEdit/render/ConsumptionContext";
import { MultipleChoiceConsumption } from "app/components/Exercises/CourseEdit/render/courseConsumptionTypes";
import {
  InputWithMedia,
  InputWithMediaRender,
} from "app/components/Exercises/CourseEdit/components/InputWithMedia";
import {
  InstructionsRender,
  QUIZ_ITEM_HINTS,
  QuizDropzone,
  QuizInstructionsRender,
  QuizItemLabelRaw,
} from "app/components/Exercises/CourseEdit/components/generate/QuizDropzone";
import { MultipleChoiceResults } from "app/components/Exercises/CourseEdit/results/courseResultsTypes";
import { CorrectMarker } from "app/components/Exercises/CourseEdit/results/PageResults";
import { CorrectnessMarker } from "app/components/Exercises/CourseEdit/results/PageCorrect";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbCircleCheck, TbListCheck, TbPlus, TbTrash } from "react-icons/tb";
import { Draft } from "immer";
import { useTranslation } from "react-i18next";

export const Checkbox = ({
  active = false,
  className = "",
  onClick,
  locked = false,
}: {
  active?: boolean;
  className?: string;
  onClick?: () => void;
  locked?: boolean;
}) => (
  <div
    className={classNames(
      "rounded-lg w-7 h-7 flex items-center justify-center transition shrink-0",
      active ? "bg-primary" : "bg-gray-200",
      !locked && "cursor-pointer hover:opacity-80",
      className
    )}
    onClick={onClick}
  >
    <FaCheck
      className={classNames(
        "text-white transition transform",
        !active && "opacity-0 scale-0"
      )}
    />
  </div>
);

export const Radio = ({
  active = false,
  className = "",
  onClick,
  locked = false,
}: {
  active?: boolean;
  className?: string;
  onClick?: () => void;
  locked?: boolean;
}) => (
  <div
    className={classNames(
      "rounded-full w-7 h-7 flex items-center justify-center transition shrink-0",
      active ? "bg-primary" : "bg-gray-200",
      !locked && "cursor-pointer hover:opacity-80",
      className
    )}
    onClick={onClick}
  >
    <div
      className={classNames(
        "bg-white rounded-full w-4 h-4 transition transform",
        !active && "opacity-0 scale-0"
      )}
    />
  </div>
);

const McqLabel = ({ multi = false }) => {
  const { t } = useTranslation();

  return multi ? (
    <QuizItemLabelRaw
      name={t("v4.item.selectAnswers.multiText")}
      icon={TbListCheck}
      tooltip={QUIZ_ITEM_HINTS(t)[PageItemType.MultipleChoice]}
    />
  ) : (
    <QuizItemLabelRaw
      name={t("v4.item.selectAnswers.singleText")}
      icon={TbCircleCheck}
      tooltip={t("v4.item.selectAnswers.singleInstructions")}
    />
  );
};

export const PageMultipleChoiceItem = () => {
  const [item, set] = usePageItemContext<MultipleChoice>();
  const { t } = useTranslation();

  return (
    <PageItemWrapper>
      <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
        <McqLabel multi={item.multi} />
        <QuizDropzone />
        {item.items?.map((item, i) => (
          <SingleMCQItem key={item.id} {...item} index={i} />
        ))}
        <NewButton
          center
          variant="bg-opacity-0 hover:bg-opacity-80"
          color="bg-white text-gray-500"
          size="lg"
          className="font-bold"
          onClick={() => {
            set((item) => {
              item.items.push({ id: uuid(), text: "", correct: false });
            });
          }}
        >
          <TbPlus /> {t("v4.generic.addItem")}
        </NewButton>
      </div>
    </PageItemWrapper>
  );
};

const SingleMCQItem = ({
  id: itemId,
  text,
  correct,
  media,
  index: itemIndex,
}: { index: number } & MultipleChoice["items"][number]) => {
  const { t } = useTranslation();
  const [item, set] = usePageItemContext<MultipleChoice>();
  const index = usePageItemIndex();
  const setList = useSetList();
  const getList = useGetList();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const setFocus = useItemFocus(itemId, (distance, reverse) => {
    const pos = reverse ? text.length - distance : distance;
    inputRef.current?.focus();
    inputRef.current?.setSelectionRange(pos, pos);
  });

  const handleText = useCallback(
    (text: string) => {
      set((item) => {
        item.items[itemIndex].text = text;
      });
    },
    [set, itemIndex]
  );

  const handleCheck = useCallback(() => {
    set((item) => {
      item.items[itemIndex].correct = !item.items[itemIndex].correct;
      checkLength(item, itemIndex);
    });
  }, [set, itemIndex]);

  const handleMedia = useCallback(
    (media?: MediaData) => {
      set((item) => {
        if (!media) {
          delete item.items[itemIndex]["media"];
        } else {
          item.items[itemIndex].media = media;
        }
      });
    },
    [set, itemIndex]
  );

  const checkLength = useCallback((item: Draft<MultipleChoice>, index = 0) => {
    const selectedCorrect = item.items.filter(({ correct }) => correct).length;
    if (!selectedCorrect) item.items[index].correct = true;
    item.multi = selectedCorrect > 1;
  }, []);

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    const target = e.target as HTMLTextAreaElement;
    const list = getList();

    // on arrow up
    if (e.key === "ArrowUp") {
      // selection has to be collapsed at start
      if (
        !!target.selectionStart ||
        target.selectionStart !== target.selectionEnd
      )
        return;

      if (!itemIndex) {
        if (!index) return;
        // moving focus to the previous item
        const previousItem = list[index - 1];

        // next item has to be text
        if (
          previousItem.type !== PageItemType.Paragraph &&
          previousItem.type !== PageItemType.List &&
          previousItem.type !== PageItemType.MultipleChoice
        )
          return;

        // selection has to be collapsed at beginning
        if (
          target.selectionStart ||
          target.selectionStart !== target.selectionEnd
        )
          return;

        setFocus(previousItem.id, 0, true);
        return;
      }

      const previousListItem = item.items[itemIndex - 1];
      setFocus(previousListItem.id, 0, true);
      return;
    }

    // on arrow down
    if (e.key === "ArrowDown") {
      // selection has to be collapsed at end
      if (
        target.selectionEnd !== text.length ||
        target.selectionStart !== target.selectionEnd
      )
        return;

      if (itemIndex === item.items.length - 1) {
        if (index === list.length - 1) return;
        // moving focus to the next item
        const nextItem = list[index + 1];

        // previous item has to be text
        if (
          nextItem.type !== PageItemType.Paragraph &&
          nextItem.type !== PageItemType.List &&
          nextItem.type !== PageItemType.MultipleChoice
        )
          return;

        // selection has to be collapsed at end
        if (
          target.selectionEnd !== text.length ||
          target.selectionStart !== target.selectionEnd
        )
          return;

        setFocus(nextItem.id);
        return;
      }

      const nextListItem = item.items[itemIndex + 1];
      setFocus(nextListItem.id);
    }

    // has to be backspace
    if (e.key === "Backspace") {
      if (media) return;

      if (item.items.length === 1 && !text) {
        setList((list) => {
          list.splice(index, 1);
        });

        if (index) {
          const previousItem = list[index - 1];
          setFocus(previousItem.id, 0, true);
        }
        return;
      }

      // selection has to be collapsed at start
      if (
        !!target.selectionStart ||
        target.selectionStart !== target.selectionEnd
      )
        return;

      e.preventDefault();
      set((item) => {
        item.items.splice(itemIndex, 1);
        if (itemIndex > 0) {
          item.items[itemIndex - 1].text += text;
          setFocus(item.items[itemIndex - 1].id, text.length, true);
        }
        checkLength(item);
      });
      return;
    }

    if (e.key === "Enter") {
      if (e.shiftKey) return;
      // selection has to be collapsed
      if (target.selectionStart !== target.selectionEnd) return;

      const pos = target.selectionStart;
      const id = uuid();
      e.preventDefault();
      set((item) => {
        const text = item.items[itemIndex].text;
        item.items[itemIndex].text = text.slice(0, pos);
        item.items.splice(itemIndex + 1, 0, {
          id,
          text: text.slice(pos),
          correct: false,
        });
      });
      setFocus(id);
    }
  };

  return (
    <div className="bg-white rounded-lg p-1.5 flex items-center relative mcq-group">
      {item.multi ? (
        <Checkbox active={correct} onClick={handleCheck} />
      ) : (
        <Radio active={correct} onClick={handleCheck} />
      )}
      <InputWithMedia
        {...{ text, media }}
        ref={inputRef}
        onText={handleText}
        onMedia={handleMedia}
        className="grow"
        placeholder={t("v4.generic.textPlaceholder")}
        onKeyDown={handleKeyDown}
      />

      <NewButton
        iconOnly
        color="bg-red-500 text-red-500"
        size="lg"
        className="mb-auto absolute top-0 -right-2.5 transform translate-x-full opacity-0 [.mcq-group:hover>&]:opacity-100"
        center
        onClick={() => {
          if (item.items.length === 1) {
            setList((list) => {
              list.splice(index, 1);
            });
          } else {
            set((item) => {
              item.items.splice(itemIndex, 1);
              checkLength(item);
            });
          }
        }}
      >
        <TbTrash />
      </NewButton>
    </div>
  );
};

export const PageMultipleChoiceRender =
  withConsumption<MultipleChoiceConsumption>(
    ({ answer, items, multi, id, set }) => {
      const handleCheck = useCallback(
        (id: string) => () => {
          if (multi) {
            set((item) => {
              item.answer[id] = !item.answer[id];
            });
            return;
          }

          set((item) => {
            for (const id in item.answer) {
              item.answer[id] = false;
            }
            item.answer[id] = true;
          });
        },
        [set, multi]
      );

      return (
        <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
          <McqLabel multi={multi} />
          <QuizInstructionsRender id={id} />
          {items.map(({ id, text, media }) => (
            <div
              className="bg-white cursor-pointer hover:bg-gray-50 transition rounded-lg p-1.5 flex items-center"
              key={id}
              onClick={handleCheck(id)}
            >
              {multi ? (
                <Checkbox active={!!answer?.[id]} />
              ) : (
                <Radio active={!!answer?.[id]} />
              )}
              <InputWithMediaRender {...{ text, media }} />
            </div>
          ))}
        </div>
      );
    }
  );

export const PageMultipleChoiceResults = ({
  item,
}: {
  item: MultipleChoiceResults;
}) => {
  if (!item.multi) {
    const correctValue = item.items?.find((a) => a.correct);
    return (
      <div className="flex items-stretch">
        <CorrectMarker
          correct={item.result}
          content={
            correctValue && (
              <InputWithMediaRender
                text={correctValue.text}
                media={correctValue.media}
              />
            )
          }
        />
        <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1 grow relative">
          <InstructionsRender instructions={item.instructions} />
          {item.items.map(({ id, text, media }) => (
            <div
              className="bg-white rounded-lg p-1.5 flex items-center grow"
              key={id}
            >
              <Radio locked active={!!item.answer?.[id]} />
              <InputWithMediaRender {...{ text, media }} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <InstructionsRender instructions={item.instructions} />
      {item.items.map(({ id, text, media }) => {
        const correct = !!item.result?.[id];
        return (
          <div className="flex items-stretch" key={id}>
            <CorrectMarker correct={correct} />
            <div className="bg-white rounded-lg p-1.5 flex items-center grow">
              <Checkbox locked active={!!item.answer?.[id]} />
              <InputWithMediaRender {...{ text, media }} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const PageMultipleChoiceCorrect = ({
  item,
}: {
  item: MultipleChoice;
}) => {
  if (!item.multi)
    return (
      <div className="flex items-stretch">
        <CorrectnessMarker ids={[item.id]} />
        <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1 grow relative">
          <InstructionsRender instructions={item.instructions} />
          {item.items.map(({ id, text, media, correct }) => (
            <div
              className="bg-white rounded-lg p-1.5 flex items-center grow relative"
              key={id}
            >
              {item.multi ? (
                <Checkbox locked active={correct} />
              ) : (
                <Radio locked active={correct} />
              )}
              <InputWithMediaRender {...{ text, media }} />
            </div>
          ))}
        </div>
      </div>
    );

  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <InstructionsRender instructions={item.instructions} />
      {item.items.map(({ id, text, media, correct }) => (
        <div className="flex items-stretch" key={id}>
          <CorrectnessMarker ids={[item.id, id]} />
          <div className="bg-white rounded-lg p-1.5 flex items-center grow relative">
            {item.multi ? (
              <Checkbox locked active={correct} />
            ) : (
              <Radio locked active={correct} />
            )}
            <InputWithMediaRender {...{ text, media }} />
          </div>
        </div>
      ))}
    </div>
  );
};
