import { isProd } from "helpers/isProd";

export const MIME = {
  pdf: "application/pdf",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  doc: "application/msword",
  ppt: "application/vnd.ms-powerpoint",
  odp: "application/vnd.oasis.opendocument.presentation",
  odt: "application/vnd.oasis.opendocument.text",
  weba: "audio/webm",
  mp4a: "audio/mp4",
  webm: "video/webm",
  mp4: "video/mp4",
  json: "application/json",
  csv: "text/csv",
};

export const SUPPORTED_AUDIO_MIMETYPES = [
  "audio/x-hx-aac-adts",
  "audio/aac",
  "audio/mpeg",
  "audio/wav",
  "audio/x-wav",
  "audio/webm",
  "video/webm",
  "audio/mp4a-latm",
  "audio/mp4",
  "audio/x-aac",
  "audio/aiff",
  "audio/x-aiff",
  "audio/ogg",
  ...(isProd() ? [] : ["audio/*", "audio/", "audio/x-flac", "audio/m4a"]),
];

export const SUPPORTED_VIDEO_MIMETYPES = [
  "video/webm",
  "video/mp4",
  "video/mpeg",
  "video/ogg",
  "video/x-msvideo",
  "video/quicktime",
  "video/avi",
  "video/x-matroska",
  "video/x-ms-wmv",
  ...(isProd() ? [] : ["video/*", "video/"]),
];

export const ACCEPTED_FILE_TYPES = [
  "image/*",
  ".heic",
  ".heif",
  MIME.pdf,
  MIME.doc,
  MIME.docx,
  MIME.ppt,
  MIME.pptx,
  MIME.odt,
  MIME.odp,
  ".ppt",
  ".pptx",
  ".m1v",
  ".m2v",
  ...SUPPORTED_AUDIO_MIMETYPES,
  ...SUPPORTED_VIDEO_MIMETYPES,
  ".aac",
];

export enum FileType {
  Document,
  Image,
  Video,
  Audio,
  File,
  Folder,
  Course,
}

export const mapSourceToType = (
  source:
    | { item_type: 0 }
    | { item_type: 2; mime_type: string }
    | { item_type: 3 }
) => {
  if (source.item_type === 0) return FileType.Folder;
  if (source.item_type === 3) return FileType.Course;
  return mapMime(source?.mime_type ?? "");
};

export const mapTypeToItemType = (type: FileType) => {
  if (type === FileType.Folder) return 0;
  if (type === FileType.Course) return 3;
  return 2;
};

export const mapMime = (mimeType: string): FileType => {
  if (
    [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/vnd.ms-powerpoint",
      "application/vnd.oasis.opendocument.presentation",
      "application/vnd.oasis.opendocument.text",
    ].find((m) => mimeType.startsWith(m))
  )
    return FileType.Document;

  if (SUPPORTED_AUDIO_MIMETYPES.find((m) => mimeType.startsWith(m)))
    return FileType.Audio;

  if (SUPPORTED_VIDEO_MIMETYPES.find((m) => mimeType.startsWith(m)))
    return FileType.Video;

  if (mimeType.startsWith("image")) return FileType.Image;

  return FileType.File;
};

export const mapFileTypeToName = (t: any) => (type: FileType) => {
  switch (type) {
    case FileType.Folder:
      return t("v4.library.folder");
    case FileType.Course:
      return t("v4.training.text");
    case FileType.Document:
      return t("media.document.name");
    case FileType.Image:
      return t("media.image.name");
    case FileType.Audio:
      return t("media.audio.name");
    case FileType.Video:
      return t("media.video.name");
    default:
      return t("media.file.name");
  }
};
