import classNames from "classnames";
import { ComponentProps, forwardRef } from "react";
import { IconType } from "react-icons";

interface InputProps extends Omit<ComponentProps<"input">, "onChange"> {
  value: string;
  onChange?: (value: string) => void;
  icon?: IconType;
  invalid?: boolean;
  variant?: "light" | "primary";
  maxLength?: number;
}

export const InputText = forwardRef<HTMLLabelElement, InputProps>(
  (
    {
      children,
      value,
      onChange,
      icon: Icon,
      className,
      disabled,
      invalid,
      variant = "light",
      maxLength,
      readOnly,
      ...props
    },
    ref
  ) => {
    const handleChange = (text: string) =>
      onChange?.(maxLength != null ? text.substring(0, maxLength) : text);

    return (
      <label
        className={classNames(
          "rounded-lg flex overflow-hidden outline-2 flex-grow outline group transition-[outline,background-color] relative",
          disabled && "opacity-50 cursor-not-allowed",
          readOnly
            ? "bg-gray-100 outline-gray-300"
            : invalid
            ? "outline-red-300 bg-red-100"
            : "bg-white outline-gray-200",
          readOnly
            ? ""
            : invalid
            ? "focus-within:outline-red-400"
            : variant === "light"
            ? "focus-within:outline-gray-300"
            : variant === "primary"
            ? "focus-within:outline-primary"
            : "",
          className
        )}
        ref={ref}
      >
        {Icon && (
          <Icon
            className={classNames(
              "my-auto ml-2 text-xl transition",
              readOnly
                ? "text-gray-400"
                : invalid
                ? "text-red-400"
                : "text-gray-400",
              readOnly
                ? ""
                : invalid
                ? "group-focus-within:text-red-600"
                : variant === "light"
                ? "group-focus-within:text-gray-600"
                : variant === "primary"
                ? "group-focus-within:text-primary"
                : ""
            )}
          />
        )}
        <input
          className="flex-grow min-w-0 px-2 py-1.5 outline-0 border-none bg-transparent"
          value={value}
          onChange={(e) => handleChange?.(e.target.value)}
          disabled={disabled}
          readOnly={readOnly}
          {...props}
        />
        {children}
        {maxLength != null && (
          <div className="text-[0.6rem] text-gray-500 backdrop-blur-sm font-bold pt-0.5 pl-0.5 pb-1 pr-1 bg-white bg-opacity-50 rounded leading-none absolute right-0 bottom-0 transition-opacity pointer-events-none [:focus-within>&]:opacity-100 opacity-0">
            {value.length}/{maxLength}
          </div>
        )}
      </label>
    );
  }
);
