import {
  GenerateItem,
  Item,
  SlideGenerateItem,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { useTranslation } from "react-i18next";
import { useMountedRef } from "app/hooks/useMounted";
import {
  usePageItemContext,
  usePageItemIndex,
  useSetList,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import { useEffect, useRef } from "react";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { SkeletonParagraph } from "app/components/Exercises/CourseEdit/components/SkeletonParagraph";
import { SlideItemWrapper } from "app/components/Exercises/CourseEdit/slide/SlideItemWrapper";
import { StyledEditable } from "app/components/Exercises/Edit/questionType/Slide/item/ItemText";
import { handleOpenAIError } from "app/components/Exercises/CourseEdit/items/generate/PageParagraphAutocomplete";
import { useSubscriptionRefetch } from "api/userAPI";
import { useRole } from "app/hooks/useRole";

export const usePageGenerate = <
  Type extends GenerateItem | SlideGenerateItem,
  Data extends Record<string, any>
>(
  promise: (item: Type) => Promise<Data>,
  gen: (data: Data, item: Type) => Item | Item[]
) => {
  const { t } = useTranslation();
  const mountedRef = useMountedRef();
  const index = usePageItemIndex();
  const indexRef = useRef(index);
  indexRef.current = index;
  const setList = useSetList();
  const [item] = usePageItemContext<Type>();
  const refetchSubscription = useSubscriptionRefetch();
  const role = useRole();

  useEffect(() => {
    promise(item)
      .then((data) => {
        if (!mountedRef.current) return;
        const generated = gen(data, item);
        const items = Array.isArray(generated) ? generated : [generated];

        setList((list) => {
          list.splice(indexRef.current, 1, ...items);
        });
        refetchSubscription();
      })
      .catch((e) => {
        handleOpenAIError(e, t, role);
        if (!mountedRef.current) return;
        setList((list) => {
          list.splice(indexRef.current, 1);
        });
      });
  }, []);
};

export const PageGenerate = <
  Type extends GenerateItem,
  Data extends Record<string, any>
>({
  promise,
  gen,
}: {
  promise: (item: Type) => Promise<Data>;
  gen: (data: Data, item: Type) => Item | Item[];
}) => {
  usePageGenerate(promise, gen);
  const { t } = useTranslation();

  return (
    <PageItemWrapper>
      <div className="flex flex-col">
        <div className="font-bold text-gray-400 px-1 mb-1">
          {t("v4.generate.generating")}
        </div>
        <div className="leading-relaxed">
          <SkeletonParagraph />
        </div>
      </div>
    </PageItemWrapper>
  );
};

export const SlidePageGenerate = <
  Type extends SlideGenerateItem,
  Data extends Record<string, any>
>({
  promise,
  gen,
}: {
  promise: (item: Type) => Promise<Data>;
  gen: (data: Data, item: Type) => Item | Item[];
}) => {
  usePageGenerate(promise, gen);

  return (
    <SlideItemWrapper>
      <StyledEditable
        vertical="center"
        className="absolute-cover overflow-y-auto flex flex-col rounded-2xl"
      >
        <div className="flex flex-wrap gap-2 p-4 leading-relaxed">
          <SkeletonParagraph />
        </div>
      </StyledEditable>
    </SlideItemWrapper>
  );
};
