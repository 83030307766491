import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../store/authentication";
import avatarImage from "../../assets/images/avatar-40-x-40.png";
import { Link, useHistory } from "react-router-dom";
import { selectUserDetails } from "../store/user";
import { useTranslation } from "react-i18next";
import { adminRoutes, miscRoutes, publicRoutes } from "enums/routes";
import { AVAILABLE_LANGUAGES } from "enums/languages";
import { updateUserDetails } from "../store/user/userActions";
import { useMutation } from "react-query";
import {
  deleteUserImpersonate,
  putUpdateUserDetails,
  useSubscription,
} from "api/userAPI";
import { FloatingMenu } from "./Header";
import classNames from "classnames";
import { ProdSwitches } from "./HamburgerMenu/NavMenu";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  TbAlertCircle,
  TbChevronDown,
  TbLanguage,
  TbSparkles,
  TbStarFilled,
} from "react-icons/tb";
import { useRole } from "app/hooks/useRole";
import { useDateFormat } from "app/hooks/useDateFormat";
import { CgSpinner } from "react-icons/cg";
import { gitBuildSHA } from "enums/constant";

export const LanguageChange = ({
  initial = "",
  local = false,
  small = false,
}) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(initial || "");

  const handleChange = (language: string) => {
    setLanguage(language);
    i18n.changeLanguage(language);
    if (!local) dispatch(updateUserDetails({ language }));
  };

  return (
    <FloatingMenu
      size="xs"
      placement="bottom-start"
      className="min-w-[15rem] w-full"
      trigger={(toggle) =>
        small ? (
          <NewButton
            onClick={toggle}
            className="border-gray-100"
            variant="transparent"
            size="lg"
            iconOnly
          >
            <TbLanguage />
          </NewButton>
        ) : (
          <NewButton
            onClick={toggle}
            className="font-bold text-sm border border-gray-100 whitespace-nowrap"
            variant="transparent"
          >
            <TbLanguage />
            {AVAILABLE_LANGUAGES?.[language || i18n.language]}
            <TbChevronDown className="ml-auto shrink-0" />
          </NewButton>
        )
      }
    >
      {({ setIsOpen }) => (
        <>
          {Object.entries(AVAILABLE_LANGUAGES).map(([key, name]) => (
            <NewButton
              key={key}
              className="text-sm"
              onClick={() => {
                setIsOpen(false);
                handleChange(key);
              }}
              variant={language === key ? "light" : "transparent"}
              color={language === key ? "bg-primary text-primary" : undefined}
            >
              {name}
            </NewButton>
          ))}
        </>
      )}
    </FloatingMenu>
  );
};

const ConditionalLink = (props) =>
  props.to == null ? <div {...props} /> : <Link {...props} />;

export const GuestMenu = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <FloatingMenu
      placement="bottom-end"
      size="sm"
      trigger={(toggle) => (
        <div
          onClick={toggle}
          className={classNames(
            "transition hover:bg-opacity-10 bg-primary bg-opacity-0 rounded-xl flex items-center p-2 cursor-pointer sm:pl-3"
          )}
        >
          <span className="font-bold mr-3 hidden sm:block">
            {t("v4.group.guest.name")}
          </span>
          <img className="w-8 h-8 rounded-full" src={avatarImage} alt="User" />
        </div>
      )}
      className="w-72 max-h-[80vh] overflow-y-auto gap-1"
    >
      <LanguageChange local />

      <ProdSwitches />

      <NewButton
        center
        className="text-sm font-bold"
        onClick={() => history.push(publicRoutes.home)}
      >
        {t("header.main.logoutBtn")}
      </NewButton>
    </FloatingMenu>
  );
};

const UserMenu = ({
  hideName = false,
  placement = undefined as any,
}): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const userDetails = useSelector(selectUserDetails);
  const role = useRole();
  const format = useDateFormat();

  const handleLogout = useCallback(() => {
    dispatch(logoutUser(history));
  }, [dispatch, history]);

  const { mutate } = useMutation(() => deleteUserImpersonate(), {
    onSuccess: () => window.location.reload(),
  });

  const userProfileMutation = useMutation(
    () =>
      putUpdateUserDetails({
        profile: {},
      }),
    {
      onSuccess: () => {
        location.reload();
      },
    }
  );

  const subscriptionQuery = useSubscription();
  const subscription = subscriptionQuery?.data;

  return (
    <FloatingMenu
      placement={placement}
      size="sm"
      trigger={(toggle) => (
        <div
          className={classNames(
            "transition hover:bg-opacity-10 bg-primary bg-opacity-0 rounded-xl flex items-center p-2 cursor-pointer",
            !hideName && "sm:pl-3"
          )}
          onClick={toggle}
        >
          {!hideName && (
            <span className="font-bold mr-3 hidden sm:block">
              {userDetails.id &&
                (userDetails.first_name || userDetails.username)}
            </span>
          )}
          <img
            className="w-8 h-8 rounded-full"
            src={userDetails.picture || avatarImage}
            alt="User"
          />
        </div>
      )}
      className="w-72 max-h-[80vh] overflow-y-auto gap-1"
    >
      <div className="bg-gray-100 rounded-lg py-2 px-3 flex flex-col leading-tight text-gray-700">
        <div className="font-bold text-base">
          {[userDetails?.first_name, userDetails?.last_name]
            .filter(Boolean)
            .join(" ")}
        </div>

        <div className="text-xs">{userDetails?.email}</div>
      </div>

      {!role.isB2B && (
        <>
          {subscription &&
          (subscription.status === "active" ||
            subscription.status === "trialing") ? (
            <ConditionalLink
              to={subscription?.id == null ? null : miscRoutes.subscriptions}
              className={classNames(
                "bg-primary bg-opacity-10 rounded-lg px-3 py-2.5 flex flex-col gap-2 leading-tight text-gray-700",
                subscription?.id != null &&
                  "hover:bg-opacity-20 cursor-pointer transition"
              )}
            >
              <div className="flex items-center gap-2 justify-between">
                <span className="font-bold text-sm leading-none text-primary mr-auto flex gap-1 items-center">
                  <TbStarFilled /> {subscription.name}
                </span>
                {subscription.credits_monthly > 0 && (
                  <span
                    className={classNames(
                      "text-xs leading-none font-bold text-right whitespace-nowrap",
                      !subscription.credits_left
                        ? "text-red-500"
                        : "text-primary"
                    )}
                  >
                    {subscription.credits_left}
                    {t("v4.subscriptions.creditsLeft")}
                  </span>
                )}
              </div>
              {subscription.credits_monthly > 0 && (
                <div
                  className={classNames(
                    "h-1 grow rounded-full relative overflow-hidden transition col-span-2",
                    !subscription.credits_left ? "bg-red-200" : "bg-gray-300"
                  )}
                >
                  <div
                    className="absolute-cover bg-primary origin-left transition"
                    style={{
                      transform: `scaleX(${
                        subscription.credits_left / subscription.credits_monthly
                      })`,
                    }}
                  />
                </div>
              )}
              {(subscription?.trial_end || subscription?.cancel_at) && (
                <span className="text-xs inline-flex font-bold gap-1 items-center text-red-500 whitespace-nowrap">
                  <TbAlertCircle className="text-sm shrink-0" />
                  {t("v4.subscriptions.expires")}
                  {format(
                    subscription?.trial_end || subscription?.cancel_at,
                    "distance"
                  )}
                </span>
              )}
            </ConditionalLink>
          ) : (
            <NewButton
              component={Link}
              to={miscRoutes.subscriptions}
              center
              color="bg-primary text-primary"
              className="font-bold text-sm"
            >
              <TbSparkles /> {t("v4.subscriptions.becomePro")}
            </NewButton>
          )}
        </>
      )}

      <LanguageChange initial={userDetails.language} />

      <ProdSwitches />

      {userDetails.is_superuser && (
        <NewButton
          center
          component={Link}
          to={adminRoutes.admin}
          className="text-sm font-bold"
          variant="primary"
        >
          Admin
        </NewButton>
      )}

      {userDetails?.impersonator && (
        <NewButton
          center
          onClick={() => mutate()}
          className="text-sm font-bold"
          color="bg-red-500 text-red-500"
        >
          Delete impersonation
        </NewButton>
      )}
      {!role.prodMode && (
        <NewButton
          center
          className="text-sm font-bold"
          onClick={userProfileMutation.mutate}
        >
          {userProfileMutation.isLoading && (
            <CgSpinner className="animate-spin" />
          )}
          Clear user profile
        </NewButton>
      )}
      <NewButton center className="text-sm font-bold" onClick={handleLogout}>
        {t("header.main.logoutBtn")}
      </NewButton>
      {!role.prodMode && gitBuildSHA && (
        <div className="text-xs text-center mt-1 text-gray-400">
          {gitBuildSHA}
        </div>
      )}
    </FloatingMenu>
  );
};

export default UserMenu;
