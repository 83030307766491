import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import { motion } from "framer-motion";
import { useSessionContext } from "../../../hooks/useSessionContext";
import { useTranslation } from "react-i18next";
import { Button } from "../../_styled/Button";
import { AiOutlineClockCircle } from "react-icons/ai";

const SingleRecord = ({
  currentExercise,
  data,
  summary,
  prevData,
  isDuration,
  expanded,
}) => {
  const prevScore: number = summary
    ? parseInt((prevData?.score * 100).toFixed(0))
    : parseInt((prevData?.scores?.[currentExercise] * 100).toFixed(0)) || 0;

  const score: number = summary
    ? parseInt((data?.score * 100).toFixed(0))
    : parseInt((data?.scores?.[currentExercise] * 100).toFixed(0)) || 0;
  const duration = data?.duration || 0;
  const minutes = Math.floor(duration / 60);

  const seconds = duration - minutes * 60;

  return (
    <ScSingleRecord
      className="bg-gray-300"
      // layout
      // layoutId={`${data.id}${summary ? "summary" : "results"}`}
      // transition={{ duration: 1.5, type: "ease-in", layout: { duration: 0.7 } }}
      key={data.id}
      // initial={!expanded && { top: `${data.index * 56}px` }}
      // animate={{ top: 0 }}
    >
      <ScProgress
        width={score}
        animate={{
          width: `${score}%`,
          backgroundColor: "#34ca59",
        }}
        transition={{
          duration: 1.5,
          type: "ease-in",
        }}
        initial={
          !expanded && {
            width: summary ? `${prevScore}%` : 0,
            backgroundColor: "#34ca59",
          }
        }
      />
      <ScContent>
        <span>{data.name}</span>
        <p style={{ display: "flex", alignItems: "center" }}>
          {duration !== 0 && isDuration && (
            <span
              style={{
                fontWeight: 300,
                margin: "0 20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <AiOutlineClockCircle style={{ marginRight: "5px" }} />
              {minutes >= 10 ? minutes : `0${minutes}`}:
              {seconds >= 10 ? seconds.toFixed(2) : `0${seconds.toFixed(2)}`}
            </span>
          )}
          {expanded ? (
            <span>{score}%</span>
          ) : (
            <>
              <CountUp
                end={score}
                duration={1.5}
                start={summary ? prevScore : 0}
              />{" "}
              %
            </>
          )}
        </p>
      </ScContent>
    </ScSingleRecord>
  );
};

const SessionLeaderboard = ({ summary = false, results = false }) => {
  const { t } = useTranslation();
  const { session } = useSessionContext();
  const [expanded, setExpanded] = useState(false);
  const [updateList, setUpdateList] = useState(false);

  const isDuration = session?.get?.status?.is_duration || false;

  useEffect(() => {
    setTimeout(() => setUpdateList(true), summary ? 0 : 1000);
  }, []);

  const allParticipants = summary
    ? !updateList && session.get.status.current_exercise !== 0
      ? session.get.status.prev_leaderboard_results
      : session.get.status.leaderboard_results
    : session.get.status.participants;

  const participants = allParticipants.filter((e) => {
    const user = session.get.status.participants.filter(
      (p) => p.id === e.id
    )[0];
    if (user && user.status !== "banned") return user;
  });

  const prevParticipants = useMemo(() => participants, []);

  const scores = participants.map((p: any) => {
    return summary
      ? p?.score
      : p.scores?.[session.get.status.current_exercise] || 0;
  });

  const average =
    scores.reduce((partialSum, a) => partialSum + a, 0) / participants.length;

  return (
    <div className="flex flex-col w-full max-w-2xl mx-auto">
      <TopBar>
        <span>
          {results
            ? t("common.tabSwitch.results")
            : t("common.tabSwitch.leaderboard")}
        </span>
        <p>
          {t("liveSession.average")}: {(average * 100).toFixed(0)}%
        </p>
      </TopBar>
      <div>
        {participants.slice(0, expanded ? undefined : 5).map((item, index) => (
          <SingleRecord
            expanded={expanded}
            summary={summary}
            currentExercise={session.get.status.current_exercise}
            data={{ ...item, index: index }}
            isDuration={isDuration}
            prevData={
              prevParticipants.filter(
                (p) => p.id === item.id && p.status !== "banned"
              )[0]
            }
          />
        ))}
      </div>
      {participants.length > 5 && !expanded && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ zIndex: 10, marginTop: "40px" }}
            clickAction={() => setExpanded(!expanded)}
          >
            {t("liveSession.showAll")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SessionLeaderboard;

const ScSingleRecord = styled.div`
  min-height: 48px;
  border-radius: 8px;
  margin: 4px 0;
  display: flex;
  align-items: center;
  position: relative;
`;

const ScContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  position: relative;
  z-index: 1;
  span {
    font-size: 14px;
    font-weight: bold;
  }
  p {
    font-size: 10px;
    margin: 0;
  }
`;

const ScProgress = styled(motion.div)<{ width: number }>`
  position: absolute;
  //background: ${({ width }) => (width > 90 ? "#34ca59" : "#34ca59")};
  height: 100%;
  border-radius: 8px;
  z-index: 0;
`;

const TopBar = styled.div`
  color: #000;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-weight: normal;
    font-size: 14px;
    margin: 0;
  }
`;
