import axios from "axios";
import { apiUrlV1 } from "enums/constant";

export async function getUserLibrary({
  folderId = "",
  page = 0,
  sort = "-recent_date",
  search = "",
  filter: [itemFilter, mimeFilter] = ["", ""],
  pageSize = 15,
}) {
  const url =
    `${apiUrlV1}/exercises/user/library/` +
    (folderId ? `${folderId}/` : "") +
    `?limit=${pageSize}` +
    `&offset=${page * pageSize}` +
    (search ? `&search=${search}` : "") +
    (itemFilter ? `&item_type=${itemFilter}` : "") +
    (mimeFilter ? `&mime_type=${mimeFilter}` : "") +
    `&order_by=-is_folder,${sort}`;

  const { data } = await axios.get<any>(url);
  return data;
}

export async function getPublicLibrary({
  page = 0,
  search = "",
  filter: [itemFilter, mimeFilter] = ["", ""],
  pageSize = 15,
}) {
  const url =
    `${apiUrlV1}/publications/` +
    `?limit=${pageSize}` +
    `&offset=${page * pageSize}` +
    (search ? `&search=${search}` : "") +
    (itemFilter ? `&item_type=${itemFilter}` : "") +
    (mimeFilter ? `&mime_type=${mimeFilter}` : "");

  const { data } = await axios.get<any>(url);
  return data;
}

export async function postUserCreateFolder({ id, dataToSave }: any) {
  const url = `${apiUrlV1}/exercises/user/library/${id ? `${id}/` : ""}`;
  const { data } = await axios.post<any>(url, dataToSave);
  return data;
}

export async function putFolder(id: string, putData: Record<string, any>) {
  const url = `${apiUrlV1}/exercises/user/library/${id}/`;
  const { data } = await axios.put<any>(url, putData);
  return data;
}

export async function addUserItemsToFolder({ id, dataToSave }: any) {
  const url = `${apiUrlV1}/exercises/user/library/add/${id ? `${id}/` : ""}`;
  const { data } = await axios.put<any>(url, dataToSave);
  return data;
}

export async function removeUserItems({ id, dataToSave }: any) {
  const url = `${apiUrlV1}/exercises/user/library/remove/${id ? `${id}/` : ""}`;
  const { data } = await axios.put<any>(url, dataToSave);
  return data;
}
