import React, { useEffect, useState } from "react";
import { ScCloseBtn, ScModal } from "../../components/_styled/Groups";
import icClose from "../../../assets/images/menu-close.png";
import styled from "styled-components";
import { Button, ButtonTypes } from "../../components/_styled/Button";
import { toast } from "react-hot-toast";
import Loader from "../../components/Loader";
import { useCreateNewOrg } from "./hooks/useCreateNewOrg";

const NewOrganization = ({ isOpen, close }) => {
  const [name, setName] = useState("");

  const create = useCreateNewOrg(name);

  useEffect(() => {
    if (create.isSuccess) {
      toast.success("Organization created successfully");
      setName("");
      close();
    }
  }, [create.isSuccess]);

  return (
    <ScModal open={isOpen}>
      <div style={{ padding: "40px 20px" }}>
        {create.isLoading ? (
          <Loader />
        ) : (
          <>
            <div>
              <ScCloseBtn src={icClose} onClick={close} />
              <ScInput
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </div>
            <div style={{ display: "flex" }}>
              <Button theme={ButtonTypes.light} clickAction={() => close()}>
                Cancel
              </Button>
              <Button
                disabled={!name.length}
                clickAction={() => create.mutate()}
              >
                Create organization
              </Button>
            </div>
          </>
        )}
      </div>
    </ScModal>
  );
};

export default NewOrganization;

const ScInput = styled.input`
  box-sizing: border-box;
  height: 38px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  padding: 2px 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
`;
