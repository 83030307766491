import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  selectExerciseUserLibrary,
  setSorting,
} from "../../store/exercise-library-user";
import { setSortingHelper } from "../../../helpers/setSorting";
import SortArrow from "../../../assets/images/table/ic-sort-arrow.svg";

const TablesHeader = () => {
  const dispatch = useDispatch();
  const { sorting } = useSelector(selectExerciseUserLibrary);

  const handleSetSorting = (sortBy) => {
    dispatch(setSorting([setSortingHelper(sortBy, sorting[0])]));
  };

  return (
    <ScHeaderWrapper>
      <ScHeaderTitle onClick={() => handleSetSorting("name")}>
        {sorting[0].id === "name" && (
          <ScArrow
            direction={sorting[0].desc}
            src={SortArrow}
            alt="sort-arrow"
          />
        )}
        <span>Name</span>
      </ScHeaderTitle>
    </ScHeaderWrapper>
  );
};

export default TablesHeader;

const ScHeaderWrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 32px 32px 0;
  display: flex;
  margin-top: 16px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

const ScHeaderTitle = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  min-height: 48px;
  :first-child {
    margin-left: 40px;
    min-width: 70px;
    @media screen and (max-width: 800px) {
      margin-left: 15px;
    }
  }
`;

const ScArrow = styled.img<{ direction: boolean }>`
  transform: ${(p) => p.direction && "rotate(180deg)"};
  position: absolute;
  transition: transform 0.2s ease-in-out;
  left: -23px;
  top: 12px;
`;
