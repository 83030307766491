import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import { SlideAudio } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { SlideItemWrapper } from "app/components/Exercises/CourseEdit/slide/SlideItemWrapper";
import { AudioPlayer } from "app/components/MediaPlayer";
import React from "react";

export const SlidePageAudio = () => {
  const [{ data }] = usePageItemContext<SlideAudio>();
  return (
    <SlideItemWrapper>
      <AudioPlayer src={data.src} cover />
    </SlideItemWrapper>
  );
};

export const SlideAudioRender = ({ item }: { item: SlideAudio }) => (
  <AudioPlayer src={item.data.src} cover />
);
