import React, { ReactNode, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { groupRoutes } from "enums/routes";
import {
  TbClipboardCheck,
  TbCrown,
  TbCrownOff,
  TbLock,
  TbLockOpen,
  TbSettings,
  TbTrash,
  TbUserPlus,
  TbUsers,
} from "react-icons/tb";
import { Link, useRouteMatch } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import { SharedGroup } from "./components/SharedGroup";
import {
  getGroup,
  removeGroupMember,
  updateGroup,
  updateGroupMember,
} from "api/groups/groupsAPI";
import { Header } from "app/components/Header";
import { NewButton } from "app/components/Buttons/NewButton";
import classNames from "classnames";
import { CgSpinner } from "react-icons/cg";
import { FaStar } from "react-icons/fa";
import { toast } from "react-hot-toast";
import { useIsGroupAdmin } from "app/hooks/useRole";
import { Tooltip } from "app/components/Tooltip";
import { useTranslation } from "react-i18next";
import { translateError } from "app/components/Exercises/Edit/missingTranslation";
import { InputToggle } from "app/components/Buttons/InputToggle";

export const SidebarItem = ({ path, children }) => {
  const active = !!useRouteMatch({
    path,
    exact: true,
    strict: false,
  });
  return (
    <SidebarItemRaw component={Link} to={path} key={path} active={active}>
      {children}
    </SidebarItemRaw>
  );
};

export const SidebarItemRaw = ({
  active = false,
  children = "" as ReactNode,
  component: Component = "div" as any,
  ...props
}) => {
  return (
    <Component
      className={classNames(
        "flex items-center gap-2 [&>svg]:text-xl px-3 py-2 rounded-xl transition",
        active
          ? "bg-primary text-white"
          : "text-gray-600 bg-primary bg-opacity-0 hover:bg-opacity-20 cursor-pointer"
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

export const GroupSidebar = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const isGroupAdmin = useIsGroupAdmin(id).data;
  return (
    <div className="flex flex-col gap-1 grow md:max-w-[15rem] md:mr-8">
      <SidebarItem path={groupRoutes.assignments(id)}>
        <TbClipboardCheck /> {t("v4.assignment.plural")}
      </SidebarItem>
      <SidebarItem path={groupRoutes.members(id)}>
        <TbUsers /> {t("v4.group.members")}
      </SidebarItem>
      {isGroupAdmin && (
        <SidebarItem path={groupRoutes.settings(id)}>
          <TbSettings /> {t("v4.generic.settings")}
        </SidebarItem>
      )}
    </div>
  );
};

export const GroupInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { isSuccess, data } = useQuery(["group", id], () => getGroup(id));
  const location = useLocation<{ invite?: true }>();
  const [invite, setInvite] = useState(!!location.state?.invite);
  const isGroupAdmin = useIsGroupAdmin(id).data;

  return (
    <div className="flex gap-2 font-bold text-2xl max-w-5xl mx-auto w-full mb-2 relative">
      {!isSuccess ? (
        <div className="bg-gray-100 grow rounded-xl h-10" />
      ) : (
        <>
          {data?.group_color && (
            <div
              className="rounded-full w-[0.25em] h-[1.25em]"
              style={{ backgroundColor: data.group_color }}
            />
          )}
          <div className="grow overflow-hidden text-ellipsis">
            {data.group_name}
          </div>

          {isGroupAdmin && (
            <NewButton
              variant="primary"
              className="text-base"
              onClick={() => setInvite(true)}
            >
              <TbUserPlus /> {t("v4.group.invite")}
            </NewButton>
          )}
        </>
      )}

      {invite && <SharedGroup id={id} close={() => setInvite(false)} />}
      {/*<NewButton iconOnly>*/}
      {/*  <TbDotsVertical />*/}
      {/*</NewButton>*/}
    </div>
  );
};

export const GroupMembers = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { isSuccess, data, refetch } = useQuery(["group", id], () =>
    getGroup(id)
  );
  const isGroupAdmin = useIsGroupAdmin(id).data;

  const isSingleAdmin = useMemo(() => {
    return data?.members?.filter((user) => user?.is_admin)?.length < 2;
  }, [data?.members]);

  const guestMutation = useMutation(
    () => updateGroup(id, { guest_access: !data?.guest_access }),
    {
      onSuccess: () => {
        refetch();
        toast.success(t("v4.group.guest.update.success"));
      },
      onError: () => {
        toast.error(translateError(t("v4.group.guest.update.error"), t));
      },
    }
  );

  return (
    <>
      <Header />
      <main className="flex flex-col w-full justify-center py-6 px-4 text-gray-600">
        <GroupInfo />
        <div className="flex flex-col md:flex-row justify-center">
          <GroupSidebar />
          <div className="max-w-3xl grow bg-white rounded-2xl shadow-xl mb-auto p-6 flex flex-col">
            <div className="flex flex-col mb-4 gap-2">
              <div className="flex items-center w-full font-bold text-xl gap-2">
                {t("v4.group.members")}
                <div className="grow" />
                <label
                  className={classNames(
                    "flex items-center gap-2",
                    isGroupAdmin && "cursor-pointer"
                  )}
                >
                  {data?.guest_access ? (
                    <div className="text-xs flex items-center gap-1 font-bold px-1.5 py-1 rounded-lg text-primary bg-primary bg-opacity-20">
                      <TbLockOpen className="text-sm" />{" "}
                      {t("v4.group.guest.open")}
                    </div>
                  ) : (
                    <div className="text-xs flex items-center gap-1 font-bold px-1.5 py-1 rounded-lg bg-gray-200 text-gray-600">
                      <TbLock className="text-sm" /> {t("v4.group.guest.close")}
                    </div>
                  )}
                  {isGroupAdmin && (
                    <InputToggle
                      disabled={guestMutation.isLoading}
                      loading={guestMutation.isLoading}
                      value={!!data?.guest_access}
                      onChange={() =>
                        !guestMutation.isLoading && guestMutation.mutate()
                      }
                    />
                  )}
                </label>
              </div>
            </div>
            {!isSuccess ? (
              <CgSpinner className="text-2xl text-gray-400 m-auto animate-spin" />
            ) : (
              <div className="flex flex-col gap-2">
                {data.members.map((item) => (
                  <GroupMember
                    key={item.id}
                    {...{ item, isSingleAdmin, isGroupAdmin }}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

const GroupMember = ({
  item: { id, name, is_admin, email, is_org_pro, is_guest },
  isSingleAdmin,
  isGroupAdmin,
}) => {
  const { t } = useTranslation();
  const { id: groupId } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(() => removeGroupMember(groupId, id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["group", groupId]);
      toast.success(t("v4.group.kickSuccess"));
    },
    onError: () => {
      toast.error(translateError(t("v4.group.kickError"), t));
    },
  });

  const adminMutation = useMutation(
    (isAdmin: boolean) => updateGroupMember(groupId, id, { is_admin: isAdmin }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["group", groupId]);
        toast.success(t("v4.group.roleSuccess"));
      },
      onError: () => {
        toast.error(translateError(t("v4.group.roleError"), t));
      },
    }
  );

  return (
    <div className="flex w-full group min-w-0 gap-2" key={id}>
      <div className="grow flex flex-col text-gray-500 py-1 leading-tight">
        <div className="font-bold items-center flex gap-2">
          <span>{name}</span>
          {is_admin && (
            <div className="bg-primary text-white text-xs px-1 py-0.5 gap-1 flex items-center rounded">
              <FaStar /> {t("v4.group.admin.text")}
            </div>
          )}
        </div>
        {email && <div className="text-sm text-gray-400">{email}</div>}
        {is_guest && (
          <div className="text-sm text-gray-400">
            {t("v4.group.guest.name")}
          </div>
        )}
      </div>
      {isGroupAdmin && (
        <div className="transition opacity-50 group-hover:opacity-100 shrink-0 flex gap-1 items-center">
          {/* if is a teacher AND is not the last admin */}
          {is_org_pro && !(is_admin && isSingleAdmin) && (
            <Tooltip
              value={
                is_admin ? t("v4.group.admin.remove") : t("v4.group.admin.make")
              }
            >
              <NewButton
                iconOnly
                onClick={() => adminMutation.mutate(!is_admin)}
                disabled={adminMutation.isLoading}
              >
                {adminMutation.isLoading ? (
                  <CgSpinner className="animate-spin" />
                ) : is_admin ? (
                  <TbCrownOff />
                ) : (
                  <TbCrown />
                )}
              </NewButton>
            </Tooltip>
          )}
          {!is_admin && (
            <NewButton
              iconOnly
              color="bg-red-500 text-red-500"
              onClick={() => deleteMutation.mutate(id)}
              disabled={deleteMutation.isLoading}
            >
              {deleteMutation.isLoading ? (
                <CgSpinner className="animate-spin" />
              ) : (
                <TbTrash />
              )}
            </NewButton>
          )}
        </div>
      )}
    </div>
  );
};
