import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { FloatingMenu } from "app/components/Header";
import { NewButton } from "app/components/Buttons/NewButton";
import {
  TbBook2,
  TbCopy,
  TbCut,
  TbDotsVertical,
  TbPlus,
  TbTrash,
  TbWand,
} from "react-icons/tb";
import { Draggable } from "react-beautiful-dnd";
import {
  useGetList,
  useIsColumnContent,
  useIsInstructionContent,
  usePageContext,
  usePageItemContext,
  usePageItemIndex,
  useSetList,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  Audio,
  Categorisation,
  Columns,
  CommentPoll,
  ConsumptionType,
  ContentItem,
  CourseItem,
  Crossword,
  Embed,
  Explanation,
  FillTheGap,
  Flashcards,
  FlashcardsQuiz,
  Image,
  ImageLabeling,
  Item,
  MediaUpload,
  MultipleChoice,
  MultipleChoicePoll,
  PageItemType,
  Pairing,
  Paragraph,
  QuizItem,
  Sorting,
  Video,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import {
  COURSE_ITEMS,
  FloatingList,
} from "app/components/Exercises/CourseEdit/items/InsertPageItem";
import {
  CreateOptContext,
  useProtectedContext,
} from "app/hooks/useProtectedContext";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import {
  FileType,
  mapMime,
  SUPPORTED_AUDIO_MIMETYPES,
  SUPPORTED_VIDEO_MIMETYPES,
} from "helpers/mimeType";
import { FILE_SIZE_LIMIT } from "helpers/file";
import { toast } from "react-hot-toast";
import { useSourceUploadContext } from "api/ws/SourceUploadContext";
import { useTranslation } from "react-i18next";
import { uuid } from "app/components/Exercises/utils/uuid";
import {
  DropMedia,
  DropMediaType,
  useDropMedia,
} from "app/components/Sources/MediaPicker/context/dropMediaContext";
import { usePageFocusContext } from "app/components/Exercises/CourseEdit/items/PageItemFocus";
import {
  MediaData,
  MediaPicker,
  SelectOnlyType,
} from "app/components/Sources/MediaPicker/MediaPicker";
import { BigModal, BigModalBody } from "app/components/BigModal";
import { Tooltip } from "app/components/Tooltip";
import { useRole } from "../../../../hooks/useRole";

export const HorizontalLine = ({ className = "" }) => (
  <div
    className={classNames(
      "w-10 border-t border-b mx-auto border-gray-200",
      className
    )}
  />
);

/**
 * Wrapper for the course item
 * @param children - the actual item content and logic
 * @param toolbar - floating toolbar
 * @param magic - floating magic button menu
 * @param generate - if this is a "generate" item (translating, next paragraph etc.). Generate items can't be copied/moved
 * @constructor
 */
export const PageItemWrapper = ({
  children,
  toolbar,
  magic,
  generate = false,
}: PropsWithChildren<{
  toolbar?: (trash: ReactNode, close: () => void) => ReactNode;
  magic?: ((close: () => void) => ReactNode) | false | ReactNode;
  generate?: boolean;
}>) => {
  const role = useRole();
  const [data] = usePageItemContext<CourseItem>();
  const index = usePageItemIndex();
  const setList = useSetList();
  const openRef = useRef(() => {});
  const isInstructionContent = useIsInstructionContent();
  const isColumnContent = useIsColumnContent();
  const [, setDropMedia] = useDropMedia();

  if (!data) return null;

  const handleDelete = () => {
    setList((list) => {
      list.splice(index, 1);
    });
  };

  const handleCut = () => {
    const { category, ...media } = data;
    setDropMedia(media);
    handleDelete();
  };

  const handleCopy = () => {
    const { category, id, ...media } = data;
    setDropMedia(media);
    openRef?.current?.();
  };

  const trashButton = (
    <div className="flex gap-1 rounded-lg bg-gray-100">
      {!generate && (
        <NewButton iconOnly size="lg" variant="transparent" onClick={handleCut}>
          <TbCut />
        </NewButton>
      )}
      {!generate && (
        <NewButton
          iconOnly
          size="lg"
          variant="transparent"
          onClick={handleCopy}
        >
          <TbCopy />
        </NewButton>
      )}
      <NewButton
        iconOnly
        size="lg"
        variant="transparent"
        color="bg-red-500 text-red-500"
        onClick={handleDelete}
      >
        <TbTrash />
      </NewButton>
    </div>
  );

  return (
    <Draggable draggableId={data.id} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }) => (
        <div
          className="flex flex-col page-item"
          ref={innerRef}
          {...draggableProps}
        >
          <GapInsertButton index={index} />
          <div className="max-w-2xl w-full mx-auto relative py-1 my-2">
            {/*<div*/}
            {/*  {...dragHandleProps}*/}
            {/*  className="absolute top-0 -left-1 transform -translate-x-full h-full w-4 flex items-center justify-center rounded bg-gray-600 bg-opacity-0 hover:bg-opacity-10 transition opacity-0 [.page-item:hover>div>&]:opacity-50 [.page-item:hover>div>&:hover]:opacity-100"*/}
            {/*>*/}
            {/*  <TbGripVertical />*/}
            {/*</div>*/}
            <div className="absolute -left-2 top-0 transform -translate-x-full flex gap-1">
              {role.aiEnabled &&
                !isInstructionContent &&
                !isColumnContent &&
                magic &&
                !isEmptyTextItem(data) &&
                (typeof magic !== "function" ? (
                  <Tooltip value={magic}>
                    <NewButton
                      iconOnly
                      variant="primary"
                      className="transition !pointer-events-none opacity-0 [.page-item:hover>div>div>div>&]:opacity-50 [.page-item:focus-within>div>div>div>&]:opacity-50 backdrop-blur"
                    >
                      <TbWand />
                    </NewButton>
                  </Tooltip>
                ) : (
                  <FloatingMenu
                    portal
                    size="xs"
                    placement="bottom-end"
                    trigger={(toggle) => (
                      <NewButton
                        iconOnly
                        variant="primary"
                        className="transition opacity-0 [.page-item:hover>div>div>div>div>&]:opacity-100 [.page-item:focus-within>div>div>div>div>&]:opacity-100 backdrop-blur"
                        onClick={toggle}
                      >
                        <TbWand />
                      </NewButton>
                    )}
                  >
                    {({ setIsOpen }) => (
                      <div className="flex flex-col gap-1 max-h-64 overflow-y-auto pr-1">
                        {magic(() => setIsOpen(false))}
                      </div>
                    )}
                  </FloatingMenu>
                ))}
              <FloatingMenu
                portal
                size="xs"
                placement="bottom-end"
                trigger={(toggle) => (
                  <NewButton
                    iconOnly
                    {...dragHandleProps}
                    className="transition opacity-0 [.page-item:hover>div>div>div>div>&]:opacity-100 [.page-item:focus-within>div>div>div>div>&]:opacity-100 !px-0.5"
                    onClick={toggle}
                  >
                    <TbDotsVertical />
                  </NewButton>
                )}
              >
                {({ isOpen, setIsOpen }) => {
                  openRef.current = () => setIsOpen(!isOpen);
                  if (toolbar)
                    return toolbar(trashButton, () => setIsOpen(false));
                  return <div className="flex gap-1">{trashButton}</div>;
                }}
              </FloatingMenu>
            </div>
            <OpenMenuContext.Provider value={openRef}>
              {children}
            </OpenMenuContext.Provider>
          </div>
        </div>
      )}
    </Draggable>
  );
};

/**
 * Hook to control dropping outside files (images) into the course
 */
export const useDropSource = () => {
  const { t } = useTranslation();
  // const role = useRole();
  const prodMode = false;
  const { upload } = useSourceUploadContext();

  const [rejectedFile, setRejectedFile] = useState<File | null>(null);

  const onDrop = useCallback(
    (files: File[]) => {
      const file = files?.[0];
      if (!file) return;

      if (file.size > FILE_SIZE_LIMIT) {
        toast.error(t("sourcesPage.status.uploading.errorSize"));
        return;
      }

      const mimeType = !!file?.type && mapMime(file.type);
      if (
        mimeType === false ||
        ![FileType.Image, FileType.Audio, FileType.Video].includes(mimeType)
      ) {
        toast.error(t("sourcesPage.uploadNew.uploadTypeError"));
        return;
      }
      const uploadId = upload({ file });
      return { uploadId, mimeType };
    },
    [upload]
  );

  const onDropRejected = useCallback(
    (files: { file: File }[]) => {
      const file = files?.[0]?.file;
      const mimeType = !!file?.type && mapMime(file.type);

      const validFile = (() => {
        if (!file?.size) return;
        if (file.size > FILE_SIZE_LIMIT) return;

        if (
          mimeType === false ||
          ![
            FileType.Image,
            FileType.Audio,
            FileType.Video,
            FileType.Document,
          ].includes(mimeType)
        )
          return;
        return true;
      })();

      if (!validFile) return toast.error(t("v4.slide.dropUploadError"));
      if (mimeType === FileType.Document && prodMode) {
        return toast.error(t("v4.slide.dropDocumentError"));
      }

      setRejectedFile(file);
    },
    [prodMode]
  );

  const confirmRejectedFile = useMemo(() => {
    if (!rejectedFile) return;
    return () => {
      const file = rejectedFile;
      setRejectedFile(null);
      return upload({ file });
    };
  }, [rejectedFile, upload]);

  const cancelRejectedFile = useMemo(() => {
    if (!rejectedFile) return;
    return () => {
      setRejectedFile(null);
    };
  }, [!!rejectedFile]);

  return { onDrop, onDropRejected, confirmRejectedFile, cancelRejectedFile };
};

export const RejectedSourceUpload = ({
  confirm,
  cancel,
}: {
  confirm?: () => void;
  cancel?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <BigModal fit open={!!confirm}>
      <BigModalBody
        maxWidth
        className="max-w-md text-gray-500 gap-1 p-8 leading-tight font-bold text-center"
      >
        <div className="text-lg text-gray-400">
          {t("v4.slide.dropUploadError")}
        </div>
        <div className="text-xl mb-8">{t("v4.slide.dropUploadPrompt")}</div>
        <NewButton
          variant="primary"
          size="lg"
          className="text-lg"
          onClick={confirm}
          center
        >
          {t("v4.generic.confirm")}
        </NewButton>
        <NewButton size="lg" className="text-lg" onClick={cancel} center>
          {t("v4.generic.cancel")}
        </NewButton>
      </BigModalBody>
    </BigModal>
  );
};

/**
 * The button to insert a course item in the quiz instructions or in the course content
 * If the user is dropping course content, or files from their desktop, insert those accordingly
 * @param index
 * @param big
 * @constructor
 */
export const GapInsertButton = ({
  index,
  big,
}: {
  index: number;
  big?: boolean;
}) => {
  const category = usePageContext((state) => state.data?.category);
  const quizType = usePageContext((state) => state.data?.quizType);
  const [dropMedia] = useDropMedia();
  const { t } = useTranslation();
  const getList = useGetList();
  const itemsLength = getList()?.length ?? 0;
  const setList = useSetList();
  const setFocus = usePageFocusContext((state) => state.select);
  const [insertMedia, setInsertMedia] = useState(false);
  const isInstructionContent = useIsInstructionContent();
  const isColumnContent = useIsColumnContent();

  const dropSource = useDropSource();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: [
      "image/*",
      ...SUPPORTED_VIDEO_MIMETYPES,
      ...SUPPORTED_AUDIO_MIMETYPES,
    ],
    noKeyboard: true,
    noClick: true,
    multiple: false,
    noDragEventsBubbling: true,
    onDropRejected: dropSource.onDropRejected,
    onDrop: (acceptedFiles) => {
      const { uploadId, mimeType } = dropSource.onDrop(acceptedFiles) || {};
      if (!uploadId) return;

      setList((list) => {
        const placeholder: MediaUpload = {
          id: uuid(),
          uploadId,
          type: PageItemType.MediaUpload,
          category: "generate",
          mediaType:
            mimeType === FileType.Audio
              ? PageItemType.Audio
              : mimeType === FileType.Video
              ? PageItemType.Video
              : PageItemType.Image,
        };
        list.splice(index, 0, placeholder);
      });
    },
  });

  if (dropMedia) {
    const drop = (media: DropMediaType) => {
      const insert =
        (category: CourseItem["category"] = "content") =>
        () => {
          setList((list) => {
            list.splice(index, 0, {
              id: uuid(),
              category,
              ...media,
            } as ContentItem);
          });
        };

      // inserting in poll
      if (category === "poll" && !isInstructionContent) {
        switch (media.type) {
          case PageItemType.CommentPoll:
          case PageItemType.MultipleChoicePoll:
            return insert("poll");
        }
        return false;
      }

      // inserting in quiz, outside instruction
      if (category === "quiz" && !isInstructionContent) {
        switch (media.type) {
          case PageItemType.MultipleChoiceGenerate:
          case PageItemType.MultipleResponseGenerate:
            return insert("generate");
          case PageItemType.MultipleChoice:
          case PageItemType.ShortAnswer:
          case PageItemType.Sorting:
          case PageItemType.Pairing:
          case PageItemType.ImageLabeling:
          case PageItemType.FillTheGap:
          case PageItemType.Explanation:
          case PageItemType.Categorisation:
          case PageItemType.Crossword:
          case PageItemType.FlashcardsQuiz:
            return insert("quiz");
          case PageItemType.Flashcards:
            return () => {
              setList((list) => {
                list.splice(index, 0, {
                  id: uuid(),
                  ...media,
                  instructions: [],
                  consumptionType: ConsumptionType.multipleChoice,
                  category: "quiz",
                  type: PageItemType.FlashcardsQuiz,
                } as QuizItem);
              });
            };
        }

        return;
      }

      // inserting not in quiz, or inside instruction
      switch (media.type) {
        case PageItemType.ParagraphSummarize:
        case PageItemType.ParagraphSimplify:
        case PageItemType.ParagraphNext:
        case PageItemType.ParagraphParaphrase:
        case PageItemType.ParagraphTranslate:
          return insert("generate");
        // case PageItemType.Heading:
        case PageItemType.Paragraph:
        case PageItemType.List:
        case PageItemType.Image:
        case PageItemType.Video:
        case PageItemType.Audio:
        case PageItemType.Embed:
          return insert();

        case PageItemType.FlashcardsQuiz: {
          if (
            quizType !== PageItemType.Flashcards ||
            isInstructionContent ||
            isColumnContent
          )
            return undefined;

          return () => {
            setList((list) => {
              list.splice(index, 0, {
                id: uuid(),
                ...media,
                instructions: undefined,
                consumptionType: undefined,
                category: "content",
                type: PageItemType.Flashcards,
              } as ContentItem);
            });
          };
        }
        case PageItemType.Flashcards: {
          if (
            quizType !== PageItemType.Flashcards ||
            isInstructionContent ||
            isColumnContent
          )
            return undefined;
          return insert();
        }
        case PageItemType.Columns:
          return !isInstructionContent && !isColumnContent
            ? insert()
            : undefined;
      }

      return;
    };

    return (
      <div
        className={classNames(
          "max-w-2xl w-full mx-auto relative -my-3",
          big ? "h-32" : "h-6"
        )}
      >
        <DropMedia onDrop={drop} />
      </div>
    );
  }

  const handleInsert = <T extends CourseItem>(item: Omit<T, "id">) => {
    const id = uuid();
    setList((list) => {
      list.splice(index, 0, { ...item, id } as T);
    });

    setFocus(id, 0, true);
  };

  return (
    <>
      <RejectedSourceUpload
        cancel={dropSource.cancelRejectedFile}
        confirm={dropSource.confirmRejectedFile}
      />
      <div
        className={classNames(
          "relative transition-all -my-3",
          isDragActive ? "h-48" : big ? "h-32" : "h-6"
        )}
        {...getRootProps()}
        role={undefined}
      >
        {insertMedia && (
          <InsertMedia index={index} close={() => setInsertMedia(false)} />
        )}
        <input {...getInputProps()} />
        <div
          className={classNames(
            "max-w-2xl w-full mx-auto transition absolute top-0 left-1/2 transform -translate-x-1/2",
            isDragActive && "opacity-0 pointer-events-none",
            big ? "h-32" : "h-6"
          )}
        >
          <FloatingMenu
            portal
            size="xs"
            placement="bottom"
            trigger={(toggle) =>
              big ? (
                <div
                  onClick={() => !isDragActive && toggle()}
                  className={classNames(
                    "flex mr-auto h-32 text-xl gap-2 rounded-lg bg-primary bg-opacity-10 text-primary font-bold items-center justify-center cursor-pointer transition",
                    !!itemsLength && "opacity-0 hover:opacity-100"
                  )}
                >
                  <TbPlus className="text-2xl" strokeWidth={3} />{" "}
                  {t("v4.generic.newItem")}
                </div>
              ) : (
                <div
                  onClick={() => !isDragActive && toggle()}
                  className="w-full h-6 rounded transition text-primary bg-primary opacity-0 bg-opacity-20 hover:opacity-100 cursor-pointer flex items-center justify-center"
                >
                  <TbPlus strokeWidth={3} />
                </div>
              )
            }
          >
            {({ setIsOpen, isOpen }) => (
              <>
                {((category !== "quiz" && category !== "poll") ||
                  isInstructionContent) && (
                  <FloatingList
                    items={[
                      {
                        key: PageItemType.Paragraph,
                        ...COURSE_ITEMS(t)[PageItemType.Paragraph],
                        color: "bg-blue-700",
                        onClick: () => {
                          handleInsert<Paragraph>({
                            type: PageItemType.Paragraph,
                            category: "content",
                            data: [[{ text: "", bold: isInstructionContent }]],
                            align: "left",
                          });
                          setIsOpen(false);
                        },
                      },
                      {
                        key: PageItemType.Image,
                        ...COURSE_ITEMS(t)[PageItemType.Image],
                        color: "bg-blue-700",
                        onClick: () => {
                          setInsertMedia(true);
                          setIsOpen(false);
                        },
                      },
                      {
                        key: PageItemType.Embed,
                        ...COURSE_ITEMS(t)[PageItemType.Embed],
                        color: "bg-blue-700",
                        onClick: () => {
                          handleInsert<Embed>({
                            type: PageItemType.Embed,
                            category: "content",
                            data: "",
                          });
                          setIsOpen(false);
                        },
                      },
                      {
                        key: PageItemType.Columns,
                        ...COURSE_ITEMS(t)[PageItemType.Columns],
                        color: "bg-blue-700",
                        hidden: isInstructionContent || isColumnContent,
                        onClick: () => {
                          const paragraph: Paragraph = {
                            id: uuid(),
                            data: [[{ text: "" }]],
                            align: "left",
                            type: PageItemType.Paragraph,
                            category: "content",
                          };
                          handleInsert<Columns>({
                            type: PageItemType.Columns,
                            category: "content",
                            items: [
                              {
                                id: uuid(),
                                data: [{ ...paragraph, id: uuid() }],
                              },
                              {
                                id: uuid(),
                                data: [{ ...paragraph, id: uuid() }],
                              },
                            ],
                          });
                          setIsOpen(false);
                        },
                      },
                    ]}
                    open={isOpen}
                  />
                )}
                {category === "poll" && !isInstructionContent && (
                  <FloatingList
                    items={[
                      {
                        key: PageItemType.MultipleChoice,
                        ...COURSE_ITEMS(t)[PageItemType.MultipleChoice],
                        color: "bg-blue-700",
                        onClick: () => {
                          handleInsert<MultipleChoicePoll>({
                            type: PageItemType.MultipleChoicePoll,
                            category: "poll",
                            multi: false,
                            instructions: [
                              {
                                id: uuid(),
                                type: PageItemType.Paragraph,
                                category: "content",
                                data: [[{ text: "", bold: true }]],
                                align: "left",
                              },
                            ],
                            items: [
                              { text: "1", id: uuid() },
                              { text: "2", id: uuid() },
                              { text: "3", id: uuid() },
                            ],
                          });
                          setIsOpen(false);
                        },
                      },
                      {
                        key: PageItemType.CommentPoll,
                        ...COURSE_ITEMS(t)[PageItemType.CommentPoll],
                        color: "bg-blue-700",
                        onClick: () => {
                          handleInsert<CommentPoll>({
                            type: PageItemType.CommentPoll,
                            category: "poll",
                            instructions: [
                              {
                                id: uuid(),
                                type: PageItemType.Paragraph,
                                category: "content",
                                data: [[{ text: "", bold: true }]],
                                align: "left",
                              },
                            ],
                          });
                          setIsOpen(false);
                        },
                      },
                    ]}
                    open={isOpen}
                  />
                )}
                {category === "quiz" && !isInstructionContent && (
                  <FloatingList
                    items={[
                      {
                        key: "explanation",
                        name: t("v4.item.explanation.text"),
                        description: t("v4.item.explanation.description"),
                        icon: TbBook2,
                        color: "bg-gray-600",
                        onClick: () => {
                          handleInsert<Explanation>({
                            type: PageItemType.Explanation,
                            category: "quiz",
                            instructions: [
                              {
                                id: uuid(),
                                type: PageItemType.Paragraph,
                                category: "content",
                                data: [[{ text: "" }]],
                                align: "left",
                              },
                            ],
                          });
                          setIsOpen(false);
                        },
                      },
                      {
                        key: PageItemType.ImageLabeling,
                        ...COURSE_ITEMS(t)[PageItemType.ImageLabeling],
                        color: "bg-red-600",
                        onClick: () => {
                          handleInsert<ImageLabeling>({
                            type: PageItemType.ImageLabeling,
                            category: "quiz",
                            instructions: [],
                            items: [],
                            size: 1,
                            showOptions: true,
                            consumptionType: ConsumptionType.multipleChoice,
                          });
                          setIsOpen(false);
                        },
                      },
                      {
                        key: PageItemType.FillTheGap,
                        ...COURSE_ITEMS(t)[PageItemType.FillTheGap],
                        color: "bg-red-600",
                        onClick: () => {
                          handleInsert<FillTheGap>({
                            type: PageItemType.FillTheGap,
                            category: "quiz",
                            items: {},
                            data: "",
                            instructions: [],
                            showOptions: true,
                            consumptionType:
                              ConsumptionType.multipleChoiceSemantic,
                          });
                          setIsOpen(false);
                        },
                      },
                      {
                        key: PageItemType.MultipleChoice,
                        ...COURSE_ITEMS(t)[PageItemType.MultipleChoice],
                        color: "bg-red-600",
                        onClick: () => {
                          handleInsert<MultipleChoice>({
                            type: PageItemType.MultipleChoice,
                            category: "quiz",
                            multi: false,
                            instructions: [
                              {
                                id: uuid(),
                                type: PageItemType.Paragraph,
                                category: "content",
                                data: [[{ text: "", bold: true }]],
                                align: "left",
                              },
                            ],
                            items: [
                              { text: "", id: uuid(), correct: true },
                              { text: "", id: uuid(), correct: false },
                              { text: "", id: uuid(), correct: false },
                            ],
                          });
                          setIsOpen(false);
                        },
                      },
                      {
                        key: PageItemType.Sorting,
                        ...COURSE_ITEMS(t)[PageItemType.Sorting],
                        color: "bg-red-600",
                        onClick: () => {
                          handleInsert<Sorting>({
                            type: PageItemType.Sorting,
                            category: "quiz",
                            items: [{ id: uuid(), text: "" }],
                            instructions: [],
                          });
                          setIsOpen(false);
                        },
                      },
                      {
                        key: PageItemType.Pairing,
                        ...COURSE_ITEMS(t)[PageItemType.Pairing],
                        color: "bg-red-600",
                        onClick: () => {
                          handleInsert<Pairing>({
                            type: PageItemType.Pairing,
                            category: "quiz",
                            items: [
                              [
                                { id: uuid(), text: "" },
                                { id: uuid(), text: "" },
                              ],
                            ],
                            instructions: [],
                          });
                          setIsOpen(false);
                        },
                      },
                      {
                        key: PageItemType.Categorisation,
                        ...COURSE_ITEMS(t)[PageItemType.Categorisation],
                        color: "bg-red-600",
                        onClick: () => {
                          handleInsert<Categorisation>({
                            type: PageItemType.Categorisation,
                            category: "quiz",
                            items: [
                              {
                                id: uuid(),
                                text: "",
                                items: [{ id: uuid(), text: "" }],
                              },
                            ],
                            instructions: [],
                          });
                          setIsOpen(false);
                        },
                      },
                      {
                        key: PageItemType.Crossword,
                        ...COURSE_ITEMS(t)[PageItemType.Crossword],
                        color: "bg-red-600",
                        onClick: () => {
                          handleInsert<Crossword>({
                            type: PageItemType.Crossword,
                            category: "quiz",
                            items: {},
                            instructions: [],
                          });
                          setIsOpen(false);
                        },
                      },
                      {
                        key: PageItemType.FlashcardsQuiz,
                        ...COURSE_ITEMS(t)[PageItemType.FlashcardsQuiz],
                        color: "bg-red-600",
                        onClick: () => {
                          handleInsert<FlashcardsQuiz>({
                            type: PageItemType.FlashcardsQuiz,
                            category: "quiz",
                            items: [
                              [
                                { id: uuid(), text: "" },
                                { id: uuid(), text: "" },
                              ],
                            ],
                            instructions: [],
                            consumptionType: ConsumptionType.multipleChoice,
                          });
                          setIsOpen(false);
                        },
                      },
                      // {
                      //   key: PageItemType.ShortAnswer,
                      //   hidden:
                      //     !!quizType && quizType !== PageItemType.ShortAnswer,
                      //   ...COURSE_ITEMS(t)[PageItemType.ShortAnswer],
                      //   color: "bg-red-600",
                      //   onClick: () => {
                      //     handleInsert<ShortAnswer>({
                      //       type: PageItemType.ShortAnswer,
                      //       category: "quiz",
                      //       consumptionType: ConsumptionType.typeTheAnswer,
                      //       instructions: [
                      //         {
                      //           id: uuid(),
                      //           type: PageItemType.Paragraph,
                      //           category: "content",
                      //           data: [[{ text: "", bold: true }]],
                      //           align: "left",
                      //         },
                      //       ],
                      //       items: [],
                      //     });
                      //     setIsOpen(false);
                      //   },
                      // },
                    ]}
                    open={isOpen}
                  />
                )}
                {category === "content" &&
                  !isInstructionContent &&
                  !isColumnContent && (
                    <FloatingList
                      items={[
                        {
                          key: PageItemType.Flashcards,
                          hidden: quizType !== PageItemType.Flashcards,
                          ...COURSE_ITEMS(t)[PageItemType.Flashcards],
                          color: "bg-blue-700",
                          onClick: () => {
                            handleInsert<Flashcards>({
                              type: PageItemType.Flashcards,
                              category: "content",
                              items: [
                                [
                                  { id: uuid(), text: "" },
                                  { id: uuid(), text: "" },
                                ],
                              ],
                            });
                            setIsOpen(false);
                          },
                        },
                      ]}
                      open={isOpen}
                    />
                  )}
              </>
            )}
          </FloatingMenu>
        </div>
        <div
          className={classNames(
            isDragActive ? "opacity-100" : "opacity-0",
            "transition max-w-2xl w-full h-full mx-auto relative py-6 flex flex-col pointer-events-none"
          )}
        >
          <div className="grow rounded-lg text-primary bg-gray-200 animate-pulse pointer-events-none top-0 flex items-center justify-center"></div>
        </div>
      </div>
    </>
  );
};

export const isEmptyTextItem = (item: Item) => {
  // if (item.type === PageItemType.Heading) return !item.data;
  // if (item.category !== "slide" && item.type === PageItemType.Paragraph) {
  //   return item.data.every((paragraph) => paragraph.every(({ text }) => !text));
  // }
  if (item.type === PageItemType.List || item.type === PageItemType.Paragraph) {
    return item.data.every((paragraph) =>
      paragraph.every((item) => {
        if ("text" in item) return !item.text;
        return item.children.every(({ text }) => !text);
      })
    );
  }
  return false;
};

const OpenMenuContext = CreateOptContext<React.MutableRefObject<() => void>>();

export const useOpenMenu = () => useProtectedContext(OpenMenuContext)?.current;

export const InsertMedia = ({ index, close }) => {
  const setList = useSetList();
  const isInstructionContent = useIsInstructionContent();

  const handleInsert = (media: MediaData | string) => {
    setList((list) => {
      const item: Video | Audio | Image | Paragraph =
        typeof media === "string"
          ? {
              id: uuid(),
              category: "content",
              type: PageItemType.Paragraph,
              align: "left",
              data: [[{ text: media, bold: isInstructionContent }]],
            }
          : {
              id: uuid(),
              category: "content",
              ...media,
            };
      list.splice(index, 0, item);
    });
    close();
  };

  return (
    <MediaPicker
      selectOnly={SelectOnlyType.media}
      close={close}
      onInsert={handleInsert}
    />
  );
};
