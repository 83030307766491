import React, { useCallback } from "react";
import { MSLogin } from "./MSLogin";
import { userSignInMS } from "../../store/authentication/authActions";
import urlParametersAsObj from "../../../helpers/urlParametersAsObj";
import { useDispatch } from "react-redux";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbBrandWindows } from "react-icons/tb";
import { useTranslation } from "react-i18next";

export const LoginMicrosoft = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const msalInstance = MSLogin();

  const loginRequest = {
    scopes: ["user.read"],
  };

  const handleLoginMicrosoft = useCallback(async (response) => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length < 0) {
      msalInstance.loginPopup({
        redirectUri: "/",
        scopes: ["user.read"],
      });
    }
    try {
      const loginResponse = await msalInstance.loginPopup(loginRequest);
      console.log(loginResponse);
      const res: any = await dispatch(
        userSignInMS({
          access_token: loginResponse.accessToken,
          context: urlParametersAsObj(),
        })
      );
      // if (userSignInMS.fulfilled.match(res)) {
      //   setRequestError(false);
      // } else {
      //   setRequestError(true);
      // }
    } catch (err) {
      // handle error
      console.log(err);
    }
  }, []);

  return (
    <NewButton
      size="lg"
      center
      onClick={handleLoginMicrosoft}
      variant="primary"
      color="bg-sky-400 text-white"
      className="whitespace-nowrap"
    >
      <TbBrandWindows /> {t("v4.account.continueMicrosoft")}
    </NewButton>
  );
};
