import { useCallback } from "react";
import { format, parseISO } from "date-fns";
import { de, enGB, fr } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

export const useDateFormat = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return useCallback(
    (string: string, dateFormat = "distance" as "distance" | string) => {
      const date = parseISO(string);
      const locale = getDateLocale(language);

      if (dateFormat === "distance")
        return formatDistanceToNow(date, { locale, addSuffix: true });

      return format(date, dateFormat, { locale });
    },
    [language]
  );
};

export const getDateLocale = (language: string) =>
  language === "en" ? enGB : language === "de" ? de : fr;
