import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import { FiMic } from "react-icons/fi";

export const VolumeMeter = ({
  mediaStream,
}: {
  mediaStream: MediaStream | null;
}) => {
  const [meter, setMeter] = useState(0);
  const animRef = useRef<number>();

  const processAudio = (analyserNode: AnalyserNode) => () => {
    const pcmData = new Float32Array(analyserNode.fftSize);
    analyserNode.getFloatTimeDomainData(pcmData);

    let sumSquares = pcmData.reduce((acc, a) => acc + a ** 2, 0);
    sumSquares = Math.sqrt(sumSquares / pcmData.length) / 0.15;
    sumSquares = Math.round(sumSquares * 100) / 100;
    setMeter(Math.min(sumSquares, 1));
    animRef.current = requestAnimationFrame(processAudio(analyserNode));
  };

  useEffect(() => {
    if (!mediaStream) return;

    const audioContext = new AudioContext();
    const mediaStreamAudioSourceNode =
      audioContext.createMediaStreamSource(mediaStream);
    const analyserNode = audioContext.createAnalyser();
    mediaStreamAudioSourceNode.connect(analyserNode);

    animRef.current = requestAnimationFrame(processAudio(analyserNode));
    return () => {
      animRef.current && cancelAnimationFrame(animRef.current);
    };
  }, [mediaStream]);

  // return <div>{meter}</div>;
  //
  return (
    <StyledVolumeMeterContainer>
      <div className="absolute-cover bg-primary" style={{ opacity: meter }} />
      <FiMic />
    </StyledVolumeMeterContainer>
  );
};

const StyledVolumeMeterContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid lightgray;
  box-shadow: 0 0.25rem 1rem 0 #0003;
  background-color: var(--dark-grey-background);
  border-radius: 50%;
  margin: 1rem;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  svg {
    position: relative;
  }
`;
