import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Editable, Slate } from "slate-react";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  ConsumptionType,
  FillTheGap,
  ImageLabeling,
  PageItemType,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import objectHash from "object-hash";
import {
  InstructionsRender,
  QuizDropzone,
  QuizInstructionsRender,
  QuizItemLabel,
} from "app/components/Exercises/CourseEdit/components/generate/QuizDropzone";
import { FillTheGapButton } from "app/components/Exercises/CourseEdit/components/ftg/FillTheGapButton";
import { createStore, useStore } from "zustand";
import classNames from "classnames";
import {
  TbAlertCircle,
  TbCheck,
  TbQuestionMark,
  TbSparkles,
  TbTag,
  TbTagOff,
  TbX,
} from "react-icons/tb";
import {
  useConsumptionContext,
  withConsumption,
} from "app/components/Exercises/CourseEdit/render/ConsumptionContext";
import {
  FillTheGapConsumption,
  ImageLabelingConsumption,
} from "app/components/Exercises/CourseEdit/render/courseConsumptionTypes";
import AutosizeInput from "react-input-autosize";
import {
  Descendant,
  gapParagraphsToSlate,
  PageBlock,
  placeholderRegex,
  useGapEditor,
} from "app/components/Exercises/CourseEdit/components/ftg/FillTheGapUtils";
import { FillTheGapResults } from "app/components/Exercises/CourseEdit/results/courseResultsTypes";
import { sticky } from "tippy.js";
import { Tooltip, TooltipRaw } from "app/components/Tooltip";
import { CorrectTooltip } from "app/components/Exercises/CourseEdit/results/PageResults";
import {
  CreateProtectedContext,
  useProtectedContext,
} from "app/hooks/useProtectedContext";
import {
  CorrectContext,
  useSuccessRate,
} from "app/components/Exercises/CourseEdit/results/PageCorrect";
import { AnimatePresence, motion } from "framer-motion";
import {
  DropMedia,
  DropMediaType,
} from "app/components/Sources/MediaPicker/context/dropMediaContext";
import { paragraphsToText } from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { useTranslation } from "react-i18next";
import { NewButton } from "app/components/Buttons/NewButton";
import arrayShuffle from "array-shuffle";
import { useMutation } from "react-query";
import { postFtgContent, postFtgGaps } from "api/course/courseContentAPI";
import { FloatingMenu } from "app/components/Header";
import { ActiveButton } from "app/components/Exercises/Edit/questionType/Slide/item/ItemText";
import { useRole } from "app/hooks/useRole";
import { SkeletonParagraph } from "app/components/Exercises/CourseEdit/components/SkeletonParagraph";
import { handleOpenAIError } from "app/components/Exercises/CourseEdit/items/generate/PageParagraphAutocomplete";
import { Collapse } from "app/components/Collapse";

export const LabelingAnswers = () => {
  const { t } = useTranslation();
  const [{ showOptions, consumptionType, items: items_, type }, set] =
    usePageItemContext<FillTheGap | ImageLabeling>();

  const items = useMemo<{ id: string; text: string }[]>(() => {
    if (type === PageItemType.ImageLabeling) return Object.values(items_);
    return Object.entries(items_).map(([id, { text }]) => ({ id, text }));
  }, [items_, type]);

  const isMcq =
    consumptionType === ConsumptionType.multipleChoice ||
    consumptionType === ConsumptionType.multipleChoiceSemantic ||
    consumptionType === ConsumptionType.multipleChoiceLanguage;

  return (
    <AnimatePresence initial>
      {!isMcq && showOptions && !!items?.length && (
        <motion.div
          className="bg-gray-100 rounded-lg flex flex-col group overflow-y-clip"
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
        >
          <div className="flex gap-4 items-center justify-between pr-1 pt-1">
            <span className="text-sm font-bold ml-2 text-gray-500 flex items-center gap-1">
              <TbTag className="text-lg" />
              {t("v4.quiz.answers.text")}
            </span>
            <NewButton
              variant="transparent"
              className="opacity-20 group-hover:opacity-100"
              iconOnly
              onClick={() => {
                set((item) => {
                  item.showOptions = false;
                });
              }}
            >
              <TbX className="!text-base" />
            </NewButton>
          </div>
          <div className="flex flex-wrap justify-center items-center gap-1 p-2 pt-1">
            {items.map(({ text, id }) => (
              <div
                key={id}
                className="bg-gray-300 text-sm px-1.5 leading-none inline-flex py-1 font-bold rounded-lg whitespace-nowrap"
              >
                {text}
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export const LabelingAnswersRender = ({
  answer,
  items: items_,
  showOptions,
  type,
  consumptionType,
}: Pick<
  ImageLabelingConsumption | FillTheGapConsumption,
  "answer" | "items" | "showOptions" | "type" | "consumptionType"
>) => {
  const { t } = useTranslation();
  const items = useMemo<{ id: string; text: string }[]>(() => {
    if (type === PageItemType.ImageLabeling)
      return arrayShuffle(Object.values(items_));
    return arrayShuffle(
      Object.entries(items_).map(([id, { text }]) => ({ id, text }))
    );
  }, [items_, type]);

  const answers = useMemo(() => {
    return new Set(Object.values(answer));
  }, [answer]);

  const isMcq =
    consumptionType === ConsumptionType.multipleChoice ||
    consumptionType === ConsumptionType.multipleChoiceSemantic ||
    consumptionType === ConsumptionType.multipleChoiceLanguage;

  if (isMcq || !showOptions || !items?.length) return null;

  return (
    <div className="bg-gray-100 rounded-lg flex flex-col group">
      <div className="flex gap-4 items-center justify-between pr-1 pt-1">
        <span className="text-sm font-bold ml-2 text-gray-500 flex items-center gap-1">
          <TbTag className="text-lg" />
          {t("v4.quiz.answers.text")}
        </span>
      </div>
      <div className="flex flex-wrap justify-center items-center gap-1 p-2 pt-1">
        {items.map(({ text, id }) => (
          <div
            key={id}
            className={classNames(
              "bg-gray-300 text-sm px-1.5 leading-none inline-flex py-1 font-bold rounded-lg whitespace-nowrap transition",
              answers.has(text) && "opacity-40 line-through"
            )}
          >
            {text}
          </div>
        ))}
      </div>
    </div>
  );
};

export const PageFillTheGapItem = () => {
  const { t } = useTranslation();
  const role = useRole();
  const [item, set] = usePageItemContext<FillTheGap>();
  const gapCount = useMemo(() => Object.keys(item.items).length, [item.items]);

  const storeRef = useRef<FTGStore>();
  if (!storeRef.current) {
    storeRef.current = createFTGStore();
  }

  const generateGapsMutation = useMutation(
    () => {
      const split = item.data
        .split(placeholderRegex)
        .map((value) => {
          if (!placeholderRegex.test(value)) return value;

          const itemId = value.slice(1, -1);
          const gap = item.items?.[itemId];
          if (!gap || (!gap?.generating && !gap?.options?.length)) return value;

          return gap.text;
        })
        .join("");
      return postFtgGaps(
        split,
        item.consumptionType === ConsumptionType.multipleChoiceLanguage
      );
    },
    {
      onSuccess: ({ items }) => {
        set((item) => {
          for (const id in items) {
            if (!item?.items?.[id]) continue;
            item.items[id].options = items[id]?.options ?? [];
          }
        });
      },
      onError: (e) => {
        handleOpenAIError(e, t, role);
      },
    }
  );

  const generateMutation = useMutation(
    () =>
      postFtgContent(
        item.data,
        item.consumptionType === ConsumptionType.multipleChoiceLanguage
      ),
    {
      onSuccess: ({ data, items }) => {
        set((item) => {
          item.data = data;
          item.items = Object.fromEntries(
            Object.entries(items).map(([key, item]) => [
              key,
              { ...item, hint: "" },
            ])
          );
        });
      },
      onError: (e) => {
        handleOpenAIError(e, t, role);
      },
    }
  );

  const isLoading =
    generateMutation.isLoading || generateGapsMutation.isLoading;

  const isMcq =
    item.consumptionType === ConsumptionType.multipleChoiceSemantic ||
    item.consumptionType === ConsumptionType.multipleChoiceLanguage;

  return (
    <PageItemWrapper
      toolbar={(trash) => (
        <div className="flex flex-col gap-1">
          {!isMcq && (
            <div className="flex justify-end gap-1">
              <Tooltip
                value={
                  item.showOptions
                    ? t("v4.quiz.answers.hide")
                    : t("v4.quiz.answers.show")
                }
              >
                <NewButton
                  iconOnly
                  variant="transparent"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    set((item) => {
                      item.showOptions = !item.showOptions;
                    });
                  }}
                  size="lg"
                >
                  {item.showOptions ? <TbTagOff /> : <TbTag />}
                </NewButton>
              </Tooltip>
            </div>
          )}
          {trash}
        </div>
      )}
    >
      <FTGStoreContext.Provider value={storeRef.current}>
        <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1 relative">
          <QuizItemLabel
            type={item.type}
            consumptionType={
              isLoading
                ? item.consumptionType
                : [
                    ConsumptionType.multipleChoiceSemantic,
                    ConsumptionType.multipleChoiceLanguage,
                    ConsumptionType.strictTypeTheAnswer,
                  ]
            }
          />
          <QuizDropzone />
          <LabelingAnswers />
          <div className="bg-white rounded-lg px-3 py-1.5 flex flex-col gap-1 relative">
            {isLoading ? (
              <div>
                <SkeletonParagraph min={20} max={30} />
              </div>
            ) : (
              <InnerFillTheGap />
            )}
          </div>

          <Collapse>
            {role.aiEnabled &&
              !isLoading &&
              isMcq &&
              !Object.values(item.items).find((item) => item.generating) &&
              !!Object.values(item.items).find(
                (item) => !item?.options?.length
              ) && (
                <div className="flex items-center gap-2 mt-1 px-1">
                  <NewButton
                    variant="primary"
                    className="text-sm"
                    size="sm"
                    onClick={generateGapsMutation.mutate}
                  >
                    <TbSparkles /> {t("v4.item.fillTheGap.generateOptions")}
                  </NewButton>
                  <span className="text-sm text-gray-500">
                    {t("v4.item.fillTheGap.generateOptionsText")}
                  </span>
                </div>
              )}
          </Collapse>

          <Collapse>
            {!gapCount && !isLoading && (
              <div className="flex items-center gap-2 mt-1 px-1">
                {role.aiEnabled ? (
                  <>
                    <Tooltip
                      value={t("v4.generate.prompt.minLength", { number: 40 })}
                      disabled={item.data.length >= 40}
                    >
                      <NewButton
                        variant="primary"
                        className="text-sm"
                        disabled={item.data.length < 40}
                        size="sm"
                        onClick={generateMutation.mutate}
                      >
                        <TbSparkles /> {t("v4.item.fillTheGap.generateGaps")}
                      </NewButton>
                    </Tooltip>
                    <span className="text-sm text-gray-500">
                      {t("v4.item.fillTheGap.generateGapsText")}
                    </span>
                  </>
                ) : (
                  <span className="text-sm text-gray-500">
                    {t("v4.item.fillTheGap.gapPrompt")}
                  </span>
                )}
              </div>
            )}
          </Collapse>

          {/*<div*/}
          {/*  className={classNames(*/}
          {/*    "absolute-cover rounded-lg bg-gray-200 bg-opacity-80 flex transition",*/}
          {/*    !generateMutation.isLoading && "opacity-0 pointer-events-none"*/}
          {/*  )}*/}
          {/*>*/}
          {/*  <CgSpinner className="text-4xl animate-spin m-auto" />*/}
          {/*</div>*/}
        </div>
      </FTGStoreContext.Provider>
    </PageItemWrapper>
  );
};

type FTGState = {
  selected: string | null;
  select: (id: string | null) => void;
};

const createFTGStore = () =>
  createStore<FTGState>((set) => ({
    selected: null,
    select: (id) => set({ selected: id }),
  }));

type FTGStore = ReturnType<typeof createFTGStore>;

export const FTGStoreContext = CreateProtectedContext<FTGStore>();

export const useFTGContext = <T,>(
  selector: (state: FTGState) => T,
  equalityFn?: (left: T, right: T) => boolean
): T => {
  const storeRef = useProtectedContext(FTGStoreContext);
  return useStore(storeRef, selector, equalityFn);
};

const InnerFillTheGap = () => {
  const [item, set] = usePageItemContext<FillTheGap>();
  const selected = useFTGContext((store) => store.selected);
  const select = useFTGContext((store) => store.select);
  const { t } = useTranslation();

  const editor = useGapEditor();

  const text = useMemo(() => {
    return gapParagraphsToSlate(item.data, item.items) as any;
  }, [item.data, objectHash(item.items)]);

  const isMcq =
    item.consumptionType === ConsumptionType.multipleChoiceSemantic ||
    item.consumptionType === ConsumptionType.multipleChoiceLanguage;

  const renderBlock = useCallback(
    (props) => <PageBlock {...props} isMcq={isMcq} />,
    [isMcq]
  );

  useEffect(() => {
    editor.children = text;
  }, []);

  const handleTextChange = (text: Descendant[]) => {
    if (
      !editor?.operations ||
      !editor.selection ||
      (editor.operations.length === 1 &&
        editor.operations[0]?.type === "set_selection")
    )
      return;

    let isSelectedInEdit = false;
    const items: FillTheGap["items"] = {};

    const currentText = text
      .map((paragraph) => {
        if ("text" in paragraph) return paragraph.text;
        return (
          // for each child within a paragraph
          paragraph.children
            .map((item: Descendant) => {
              if ("text" in item) return item.text;
              if (item.type === "gap") {
                if (item.id === selected) isSelectedInEdit = true;

                items[item.id] = {
                  text: item.data,
                  hint: item.hint,
                  options: item?.options,
                  generating: item?.generating,
                };
                return `[${item.id}]`;
              }
            })
            .join("")
        );
      })
      .join("\n");

    if (!isSelectedInEdit) select(null);
    set((item) => {
      item.data = currentText;
      item.items = items;
    });
  };

  const drop = (media: DropMediaType) => {
    const setData = (appendData: string) => {
      set((item) => {
        if (item.data.trim()) item.data += "\n" + appendData;
        else item.data = appendData;

        editor.children = gapParagraphsToSlate(item.data, item.items);
      });
    };

    switch (media.type) {
      case PageItemType.Paragraph:
      case PageItemType.List:
        return () => {
          setData(paragraphsToText(media.data));
        };
    }
  };

  return (
    <Slate editor={editor} value={text} onChange={handleTextChange}>
      <FillTheGapButton />
      <Editable
        renderElement={renderBlock}
        spellCheck={false}
        className="leading-relaxed cursor-text"
        placeholder={t("v4.generic.typingPlaceholder")}
      />
      <DropMedia onDrop={drop} />
    </Slate>
  );
};

export const useInputFocus = <T extends HTMLInputElement | HTMLTextAreaElement>(
  isFocused: boolean
) => {
  const ref = useRef<T>(null);
  useEffect(() => {
    if (isFocused) {
      if (!ref.current) return;
      ref.current.focus();
    }
  }, [isFocused]);
  return ref;
};

const FillTheGapRenderItem = ({
  id,
  quizItemId,
  onChange,
  active,
  setActive,
  setNextActive,
  useOptions,
}) => {
  const text = useConsumptionContext(
    (store) => (store.items[quizItemId] as FillTheGapConsumption).answer[id]
  );
  const hint = useConsumptionContext(
    (store) =>
      (store.items[quizItemId] as FillTheGapConsumption).items[id]?.hint
  );
  const options = useConsumptionContext(
    (store) =>
      (store.items[quizItemId] as FillTheGapConsumption).items[id]?.options
  );
  const ref = useInputFocus(active);

  return (
    <TooltipRaw
      zIndex={100000}
      plugins={[sticky]}
      sticky="reference"
      appendTo={document.body}
      visible={!!hint && active}
      disabled={!!useOptions}
      content={
        <div className="bg-white border border-gray-100 rounded-lg flex shadow text-gray-600 py-1.5 px-3 items-center gap-1.5">
          <TbAlertCircle className="text-lg shrink-0" />
          <div className="max-w-md">{hint}</div>
        </div>
      }
    >
      <label
        className={classNames(
          text ? "bg-gray-200" : "bg-gray-500",
          "transition focus-within:bg-primary focus-within:bg-opacity-50 rounded inline-block relative group min-w-[3em] min-h-[1.25em]"
        )}
      >
        {useOptions ? (
          <>
            <FloatingMenu
              size="xs"
              portal
              placement="bottom-start"
              containerClassName="absolute-cover [&>div]:absolute-cover !absolute"
              trigger={(trigger) => (
                <div
                  className="absolute-cover cursor-pointer"
                  onClick={trigger}
                />
              )}
            >
              {({ setIsOpen }) => (
                <>
                  {hint && (
                    <div className="bg-gray-100 rounded-lg flex text-gray-600 py-1 px-2 items-center gap-1.5 mb-1">
                      <TbAlertCircle className="text-base shrink-0" />
                      <div className="max-w-md text-sm">{hint}</div>
                    </div>
                  )}
                  {options?.map((option, i) => (
                    <ActiveButton
                      key={`${option}-${i}`}
                      size="md"
                      isActive={text === option}
                      onClick={() => {
                        onChange(option);
                        setIsOpen(false);
                      }}
                    >
                      {option}
                    </ActiveButton>
                  ))}
                </>
              )}
            </FloatingMenu>
            <div
              className={classNames(
                "text-center px-1.5 pointer-events-none",
                !text && "opacity-0"
              )}
            >
              {text || "a"}
            </div>
          </>
        ) : (
          <AutosizeInput
            ref={ref}
            value={text || ""}
            inputClassName="bg-transparent outline-0 px-1 leading-none py-0.5 text-center"
            onChange={(e) => onChange(e.target.value.substring(0, 25))}
            minWidth={48}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setNextActive();
              }
            }}
          />
        )}
        <TbQuestionMark
          className={classNames(
            "w-[90%] h-[90%] top-[5%] left-[5%] absolute object-contain transition pointer-events-none text-white",
            text ? "opacity-0" : "group-focus-within:opacity-0"
          )}
        />
      </label>
    </TooltipRaw>
  );
};

export const PageFillTheGapRender = withConsumption<FillTheGapConsumption>(
  ({ data, items, set, id, answer, consumptionType, type, showOptions }) => {
    const handleChange = useCallback(
      (id: string) => (value: string) => {
        set((item) => {
          item.answer[id] = value;
        });
      },
      []
    );

    const [selected, setSelected] = useState<number | null>(null);

    const split = useMemo(() => {
      const split = data.split(placeholderRegex);

      return split.map((value) => {
        if (!placeholderRegex.test(value)) return value;

        const itemId = value.slice(1, -1);
        const item = items?.[itemId];
        if (!item) return value;

        return { id: itemId };
      });
    }, [data, items]);

    const filtered = split.filter((item) => typeof item !== "string") as {
      id: string;
    }[];

    const setActive = useCallback(
      (i: number) => (value: boolean) => setSelected(value ? i : null),
      []
    );
    const setNextActive = useCallback(
      (i: number) => () => {
        const nextAfter = filtered.findIndex(
          ({ id }, index) => index > i && !answer?.[id]
        );
        if (nextAfter > -1) return setSelected(nextAfter);

        const nextBefore = filtered.findIndex(
          ({ id }, index) => index < i && !answer?.[id]
        );
        if (nextBefore > -1) return setSelected(nextBefore);
      },
      [filtered, answer]
    );

    const isMcq =
      consumptionType === ConsumptionType.multipleChoiceSemantic ||
      consumptionType === ConsumptionType.multipleChoiceLanguage;

    return (
      <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
        <QuizItemLabel type={type} consumptionType={consumptionType} />
        <QuizInstructionsRender id={id} />
        <LabelingAnswersRender
          {...{ answer, type, items, showOptions, consumptionType }}
        />
        <div className="bg-white rounded-lg px-3 py-1.5 flex items-center">
          <div className="whitespace-pre-line">
            {split.map((item, i) => {
              if (typeof item === "string") return <span key={i}>{item}</span>;

              const index = filtered.findIndex(({ id }) => item.id === id);
              return (
                <FillTheGapRenderItem
                  onChange={handleChange(item.id)}
                  id={item.id}
                  key={item.id}
                  quizItemId={id}
                  active={selected === index}
                  setActive={setActive(index)}
                  setNextActive={setNextActive(index)}
                  useOptions={isMcq}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);

export const PageFillTheGapResults = ({
  item,
}: {
  item: FillTheGapResults;
}) => {
  const { data, result, answer, items } = item;
  const split = useMemo(() => {
    const split = data.split(placeholderRegex);

    return split.map((value, i) => {
      if (!placeholderRegex.test(value)) return <span key={i}>{value}</span>;

      const id = value.slice(1, -1);
      const item = answer?.[id];
      if (item == null) return <span key={i}>{value}</span>;
      const correct = !!result?.[id];

      const content = (
        <span
          key={id}
          className={classNames(
            "px-1 leading-none transform translate-y-0.5 py-0.5 gap-1 bg-opacity-30 inline-flex font-bold rounded min-w-[3rem] items-center",
            correct ? "bg-green-500 text-green-700" : "bg-red-500 text-red-700"
          )}
        >
          {correct ? (
            <TbCheck className="text-lg" strokeWidth={3} />
          ) : (
            <TbX className="text-lg" strokeWidth={3} />
          )}
          {item}
        </span>
      );

      const correctAnswer = items?.[id]?.text;
      if (!correct && correctAnswer != null)
        return (
          <CorrectTooltip content={correctAnswer} key={id}>
            {content}
          </CorrectTooltip>
        );
      return content;
    });
  }, [data, result, answer, items]);

  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <InstructionsRender instructions={item.instructions} />
      <div className="bg-white rounded-lg px-3 py-1.5 flex items-center">
        <div className="whitespace-pre-line">{split}</div>
      </div>
    </div>
  );
};

export const PageFillTheGapCorrect = ({ item }: { item: FillTheGap }) => {
  const successRate = useSuccessRate<Record<string, number>>([item.id]);
  const { data, items } = item;
  const correct = useProtectedContext(CorrectContext);

  const split = useMemo(() => {
    const split = data.split(placeholderRegex);

    return split.map((value, i) => {
      if (!placeholderRegex.test(value)) return <span key={i}>{value}</span>;

      const id = value.slice(1, -1);
      const item = items?.[id];
      if (item == null) return <span key={i}>{value}</span>;

      return (
        <span className="min-h-[1.25em] inline-flex font-bold">
          {successRate?.[id] != null && (
            <div className="text-sm font-bold pl-1 pr-2 text-primary inline-block shrink-0 bg-primary bg-opacity-10 border-l-2 border-primary rounded-l -mr-1">
              {Math.round(successRate[id] * 100)}
              <span className="text-xs">%</span>
            </div>
          )}
          <div className="px-1 leading-none py-0.5 min-w-[2rem] text-center grow bg-gray-200 rounded relative">
            {item.text || (
              <span className="opacity-0 pointer-events-none select-none">
                l
              </span>
            )}
          </div>
        </span>
      );
    });
  }, [data, items, correct]);

  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <InstructionsRender instructions={item.instructions} />
      <div className="bg-white rounded-lg px-3 py-1.5 flex items-center">
        <div className="whitespace-pre-line">{split}</div>
      </div>
    </div>
  );
};
