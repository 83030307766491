import React, {
  ComponentProps,
  ElementType,
  HTMLAttributes,
  ReactNode,
} from "react";
import classNames from "classnames";

const VARIANTS = ["transparent", "light", "primary", "strongLight"] as const;

export const NewButton = <T extends ElementType>({
  children,
  size = "md",
  variant = "light",
  iconOnly = false,
  component: Component = "a",
  className = "",
  color: color_,
  center,
  disabled,
  ...props
}: {
  children: ReactNode;
  size?: "sm" | "md" | "lg";
  variant?: typeof VARIANTS[number] | string;
  iconOnly?: boolean;
  component?: T;
  color?: string;
  className?: HTMLAttributes<"div">["className"];
  center?: boolean;
  disabled?: boolean;
} & ComponentProps<T>) => {
  const color =
    color_ ??
    (variant === "primary"
      ? "bg-primary text-white"
      : "bg-gray-500 text-gray-500");
  return (
    <Component
      className={classNames(
        "rounded-lg transition flex items-center gap-2 [&>svg:first-of-type]:flex-shrink-0 [&>svg:first-of-type]:text-xl cursor-pointer select-none",
        center && "text-center justify-center",
        color,
        {
          "bg-opacity-10 hover:bg-opacity-20": variant === "light",
          "bg-opacity-25 hover:bg-opacity-40": variant === "strongLight",
          "bg-opacity-0 hover:bg-opacity-10": variant === "transparent",
          "bg-opacity-0 hover:bg-opacity-30": variant === "strongTransparent",
          "bg-opacity-100 hover:bg-opacity-80 font-bold": variant === "primary",
        },
        !!variant && !VARIANTS.includes(variant) && variant,
        disabled && "opacity-50 !pointer-events-none",
        iconOnly
          ? {
              "p-1": size === "sm",
              "p-1.5": size === "md",
              "p-2": size === "lg",
            }
          : {
              "px-2 py-1": size === "sm",
              "px-3 py-1.5": size === "md",
              "px-4 py-2": size === "lg",
            },
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
};
