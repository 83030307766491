import React from "react";
import styled from "styled-components";
import ErrorBoundary from "../../components/Helpers/ErrorBoundary";
import { useTranslation } from "react-i18next";
import ResetPasswordForm from "../../components/Forms/ResetPasswordForm";

const ScTitle = styled.h2`
  text-align: center;
`;

const ForgotPassword: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <section>
      <ScTitle>{t("authPages.register.sendResetPasswordBtn.label")}</ScTitle>
      {/* <h3>{t("authPage.login_top.form_subtitle")}</h3> */}
      <ErrorBoundary>
        <ResetPasswordForm />
      </ErrorBoundary>
    </section>
  );
};

export default ForgotPassword;
