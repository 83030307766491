import {
  CreateProtectedContext,
  useProtectedContext,
} from "app/hooks/useProtectedContext";

export const SlideItemContext = CreateProtectedContext<{
  selected: boolean;
  select: (value: string | null) => void;
}>();

export const useSlideItemContext = () => useProtectedContext(SlideItemContext);
