import React, { useState } from "react";
import { youtubeId } from "helpers/extractYoutubeVideoId";
import { InputText } from "app/components/Buttons/InputText";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
import { TbCheck, TbPencil } from "react-icons/tb";
import { NewButton } from "app/components/Buttons/NewButton";
import { usePageItemContext } from "app/components/Exercises/CourseEdit/PageStoreContext";
import { Embed } from "app/components/Exercises/CourseEdit/courseEditTypes";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";

export const PageEmbedItem = () => {
  const { t } = useTranslation();
  const [draftSrc, setDraftSrc] = useState("");
  const [item, set] = usePageItemContext<Embed>();

  return (
    <PageItemWrapper
      toolbar={(trash) => (
        <div className="flex gap-1">
          {!!item.data && (
            <NewButton
              iconOnly
              variant="transparent"
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => {
                set((item) => {
                  item.data = "";
                });
                setDraftSrc(item.data);
              }}
              size="lg"
            >
              <TbPencil />
            </NewButton>
          )}
          {trash}
        </div>
      )}
    >
      {!item.data ? (
        <form
          className="aspect-video bg-gray-200 rounded-xl p-4 flex flex-col items-center justify-center gap-2"
          onSubmit={(e) => {
            e.preventDefault();
            set((item) => {
              item.data = draftSrc;
            });
          }}
        >
          <YouTube
            videoId={youtubeId(draftSrc)}
            className="mx-auto rounded-lg w-full h-full"
            containerClassName="w-full aspect-video"
          />
          <InputText
            value={draftSrc}
            onChange={setDraftSrc}
            placeholder={t("media.youtube.link")}
            className="!flex-grow-0 w-full max-w-md mt-6"
          />
          <NewButton
            component="button"
            type="submit"
            variant="primary"
            size="lg"
          >
            <TbCheck /> {t("v4.generic.confirm")}
          </NewButton>
        </form>
      ) : (
        <YouTube
          videoId={youtubeId(item.data)}
          className="mx-auto rounded-lg w-full h-full"
          containerClassName="w-full aspect-video"
        />
      )}
    </PageItemWrapper>
  );
};

export const PageEmbedRender = ({ item }: { item: Embed }) => (
  <YouTube
    videoId={youtubeId(item.data)}
    className="mx-auto rounded-lg w-full h-full"
    containerClassName="w-full aspect-video"
  />
);
export const youtubeThumbnail = ({
  url,
  id,
}: { url: string; id?: never } | { url?: never; id: string }) => {
  return `https://i1.ytimg.com/vi/${id ? id : youtubeId(url)}/hqdefault.jpg`;
};
