import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ISearchPhrase } from "models/api/request.interface";

interface ISorting {
  id: string;
  desc: boolean;
}

interface MyGroupsState {
  sorting: Array<ISorting>;
  search: ISearchPhrase;
}

const initialState: MyGroupsState = {
  search: {
    phrase: "",
  },
  sorting: [
    {
      id: "",
      desc: false,
    },
  ],
};

export const slice = createSlice({
  name: "exerciseUserLibrary",
  initialState,
  reducers: {
    setSorting: (state, action: PayloadAction<Array<ISorting>>) => {
      state.sorting = action.payload;
    },
    setSearchPhase: (state, action: PayloadAction<ISearchPhrase>) => {
      state.search = action.payload;
    },
  },
});

export const { setSorting, setSearchPhase } = slice.actions;

export const selectExerciseUserLibrary = (state: RootState) =>
  state.exerciseUserLibrary;

export const selectSearchPhase = (state: RootState) =>
  state.exerciseUserLibrary.search.phrase;

export default slice.reducer;
