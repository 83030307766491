import { apiUrlV1 } from "enums/constant";
import axios from "axios";
import { ItemResults } from "app/components/Exercises/CourseEdit/results/courseResultsTypes";
import { ItemConsumption } from "app/components/Exercises/CourseEdit/render/courseConsumptionTypes";
import { Page } from "app/components/Exercises/CourseEdit/courseEditTypes";

export async function postValidateCourse(
  pageId: string,
  items: Record<string, ItemConsumption>
) {
  const url = `${apiUrlV1}/courses/page/calculate_save_results/`;
  const res = await axios.post<{
    percentage: number;
    next_page_id: string;
    result_id: string;
  }>(url, {
    page_id: pageId,
    items,
  });
  return res.data;
}
export async function postValidateGuestCourse(
  guestPin: string,
  pageId: string,
  items: Record<string, ItemConsumption>
) {
  const url = `${apiUrlV1}/guest/${guestPin}/courses/page/calculate_save_results/`;
  const res = await axios.post<{
    percentage: number;
    next_page_id: string;
    result_id: string;
  }>(url, {
    page_id: pageId,
    items,
  });
  return res.data;
}

export async function getActivityResults(id: string, activityId: string) {
  const url = `${apiUrlV1}/courses/results/${id}/?activity_id=${activityId}`;
  const { data } = await axios.get(url);
  return data;
}

export async function getCourseResult(resultId: string) {
  const url = `${apiUrlV1}/courses/page/results/${resultId}/`;
  const { data } = await axios.get<{
    results: Record<string, ItemResults>;
    percentage: number;
    page: Page;
  }>(url);
  return data;
}

export async function getCoursePollingResult(
  activityId: string,
  pageId: string
) {
  const url = `${apiUrlV1}/get_polling_results/${activityId}/${pageId}/`;
  const { data } = await axios.get<any>(url);
  return data;
}

export async function postCloseAssignment(assignments: string[]) {
  const url = `${apiUrlV1}/courses/close_assignments/`;
  const { data } = await axios.post(url, { assignments });
  return data;
}

export async function postOpenAssignment(assignments: string[]) {
  const url = `${apiUrlV1}/courses/open_assignments/`;
  const { data } = await axios.post(url, { assignments });
  return data;
}

export async function postRemoveAssignment(assignments: string[]) {
  const url = `${apiUrlV1}/courses/remove_assignments/`;
  const { data } = await axios.post(url, { assignments });
  return data;
}

export async function getCoursePageResult(pageId: string) {
  const url = `${apiUrlV1}/courses/page/recent_results/${pageId}/`;
  const { data } = await axios.get<{
    percentage?: number;
    results?: any;
    page: any;
  }>(url);
  return data;
}
export async function getCourseGuestPageResult(
  guestPin: string,
  pageId: string
) {
  const url = `${apiUrlV1}/guest/${guestPin}/courses/page/recent_results/${pageId}/`;
  const { data } = await axios.get<{
    percentage?: number;
    results?: any;
    page: any;
  }>(url);
  return data;
}

export async function getExportResults(courseId: string, activityId: string) {
  const url = `${apiUrlV1}/courses/export_results/${courseId}/?activity_id=${activityId}`;
  const { data } = await axios.get<any>(url, { responseType: "blob" });
  return data;
}
