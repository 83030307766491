import React, { useEffect } from "react";

export function useDetectKeyPress(key: string, callback, deps: any[] = []) {
  const detectClick = (event: KeyboardEvent) => {
    if (event.key === key) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", detectClick, false);

    return () => {
      document.removeEventListener("keydown", detectClick, false);
    };
  }, deps);
}
