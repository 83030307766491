import { useEffect } from "react";
import { create } from "zustand";
import { combine } from "zustand/middleware";
import { usePageItemId } from "app/components/Exercises/CourseEdit/PageStoreContext";

export type PageFocus = null | {
  id: string;
  distance: number;
  reverse: boolean;
};

export const usePageFocusContext = create(
  combine({ focus: null as PageFocus }, (set) => ({
    clear: () => set({ focus: null }),
    select: (id: string, distance = 0, reverse = false) =>
      set({ focus: { id, distance, reverse } }),
  }))
);

export const usePageItemFocus = (
  focusFn: (pos: number, end: boolean, focus: PageFocus) => void
) => {
  const id = usePageItemId();
  return useItemFocus(id, focusFn);
};

export const useItemFocus = (
  id: string,
  focusFn: (pos: number, end: boolean, focus: PageFocus) => void
) => {
  const { focus, select, clear } = usePageFocusContext();

  useEffect(() => {
    if (!focus || focus.id !== id) return;
    // if (!focus.id.startsWith(id)) return;

    focusFn(focus.distance, focus.reverse, focus);
    clear();
  }, [focus?.id, focus?.distance, !!focus?.reverse]);

  return select;
};
