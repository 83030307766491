import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import icLoupe from "../../assets/images/loupe.svg";
import icClose from "../../assets/images/menu-close.png";
import { AppDispatch } from "../store";
import {
  selectSearchPhase,
  setSearchPhase,
} from "../store/exercise-library-user";

const ScSearchBar = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  height: 40px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-right: auto;
  width: 100%;
  max-width: 370px;
  margin-left: 15px;
  //margin-right: 30px;
`;

const ScInput = styled.input`
  border: solid 1px #d0d0d0;
  border-radius: 8px;
  font-size: 16px;
  padding-left: 30px;
  width: 100%;
  &::placeholder {
    color: #a0a0a0;
    font-size: 16px;
  }
`;

const ScImageLoupe = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 5px;
`;

const ScImageClose = styled.img`
  width: 38px;
  height: 38px;
`;

const ScCloseButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -10px;
  margin: auto 5px;
  background-color: transparent;
`;

const SearchBar: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const searchPhase = useSelector(selectSearchPhase);

  const callbackKeyboardEvents = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        dispatch(setSearchPhase({ phrase: "" }));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", callbackKeyboardEvents, false);

    return () => {
      document.removeEventListener("keydown", callbackKeyboardEvents, false);
    };
  }, [callbackKeyboardEvents]);

  return (
    <ScSearchBar>
      <ScImageLoupe src={icLoupe} alt="icon-loupe" />
      <ScInput
        ref={inputRef}
        value={searchPhase}
        onChange={(e) =>
          dispatch(setSearchPhase({ phrase: e.target.value.substring(0, 50) }))
        }
        placeholder={t(`v4.generic.search`)}
      />
      {searchPhase && (
        <ScCloseButton
          type="button"
          onClick={() => dispatch(setSearchPhase({ phrase: "" }))}
        >
          <ScImageClose src={icClose} alt="icon-close" />
        </ScCloseButton>
      )}
    </ScSearchBar>
  );
};

export default SearchBar;
