import React from "react";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import { withConsumption } from "app/components/Exercises/CourseEdit/render/ConsumptionContext";
import { TbMessageCircle2 } from "react-icons/tb";
import {
  InstructionsRender,
  QuizDropzone,
  QuizInstructionsRender,
  QuizItemLabelRaw,
} from "app/components/Exercises/CourseEdit/components/generate/QuizDropzone";
import { useTranslation } from "react-i18next";
import { CommentPollConsumption } from "../../render/courseConsumptionTypes";
import { CommentPollResults } from "../../results/courseResultsTypes";
import { CommentPoll, PollItem } from "../../courseEditTypes";
import TextareaAutosize from "react-textarea-autosize";
import { useSessionContext } from "../../../../../hooks/useSessionContext";

export const PollCommentItem = () => {
  const { t } = useTranslation();

  return (
    <PageItemWrapper>
      <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
        <QuizItemLabelRaw
          icon={TbMessageCircle2}
          name={t("v4.item.comment.text")}
        />
        <QuizDropzone />
      </div>
    </PageItemWrapper>
  );
};

export const PollCommentRender = withConsumption<CommentPollConsumption>(
  ({ answer, set, id, type }) => {
    const { t } = useTranslation();

    return (
      <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
        <QuizItemLabelRaw
          name={t("v4.item.comment.text")}
          icon={TbMessageCircle2}
        />
        <QuizInstructionsRender id={id} />
        <div className="bg-white rounded-lg p-1.5 flex items-center">
          {/*<TbMessageCircle2 className="text-2xl text-gray-500 ml-0.5" />*/}
          <div className="flex flex-col grow">
            <TextareaAutosize
              value={answer || ""}
              onChange={(e) => {
                set((item) => {
                  item.answer = e.target.value;
                });
              }}
              placeholder={t("v4.item.shortAnswer.consumptionPlaceholder")}
              className="outline-none px-2 py-1 w-full flex-grow resize-none overflow-hidden m-0 min-h-full"
            />
          </div>
        </div>
      </div>
    );
  }
);

export const PollCommentResults = ({ item }: { item: CommentPollResults }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <InstructionsRender instructions={item.instructions} />
      <div className="bg-white rounded-lg p-1.5 flex items-center grow">
        <TextareaAutosize
          value={item.answer || ""}
          readOnly
          className="outline-none px-2 py-1 w-full flex-grow resize-none overflow-hidden m-0 min-h-full"
        />
      </div>
    </div>
  );
};

export const usePollResults = <T = Record<string, never>,>(item: PollItem) => {
  if ("results" in (item as any)) return (item as any).results as T;
  const { session } = useSessionContext();
  return session.get.status?.polling_results?.[item.id] as T;
};

export const PollCommentTeacher = ({ item }: { item: CommentPoll }) => {
  const { t } = useTranslation();
  const results = usePollResults<{ id: string; value: string }[]>(item);

  return (
    <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
      <InstructionsRender instructions={item.instructions} />
      {results &&
        Array.isArray(results) &&
        results.map(({ id, value }) => (
          <div
            key={id}
            className="bg-white rounded-lg px-3 py-2 flex items-center grow"
            style={{ wordBreak: "break-word" }}
          >
            {value}
          </div>
        ))}
    </div>
  );
};
