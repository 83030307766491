import React, { FC, ReactNode } from "react";
import { Redirect, Route, RouteProps, Switch, useLocation } from "react-router";
import { authRoutes, publicRoutes } from "../../../enums/routes";
import { Layout } from "../../pages/Layout";
import { useRole } from "app/hooks/useRole";

export const AppRoute = ({
  layout: Layout_ = Layout,
  noLayout,
  ...props
}: RouteProps &
  (
    | { layout?: FC<{ children: ReactNode }>; noLayout?: never }
    | { layout?: never; noLayout: true }
  )) => {
  if (noLayout) return <Route {...props} />;

  return (
    <Layout_>
      <Route {...props} />
    </Layout_>
  );
};

export const ProtectedRoute = ({
  layout,
  noLayout,
  isAdmin = false,
  ...props
}: {
  isAdmin?: boolean;
} & RouteProps &
  (
    | { layout?: FC<{ children: ReactNode }>; noLayout?: never }
    | { layout?: never; noLayout: true }
  )) => {
  const role = useRole();
  const location = useLocation();

  if (role.guest) {
    return (
      <Redirect
        to={{
          pathname: authRoutes.login,
          state: {
            requestedPath: location.pathname + location.search,
            requestedState: location?.state,
          },
        }}
      />
    );
  }

  if (isAdmin && !role.superuser) {
    return <Redirect to={publicRoutes.not_found} />;
  }

  if (noLayout) return <AppRoute noLayout {...props} />;
  return <AppRoute layout={layout} {...props} />;
};

export const AppSwitch = ({ children }: { children?: ReactNode }) => (
  <Switch>
    {children}
    <Redirect to={publicRoutes.not_found} />
  </Switch>
);
