import React, { useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { useSessionContext } from "app/hooks/useSessionContext";
import useInterval from "helpers/hooks/useInterval";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbCircleCheck, TbLink } from "react-icons/tb";
import { toast } from "react-hot-toast";
import classNames from "classnames";

const ShareInfo = ({ isBig: defaultIsBig = false }) => {
  const { t } = useTranslation();
  const [copy, setCopied] = useState(false);
  const { session } = useSessionContext();
  const { pin } = session.get.status;
  const [isBig, setIsBig] = useState(defaultIsBig);

  const link = `${window.location.origin}/session/${pin}`;

  useInterval(() => setCopied(false), copy ? 5000 : null);

  return (
    <div className="flex flex-col lg:flex-row items-center justify-center grow">
      <div className="flex items-center grow">
        <div className="flex flex-col mr-auto p-6 items-start">
          <div className="text-sm font-bold mb-1">
            {t("myExercisesPage.share.rows.url")}
          </div>
          <CopyToClipboard
            text={link}
            onCopy={() => {
              setCopied(true);
              toast.success(t("common.linkCopy.copied"), {
                position: "top-center",
              });
            }}
          >
            <NewButton
              color="bg-primary text-primary"
              size="sm"
              className="mt-1"
            >
              {copy ? <TbCircleCheck /> : <TbLink />}
              {copy ? t("common.linkCopy.copied") : t("common.linkCopy.copy")}
            </NewButton>
          </CopyToClipboard>
        </div>
        <div className="flex flex-col mr-auto grow p-6 items-start">
          <div className="text-sm font-bold">{t("groupsPage.labels.PIN")}</div>
          <CopyToClipboard
            text={pin}
            onCopy={() =>
              toast.success(t("groupsPage.labels.copiedPIN"), {
                position: "top-center",
              })
            }
          >
            <div
              className={classNames(
                "font-bold text-4xl text-primary cursor-pointer bg-primary bg-opacity-0 hover:bg-opacity-20 transition py-1 px-2 -mx-2 -mb-1 rounded-xl"
              )}
            >
              {pin}
            </div>
          </CopyToClipboard>
        </div>
      </div>
      <div onClick={() => setIsBig(!isBig)} className="cursor-pointer p-2">
        <QRCode value={link} size={isBig ? 250 : 150} />
      </div>
    </div>
  );
};

export default ShareInfo;
