import {
  CreateProtectedContext,
  useProtectedContext,
} from "app/hooks/useProtectedContext";
import { SelectOnlyType } from "app/components/Sources/MediaPicker/MediaPicker";

export const SelectOnlyContext =
  CreateProtectedContext<SelectOnlyType | null>();

export const useSelectOnly = () => useProtectedContext(SelectOnlyContext);
