import React, { useState } from "react";
import { useParams } from "react-router";
import Navigation from "../Navigation";
import { useQuery } from "react-query";
import { getAdminOrganization } from "api/organizationsAPI";
import CreateUser from "../AdminCreateUser";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbSettings, TbUserCircle, TbUserPlus, TbUsers } from "react-icons/tb";
import { SidebarItemRaw } from "app/pages/groups/GroupMembers";
import { OrganizationUsers } from "app/pages/admin/organizationDetails/OrganizationUsers";
import { OrganizationGroups } from "app/pages/admin/organizationDetails/OrganizationGroups";
import { OrganizationSettings } from "app/pages/admin/organizationDetails/OrganizationSettings";

enum OrganizationView {
  Settings,
  Users,
  Groups,
}

export const useGetOrganization = () => {
  const { id } = useParams<{ id?: string }>();
  return useQuery(["organization", id], () => getAdminOrganization(id || ""), {
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

const OrganizationTopbar = () => {
  const [newUserModal, setNewUserModal] = useState(false);
  const { data, isSuccess } = useGetOrganization();

  if (!isSuccess) return null;

  return (
    <div className="flex items-center gap-4 mb-6">
      <Navigation id={data.id} name={data.name} />
      <NewButton
        variant="primary"
        className="ml-auto"
        onClick={() => setNewUserModal(true)}
      >
        <TbUserPlus /> New User(s)
      </NewButton>

      {newUserModal && (
        <CreateUser
          org={data.id}
          open={newUserModal}
          close={() => setNewUserModal(false)}
        />
      )}
    </div>
  );
};

export const AdminOrganizationDetails = () => {
  const { id } = useParams<{ id?: string }>();
  const [newUserModal, setNewUserModal] = useState(false);
  const [view, setView] = useState(OrganizationView.Settings);

  return (
    <div className="flex flex-col w-full justify-center py-6 px-4 text-gray-600">
      <OrganizationTopbar />

      <div className="flex flex-col md:flex-row justify-center">
        <div className="flex flex-col gap-1 grow md:max-w-[15rem] md:mr-8">
          <SidebarItemRaw
            onClick={() => setView(OrganizationView.Settings)}
            active={view === OrganizationView.Settings}
          >
            <TbSettings /> Settings
          </SidebarItemRaw>
          <SidebarItemRaw
            onClick={() => setView(OrganizationView.Users)}
            active={view === OrganizationView.Users}
          >
            <TbUserCircle /> Users
          </SidebarItemRaw>
          <SidebarItemRaw
            onClick={() => setView(OrganizationView.Groups)}
            active={view === OrganizationView.Groups}
          >
            <TbUsers /> Groups
          </SidebarItemRaw>
        </div>
        <div className="flex flex-col gap-4 max-w-3xl grow mb-auto">
          {view === OrganizationView.Settings && <OrganizationSettings />}
          {view === OrganizationView.Users && <OrganizationUsers />}
          {view === OrganizationView.Groups && <OrganizationGroups />}
        </div>
      </div>

      {/*{id && (*/}
      {/*  <>*/}
      {/*    <TabSwitchButton admin defaultTab={1} setActiveTab={setActiveTab} />*/}
      {/*    {activeTab === 1 && (*/}
      {/*      <Profile*/}
      {/*        id={id}*/}
      {/*        name={data?.name || "No name"}*/}
      {/*        is2FA={data?.is_2fa}*/}
      {/*        refetch={refetch}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*    {activeTab === 2 && <Users id={id} />}*/}
      {/*    {activeTab === 3 && <Classes id={id} />}*/}
      {/*    {activeTab === 4 && <OrganizationSettings id={id} />}*/}
      {/*  </>*/}
      {/*)}*/}
      {newUserModal && (
        <CreateUser
          org={id}
          open={newUserModal}
          close={() => setNewUserModal(false)}
        />
      )}
    </div>
  );
};
