import React, { useState } from "react";
import arrow from "../../../../assets/images/arrowRigth.svg";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getGroupsForUser,
  putUsersRoleToOrganizationGroup,
} from "../../../../api/organizationsAPI";
import Loader from "../../../components/Loader";
import { useSelector } from "react-redux";
import { selectUserDetails } from "../../../store/user";
import { toast } from "react-hot-toast";

const UsersClass = ({ id, member_id }) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const user = useSelector(selectUserDetails);

  const { data, isLoading, isSuccess, isFetching } = useQuery(
    ["user", id, member_id],
    () => getGroupsForUser(id, member_id),
    { refetchOnWindowFocus: false, enabled: !!open }
  );

  const updateRole = useMutation(
    "update",
    ({ admin, group_id }: { admin: boolean; group_id: string }) => {
      return putUsersRoleToOrganizationGroup(id, group_id, member_id, {
        is_admin: admin,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", id, member_id]);
        queryClient.invalidateQueries(["user", id]);
      },
    }
  );

  return (
    <ScClass>
      <div>
        <ScArrow
          direction={open}
          onClick={() => setOpen(!open)}
          alt="arrow"
          src={arrow}
        />
      </div>
      {open ? (
        isLoading ? (
          <Loader />
        ) : isSuccess ? (
          <ScCol style={{ marginLeft: "30px" }}>
            {data.results.length > 0 && (
              <div style={{ display: "flex" }}>
                <ScColElement style={{ minWidth: "200px", maxWidth: "200px" }}>
                  Name
                </ScColElement>
                <ScColElement>Admin</ScColElement>
                <ScColElement>Owner</ScColElement>
                <ScColElement style={{ minWidth: "200px", maxWidth: "200px" }}>
                  Owner
                </ScColElement>
              </div>
            )}
            {data.results.length ? (
              data.results.map((item) => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    key={item.group_id}
                  >
                    <ScColElement
                      style={{ minWidth: "200px", maxWidth: "200px" }}
                    >
                      <input value={item.group} />
                    </ScColElement>
                    <ScColElement style={{ justifyContent: "center" }}>
                      {isLoading || updateRole.isLoading || isFetching ? (
                        <Loader />
                      ) : (
                        <input
                          type="checkbox"
                          checked={
                            item.admins.some((item) => item.id === user.id) ||
                            item.owner.id === user.id
                          }
                          onChange={() =>
                            item.owner.id === user.id
                              ? toast.error("Group owner cant be downgraded")
                              : updateRole.mutate({
                                  admin: !item.admins.some(
                                    (item) => item.id === user.id
                                  ),
                                  group_id: item.group_id,
                                })
                          }
                        />
                      )}
                    </ScColElement>
                    <ScColElement style={{ justifyContent: "center" }}>
                      <input
                        type="checkbox"
                        checked={item.owner.id === user.id}
                        disabled={item.owner.id === user.id}
                      />
                    </ScColElement>
                    <ScColElement
                      style={{ minWidth: "200px", maxWidth: "200px" }}
                    >
                      <span>
                        {item.owner.id !== user.id && item.owner.name}
                      </span>
                    </ScColElement>
                  </div>
                );
              })
            ) : (
              <div>No classes</div>
            )}
          </ScCol>
        ) : (
          <div>Something went wrong</div>
        )
      ) : null}
    </ScClass>
  );
};

export default UsersClass;

const ScClass = styled.div`
  display: flex;
  margin: 10px 0;
  padding-top: 10px;
  border-top: 1px solid #b7b7b7;
  min-height: 62px;
`;

const ScArrow = styled.img<{ direction: boolean }>`
  transform: ${(p) => p.direction && "rotate(90deg)"};
  transition: transform 0.2s ease-in-out;
  margin: 0 10px;
  cursor: pointer;
`;

const ScCol = styled.div`
  display: flex;
  flex-direction: column;

  input {
    &:first-child {
      margin-left: 0;
    }
    margin: 10px 0;
    max-width: 300px;
    height: 30px;
    font-size: 16px;
  }
`;

const ScColElement = styled.div`
  display: flex;
  min-width: 70px;
  max-width: 70px;
`;
