import React, { KeyboardEventHandler, useCallback, useRef } from "react";
import {
  useGetList,
  usePageItemContext,
  usePageItemIndex,
  useSetList,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  PageItemType,
  Sorting,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import {
  MediaData,
  SelectOnlyType,
} from "app/components/Sources/MediaPicker/MediaPicker";
import { useItemFocus } from "app/components/Exercises/CourseEdit/items/PageItemFocus";
import { uuid } from "app/components/Exercises/utils/uuid";
import {
  InputWithMedia,
  InputWithMediaRender,
} from "app/components/Exercises/CourseEdit/components/InputWithMedia";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { TbGripVertical, TbPlus, TbTrash } from "react-icons/tb";
import { withConsumption } from "app/components/Exercises/CourseEdit/render/ConsumptionContext";
import { SortingConsumption } from "app/components/Exercises/CourseEdit/render/courseConsumptionTypes";
import {
  InstructionsRender,
  QuizDropzone,
  QuizInstructionsRender,
  QuizItemLabel,
} from "app/components/Exercises/CourseEdit/components/generate/QuizDropzone";
import { SortingResults } from "app/components/Exercises/CourseEdit/results/courseResultsTypes";
import { CorrectMarker } from "app/components/Exercises/CourseEdit/results/PageResults";
import { CorrectnessMarker } from "app/components/Exercises/CourseEdit/results/PageCorrect";
import { NewButton } from "app/components/Buttons/NewButton";
import { useTranslation } from "react-i18next";
import {
  DropMedia,
  DropMediaColumns,
  useDropMedia,
} from "../../../../Sources/MediaPicker/context/dropMediaContext";
import { ImportQuizFromTable } from "./PagePairingItem";

export const PageSortingItem = () => {
  const { t } = useTranslation();
  const [item, set] = usePageItemContext<Sorting>();
  const [dropMedia] = useDropMedia();

  const handleDrop = ({ destination, source }: any) => {
    if (!source || !destination) return;
    if (source.index === destination.index) return;

    set((item) => {
      const [single] = item.items.splice(source.index, 1);
      item.items.splice(destination.index, 0, single);
    });
  };

  const insertOneColumn = (data: string[]) => {
    set<Sorting>((item) => {
      // delete empty items
      item.items
        .slice()
        .reverse()
        .forEach((side, i, list) => {
          if (!side.text && !side.media)
            item.items.splice(list.length - 1 - i, 1);
        });

      item.items.push(
        ...(data.map((text) => ({
          text,
          id: uuid(),
        })) as Sorting["items"])
      );
    });
  };

  return (
    <PageItemWrapper>
      <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col">
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable
            droppableId={`sorting-${item.id}`}
            type={`sorting-${item.id}`}
          >
            {({ innerRef, droppableProps, placeholder }) => (
              <div className="flex flex-col" ref={innerRef} {...droppableProps}>
                <div className="mb-0.5 flex flex-col gap-1">
                  <QuizItemLabel type={item.type}>
                    <ImportQuizFromTable
                      selectOnly={SelectOnlyType.oneColumn}
                      onInsert={({ data }) => insertOneColumn(data)}
                    />
                  </QuizItemLabel>
                  <QuizDropzone />
                </div>
                {item.items.map((item, i) => (
                  <SingleSortingItem key={item.id} {...item} index={i} />
                ))}
                {placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {dropMedia && dropMedia.type === DropMediaColumns.OneColumn ? (
          <div className="h-24 relative w-full">
            <DropMedia
              onDrop={(media) => {
                if (media.type === DropMediaColumns.OneColumn) {
                  return () => insertOneColumn(media.data);
                }
              }}
            />
          </div>
        ) : (
          <NewButton
            center
            variant="bg-opacity-0 hover:bg-opacity-80"
            color="bg-white text-gray-500"
            size="lg"
            className="font-bold mt-1"
            onClick={() => {
              set((item) => {
                item.items.push({ id: uuid(), text: "" });
              });
            }}
          >
            <TbPlus /> {t("v4.generic.addItem")}
          </NewButton>
        )}
      </div>
    </PageItemWrapper>
  );
};

const SingleSortingItem = ({
  id: itemId,
  text,
  media,
  index: itemIndex,
}: { index: number } & Sorting["items"][number]) => {
  const { t } = useTranslation();
  const [item, set] = usePageItemContext<Sorting>();
  const index = usePageItemIndex();
  const setList = useSetList();
  const getList = useGetList();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const setFocus = useItemFocus(itemId, (distance, reverse) => {
    const pos = reverse ? text.length - distance : distance;
    inputRef.current?.focus();
    inputRef.current?.setSelectionRange(pos, pos);
  });

  const handleText = useCallback(
    (text: string) => {
      set((item) => {
        item.items[itemIndex].text = text;
      });
    },
    [set, itemIndex]
  );

  const handleMedia = useCallback(
    (media?: MediaData) => {
      set((item) => {
        if (!media) {
          delete item.items[itemIndex]["media"];
        } else {
          item.items[itemIndex].media = media;
        }
      });
    },
    [set, itemIndex]
  );

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    const target = e.target as HTMLTextAreaElement;
    const list = getList();

    // on arrow up
    if (e.key === "ArrowUp") {
      // selection has to be collapsed at start
      if (
        !!target.selectionStart ||
        target.selectionStart !== target.selectionEnd
      )
        return;

      if (!itemIndex) {
        if (!index) return;
        // moving focus to the previous item
        const previousItem = list[index - 1];

        // next item has to be text
        if (
          previousItem.type !== PageItemType.Paragraph &&
          previousItem.type !== PageItemType.List &&
          previousItem.type !== PageItemType.MultipleChoice &&
          previousItem.type !== PageItemType.Sorting
        )
          return;

        // selection has to be collapsed at beginning
        if (
          target.selectionStart ||
          target.selectionStart !== target.selectionEnd
        )
          return;

        setFocus(previousItem.id, 0, true);
        return;
      }

      const previousListItem = item.items[itemIndex - 1];
      setFocus(previousListItem.id, 0, true);
      return;
    }

    // on arrow down
    if (e.key === "ArrowDown") {
      // selection has to be collapsed at end
      if (
        target.selectionEnd !== text.length ||
        target.selectionStart !== target.selectionEnd
      )
        return;

      if (itemIndex === item.items.length - 1) {
        if (index === list.length - 1) return;
        // moving focus to the next item
        const nextItem = list[index + 1];

        // previous item has to be text
        if (
          nextItem.type !== PageItemType.Paragraph &&
          nextItem.type !== PageItemType.List &&
          nextItem.type !== PageItemType.MultipleChoice &&
          nextItem.type !== PageItemType.Sorting
        )
          return;

        // selection has to be collapsed at end
        if (
          target.selectionEnd !== text.length ||
          target.selectionStart !== target.selectionEnd
        )
          return;

        setFocus(nextItem.id);
        return;
      }

      const nextListItem = item.items[itemIndex + 1];
      setFocus(nextListItem.id);
    }

    // has to be backspace
    if (e.key === "Backspace") {
      if (media) return;

      if (item.items.length === 1 && !text) {
        setList((list) => {
          list.splice(index, 1);
        });

        if (index) {
          const previousItem = list[index - 1];
          setFocus(previousItem.id, 0, true);
        }
        return;
      }

      // selection has to be collapsed at start
      if (
        !!target.selectionStart ||
        target.selectionStart !== target.selectionEnd
      )
        return;

      e.preventDefault();
      set((item) => {
        item.items.splice(itemIndex, 1);
        if (itemIndex > 0) {
          item.items[itemIndex - 1].text += text;
          setFocus(item.items[itemIndex - 1].id, text.length, true);
        }
      });
      return;
    }

    if (e.key === "Enter") {
      if (e.shiftKey) return;
      // selection has to be collapsed
      if (target.selectionStart !== target.selectionEnd) return;

      const pos = target.selectionStart;
      const id = uuid();
      e.preventDefault();
      set((item) => {
        const text = item.items[itemIndex].text;
        item.items[itemIndex].text = text.slice(0, pos);
        item.items.splice(itemIndex + 1, 0, {
          id,
          text: text.slice(pos),
        });
      });
      setFocus(id);
    }
  };

  return (
    <Draggable draggableId={itemId} index={itemIndex}>
      {({ innerRef, draggableProps, dragHandleProps }) => (
        <div
          className="flex sorting-item bg-gray-200 rounded-lg p-0.5 relative sorting-group"
          ref={innerRef}
          {...draggableProps}
        >
          <div
            {...dragHandleProps}
            className="self-stretch shrink-0 w-11 flex items-center justify-center rounded-lg bg-gray-600 bg-opacity-0 hover:bg-opacity-10 transition"
          >
            <TbGripVertical className="shrink-0 opacity-0 [.sorting-item:hover_&]:opacity-50 [.sorting-item:hover_&:hover]:opacity-100 transition" />
            <span className="text-gray-500 font-bold mx-auto pr-1">
              {itemIndex + 1}
            </span>
          </div>
          <div className="grow bg-white rounded-lg p-1.5 flex items-center">
            <InputWithMedia
              {...{ text, media }}
              ref={inputRef}
              onText={handleText}
              onMedia={handleMedia}
              className="grow"
              placeholder={t("v4.generic.textPlaceholder")}
              onKeyDown={handleKeyDown}
            />
          </div>

          <NewButton
            iconOnly
            color="bg-red-500 text-red-500"
            size="lg"
            className="mb-auto absolute top-0 -right-2.5 transform translate-x-full opacity-0 [.sorting-group:hover>&]:opacity-100"
            center
            onClick={() => {
              if (item.items.length === 1) {
                setList((list) => {
                  list.splice(index, 1);
                });
              } else {
                set((item) => {
                  item.items.splice(itemIndex, 1);
                });
              }
            }}
          >
            <TbTrash />
          </NewButton>
        </div>
      )}
    </Draggable>
  );
};

export const PageSortingRender = withConsumption<SortingConsumption>(
  ({ answer, items, set, id, type }) => {
    const handleDrop = ({ destination, source }: any) => {
      if (!source || !destination) return;
      if (source.index === destination.index) return;

      set((item) => {
        const [single] = item.answer.splice(source.index, 1);
        item.answer.splice(destination.index, 0, single);
      });
    };

    return (
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable
          droppableId={`sorting-consumption-${id}`}
          type={`sorting-consumption-${id}`}
        >
          {({ innerRef, droppableProps, placeholder }) => (
            <div
              className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1"
              ref={innerRef}
              {...droppableProps}
            >
              <QuizItemLabel type={type} />
              <QuizInstructionsRender id={id} />
              {answer.map((id, i) => {
                const { text, media } = items.find((item) => item.id === id)!;
                return (
                  <Draggable key={id} draggableId={id} index={i}>
                    {({ innerRef, draggableProps, dragHandleProps }) => (
                      <div
                        className="flex sorting-item bg-gray-200 rounded-lg p-0.5"
                        ref={innerRef}
                        {...draggableProps}
                        {...dragHandleProps}
                      >
                        <TbGripVertical className="shrink-0 text-gray-400 my-auto text-sm" />
                        <div className="self-stretch shrink-0 w-7 flex items-center justify-center rounded-lg transition">
                          <span className="text-gray-500 font-bold mx-auto pr-1">
                            {i + 1}
                          </span>
                        </div>
                        <div className="grow bg-white rounded-lg p-1.5 flex items-center">
                          <InputWithMediaRender {...{ text, media }} />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export const PageSortingResults = ({ item }: { item: SortingResults }) => (
  <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
    <InstructionsRender instructions={item.instructions} />
    {item.answer.map((id, i) => {
      const { text, media } = item.items.find((item) => item.id === id)!;
      const correct = !!item.result?.[id];

      return (
        <div
          className="flex sorting-item bg-gray-200 rounded-lg p-0.5"
          key={id}
        >
          <div className="self-stretch shrink-0 w-7 flex items-center justify-center rounded-lg transition">
            <span className="text-gray-500 font-bold mx-auto pr-1">
              {i + 1}
            </span>
          </div>
          <CorrectMarker
            correct={correct}
            content={
              <InputWithMediaRender
                text={item.items[i].text}
                media={item.items[i].media}
              />
            }
          />
          <div className="grow bg-white rounded-lg p-1.5 flex items-center">
            <InputWithMediaRender {...{ text, media }} />
          </div>
        </div>
      );
    })}
  </div>
);

export const PageSortingCorrect = ({ item }: { item: Sorting }) => (
  <div className="bg-gray-200 rounded-lg p-1.5 flex flex-col gap-1">
    <InstructionsRender instructions={item.instructions} />
    {item.items.map(({ id, text, media }, i) => {
      return (
        <div
          className="flex sorting-item bg-gray-200 rounded-lg p-0.5"
          key={id}
        >
          <div className="self-stretch shrink-0 w-7 flex items-center justify-center rounded-lg transition">
            <span className="text-gray-500 font-bold mx-auto pr-1">
              {i + 1}
            </span>
          </div>
          <CorrectnessMarker ids={[item.id, id]} />
          <div className="grow bg-white rounded-lg p-1.5 flex items-center relative">
            <InputWithMediaRender {...{ text, media }} />
          </div>
        </div>
      );
    })}
  </div>
);
