import { Link } from "react-router-dom";
import { publicRoutes } from "enums/routes";
import React from "react";
import { NewButton } from "app/components/Buttons/NewButton";
import { TbAlertTriangle, TbHome } from "react-icons/tb";
import { GlobalFlexStyles } from "app/components/Helpers/GlobalFlexStyles";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center text-center flex-col p-10 m-auto absolute left-0 top-0 w-full h-full text-gray-500">
      <GlobalFlexStyles />
      <TbAlertTriangle className="text-5xl mb-2" />
      <h2 className="text-3xl mb-8 text-gray-500">
        {t("pages.notFound.title")}
      </h2>
      <NewButton
        size="lg"
        variant="primary"
        component={Link}
        to={publicRoutes.home}
        className="mb-8"
      >
        <TbHome />
        {t("pages.notFound.return")}
      </NewButton>
    </div>
  );
};
