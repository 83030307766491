import { apiUrlV1 } from "enums/constant";
import axios from "axios";

export async function postAutocomplete(user_text: string) {
  const url = `${apiUrlV1}/autocomplete_paragraph/`;
  const { data } = await axios.post<{ text_predicted: string }>(url, {
    user_text,
  });
  return data;
}

export async function postGenerateList(text: string, listItems?: string[]) {
  const url = `${apiUrlV1}/get_slide_bullet_points/`;
  const { data } = await axios.post<{ bullet_points: string[] }>(url, {
    slide_text: text,
    bullet_points: listItems,
    presentation_title: "",
  });
  return data;
}

export async function postSummarize(user_text: string) {
  const url = `${apiUrlV1}/summarise_article/`;
  const { data } = await axios.post<{ summary: string }>(url, {
    user_text,
  });
  return data;
}

export async function postSimplify(user_text: string) {
  const url = `${apiUrlV1}/simplify_article/`;
  const { data } = await axios.post<{ text_predicted: string }>(url, {
    user_text,
  });
  return data;
}

export async function postParaphrase(user_text: string) {
  const url = `${apiUrlV1}/paraphrase_paragraph/`;
  const { data } = await axios.post<{ text_predicted: string }>(url, {
    user_text,
  });
  return data;
}

export async function postNext(user_text: string) {
  const url = `${apiUrlV1}/generate_next_paragraph/`;
  const { data } = await axios.post<{ text_predicted: string }>(url, {
    user_text,
  });
  return data;
}

export async function postTranslate(text: string, language) {
  const url = `${apiUrlV1}/ms_translate/`;
  const { data } = await axios.post<{ translation: string }>(url, {
    text,
    tgt_lang: language,
  });
  return data;
}

export interface MediaListApiImage {
  title: string;
  url: string;
  source: "wiki" | "pexels" | "unsplash";
  type: "image";
  attribution?: string;
}
export interface MediaListApiVideo {
  title: string;
  url: string;
  source: "youtube";
  type: "video";
}

export async function getGenerateMedia(text: string) {
  const url = `${apiUrlV1}/get_slide_media/`;
  const { data } = await axios.post<{
    images: MediaListApiImage[];
    videos: MediaListApiVideo[];
  }>(url, { query: text });
  return data;
}

export async function postMultipleChoice(user_text: string, number = 3) {
  const url = `${apiUrlV1}/mcq_generate/`;
  const { data } = await axios.post<{
    items: {
      question: { text: string };
      answers: { isCorrect: boolean; text: string }[];
    }[];
  }>(url, {
    user_text,
    n_questions: number,
  });
  return data;
}

export async function postPageFromSource({
  sourceId,
  number = 3,
  summarize = false,
  text = "",
}) {
  const url = `${apiUrlV1}/generate_course/`;
  const { data } = await axios.post<any>(url, {
    source_id: sourceId,
    user_text: text,
    n_questions: number,
    force_summarize: summarize,
  });
  return data;
}

export async function postMultipleResponse(user_text: string) {
  const url = `${apiUrlV1}/true_false_questions/`;
  const { data } = await axios.post<{
    items: { isCorrect: boolean; text: string }[];
  }>(url, { user_text });
  return data;
}

export async function postFtgContent(user_text: string, language = false) {
  const url = `${apiUrlV1}/ftg_generate/`;
  const { data } = await axios.post<{
    data: string;
    items: Record<string, { options: string[]; text: string }>;
  }>(url, {
    user_text,
    topic: language ? "language" : "semantics",
  });
  return data;
}

export async function postFtgGaps(user_text: string, language = false) {
  const url = `${apiUrlV1}/generate_ftg_distractors/`;
  const { data } = await axios.post<{
    data: string;
    items: Record<string, { options: string[]; text: string }>;
  }>(url, {
    user_text,
    topic: language ? "language" : "semantics",
  });
  return data;
}
