import {
  usePageItemContext,
  usePageItemIndex,
  useSetList,
} from "app/components/Exercises/CourseEdit/PageStoreContext";
import {
  ParagraphAutocomplete,
  RichParagraph,
} from "app/components/Exercises/CourseEdit/courseEditTypes";
import { postAutocomplete } from "api/course/courseContentAPI";
import { PageItemWrapper } from "app/components/Exercises/CourseEdit/items/PageItemWrapper";
import {
  PageLeafRender,
  paragraphsToText,
  textToParagraphs,
} from "app/components/Exercises/CourseEdit/items/content/PageParagraphItem";
import { SkeletonParagraph } from "app/components/Exercises/CourseEdit/components/SkeletonParagraph";
import React, { useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useMountedRef } from "app/hooks/useMounted";
import { translateError } from "app/components/Exercises/Edit/missingTranslation";
import { useSubscriptionRefetch } from "api/userAPI";
import { useRole } from "app/hooks/useRole";

export const joinParagraphs = (
  first: RichParagraph[],
  second: RichParagraph[]
): RichParagraph[] => [
  ...first.slice(0, -1),
  [...first.slice(-1)[0], { text: " " }, ...(second?.[0] || [])],
  ...second.slice(1),
];

export const handleOpenAIError = (error, t, role) => {
  if (error?.response?.data?.error === "NO_CREDITS_LEFT")
    if (role.pro) toast.error(t("v4.subscriptions.creditsErrorPro"));
    else toast.error(t("v4.subscriptions.creditsError"));
  else toast.error(translateError(t("common.errors.general"), t));
};

export const PageParagraphAutocomplete = () => {
  const [item] = usePageItemContext<ParagraphAutocomplete>();
  const { t } = useTranslation();
  const mountedRef = useMountedRef();
  const index = usePageItemIndex();
  const indexRef = useRef(index);
  indexRef.current = index;
  const setList = useSetList();
  const refetchSubscription = useSubscriptionRefetch();
  const role = useRole();

  useEffect(() => {
    if (!item?.data?.data) {
      toast.error(t("common.errors.general"));
      if (!mountedRef.current) return;
      setList((list) => {
        list.splice(indexRef.current, 1);
      });
      return;
    }
    postAutocomplete(paragraphsToText(item.data.data))
      .then(({ text_predicted }) => {
        if (!mountedRef.current) return;
        const text = textToParagraphs(text_predicted);
        const generated = {
          ...item.data,
          data: joinParagraphs(item.data.data, text),
        };
        const items = Array.isArray(generated) ? generated : [generated];

        setList((list) => {
          list.splice(indexRef.current, 1, ...items);
        });
        refetchSubscription();
      })
      .catch((e) => {
        handleOpenAIError(e, t, role);
        if (!mountedRef.current) return;
        setList((list) => {
          list.splice(indexRef.current, 1, item.data);
        });
      });
  }, []);

  return (
    <PageItemWrapper>
      <div className="leading-relaxed flex flex-col gap-2">
        {item.data.data.map((paragraph, i, list) => (
          <div>
            {paragraph.map((item, i) => (
              <PageLeafRender item={item} key={i} />
            ))}
            {i === list.length - 1 && <SkeletonParagraph min={8} max={20} />}
          </div>
        ))}
      </div>
    </PageItemWrapper>
  );
};
